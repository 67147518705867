/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { Product } from '../../constants/types';
import { calculateDiscountPercentage } from '../../utils/misc';

interface OwnProps {
    product: Product;
}

const DiscountBox: React.FC<OwnProps> = (props: OwnProps) => {
    const { product } = props;
    const discount = calculateDiscountPercentage(product);

    let style = '';

    if (discount <= 10) {
        style = 'green';
    } else if (discount < 50) {
        style = 'yellow';
    } else {
        style = 'orange';
    }

    return (
        <div className={`discount-box discount-box--${style}`}>
            -{discount}%
        </div>
    );
};

export default DiscountBox;

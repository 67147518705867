/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import Table from 'react-bootstrap/Table';
import { get, throttle } from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ModalFooter from 'react-bootstrap/ModalFooter';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import {
    ContactFeeType, DeliveryMethods, ScheduleItem, Store,
} from '../../../constants/types';
import {
    storeDeliveryMethodsURL,
    storeDisableUrl,
    storesURL,
    storeUploadURL,
    storeURL,
    storeXLSURL,
} from '../../../services/stores';
import Loader from '../../elements/Loader';
import Button from '../../elements/Button';
import FormTextField from '../../elements/FormTextField';
import ImageUpload from '../../elements/ImageUpload';
import {
    getFormErrors, IFormError, validateField, VALIDATIONS,
} from '../../../utils/validation';
import { globalButtonLabels, ibanCodeLengths, SelectOption } from '../../../constants/misc';
import { categoriesAdminURL, customCategoriesURL } from '../../../services/categories';
import FormSelectField from '../../elements/FormSelectField';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import { resourcesURL } from '../../../services/resources';
import displayConfirm from '../../elements/displayConfirm';
import StoreSchedule from '../../elements/StoreSchedule';
import { generateId, validateIBAN, validatePtNif } from '../../../utils/misc';
import withPaging, { WithPagingProps } from '../../hocs/withPaging';
import TablePaging from '../../elements/TablePaging';
import FormCheckbox from '../../elements/FormCheckbox';
import { csvUploadErrorCodes } from '../../../constants/errorCodes';
import SortArrow from '../../elements/SortArrow';

interface OwnProps extends TranslationContext, WithPagingProps { }

interface OwnState {
    isFetching: boolean;
    store: Store;
    showStoreModal: boolean;
    showRemoteStoreModal: boolean;
    showUploadModal: boolean;
    showChangeStoreStateModal: boolean;
    showErrorEuPagoDownModal: boolean;
    isEditingStore: boolean;
    formErrors: any;
    categoriesOptions: Array<SelectOption>;
    deliveryMethodsOptions: Array<SelectOption>;
    stores: Array<Store>;
    _limit: number;
    totalResults: number;
    photosToRemove: Array<string>;
    banner: File | null;
    searchString: string;
    sortOrder: string;
    sortField: string;
    failedToDelete: boolean;
    isEnablingStore: boolean;
}

const initialState: OwnState = {
    isFetching: false,
    stores: [],
    showStoreModal: false,
    showRemoteStoreModal: false,
    showUploadModal: false,
    showErrorEuPagoDownModal: false,
    showChangeStoreStateModal: false,
    isEditingStore: false,
    formErrors: null,
    categoriesOptions: [],
    deliveryMethodsOptions: [],
    _limit: 25,
    totalResults: 0,
    photosToRemove: [],
    banner: null,
    store: {
        id: -1,
        address: '',
        closeToLaunch: false,
        closeToLaunchOn: '',
        openFromLaunchOn: '',
        contact: '',
        description: '',
        email: '',
        homeDelivery: false,
        latitude: null,
        longitude: null,
        name: '',
        thumbnailHash: '',
        website: '',
        postalCode: '',
        thumbnail: null,
        categoryId: null,
        tags: [],
        weekSchedule: [],
        iban: '',
        nif: '',
        certCode: '',
        bicSwift: '',
        location: '',
        country: '',
        photosHash: [],
        bannerHash: '',
        history: {
            description: '',
            photo1: '',
            photo2: '',
            photo3: '',
            photo4: '',
            title: '',
        },
        courier: false,
        allowVouchers: false,
        productFormat: '',
        customStoreCategories: [],
        exhibition: false,
        productsFeedURL: '',
        contactFeeType: '',
    },
    searchString: '',
    sortOrder: 'ASC',
    sortField: 'NAME',
    failedToDelete: false,
    isEnablingStore: false,
};

const adminStoresLabels = {
    selectCategory: 'admin.stores.labels.selectCategory',
    productFormat: 'admin.stores.labels.productFormat',
};

const cancelButtonLabel = 'global.buttons.cancel';
const categoryIdFieldLabel = 'fields.categoryId';
const okButtonLabel = 'global.buttons.ok';

class StoresAdminList extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    private readonly throttledGetStores = () => { };

    constructor(props: OwnProps) {
        super(props);
        this.throttledGetStores = throttle(this.getStores, 900);
    }

    componentDidMount(): void {
        this.prepare();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>) {
        const { currentPage } = this.props;
        const { currentPage: oldCurrentPage } = prevProps;

        const hasPagingChanged: boolean = currentPage !== oldCurrentPage;

        if (hasPagingChanged) {
            this.getStores();
        }
    }

    onStoreImageSelected = (file: File) => {
        const { store } = this.state;
        this.setState({
            store: {
                ...store,
                thumbnail: file,
            },
        });
    };

    onAddSchedule = () => {
        const { store } = this.state;
        const { weekSchedule } = store;
        const id = generateId();

        const hasEmptySchedule: boolean = this.validateHasEmptySchedule();

        if (!hasEmptySchedule && weekSchedule.length < 14) {
            const newSchedule = {
                id,
                initialWeekDay: '',
                endWeekDay: '',
                openOn: '',
                closeOn: '',
                isInterval: false,
            };

            weekSchedule.push(newSchedule);

            this.setState({
                store: {
                    ...store,
                    weekSchedule: [...weekSchedule],
                },
            });
        }
    };

    onDisableOrDeleteProductClose = () => {
        this.setState({
            showChangeStoreStateModal: false,
            store: initialState.store,
        });
    };

    onScheduleRemove = (id: string) => {
        const { store } = this.state;
        const { weekSchedule } = store;

        const newSchedule: Array<ScheduleItem> = weekSchedule.filter(item => item.id !== id);

        this.setState({
            ...this.state,
            store: {
                ...this.state.store,
                weekSchedule: [...newSchedule],
            },
        });
    };

    onScheduleChange = (schedule: ScheduleItem) => {
        const { store } = this.state;
        const { weekSchedule } = store;

        const newWeekSchedule = [...weekSchedule];
        const index = newWeekSchedule.findIndex(s => s.id === schedule.id);

        newWeekSchedule[index] = {
            ...schedule,
            initialWeekDay: Number(schedule.initialWeekDay),
        };

        this.setState({
            store: {
                ...store,
                weekSchedule: [...newWeekSchedule],
            },
        });
    };

    onCourierChange = (): void => {
        const { store } = this.state;

        this.setState({
            store: {
                ...store,
                courier: !store.courier,
            },
        });
    }

    onVoucherChange = (): void => {
        const { store } = this.state;

        this.setState({
            store: {
                ...store,
                allowVouchers: !store.allowVouchers,
            },
        });
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>, idx?: number) => {
        const { store } = this.state;
        const { name, value } = e.currentTarget;
        let errors = null;

        if (name.includes('customStoreCategory') && idx !== undefined) {
            const { store: { customStoreCategories } } = this.state;
            if (!customStoreCategories) return;
            const tempCategories = [...customStoreCategories];
            tempCategories[idx].categoryId = e.currentTarget.value;

            this.setState(prev => ({
                ...prev,
                store: {
                    ...prev.store,
                    customStoreCategories: tempCategories,
                },
            }));
            return;
        }

        if (name === 'homeDelivery') {
            this.setState(prev => ({
                ...prev,
                store: {
                    ...prev.store,
                    homeDelivery: !prev.store.homeDelivery,
                },
            }));

            return;
        }

        if (name === 'closeToLaunch') {
            this.setState(prev => ({
                ...prev,
                store: {
                    ...prev.store,
                    closeToLaunch: !prev.store.closeToLaunch,
                },
            }));

            return;
        }

        if (name === 'iban') {
            errors = { [e.currentTarget.name]: validateField(name, value, VALIDATIONS.STORE_FORM) };
        }

        this.setState({
            store: {
                ...store,
                [e.currentTarget.name]: e.currentTarget.value,
            },
            formErrors: { fields: errors },
        });
    };

    onAddStoreClick = () => {
        this.setState({
            showStoreModal: true,
        }, () => this.getCategories());
    };

    onAddRemoteStoreClick = () => {
        this.setState({
            showRemoteStoreModal: true,
            store: {
                ...this.state.store,
                exhibition: true,
            },
        }, () => {
            this.getCategories();
            this.getProductFormatTypes();
        });
    };

    onCloseStoreModal = () => {
        this.setState({
            ...this.state,
            showStoreModal: false,
            showRemoteStoreModal: false,
            store: {
                ...initialState.store,
            },
            isEditingStore: false,
            formErrors: null,
        });
    };

    onCloseErrorModal = () => {
        this.setState({
            showErrorEuPagoDownModal: false,
            showStoreModal: false,
            store: {
                ...initialState.store,
            },
            isEditingStore: false,
            formErrors: null,
        });
    }

    onUploadModalClose = () => {
        this.setState({
            showUploadModal: false,
        });
    };

    onStoreClick = (store: Store) => {
        const tagsAsSelectOptions: Array<SelectOption> = [];
        Object.keys(store.tags).forEach(k => {
            const tag: any = store.tags[Number(k)];
            tagsAsSelectOptions.push({
                value: tag.id,
                label: tag.description,
            });
        });

        const weekSchedule: Array<ScheduleItem> = [];
        Object.keys(store.weekSchedule).forEach(k => {
            const item = store.weekSchedule[Number(k)];

            const isInterval: boolean = (item.endWeekDay !== null && item.endWeekDay !== undefined && Number(item.endWeekDay) !== Number(item.initialWeekDay));

            weekSchedule.push({
                id: item.id,
                openOn: item.openOn ? moment(item.openOn, 'HH:mm:ss').unix() * 1000 : '',
                closeOn: item.closeOn ? moment(item.closeOn, 'HH:mm:ss').unix() * 1000 : '',
                initialWeekDay: Number(item.initialWeekDay) + 1,
                endWeekDay: Number(item.endWeekDay) + 1,
                isInterval,
            });
        });

        this.setState({
            showStoreModal: !store.exhibition,
            showRemoteStoreModal: store.exhibition ? store.exhibition : false,
            isEditingStore: true,
            store: {
                ...store,
                tags: tagsAsSelectOptions,
                closeToLaunchOn: store.closeToLaunchOn ? moment(store.closeToLaunchOn, 'HH:mm:ss').unix() * 1000 : '',
                openFromLaunchOn: store.openFromLaunchOn ? moment(store.openFromLaunchOn, 'HH:mm:ss').unix() * 1000 : '',
                thumbnailUrl: resourcesURL(store.thumbnailHash),
                categoryId: store.category ? store.category.id : store.categoryId,
                weekSchedule: [...weekSchedule],
            },
        }, () => {
            this.getCategories();
            this.getProductFormatTypes();
            this.getCustomStoreCategories();
        });
    };

    onDeleteStore = (e: React.MouseEvent, store: Store) => {
        e.stopPropagation();

        const { t } = this.props;

        displayConfirm({
            acceptButtonText: t(okButtonLabel),
            onAccept: () => this.deleteStore(store.id),
            onReject: () => { },
            rejectButtonText: t(cancelButtonLabel),
            message: t('admin.stores.removeStoreConfirmation', { name: store.name }),
        });
    };

    onStoreBannerSelected = (file: File) => {
        this.setState({
            banner: file,
        });
    };

    onRemoveBannerPhoto = () => {
        const { photosToRemove, store } = this.state;

        photosToRemove.push(store.bannerHash);

        this.setState({
            photosToRemove,
            store: {
                ...store,
                bannerHash: '',
            },
        });
    }

    onClickUploadButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, store: Store) => {
        e.stopPropagation();
        this.setState({
            ...this.state,
            store: {
                ...store,
            },
            showUploadModal: true,
        });
    }

    onProductUploadSave = () => {
        const { store } = this.state;
        const { t } = this.props;

        if (!this.validateUploadFields()) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.errors.uploadError'));
            return;
        }

        let axiosRequestURL = '';
        const axiosRequestData = {};
        const axiosRequestConfig = {};

        this.setState({
            isFetching: true,
        });

        if (store.productsFeedURL && store.id) {
            axiosRequestURL = storeUploadURL(store.id, store.productsFeedURL, 'CSV_YOUGET');
        }

        axios.post(axiosRequestURL, axiosRequestData, axiosRequestConfig).then(() => {
            displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.stores.uploadSuccess'));
        }).catch(error => {
            switch (error.response?.data?.errorCode) {
                case csvUploadErrorCodes.DISCOUNT_PRICE_IS_EQUAL_TO_NORMAL_PRICE:
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.errors.discountPriceEqual'));
                    break;
                case csvUploadErrorCodes.PROMOTION_TOO_LONG:
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.errors.promotionTooLong'));
                    break;
                case csvUploadErrorCodes.DISCOUNT_PRICE_IS_HIGHER_THAN_NORMAL_PRICE:
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.errors.discountPriceHigher'));
                    break;
                case csvUploadErrorCodes.CATEGORY_NOT_FOUND:
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.errors.categoryNotFound'));
                    break;
                case csvUploadErrorCodes.STORE_NOT_FOUND:
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.errors.storeNotFound'));
                    break;
                default:
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.errors.uploadError'));
            }
        }).finally(() => {
            this.setState({
                isFetching: false,
            });
        });
    }

    getCustomStoreCategories() {
        const { store } = this.state;

        axios.get(customCategoriesURL(store.id))
            .then(response => {
                this.setState({
                    ...this.state,
                    store: {
                        ...this.state.store,
                        customStoreCategories: response.data,
                    },
                });
            });
    }

    validateHasEmptySchedule = (): boolean => {
        const { store } = this.state;
        const { weekSchedule } = store;

        let hasEmpty = false;
        if (weekSchedule && weekSchedule.length > 0) {
            Object.keys(weekSchedule).forEach(k => {
                const item = weekSchedule[Number(k)];

                const emptyInitialWeekDay: boolean = item.initialWeekDay === null || item.initialWeekDay === undefined || String(item.initialWeekDay).trim() === '';
                const emptyEndWeekDay: boolean = item.endWeekDay === null || item.endWeekDay === undefined || String(item.endWeekDay).trim() === '';
                const emptyCloseOn: boolean = !item.closeOn || String(item.closeOn).trim() === '';
                const emptyOpenOn: boolean = !item.openOn || String(item.openOn).trim() === '';

                if (emptyCloseOn && emptyEndWeekDay && emptyInitialWeekDay && emptyOpenOn) hasEmpty = true;
            });
        }

        return hasEmpty;
    };

    parseTimeToSend = (date: string | number): string | null => {
        if (!date || String(date).trim() === '') {
            return null;
        }

        if (isNaN(Number(date))) {
            return String(date);
        }

        return moment(date).format('HH:mm:ss.SSSSSSSSS');
    };

    deleteStore = async (id: number) => {
        const { t } = this.props;
        this.setState({ isFetching: true });

        await axios.delete(storeURL(id))
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.stores.removeStoreSuccess'));
                this.setState({
                    isFetching: false,
                    showChangeStoreStateModal: false,
                    store: initialState.store,
                }, () => this.prepare());
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.removeStoreError'));
                this.setState({
                    isFetching: false,
                    failedToDelete: true,
                });
            });
    };

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    saveStore = () => {
        const { t } = this.props;
        const {
            store, isEditingStore, banner, photosToRemove,
        } = this.state;

        if (this.validateFields()) {
            if (this.validateHasEmptySchedule()) {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.emptyScheduleError'));
                return;
            }

            const formData = new FormData();

            const actualTags: Array<string> = [];
            Object.keys(store.tags).forEach(k => {
                const tag: any = store.tags[Number(k)];

                actualTags.push(tag.label);
            });

            const weekSchedule: Array<any> = [];
            Object.keys(store.weekSchedule).forEach(k => {
                const s = store.weekSchedule[Number(k)];
                const initialWeekDay = String(s.initialWeekDay).trim() === '' ? null : Number(s.initialWeekDay) - 1;

                weekSchedule.push({
                    initialWeekDay,
                    endWeekDay: String(s.endWeekDay).trim() === '' ? initialWeekDay : Number(s.endWeekDay) - 1,
                    closeOn: s.closeOn ? this.parseTimeToSend(s.closeOn) : null,
                    openOn: s.openOn ? this.parseTimeToSend(s.openOn) : null,
                });
            });

            const fields = {
                name: String(store.name).trim(),
                description: String(store.description).trim(),
                address: String(store.address).trim(),
                email: String(store.email).trim(),
                contact: String(store.contact).trim(),
                website: String(store.website).trim(),
                postalCode: String(store.postalCode).trim(),
                nif: !store.exhibition ? String(store.nif).trim() : null,
                iban: !store.exhibition ? String(store.iban).trim() : null,
                certCode: String(store.certCode).trim(),
                bicSwift: String(store.bicSwift).trim(),
                location: String(store.location).trim(),
                country: String(store.country).trim(),
                latitude: String(store.latitude).trim() === '' ? null : store.latitude,
                longitude: String(store.longitude).trim() === '' ? null : store.longitude,
                homeDelivery: store.homeDelivery,
                closeToLaunch: store.closeToLaunch,
                categoryId: store.categoryId,
                thumbnailHash: store.thumbnailHash,
                closeToLaunchOn: store.closeToLaunch && store.closeToLaunchOn ? this.parseTimeToSend(store.closeToLaunchOn) : null,
                openFromLaunchOn: store.closeToLaunch && store.openFromLaunchOn ? this.parseTimeToSend(store.openFromLaunchOn) : null,
                tags: [...actualTags],
                weekSchedule: [...weekSchedule],
                courier: store.courier,
                allowVouchers: store.allowVouchers,
                exhibition: store.exhibition,
                productsFeedURL: store.productsFeedURL,
                contactFeeType: store.contactFeeType,
                photosToRemove,
            };

            if (store.thumbnail) {
                formData.append('thumbnail', store.thumbnail, store.thumbnail.name);
            }

            if (banner) {
                formData.append('banner', banner, banner.name);
            }

            formData.append('store', new Blob([JSON.stringify(fields)], {
                type: 'application/json',
            }));

            if (isEditingStore) this.editStoreRequest(formData);
            else this.createStoreRequest(formData);
        }
    };

    editStoreRequest = (formData: FormData) => {
        const { t } = this.props;
        const { store } = this.state;

        this.setState({ isFetching: true });
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        const urls = [axios.put(storeURL(store.id), formData, config)];
        if (store.customStoreCategories && store.customStoreCategories.length > 0) {
            const tempCategories: Record<string, string> = {};
            store.customStoreCategories.forEach(category => tempCategories[category.id] = category.categoryId);
            urls.push(axios.put(customCategoriesURL(store.id), tempCategories));
        }
        axios.all(urls)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.stores.editStoreSuccess'));

                this.setState({
                    isFetching: false,
                    showStoreModal: false,
                    showRemoteStoreModal: false,
                    isEditingStore: false,
                    store: {
                        ...initialState.store,
                    },
                }, () => this.prepare());
            })
            .catch(error => {
                this.handleSaveErrorResponse(error);
            });
    };

    createStoreRequest = (formData: FormData) => {
        const { t } = this.props;
        this.setState({ isFetching: true });

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        axios.post(storesURL(), formData, config)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.stores.createStoreSuccess'));

                this.setState({
                    isFetching: false,
                    showStoreModal: false,
                    showRemoteStoreModal: false,
                    isEditingStore: false,
                }, () => this.prepare());
            })
            .catch(error => {
                this.handleSaveErrorResponse(error);
            });
    };

    handleSaveErrorResponse = (error: any) => {
        const { t } = this.props;
        const { isEditingStore } = this.state;

        if (error.response) {
            const { status, data } = error.response;
            if (status === 400) {
                if (data.errorCode === 21) {
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.saveStoreDuplicatedNameError'));
                } else if (data.errorCode === 0) {
                    const responseError = { fields: { postalCode: [{ typeOfViolation: 'Pattern' }] } };
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.editStoreError'));
                    this.handleResponse({ ...responseError, data });
                } else if (data.result === 'EMAIL_TAKEN') {
                    const responseError = { fields: { email: [{ typeOfViolation: 'EmailTaken' }] } };
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.emailTakenError'));
                    this.handleResponse({ ...responseError, data });
                }
            } else if (status === 412) {
                this.setState({
                    showErrorEuPagoDownModal: true,
                });
            } else if (status === 413) {
                displayNotification(NOTIFICATION_TYPE.ERROR, isEditingStore ? t('admin.stores.editStoreError') : t('admin.stores.createStoreError'));

                const responseError = { fields: { thumbnail: [{ typeOfViolation: 'FileSize' }] } };
                this.handleResponse(responseError || error.response.data);
            } else {
                this.handleResponse(error.response.data);
            }
        } else {
            displayNotification(NOTIFICATION_TYPE.ERROR, isEditingStore ? t('admin.stores.editStoreError') : t('admin.stores.createStoreError'));
        }
        this.setState({ isFetching: false });
    }

    validateFields = () => {
        let errors: IFormError | null = null;
        const { store } = this.state;
        if (!store.exhibition) {
            errors = getFormErrors(this.state.store, VALIDATIONS.STORE_FORM);
        } else {
            errors = getFormErrors(this.state.store, VALIDATIONS.EXHIBITION_STORE_FORM);
        }

        if (!errors) errors = {};

        if (store.closeToLaunch) {
            if (!store.closeToLaunchOn || String(store.closeToLaunchOn).trim() === '') {
                errors.closeToLaunchOn = [{ typeOfViolation: 'NotBlank' }];
            }

            if (!store.openFromLaunchOn || String(store.openFromLaunchOn).trim() === '') {
                errors.openFromLaunchOn = [{ typeOfViolation: 'NotBlank' }];
            }
        }

        if (!store.paymentInfoValid && !store.exhibition) {
            if (store.nif) {
                if (!validatePtNif(store.nif)) {
                    errors.nif = [{ typeOfViolation: 'ValidNif' }];
                }
            }
            if (store.iban) {
                if (store.iban.length !== ibanCodeLengths.PT) {
                    errors.iban = [{ typeOfViolation: 'IBANLength' }];
                } else if (!validateIBAN(store.iban)) {
                    errors.iban = [{ typeOfViolation: 'ValidIBAN' }];
                }
            }
        }

        Object.keys(store.weekSchedule).forEach(key => {
            const item = store.weekSchedule[Number(key)];

            const itemErrors: any = {};

            const errorInitialWeekDay = validateField('initialWeekDay', item.initialWeekDay, VALIDATIONS.STORE_FORM);
            if (errorInitialWeekDay) itemErrors.initialWeekDay = errorInitialWeekDay;

            if (!item.id || !errors) return;

            if (Object.keys(itemErrors).length > 0) errors[item.id] = itemErrors;
            else delete errors[item.id];
        });

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    validateUploadFields = () => {
        const { store: { productsFeedURL } } = this.state;
        const errors = getFormErrors({ productsFeedURL }, VALIDATIONS.UPLOAD_PRODUCTS_FORM);
        return errors === null;
    }

    getCategories = async () => {
        const { t } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        try {
            const { data: categoriesData } = await axios.get(categoriesAdminURL());

            const categoriesOptions: Array<SelectOption> = [];
            categoriesOptions.push({
                value: '',
                label: t(adminStoresLabels.selectCategory),
            });

            Object.keys(categoriesData).forEach(k => {
                const c = categoriesData[Number(k)];
                categoriesOptions.push({
                    value: c.id,
                    label: c.description,
                });
            });

            this.setState({
                isFetching: false,
                categoriesOptions,
            });
        } catch {
            this.setState({ isFetching: false });
        }
    };

    getProductFormatTypes = () => {
        const formatTypeOptions: Array<SelectOption> = [];
        formatTypeOptions.push({
            value: '',
            label: 'Selecione o formato dos produtos',
        });
        formatTypeOptions.push({
            value: 'kuantokusta',
            label: 'Formato KuantoKusta',
        });

        this.setState(prev => ({
            ...prev,
            formatTypeOptions,
        }));
    }

    getStores = async () => {
        const { currentPage, onPagingChange } = this.props;
        const {
            isFetching, _limit, searchString, sortField, sortOrder,
        } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        try {
            const { data, headers } = await axios.get(storesURL({
                pageSize: _limit,
                page: currentPage - 1,
                q: searchString === '' ? null : searchString,
                sort: sortField,
                order: sortOrder,
            }));

            const newTotalResults: number = parseInt(headers['x-total-count']);

            if (data && data.length === 0 && currentPage !== 1) {
                this.setState({ isFetching: false }, () => {
                    onPagingChange(currentPage - 1);
                    this.getStores();
                });
            } else {
                this.setState({
                    isFetching: false,
                    stores: data,
                    totalResults: newTotalResults,
                });
            }
        } catch (e) {
            this.setState({ isFetching: false });
        }
    }

    prepare = async () => {
        const { t } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        try {
            const { data } = await axios.get(storeDeliveryMethodsURL());
            const deliveryMethodsOptions: Array<SelectOption> = [];

            Object.keys(data).forEach(k => {
                const delivery: DeliveryMethods = data[Number(k)];
                if (delivery !== DeliveryMethods.THIRD_PARTY) {
                    deliveryMethodsOptions.push({
                        value: delivery,
                        label: t(`enums.deliveryMethods.${delivery}`),
                    });
                }
            });
            this.setState({
                isFetching: false,
                deliveryMethodsOptions,
            }, () => this.getStores());
        } catch {
            this.setState({ isFetching: false });
        }
    };

    downloadXLS = (): void => {
        axios({
            url: storeXLSURL(),
            method: 'GET',
            responseType: 'blob',
            headers: {
                accept: 'application/octet-stream',
            },
        }).then(response => {
            if (response.headers['content-disposition']) {
                const link = document.createElement('a');

                link.href = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1].replaceAll('"', ''));
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        });
    }

    onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { onPagingChange } = this.props;
        const { value } = e.currentTarget;

        onPagingChange(1);
        this.setState({
            searchString: value,
        }, () => {
            this.throttledGetStores();
        });
    }

    onSortChange = (sortField: string, sortOrder : string) => {
        this.setState({
            sortField,
            sortOrder,
        }, this.prepare);
    }

    onChangeStoreStatus = (e: React.MouseEvent, store: Store) => {
        e.stopPropagation();
        this.setState({
            showChangeStoreStateModal: true,
            store,
            failedToDelete: false,
            isEnablingStore: false,
        });
    };

    onEnableStore = (e: React.MouseEvent, store: Store) => {
        e.stopPropagation();
        this.setState({
            showChangeStoreStateModal: true,
            store,
            failedToDelete: false,
            isEnablingStore: true,
        });
    };

    changeStoreStatus = (disabled : boolean) => {
        const { t } = this.props;
        const { store } = this.state;
        this.setState({ isFetching: true });
        
        axios.put(storeDisableUrl(store.id, disabled))
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t(disabled ? 'admin.stores.disableSuccess' : 'admin.stores.restoreSuccess'));
                this.setState({
                    isFetching: false,
                    showChangeStoreStateModal: false,
                    store: initialState.store,
                }, () => {
                    this.getStores();
                });
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t(disabled ? 'admin.stores.disableError' : 'admin.stores.restoreError'));
                this.setState({
                    isFetching: false,
                    showChangeStoreStateModal: false,
                    store: initialState.store,
                });
            });
    }

    renderStoreSchedules = (store: Store, formErrors: any): React.ReactNode => (
        Object.keys(store.weekSchedule).map(key => {
            const s = store.weekSchedule[Number(key)];

            return (
                <StoreSchedule
                    key={s.id}
                    schedule={s}
                    onScheduleChange={this.onScheduleChange}
                    errors={get(formErrors, `fields.${s.id}`, null)}
                    onRemove={this.onScheduleRemove}
                />
            );
        }))

    renderGeneralStoreInformation = (store: Store, formErrors: any): React.ReactNode => {
        const { t } = this.props;
        const { isEditingStore } = this.state;

        const contactFeeTypeLabel = t('admin.stores.labels.contactFeeType');

        return (
            <>
                <Row>
                    <ImageUpload
                        imageUrl={store.thumbnailUrl}
                        onSelected={this.onStoreImageSelected}
                        buttonText={t('global.buttons.upload')}
                        helpText={t('admin.stores.labels.thumbnailHelpText')}
                        errors={get(formErrors, 'fields.thumbnail', null)}
                    />
                </Row>

                <Row>
                    <div className="banner-upload">
                        <ImageUpload
                            imageUrl={store.bannerHash ? resourcesURL(store.bannerHash) : ''}
                            onSelected={this.onStoreBannerSelected}
                            buttonText={t('global.buttons.upload')}
                            helpText={t('storeAdmin.editStore.labels.photos.bannerHelpText')}
                        />
                        {
                            isEditingStore && (
                                <Button
                                    text={t('global.buttons.remove')}
                                    styles="button--light-red button--smaller"
                                    disabled={store.bannerHash === undefined || store.bannerHash === ''}
                                    callback={() => this.onRemoveBannerPhoto()}
                                />
                            )
                        }
                    </div>
                </Row>

                <Row className="show-grid">
                    <Col xs={12} md={12}>
                        <FormTextField
                            label={t('admin.stores.labels.name')}
                            placeholder={t('admin.stores.labels.name')}
                            name="name"
                            value={store.name}
                            maxLength={60}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.name', null)}
                        />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col xs={12} md={12}>
                        <FormTextField
                            label={t('admin.stores.labels.description')}
                            placeholder={t('admin.stores.labels.description')}
                            name="description"
                            value={store.description}
                            onChange={this.onInputChange}
                            maxLength={120}
                            errors={get(formErrors, 'fields.description', null)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <FormTextField
                            label={t('admin.stores.labels.address')}
                            placeholder={t('admin.stores.labels.address')}
                            name="address"
                            value={store.address}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.address', null)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4}>
                        <FormTextField
                            label={t('admin.stores.labels.postalCode')}
                            placeholder={t('admin.stores.labels.postalCode')}
                            name="postalCode"
                            value={store.postalCode}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.postalCode', null)}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <FormTextField
                            label={t('admin.stores.labels.latitude')}
                            placeholder={t('admin.stores.labels.latitude')}
                            name="latitude"
                            value={store.latitude}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.latitude', null)}
                        />
                    </Col>
                    <Col xs={12} md={4}>
                        <FormTextField
                            label={t('admin.stores.labels.longitude')}
                            placeholder={t('admin.stores.labels.longitude')}
                            name="longitude"
                            value={store.longitude}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.longitude', null)}
                        />
                    </Col>
                </Row>
                <Row className="show-grid">
                    <Col xs={12} md={4}>
                        <FormTextField
                            label={t('admin.stores.labels.website')}
                            placeholder={t('admin.stores.labels.website')}
                            name="website"
                            value={store.website}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.website', null)}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={12} md={6}>
                        <FormTextField
                            label={t('admin.stores.labels.contact')}
                            placeholder={t('admin.stores.labels.contact')}
                            name="contact"
                            value={store.contact}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.contact', null)}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <FormTextField
                            label={t('admin.stores.labels.email')}
                            placeholder={t('admin.stores.labels.email')}
                            name="email"
                            value={store.email}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.email', null)}
                        />
                    </Col>
                    <Col xs={12} md={6} data-testid="contact-fee-container">
                        <FormSelectField
                            name="contactFeeType"
                            label={contactFeeTypeLabel}
                            placeholder={contactFeeTypeLabel}
                            value={store.contactFeeType || ''}
                            options={[
                                { value: '', label: contactFeeTypeLabel },
                                { value: ContactFeeType.MOBILE, label: t('contactFeeType.MOBILE') },
                                { value: ContactFeeType.LANDLINE, label: t('contactFeeType.LANDLINE') },
                            ]}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.contactFeeType', null)}
                        />
                    </Col>
                </Row>
                <hr />
            </>
        );
    }

    renderRemoteStoreModal = (): React.ReactNode => {
        const { t } = this.props;

        const {
            showRemoteStoreModal, isEditingStore, store, formErrors, categoriesOptions,
        } = this.state;

        return (
            <Modal show={showRemoteStoreModal} onHide={this.onCloseStoreModal} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {isEditingStore ? t('admin.stores.editRemoteStore') : t('admin.stores.addRemoteStore')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container modal-custom store-form">
                        {this.renderGeneralStoreInformation(store, formErrors)}
                        <Row className="show-grid">
                            <Col xs={12} md={8}>
                                <FormSelectField
                                    name="categoryId"
                                    label={t(adminStoresLabels.selectCategory)}
                                    placeholder={t(adminStoresLabels.selectCategory)}
                                    value={String(store.categoryId)}
                                    options={categoriesOptions}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, categoryIdFieldLabel, null)}
                                />
                            </Col>
                        </Row>
                        {isEditingStore && store.customStoreCategories && store.customStoreCategories?.length > 0 && (
                            <>
                                <Modal.Title>
                                    Mapear Categorias
                                </Modal.Title>
                                <hr />
                            </>
                        )}
                        {
                            isEditingStore && store.customStoreCategories && store.customStoreCategories.map((category, idx) => (
                                <Row key={category.id}>
                                    <Col xs={12} md={8}>
                                        <FormSelectField
                                            name="customStoreCategory[]"
                                            label={category.name}
                                            placeholder={t(adminStoresLabels.selectCategory)}
                                            value={String(category.categoryId)}
                                            options={categoriesOptions}
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => this.onInputChange(e, idx)}
                                            errors={get(formErrors, categoryIdFieldLabel, null)}
                                        />
                                    </Col>
                                </Row>
                            ))
                        }

                        {this.renderStoreSchedules(store, formErrors)}
                        <Row className="show-grid">
                            {store.weekSchedule.length < 14 && (
                                <Col xs={12} md={12} className="schedule-add-btn-wrapper">
                                    <Button
                                        text={t('admin.stores.labels.addSchedule')}
                                        styles="button--blue button--smaller"
                                        callback={this.onAddSchedule}
                                    />
                                </Col>
                            )}
                        </Row>
                    </div>
                </Modal.Body>
                <ModalFooter>
                    <Button
                        styles="button--blue button--small"
                        text={t(cancelButtonLabel)}
                        callback={this.onCloseStoreModal}
                    />
                    <Button
                        styles="button--dark-blue button--small"
                        text={t(okButtonLabel)}
                        callback={this.saveStore}
                    />
                </ModalFooter>
            </Modal>
        );
    }

    renderStoreModal = (): React.ReactNode => {
        const { t } = this.props;

        const {
            showStoreModal, isEditingStore, store, formErrors, categoriesOptions,
        } = this.state;

        return (
            <Modal show={showStoreModal} onHide={this.onCloseStoreModal} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {isEditingStore ? t('admin.stores.editStore') : t('admin.stores.addStore')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container modal-custom store-form">
                        {this.renderGeneralStoreInformation(store, formErrors)}
                        <Row>
                            {store.paymentInfoValid && (
                                <Col xs={12} lg={12}>
                                    <p className="info-bold">{t('admin.stores.paymentInfoValid')}</p>
                                </Col>
                            )}
                        </Row>
                        <Row className={store.paymentInfoValid ? 'payment-info-container' : ''}>
                            <Col xs={12} md={8}>
                                <FormTextField
                                    label={t('admin.stores.labels.iban')}
                                    placeholder={t('admin.stores.labels.iban')}
                                    name="iban"
                                    value={store.iban || null}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.iban', null)}
                                    disabled={store.paymentInfoValid}
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <FormTextField
                                    label={t('admin.stores.labels.nif')}
                                    placeholder={t('admin.stores.labels.nif')}
                                    name="nif"
                                    value={store.nif || null}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.nif', null)}
                                    disabled={store.paymentInfoValid}
                                />
                            </Col>
                        </Row>
                        <Row className={store.paymentInfoValid ? 'payment-info-container' : ''}>
                            <Col xs={12} md={12}>
                                <FormTextField
                                    label={t('admin.stores.labels.certCode')}
                                    placeholder={t('admin.stores.labels.certCode')}
                                    name="certCode"
                                    value={store.certCode || null}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.certCode', null)}
                                    disabled={store.paymentInfoValid}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4}>
                                <FormTextField
                                    label={t('admin.stores.labels.bicSwift')}
                                    placeholder={t('admin.stores.labels.bicSwift')}
                                    name="bicSwift"
                                    value={store.bicSwift || null}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.bicSwift', null)}
                                    disabled={store.paymentInfoValid}
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <FormTextField
                                    label={t('admin.stores.labels.location')}
                                    placeholder={t('admin.stores.labels.location')}
                                    name="location"
                                    value={store.location || null}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.location', null)}
                                    disabled={store.paymentInfoValid}
                                />
                            </Col>
                            <Col xs={12} md={4}>
                                <FormTextField
                                    label={t('admin.stores.labels.country')}
                                    placeholder={t('admin.stores.labels.country')}
                                    name="country"
                                    value={store.country || null}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.country', null)}
                                    disabled={store.paymentInfoValid}
                                />
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col xs={12} md={8}>
                                <FormSelectField
                                    name="categoryId"
                                    label={t(adminStoresLabels.selectCategory)}
                                    placeholder={t(adminStoresLabels.selectCategory)}
                                    value={String(store.categoryId)}
                                    options={categoriesOptions}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, categoryIdFieldLabel, null)}
                                />
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col xs={12} md={8}>
                                <FormCheckbox name="courier" value={store.courier === undefined ? false : store.courier} label={t('admin.stores.labels.courier')} onChange={this.onCourierChange} />
                            </Col>
                            <Col xs={12} md={8}>
                                <FormCheckbox name="allowVouchers" value={store.allowVouchers === undefined ? false : store.allowVouchers} label={t('admin.stores.labels.voucher')} onChange={this.onVoucherChange} />
                            </Col>
                        </Row>
                        <hr />
                        {this.renderStoreSchedules(store, formErrors)}
                        <Row className="show-grid">
                            {store.weekSchedule.length < 14 && (
                                <Col xs={12} md={12} className="schedule-add-btn-wrapper">
                                    <Button
                                        text={t('admin.stores.labels.addSchedule')}
                                        styles="button--blue button--smaller"
                                        callback={this.onAddSchedule}
                                    />
                                </Col>
                            )}
                        </Row>
                    </div>
                </Modal.Body>
                <ModalFooter>
                    <Button
                        styles="button--blue button--small"
                        text={t(cancelButtonLabel)}
                        callback={this.onCloseStoreModal}
                    />
                    <Button
                        styles="button--dark-blue button--small"
                        text={t(okButtonLabel)}
                        callback={this.saveStore}
                    />
                </ModalFooter>
            </Modal>
        );
    }

    renderUploadModal = (): React.ReactNode => {
        const { showUploadModal, store } = this.state;
        const { t } = this.props;
        return (
            <Modal show={showUploadModal} onHide={this.onUploadModalClose} centered size="lg" className="modal-custom">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('storeAdmin.products.addProduct')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Col xs={12} md={8}>
                        <p>{t('admin.stores.fileTypeInformation')}</p>
                        <FormTextField
                            name="productsFeedURL"
                            type="text"
                            value={String(store.productsFeedURL)}
                            placeholder="http://www.products.xyz/"
                            onChange={this.onInputChange}
                        />
                        <p>{t('admin.stores.upload')}</p>
                    </Col>

                </Modal.Body>
                <Modal.Footer>
                    <div className="footer-buttons-container">
                        <Button
                            styles="button--blue button--small"
                            text={t('global.buttons.cancel')}
                            callback={this.onUploadModalClose}
                        />
                        <Button
                            styles="button--dark-blue button--small"
                            text={t('storeAdmin.products.buttons.save')}
                            callback={this.onProductUploadSave}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }

    renderChangeStoreStateModal() {
        const {
            showChangeStoreStateModal, store, failedToDelete, isEnablingStore,
        } = this.state;
        const { t } = this.props;

        const removeText = !failedToDelete ? t('admin.stores.removeConfirmation', { name: store.name }) : t('admin.stores.disableConfirmation', { name: store.name });
        const confirmationCallback = !failedToDelete ? () => this.deleteStore(store.id) : () => this.changeStoreStatus(true);
        
        return (
            <Modal show={showChangeStoreStateModal} onHide={this.onDisableOrDeleteProductClose} centered size="lg" className="modal-custom">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {store.disabled && isEnablingStore ? t('admin.stores.enableStore') : t('admin.stores.deleteOrDisableStore') }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{ store.disabled && isEnablingStore ? t('admin.stores.restoreConfirmation', { name: store.name }) : removeText }</p>
                </Modal.Body>
                <Modal.Footer>
                    <div className="w-100 row justify-content-between">
                        <div className="footer-buttons-container">
                            <Button
                                styles="button--blue button--small"
                                text={t(globalButtonLabels.cancel)}
                                callback={this.onDisableOrDeleteProductClose}
                            />
                            <Button
                                styles="button--dark-blue button--small"
                                text={t(globalButtonLabels.ok)}
                                callback={() => (store.disabled && isEnablingStore ? this.changeStoreStatus(false) : confirmationCallback())}
                            />
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }

    render() {
        const { t, currentPage, onPagingChange } = this.props;
        const {
            isFetching,
            stores,
            _limit,
            totalResults,
            showErrorEuPagoDownModal,
            showUploadModal,
            showChangeStoreStateModal,
            store,
            searchString,
            sortField,
            sortOrder,
        } = this.state;

        const hasData = stores && stores.length > 0;

        return (
            <div className="app-tabs__tab-content">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="d-flex flex-row justify-content-between">
                    <FormTextField
                        placeholder="Pesquisar Lojas"
                        name="query"
                        value={searchString}
                        label="Pesquisar"
                        onChange={this.onFilterChange}
                    />
                    <div className="buttons-container__buttons">
                        <div className="button-wrapper">
                            <Button
                                styles="button--dark-blue button--small"
                                text={t('admin.stores.addStore')}
                                callback={this.onAddStoreClick}
                            />
                        </div>
                        <div className="button-wrapper">
                            <Button
                                styles="button--dark-blue button--small"
                                text={t('admin.stores.addRemoteStore')}
                                callback={this.onAddRemoteStoreClick}
                            />
                        </div>
                        <div className="button-wrapper">
                            <Button
                                styles="button--dark-blue button--small"
                                text={t('admin.stores.export')}
                                callback={this.downloadXLS}
                            />
                        </div>
                    </div>
                </div>
                <Table responsive="sm">
                    <thead>
                        <tr>
                            <th>
                                {t('admin.stores.headers.name')}
                                <SortArrow
                                    active={sortField === 'NAME'}
                                    ascending={sortOrder === 'ASC'}
                                    callback={() => { this.onSortChange('NAME', sortField === 'NAME' && sortOrder === 'ASC' ? 'DESC' : 'ASC'); }}
                                />
                            </th>
                            <th>{t('admin.stores.headers.contact')}</th>
                            <th>{t('admin.stores.headers.email')}</th>
                            <th>
                                {t('admin.stores.headers.category')}
                                <SortArrow
                                    active={sortField === 'CATEGORY'}
                                    ascending={sortOrder === 'ASC'}
                                    callback={() => { this.onSortChange('CATEGORY', sortField === 'CATEGORY' && sortOrder === 'ASC' ? 'DESC' : 'ASC'); }}
                                />
                            </th>
                            
                            <th>
                                {t('admin.stores.headers.paymentValid')}
                                <SortArrow
                                    active={sortField === 'PAYMENT'}
                                    ascending={sortOrder === 'ASC'}
                                    callback={() => { this.onSortChange('PAYMENT', sortField === 'PAYMENT' && sortOrder === 'ASC' ? 'DESC' : 'ASC'); }}
                                />
                            </th>
                            <th>
                                {t('admin.stores.headers.state')}
                                <SortArrow
                                    active={sortField === 'DISABLED'}
                                    ascending={sortOrder === 'ASC'}
                                    callback={() => { this.onSortChange('DISABLED', sortField === 'DISABLED' && sortOrder === 'ASC' ? 'DESC' : 'ASC'); }}
                                />
                            </th>
                        </tr>
                    </thead>
                    {hasData ? (
                        <tbody>
                            {Object.keys(stores).map(k => {
                                const storeAux = stores[Number(k)];

                                return (
                                    <tr key={storeAux.id} onClick={() => this.onStoreClick(storeAux)} className="clickable">
                                        <td>{storeAux.name}</td>
                                        <td>{storeAux.contact}</td>
                                        <td>{storeAux.email}</td>
                                        <td>{storeAux.category ? storeAux.category.description : ' - '}</td>
                                        {!storeAux.exhibition && (<td>{storeAux.paymentInfoValid ? t('global.yes') : t('global.no')}</td>)}
                                        {storeAux.exhibition && (
                                            <td>
                                                <div className="button-wrapper">
                                                    <Button
                                                        text="Carregar Produtos"
                                                        callback={e => this.onClickUploadButton(e, storeAux)}
                                                        styles="button--dark-blue"
                                                    />
                                                </div>
                                            </td>
                                        )}
                                        <td>
                                            <span className="trash-icon-cell">
                                                <SvgIcon icon={ICON.TRASH} callback={(e: React.MouseEvent) => (this.onChangeStoreStatus(e, storeAux))} />
                                            </span>
                                            { storeAux.disabled && (
                                                <span className="restore-icon-cell">
                                                    <SvgIcon icon={ICON.RESTORE} callback={(e: React.MouseEvent) => (this.onEnableStore(e, storeAux))} />
                                                </span>
                                            )
                                            }
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan={4}>
                                    {t('global.noData')}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
                {hasData && (
                    <TablePaging
                        currentPage={currentPage}
                        limit={_limit}
                        totalResults={totalResults}
                        onStartChange={onPagingChange}
                    />
                )}
                {/* STORE MODAL */}
                {!store.exhibition && this.renderStoreModal()}

                {/* REMOTE STORE MODAL */}
                {store.exhibition && this.renderRemoteStoreModal()}

                {showUploadModal && this.renderUploadModal()}
                { showChangeStoreStateModal && this.renderChangeStoreStateModal()}

                <Modal className="modal-custom" show={showErrorEuPagoDownModal} onHide={this.onCloseErrorModal} centered>
                    <ModalHeader className="success-modal-header" closeButton>
                        <ModalTitle>
                            <div className="success-modal">
                                <h2 className="my-2 red-text">{t('home.contact.error')}</h2>
                                <h5>{t('admin.stores.errorEuPagoDown')}</h5>
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                </Modal>
            </div>
        );
    }
}

export default withPaging(withTranslationContext(StoresAdminList));

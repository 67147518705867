/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { get, throttle } from 'lodash';
import Table from 'react-bootstrap/Table';
import { HexColorPicker } from 'react-colorful';
import 'react-colorful/dist/index.css';

import moment from 'moment';

import {
    Campaign,
    Category,
    CompoundSellable,
    DeliveryMethods,
    Product,
    Sellable,
    Store,
    Voucher,
} from '../../../constants/types';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import Loader from '../../elements/Loader';
import Button from '../../elements/Button';
import FormTextField from '../../elements/FormTextField';
import ImageUpload from '../../elements/ImageUpload';
import {
    getFormErrors, IFormError, validateField, VALIDATIONS,
} from '../../../utils/validation';
import {
    productCampaignEditURL,
    productCSVTemplateURL,
    productCSVUploadURL,
    productCSVURL,
    productGroupURL,
    productsAdminURL, productsCampaignsURL,
    productsDeleteURL,
    productsDisableURL,
    productURL,
} from '../../../services/products';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { categoriesPublicURL, subcategoriesPublicURL } from '../../../services/categories';
import { globalButtonLabels, SelectOption } from '../../../constants/misc';
import FormSelectField from '../../elements/FormSelectField';
import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import { tagsPublicURL } from '../../../services/tags';
import FormMultipleSelectField from '../../elements/FormMultipleSelectField';
import FormCheckbox from '../../elements/FormCheckbox';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import displayConfirm from '../../elements/displayConfirm';
import { resourcesURL } from '../../../services/resources';
import withPaging, { WithPagingProps } from '../../hocs/withPaging';
import TablePaging from '../../elements/TablePaging';
import {
    storeUploadURL, storeDeliveryMethodsURL, storesURL, storeURL,
} from '../../../services/stores';
import FormRadioButtons from '../../elements/FormRadioButtons';
import VoucherForm from './VoucherForm';
import FormTextAreaField from '../../elements/FormTextAreaField';
import { calculateDiscountPercentage } from '../../../utils/misc';
import { BackofficeTooltip } from '../../elements/BackofficeTooltip';
import { UserRoles } from '../../../constants/authorization';
import { campaignsAdminURL } from '../../../services/campaigns';
import { csvUploadErrorCodes } from '../../../constants/errorCodes';

interface OwnProps extends TranslationContext, AuthenticationContext, WithPagingProps { }

interface OwnState {
    isFetching: boolean;
    showProductModal: boolean;
    showUploadModal: boolean;
    showEditMultiple: boolean;
    showDisableOrDelete: boolean;
    uploadOption: string;
    csvFile: File | null;
    csvURL: string;
    isEditingProduct: boolean;
    product: Product;
    voucherToEdit: Voucher | null,
    initialProduct: Product | null;
    formErrors: any;
    categoriesOptions: Array<SelectOption>;
    actionsOptions: Array<SelectOption>;
    subcategoriesOptions: Array<SelectOption> | null;
    tagsOptions: any;
    products: Array<CompoundSellable>;
    productsVariants: Array<Product>;
    deliveryMethodsOptions: Array<SelectOption>;
    _limit: number;
    totalResults: number;
    hasUnsavedChanges: boolean;
    isCreatingProductVariant: boolean;
    parentProductName: string;
    initialGroupId: string | null;
    hasGroupIDchanged: boolean;
    photosToRemove: Array<string>;
    isStoreCourier: boolean;
    showVoucherModal: boolean;
    storeAllowVouchers: boolean;
    storeAllowHomeShipping: boolean;
    foodCategoryId: number | string;
    searchString: string;
    availableFilters: {
        categories: Array<SelectOption>;
        store: Array<SelectOption>;
        promotion: Array<SelectOption>;
        campaign: Array<SelectOption>;
    },
    selectedFilters: {
        category: number | null;
        store: number | null;
        promotion: string | null;
        campaign: number | null;
    },
    selectedProducts: Set<number>;
    selectAllProducts: boolean;
}

const initialState: OwnState = {
    isFetching: false,
    showProductModal: false,
    showUploadModal: false,
    showEditMultiple: false,
    showDisableOrDelete: false,
    uploadOption: '',
    csvFile: null,
    csvURL: '',
    isEditingProduct: false,
    formErrors: null,
    categoriesOptions: [],
    actionsOptions: [],
    isCreatingProductVariant: false,
    parentProductName: '',
    subcategoriesOptions: null,
    productsVariants: [],
    deliveryMethodsOptions: [],
    tagsOptions: [],
    products: [],
    _limit: 25,
    totalResults: 0,
    hasUnsavedChanges: false,
    initialGroupId: null,
    initialProduct: null,
    hasGroupIDchanged: false,
    voucherToEdit: null,
    product: {
        id: -1,
        description: '',
        shortDescription: '',
        categoryId: null,
        visibleOnlyOnGroup: false,
        groupId: null,
        subCategoryId: null,
        storeId: null,
        inPromotion: false,
        lastPrice: null,
        price: null,
        reference: '',
        tags: [],
        thumbnail: null,
        photos: null,
        brand: '',
        thumbnailHash: '',
        thumbnailUrl: '',
        photosHash: [],
        tax: null,
        packingWeight: null,
        inStock: false,
        color: '',
        colorHex: '',
        size: '',
        length: null,
        width: null,
        height: null,
        availableDeliveryMethods: [],
        storeInfo: {
            id: -1,
            name: '',
            slug: '',
        },
    },
    photosToRemove: [],
    isStoreCourier: false,
    showVoucherModal: false,
    storeAllowVouchers: false,
    storeAllowHomeShipping: false,
    foodCategoryId: -1,
    searchString: '',
    availableFilters: {
        categories: [],
        store: [{
            value: null,
            label: 'Selecionar',
        }],
        promotion: [{
            value: null,
            label: 'Selecionar',
        }],
        campaign: [{
            value: null,
            label: 'Selecionar',
        }],
    },
    selectedFilters: {
        category: null,
        store: null,
        promotion: null,
        campaign: null,
    },
    selectedProducts: new Set<number>(),
    selectAllProducts: false,

};

const promotionSelectOptions: Array<SelectOption> = [
    {
        label: 'Todas',
        value: 'null',
    },
    {
        label: 'Em Promoção',
        value: 'true',
    },
    {
        label: 'Sem Promoção',
        value: 'false',
    },
];

const deliveryMethodsLabels = {
    private: 'enums.deliveryMethods.PRIVATE',
    thirdParty: 'enums.deliveryMethods.THIRD_PARTY',
    courier: 'enums.deliveryMethods.COURIER',
};

const createErrorLabel = 'storeAdmin.products.createError';
const subcategoryLabel = 'storeAdmin.products.labels.subcategory';
const categoryLabel = 'storeAdmin.products.labels.category';
const nullSelectOptionValue = 'null';

class ProductsAdmin extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    private throttledGetProducts = () => { };

    componentDidMount(): void {
        this.getFilters();
        this.getProducts();
        this.getStoreShippingStatuses();
        this.getActions();
        this.throttledGetProducts = throttle(this.getProducts, 900);
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const { currentPage } = this.props;
        const { currentPage: oldCurrentPage } = prevProps;

        const hasPagingChanged: boolean = currentPage !== oldCurrentPage;

        if (hasPagingChanged) {
            this.getProducts();
        }
    }

    getActions = () => {
        const { user, t } = this.props;
        const { selectedProducts, storeAllowVouchers } = this.state;
        const isSuperAdmin = user?.groups[0] === UserRoles.ADMIN;

        const actions = [{
            label: t('storeAdmin.products.options'),
            value: '',
        },
        {
            label: t('storeAdmin.products.resetFilters'),
            value: 'resetFilters',
        },
        ];
        if (storeAllowVouchers) {
            actions.push({
                label: t('storeAdmin.vouchers.addVoucher'),
                value: 'addVoucher',
            });
        }
        if (!isSuperAdmin) {
            actions.push({
                label: t('storeAdmin.products.massAdd'),
                value: 'addMultipleProducts',
            }, {
                label: t('storeAdmin.products.addProduct'),
                value: 'addProduct',
            });
            if (selectedProducts.size > 0) {
                actions.push({
                    label: t('storeAdmin.products.removeProducts'),
                    value: 'removeProducts',
                });
            }
        } else if (isSuperAdmin) {
            actions.push({
                label: t('admin.stores.export'),
                value: 'exportProducts',
            });
        }

        this.setState({
            actionsOptions: actions,
        });
    }

    getFilters = () => {
        const { user } = this.props;
        const { availableFilters } = this.state;

        const urls = [categoriesPublicURL()];
        const role = user?.groups[0];

        if (role === UserRoles.STORE_MANAGER && user) {
            urls.push(productsCampaignsURL({ storeId: user.store?.id }));
        } else if (role === UserRoles.ADMIN) {
            urls.push(campaignsAdminURL());
            urls.push(storesURL({ list: true, pageSize: 9999 }));
        }

        axios.all(urls.map(url => axios.get(url)))
            .then(axios.spread((categoriesResponse, campaignsResponse, storesResponse) => {
                const defaultValue: SelectOption = {
                    value: '-1',
                    label: 'Selecionar',
                };
                const categoriesMap = categoriesResponse.data.map((cat: Category) => {
                    return {
                        value: cat.id,
                        label: cat.description,
                    };
                });

                let storesMap = [];
                if (storesResponse) {
                    storesMap = storesResponse.data.map((str: Store) => {
                        return {
                            value: str.id,
                            label: str.name,
                        };
                    });
                }

                let campaignsMap = [];

                if (role === UserRoles.STORE_MANAGER) {
                    campaignsMap = campaignsResponse.data.map((cam: Array<number | string>) => {
                        return {
                            value: cam[0],
                            label: cam[1],
                        };
                    });
                } else if (role === UserRoles.ADMIN) {
                    campaignsMap = campaignsResponse.data.map((cam: Campaign) => {
                        return {
                            value: cam.id,
                            label: cam.name,
                        };
                    });
                }

                this.setState({
                    availableFilters: {
                        ...availableFilters,
                        promotion: promotionSelectOptions,
                        categories: [defaultValue, ...categoriesMap],
                        store: [defaultValue, ...storesMap],
                        campaign: [defaultValue, ...campaignsMap],
                    },
                });
            }))
            .catch(error => {
                displayNotification(NOTIFICATION_TYPE.ERROR, 'Erro a carregar filtros');
            });
    }

    onProductThumbnailSelected = (file: File) => {
        this.setState({
            ...this.state,
            hasUnsavedChanges: true,
            product: {
                ...this.state.product,
                thumbnail: file,
            },
        });
    };

    onProductImageSelected = (file: File, idx: number) => {
        const prevState = this.state;
        const { product } = this.state;
        let { photos } = product;

        if (photos) {
            photos[idx] = file;
        } else {
            photos = [file];
        }

        this.setState({
            ...prevState,
            hasUnsavedChanges: true,
            product: {
                ...product,
                photos,
            },
        });
    };

    openUploadModal = () => {
        this.setState({
            showUploadModal: true,
            isEditingProduct: false,
        });
    };

    onAddProduct = () => {
        this.setState({
            showProductModal: true,
            isEditingProduct: false,
            product: initialState.product,
        }, () => this.getTagsCategoriesAndDeliveryMethods());
    };

    onAddVoucher = () => {
        this.setState({
            showVoucherModal: true,
        });
    };

    onVoucherModalClose = () => {
        this.setState({
            showVoucherModal: false,
            voucherToEdit: null,
        });
    }

    onProductModalClose = () => {
        this.setState({
            showProductModal: false,
            isEditingProduct: false,
            subcategoriesOptions: null,
            hasUnsavedChanges: false,
            isCreatingProductVariant: false,
            hasGroupIDchanged: false,
            productsVariants: [],
            formErrors: null,
            product: {
                ...initialState.product,
            },
        });
    };

    onUploadModalClose = () => {
        this.setState({
            showUploadModal: false,
        });
    };

    onEditMultipleProductClose = () => {
        this.setState({
            showEditMultiple: false,
        });
    };

    onDisableOrDeleteProductClose = () => {
        this.setState({
            showDisableOrDelete: false,
        });
    };

    onMultiSelectChange = (name: string, e: any) => {
        this.setState({
            ...this.state,
            hasUnsavedChanges: true,
            product: {
                ...this.state.product,
                [name]: e,
            },
        });
    };

    onCSVInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;

        this.setState({
            csvURL: value,
        });
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { product, deliveryMethodsOptions } = this.state;
        const { t } = this.props;
        const { name, value } = e.currentTarget;

        if (name === 'colorHex') {
            this.setState({
                ...this.state,
                hasUnsavedChanges: true,
                product: {
                    ...product,
                    colorHex: value,
                },
            });

            return;
        }

        if (name === 'inPromotion') {
            this.setState({
                ...this.state,
                hasUnsavedChanges: true,
                product: {
                    ...this.state.product,
                    inPromotion: !this.state.product.inPromotion,
                },
            });

            return;
        }

        if (name === 'inStock') {
            this.setState({
                ...this.state,
                hasUnsavedChanges: true,
                product: {
                    ...this.state.product,
                    inStock: !this.state.product.inStock,
                },
            });

            return;
        }

        if (name === 'groupId') {
            this.setState({
                hasGroupIDchanged: true,
                hasUnsavedChanges: true,
                product: {
                    ...product,
                    groupId: value,
                },
            });
            return;
        }
        if (name === 'price') {
            if (value.length > 0 && validateField(name, value, VALIDATIONS.PRODUCT_FORM)) {
                return;
            }
        }

        let newProduct = {};
        let newDeliveryMethodsOptions: Array<SelectOption> = [...deliveryMethodsOptions];

        if (['width', 'length', 'height', 'packingWeight'].includes(name)) {
            if ((name === 'packingWeight' && Number(value) <= 30) || (Number(value) <= 150 && name !== 'packingWeight')) {
                let alreadyInOptions = false;
                deliveryMethodsOptions.forEach(d => { if (d.value === DeliveryMethods.THIRD_PARTY) return alreadyInOptions = true; });
                if (!alreadyInOptions && this.isValidForThirdParty(product)) newDeliveryMethodsOptions = [...deliveryMethodsOptions, { value: DeliveryMethods.THIRD_PARTY, label: t(deliveryMethodsLabels.thirdParty) }];
            } else {
                newProduct = { availableDeliveryMethods: [{ value: DeliveryMethods.PRIVATE, label: t(deliveryMethodsLabels.private) }] };
                newDeliveryMethodsOptions = [{ value: DeliveryMethods.PRIVATE, label: t(deliveryMethodsLabels.private) }];
            }
        }

        newProduct = { ...newProduct, [e.currentTarget.name]: e.currentTarget.value };

        this.setState({
            ...this.state,
            hasUnsavedChanges: true,
            product: {
                ...this.state.product,
                ...newProduct,
            },
            deliveryMethodsOptions: newDeliveryMethodsOptions,
        }, () => {
            if (name === 'categoryId') {
                this.getSubcategories();
            }
        });
    };

    onAddProductVariant = (): void => {
        const { t } = this.props;
        const {
            hasUnsavedChanges, product, initialProduct, hasGroupIDchanged,
        } = this.state;

        if ((!product.groupId || String(product.groupId).trim() === '') && initialProduct && product.groupId === initialProduct.groupId) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('storeAdmin.products.doesNotHaveGroupId'));
            return;
        }

        if (hasGroupIDchanged) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('storeAdmin.products.needsToSaveChangesBecauseGroupID'));
            return;
        }

        if (hasUnsavedChanges) {
            displayConfirm({
                acceptButtonText: t('global.buttons.ok'),
                onAccept: () => this.showAddProductVariant(true),
                onReject: () => { },
                rejectButtonText: t(globalButtonLabels.cancel),
                message: t('storeAdmin.products.hasUnsavedChangesMessage'),
            });
            return;
        }

        this.showAddProductVariant();
    }

    onVoucherClick = (voucher: Voucher) => {
        this.setState({
            voucherToEdit: voucher,
            showVoucherModal: true,
        });
    };

    onVoucherSave = () => {
        this.setState({
            voucherToEdit: null,
            showVoucherModal: false,
        }, () => {
            this.getProducts();
        });
    };

    onProductClick = (product: Product) => {
        const { category, subCategory } = product;
        let categoryId: number | string | null = null;
        if (category) {
            categoryId = category.id;
        }

        let subCategoryId: number | string | null = null;
        if (subCategory) {
            subCategoryId = subCategory.id;
        }

        const tagsAsSelectOptions: Array<SelectOption> = [];
        Object.keys(product.tags).forEach(k => {
            const tag: any = product.tags[Number(k)];
            tagsAsSelectOptions.push({
                value: tag.id,
                label: tag.description,
            });
        });

        const bannersAsSelectOptions = this.productBannersToSelectOptions(product);

        this.setState({
            showProductModal: true,
            isEditingProduct: true,
            initialGroupId: product.groupId,
            product: {
                ...initialState.product,
                ...product,
                categoryId,
                tags: tagsAsSelectOptions,
                thumbnailUrl: resourcesURL(product.thumbnailHash),
                subCategoryId,
                banners: bannersAsSelectOptions,
            },
            initialProduct: {
                ...initialState.product,
                ...product,
                categoryId,
                tags: tagsAsSelectOptions,
                thumbnailUrl: resourcesURL(product.thumbnailHash),
                subCategoryId,
            },
        }, () => {
            this.getTagsCategoriesAndDeliveryMethods();
            this.getProductVariants();
            if (subCategory) {
                this.getSubcategories(true);
            }
        });
    };

    onDeleteSellable = (e: React.MouseEvent, product: Product, productVariant = false) => {
        e.stopPropagation();
        this.setState({
            showDisableOrDelete: true,
            product,
        });
    };

    onRestoreSellable = (e: React.MouseEvent, product: Sellable) => {
        e.stopPropagation();
        const { t } = this.props;
        const { voucher } = product;
        const confirmMessage = voucher ? 'storeAdmin.vouchers.removeConfirmation' : 'storeAdmin.products.restoreConfirmation';

        displayConfirm({
            acceptButtonText: t('global.buttons.ok'),
            onAccept: () => this.changeProductStatus(product.id, false),
            onReject: () => { },
            rejectButtonText: t(globalButtonLabels.cancel),
            message: t(confirmMessage, { name: product.shortDescription }),
        });
    };

    showAddProductVariant = (resetChanges = false) => {
        const { product, initialProduct } = this.state;

        if (resetChanges && initialProduct) {
            this.setState({
                isCreatingProductVariant: true,
                isEditingProduct: false,
                parentProductName: product.shortDescription,
                product: {
                    ...initialProduct,
                    thumbnail: null,
                    thumbnailHash: '',
                    thumbnailUrl: '',
                    photos: [],
                    photosHash: [],
                },
                photosToRemove: [],
            });
        } else {
            this.setState({
                isCreatingProductVariant: true,
                isEditingProduct: false,
                parentProductName: product.shortDescription,
                product: {
                    ...product,
                    thumbnail: null,
                    thumbnailHash: '',
                    thumbnailUrl: '',
                    photosHash: [],
                },
                photosToRemove: [],
            });
        }
    }

    deleteProduct = (id: string | number, productVariant: boolean) => {
        const { t } = this.props;
        this.setState({ isFetching: true });

        axios.delete(productsDeleteURL(id))
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('storeAdmin.products.removeSuccess'));
                this.setState({
                    showDisableOrDelete: false,
                    isFetching: false,
                }, () => {
                    this.getProducts();
                    if (productVariant) {
                        this.getProductVariants();
                    }
                });
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('storeAdmin.products.removeError'));
                this.setState({
                    showDisableOrDelete: true,
                    isFetching: false,
                });
            });
        
        this.setState({ selectedProducts: new Set<number>() });
    };

    changeProductStatus = (id: string | number, disable: boolean) => {
        const { t } = this.props;
        this.setState({ isFetching: true });

        axios.put(productsDisableURL(), { [String(id)]: disable })
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t(disable ? 'storeAdmin.products.disableSuccess' : 'storeAdmin.products.restoreSuccess'));
                this.setState({
                    isFetching: false,
                    showDisableOrDelete: false,
                }, () => {
                    this.getProducts();
                });
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t(disable ? 'storeAdmin.products.disableError' : 'storeAdmin.products.restoreError'));
                this.setState({
                    isFetching: false,
                    showDisableOrDelete: false,
                });
            });

        this.setState({ selectedProducts: new Set<number>() });
    };

    deleteMultipleProducts = () => {
        const { t } = this.props;
        const { selectedProducts } = this.state;
        this.setState({ isFetching: true, showEditMultiple: false });

        axios.delete(productsDeleteURL(Array.from(selectedProducts)))
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('storeAdmin.products.removeMultipleSuccess'));
                this.setState({
                    isFetching: false,
                }, () => {
                    this.getProducts();
                });
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('storeAdmin.products.removeMultipleError'));
                this.setState({
                    isFetching: false,
                });
            });

        this.setState({ selectedProducts: new Set<number>() });
    }

    changeStatusMultipleProducts = (disabled : boolean) => {
        const { t } = this.props;
        const { selectedProducts } = this.state;
        this.setState({ isFetching: true, showEditMultiple: false });

        const fields = Object.fromEntries(Array.from(selectedProducts).map(id => [id, disabled]));
        
        axios.put(productsDisableURL(), fields)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t(disabled ? 'storeAdmin.products.disableMultipleSuccess' : 'storeAdmin.products.enableMultipleSuccess'));
                this.setState({
                    isFetching: false,
                }, () => {
                    this.getProducts();
                });
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t(disabled ? 'storeAdmin.products.disableMultipleError' : 'storeAdmin.products.enableMultipleError'));
                this.setState({
                    isFetching: false,
                });
            });

        this.setState({ selectedProducts: new Set<number>() });
    }

    saveProduct = () => {
        const { t, user } = this.props;
        const {
            product, isEditingProduct, isCreatingProductVariant, photosToRemove,
        } = this.state;

        const actualBanners: Array<number> = [];
        if (product.banners) {
            const bannersAux = [...product.banners];
            Object.keys(product.banners).forEach(k => {
                const banner: any = bannersAux[Number(k)];
                actualBanners.push(banner.value);
            });
        }

        if (user?.groups[0] === UserRoles.STORE_MANAGER && this.validateFields()) {
            const formData = new FormData();

            const actualTags: Array<string> = [];
            Object.keys(product.tags).forEach(k => {
                const tag: any = product.tags[Number(k)];

                actualTags.push(tag.label);
            });

            const actualDeliveryMethods: Array<string> = [];
            Object.keys(product.availableDeliveryMethods).forEach(k => {
                const delivery: any = product.availableDeliveryMethods[Number(k)];

                actualDeliveryMethods.push(delivery.value);
            });

            const fields = {
                description: String(product.description).trim(),
                shortDescription: String(product.shortDescription).trim(),
                categoryId: product.categoryId,
                packingWeight: String(product.packingWeight).trim(),
                subCategoryId: product.subCategoryId || undefined,
                reference: String(product.reference).trim(),
                price: String(product.price).trim(),
                groupId: product.groupId ? String(product.groupId).trim() : '',
                lastPrice: product.lastPrice && String(product.lastPrice).trim() !== '' ? product.lastPrice : null,
                brand: String(product.brand).trim(),
                tax: String(product.tax).trim(),
                size: String(product.size).trim(),
                color: String(product.color).trim(),
                colorHex: String(product.colorHex).trim(),
                length: String(product.length).trim(),
                width: String(product.width).trim(),
                height: String(product.height).trim(),
                inStock: product.inStock,
                inPromotion: product.inPromotion || false,
                visibleOnlyOnGroup: product.visibleOnlyOnGroup || false,
                storeId: user && user.store ? user.store.id : null,
                tags: [...actualTags],
                availableDeliveryMethods: [...actualDeliveryMethods],
                photosToRemove,
                discountStart: product.inPromotion ? moment(product.discountStart).unix() : null,
                discountEnd: product.inPromotion ? moment(product.discountEnd).set({
                    hour: 23, minute: 59, second: 59, millisecond: 999,
                }).unix() : null,
                discountPrice: product.inPromotion ? product.discountPrice : null,
                banners: [...actualBanners],
            };

            if (product.thumbnail) {
                formData.append('thumbnail', product.thumbnail, product.thumbnail.name);
            }

            if (product.photos && product.photos.length > 0) {
                product.photos.forEach((el, idx) => {
                    formData.append(`additionalPhotos[${idx}]`, el, el.name);
                });
            }

            formData.append('product', new Blob([JSON.stringify(fields)], {
                type: 'application/json',
            }));

            if (isEditingProduct && !isCreatingProductVariant) this.editProductRequest(formData);
            else this.createProductRequest(formData);
        } else if (user?.groups[0] === UserRoles.ADMIN) {
            this.saveCampaignsRequest(actualBanners);
        } else {
            if (isEditingProduct) displayNotification(NOTIFICATION_TYPE.ERROR, t('storeAdmin.products.editFieldsError'));
            else displayNotification(NOTIFICATION_TYPE.ERROR, t('storeAdmin.products.createFieldsError'));
        }
    };

    saveCampaignsRequest = (actualBanners: Array<number>) => {
        const { product } = this.state;
        this.setState({
            isFetching: true,
        });
        axios.put(productCampaignEditURL(product.id), actualBanners)
            .then(() => {
                this.setState({
                    isEditingProduct: false,
                    showProductModal: false,
                }, () => {
                    this.getProducts();
                });
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, 'Erro a editar campanhas do produto.');
            })
            .finally(() => {
                this.setState({
                    isFetching: false,
                });
            });
    }

    editProductRequest = (formData: FormData) => {
        const { t } = this.props;
        const { product, isEditingProduct, initialProduct } = this.state;
        this.setState({ isFetching: true });

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        axios.put(productURL(product.id), formData, config)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('storeAdmin.products.editSuccess'));

                if (isEditingProduct) {
                    this.setState({
                        isFetching: false,
                        isEditingProduct: false,
                        showProductModal: false,
                        subcategoriesOptions: null,
                        hasUnsavedChanges: false,
                        hasGroupIDchanged: false,
                        photosToRemove: [],
                    }, () => {
                        this.getProducts();
                        this.getProductVariants();

                        if (initialProduct && initialProduct.groupId !== product.groupId) {
                            this.getProductVariants();
                        }
                    });
                } else {
                    this.setState({
                        product: {
                            ...initialState.product,
                        },
                        isFetching: false,
                        isEditingProduct: false,
                        showProductModal: false,
                        subcategoriesOptions: null,
                        hasUnsavedChanges: false,
                        photosToRemove: [],
                    }, () => this.getProducts());
                }
            })
            .catch(error => {
                if (error.response) {
                    const { data, status } = error.response;
                    if (data && data.result) {
                        if (data.result === 'MISSING PAYMENT FIELDS ON STOR') {
                            displayNotification(NOTIFICATION_TYPE.ERROR, t('storeAdmin.products.completeStoreMissingInfoEdit'));
                            this.setState({
                                isFetching: false,
                            });
                        } else {
                            displayNotification(NOTIFICATION_TYPE.ERROR, t(createErrorLabel));
                            this.setState({
                                isFetching: false,
                            });
                        }
                    } else {
                        let responseError;
                        if (status === 413) {
                            responseError = { fields: { thumbnail: [{ typeOfViolation: 'FileSize' }] } };
                        }
                        displayNotification(NOTIFICATION_TYPE.ERROR, t(createErrorLabel));
                        this.handleResponse(responseError || error.response.data);
                    }
                } else {
                    displayNotification(NOTIFICATION_TYPE.ERROR, t(createErrorLabel));
                    this.setState({
                        isFetching: false,
                    });
                }
            });
    };

    createProductRequest = (formData: FormData) => {
        const { t } = this.props;
        this.setState({ isFetching: true });

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        axios.post(productsAdminURL(), formData, config)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('storeAdmin.products.createSuccess'));

                this.setState({
                    product: {
                        ...initialState.product,
                    },
                    isFetching: false,
                    isEditingProduct: false,
                    showProductModal: false,
                    hasUnsavedChanges: false,
                    subcategoriesOptions: null,
                    isCreatingProductVariant: false,
                    photosToRemove: [],
                }, () => this.getProducts());
            })
            .catch(error => {
                if (error.response) {
                    const { data } = error.response;
                    if (data && data.result) {
                        if (data.result === 'MISSING PAYMENT FIELDS ON STORE') {
                            displayNotification(NOTIFICATION_TYPE.ERROR, t('storeAdmin.products.completeStoreMissingInfo'));
                            this.setState({
                                isFetching: false,
                            });
                        } else if (data.errorCode === 21) {
                            displayNotification(NOTIFICATION_TYPE.ERROR, t('storeAdmin.products.slugInUseError'));
                            const responseError = { fields: { shortDescription: [{ typeOfViolation: 'SlugTaken' }] } };
                            this.handleResponse(responseError);
                            this.setState({
                                isFetching: false,
                            });
                        } else {
                            displayNotification(NOTIFICATION_TYPE.ERROR, t(createErrorLabel));
                            this.setState({
                                isFetching: false,
                            });
                        }
                    } else {
                        displayNotification(NOTIFICATION_TYPE.ERROR, t(createErrorLabel));
                        this.handleResponse(error.response.data);
                    }
                } else {
                    displayNotification(NOTIFICATION_TYPE.ERROR, t(createErrorLabel));
                    this.setState({
                        isFetching: false,
                    });
                }
            });
    };

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    validateFields = () => {
        let errors: IFormError | null = getFormErrors(this.state.product, VALIDATIONS.PRODUCT_FORM);
        const { product, isEditingProduct, foodCategoryId } = this.state;

        if (!errors) errors = {};
        if (!isEditingProduct && !product.thumbnail) {
            errors.thumbnail = [{ typeOfViolation: 'NotBlank' }];
        }
        if (!product.availableDeliveryMethods || product.availableDeliveryMethods.length === 0) {
            errors.availableDeliveryMethods = [{ typeOfViolation: 'NotBlank' }];
        }
        if (product.colorHex !== '' && product.color === '') {
            errors.color = [{ typeOfViolation: 'NotBlank' }];
        }
        if (Number(product.categoryId) === Number(foodCategoryId) && product.description.length === 0) {
            errors.description = [{ typeOfViolation: 'NotBlank' }];
        }
        if (product.inPromotion) {
            if (!product.discountPrice || String(product.discountPrice).trim() === '') {
                errors.discountPrice = [{ typeOfViolation: 'NotBlank' }];
            } else if (product.price && Number(product.discountPrice) >= Number(product.price)) {
                errors.discountPrice = [{ typeOfViolation: 'discountPriceTooHigh' }];
            }
            if (!product.discountStart) errors.discountStart = [{ typeOfViolation: 'NotBlank' }];
            if (!product.discountEnd) errors.discountEnd = [{ typeOfViolation: 'NotBlank' }];
            if (product.discountStart && product.discountEnd) {
                const momentStart = moment(product.discountStart);
                const momentEnd = moment(product.discountEnd);

                if (momentEnd.isBefore(momentStart)) {
                    errors.discountEnd = [{ typeOfViolation: 'afterStartDate' }];
                }

                if (momentEnd.diff(momentStart, 'days') + 1 > 30) {
                    errors.discountEnd = [{ typeOfViolation: 'noMoreThan30Days' }];
                    errors.discountStart = [{ typeOfViolation: 'noMoreThan30Days' }];
                }
            }
        }

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    getProductVariants = () => {
        const { user } = this.props;
        const { product } = this.state;

        const isUserAdmin = user?.groups[0] === UserRoles.ADMIN;

        if (!isUserAdmin && product && product.groupId && String(product.groupId).trim() !== '') {
            this.setState({ isFetching: true });
            axios.get(productGroupURL(product.groupId))
                .then(response => {
                    const { data } = response;
                    this.setState({
                        isFetching: false,
                        productsVariants: [...data],
                    });
                })
                .catch(() => {
                    this.setState({
                        isFetching: false,
                    });
                });
        }
    }

    getSubcategories = (isEdit = false) => {
        const { t } = this.props;
        const { product } = this.state;

        this.setState({ isFetching: true });

        axios.get(subcategoriesPublicURL({ categoryId: product.categoryId }))
            .then(response => {
                const subcategoriesOptions: Array<SelectOption> = [];
                if (response && response.data && response.data.length > 0) {
                    subcategoriesOptions.push({
                        value: '',
                        label: t(subcategoryLabel),
                    });

                    Object.keys(response.data).forEach(k => {
                        const c = response.data[Number(k)];
                        subcategoriesOptions.push({
                            value: c.id,
                            label: c.description,
                        });
                    });
                }

                this.setState({
                    isFetching: false,
                    subcategoriesOptions: subcategoriesOptions.length > 0 ? [...subcategoriesOptions] : null,
                    product: {
                        ...this.state.product,
                        subCategoryId: isEdit ? product.subCategoryId : null,
                    },
                });
            })
            .catch(() => {
                this.setState({ isFetching: false });
            });
    };

    isValidForThirdParty = (product: Product): boolean => {
        return !(product && ((product.packingWeight || 0) > 30 || (product.length || 0) > 150 || (product.width || 0) > 150 || (product.height || 0) > 150));
    }

    getFoodCategoryID = () => {
        const { categoriesOptions } = this.state;

        const foodCategoryElement = categoriesOptions.find(el => {
            return String(el.label).toUpperCase().search('ALIMENTAÇÃO') !== -1;
        });

        if (foodCategoryElement) {
            this.setState({
                foodCategoryId: foodCategoryElement.value,
            });
        }
    }

    getTagsCategoriesAndDeliveryMethods = (): void => {
        const { t } = this.props;
        const {
            isFetching, product, isStoreCourier, storeAllowHomeShipping,
        } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        const urls = [categoriesPublicURL(), tagsPublicURL({ keyword: '*' }), storeDeliveryMethodsURL()];

        const deliveryMethodsAsSelectOptions: Array<SelectOption> = [];
        Object.keys(product.availableDeliveryMethods).forEach(k => {
            const delivery = product.availableDeliveryMethods[Number(k)];
            if (delivery === DeliveryMethods.EMAIL || (!storeAllowHomeShipping && delivery === DeliveryMethods.HOME)) return;
            if (isStoreCourier) {
                if (delivery !== DeliveryMethods.THIRD_PARTY) {
                    deliveryMethodsAsSelectOptions.push({
                        value: delivery,
                        label: t(`enums.deliveryMethods.${delivery}`),
                    });
                }
            } else {
                if (delivery !== DeliveryMethods.COURIER) {
                    deliveryMethodsAsSelectOptions.push({
                        value: delivery,
                        label: t(`enums.deliveryMethods.${delivery}`),
                    });
                }
            }
        });

        axios.all(urls.map(url => axios.get(url)))
            .then(axios.spread((categoriesResponse, tagsResponse, deliveryMethodsResponse) => {
                const categoriesOptions: Array<SelectOption> = [];
                if (categoriesResponse && categoriesResponse.data) {
                    categoriesOptions.push({
                        value: '',
                        label: t(categoryLabel),
                    });

                    Object.keys(categoriesResponse.data).forEach(k => {
                        const c = categoriesResponse.data[Number(k)];
                        categoriesOptions.push({
                            value: c.id,
                            label: c.description,
                        });
                    });
                }

                const tagsOptions: Array<any> = [];
                if (tagsResponse && tagsResponse.data) {
                    Object.keys(tagsResponse.data).forEach(k => {
                        const c = tagsResponse.data[Number(k)];
                        tagsOptions.push({
                            value: Number(k),
                            label: c,
                        });
                    });
                }

                const deliveryMethodsOptions: Array<SelectOption> = [];
                if (deliveryMethodsResponse && deliveryMethodsResponse.data) {
                    Object.keys(deliveryMethodsResponse.data).forEach(k => {
                        const delivery: any = deliveryMethodsResponse.data[Number(k)];
                        if (delivery === DeliveryMethods.EMAIL || (!storeAllowHomeShipping && delivery === DeliveryMethods.HOME) || (delivery === DeliveryMethods.THIRD_PARTY && !this.isValidForThirdParty(product))) {
                            return;
                        }
                        if (isStoreCourier) {
                            if (delivery !== DeliveryMethods.THIRD_PARTY) {
                                deliveryMethodsOptions.push({
                                    value: delivery,
                                    label: t(`enums.deliveryMethods.${delivery}`),
                                });
                            }
                        } else {
                            if (delivery !== DeliveryMethods.COURIER) {
                                deliveryMethodsOptions.push({
                                    value: delivery,
                                    label: t(`enums.deliveryMethods.${delivery}`),
                                });
                            }
                        }
                    });
                }

                this.setState({
                    isFetching: false,
                    tagsOptions,
                    categoriesOptions,
                    deliveryMethodsOptions,
                    product: {
                        ...product,
                        availableDeliveryMethods: deliveryMethodsAsSelectOptions,
                    },
                }, () => {
                    this.getFoodCategoryID();
                });
            }))
            .catch(() => {
                this.setState({ isFetching: false });
            });
    };

    getStoreShippingStatuses = async () => {
        const { user } = this.props;

        if (user && user.store) {
            const { data: { courier, allowVouchers, deliveryData } } = await axios.get(storeURL(user.store.id));
            
            const storeAllowHomeShipping: boolean = deliveryData !== undefined
                && deliveryData.latitude && deliveryData.latitude !== ''
                && deliveryData.longitude && deliveryData.longitude !== ''
                && deliveryData.distanceMax && deliveryData.distanceMax !== ''
                && deliveryData.shippingPriceMin !== undefined && deliveryData.shippingPriceMin !== '';

            this.setState({
                isStoreCourier: courier,
                storeAllowVouchers: allowVouchers,
                storeAllowHomeShipping,
            });
        }
    }

    getProducts = async () => {
        const { currentPage, onPagingChange, user } = this.props;
        const {
            isFetching, _limit, searchString, selectedFilters,
        } = this.state;

        if (isFetching && !user) return;

        this.setState({ isFetching: true, selectAllProducts: false });
        const url = productsAdminURL({
            pageSize: _limit,
            page: currentPage - 1,
            q: searchString === '' ? null : searchString,
            banner: selectedFilters.campaign,
            storeId: selectedFilters.store,
            categoryId: selectedFilters.category,
            promotion: selectedFilters.promotion === nullSelectOptionValue ? null : selectedFilters.promotion,
            time: selectedFilters.promotion === nullSelectOptionValue ? null : moment().unix(),
            sort: 'createdDate',
            order: 'desc',
        });

        try {
            const { data, headers } = await axios.get(url);
            const newTotalResults: number = parseInt(headers['x-total-count']);

            if (data && data.length === 0 && currentPage !== 1) {
                this.setState({ isFetching: false }, () => {
                    onPagingChange(currentPage - 1);
                    this.getProducts();
                });
            } else {
                this.setState({
                    isFetching: false,
                    products: data.map((prod: Product) => {
                        return {
                            ...prod,
                            discountStart: prod.discountStart ? moment.unix(Number(prod.discountStart)).format('YYYY-MM-DD') : null,
                            discountEnd: prod.discountEnd ? moment.unix(Number(prod.discountEnd)).format('YYYY-MM-DD') : null,
                        };
                    }),
                    totalResults: newTotalResults,
                });
            }
        } catch (e) {
            this.setState({ isFetching: false });
        }
    }

    productBannersToSelectOptions = (product: Product): Array<SelectOption> => {
        const bannerSelectOpts: Array<SelectOption> = [];

        if (product.banners) {
            product.banners.forEach((el: any) => {
                const bannerAux: SelectOption = {
                    value: el.id,
                    label: el.name,
                };

                bannerSelectOpts.push(bannerAux);
            });
        }

        return bannerSelectOpts;
    }

    getModalTitle = (): string => {
        const { t } = this.props;
        const { isEditingProduct, isCreatingProductVariant, parentProductName } = this.state;

        if (isCreatingProductVariant) {
            return t('storeAdmin.products.addProductVariant', { productName: parentProductName });
        }

        if (isEditingProduct) {
            return t('storeAdmin.products.editProduct');
        }

        return t('storeAdmin.products.addProduct');
    }

    setColorHex = (colorHex: string): void => {
        const { user } = this.props;
        const { product } = this.state;

        if (user?.groups[0] === UserRoles.ADMIN) {
            return;
        }

        if (colorHex !== '#NaNNaNNaN') {
            this.setState({
                product: {
                    ...product,
                    colorHex,
                },
            });
        } else {
            this.setState({
                product: {
                    ...product,
                    colorHex: '#ffffff',
                },
            });
        }
    }

    onRemovePhoto = (idx: number) => {
        const prevState = this.state;
        const { product, photosToRemove } = this.state;
        const { photosHash, photos } = product;

        if (photosHash && photosToRemove) {
            const photoHashSplit = photosHash[idx].split('/');
            const photoHashFileName = photoHashSplit[photoHashSplit.length - 1];

            photosToRemove.push(photoHashFileName);
            photosHash.splice(idx, 1);
        }

        if (photos) {
            photos.splice(idx, 1);
        }

        this.setState({
            ...prevState,
            product: {
                ...product,
                photosHash,
                photos,
            },
            photosToRemove,
        });
    }

    onAddPhoto = () => {
        const prevState = this.state;
        const { product } = this.state;
        const { photosHash } = product;

        photosHash.push('');
        this.setState({
            ...prevState,
            product: {
                ...product,
                photosHash,
            },
        });
    }

    onUploadCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
            currentTarget: {
                files,
            },
        } = event;

        let file: File | null = null;
        if (files && files[0]) {
            file = files[0];

            this.setState({
                csvFile: file,
            });
        }
    };

    onProductUploadSave = () => {
        const { user } = this.props;
        const { csvFile, csvURL, uploadOption } = this.state;

        let axiosRequestURL = '';
        let axiosRequestData = {};
        let axiosRequestConfig = {};

        this.setState({
            isFetching: true,
        });

        if (uploadOption === 'URL' && csvURL !== '' && user && user.store) {
            axiosRequestURL = storeUploadURL(user.store.id, csvURL, 'CSV');
        }

        if (uploadOption === 'FILE' && csvFile && user && user.store) {
            const formData = new FormData();
            formData.append('file', csvFile);

            axiosRequestConfig = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            axiosRequestURL = productCSVUploadURL(user.store.id);
            axiosRequestData = formData;
        }

        axios.post(axiosRequestURL, axiosRequestData, axiosRequestConfig).then(() => {
            displayNotification(NOTIFICATION_TYPE.SUCCESS, 'CSV carregado com sucesso!');
        }).catch(error => {
            switch (error.response?.data?.errorCode) {
                case csvUploadErrorCodes.DISCOUNT_PRICE_IS_EQUAL_TO_NORMAL_PRICE:
                    displayNotification(NOTIFICATION_TYPE.ERROR, 'Erro: Um dos produtos carregados tem o preço de desconto igual ao preço base.');
                    break;
                case csvUploadErrorCodes.PROMOTION_TOO_LONG:
                    displayNotification(NOTIFICATION_TYPE.ERROR, 'Erro: Um dos produtos carregados tem uma promoção de duração superior a 30 dias.');
                    break;
                case csvUploadErrorCodes.DISCOUNT_PRICE_IS_HIGHER_THAN_NORMAL_PRICE:
                    displayNotification(NOTIFICATION_TYPE.ERROR, 'Erro: Um dos produtos carregados tem o preço de desconto superior ao preço base.');
                    break;
                case csvUploadErrorCodes.CATEGORY_NOT_FOUND:
                    displayNotification(NOTIFICATION_TYPE.ERROR, 'Erro: Um dos produtos carregados está associado a uma categoria inexistente.');
                    break;
                case csvUploadErrorCodes.STORE_NOT_FOUND:
                    displayNotification(NOTIFICATION_TYPE.ERROR, 'Erro: Um dos produtos carregados está associado a uma loja inexistente.');
                    break;
                default:
                    displayNotification(NOTIFICATION_TYPE.ERROR, 'Erro no upload de produto.');
            }
        }).finally(() => {
            this.setState({
                isFetching: false,
            });
        });
    }

    downloadProductTemplate = (): void => {
        axios({
            url: productCSVTemplateURL(),
            method: 'GET',
            responseType: 'blob',
            headers: {
                accept: 'application/octet-stream',
            },
        }).then(response => {
            if (response.headers['content-disposition']) {
                const link = document.createElement('a');

                link.href = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1].replaceAll('"', ''));
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        });
    }

    checkInPromotion = (product: Product): boolean => {
        if (product.inPromotion) {
            const { discountStart, discountEnd } = product;
            const startFormatted = moment(discountStart);
            const endFormatted = moment(discountEnd);

            return moment().isBetween(startFormatted, endFormatted, 'day', '[]');
        }
        return false;
    }

    buildPromotionString = (product: Product): string => {
        const { t } = this.props;
        const { inPromotion, discountStart, discountEnd } = product;

        if (inPromotion && discountStart && discountEnd) {
            return t('storeAdmin.products.labels.promotionRange', { start: discountStart, end: discountEnd });
        }
        return '';
    }

    onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { onPagingChange } = this.props;
        const { name, value } = e.currentTarget;

        onPagingChange(1);
        if (name === 'query') {
            this.setState({
                searchString: value,
            }, () => {
                this.throttledGetProducts();
            });
        } else {
            const { selectedFilters } = this.state;
            this.setState({
                selectedFilters: {
                    ...selectedFilters,
                    [name]: value === '-1' ? null : value,
                },
            }, this.getProducts);
        }
    }

    onActionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;

        switch (value) {
            case 'resetFilters':
                this.resetFilters();
                break;
            case 'addProduct':
                this.onAddProduct();
                break;
            case 'addMultipleProducts':
                this.openUploadModal();
                break;
            case 'addVoucher':
                this.onAddVoucher();
                break;
            case 'removeProducts':
                this.removeMultipleProducts();
                break;
            case 'exportProducts':
                window.open(productCSVURL());
                break;
            default:
                break;
        }
    }

    onSelectProduct = (id : number) => {
        const { selectedProducts } = this.state;
        
        if (selectedProducts.has(id)) {
            selectedProducts.delete(id);
        } else {
            selectedProducts.add(id);
        }

        this.setState({
            ...this.state,
            selectedProducts,
        }, () => this.getActions());
    }

    onSelectAll = () => {
        const { products, selectAllProducts, selectedProducts } = this.state;
        const pageProductsIds = products.map(product => product.id);
        const selectAllProductsAux = !selectAllProducts;
        if (selectAllProductsAux) {
            pageProductsIds.forEach(id => selectedProducts.add(id));
        } else {
            pageProductsIds.forEach(id => selectedProducts.delete(id));
        }
        this.setState({
            ...this.state,
            selectedProducts,
            selectAllProducts: selectAllProductsAux,
        }, () => this.getActions());
    }

    removeMultipleProducts = () => {
        this.setState({ showEditMultiple: true });
    }

    resetFilters = () => {
        const { onPagingChange } = this.props;
        this.setState({
            selectedFilters: initialState.selectedFilters,
            searchString: '',
        }, async () => {
            await this.getProducts();
            onPagingChange(1);
        });
    }

    renderFilters = () => {
        const { user } = this.props;
        const {
            availableFilters, selectedFilters, searchString,
        } = this.state;

        return (
            <>
                <FormTextField
                    name="query"
                    value={searchString}
                    label="Pesquisar"
                    onChange={this.onFilterChange}
                />
                <FormSelectField
                    name="category"
                    label="Categoria"
                    value={String(selectedFilters.category)}
                    options={availableFilters.categories}
                    onChange={this.onFilterChange}
                />
                {user?.groups[0] === UserRoles.ADMIN && (
                    <FormSelectField
                        name="store"
                        label="Loja"
                        value={String(selectedFilters.store)}
                        options={availableFilters.store}
                        onChange={this.onFilterChange}
                    />
                )}
                <FormSelectField
                    name="promotion"
                    label="Em Promoção"
                    value={String(selectedFilters.promotion)}
                    options={availableFilters.promotion}
                    onChange={this.onFilterChange}
                />
                <FormSelectField
                    name="campaign"
                    label="Campanha"
                    value={String(selectedFilters.campaign)}
                    options={availableFilters.campaign}
                    onChange={this.onFilterChange}
                />
            </>
        );
    }

    renderEditMultipleProductsStatusModal() {
        const { showEditMultiple, selectedProducts } = this.state;
        const { t } = this.props;
        return (
            <Modal show={showEditMultiple} onHide={this.onEditMultipleProductClose} centered size="lg" className="modal-custom">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('storeAdmin.products.editMultipleModal')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('storeAdmin.products.removeMultipleConfirmation', { num: selectedProducts.size })}</p>
                </Modal.Body>
                <Modal.Footer>
                    <div className="w-100 row justify-content-between">
                        <div className="footer-buttons-container">
                            <Button
                                styles="button--blue button--small"
                                text={t(globalButtonLabels.cancel)}
                                callback={this.onEditMultipleProductClose}
                            />
                            <Button
                                styles="button--dark-blue button--small"
                                text={t('storeAdmin.products.removeProducts')}
                                callback={this.deleteMultipleProducts}
                            />
                            <Button
                                styles="button--dark-blue button--small"
                                text={t('storeAdmin.products.disableProducts')}
                                callback={event => this.changeStatusMultipleProducts(true)}
                            />
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }

    renderDisableOrDeleteProductModal() {
        const { showDisableOrDelete, product } = this.state;
        const { t } = this.props;
        return (
            <Modal show={showDisableOrDelete} onHide={this.onDisableOrDeleteProductClose} centered size="lg" className="modal-custom">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('storeAdmin.products.editMultipleModal')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('storeAdmin.products.removeConfirmation', { name: product.shortDescription })}</p>
                </Modal.Body>
                <Modal.Footer>
                    <div className="w-100 row justify-content-between">
                        <div className="footer-buttons-container">
                            <Button
                                styles="button--blue button--small"
                                text={t(globalButtonLabels.cancel)}
                                callback={this.onDisableOrDeleteProductClose}
                            />
                            <Button
                                styles="button--dark-blue button--small"
                                text={t('storeAdmin.products.removeProducts')}
                                callback={() => this.deleteProduct(product.id, false)}
                            />
                            <Button
                                styles="button--dark-blue button--small"
                                text={t('storeAdmin.products.disableProducts')}
                                callback={() => this.changeProductStatus(product.id, true)}
                            />
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }

    render() {
        const {
            t, currentPage, onPagingChange, user,
        } = this.props;
        const {
            isFetching,
            product,
            uploadOption,
            showProductModal,
            showUploadModal,
            showVoucherModal,
            showEditMultiple,
            showDisableOrDelete,
            voucherToEdit,
            isEditingProduct,
            formErrors,
            categoriesOptions,
            subcategoriesOptions,
            deliveryMethodsOptions,
            isCreatingProductVariant,
            products,
            tagsOptions,
            _limit,
            totalResults,
            productsVariants,
            csvURL,
            storeAllowVouchers,
            foodCategoryId,
            availableFilters,
            selectedProducts,
            selectAllProducts,
            selectedFilters,
            actionsOptions,
        } = this.state;

        const hasData = products && products.length > 0;

        const csvUploadOptions = [
            {
                label: 'Upload de ficheiro',
                value: 'FILE',
            },
            {
                label: 'Especifique um URL',
                value: 'URL',
            },
        ];

        const isFoodCategory = Number(product.categoryId) === Number(foodCategoryId);
        const isSuperAdmin = user?.groups[0] === UserRoles.ADMIN;

        return (
            <div className="app-tabs__tab-content products-admin">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="buttons-container">
                    <div className="mr-3 buttons-container__filters">
                        {this.renderFilters()}
                    </div>
                    <div className="buttons-container__filters">
                        <FormSelectField
                            name="action"
                            label=" "
                            value={String(selectedFilters.campaign)}
                            options={actionsOptions}
                            onChange={this.onActionChange}
                            customClass="buttons-container__filters--action-select"
                        />
                    </div>
                </div>
                <Table responsive="sm">
                    <thead>
                        <tr>
                            <th>{t('storeAdmin.products.headers.shortDescription')}</th>
                            <th>{t('storeAdmin.products.headers.category')}</th>
                            {isSuperAdmin && <th>{t('storeAdmin.products.headers.storeName')}</th>}
                            <th>{t('storeAdmin.products.headers.price')}</th>
                            <th>{t('storeAdmin.products.headers.inPromotion')}</th>
                            { !isSuperAdmin && (
                                <th>
                                    <input
                                        className="checkbox--products"
                                        name="allProducts"
                                        checked={selectAllProducts}
                                        onChange={this.onSelectAll}
                                        type="checkbox"
                                    />
                                </th>
                            ) }
                            <th />
                        </tr>
                    </thead>
                    {hasData ? (
                        <tbody>
                            {products.map(prod => {
                                const clickHandler = !prod.voucher ? this.onProductClick : this.onVoucherClick;

                                return (
                                    <tr key={prod.id} onClick={() => clickHandler(prod)} className="clickable">
                                        <td>{prod.shortDescription}</td>
                                        <td>{prod.category ? prod.category.description : ' - '}</td>
                                        {isSuperAdmin && <td>{prod.storeInfo?.name ? prod.storeInfo?.name : 'N.A.'}</td>}
                                        <td>{`${this.checkInPromotion(prod) ? prod.discountPrice : prod.price} €`}</td>
                                        <td>{this.checkInPromotion(prod) ? `${t('global.yes')} ${this.buildPromotionString(prod)}` : t('global.no')}</td>
                                        {!isSuperAdmin && (
                                            <>
                                                <td>
                                                    <input
                                                        className="checkbox--products"
                                                        name="product"
                                                        checked={selectedProducts.has(prod.id)}
                                                        onChange={() => this.onSelectProduct(prod.id)}
                                                        type="checkbox"
                                                    />
                                                </td>
                                                <td className={`${prod.disabled ? 'restore-icon-cell' : 'trash-icon-cell'}`}>
                                                    <SvgIcon icon={prod.disabled ? ICON.RESTORE : ICON.TRASH} callback={(e: React.MouseEvent) => (prod.disabled ? this.onRestoreSellable(e, prod) : this.onDeleteSellable(e, prod))} />
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan={4}>
                                    {t('global.noData')}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
                {hasData && (
                    <TablePaging
                        currentPage={currentPage}
                        limit={_limit}
                        totalResults={totalResults}
                        onStartChange={onPagingChange}
                    />
                )}
                <Modal show={showVoucherModal} onHide={this.onVoucherModalClose} centered size="lg">
                    <VoucherForm
                        saveCallback={this.onVoucherSave}
                        closeCallback={this.onVoucherModalClose}
                        onVoucherClickCallback={this.onVoucherClick}
                        onVoucherDeleteCallback={this.onDeleteSellable}
                        voucherEdit={voucherToEdit}
                        isVoucherAllowed={storeAllowVouchers}
                    />
                </Modal>
                <Modal show={showProductModal} onHide={this.onProductModalClose} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.getModalTitle()}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container modal-custom product-form">
                            <p>{t('storeAdmin.products.headers.mainPhoto')}</p>
                            <Row>
                                <ImageUpload
                                    imageUrl={product.thumbnailUrl}
                                    onSelected={this.onProductThumbnailSelected}
                                    buttonText={t('global.buttons.upload')}
                                    helpText={t('storeAdmin.products.labels.photos.imageHelpText')}
                                    errors={get(formErrors, 'fields.thumbnail', null)}
                                    disabled={isSuperAdmin}
                                />
                            </Row>
                            <p>{t('storeAdmin.products.headers.photos')}</p>
                            <div className="upload-container">
                                {product.photosHash.map((photo, idx) => {
                                    return (
                                        <div className="upload-container__upload-component">
                                            <ImageUpload
                                                imageUrl={(photo && photo !== '') ? `${photo}` : ''}
                                                onSelected={(file: File) => this.onProductImageSelected(file, idx)}
                                                buttonText={t('global.buttons.upload')}
                                                disabled={isSuperAdmin}
                                            />
                                            <Button
                                                text={t('global.buttons.remove')}
                                                styles="button--light-red button--smaller"
                                                callback={() => this.onRemovePhoto(idx)}
                                                disabled={isSuperAdmin}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            {product.photosHash && product.photosHash.length < 9 && (
                                <Row className="show-grid">
                                    <Col xs={12} md={12} className="schedule-add-btn-wrapper">
                                        <Button
                                            text={t('storeAdmin.products.buttons.addPhoto')}
                                            styles="button--blue button--smaller"
                                            callback={this.onAddPhoto}
                                            disabled={isSuperAdmin}
                                        />
                                    </Col>
                                </Row>
                            )}
                            {isSuperAdmin && (
                                <Row className="show-grid">
                                    <Col xs={12} md={12}>
                                        <FormMultipleSelectField
                                            name="banners"
                                            value={product.banners}
                                            options={availableFilters.campaign}
                                            onChange={this.onMultiSelectChange}
                                            label="Campanha(s)"
                                            errors={get(formErrors, 'fields.campaigns', null)}
                                        />
                                    </Col>
                                </Row>
                            )}
                            <Row className="show-grid">
                                <Col xs={12} md={6}>
                                    <FormTextField
                                        label={t('storeAdmin.products.labels.shortDescription')}
                                        placeholder={t('storeAdmin.products.labels.shortDescription')}
                                        name="shortDescription"
                                        value={product.shortDescription}
                                        onChange={this.onInputChange}
                                        maxLength={60}
                                        disabled={isSuperAdmin}
                                        errors={get(formErrors, 'fields.shortDescription', null)}
                                    />
                                </Col>
                            </Row>
                            <Row className="show-grid">
                                <Col xs={12} md={6}>
                                    <FormSelectField
                                        name="categoryId"
                                        label={t(categoryLabel)}
                                        placeholder={t(categoryLabel)}
                                        value={String(product.categoryId)}
                                        options={categoriesOptions}
                                        onChange={this.onInputChange}
                                        disabled={isSuperAdmin}
                                        errors={get(formErrors, 'fields.categoryId', null)}
                                    />
                                </Col>
                                {subcategoriesOptions && (
                                    <Col xs={12} md={6}>
                                        <FormSelectField
                                            name="subCategoryId"
                                            label={t(subcategoryLabel)}
                                            placeholder={t(subcategoryLabel)}
                                            value={String(product.subCategoryId)}
                                            options={subcategoriesOptions}
                                            onChange={this.onInputChange}
                                            disabled={isSuperAdmin}
                                            errors={get(formErrors, 'fields.subCategoryId', null)}
                                        />
                                    </Col>
                                )}
                            </Row>
                            <Row className="show-grid">
                                <Col xs={12} md={12}>
                                    <div className="d-flex align-content-end">
                                        <p className="mb-2 mr-2">{t('storeAdmin.products.labels.description')}</p>
                                        {isFoodCategory && <BackofficeTooltip text={t('storeAdmin.products.tooltip')} />}
                                    </div>
                                    <FormTextAreaField
                                        placeholder={t('storeAdmin.products.labels.description')}
                                        name="description"
                                        value={product.description}
                                        onChange={this.onInputChange}
                                        maxLength={1000}
                                        rows={6}
                                        disabled={isSuperAdmin}
                                        errors={get(formErrors, 'fields.description', null)}
                                    />
                                </Col>
                            </Row>
                            <Row className="show-grid">
                                <Col xs={12} md={4}>
                                    <FormTextField
                                        label={t('storeAdmin.products.labels.brand')}
                                        placeholder={t('storeAdmin.products.labels.brand')}
                                        name="brand"
                                        value={product.brand}
                                        onChange={this.onInputChange}
                                        maxLength={60}
                                        disabled={isSuperAdmin}
                                        errors={get(formErrors, 'fields.brand', null)}
                                    />
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormTextField
                                        label={t('storeAdmin.products.labels.reference')}
                                        placeholder={t('storeAdmin.products.labels.reference')}
                                        name="reference"
                                        value={product.reference}
                                        onChange={this.onInputChange}
                                        maxLength={60}
                                        disabled={isSuperAdmin}
                                        errors={get(formErrors, 'fields.reference', null)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={4} lg={4} sm={6}>
                                    <FormTextField
                                        label={t('storeAdmin.products.labels.size')}
                                        placeholder={t('storeAdmin.products.labels.size')}
                                        name="size"
                                        value={product.size}
                                        onChange={this.onInputChange}
                                        disabled={isSuperAdmin}
                                        errors={get(formErrors, 'fields.size', null)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={4} lg={4} sm={6}>
                                    <div>
                                        {t('storeAdmin.products.labels.color')}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={4} lg={4} sm={6}>
                                    <HexColorPicker color={product.colorHex} onChange={this.setColorHex} />
                                </Col>
                                <Col xs={12} md={4} lg={4} sm={6}>
                                    <FormTextField
                                        label={`${t('storeAdmin.products.labels.colorName')} (${product.colorHex})`}
                                        placeholder={t('storeAdmin.products.labels.color')}
                                        name="color"
                                        value={product.color}
                                        onChange={this.onInputChange}
                                        disabled={isSuperAdmin}
                                        errors={get(formErrors, 'fields.color', null)}
                                    />
                                    <FormTextField
                                        name="colorHex"
                                        value={product.colorHex}
                                        onChange={this.onInputChange}
                                        disabled={isSuperAdmin}
                                        errors={get(formErrors, 'fields.colorHex', null)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={3} lg={3} sm={6}>
                                    <FormTextField
                                        label={t('storeAdmin.products.labels.price')}
                                        placeholder={t('storeAdmin.products.labels.price')}
                                        name="price"
                                        value={product.price}
                                        onChange={this.onInputChange}
                                        maxLength={30}
                                        disabled={isSuperAdmin}
                                        errors={get(formErrors, 'fields.price', null)}
                                    />
                                </Col>
                                <Col xs={12} md={4} lg={4} sm={6}>
                                    <FormTextField
                                        label={t('storeAdmin.products.labels.packingWeight')}
                                        placeholder={t('storeAdmin.products.labels.packingWeight')}
                                        name="packingWeight"
                                        value={product.packingWeight || ''}
                                        onChange={this.onInputChange}
                                        maxLength={50}
                                        disabled={isSuperAdmin}
                                        errors={get(formErrors, 'fields.packingWeight', null)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8}>
                                    <span className="mb-2 mr-2">{t('storeAdmin.products.maxDimensions')}</span>
                                    <BackofficeTooltip text={t('storeAdmin.products.dimensionsTooltip')} />
                                </Col>

                            </Row>
                            <Row>
                                <Col xs={12} md={4} lg={4} sm={6} />
                            </Row>
                            <Row>
                                <Col xs={12} md={3} lg={3} sm={6}>
                                    <FormTextField
                                        label={t('storeAdmin.products.labels.length')}
                                        placeholder={t('storeAdmin.products.labels.length')}
                                        name="length"
                                        value={product.length}
                                        onChange={this.onInputChange}
                                        maxLength={30}
                                        disabled={isSuperAdmin}
                                        errors={get(formErrors, 'fields.length', null)}
                                    />
                                </Col>
                                <Col xs={12} md={3} lg={3} sm={6}>
                                    <FormTextField
                                        label={t('storeAdmin.products.labels.width')}
                                        placeholder={t('storeAdmin.products.labels.width')}
                                        name="width"
                                        value={product.width}
                                        onChange={this.onInputChange}
                                        maxLength={30}
                                        disabled={isSuperAdmin}
                                        errors={get(formErrors, 'fields.width', null)}
                                    />
                                </Col>
                                <Col xs={12} md={4} lg={4} sm={6}>
                                    <FormTextField
                                        label={t('storeAdmin.products.labels.height')}
                                        placeholder={t('storeAdmin.products.labels.height')}
                                        name="height"
                                        value={product.height || ''}
                                        onChange={this.onInputChange}
                                        maxLength={50}
                                        disabled={isSuperAdmin}
                                        errors={get(formErrors, 'fields.height', null)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={3} className="mb-0">
                                    <FormCheckbox
                                        name="inStock"
                                        value={product.inStock}
                                        onChange={this.onInputChange}
                                        disabled={isSuperAdmin}
                                        label={t('storeAdmin.products.labels.inStock')}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={3} className="mb-0">
                                    <FormCheckbox
                                        name="inPromotion"
                                        value={product.inPromotion}
                                        onChange={this.onInputChange}
                                        disabled={isSuperAdmin}
                                        label={t('storeAdmin.products.labels.inPromotion')}
                                    />
                                </Col>
                            </Row>
                            {product.inPromotion && (
                                <>
                                    <br />
                                    <Row>
                                        <Col xs={12} md={3} lg={3} sm={6}>
                                            <FormTextField
                                                label={t('storeAdmin.products.labels.discountPrice')}
                                                placeholder={t('storeAdmin.products.labels.discountPrice')}
                                                name="discountPrice"
                                                value={product.discountPrice || null}
                                                onChange={this.onInputChange}
                                                maxLength={30}
                                                disabled={isSuperAdmin}
                                                errors={get(formErrors, 'fields.discountPrice', null)}
                                            />
                                        </Col>
                                        <Col xs={12} md={3} lg={3} sm={6}>
                                            <FormTextField
                                                label={t('storeAdmin.products.labels.discountStart')}
                                                placeholder={t('storeAdmin.products.labels.discountStart')}
                                                name="discountStart"
                                                type="date"
                                                value={product.discountStart || ''}
                                                onChange={this.onInputChange}
                                                disabled={isSuperAdmin}
                                                errors={get(formErrors, 'fields.discountStart', null)}
                                            />
                                        </Col>
                                        <Col xs={12} md={3} lg={3} sm={6}>
                                            <FormTextField
                                                label={t('storeAdmin.products.labels.discountEnd')}
                                                placeholder={t('storeAdmin.products.labels.discountEnd')}
                                                name="discountEnd"
                                                type="date"
                                                value={product.discountEnd || ''}
                                                onChange={this.onInputChange}
                                                disabled={isSuperAdmin}
                                                errors={get(formErrors, 'fields.discountEnd', null)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <div className="d-flex align-content-end">
                                                <p className="mb-2 mr-2">{t('storeAdmin.products.labels.discountPercent')}</p>
                                                <BackofficeTooltip text={t('storeAdmin.products.labels.discountPercentTooltip')} />
                                            </div>
                                            <FormTextField
                                                name="percentField"
                                                placeholder={t('storeAdmin.products.labels.discountPercentPlaceholder')}
                                                disabled={isSuperAdmin}
                                                value={calculateDiscountPercentage(product)}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}
                            <Row className="show-grid">
                                <Col xs={12} md={12}>
                                    <FormMultipleSelectField
                                        name="availableDeliveryMethods"
                                        value={product.availableDeliveryMethods}
                                        options={deliveryMethodsOptions}
                                        onChange={this.onMultiSelectChange}
                                        label={t('storeAdmin.editStore.labels.deliveryMethods')}
                                        placeholder={t('storeAdmin.editStore.labels.deliveryMethods')}
                                        disabled={isSuperAdmin}
                                        errors={get(formErrors, 'fields.availableDeliveryMethods', null)}
                                    />
                                </Col>
                            </Row>
                            <Row className="show-grid mt-2">
                                <Col xs={12} md={12}>
                                    <FormMultipleSelectField
                                        name="tags"
                                        value={product.tags}
                                        options={tagsOptions}
                                        onChange={this.onMultiSelectChange}
                                        label={t('storeAdmin.products.labels.tags')}
                                        disabled={isSuperAdmin}
                                        placeholder={t('storeAdmin.products.labels.tags')}
                                    />
                                </Col>
                            </Row>
                            {isEditingProduct && (
                                <React.Fragment>
                                    <Row>
                                        <Col xs={12} md={3}>
                                            <FormTextField
                                                label={t('storeAdmin.products.labels.groupId')}
                                                placeholder={t('storeAdmin.products.labels.groupId')}
                                                name="groupId"
                                                value={product.groupId}
                                                onChange={this.onInputChange}
                                                maxLength={80}
                                                errors={get(formErrors, 'fields.groupId', null)}
                                                disabled={isCreatingProductVariant || isSuperAdmin}
                                            />
                                        </Col>
                                        <Col xs={12} md={4} className="button-align-form">
                                            <Button
                                                styles="button--blue button--small"
                                                text={t('storeAdmin.products.labels.addProductVariant')}
                                                callback={this.onAddProductVariant}
                                                disabled={isSuperAdmin}
                                            />
                                        </Col>
                                    </Row>
                                    {productsVariants.length > 0 && (
                                        <ul>
                                            <h5 className="small-title">{t('storeAdmin.products.labels.productVariants')}</h5>
                                            {Object.keys(productsVariants).map(k => {
                                                const p = productsVariants[Number(k)];

                                                return (
                                                    <li key={p.id} className={`list-group-item variant-item ${p.id === product.id ? 'disabled' : ''}`} onClick={() => this.onProductClick(p)}>
                                                        <div className="d-flex w-100 justify-content-between">
                                                            <p className={p.id === product.id ? 'bold' : ''}>{p.shortDescription}</p>
                                                            {p.id !== product.id && (
                                                                <SvgIcon icon={ICON.TRASH} callback={(e: React.MouseEvent) => this.onDeleteSellable(e, p, true)} />
                                                            )}
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            styles="button--blue button--small"
                            text={t(globalButtonLabels.cancel)}
                            callback={this.onProductModalClose}
                        />
                        <Button
                            styles="button--dark-blue button--small"
                            text={t('storeAdmin.products.buttons.save')}
                            callback={this.saveProduct}
                        />
                    </Modal.Footer>
                </Modal>
                <Modal show={showUploadModal} onHide={this.onUploadModalClose} centered size="lg" className="modal-custom">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.getModalTitle()}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormRadioButtons
                            name="Método de upload"
                            options={csvUploadOptions}
                            onChange={opt => {
                                this.setState({
                                    uploadOption: opt,
                                });
                            }}
                        />
                        {uploadOption === 'FILE' && (
                            <input
                                type="file"
                                onChange={this.onUploadCSV}
                            />
                        )}
                        {uploadOption === 'URL' && (
                            <Col xs={12} md={8}>
                                <FormTextField
                                    name="csvURL"
                                    type="text"
                                    value={csvURL}
                                    placeholder="http://www.products.xyz/"
                                    onChange={this.onCSVInputChange}
                                />
                                <p>{t('admin.stores.upload')}</p>
                            </Col>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="w-100 row justify-content-between">
                            <Button
                                styles="button--blue-link"
                                text={t('storeAdmin.editStore.labels.downloadTemplate')}
                                callback={() => this.downloadProductTemplate()}
                            />
                            <div className="footer-buttons-container">
                                <Button
                                    styles="button--blue button--small"
                                    text={t(globalButtonLabels.cancel)}
                                    callback={this.onUploadModalClose}
                                />
                                <Button
                                    styles="button--dark-blue button--small"
                                    text={t('storeAdmin.products.buttons.save')}
                                    callback={this.onProductUploadSave}
                                />
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
                { showEditMultiple && this.renderEditMultipleProductsStatusModal() }
                { showDisableOrDelete && this.renderDisableOrDeleteProductModal() }
            </div>
        );
    }
}

export default withPaging(withAuthenticationContext(withTranslationContext(ProductsAdmin)));

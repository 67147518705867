import React, { Component } from 'react';
import ErrorMessage from './ErrorMessage';

interface OwnProps {
    name: string;
    value: string | number | null;
    onChange?: Function;
    label?: string;
    containerStyles?: string;
    placeholder?: string;
    fieldStyles?: string;
    errors?: any;
    disabled?: boolean;
    required?: boolean;
    rows?: number;
    maxLength?: number;
}

class FormTextAreaField extends Component<OwnProps> {
    renderErrors = () => {
        const { name, errors } = this.props;
        if (errors) {
            return (
                <ErrorMessage errors={errors} field={name} />
            );
        }
        return <React.Fragment />;
    };

    render() {
        const {
            name,
            value,
            onChange,
            label,
            disabled,
            placeholder,
            errors,
            required,
            fieldStyles,
            rows,
            maxLength,
        } = this.props;

        return (
            <div className="form-group">
                {label && (
                    <label htmlFor={name}>{label}</label>
                )}
                <textarea
                    name={name}
                    value={value || ''}
                    rows={rows || 2}
                    placeholder={placeholder}
                    className={errors ? `form-control is-invalid ${fieldStyles}` : `form-control ${fieldStyles}`}
                    onChange={onChange ? e => onChange(e) : () => {}}
                    disabled={disabled || false}
                    required={required || false}
                    maxLength={maxLength}
                />
                <p className="error-field">{this.renderErrors()}</p>
            </div>
        );
    }
}

export default FormTextAreaField;

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, RefObject } from 'react';
import Button from './Button';

import imagePlaceholder from '../../assets/images/image.svg';
import ErrorMessage from './ErrorMessage';

interface OwnProps {
    onSelected: Function;
    buttonText: string;
    buttonIcon?: string;
    styles?: string;
    helpText?: string;
    imageUrl?: string;
    errors?: any;
    disabled?: boolean;
}

interface OwnState {
    fileInputRef: RefObject<HTMLInputElement>;
    previewUrl: string | null;
}

class ImageUpload extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);

        this.state = {
            fileInputRef: React.createRef(),
            previewUrl: null,
        };
    }

    onOpenFileDialog = () => {
        if (this.state.fileInputRef.current) {
            this.state.fileInputRef.current.click();
        }
    };

    onFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onSelected } = this.props;

        const {
            currentTarget: {
                files,
            },
        } = event;

        let file: File | null = null;
        if (files && files[0]) {
            file = files[0];

            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    previewUrl: reader.result as string,
                }, () => {
                    if (file) {
                        onSelected(file);
                    }
                });
            };

            reader.readAsDataURL(file);
        }
    };

    renderErrors = () => {
        const { errors } = this.props;

        if (errors) {
            return (
                <ErrorMessage errors={errors} />
            );
        }

        return null;
    };

    render() {
        const {
            buttonText,
            styles,
            buttonIcon,
            helpText,
            imageUrl,
            disabled,
        } = this.props;
        const { previewUrl } = this.state;

        const image = (imageUrl || imagePlaceholder);
        const cssClass = styles || 'product-image';

        return (
            <div className="image-upload">
                <input
                    type="file"
                    ref={this.state.fileInputRef}
                    onChange={this.onFileSelected}
                />
                <img className={cssClass} src={previewUrl || image} alt="" />
                <div>
                    <Button
                        type="button"
                        text={buttonText}
                        styles="button--blue button--smaller"
                        icon={buttonIcon || undefined}
                        iconPosition="right"
                        callback={this.onOpenFileDialog}
                        disabled={disabled}
                    />
                    <p>
                        {helpText}
                    </p>
                    <p className="field-error">{this.renderErrors()}</p>
                </div>
            </div>
        );
    }
}

export default ImageUpload;

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';

interface OwnProps {
    isLink?: boolean;
    type?: 'button' | 'submit';
    text?: string;
    styles?: string;
    callback?: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    icon?: string;
    name?: string;
    iconPosition?: string;
    disabled?: boolean;
    toolTipText?: string;
}

class ButtonImgIcon extends Component<OwnProps> {
    getClassNames = (): string => {
        const {
            styles, isLink, icon, text, iconPosition, disabled,
        } = this.props;

        let baseStyle = 'button';

        if (styles) {
            baseStyle = `button ${styles}`;
        }

        let disabledStyle = '';
        if (disabled) {
            disabledStyle = 'button--disabled';
        }

        const linkStyle: string = isLink ? 'button--link' : '';

        let iconStyle = '';
        let iconPosStyle = '';
        if (icon) {
            if (text && text.length > 0) {
                iconStyle = 'button--with-icon';
                iconPosStyle = `button--with-icon--${iconPosition}`;
            } else {
                iconStyle = 'button--just-icon';
            }
        }

        return `${baseStyle} ${linkStyle} ${iconStyle} ${iconPosStyle} ${disabledStyle}`;
    };

    renderDisabledButton = () => {
        const {
            text,
            callback,
            type,
            icon,
            name,
        } = this.props;

        return (
            <button
                type={type || 'button'}
                className={this.getClassNames()}
                onClick={callback}
                name={name || ''}
                disabled
            >
                {text && <span>{text}</span>}
                {icon && <img src={icon} alt="" />}
            </button>
        );
    };

    render() {
        const {
            text,
            callback,
            type,
            icon,
            name,
            disabled,
            toolTipText,
        } = this.props;

        if (disabled) {
            return this.renderDisabledButton();
        }

        return (
            <button
                type={type || 'button'}
                className={this.getClassNames()}
                onClick={callback}
                name={name || ''}
                title={toolTipText || undefined}
            >
                {text && <span>{text}</span>}
                {icon && <img src={icon} alt="" />}
            </button>
        );
    }
}

export default ButtonImgIcon;

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { COOKIES_POLICY_ROUTE, PRIVACY_POLICY_ROUTE } from '../../constants/routes';
import { MatchParams } from '../../constants/misc';
import Button from './Button';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext, ReactCookieProps {
    onConsentGiven(): void;
}

class CookiesFooter extends Component<OwnProps> {
    onCloseClick = () => {
        const { cookies, onConsentGiven } = this.props;
        if (cookies) {
            cookies.set('cookies_consent', true, { path: '/' });
            onConsentGiven();
        }
    }

    onCookiesPrivacyClick = () => {
        const { history } = this.props;
        history.push(COOKIES_POLICY_ROUTE);
    }

    onPrivacyPolicyClick = () => {
        const { history } = this.props;
        history.push(PRIVACY_POLICY_ROUTE);
    }

    render() {
        const { t } = this.props;

        return (
            <div className="cookies-footer">
                <span>{t('cookiesFooter.information')}&nbsp;</span>
                <span className="clickable" onClick={this.onCookiesPrivacyClick}>{t('cookiesFooter.cookiesPolicy')}</span>
                <span>&nbsp;{t('cookiesFooter.andOur')}&nbsp;</span>
                <span className="clickable" onClick={this.onPrivacyPolicyClick}>{t('cookiesFooter.privacyPolicy')}</span>
                <span>.</span>
                <span>&nbsp;{t('cookiesFooter.informationHelp')}</span>
                <Button
                    text={t('global.buttons.accept')}
                    callback={this.onCloseClick}
                    styles="button--dark-blue button--smaller"
                />
            </div>
        );
    }
}

export default withRouter(withCookies(withTranslationContext(CookiesFooter)));

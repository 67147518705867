/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import Modal from 'react-bootstrap/Modal';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import {
    getFormErrors, IFormError, VALIDATIONS, validateField,
} from '../../utils/validation';
import FormTextField from '../elements/FormTextField';
import Button from '../elements/Button';
import Loader from '../elements/Loader';
import { customerUserURL } from '../../services/users';
import { LOGIN_ROUTE, PRIVACY_POLICY_ROUTE, TERMS_AND_CONDITIONS_OF_USE_ROUTE } from '../../constants/routes';
import { MatchParams } from '../../constants/misc';
import FormCheckbox from '../elements/FormCheckbox';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext {}

interface OwnState {
    formErrors: any;
    isFetching: boolean;
    registerSuccess: boolean;
    showCustomerExistsErrorModal: boolean;
    acceptedTermsAndConditions: boolean;
    acceptedPromotionalEmails: boolean;
    hasTermsAndConditionsError: boolean;
    fields: {
        name: string;
        email: string;
        contact: string;
        address: string;
        city: string;
        postalCode: string;
        password: string;
        passwordConfirmation: string;
    };
}

const initialState: OwnState = {
    formErrors: null,
    isFetching: false,
    registerSuccess: false,
    showCustomerExistsErrorModal: false,
    acceptedTermsAndConditions: false,
    acceptedPromotionalEmails: false,
    hasTermsAndConditionsError: false,
    fields: {
        name: '',
        email: '',
        contact: '',
        address: '',
        city: '',
        postalCode: '',
        password: '',
        passwordConfirmation: '',
    },
};

class CustomerRegisterScreen extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        if (this.validateFields()) {
            const { fields, acceptedPromotionalEmails } = this.state;

            const formFields = {
                name: String(fields.name).trim(),
                email: String(fields.email).trim(),
                contact: String(fields.contact).trim(),
                address: String(fields.address).trim(),
                city: String(fields.city).trim(),
                postalCode: String(fields.postalCode).trim(),
                password: String(fields.password).trim(),
                newsletter: acceptedPromotionalEmails,
            };

            this.createCustomerRequest(formFields);
        }
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onErrorModalClose = (): void => {
        this.setState({
            showCustomerExistsErrorModal: false,
        });
    }

    onLoginClick = (): void => {
        const { history } = this.props;
        history.push(LOGIN_ROUTE);
    }

    onOptInEmailsChanged = (): void => {
        const { acceptedPromotionalEmails } = this.state;

        this.setState({
            acceptedPromotionalEmails: !acceptedPromotionalEmails,
        });
    }

    onAcceptedTermsChanged = (): void => {
        const { acceptedTermsAndConditions } = this.state;

        this.setState({
            acceptedTermsAndConditions: !acceptedTermsAndConditions,
        });
    }

    validateFields = (): boolean => {
        let errors: IFormError | null = getFormErrors(this.state.fields, VALIDATIONS.CUSTOMER_FORM);
        const { acceptedTermsAndConditions } = this.state;

        if (!errors) errors = {};
        const { password, passwordConfirmation, postalCode } = this.state.fields;
        if (password && passwordConfirmation && password !== passwordConfirmation) {
            errors.passwordConfirmation = [{ typeOfViolation: 'passwordsDontMatch' }];
        }

        const errorPostalCode = validateField('postalCode', postalCode, VALIDATIONS.CHECKOUT);
        if (errorPostalCode) errors.postalCode = errorPostalCode;

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({
            formErrors: errors ? { fields: errors } : errors,
            hasTermsAndConditionsError: !acceptedTermsAndConditions,
        });
        return errors === null && acceptedTermsAndConditions;
    };

    createCustomerRequest = (fields: object): void => {
        this.setState({ isFetching: true });

        axios.post(customerUserURL(), fields)
            .then(() => {
                this.setState({
                    isFetching: false,
                    registerSuccess: true,
                });
            })
            .catch(error => {
                if (error.response) {
                    const { data } = error.response;
                    if (data.result && data.result === 'USER ALREADY EXISTS') {
                        this.setState({
                            isFetching: false,
                            showCustomerExistsErrorModal: true,
                        });
                    } else {
                        this.handleResponse(error.response.data);
                    }
                } else {
                    this.setState({
                        isFetching: false,
                    });
                }
            });
    };

    handleResponse = (formErrors: any = null): void => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    render() {
        const { t } = this.props;
        const {
            fields,
            formErrors,
            isFetching,
            registerSuccess,
            hasTermsAndConditionsError,
            acceptedTermsAndConditions,
            acceptedPromotionalEmails,
            showCustomerExistsErrorModal,
        } = this.state;

        return (
            <div className="container-fluid app_screen">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                {registerSuccess ? (
                    <div className="app_screen__centered margin-top-bar">
                        <div className="page-title">
                            {t('customerRegister.successTitle')}
                        </div>
                        <div className="register-form-content success-register d-flex flex-column justify-content-center">
                            <h4>{t('customerRegister.successText', { email: fields.email })}</h4>
                            <div className="success-button-wrapper">
                                <Button
                                    text={t('customerRegister.loginBtn')}
                                    styles="button--dark-blue button--bigger"
                                    callback={this.onLoginClick}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="app_screen__centered margin-top-bar">
                        <div className="page-pretitle">
                            {t('customerRegister.pretitle')}
                        </div>
                        <div className="page-title">
                            {t('customerRegister.title')}
                        </div>
                        <form className="register-form-content" onSubmit={this.onSubmit}>
                            <Row>
                                <Col sm={12} md={12}>
                                    <FormTextField
                                        name="name"
                                        value={fields.name}
                                        placeholder={`${t('customerRegister.labels.name')} *`}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.name', null)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12}>
                                    <FormTextField
                                        name="address"
                                        value={fields.address}
                                        placeholder={`${t('customerRegister.labels.address')} *`}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.address', null)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12}>
                                    <FormTextField
                                        name="city"
                                        value={fields.city}
                                        placeholder={`${t('customerRegister.labels.city')} *`}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.city', null)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} md={3} lg={3}>
                                    <FormTextField
                                        name="postalCode"
                                        value={fields.postalCode}
                                        placeholder={`${t('customerRegister.labels.postalCode')} *`}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.postalCode', null)}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={3} lg={3}>
                                    <FormTextField
                                        name="contact"
                                        value={fields.contact}
                                        placeholder={`${t('customerRegister.labels.contact')} *`}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.contact', null)}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <FormTextField
                                        name="email"
                                        value={fields.email}
                                        placeholder={`${t('customerRegister.labels.email')} *`}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.email', null)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <FormTextField
                                        type="password"
                                        name="password"
                                        value={fields.password}
                                        placeholder={`${t('customerRegister.labels.password')} *`}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.password', null)}
                                        autoComplete="new-password"
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <FormTextField
                                        type="password"
                                        name="passwordConfirmation"
                                        value={fields.passwordConfirmation}
                                        placeholder={`${t('customerRegister.labels.passwordConfirmation')} *`}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.passwordConfirmation', null)}
                                    />
                                </Col>
                            </Row>
                            <div className="terms-checkbox-container">
                                <div>
                                    <FormCheckbox
                                        name="acceptedTermsAndConditions"
                                        value={acceptedTermsAndConditions}
                                        onChange={this.onAcceptedTermsChanged}
                                    />
                                    <p>{t('customerRegister.readAndAccept')}&nbsp;
                                        <NavLink to={TERMS_AND_CONDITIONS_OF_USE_ROUTE} target="_blank">{t('customerRegister.termsAndConditions')}</NavLink>
                                        &nbsp;{t('customerRegister.and')}&nbsp;
                                        <NavLink to={PRIVACY_POLICY_ROUTE} target="_blank">{t('customerRegister.privacyPolicy')}</NavLink>
                                    </p>
                                </div>
                                {hasTermsAndConditionsError && (
                                    <p className="error-field">{t('errors.Mandatory')}</p>
                                )}
                            </div>
                            <div className="terms-checkbox-container">
                                <div>
                                    <FormCheckbox
                                        name="acceptedPromotionalEmails"
                                        value={acceptedPromotionalEmails}
                                        onChange={this.onOptInEmailsChanged}
                                    />
                                    <p>{t('customerRegister.optInEmails')}&nbsp;</p>
                                </div>
                            </div>
                            <div className="button-wrapper">
                                <Button
                                    type="submit"
                                    text={t('customerRegister.registerBtn')}
                                    styles="button--blue"
                                />
                            </div>
                        </form>
                    </div>
                )}
                <Modal className="modal-custom" show={showCustomerExistsErrorModal} onHide={this.onErrorModalClose} centered>
                    <ModalHeader className="success-modal-header" closeButton>
                        <ModalTitle>
                            <div className="success-modal">
                                <h2 className="my-2 red-text">{t('customerRegister.error')}</h2>
                                <h5>{t('customerRegister.customerExistsError')}</h5>
                                <div className="mt-4">
                                    <Button
                                        text={t('customerRegister.loginBtn')}
                                        styles="button--dark-blue button--big"
                                        callback={this.onLoginClick}
                                    />
                                </div>
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                </Modal>
            </div>
        );
    }
}

export default withTranslationContext(CustomerRegisterScreen);

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, createRef, RefObject } from 'react';
import {
    NavLink, Route, RouteComponentProps, Switch, withRouter,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { ReactCookieProps, withCookies } from 'react-cookie';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { get } from 'lodash';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import HomeScreen from './screens/HomeScreen';
import {
    ADMIN_ROUTE,
    INDEX_ROUTE,
    LOGIN_ROUTE,
    NOT_FOUND_ROUTE,
    PRODUCT_VIEW_ROUTE,
    STORE_ADMIN_ROUTE,
    STORE_VIEW_ROUTE,
    STORES_CATEGORY_ROUTE,
    TERMS_AND_CONDITIONS_OF_USE_ROUTE,
    COOKIES_POLICY_ROUTE,
    PRIVACY_POLICY_ROUTE,
    REGISTER_ROUTE,
    CART_ROUTE,
    CUSTOMER_ADMIN_ROUTE,
    CHECKOUT_SUCCESS_ROUTE,
    ALL_STORES_ROUTE,
    ALL_PRODUCTS_VIEW_ROUTE,
    RECOVER_PASSWORD_ROUTE,
    RESET_PASSWORD_ROUTE,
    JOIN_US_ROUTE,
    CHECKOUT_ROUTE,
    REGISTER_GUEST_ROUTE,
    ACCOUNT_ACTIVATION_ROUTE,
    FAQS_ROUTE,
    SALE_CONDITIONS_ROUTE,
    OLD_PRODUCT_VIEW_ROUTE,
    RAFFLE_ROUTE,
    RESET_TOKEN_ROUTE,
} from '../constants/routes';
import TopBar from './elements/TopBar';
import LoginScreen from './screens/LoginScreen';
import AdminScreen from './screens/admin/AdminScreen';
import AuthenticatedRoute from './containers/AuthenticatedRoute';
import { PermissionKey } from '../constants/authorization';
import Footer from './elements/Footer';
import StoresByCategoryScreen from './screens/stores/StoresListScreen';
import StoreScreen from './screens/stores/StoreScreen';
import ProductScreen from './elements/ProductDetails';
import StoreManagerAdminScreen from './screens/storeAdmin/StoreManagerAdminScreen';
import TermsAndConditionsScreen from './screens/TermsAndConditionsScreen';
import CookiesFooter from './elements/CookiesFooter';
import CustomerRegisterScreen from './screens/CustomerRegisterScreen';
import CartScreen from './screens/checkout/CartScreen';
import CheckoutScreen from './screens/checkout/CheckoutScreen';
import CustomerAdminScreen from './screens/customer/CustomerAdminScreen';
import CheckoutSuccessScreen from './screens/checkout/CheckoutSuccessScreen';
import ProductsListScreen from './screens/ProductsListScreen';
import RaffleScreen from './screens/RaffleScreen';

import RecoverPasswordScreen from './screens/RecoverPasswordScreen';
import ResetActivationTokenScreen from './screens/ResetActivationTokenScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import { enabledNormalContactForm, MatchParams } from '../constants/misc';

import emailIcon from '../assets/images/icon-email.svg';
import whatsAppIcon from '../assets/images/whatsapp_logo.svg';
import { ContactMessageType } from '../constants/types';
import FormSelectField from './elements/FormSelectField';
import { TranslationContext, withTranslationContext } from './controllers/translation/TranslationContext';
import { AuthenticationContext, withAuthenticationContext } from './controllers/authentication/AuthenticationContext';
import { CartContext, withCartContext } from './controllers/cart/CartContext';
import FormTextField from './elements/FormTextField';
import Button from './elements/Button';
import FormTextAreaField from './elements/FormTextAreaField';
import { getFormErrors, IFormError, VALIDATIONS } from '../utils/validation';
import { messagesURL } from '../services/messages';
import Loader from './elements/Loader';
import { RECAPTCHA_KEY } from '../settings';
import greenTickIcon from '../assets/images/green_tick.svg';

import 'react-toastify/dist/ReactToastify.css';
import JoinUsScreen from './screens/JoinUsScreen';
import WhatsAppContactForm from './elements/WhatsAppContactForm';
import GuestRegisterScreen from './screens/GuestRegisterScreen';
import ConfirmRegistrationScreen from './screens/ConfirmRegistrationScreen';

interface OwnProps extends RouteComponentProps<MatchParams>, ReactCookieProps, TranslationContext, AuthenticationContext, CartContext {}

interface OwnState {
    hasCookiesConsent: boolean;
    isFetching: boolean;
    showContactForm: boolean;
    showWhatsAppForm: boolean;
    formErrors: any;
    acceptedTermsAndConditions: boolean;
    hasTermsAndConditionsError: boolean;
    showSuccessModal: boolean;
    showErrorModal: boolean;
    hasReCaptchaError: boolean;
    recaptchaResponse: string;
    contactFormFields: {
        name: string;
        email: string;
        contact: string;
        message: string;
        messageType: string;
    };
}

const initialState: OwnState = {
    hasCookiesConsent: false,
    showContactForm: false,
    showWhatsAppForm: false,
    isFetching: false,
    formErrors: null,
    hasTermsAndConditionsError: false,
    acceptedTermsAndConditions: false,
    showSuccessModal: false,
    showErrorModal: false,
    hasReCaptchaError: false,
    recaptchaResponse: '',
    contactFormFields: {
        name: '',
        email: '',
        contact: '',
        message: '',
        messageType: '',
    },
};

class Router extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    private readonly contactDivRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    private readonly whatsAppRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    componentDidMount(): void {
        const {
            cookies,
        } = this.props;

        this.refreshCartCookie();

        if (cookies) {
            const cookiesConsent: any = cookies.get('cookies_consent');
            if (cookiesConsent) {
                this.setState({
                    hasCookiesConsent: true,
                });
            }
        }
    }

    onCookiesConsentGiven = () => {
        this.setState({
            hasCookiesConsent: true,
        });
    }

    refreshCartCookie = () => {
        const {
            fetchCart, isAuthenticated, user,
        } = this.props;

        if ((isAuthenticated && user && user.role === 'CUSTOMER') || !isAuthenticated) {
            fetchCart();
        }
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            contactFormFields: {
                ...this.state.contactFormFields,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onAcceptedTermsChanged = () => {
        const { acceptedTermsAndConditions } = this.state;

        this.setState({
            acceptedTermsAndConditions: !acceptedTermsAndConditions,
        });
    }

    onRecaptchaVerify = (response: string | null) => {
        if (response) {
            this.setState({
                ...this.state,
                hasReCaptchaError: false,
                recaptchaResponse: response,
            });
        } else {
            this.setState({
                ...this.state,
                hasReCaptchaError: true,
            });
        }
    }

    onContactUsSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { contactFormFields, recaptchaResponse } = this.state;

        if (!recaptchaResponse || recaptchaResponse === '') {
            this.setState({
                hasReCaptchaError: true,
            });
            return;
        }

        if (this.validateContactForm()) {
            const fields = {
                name: String(contactFormFields.name).trim(),
                contact: String(contactFormFields.contact).trim(),
                email: String(contactFormFields.email).trim(),
                message: String(contactFormFields.message).trim(),
                messageType: String(contactFormFields.messageType),
            };

            this.sendContactRequest(fields);
        }
    };

    onContactClick = () => {
        this.setState({
            showContactForm: true,
            showWhatsAppForm: false,
        });
    }

    onWhatsAppClick = () => {
        this.setState({
            showWhatsAppForm: true,
            showContactForm: false,
        });
    }

    onPopoverClose = () => {
        const { hasCookiesConsent } = this.state;

        this.setState({
            ...initialState,
            hasCookiesConsent,
            showContactForm: false,
        });
    }

    onWhatsAppClose = () => {
        this.setState({
            showWhatsAppForm: false,
        });
    }

    onCloseSuccessModal = () => {
        this.setState({
            showSuccessModal: false,
        });
    };

    onCloseErrorModal = () => {
        this.setState({
            showErrorModal: false,
        });
    };

    sendContactRequest = (fields: object) => {
        const { recaptchaResponse } = this.state;

        this.setState({ isFetching: true });
        axios.post(messagesURL({
            'g-recaptcha-response': recaptchaResponse,
        }), fields)
            .then(() => {
                this.setState({
                    ...initialState,
                    showSuccessModal: true,
                });
            })
            .catch(error => {
                if (error && error.response) {
                    const { status, data } = error.response;
                    if (status === 400 && !data.result) {
                        this.handleResponse(data);
                    } else {
                        this.setState({
                            showSuccessModal: false,
                            showErrorModal: true,
                            isFetching: false,
                        });
                    }
                } else {
                    this.setState({
                        showSuccessModal: false,
                        showErrorModal: true,
                        isFetching: false,
                    });
                }
            });
    };

    validateContactForm = () => {
        let errors: IFormError | null = getFormErrors(this.state.contactFormFields, VALIDATIONS.CONTACT_FORM);
        const { acceptedTermsAndConditions } = this.state;

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({
            formErrors: errors ? { fields: errors } : errors,
            hasTermsAndConditionsError: !acceptedTermsAndConditions,
        });

        return errors === null && acceptedTermsAndConditions;
    };

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    renderWhatsAppForm = () => {
        return (
            <Popover
                id="whatsApp-popover"
                className="contact-popover"
            >
                <Popover.Content>
                    <WhatsAppContactForm />
                </Popover.Content>
            </Popover>
        );
    }

    renderContact() {
        const { t } = this.props;
        const {
            contactFormFields,
            formErrors,
            acceptedTermsAndConditions,
            hasTermsAndConditionsError,
            hasReCaptchaError,
        } = this.state;

        return (
            <Popover
                id="contact-popover"
                className="contact-popover"
            >
                <Popover.Content>
                    <form onSubmit={this.onContactUsSubmit}>
                        <Row>
                            <Col xs={12} lg={12}>
                                <FormSelectField
                                    name="messageType"
                                    value={contactFormFields.messageType}
                                    placeholder={t('home.contact.labels.messageType')}
                                    onChange={this.onInputChange}
                                    options={[
                                        {
                                            value: '',
                                            label: t('home.contact.labels.messageType'),
                                        },
                                        {
                                            value: ContactMessageType.MARKET,
                                            label: t('enums.contactMessageType.MARKET'),
                                        },
                                    ]}
                                    required
                                    errors={get(formErrors, 'fields.messageType', null)}
                                />
                            </Col>
                            <Col xs={12} lg={12}>
                                <FormTextField
                                    name="name"
                                    value={contactFormFields.name}
                                    placeholder={t('home.contact.labels.name')}
                                    onChange={this.onInputChange}
                                    required
                                    errors={get(formErrors, 'fields.name', null)}
                                />
                            </Col>
                            <Col xs={12} lg={12}>
                                <FormTextField
                                    name="email"
                                    value={contactFormFields.email}
                                    placeholder={t('home.contact.labels.email')}
                                    onChange={this.onInputChange}
                                    required
                                    errors={get(formErrors, 'fields.email', null)}
                                />
                            </Col>
                            <Col xs={12} lg={12}>
                                <FormTextField
                                    name="contact"
                                    value={contactFormFields.contact}
                                    placeholder={t('home.contact.labels.phone')}
                                    onChange={this.onInputChange}
                                    required
                                    errors={get(formErrors, 'fields.contact', null)}
                                />
                            </Col>
                            <Col xs={12} lg={12}>
                                <FormTextAreaField
                                    name="message"
                                    value={contactFormFields.message}
                                    placeholder={t('home.contact.labels.message')}
                                    required
                                    onChange={this.onInputChange}
                                />
                            </Col>
                            <Col xs={12} lg={12}>
                                <div className="terms-checkbox-container">
                                    <div>
                                        <input
                                            type="checkbox"
                                            name="acceptedTermsAndConditions"
                                            checked={acceptedTermsAndConditions}
                                            onChange={this.onAcceptedTermsChanged}
                                        />
                                        <label>
                                            <span>{t('pickup.labels.readAndAccept')}&nbsp;</span>
                                            <NavLink to={TERMS_AND_CONDITIONS_OF_USE_ROUTE} target="_blank">{t('pickup.labels.termsAndConditions')}</NavLink>
                                        </label>
                                    </div>
                                    {hasTermsAndConditionsError && (
                                        <p className="error-field">{t('errors.Mandatory')}</p>
                                    )}
                                </div>
                            </Col>
                            <Col xs={12} lg={12} className="w-100 d-flex flex-column align-items-center justify-content-center">
                                <ReCAPTCHA
                                    sitekey={String(RECAPTCHA_KEY)}
                                    onChange={this.onRecaptchaVerify}
                                    hl="pt-PT"
                                />
                                {hasReCaptchaError && (
                                    <p className="recaptcha-error">
                                        {t('home.reCaptchaError')}
                                    </p>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12} className="w-100 d-flex justify-content-end mt-2">
                                <Button
                                    type="submit"
                                    text={t('home.contact.labels.sendMessage')}
                                    styles="button--blue button--small"
                                />
                            </Col>
                        </Row>
                    </form>
                </Popover.Content>
            </Popover>
        );
    }

    render() {
        const { t, location } = this.props;
        const {
            hasCookiesConsent,
            showContactForm,
            showWhatsAppForm,
            isFetching,
            showErrorModal,
            showSuccessModal,
        } = this.state;
        const { pathname } = location;

        const inProductView: boolean = pathname.startsWith(`${PRODUCT_VIEW_ROUTE}`);

        let popOverProps: any = {
            placement: 'top',
            show: showContactForm,
            rootCloseEvent: 'click',
            rootClose: true,
            onHide: this.onPopoverClose,
        };

        if (this.contactDivRef.current) {
            popOverProps = {
                placement: 'top',
                target: this.contactDivRef.current,
                show: showContactForm,
                rootCloseEvent: 'click',
                rootClose: true,
                onHide: this.onPopoverClose,
            };
        }

        let whatsAppProps: any = {
            placement: 'top',
            show: showWhatsAppForm,
            rootCloseEvent: 'click',
            rootClose: true,
            onHide: this.onWhatsAppClose,
        };

        if (this.whatsAppRef.current) {
            whatsAppProps = {
                placement: 'top',
                target: this.whatsAppRef.current,
                show: showWhatsAppForm,
                rootCloseEvent: 'click',
                rootClose: true,
                onHide: this.onWhatsAppClose,
            };
        }

        return (
            <React.Fragment>
                {!inProductView && (
                    <Helmet>
                        <meta property="og:title" content="Leiria Market" />
                        <meta property="og:description" content="O Leiria market é uma plataforma de lojas online desenvolvida para ajudar o comércio tradicional da região de Leiria a mais facilmente chegar à população local." />
                        <meta property="og:image" content={`${window.location.origin}/logo.jpg`} />
                        <meta property="og:image:width" content="500" />
                        <meta property="og:image:height" content="500" />
                        <meta property="og:type" content="website" />
                    </Helmet>
                )}
                <div className="app-router">
                    {isFetching && (
                        <div className="loader-wrapper">
                            <Loader />
                        </div>
                    )}
                    <TopBar />
                    <div className="app-container">
                        <Switch>
                            <Route exact path={NOT_FOUND_ROUTE}>
                                <div>
                                    not found
                                </div>
                            </Route>
                            <Route exact path={LOGIN_ROUTE} component={LoginScreen} />
                            <Route exact path={RECOVER_PASSWORD_ROUTE} component={RecoverPasswordScreen} />
                            <Route exact path={RESET_PASSWORD_ROUTE} component={ResetPasswordScreen} />
                            <Route exact path={RESET_TOKEN_ROUTE} component={ResetActivationTokenScreen} />
                            <Route exact path={REGISTER_ROUTE} component={CustomerRegisterScreen} />
                            <Route exact path={REGISTER_GUEST_ROUTE} component={GuestRegisterScreen} />
                            <Route exact path={TERMS_AND_CONDITIONS_OF_USE_ROUTE} component={TermsAndConditionsScreen} />
                            <Route exact path={COOKIES_POLICY_ROUTE} component={TermsAndConditionsScreen} />
                            <Route exact path={PRIVACY_POLICY_ROUTE} component={TermsAndConditionsScreen} />
                            <Route exact path={FAQS_ROUTE} component={TermsAndConditionsScreen} />
                            <Route exact path={RAFFLE_ROUTE} component={RaffleScreen} />
                            <Route exact path={SALE_CONDITIONS_ROUTE} component={TermsAndConditionsScreen} />
                            <Route exact path={ALL_STORES_ROUTE} component={StoresByCategoryScreen} />
                            <Route exact path={ALL_PRODUCTS_VIEW_ROUTE} component={ProductsListScreen} />
                            <Route exact path={`${STORES_CATEGORY_ROUTE}/:id`} component={StoresByCategoryScreen} />
                            <Route exact path={`${STORE_VIEW_ROUTE}/:id`} component={StoreScreen} />
                            <Route exact path={`${STORE_VIEW_ROUTE}/:id/:tab`} component={StoreScreen} />
                            <Route path={`${STORE_VIEW_ROUTE}/:storeName`} component={StoreScreen} />
                            <Route exact path={[`${PRODUCT_VIEW_ROUTE}/:productId`, `${OLD_PRODUCT_VIEW_ROUTE}/:productId`]} component={ProductScreen} />
                            <Route exact path={`/:storeName${PRODUCT_VIEW_ROUTE}/:categoryName/:productName-:productId`} component={ProductScreen} />
                            <Route exact path={JOIN_US_ROUTE} component={JoinUsScreen} />
                            <Route exact path={CART_ROUTE} component={CartScreen} />
                            <Route exact path={`${ACCOUNT_ACTIVATION_ROUTE}/:token`} component={ConfirmRegistrationScreen} />
                            <AuthenticatedRoute exact path={CUSTOMER_ADMIN_ROUTE} actions={[PermissionKey.CUSTOMER]} component={CustomerAdminScreen} />
                            <AuthenticatedRoute exact path={`${CUSTOMER_ADMIN_ROUTE}/order-info/:id`} actions={[PermissionKey.CUSTOMER]} component={CustomerAdminScreen} />
                            <AuthenticatedRoute exact path={CHECKOUT_ROUTE} actions={[PermissionKey.CUSTOMER]} component={CheckoutScreen} />
                            <AuthenticatedRoute exact path={CHECKOUT_SUCCESS_ROUTE} actions={[PermissionKey.CUSTOMER]} component={CheckoutSuccessScreen} />
                            <AuthenticatedRoute exact path={ADMIN_ROUTE} actions={[PermissionKey.ALL]} component={AdminScreen} />
                            <AuthenticatedRoute exact path={STORE_ADMIN_ROUTE} actions={[PermissionKey.STORE_MANAGER]} component={StoreManagerAdminScreen} />
                            <AuthenticatedRoute exact path={`${STORE_ADMIN_ROUTE}/order-info/:id`} actions={[PermissionKey.STORE_MANAGER]} component={StoreManagerAdminScreen} />
                            <Route path={INDEX_ROUTE} component={HomeScreen} />
                        </Switch>
                    </div>
                    {!hasCookiesConsent && (
                        <CookiesFooter onConsentGiven={this.onCookiesConsentGiven} />
                    )}
                    <Footer />
                    <ToastContainer className="toast-container" />
                    {enabledNormalContactForm && (
                    <div className="contact-icon-container">
                        <div className="wrapper" ref={this.contactDivRef} onClick={this.onContactClick}>
                            <img src={emailIcon} alt="Contact" />
                        </div>
                    </div>
                    )}
                    <div className="contact-icon-container">
                        <div className="wrapper wrapper__whatsapp" ref={this.whatsAppRef} onClick={this.onWhatsAppClick}>
                            <img src={whatsAppIcon} alt="WhatsApp" />
                        </div>
                    </div>
                    <>
                        <Overlay {...popOverProps}>
                            {this.renderContact()}
                        </Overlay>
                        <Overlay {...whatsAppProps}>
                            {this.renderWhatsAppForm()}
                        </Overlay>
                    </>
                    {/* SUCCESS */}
                    <Modal className="modal-custom" show={showSuccessModal} onHide={this.onCloseSuccessModal} centered>
                        <ModalHeader className="success-modal-header" closeButton>
                            <ModalTitle>
                                <div className="success-modal">
                                    <img className="green-tick" src={greenTickIcon} alt="" />
                                    <h2 className="my-2">{t('home.contact.success')}</h2>
                                    <h5>{t('home.contact.successMessage')}</h5>
                                </div>
                            </ModalTitle>
                        </ModalHeader>
                    </Modal>
                    {/* ERROR */}
                    <Modal className="modal-custom" show={showErrorModal} onHide={this.onCloseErrorModal} centered>
                        <ModalHeader className="success-modal-header" closeButton>
                            <ModalTitle>
                                <div className="success-modal">
                                    <h2 className="my-2 red-text">{t('home.contact.error')}</h2>
                                    <h5>{t('home.contact.errorMessage')}</h5>
                                </div>
                            </ModalTitle>
                        </ModalHeader>
                    </Modal>
                </div>
            </React.Fragment>
        );
    }
}

export default withCartContext(withTranslationContext(withRouter(withCookies(withAuthenticationContext((Router))))));

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import TagManager from 'react-gtm-module';

import moment from 'moment';
import Router from './components/Router';
import { setupAPI } from './api';
import 'moment/locale/pt';

import { configureStore } from './store';
import { GTM_KEY } from './settings';

moment.locale('pt');

const { persistor, store } = configureStore();

class App extends Component {
    state = { isReady: false };

    async componentDidMount() {
        await setupAPI(store);
        this.setState({ isReady: true });
        TagManager.initialize({
            gtmId: String(GTM_KEY),
        });
    }

    render() {
        const {
            isReady,
        } = this.state;

        if (!isReady) return null;

        return (
            <div className="app-screen">
                <PersistGate persistor={persistor}>
                    <Provider store={store}>
                        <CookiesProvider>
                            <BrowserRouter>
                                <Router />
                            </BrowserRouter>
                        </CookiesProvider>
                    </Provider>
                </PersistGate>
            </div>
        );
    }
}

export default App;

import React, { Component } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import Modal from 'react-bootstrap/Modal';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';
import FormTextField from '../elements/FormTextField';
import Button from '../elements/Button';
import { userForgetPassword } from '../../services/users';
import greenTickIcon from '../../assets/images/green_tick.svg';

type OwnProps = TranslationContext

interface OwnState {
    email: string;
    formErrors: any;
    isFetching: boolean;
    showErrorModal: boolean;
    showSuccessModal: boolean;
    errorLabel: string;
}

const initialState: OwnState = {
    email: '',
    formErrors: null,
    isFetching: false,
    showErrorModal: false,
    showSuccessModal: false,
    errorLabel: '',
};

class RecoverPasswordScreen extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value,
        });
    };

    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { email } = this.state;

        if (this.validateFields()) {
            const fields = {
                email: String(email).trim(),
            };

            this.sendRecoverRequest(fields);
        }
    }

    onCloseErrorModal = () => {
        this.setState({
            showErrorModal: false,
        });
    };

    onCloseSuccessModal = () => {
        this.setState({
            showSuccessModal: false,
        });
    };

    validateFields = () => {
        let errors: IFormError | null = getFormErrors(this.state, VALIDATIONS.RECOVER_PASSWORD);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    }

    sendRecoverRequest = (fields: object) => {
        this.setState({ isFetching: true });
        const { t } = this.props;

        axios.post(userForgetPassword(), fields)
            .then(() => {
                this.setState({
                    isFetching: false,
                    showSuccessModal: true,
                    showErrorModal: false,
                });
            })
            .catch(error => {
                const { response } = error;
                let errorLabel = '';
                if (response.data.result === 'USER NOT FOUND') {
                    errorLabel = t('recoverPassword.userNotFound');
                }
                this.setState({
                    errorLabel: errorLabel || t('home.contact.error'),
                    isFetching: false,
                    showErrorModal: true,
                    showSuccessModal: false,
                });
            });
    }

    render() {
        const { t } = this.props;
        const {
            email,
            formErrors,
            showErrorModal,
            showSuccessModal,
            errorLabel,
        } = this.state;

        return (
            <div className="container-fluid app_screen">
                <div className="app_screen__centered margin-top-bar">
                    <div className="page-title with-border">
                        {t('recoverPassword.title')}
                    </div>
                    <div className="information-text">
                        {t('recoverPassword.informationText')}
                    </div>
                    <form className="login-form-content recover-password" onSubmit={this.onSubmit}>
                        <FormTextField
                            name="email"
                            value={email}
                            label={t('recoverPassword.labels.email')}
                            placeholder={t('recoverPassword.labels.email')}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.email', null)}
                        />
                        <Button
                            type="submit"
                            text={t('recoverPassword.sendBtn')}
                            styles="button--dark-blue"
                        />
                    </form>
                </div>
                {/* SUCCESS */}
                <Modal className="modal-custom" show={showSuccessModal} onHide={this.onCloseSuccessModal} centered>
                    <ModalHeader className="success-modal-header" closeButton>
                        <ModalTitle>
                            <div className="success-modal">
                                <img className="green-tick" src={greenTickIcon} alt="" />
                                <h5 className="mt-2">{t('recoverPassword.successMessage', { email })}</h5>
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                </Modal>
                {/* ERROR */}
                <Modal className="modal-custom" show={showErrorModal} onHide={this.onCloseErrorModal} centered>
                    <ModalHeader className="success-modal-header" closeButton>
                        <ModalTitle>
                            <div className="success-modal">
                                <h2 className="my-2 red-text">{errorLabel}</h2>
                                <h5>{t('recoverPassword.errorMessage')}</h5>
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                </Modal>
            </div>
        );
    }
}

export default withTranslationContext(RecoverPasswordScreen);

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { decode } from 'jsonwebtoken';
import axios, { AxiosResponse } from 'axios';

import { AuthenticationAction, AuthenticationActionTypes } from './authentication_types';
import { AuthenticationUserStore, LoginFormFields } from '../constants/authentication';
import { activateUserURL, authenticationLoginURL } from '../services/authentication';
import {
    AdminPermissions, CustomerPermissions, StoreManagerPermissions, UserRoles,
} from '../constants/authorization';
import { permissionsResetActionCreator, permissionsSetActionCreator } from './authorization';
import { resetCartAction } from './cart';

/**
 * signals the login request is being made
 * @returns {AuthenticationActionTypes}
 */
export const loginRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_REQUEST,
    };
};

/**
 * received login success
 * @param {object} auth
 * @returns {AuthenticationActionTypes}
 */
export const loginSuccessActionCreator = (auth: object): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_SUCCESS,
        payload: auth,
    };
};

/**
 * received login failure
 * @param {object} formErrors
 * @returns {AuthenticationActionTypes}
 */
export const loginFailureActionCreator = (formErrors: object): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGIN_FAILURE,
        payload: formErrors,
    };
};

/**
 * signals the logout request is being made
 * @returns {AuthenticationActionTypes}
 */
export const logoutRequestActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.LOGOUT_REQUEST,
    };
};

/**
 * sets authentication properties
 * @param {object} auth
 * @returns {AuthenticationActionTypes}
 */
export const setAuthenticationActionCreator = (auth: object): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.SET_AUTHENTICATION,
        payload: auth,
    };
};

/**
 * resets the authentication properties
 * @returns {AuthenticationActionTypes}
 */
export const resetAuthenticationActionCreator = (): AuthenticationActionTypes => {
    return {
        type: AuthenticationAction.RESET_AUTHENTICATION,
    };
};

const buildAndSetAuthenticationData = async (dispatch: ThunkDispatch<{}, {}, AnyAction>, req: Promise<AxiosResponse<any>>, onSuccess: Function, onFailure?: Function) => {
    try {
        const { status, headers, data: responseData } = await req;

        const { activationToken } = responseData;

        if (activationToken) {
            dispatch(loginFailureActionCreator({
                result: 'Inactive Account',
            }));
            return;
        }

        if (status === 200) {
            const authorizationToken = headers.authorization;

            const data: any = decode(authorizationToken);
            const { email, groups } = data;

            const userGroups: Array<string> = groups;

            let store: AuthenticationUserStore | null = null;
            let name: string = email;
            if (responseData) {
                const { myStores, name: userName } = responseData;
                name = userName;

                if (myStores && myStores.length > 0) {
                    const s = myStores[0];
                    store = {
                        id: s.id,
                        name: s.name,
                    };
                }
            }

            const userAddress: string | null = userGroups.includes(UserRoles.CUSTOMER) && responseData.address ? responseData.address : undefined;
            const userCity: string | null = userGroups.includes(UserRoles.CUSTOMER) && responseData.city ? responseData.city : undefined;
            const userPostalCode: string | null = userGroups.includes(UserRoles.CUSTOMER) && responseData.postalCode ? responseData.postalCode : undefined;
            dispatch(loginSuccessActionCreator({
                token: headers.authorization,
                user: {
                    email,
                    name,
                    groups: [...groups],
                    store,
                    address: userAddress,
                    postalCode: userPostalCode,
                    city: userCity,
                    contact: responseData.contact || null,
                },
            }));

            if (userGroups && userGroups.includes(UserRoles.ADMIN)) {
                dispatch(permissionsSetActionCreator(AdminPermissions));
                dispatch(resetCartAction());
            } else if (userGroups && userGroups.includes(UserRoles.STORE_MANAGER) && store) {
                dispatch(permissionsSetActionCreator(StoreManagerPermissions));
                dispatch(resetCartAction());
            } else if (userGroups && (userGroups.includes(UserRoles.CUSTOMER) || userGroups.includes(UserRoles.GUEST))) {
                dispatch(permissionsSetActionCreator(CustomerPermissions));
            }

            onSuccess();
        }
    } catch (error) {
        if (error && error.response) {
            dispatch(loginFailureActionCreator(error.response.data));
        } else {
            dispatch(loginFailureActionCreator({}));
        }

        if (onFailure) onFailure();
    }
};

/**
 * creates a guest user and sets their data to the store
 */

export const activateAccount = (token: string, cartId: string, onSuccess: Function, onFailure: Function) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(loginRequestActionCreator());
        const req = axios.post(activateUserURL(token, cartId));
        buildAndSetAuthenticationData(dispatch, req, onSuccess, onFailure);
    };
};

/**
 * makes the login request and sets the necessary data in the store
 * @param {LoginFormFields} formData
 * @param {Function} onSuccess
 */
export const requestLogin = (formData: LoginFormFields, onSuccess: Function) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(loginRequestActionCreator());
        const req = axios.post(authenticationLoginURL(), formData);
        buildAndSetAuthenticationData(dispatch, req, onSuccess, () => {});
    };
};

/**
 * makes the logout request and sets the necessary data in the store
 */
export const requestLogout = () => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        dispatch(resetAuthenticationActionCreator());
        dispatch(permissionsResetActionCreator());
        dispatch(resetCartAction());
    };
};

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { MatchParams } from '../../constants/misc';
import Loader from '../elements/Loader';
import okIcon from '../../assets/images/icon-ok-circle.svg';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import Button from '../elements/Button';
import { UserRoles } from '../../constants/authorization';
import { CHECKOUT_ROUTE, RESET_TOKEN_ROUTE } from '../../constants/routes';
import { CartContext, withCartContext } from '../controllers/cart/CartContext';
import { formatToken } from '../../utils/misc';

interface OwnProps extends RouteComponentProps<MatchParams>, CartContext, AuthenticationContext {}

interface OwnState {
    isFetching: boolean;
    isUserConfirmed: boolean;
}

const initialState = {
    isFetching: false,
    isUserConfirmed: true,
};

class ConfirmRegistrationScreen extends React.Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { match } = this.props;
        const { params } = match;
        const { token } = params;

        if (token) {
            this.confirmAccount(token);
        }
    }

    onContinue = () => {
        const { history } = this.props;

        history.push(CHECKOUT_ROUTE);
    }

    confirmAccount = async (token: string) => {
        const {
            activateAccount, generateCartId, history,
        } = this.props;

        this.setState({
            isFetching: true,
        });
        const cartId = await generateCartId();
        
        activateAccount(formatToken(token), cartId,
            () => {
                this.setState({
                    isUserConfirmed: true,
                }, () => {
                    const { user } = this.props;
                    const userGroup = user?.groups[0];

                    if (userGroup === UserRoles.GUEST) {
                        history.push(CHECKOUT_ROUTE);
                    }
                });
            },
            () => {
                const { formErrors } = this.props;

                if (formErrors && Object.keys(formErrors).length > 0) {
                    displayNotification(NOTIFICATION_TYPE.ERROR, 'Erro a confirmar utilizador.');
                    this.setState({
                        isUserConfirmed: false,
                    });
                }
                
                if (formErrors && Object.values(formErrors).some(err => err === 'TOKEN EXPIRED')) {
                    history.push({
                        pathname: RESET_TOKEN_ROUTE,
                        state: {
                            isRedirect: true,
                        },
                    });
                }
            });

        this.setState({
            isFetching: false,
        });
    }

    render() {
        const { isFetching, isUserConfirmed } = this.state;

        return (
            <div className="container-fluid app_screen">
                {isFetching && (
                <div className="loader-wrapper">
                    <Loader />
                </div>
                )}
                <div className="app_screen__centered margin-top-bar container">
                    {!isFetching && (
                        <div className="confirm-user-screen">
                            {isUserConfirmed
                                ? (
                                    <>
                                        <img src={okIcon} alt="" />
                                        <div className="message">
                                            <h3>Utilizador confirmado com sucesso!</h3>
                                            <h5>Já pode começar a comprar no Leiria Market!</h5>
                                        </div>
                                        <Button
                                            styles="button--bigger button--blue"
                                            callback={this.onContinue}
                                            text="Continuar"
                                        />
                                    </>
                                )
                                : (
                                    <>
                                        <div className="message">
                                            <h5>Não foi possível confirmar o utilizador.<br />
                                                O endereço utilizado é inválido ou já foi utilizado.
                                            </h5>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withCartContext(ConfirmRegistrationScreen));

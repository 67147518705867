/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { CSSProperties } from 'react';
import { ReactSVG } from 'react-svg';

import iconTrash from '../../assets/images/icon-trash.svg';
import iconChevronLeft from '../../assets/images/icon-left_chevron.svg';
import iconChevronRight from '../../assets/images/icon-right_chevron.svg';
import iconLeftArrow from '../../assets/images/icon-left_arrow.svg';
import iconRightArrow from '../../assets/images/icon-right_arrow.svg';
import iconMinus from '../../assets/images/icon-minus.svg';
import iconPlus from '../../assets/images/icon-plus.svg';
import iconSearch from '../../assets/images/icon-search.svg';
import iconCircleCross from '../../assets/images/icon-cross_circle.svg';
import iconShoppingCart from '../../assets/images/icon-shopping_cart.svg';
import iconRightArrowSlim from '../../assets/images/icon-right_arrow_slim.svg';
import iconEmail from '../../assets/images/icon-email.svg';
import iconPhone from '../../assets/images/icon-phone.svg';
import iconRestore from '../../assets/images/icon-restore.svg';

interface OwnProps {
    icon: string,
    className?: string,
    callback?: Function,
}

export enum ICON {
    TRASH = 'TRASH',
    CHEVRON_RIGHT = 'CHEVRON_RIGHT',
    LEFT_ARROW = 'LEFT_ARROW',
    RIGHT_ARROW = 'RIGHT_ARROW',
    CHEVRON_LEFT = 'CHEVRON_LEFT',
    MINUS = 'MINUS',
    PLUS = 'PLUS',
    SEARCH = 'SEARCH',
    CROSS_CIRCLE = 'CROSS_CIRCLE',
    SHOPPING_CART = 'SHOPPING_CART',
    RIGHT_ARROW_SLIM = 'RIGHT_ARROW_SLIM',
    EMAIL = 'EMAIL',
    PHONE = 'PHONE',
    RESTORE = 'RESTORE'
}

export const SvgIcon = (props: OwnProps) => {
    const getIcon = () : any => {
        switch (props.icon) {
            case ICON.TRASH: return iconTrash;
            case ICON.CHEVRON_RIGHT: return iconChevronRight;
            case ICON.CHEVRON_LEFT: return iconChevronLeft;
            case ICON.LEFT_ARROW: return iconLeftArrow;
            case ICON.RIGHT_ARROW: return iconRightArrow;
            case ICON.MINUS: return iconMinus;
            case ICON.PLUS: return iconPlus;
            case ICON.SEARCH: return iconSearch;
            case ICON.CROSS_CIRCLE: return iconCircleCross;
            case ICON.SHOPPING_CART: return iconShoppingCart;
            case ICON.RIGHT_ARROW_SLIM: return iconRightArrowSlim;
            case ICON.EMAIL: return iconEmail;
            case ICON.PHONE: return iconPhone;
            case ICON.RESTORE: return iconRestore;
            default: return '';
        }
    };

    const getStyles = () : CSSProperties => {
        let styles : CSSProperties = {
            pointerEvents: 'none',
        };

        if (props.callback) {
            styles = {
                cursor: 'pointer',
            };
        }

        return styles;
    };

    const onClick = (e: React.MouseEvent) => {
        if (props.callback) {
            props.callback(e);
        }
    };

    return (
        <ReactSVG
            wrapper="span"
            onClick={event => onClick(event)}
            className={`svg-icon ${props.className}`}
            data-testid={props.icon}
            src={getIcon()}
            style={getStyles()}
        />
    );
};

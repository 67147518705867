/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

/**
 * @typedef {Object} RolePermission
 * @property {string} name
 * @property {number} id
 * @property {string} key
 * @property {RolePermission[]} dependencies
 */
export interface RolePermission {
    name: string;
    id: number;
    key: string;
    dependencies: RolePermission[];
}

/**
 * PermissionKey
 * @enum {string}
 */
export enum PermissionKey {
    ALL = 'ALL',
    STORE_MANAGER = 'STORE_MANAGER',
    CUSTOMER = 'CUSTOMER',
}

export enum UserRoles {
    ADMIN = 'ADMIN',
    STORE_MANAGER = 'STORE_MANAGER',
    CUSTOMER = 'CUSTOMER',
    GUEST = 'GUEST',
}

export const AdminPermissions: RolePermission[] = [
    {
        name: 'All',
        id: 1,
        key: PermissionKey.ALL,
        dependencies: [],
    },
];

export const StoreManagerPermissions: RolePermission[] = [
    {
        name: 'Store_manager',
        id: 1,
        key: PermissionKey.STORE_MANAGER,
        dependencies: [],
    },
];

export const CustomerPermissions: RolePermission[] = [
    {
        name: 'Customer',
        id: 1,
        key: PermissionKey.CUSTOMER,
        dependencies: [],
    },
];

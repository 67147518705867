import React, { ChangeEvent, Component } from 'react';

import ErrorMessage from './ErrorMessage';
import { SelectOption } from '../../constants/misc';

interface OwnProps {
    name: string;
    options: Array<SelectOption>;
    onChange: (value: string) => void;
    errors?: any;
    initialSelected?: string;
}

interface OwnState {
    currentlySelected: string;
}

const initialState = {
    currentlySelected: '',
};

class FormRadioButtons extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { initialSelected } = this.props;
        if (initialSelected) {
            this.setState({
                currentlySelected: initialSelected,
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const prevInitialSelected = prevProps.initialSelected;
        const { initialSelected } = this.props;
        if (initialSelected && initialSelected !== prevInitialSelected) {
            this.setState({
                currentlySelected: initialSelected,
            });
        }
    }

    onSelectOption = (e: ChangeEvent<HTMLInputElement>): void => {
        const { onChange } = this.props;
        const { value } = e.target;
        this.setState({
            currentlySelected: value,
        }, () => {
            onChange(value);
        });
    }

    reset = () => {
        const { onChange } = this.props;

        this.setState({
            currentlySelected: '',
        }, () => {
            onChange('');
        });
    }

    renderErrors = () => {
        const { name, errors } = this.props;
        if (errors) {
            return (
                <ErrorMessage errors={errors} field={name} />
            );
        }
        return <React.Fragment />;
    };

    render() {
        const {
            options,
            name,
        } = this.props;

        const { currentlySelected } = this.state;

        return (
            <form className="form-radio">
                {options.map(opt => {
                    if (opt.value && opt.value !== '') {
                        return (
                            <div className="form-radio__item" key={`${opt.label}-${opt.value}`}>
                                <label>
                                    <div>
                                        <div className={`form-radio__item__fake${opt.value === currentlySelected ? '--checked' : ''}`} />
                                    </div>
                                    <input
                                        type="radio"
                                        name={name}
                                        value={opt.value}
                                        checked={opt.value === currentlySelected}
                                        onChange={e => this.onSelectOption(e)}
                                    />
                                    {opt.label}
                                </label>
                            </div>
                        );
                    }
                    return null;
                })}
                <p className="error-field">{this.renderErrors()}</p>
            </form>
        );
    }
}

export default FormRadioButtons;

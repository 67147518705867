/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */
import { ScrollMemoryAction, ScrollMemoryTypes } from './scroll_types';

/**
 * stores the user's paginated scroll position in the products list page
 * @param {number} coordinate
 * @param {number} page
 * @param {string} location
 * @returns {ScrollMemoryTypes}
 */
export const setProductsScrollMemoryActionCreator = (coordinate: number, page: number, location: string): ScrollMemoryTypes => {
    return {
        type: ScrollMemoryAction.SET_SCROLL_MEMORY_PRODUCTS,
        payload: {
            coordinate,
            page,
            location,
        },
    };
};

/**
 * clear the user's previously stored paginated scroll position in products list page
 * @returns {ScrollMemoryTypes}
 */
export const clearProductsScrollMemoryActionCreator = (): ScrollMemoryTypes => {
    return {
        type: ScrollMemoryAction.CLEAR_SCROLL_MEMORY_PRODUCTS,
    };
};

/**
 * stores the user's paginated scroll position in stores list page
 * @param {number} coordinate
 * @param {number} page
 * @param {string} location
 * @returns {ScrollMemoryTypes}
 */
export const setStoreScrollMemoryActionCreator = (coordinate: number, page: number, location: string): ScrollMemoryTypes => {
    return {
        type: ScrollMemoryAction.SET_SCROLL_MEMORY_STORES,
        payload: {
            coordinate,
            page,
            location,
        },
    };
};

/**
 * clear the user's previously stored paginated scroll position in stores list page
 * @returns {ScrollMemoryTypes}
 */
export const clearStoreScrollMemoryActionCreator = (): ScrollMemoryTypes => {
    return {
        type: ScrollMemoryAction.CLEAR_SCROLL_MEMORY_STORES,
    };
};

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { ShoppingCart } from '../constants/types';

export enum CartAction {
    UPDATE_CART = 'UPDATE_CART',
    RESET_CART = 'RESET_CART',
    SET_CART_ID = 'SET_CART_ID',
}

export interface SetCartIdRequestAction {
    type: typeof CartAction.SET_CART_ID,
    payload: string,
}

export interface UpdateCartRequestAction {
    type: typeof CartAction.UPDATE_CART,
    payload: ShoppingCart,
}

export interface ResetCartRequestAction {
    type: typeof CartAction.RESET_CART,
}

export type CartActionTypes = UpdateCartRequestAction | ResetCartRequestAction | SetCartIdRequestAction;

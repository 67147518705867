/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const authenticationLoginURL = (): string => {
    return `${API_URL}/authentication/login`;
};

export const authenticationLogoutURL = (): string => {
    return `${API_URL}/authentication/logout`;
};

export const registerGuestURL = (): string => {
    return `${API_URL}/authentication/register-guest`;
};

export const activateUserURL = (token: string, uuid: string): string => {
    const queryString = objectToParams({ t: token, uuid });
    return `${API_URL}/authentication/activate${queryString}`;
};

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { RouteComponentProps } from 'react-router-dom';
import {
    Map,
    Marker,
    Popup,
    TileLayer,
} from 'react-leaflet';
import L, { Icon, LatLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import moment from 'moment';
import MediaQuery from 'react-responsive';
import { orderBy } from 'lodash';
import TagManager, { DataLayerArgs } from 'react-gtm-module';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { enabledStoreAltPhotos, MatchParams, StoreScreenTabs } from '../../../constants/misc';
import {
    Category,
    FormattedSchedule,
    ScheduleItem,
    Store,
    StoreTag,
} from '../../../constants/types';
import {
    ALL_STORES_ROUTE,
    INDEX_ROUTE,
    NOT_FOUND_ROUTE,
    STORE_CONTACTS_ROUTE,
    STORE_HISTORY_ROUTE,
    STORE_PRODUCTS_ROUTE,
    STORE_VIEW_ROUTE,
} from '../../../constants/routes';
import Loader from '../../elements/Loader';
import { storeSlugURL } from '../../../services/stores';
import { resourcesURL } from '../../../services/resources';

import mapMarker from '../../../assets/images/map_marker.svg';
import SortFilterButton from '../../elements/SortFilterButton';
import iconSeguro from '../../../assets/images/ic-seguro.svg';
import iconTotalmenteOnline from '../../../assets/images/ic-totally-online.svg';
import iconRapido from '../../../assets/images/ic-rapido.svg';
import Button from '../../elements/Button';
import ProductsListScreen from '../ProductsListScreen';
import { schedulesComparator, schedulesGrouper } from '../../../utils/schedules';
import { tagsStringBuilder } from '../../../utils/misc';
import { getFormattedStoreURL } from '../../../utils/urls';
import { productsFindPublicURL } from '../../../services/products';
import { categoriesPublicURL } from '../../../services/categories';

const marker: Icon = new L.Icon({
    iconUrl: mapMarker,
    iconSize: [55, 75],
    iconAnchor: [27, 75],
    popupAnchor: [0, -76],
});

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext {}

interface OwnState {
    store: Store;
    id: string;
    isFetching: boolean;
    hasProducts: boolean;
    currentTab: StoreScreenTabs;
    bannerURL: string;
}

const initialState: OwnState = {
    store: {
        id: -1,
        address: '',
        closeToLaunch: false,
        closeToLaunchOn: '',
        openFromLaunchOn: '',
        contact: '',
        description: '',
        email: '',
        homeDelivery: false,
        latitude: null,
        longitude: null,
        name: '',
        thumbnailHash: '',
        website: '',
        postalCode: '',
        thumbnail: null,
        categoryId: null,
        tags: [],
        weekSchedule: [],
        photosHash: [],
        bannerHash: '',
        history: {
            description: '',
            photo1: '',
            photo2: '',
            photo3: '',
            photo4: '',
            title: '',
        },
        exhibition: false,
        slug: '',
    },
    id: '',
    isFetching: false,
    hasProducts: true,
    currentTab: StoreScreenTabs.PRODUCTS,
    bannerURL: '',
};

class StoreScreen extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    componentDidMount(): void {
        const { match, history } = this.props;
        const { params } = match;
        const { id } = params;
        const { store } = this.state;

        if (history.location.hash.includes(STORE_HISTORY_ROUTE) && !store.exhibition) {
            this.setState({
                currentTab: StoreScreenTabs.HISTORY,
            });
        } else if (history.location.hash.includes(STORE_PRODUCTS_ROUTE)) {
            this.setState({
                currentTab: StoreScreenTabs.PRODUCTS,
            });
        } else if (history.location.hash.includes(STORE_CONTACTS_ROUTE)) {
            this.setState({
                currentTab: StoreScreenTabs.CONTACTS,
            });
        }

        if (id) {
            this.setState({
                id,
            }, () => this.prepare());
        } else {
            history.push(NOT_FOUND_ROUTE);
        }
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>): void {
        const { match: oldMatch } = prevProps;
        const { match } = this.props;
        const { params } = match;
        const { id: oldStoreId, productId: oldProductId } = oldMatch.params;
        const { id, productId } = params;

        const changedProduct = oldProductId !== productId;
        if (changedProduct && (productId || id === oldStoreId)) {
            window.scrollTo(0, 0);
            return;
        }

        if (id && id !== oldStoreId) {
            this.setState({
                id,
            }, () => {
                window.scrollTo(0, 0);
                this.prepare();
            });
        }
    }

    onTabChange = (tab: StoreScreenTabs) => {
        const { history } = this.props;
        const { store } = this.state;

        const commonUrl = `${STORE_VIEW_ROUTE}/${store.slug}`;
        let route = '';
        switch (tab) {
            case StoreScreenTabs.HISTORY:
                route = STORE_HISTORY_ROUTE;
                break;
            case StoreScreenTabs.CONTACTS:
                route = STORE_CONTACTS_ROUTE;
                break;
            case StoreScreenTabs.PRODUCTS:
            default:
                route = STORE_PRODUCTS_ROUTE;
        }
        history.push(`${commonUrl}${route}`);
        this.setState({
            currentTab: tab,
        });
    }

    sendGoogleStoreImpression = () => {
        const { store } = this.state;

        const impressionStore = {
            item_id: store.id,
            item_name: store.name,
            item_category: store.category?.description,
        };

        const gtmECommerceTag: DataLayerArgs = {
            dataLayer: {
                event: 'view_store',
                ecommerce: {
                    items: impressionStore,
                },
            },
        };

        TagManager.dataLayer(gtmECommerceTag);
    }

    prepare = () => {
        const { history } = this.props;
        const { isFetching, id } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        axios.get(storeSlugURL(id))
            .then(async response => {
                const { data } = response;

                if (!data || Object.keys(data).length === 0) {
                    history.push(NOT_FOUND_ROUTE);
                    return;
                }

                const { closeToLaunchOn, openFromLaunchOn, weekSchedule } = data;

                let updateCloseToLaunchOn = closeToLaunchOn;
                if (closeToLaunchOn && closeToLaunchOn !== '') {
                    updateCloseToLaunchOn = String(closeToLaunchOn).substring(0, String(closeToLaunchOn).length - 3);
                }

                let updateOpenFromLaunchOn = openFromLaunchOn;
                if (openFromLaunchOn && openFromLaunchOn !== '') {
                    updateOpenFromLaunchOn = String(openFromLaunchOn).substring(0, String(openFromLaunchOn).length - 3);
                }

                const updateSchedule: Array<ScheduleItem> = [];
                let scheduleOrdered: Array<ScheduleItem> = [];
                if (weekSchedule && weekSchedule.length > 0) {
                    Object.keys(weekSchedule).forEach(k => {
                        const item = weekSchedule[Number(k)];

                        updateSchedule.push({
                            id: item.id,
                            closeOn: item.closeOn ? String(item.closeOn).substring(0, String(item.closeOn).length - 3) : null,
                            openOn: item.openOn ? String(item.openOn).substring(0, String(item.openOn).length - 3) : null,
                            initialWeekDay: item.initialWeekDay,
                            endWeekDay: item.endWeekDay,
                            isInterval: (item.endWeekDay !== null && item.endWeekDay !== undefined && Number(item.endWeekDay) + 1 !== Number(item.initialWeekDay) + 1),
                        });
                    });

                    scheduleOrdered = orderBy(updateSchedule, (o: ScheduleItem) => {
                        return o.initialWeekDay;
                    }, ['asc']);
                }

                const productsUrl = productsFindPublicURL({
                    storeId: data.id,
                    pageSize: 20,
                    page: 0,
                });

                const { data: productsData } = await axios.get(productsUrl);

                const hasProducts = productsData !== undefined && productsData.length !== 0;

                let currentTab = hasProducts ? StoreScreenTabs.PRODUCTS : StoreScreenTabs.HISTORY;
                if (!hasProducts && data.exhibition) {
                    currentTab = StoreScreenTabs.CONTACTS;
                }

                this.setState({
                    isFetching: false,
                    hasProducts,
                    store: {
                        ...data,
                        id: data.id,
                        closeToLaunchOn: updateCloseToLaunchOn,
                        openFromLaunchOn: updateOpenFromLaunchOn,
                        weekSchedule: [...scheduleOrdered],
                    },
                    currentTab,
                }, () => {
                    const { store } = this.state;
                    if (history.location.pathname === `${STORE_VIEW_ROUTE}/${store.id}`) {
                        history.push(getFormattedStoreURL(store));
                    }
                    window.scrollTo(0, 0);
                    this.sendGoogleStoreImpression();
                    this.getBannerURL();
                });
            })
            .catch(() => {
                this.setState({
                    isFetching: false,
                });

                history.push(NOT_FOUND_ROUTE);
            });
    };

    getBannerURL = async (): Promise<void> => {
        const { store } = this.state;

        if (!store.bannerHash || store.bannerHash === '') {
            let cat = { headerUrl: '' };
            const { status, data } = await axios.get(categoriesPublicURL());
            if (status === 200) {
                cat = data.find((el: Category) => {
                    return el.id === store.category?.id;
                });
            }

            if (cat) {
                this.setState({
                    bannerURL: cat.headerUrl || '',
                });
            }
        } else {
            this.setState({
                bannerURL: resourcesURL(store.bannerHash),
            });
        }
    }

    renderSchedule = () => {
        const { t } = this.props;
        const { store } = this.state;

        const weekDays: string[] = moment.weekdays();
        const schedulesArray: Array<FormattedSchedule> = [];
        const sortedWeekSchedule = [...store.weekSchedule];

        sortedWeekSchedule.sort(schedulesComparator);

        weekDays.forEach((day, idx) => {
            const daySchedules = sortedWeekSchedule.filter(el => {
                return el.initialWeekDay === idx;
            });

            if (daySchedules.length > 0) {
                const formatted: FormattedSchedule = {
                    schedule1: daySchedules[0],
                    schedule2: daySchedules[1] || null,
                    weekday: day,
                };

                schedulesArray.push(formatted);
            }
        });

        const scheduleGroups: Array<Array<FormattedSchedule>> = schedulesGrouper(schedulesArray);

        return (
            <div>
                <p>{t('stores.schedule')} </p>
                {scheduleGroups.map((el: Array<FormattedSchedule>) => {
                    const beginning: FormattedSchedule = el[0];
                    const end: FormattedSchedule = el[el.length - 1];

                    const dayHeader = beginning.weekday === end.weekday ? beginning.weekday : `${beginning.weekday} - ${end.weekday}`;

                    return (
                        <>
                            <p>{dayHeader}</p>
                            <p>{`${t('stores.fromSchedule')} ${beginning.schedule1.openOn}h ${t('stores.toTimeSchedule')} ${beginning.schedule1.closeOn}h`}</p>
                            {beginning.schedule2 && (
                                <p>{`${t('stores.fromSchedule')} ${beginning.schedule2.openOn}h ${t('stores.toTimeSchedule')} ${beginning.schedule2.closeOn}h`}</p>
                            )}
                            <hr />
                        </>
                    );
                })}
            </div>
        );
    };

    renderHistoryPanel = () => {
        const { store, hasProducts } = this.state;
        const { history, description } = store;
        if (store.exhibition) return null;
        return (
            <div className="store-screen__history">
                {description && (
                    <>
                        <div className="tagline">
                            <h3>Sobre nós</h3>
                            <p className="tagline__p-regular">{description}</p>
                        </div>
                        {hasProducts && (
                            <div className="store-screen__history__product-button">
                                <Button
                                    text="Ver produtos"
                                    callback={() => { this.onTabChange(StoreScreenTabs.PRODUCTS); }}
                                    styles="button--bigger button--blue"
                                />
                            </div>
                        )}
                    </>
                )}
                {history && (
                    <>
                        <MediaQuery maxWidth={900}>
                            <div className="store-screen__history__small-gallery">
                                <div className="store-screen__history__small-gallery__text">
                                    <h3>{history.title}</h3>
                                    <p>{history.description}</p>
                                </div>
                                <div className="store-screen__history__small-gallery__photos">
                                    <div
                                        style={{
                                            backgroundImage: `url(${resourcesURL(history.photo1)})`,
                                        }}
                                    />
                                    <div
                                        style={{
                                            backgroundImage: `url(${resourcesURL(history.photo2)})`,
                                        }}
                                    />
                                    <div
                                        style={{
                                            backgroundImage: `url(${resourcesURL(history.photo3)})`,
                                        }}
                                    />
                                    <div
                                        style={{
                                            backgroundImage: `url(${resourcesURL(history.photo4)})`,
                                        }}
                                    />
                                    {enabledStoreAltPhotos && store.photosHash && store.photosHash.map(photo => {
                                        return (
                                            <div
                                                style={{
                                                    backgroundImage: `url(${resourcesURL(photo)})`,
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </MediaQuery>
                        <MediaQuery minWidth={901}>
                            <div className="store-screen__history__gallery">
                                <div className="store-screen__history__gallery__text-container">
                                    <h3>{history.title}</h3>
                                    <p>{history.description}</p>
                                </div>
                                <div className="store-screen__history__gallery__photo-container">
                                    <div
                                        className="short"
                                        style={{
                                            backgroundImage: `url(${resourcesURL(history.photo1)})`,
                                        }}
                                    />
                                    <div
                                        className="tall"
                                        style={{
                                            backgroundImage: `url(${resourcesURL(history.photo2)})`,
                                        }}
                                    />
                                    <div
                                        className="tall"
                                        style={{
                                            backgroundImage: `url(${resourcesURL(history.photo3)})`,
                                        }}
                                    />
                                    <div
                                        className="short"
                                        style={{
                                            backgroundImage: `url(${resourcesURL(history.photo4)})`,
                                        }}
                                    />
                                </div>
                                {enabledStoreAltPhotos && (
                                    <div className="store-screen__history__gallery__alt-photos-container">
                                        {store.photosHash && store.photosHash.map(photo => {
                                            return (<img src={resourcesURL(photo)} alt="" />);
                                        })}
                                    </div>
                                )}
                            </div>
                        </MediaQuery>
                    </>
                )}
            </div>
        );
    }

    renderContactsPanel = () => {
        const { t } = this.props;

        const { store } = this.state;

        const hasLongitude = store.longitude && String(store.longitude).trim() !== '';
        const hasLatitude = store.latitude && String(store.latitude).trim() !== '';
        const hasAddress = store.address && String(store.address).trim() !== '';
        const hasTags = store.tags && store.tags.length > 0;
        const hasSchedule = store.weekSchedule && store.weekSchedule.length > 0;

        let position: LatLng = new LatLng(39.7541222, -8.8409781);

        let tagsStr = '';

        if (hasTags) {
            if (store.tags && store.tags.length > 0) {
                tagsStr = tagsStringBuilder(store.tags as Array<StoreTag>);
            }
        }

        if (store.latitude && String(store.latitude).trim() !== '' && store.longitude && String(store.longitude).trim() !== '') {
            position = new LatLng(store.latitude, store.longitude);
        }

        return (
            <div className="store-screen__contacts">
                <div className="w-100">
                    {(hasLatitude && hasLongitude) && (
                        <Map center={position} zoom={13}>
                            <TileLayer url="https://c.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            <Marker position={position} icon={marker}>
                                <Popup>{hasAddress ? store.address : store.name}</Popup>
                            </Marker>
                        </Map>
                    )}
                </div>
                <div className="store-screen__contacts__info-container">
                    <div className="store-screen__contacts__info-container__content">
                        <div>
                            <p>Morada:</p>
                            <p>{store.address}</p>
                            <p>{store.postalCode}, {store.location}</p>
                        </div>
                        <div>
                            <p>Telefone:</p>
                            <p>{store.contact}</p>
                            {store.contactFeeType && (
                                <p className="contact-fee-info">{t(`contactFeeType.${store.contactFeeType}`)}</p>
                            )}
                        </div>
                        <div>
                            <p>Email:</p>
                            <p>{store.email}</p>
                        </div>
                    </div>
                    <div className="store-screen__contacts__info-container__content">
                        {hasSchedule && this.renderSchedule()}
                    </div>
                </div>
                <div className="store-screen__contacts__additional-info">
                    <div className="tagline">
                        <h3>{t('home.benefits.easeOfPayment')}</h3>
                        <MediaQuery minWidth={1025}>
                            <p className="tagline__p-regular">{t('home.commerce.text')}</p>
                            <p className="tagline__p-bold">{t('home.commerce.textLast')}</p>
                        </MediaQuery>
                    </div>
                </div>
                {hasTags && (
                    <div className="info-item">
                        <p className="info small">{tagsStr}</p>
                    </div>
                )}
                <MediaQuery maxWidth={1024}>
                    <div className="store-screen__contacts__features--mobile" id="commerce">
                        <div className="store-screen__contacts__features--mobile__item">
                            <div className="store-screen__contacts__features--mobile__item__icon-container">
                                <img src={iconSeguro} alt="" />
                            </div>
                            <div className="store-screen__contacts__features--mobile__item__text-container">
                                <h2>{t('joinUs.features.safe.title')}</h2>
                            </div>
                        </div>
                        <div className="store-screen__contacts__features--mobile__item">
                            <div className="store-screen__contacts__features--mobile__item__icon-container">
                                <img src={iconTotalmenteOnline} alt="" />
                            </div>
                            <div className="store-screen__contacts__features--mobile__item__text-container">
                                <h2>{t('joinUs.features.totallyOnline.title')}</h2>
                            </div>
                        </div>
                        <div className="store-screen__contacts__features--mobile__item">
                            <div className="store-screen__contacts__features--mobile__item__icon-container">
                                <img src={iconRapido} alt="" />
                            </div>
                            <div className="store-screen__contacts__features--mobile__item__text-container">
                                <h2>{t('joinUs.features.fast.title')}</h2>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={1025}>
                    <div className="store-screen__contacts__features--desktop" id="commerce">
                        <div className="store-screen__contacts__features--desktop__item">
                            <div className="store-screen__contacts__features--desktop__item__icon-container">
                                <img src={iconSeguro} alt="" />
                            </div>
                            <h2>{t('joinUs.features.safe.title')}</h2>
                        </div>
                        <div className="store-screen__contacts__features--desktop__item">
                            <div className="store-screen__contacts__features--desktop__item__icon-container">
                                <img src={iconTotalmenteOnline} alt="" />
                            </div>
                            <h2>{t('joinUs.features.totallyOnline.title')}</h2>
                        </div>
                        <div className="store-screen__contacts__features--desktop__item">
                            <div className="store-screen__contacts__features--desktop__item__icon-container">
                                <img src={iconRapido} alt="" />
                            </div>
                            <h2>{t('joinUs.features.fast.title')}</h2>
                        </div>
                    </div>
                </MediaQuery>
            </div>
        );
    }

    render() {
        const { t } = this.props;

        const {
            isFetching,
            store,
            currentTab,
            hasProducts,
            bannerURL,
        } = this.state;

        return (
            <div className="app_screen">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="store-screen">
                    {store.category
                    && (
                    <div
                        className="store-screen__header"
                        style={{
                            backgroundImage: `url(${bannerURL})`,
                        }}
                    >
                        <div className="store-screen__header__titles">
                            <MediaQuery maxWidth={900}>
                                <div className="store-screen__header__titles__links">
                                    <a href={INDEX_ROUTE}>{t('productDetails.backLinks.index')}</a>&nbsp;/&nbsp;
                                    <a href={ALL_STORES_ROUTE}>Lojas</a>
                                    {store.category && (
                                        <>
                                            &nbsp;/&nbsp;
                                            <a href={`${ALL_STORES_ROUTE}?categoryId=${store.category.id}`}>{store.category.description}</a>
                                        </>
                                    )}
                                </div>
                            </MediaQuery>
                            <MediaQuery minWidth={901}>
                                {store.category && <h6>{store.category.description}</h6>}
                            </MediaQuery>
                            <h3>{store.name}</h3>
                        </div>
                    </div>
                    )}
                    <div className="store-screen__links">
                        <MediaQuery minWidth={901}>
                            <a href={INDEX_ROUTE}>{t('productDetails.backLinks.index')}</a>&nbsp;/&nbsp;
                            {store.category && (
                                <>
                                    <a href={`${ALL_STORES_ROUTE}?categoryId=${store.category.id}`}>{store.category.description}</a>
                                    &nbsp;/&nbsp;
                                </>
                            )}
                            <div className="store-screen__links--current">{store.name}</div>
                        </MediaQuery>
                    </div>
                    <div className="store-screen__selectors">
                        {hasProducts && (
                            <SortFilterButton
                                label="Ver produtos"
                                callback={() => { this.onTabChange(StoreScreenTabs.PRODUCTS); }}
                                isActive={currentTab !== StoreScreenTabs.PRODUCTS}
                            />
                        )}
                        { !store.exhibition && (
                            <>
                                <MediaQuery minWidth={901}>
                                    <SortFilterButton
                                        label="Conhecer a história"
                                        callback={() => { this.onTabChange(StoreScreenTabs.HISTORY); }}
                                        isActive={currentTab !== StoreScreenTabs.HISTORY}
                                    />
                                </MediaQuery>
                                <MediaQuery maxWidth={900}>
                                    <SortFilterButton
                                        label="História"
                                        callback={() => { this.onTabChange(StoreScreenTabs.HISTORY); }}
                                        isActive={currentTab !== StoreScreenTabs.HISTORY}
                                    />
                                </MediaQuery>
                            </>
                        )}

                        <SortFilterButton
                            label="Contactos"
                            callback={() => { this.onTabChange(StoreScreenTabs.CONTACTS); }}
                            isActive={currentTab !== StoreScreenTabs.CONTACTS}
                        />
                    </div>
                    {currentTab === StoreScreenTabs.PRODUCTS && store.id !== -1 && <ProductsListScreen storeId={store.id} storeSlug={store.slug} />}
                    {currentTab === StoreScreenTabs.HISTORY && this.renderHistoryPanel()}
                    {currentTab === StoreScreenTabs.CONTACTS && this.renderContactsPanel()}
                </div>
            </div>
        );
    }
}

export default withTranslationContext(StoreScreen);

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const productsAdminURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/products${queryString}`;
};

export const productsRandomURL = (): string => {
    return `${API_URL}/products/public/highlight`;
};

export const productURL = (id: number | string): string => {
    return `${API_URL}/products/${id}`;
};

export const productsDeleteURL = (ids: number | string | Array< number | string>): string => {
    return `${API_URL}/products?ids=${Array.isArray(ids) ? ids.join('&ids=') : ids}`;
};

export const productsDisableURL = (): string => {
    return `${API_URL}/products/disable`;
};

export const productsFindPublicURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/products/find/public${queryString}`;
};

export const productsBrandsURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/products/brands${queryString}`;
};

export const productsColorsURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/products/colors${queryString}`;
};

export const productsSizesURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/products/sizes${queryString}`;
};

export const productGroupPublicURL = (storeId: number | string, groupId: number | string): string => {
    return `${API_URL}/products/public/product-group/${storeId}/${groupId}`;
};

export const productGroupURL = (groupId: number | string): string => {
    return `${API_URL}/products/product-group/${groupId}`;
};

export const productCSVURL = (): string => {
    return `${API_URL}/products/products.csv?all=true`;
};

export const productCSVUploadURL = (storeId: number | string): string => {
    return `${API_URL}/products/import?storeId=${storeId}`;
};

export const productCSVTemplateURL = (): string => {
    return `${API_URL}/products/download-import-template`;
};

export const productMaxPriceURL = (params: object = {}) => {
    const queryString = objectToParams(params);
    return `${API_URL}/products/maxprice${queryString}`;
};

export const productsCategoriesURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/products/categories${queryString}`;
};

export const productsCampaignsURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/products/banners${queryString}`;
};

export const productCampaignEditURL = (id: string | number) => {
    return `${API_URL}/products/${id}/campaigns`;
};

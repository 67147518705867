/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import Modal from 'react-bootstrap/Modal';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Table from 'react-bootstrap/Table';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { ShippingPrice } from '../../../constants/types';
import Loader from '../../elements/Loader';
import Button from '../../elements/Button';
import FormTextField from '../../elements/FormTextField';
import {
    getFormErrors, IFormError, VALIDATIONS, validateField,
} from '../../../utils/validation';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { shippingPricesURL, shippingPriceURL } from '../../../services/shippingPrices';

type OwnProps = TranslationContext

interface OwnState {
    isFetching: boolean;
    shippingPrices: Array<ShippingPrice>;
    shippingPrice: ShippingPrice;
    showShippingPriceModal: boolean;
    isEditingShippingPrice: boolean;
    formErrors: any;
}

const initialState: OwnState = {
    isFetching: false,
    shippingPrices: [],
    showShippingPriceModal: false,
    isEditingShippingPrice: false,
    formErrors: null,
    shippingPrice: {
        id: 0,
        maxWeight: null,
        price: null,
    },
};

const shippingPricesLabels = {
    maxWeight: 'admin.shippingPrices.labels.maxWeight',
    price: 'admin.shippingPrices.labels.price',
};

class ShippingPrices extends Component<OwnProps, OwnState> {
    state = {
        ...initialState,
    };

    componentDidMount(): void {
        this.prepare();
    }

    onNewShippingPriceInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        let errors = null;
        if (e.currentTarget.name === 'price' && e.currentTarget.value.length > 0) {
            errors = validateField('price', e.currentTarget.value, VALIDATIONS.NEW_SHIPPING_PRICE_FORM);
        }

        if (!errors) {
            this.setState({
                ...this.state,
                shippingPrice: {
                    ...this.state.shippingPrice,
                    [e.currentTarget.name]: e.currentTarget.value,
                },
            });
        }
    };

    onCloseAddShippingPriceModal = () => {
        this.setState({
            showShippingPriceModal: false,
            shippingPrice: {
                ...initialState.shippingPrice,
            },
            isEditingShippingPrice: false,
        });
    };

    onShippingPriceClick = (shippingPrice: ShippingPrice) => {
        this.setState({
            shippingPrice: {
                id: shippingPrice.id,
                maxWeight: shippingPrice.maxWeight,
                price: shippingPrice.price,
            },
            showShippingPriceModal: true,
            isEditingShippingPrice: true,
        });
    };

    saveShippingPrice = () => {
        const { shippingPrice, isEditingShippingPrice } = this.state;

        if (this.validateShippingPriceFields()) {
            const fields = {
                maxWeight: Number(shippingPrice.maxWeight),
                price: Number(shippingPrice.price),
            };

            if (isEditingShippingPrice) this.editShippingPriceRequest(shippingPrice.id, fields);
        }
    };

    validateShippingPriceFields = () => {
        let errors: IFormError | null = getFormErrors(this.state.shippingPrice, VALIDATIONS.NEW_SHIPPING_PRICE_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    editShippingPriceRequest = (id: number, fields: object) => {
        const { t } = this.props;
        this.setState({ isFetching: true });

        axios.put(shippingPriceURL(id), fields)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.shippingPrices.editShippingPriceSuccess'));

                this.setState({
                    isFetching: false,
                    shippingPrice: {
                        ...initialState.shippingPrice,
                    },
                    showShippingPriceModal: false,
                    isEditingShippingPrice: false,
                }, () => this.prepare());
            }).catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.shippingPrices.editShippingPriceError'));
                this.setState({ isFetching: false });
            });
    };

    prepare = () => {
        const { isFetching } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        axios.get(shippingPricesURL())
            .then(response => {
                if (response && response.data) {
                    this.setState({
                        shippingPrices: response.data,
                        isFetching: false,
                    });
                }
            }).catch(() => {
                this.setState({ isFetching: false });
            });
    };

    render() {
        const { t } = this.props;
        const {
            isFetching,
            shippingPrices,
            showShippingPriceModal,
            formErrors,
            shippingPrice,
            isEditingShippingPrice,
        } = this.state;

        const hasData = shippingPrices && shippingPrices.length > 0;

        return (
            <div className="app-tabs__tab-content">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <Table responsive="sm">
                    <thead>
                        <tr>
                            <th>{t(shippingPricesLabels.maxWeight)}</th>
                            <th>{t(shippingPricesLabels.price)}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {hasData ? shippingPrices.map(price => {
                            return (
                                <tr onClick={() => this.onShippingPriceClick(price)}>
                                    <td>{price.maxWeight}</td>
                                    <td>{price.price}</td>
                                </tr>
                            );
                        }) : (
                            <tr>
                                <td colSpan={4}>
                                    {t('global.noData')}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                {/* ADD CATEGORY MODAL */}
                <Modal show={showShippingPriceModal} onHide={this.onCloseAddShippingPriceModal} centered>
                    <Modal.Header closeButton>
                        <ModalTitle>{isEditingShippingPrice ? t('admin.shippingPrices.editShippingPrice') : t('admin.shippingPrices.addShippingPrice')}</ModalTitle>
                    </Modal.Header>
                    <ModalBody>
                        <div className="modal-body modal-custom">
                            <FormTextField
                                name="maxWeight"
                                disabled
                                value={shippingPrice.maxWeight}
                                label={t(shippingPricesLabels.maxWeight)}
                                placeholder={t(shippingPricesLabels.maxWeight)}
                                onChange={this.onNewShippingPriceInputChange}
                                errors={get(formErrors, 'fields.maxWeight', null)}
                            />
                            <FormTextField
                                name="price"
                                value={shippingPrice.price}
                                label={t(shippingPricesLabels.price)}
                                placeholder={t(shippingPricesLabels.price)}
                                onChange={this.onNewShippingPriceInputChange}
                                errors={get(formErrors, 'fields.price', null)}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            styles="button--blue button--small"
                            text={t('global.buttons.cancel')}
                            callback={this.onCloseAddShippingPriceModal}
                        />
                        <Button
                            styles="button--dark-blue button--small"
                            text={t('global.buttons.ok')}
                            callback={this.saveShippingPrice}
                        />
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default withTranslationContext(ShippingPrices);

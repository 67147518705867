/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

export const resourcesURL = (hash: string): string => {
    if (hash === '') return '';
    return `${API_URL}/resources/view/public/${hash}`;
};

export const resourcesManualsURL = (manual: string): string => {
    return `${API_URL}/resources/download/public/manuals/${manual}`;
};

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { CartAction, CartActionTypes } from './cart_types';
import { ShoppingCart } from '../constants/types';

export const setCartIdAction = (cartId: string): CartActionTypes => {
    return {
        type: CartAction.SET_CART_ID,
        payload: cartId,
    };
};

export const updateCartAction = (cart: ShoppingCart): CartActionTypes => {
    return {
        type: CartAction.UPDATE_CART,
        payload: cart,
    };
};

export const resetCartAction = (): CartActionTypes => {
    return {
        type: CartAction.RESET_CART,
    };
};

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';

import { AppState } from '../../../reducers/types';
import { ScrollMemory } from '../../../constants/scroll';
import {
    clearProductsScrollMemoryActionCreator,
    clearStoreScrollMemoryActionCreator,
    setProductsScrollMemoryActionCreator,
    setStoreScrollMemoryActionCreator,
} from '../../../actions/scroll';
import { ScrollMemoryContextProvider } from './ScrollMemoryContext';

/**
 * @typedef {Object} StateProps
 * @property {ScrollMemory} scrollMemory
 */
export interface StateProps {
    scrollMemory: {
        storesScrollMemory: ScrollMemory | null;
        productsScrollMemory: ScrollMemory | null;
    }
}

/**
 * @typedef {Object} DispatchProps
 * @property {Function} setScrollMemory
 * @property {Function} clearScrollMemory
 */
export interface DispatchProps {
    setScrollMemoryProducts: Function;
    clearScrollMemoryProducts: Function;
    setScrollMemoryStores: Function;
    clearScrollMemoryStores: Function;
}

/**
 * @typedef {Object} OwnProps
 * @property {any} children
 */
interface OwnProps {
    children: any;
}

/**
 * @typedef {Object} Props
 */
type Props = StateProps & DispatchProps & OwnProps;

/**
 * @typedef {Object} OwnState
 */
interface OwnState {}

/**
 * @typedef {Object} State
 */
type State = OwnState;

/**
 * provides access to scroll position storage
 * @extends {Component<Props, State>}
 */
export class ScrollMemoryController extends Component<Props, State> {
    setScrollMemoryProducts = (coordinate: number, page: number, location: string): void => {
        const { setScrollMemoryProducts: setScrollMemoryProp } = this.props;
        setScrollMemoryProp(coordinate, page, location);
    }

    setScrollMemoryStores = (coordinate: number, page: number, location: string): void => {
        const { setScrollMemoryStores: setScrollMemoryProp } = this.props;
        setScrollMemoryProp(coordinate, page, location);
    }

    clearScrollMemoryProducts = ():void => {
        const { clearScrollMemoryProducts: clearScrollMemoryProp } = this.props;
        clearScrollMemoryProp();
    }

    clearScrollMemoryStores = ():void => {
        const { clearScrollMemoryStores: clearScrollMemoryProp } = this.props;
        clearScrollMemoryProp();
    }

    render() {
        const {
            children,
            scrollMemory: {
                storesScrollMemory,
                productsScrollMemory,
            },
        } = this.props;

        return (
            <ScrollMemoryContextProvider
                value={{
                    storesScrollMemory,
                    productsScrollMemory,
                    setScrollMemoryProducts: this.setScrollMemoryProducts,
                    clearScrollMemoryProducts: this.clearScrollMemoryProducts,
                    setScrollMemoryStores: this.setScrollMemoryStores,
                    clearScrollMemoryStores: this.clearScrollMemoryStores,
                }}
            >
                {children}
            </ScrollMemoryContextProvider>
        );
    }
}

/**
 * mapStateToProps
 * @param {AppState} state
 * @returns {StateProps}
 */
const mapStateToProps = (state: AppState): StateProps => {
    return {
        scrollMemory: state.scroll,
    };
};

/**
 * mapDispatchToProps
 * @param {Dispatch} dispatch
 * @returns {DispatchProps}
 */
export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    setScrollMemoryProducts: (coordinate: number, page: number, location: string) => dispatch(setProductsScrollMemoryActionCreator(coordinate, page, location)),
    clearScrollMemoryProducts: () => dispatch(clearProductsScrollMemoryActionCreator()),
    setScrollMemoryStores: (coordinate: number, page: number, location: string) => dispatch(setStoreScrollMemoryActionCreator(coordinate, page, location)),
    clearScrollMemoryStores: () => dispatch(clearStoreScrollMemoryActionCreator()),
});

export const ConnectedScrollMemoryController = connect(mapStateToProps, mapDispatchToProps)(ScrollMemoryController);

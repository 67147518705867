/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import axios from 'axios';

import arrow from '../../assets/images/categories/ic-seta-branca.svg';
import chevron from '../../assets/images/icon-down_arrow_blue.svg';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { ALL_PRODUCTS_VIEW_ROUTE } from '../../constants/routes';
import { Category } from '../../constants/types';
import { categoriesPublicURL } from '../../services/categories';
import { scrollToTop } from '../../utils/misc';

interface OwnProps extends TranslationContext, RouteComponentProps {}

interface OwnState {
    categories: Array<Category>;
    isExpanded: boolean;
}

const initialState = {
    categories: [],
    isExpanded: false,
};

class HomeCategoriesSelector extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        this.prepare();
    }

    onCategoryClick = (id: number):void => {
        const { history } = this.props;
        history.push(`${ALL_PRODUCTS_VIEW_ROUTE}?categoryId=${id}`);
        setTimeout(() => {
            scrollToTop();
        },
        400);
    }

    prepare = async () => {
        axios.get(categoriesPublicURL())
            .then(res => {
                this.setState({
                    categories: res.data,
                });
            })
            .catch(err => {
                if (err.response?.status === 401) {
                    this.prepare();
                }
            });
    }

    toggleExpand = ():void => {
        const { isExpanded } = this.state;
        this.setState({
            isExpanded: !isExpanded,
        });
    }

    render() {
        const { categories, isExpanded } = this.state;

        return (
            <>
                <div className={`home-categories-selector${isExpanded ? ' expanded' : ''}`}>
                    {categories.map((cat: Category) => {
                        return (
                            <div
                                key={cat.id}
                                style={{ backgroundImage: `url(${cat.thumbnailUrl})` }}
                                className="home-categories-selector__panel"
                                onClick={() => this.onCategoryClick(cat.id)}
                            >
                                <div>
                                    <p>{cat.description}</p>
                                    <img src={arrow} alt="" />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={`home-categories-button${isExpanded ? '--expanded' : ''}`} onClick={this.toggleExpand}>
                    <img src={chevron} alt="" />
                </div>
            </>
        );
    }
}

export default withTranslationContext(withRouter(HomeCategoriesSelector));

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */
import { CartState } from './types';
import { CartAction } from '../actions/cart_types';

export const cartInitialState: CartState = {
    cartId: null,
    shoppingCart: null,
};

export default function (state = cartInitialState, action: any): CartState {
    switch (action.type) {
        case CartAction.SET_CART_ID:
            return {
                ...state,
                cartId: action.payload,
            };
        case CartAction.UPDATE_CART: {
            return {
                ...state,
                shoppingCart: { ...action.payload },
            };
        }
        case CartAction.RESET_CART: {
            return {
                ...cartInitialState,
            };
        }
        default:
            return state;
    }
}

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
*/

import React from 'react';
import axios from 'axios';
import MediaQuery from 'react-responsive';
import { RouteComponentProps } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import { get } from 'lodash';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { GeneralContext, withGeneralContext } from '../controllers/general/GeneralContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import iconGratis from '../../assets/images/ic-gratis.svg';
import iconOnline from '../../assets/images/ic-online.svg';
import iconParcerias from '../../assets/images/ic-parcerias.svg';
import imageLeft from '../../assets/images/img-comerciolocal.png';
import imageRight from '../../assets/images/img-compras-seguras.png';
import { JoinUsFormFields } from '../../constants/types';
import Button from '../elements/Button';
import { MatchParams, SelectOption } from '../../constants/misc';
import { categoriesPublicURL } from '../../services/categories';
import FormSelectField from '../elements/FormSelectField';
import FormTextField from '../elements/FormTextField';
import { RECAPTCHA_KEY } from '../../settings';
import Loader from '../elements/Loader';
import { scrollToTop } from '../../utils/misc';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';
import { joinMessageURL } from '../../services/messages';
import greenTickIcon from '../../assets/images/green_tick.svg';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext, GeneralContext {}

interface OwnState {
    showSuccessModal: boolean;
    showErrorModal: boolean;
    formErrors: any;
    isFetching: boolean;
    hasReCaptchaError: boolean;
    recaptchaResponse: string;
    formFields: JoinUsFormFields;
    categoryList: Array<SelectOption>;
}

const initialState = {
    showSuccessModal: false,
    showErrorModal: false,
    formErrors: null,
    isFetching: false,
    hasReCaptchaError: false,
    recaptchaResponse: '',
    formFields: {
        name: '',
        email: '',
        storeName: '',
        phone: '',
        categoryId: 0,
        address: '',
        postalCode: '',
        city: '',
    },
    categoryList: [],
};

const FormFieldIdentifiers = {
    name: 'FORM_NAME',
    email: 'FORM_EMAIL',
    storeName: 'FORM_STORE',
    phone: 'FORM_PHONE',
    category: 'FORM_CATEGORY',
    address: 'FORM_ADDRESS',
    postalCode: 'FORM_POSTAL_CODE',
    city: 'FORM_CITY',
};

export class JoinUsScreen extends React.Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { history } = this.props;

        setTimeout(() => {
            scrollToTop();
            if (history.location.hash) history.location.hash = '';
        },
        400);

        this.setState({
            ...this.state,
            isFetching: true,
        });

        this.loadCategories();
    }

    onCloseSuccessModal = () => {
        const prevState = this.state;
        this.setState({
            ...prevState,
            showSuccessModal: false,
        });
    }

    onCloseErrorModal = () => {
        const prevState = this.state;
        this.setState({
            ...prevState,
            showErrorModal: false,
        });
    }

    onRecaptchaVerify = (response: string | null) => {
        if (response) {
            this.setState({
                ...this.state,
                hasReCaptchaError: false,
                recaptchaResponse: response,
            });
        } else {
            this.setState({
                ...this.state,
                hasReCaptchaError: true,
            });
        }
    }

    onJoinSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const prevState = this.state;
        const { recaptchaResponse } = this.state;

        if (!recaptchaResponse || recaptchaResponse === '') {
            this.setState({
                ...prevState,
                hasReCaptchaError: true,
            });
            return;
        }

        if (this.validateForm()) {
            this.sendJoinRequest();
        }
    }

    loadCategories = () => {
        const { t } = this.props;

        axios.get(categoriesPublicURL()).then(res => {
            const optsArrayAux: Array<SelectOption> = [{
                value: '-1',
                label: t('joinUs.form.fields.category'),
            }];

            res.data.forEach((cat: {id: string, description: string}) => {
                optsArrayAux.push({
                    value: cat.id,
                    label: cat.description,
                });
            });

            this.setState({
                ...this.state,
                isFetching: false,
                categoryList: optsArrayAux,
            });
        }).catch(() => {
            this.setState({
                ...this.state,
                isFetching: false,
            });
        });
    }

    validateForm = () => {
        const prevState = this.state;
        const { formFields } = this.state;
        let errors: IFormError | null = getFormErrors(formFields, VALIDATIONS.JOIN_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({
            ...prevState,
            formErrors: errors ? { fields: errors } : errors,
        });

        return errors === null;
    }

    sendJoinRequest = () => {
        const prevState = this.state;
        const { formFields: fields, recaptchaResponse } = this.state;

        this.setState({
            ...prevState,
            isFetching: true,
        });

        axios.post(joinMessageURL({
            'g-recaptcha-response': recaptchaResponse,
        }), fields)
            .then(() => {
                this.setState({
                    ...initialState,
                    showSuccessModal: true,
                });
                this.loadCategories();
            })
            .catch(error => {
                if (error && error.response) {
                    const { status, data } = error.response;
                    if (status === 400 && !data.result) {
                        this.handleResponse(data);
                    } else {
                        this.setState({
                            ...prevState,
                            showSuccessModal: false,
                            showErrorModal: true,
                            isFetching: false,
                        });
                    }
                } else {
                    this.setState({
                        ...prevState,
                        showSuccessModal: false,
                        showErrorModal: true,
                        isFetching: false,
                    });
                }
            });
    }

    handleResponse = (formErrors: any = null) => {
        const prevState = this.state;

        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                ...prevState,
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({
                ...prevState,
                isFetching: false,
            });
        }
    }

    setCategory = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { formFields } = this.state;
        this.setState({
            ...this.state,
            formFields: {
                ...formFields,
                categoryId: Number.parseInt(e.target.value),
            },
        });
    }

    setFormFields = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { formFields } = this.state;
        switch (e.target.name.valueOf()) {
            case FormFieldIdentifiers.name:
                this.setState(
                    {
                        ...this.state,
                        formFields: {
                            ...formFields,
                            name: e.target.value,
                        },
                    },
                );
                break;
            case FormFieldIdentifiers.email:
                this.setState(
                    {
                        ...this.state,
                        formFields: {
                            ...formFields,
                            email: e.target.value,
                        },
                    },
                );
                break;
            case FormFieldIdentifiers.storeName:
                this.setState(
                    {
                        ...this.state,
                        formFields: {
                            ...formFields,
                            storeName: e.target.value,
                        },
                    },
                );
                break;
            case FormFieldIdentifiers.phone:
                this.setState(
                    {
                        ...this.state,
                        formFields: {
                            ...formFields,
                            phone: e.target.value,
                        },
                    },
                );
                break;
            case FormFieldIdentifiers.address:
                this.setState(
                    {
                        ...this.state,
                        formFields: {
                            ...formFields,
                            address: e.target.value,
                        },
                    },
                );
                break;
            case FormFieldIdentifiers.postalCode:
                this.setState(
                    {
                        ...this.state,
                        formFields: {
                            ...formFields,
                            postalCode: e.target.value,
                        },
                    },
                );
                break;
            case FormFieldIdentifiers.city:
                this.setState(
                    {
                        ...this.state,
                        formFields: {
                            ...formFields,
                            city: e.target.value,
                        },
                    },
                );
                break;
            default: break;
        }
    }

    renderQuestions = () => {
        const { t } = this.props;
        return (
            <div className="join-us-screen__questions">
                <h2>{t('joinUs.body.whatIsLM.title')}</h2>
                <p>{t('joinUs.body.whatIsLM.body.line1')}</p>
                <p>{t('joinUs.body.whatIsLM.body.line2')}</p>

                <h2>{t('joinUs.body.whatAdvantages.title')}</h2>
                <p>{t('joinUs.body.whatAdvantages.body.line1')}</p>
                <p className="bold">{t('joinUs.body.whatAdvantages.body.line2')}</p>
            </div>
        );
    }

    renderFeatures = () => {
        const { t } = this.props;
        return (
            <>
                <MediaQuery minWidth={900}>
                    <div className="join-us-screen__features--desktop">
                        <div className="join-us-screen__features--desktop__item">
                            <div className="join-us-screen__features--desktop__item__icon-container">
                                <img src={iconGratis} alt="" />
                            </div>
                            <h2>{t('joinUs.features.free.title')}</h2>
                            <h3>{t('joinUs.features.free.body')}</h3>
                        </div>
                        <div className="join-us-screen__features--desktop__item">
                            <div className="join-us-screen__features--desktop__item__icon-container">
                                <img src={iconOnline} alt="" />
                            </div>
                            <h2>{t('joinUs.features.online.title')}</h2>
                            <h3>{t('joinUs.features.online.body')}</h3>
                        </div>
                        <div className="join-us-screen__features--desktop__item">
                            <div className="join-us-screen__features--desktop__item__icon-container">
                                <img src={iconParcerias} alt="" />
                            </div>
                            <h2>{t('joinUs.features.partners.title')}</h2>
                            <h3>{t('joinUs.features.partners.body')}</h3>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={900}>
                    <div className="join-us-screen__features--mobile">
                        <div className="join-us-screen__features--mobile__item">
                            <div className="join-us-screen__features--mobile__item__icon-container">
                                <img src={iconGratis} alt="" />
                            </div>
                            <div className="join-us-screen__features--mobile__item__text-container">
                                <h2>{t('joinUs.features.free.title')}</h2>
                                <h3>{t('joinUs.features.free.body')}</h3>
                            </div>
                        </div>
                        <div className="join-us-screen__features--mobile__item">
                            <div className="join-us-screen__features--mobile__item__icon-container">
                                <img src={iconOnline} alt="" />
                            </div>
                            <div className="join-us-screen__features--mobile__item__text-container">
                                <h2>{t('joinUs.features.online.title')}</h2>
                                <h3>{t('joinUs.features.online.body')}</h3>
                            </div>
                        </div>
                        <div className="join-us-screen__features--mobile__item">
                            <div className="join-us-screen__features--mobile__item__icon-container">
                                <img src={iconParcerias} alt="" />
                            </div>
                            <div className="join-us-screen__features--mobile__item__text-container">
                                <h2>{t('joinUs.features.partners.title')}</h2>
                                <h3>{t('joinUs.features.partners.body')}</h3>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
            </>
        );
    }

    renderTaglines = () => {
        const { t } = this.props;
        return (
            <>
                <MediaQuery minWidth={900}>
                    <div className="join-us-screen__taglines--desktop">
                        <div className="join-us-screen__taglines--desktop__container">
                            <div className="join-us-screen__taglines--desktop__container__image--left">
                                <img src={imageLeft} alt="" />
                                <div>
                                    <h2>{t('joinUs.taglines.left.line1')}</h2>
                                    <p>{t('joinUs.taglines.left.line2')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="join-us-screen__taglines--desktop__container">
                            <div className="join-us-screen__taglines--desktop__container__image--right">
                                <img src={imageRight} alt="" />
                                <div>
                                    <h2>{t('joinUs.taglines.right.line1')}</h2>
                                    <p>{t('joinUs.taglines.right.line2')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={900}>
                    <div className="join-us-screen__taglines--mobile">
                        <div className="join-us-screen__taglines--mobile__container--left">
                            <div>
                                <h2>{t('joinUs.taglines.left.line1')}</h2>
                                <p>{t('joinUs.taglines.left.line2')}</p>
                            </div>
                        </div>
                        <div className="join-us-screen__taglines--mobile__container--right">
                            <div>
                                <h2>{t('joinUs.taglines.right.line1')}</h2>
                                <p>{t('joinUs.taglines.right.line2')}</p>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
            </>
        );
    }

    renderForm = () => {
        const { t } = this.props;
        const {
            categoryList,
            formFields,
            hasReCaptchaError,
            formErrors,
            showErrorModal,
            showSuccessModal,
        } = this.state;

        return (
            <div className="join-us-screen__form">
                <h2>{t('joinUs.form.title')}</h2>
                <form onSubmit={this.onJoinSubmit}>
                    <FormTextField
                        value={formFields.name}
                        type="text"
                        placeholder={`${t('joinUs.form.fields.name')}*`}
                        name={FormFieldIdentifiers.name}
                        onChange={this.setFormFields}
                        errors={get(formErrors, 'fields.name', null)}
                    />
                    <FormTextField
                        value={formFields.email}
                        type="text"
                        placeholder={`${t('joinUs.form.fields.email')}*`}
                        name={FormFieldIdentifiers.email}
                        onChange={this.setFormFields}
                        errors={get(formErrors, 'fields.email', null)}
                    />
                    <FormTextField
                        value={formFields.storeName}
                        type="text"
                        placeholder={`${t('joinUs.form.fields.store')}*`}
                        name={FormFieldIdentifiers.storeName}
                        onChange={this.setFormFields}
                        errors={get(formErrors, 'fields.storeName', null)}
                    />
                    <FormTextField
                        value={formFields.phone}
                        type="text"
                        placeholder={`${t('joinUs.form.fields.phone')}*`}
                        name={FormFieldIdentifiers.phone}
                        onChange={this.setFormFields}
                        errors={get(formErrors, 'fields.phone', null)}
                    />
                    <FormSelectField
                        value={formFields.categoryId}
                        name={FormFieldIdentifiers.category}
                        options={categoryList}
                        onChange={this.setCategory}
                        errors={get(formErrors, 'fields.categoryId', null)}
                    />
                    <FormTextField
                        value={formFields.address}
                        type="text"
                        placeholder={`${t('joinUs.form.fields.address')}*`}
                        name={FormFieldIdentifiers.address}
                        onChange={this.setFormFields}
                        errors={get(formErrors, 'fields.address', null)}
                    />
                    <div className="join-us-screen__form--double-line">
                        <FormTextField
                            value={formFields.postalCode}
                            type="text"
                            placeholder={`${t('joinUs.form.fields.postalCode')}*`}
                            name={FormFieldIdentifiers.postalCode}
                            onChange={this.setFormFields}
                            errors={get(formErrors, 'fields.postalCode', null)}
                        />
                        <FormTextField
                            value={formFields.city}
                            type="text"
                            placeholder={`${t('joinUs.form.fields.city')}*`}
                            name={FormFieldIdentifiers.city}
                            onChange={this.setFormFields}
                            errors={get(formErrors, 'fields.city', null)}
                        />
                    </div>
                    <div className="join-us-screen__form__recaptcha">
                        <ReCAPTCHA
                            sitekey={String(RECAPTCHA_KEY)}
                            onChange={this.onRecaptchaVerify}
                            hl="pt-PT"
                        />
                        {hasReCaptchaError && (
                            <p className="recaptcha-error">
                                {`${t('home.reCaptchaError')}`}
                            </p>
                        )}
                    </div>
                    <Button
                        type="submit"
                        styles="button--blue"
                        text={t('joinUs.form.button')}
                    />
                </form>
                <Modal className="modal-custom" show={showSuccessModal} onHide={this.onCloseSuccessModal} centered>
                    <ModalHeader className="success-modal-header" closeButton>
                        <ModalTitle>
                            <div className="success-modal">
                                <img className="green-tick" src={greenTickIcon} alt="" />
                                <h2 className="my-2">{t('home.contact.success')}</h2>
                                <h5>{t('home.contact.successMessage')}</h5>
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                </Modal>
                <Modal className="modal-custom" show={showErrorModal} onHide={this.onCloseErrorModal} centered>
                    <ModalHeader className="success-modal-header" closeButton>
                        <ModalTitle>
                            <div className="success-modal">
                                <h2 className="my-2 red-text">{t('home.contact.error')}</h2>
                                <h5>{t('home.contact.errorMessage')}</h5>
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                </Modal>
            </div>
        );
    }

    render() {
        const { t } = this.props;
        const { isFetching } = this.state;

        return (
            <>
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div id="join_us_screen" className="join-us-screen">
                    <div className="join-us-screen__carousel-container">
                        <div className="join-us-screen__carousel-container__header masthead centered">
                            <div className="container">
                                <div className="row justify-content-start align-content-start container__center">
                                    <div className="col-lg-10 align-self-end">
                                        <h2 className="pretitle">{t('joinUs.header.pretitle')}</h2>
                                    </div>
                                    <div className="join-us-screen__carousel-container__header__title-container col-lg-5">
                                        <h1 className="title">{t('joinUs.header.title')}</h1>
                                        <h2 className="subtitle">{t('joinUs.header.subtitle')}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderQuestions()}
                    {this.renderFeatures()}
                    {this.renderTaglines()}
                    {this.renderForm()}
                </div>
            </>
        );
    }
}

export default withGeneralContext(withTranslationContext(JoinUsScreen));

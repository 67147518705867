/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import Modal from 'react-bootstrap/Modal';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { Category, Subcategory } from '../../../constants/types';
import Loader from '../../elements/Loader';
import {
    categoriesAdminURL,
    categoryAdminURL,
    subcategoriesAdminURL,
    subcategoryAdminURL,
} from '../../../services/categories';
import Button from '../../elements/Button';
import FormTextField from '../../elements/FormTextField';
import { getFormErrors, IFormError, VALIDATIONS } from '../../../utils/validation';
import { enabledSubcategories, globalButtonLabels, SelectOption } from '../../../constants/misc';
import FormSelectField from '../../elements/FormSelectField';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import ImageUpload from '../../elements/ImageUpload';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import displayConfirm from '../../elements/displayConfirm';

type OwnProps = TranslationContext

interface OwnState {
    isFetching: boolean;
    categories: Array<Category>;
    category: Category;
    subcategory: Subcategory;
    showCategoryModal: boolean;
    showSubcategoryModal: boolean;
    isEditingCategory: boolean;
    isEditingSubcategory: boolean;
    formErrors: any;
    categoriesOptions: Array<SelectOption>;
}

const initialState: OwnState = {
    isFetching: false,
    categories: [],
    showCategoryModal: false,
    showSubcategoryModal: false,
    isEditingCategory: false,
    isEditingSubcategory: false,
    formErrors: null,
    categoriesOptions: [],
    category: {
        id: 0,
        description: '',
        longDescription: '',
        thumbnailUrl: '',
        headerUrl: '',
        thumbnail: null,
    },
    subcategory: {
        id: 0,
        description: '',
        mainCategoryId: null,
    },
};

const categoriesLabels = {
    description: 'admin.categories.labels.description',
    selectMainCategory: 'admin.categories.labels.selectMainCategory',
    thumbnailHelpText: 'admin.categories.labels.thumbnailHelpText',
    title: 'admin.categories.labels.title',
    mainCategory: 'admin.categories.labels.mainCategory',
};

class Categories extends Component<OwnProps, OwnState> {
    state = {
        ...initialState,
    };

    componentDidMount(): void {
        this.prepare();
    }

    onNewCategoryInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            category: {
                ...this.state.category,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onCategoryImageSelected = (file: File) => {
        this.setState({
            ...this.state,
            category: {
                ...this.state.category,
                thumbnail: file,
            },
        });
    };

    onNewSubcategoryInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            subcategory: {
                ...this.state.subcategory,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onAddCategory = () => {
        this.setState({
            showCategoryModal: true,
        });
    };

    onCloseAddCategoryModal = () => {
        this.setState({
            showCategoryModal: false,
            category: {
                ...initialState.category,
            },
            isEditingCategory: false,
        });
    };

    onAddSubcategory = () => {
        this.setState({
            showSubcategoryModal: true,
        });
    };

    onCategoryClick = (category: Category) => {
        this.setState({
            category: {
                ...category,
            },
            showCategoryModal: true,
            isEditingCategory: true,
        });
    };

    onEditSubcategory = (sub: Subcategory) => {
        this.setState({
            subcategory: {
                id: sub.id,
                description: sub.description,
                mainCategoryId: sub.mainCategory ? sub.mainCategory.id : null,
            },
            showSubcategoryModal: true,
            isEditingSubcategory: true,
            showCategoryModal: false,
        });
    };

    onCloseAddSubcategoryModal = () => {
        this.setState({
            showSubcategoryModal: false,
            subcategory: {
                ...initialState.subcategory,
            },
            isEditingSubcategory: false,
        });
    };

    onDeleteCategory = (e: React.MouseEvent, category: Category) => {
        e.stopPropagation();

        const { t } = this.props;

        if (category.inUse) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.categories.categoryInUse'));
            return;
        }

        displayConfirm({
            acceptButtonText: t(globalButtonLabels.ok),
            onAccept: () => this.deleteCategory(category.id),
            onReject: () => {},
            rejectButtonText: t(globalButtonLabels.cancel),
            message: t('admin.categories.removeCategoryConfirmation', { description: category.description }),
        });
    };

    onDeleteSubcategory = (e: React.MouseEvent, subCategory: Subcategory) => {
        e.stopPropagation();

        const { t } = this.props;

        if (subCategory.inUse) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.categories.categoryInUse'));
            return;
        }

        displayConfirm({
            acceptButtonText: t(globalButtonLabels.ok),
            onAccept: () => this.deleteSubcategory(subCategory.id),
            onReject: () => {},
            rejectButtonText: t(globalButtonLabels.cancel),
            message: t('admin.categories.removeSubcategoryConfirmation', { description: subCategory.description }),
        });
    };

    saveCategory = () => {
        const { category, isEditingCategory } = this.state;

        if (this.validateCategoryFields()) {
            const formData = new FormData();
            const fields = {
                description: String(category.description).trim(),
                longDescription: String(category.longDescription).trim(),
            };

            if (category.thumbnail) {
                formData.append('thumbnail', category.thumbnail, category.thumbnail.name);
            }

            formData.append('category', new Blob([JSON.stringify(fields)], {
                type: 'application/json',
            }));

            if (isEditingCategory) this.editCategoryRequest(category.id, formData);
            else this.createNewCategoryRequest(formData);
        }
    };

    saveSubcategory = () => {
        const { subcategory, isEditingSubcategory } = this.state;

        if (this.validateSubcategoryFields()) {
            const fields = {
                description: String(subcategory.description).trim(),
                mainCategoryId: subcategory.mainCategoryId,
            };

            if (isEditingSubcategory) this.editSubcategoryRequest(subcategory.id, fields);
            else this.createNewSubcategoryRequest(fields);
        }
    };

    validateCategoryFields = () => {
        let errors: IFormError | null = getFormErrors(this.state.category, VALIDATIONS.NEW_CATEGORY_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    validateSubcategoryFields = () => {
        let errors: IFormError | null = getFormErrors(this.state.subcategory, VALIDATIONS.NEW_SUBCATEGORY_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    deleteCategory = (id: number) => {
        const { t } = this.props;
        this.setState({ isFetching: true });

        axios.delete(categoryAdminURL(id))
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.categories.removeCategorySuccess'));
                this.setState({ isFetching: false }, () => this.prepare());
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.categories.removeCategoryError'));
                this.setState({ isFetching: false });
            });
    };

    deleteSubcategory = (id: number) => {
        const { t } = this.props;
        this.setState({ isFetching: true });

        axios.delete(subcategoryAdminURL(id))
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.categories.removeSubcategorySuccess'));
                this.setState({ isFetching: false }, () => this.prepare(true));
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.categories.removeSubcategoryError'));
                this.setState({ isFetching: false });
            });
    };

    updateCategoryShowing = () => {
        const { categories, category } = this.state;
        const newCategory: Category | undefined = categories.find(c => c.id === category.id);

        if (newCategory) {
            this.setState({
                ...this.state,
                category: { ...newCategory },
            });
        }
    };

    editCategoryRequest = (id: number, formData: FormData) => {
        const { t } = this.props;
        this.setState({ isFetching: true });

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        axios.put(categoryAdminURL(id), formData, config)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.categories.editCategorySuccess'));

                this.setState({
                    isFetching: false,
                    category: {
                        ...initialState.category,
                    },
                    showCategoryModal: false,
                    isEditingCategory: false,
                }, () => this.prepare());
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.categories.editCategoryError'));
                this.setState({ isFetching: false });
            });
    };

    editSubcategoryRequest = (id: number, fields: object) => {
        const { t } = this.props;
        this.setState({ isFetching: true });

        axios.put(subcategoryAdminURL(id), fields)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.categories.editSubcategorySuccess'));
                this.setState({
                    isFetching: false,
                    subcategory: {
                        ...initialState.category,
                    },
                    showSubcategoryModal: false,
                    isEditingSubcategory: false,
                }, () => this.prepare());
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.categories.editSubcategoryError'));
                this.setState({ isFetching: false });
            });
    };

    createNewCategoryRequest = (formData: FormData) => {
        const { t } = this.props;

        this.setState({ isFetching: true });

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        axios.post(categoriesAdminURL(), formData, config)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.categories.addCategorySuccess'));

                this.setState({
                    isFetching: false,
                    category: {
                        ...initialState.category,
                    },
                    showCategoryModal: false,
                }, () => this.prepare());
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.categories.addCategoryError'));
                this.setState({ isFetching: false });
            });
    };

    createNewSubcategoryRequest = (fields: object) => {
        const { t } = this.props;
        this.setState({ isFetching: true });

        axios.post(subcategoryAdminURL(), fields)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.categories.addSubcategorySuccess'));

                this.setState({
                    isFetching: false,
                    subcategory: {
                        ...initialState.subcategory,
                    },
                    showSubcategoryModal: false,
                }, () => this.prepare());
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.categories.addSubcategoryError'));
                this.setState({ isFetching: false });
            });
    };

    prepare = (updateCategory = false) => {
        const { t } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        const urls = [categoriesAdminURL(), subcategoriesAdminURL()];

        axios.all(urls.map(url => axios.get(url)))
            .then(axios.spread((categoriesResponse, subcategoriesResponse) => {
                const categoriesOptions: Array<SelectOption> = [];
                let categories: Category[] = [];
                if (categoriesResponse && categoriesResponse.data) {
                    categories = categoriesResponse.data;
                    categoriesOptions.push({
                        value: '',
                        label: t(categoriesLabels.selectMainCategory),
                    });

                    Object.keys(categoriesResponse.data).forEach(k => {
                        const c = categoriesResponse.data[Number(k)];
                        categoriesOptions.push({
                            value: c.id,
                            label: c.description,
                        });
                    });

                    if (subcategoriesResponse && subcategoriesResponse.data) {
                        const { data: subs } = subcategoriesResponse;

                        Object.keys(subs).forEach(k => {
                            const sub = subs[Number(k)];
                            const { mainCategory } = sub;
                            const index = categories.findIndex(c => c.id === mainCategory.id);
                            const category = categories[index];

                            if (category && category.subcategories) {
                                category.subcategories.push(sub);
                                categories[index] = category;
                            } else if (category && !category.subcategories) {
                                category.subcategories = [];
                                category.subcategories.push(sub);
                                categories[index] = category;
                            }
                        });
                    }
                }

                this.setState({
                    categoriesOptions,
                    categories,
                    isFetching: false,
                }, () => {
                    if (updateCategory) {
                        this.updateCategoryShowing();
                    }
                });
            }))
            .catch(() => {
                this.setState({ isFetching: false });
            });
    };

    renderSubcategories = (category: Category) => {
        if (category.subcategories && category.subcategories.length > 0) {
            return (
                <div className="subcategories-list">
                    {Object.keys(category.subcategories).map(k => {
                        // @ts-ignore
                        const sub = category.subcategories[Number(k)];

                        return (
                            <p key={sub.id}>- {sub.description}</p>
                        );
                    })}
                </div>
            );
        }
        return <React.Fragment />;
    };

    render() {
        const { t } = this.props;
        const {
            isFetching,
            categories,
            showCategoryModal,
            formErrors,
            category,
            showSubcategoryModal,
            subcategory,
            categoriesOptions,
            isEditingSubcategory,
            isEditingCategory,
        } = this.state;

        const hasData = categories && categories.length > 0;

        return (
            <div className="app-tabs__tab-content">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="row justify-content-end buttons-container">
                    <div className="button-wrapper">
                        <Button
                            styles="button--dark-blue button--small"
                            text={t('admin.categories.addCategory')}
                            callback={this.onAddCategory}
                        />
                    </div>
                    {enabledSubcategories && (
                        <div className="button-wrapper">
                            <Button
                                styles="button--dark-blue button--small"
                                text={t('admin.categories.addSubcategory')}
                                callback={this.onAddSubcategory}
                            />
                        </div>
                    )}
                </div>
                <ul>
                    {hasData ? Object.keys(categories).map(k => {
                        const category = categories[Number(k)];
                        return (
                            <li className="list-group-item category-item" key={category.id} onClick={() => this.onCategoryClick(category)}>
                                <div className="d-flex w-100 justify-content-between">
                                    <h5 className="mb-1">{category.description}</h5>
                                    <SvgIcon icon={ICON.TRASH} callback={(e: React.MouseEvent) => this.onDeleteCategory(e, category)} />
                                </div>
                                {enabledSubcategories && this.renderSubcategories(category)}
                            </li>
                        );
                    }) : (
                        <div className="no-data">
                            {t('global.noData')}
                        </div>
                    )}
                </ul>
                {/* ADD CATEGORY MODAL */}
                <Modal show={showCategoryModal} onHide={this.onCloseAddCategoryModal} centered>
                    <Modal.Header closeButton>
                        <ModalTitle>{isEditingCategory ? t('admin.categories.editCategory') : t('admin.categories.addCategory')}</ModalTitle>
                    </Modal.Header>
                    <ModalBody>
                        <div className="modal-body modal-custom">
                            <ImageUpload
                                imageUrl={category.thumbnailUrl}
                                onSelected={this.onCategoryImageSelected}
                                buttonText={t('global.buttons.upload')}
                                helpText={t(categoriesLabels.thumbnailHelpText)}
                                errors={get(formErrors, 'fields.thumbnail', null)}
                            />
                            <FormTextField
                                name="description"
                                value={category.description}
                                label={t(categoriesLabels.title)}
                                placeholder={t(categoriesLabels.title)}
                                onChange={this.onNewCategoryInputChange}
                                maxLength={35}
                                errors={get(formErrors, 'fields.description', null)}
                            />
                            <FormTextField
                                name="longDescription"
                                value={category.longDescription}
                                label={t(categoriesLabels.description)}
                                placeholder={t(categoriesLabels.description)}
                                onChange={this.onNewCategoryInputChange}
                                maxLength={80}
                                errors={get(formErrors, 'fields.longDescription', null)}
                            />
                            {(category.subcategories && category.subcategories.length > 0) && (
                                <ul>
                                    <h5 className="small-title">{t('admin.categories.subcategories')}</h5>
                                    {Object.keys(category.subcategories).map(k => {
                                        // @ts-ignore
                                        const sub = category.subcategories[Number(k)];

                                        return (
                                            <li key={sub.id} className="list-group-item subcategory-item" onClick={() => this.onEditSubcategory(sub)}>
                                                <div className="d-flex w-100 justify-content-between">
                                                    <p>{sub.description}</p>
                                                    <SvgIcon icon={ICON.TRASH} callback={(e: React.MouseEvent) => this.onDeleteSubcategory(e, sub)} />
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            )}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            styles="button--blue button--small"
                            text={t(globalButtonLabels.cancel)}
                            callback={this.onCloseAddCategoryModal}
                        />
                        <Button
                            styles="button--dark-blue button--small"
                            text={t(globalButtonLabels.ok)}
                            callback={this.saveCategory}
                        />
                    </ModalFooter>
                </Modal>
                {/* ADD SUBCATEGORY MODAL */}
                <Modal show={showSubcategoryModal} onHide={this.onCloseAddSubcategoryModal} centered>
                    <Modal.Header closeButton>
                        <ModalTitle>{isEditingSubcategory ? t('admin.categories.editSubcategory') : t('admin.categories.addSubcategory')}</ModalTitle>
                    </Modal.Header>
                    <ModalBody>
                        <div className="modal-body">
                            <FormTextField
                                name="description"
                                value={subcategory.description}
                                label={t(categoriesLabels.description)}
                                placeholder={t(categoriesLabels.description)}
                                onChange={this.onNewSubcategoryInputChange}
                                errors={get(formErrors, 'fields.description', null)}
                                maxLength={40}
                            />
                            <FormSelectField
                                name="mainCategoryId"
                                label={t(categoriesLabels.mainCategory)}
                                placeholder={t(categoriesLabels.mainCategory)}
                                value={String(subcategory.mainCategoryId)}
                                options={categoriesOptions}
                                onChange={this.onNewSubcategoryInputChange}
                                errors={get(formErrors, 'fields.mainCategoryId', null)}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            styles="button--blue button--small"
                            text={t(globalButtonLabels.cancel)}
                            callback={this.onCloseAddSubcategoryModal}
                        />
                        <Button
                            styles="button--dark-blue button--small"
                            text={t(globalButtonLabels.ok)}
                            callback={this.saveSubcategory}
                        />
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default withTranslationContext(Categories);

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { RolePermission } from '../constants/authorization';

/**
 * AuthorizationAction
 * @enum {string}
 */
export enum AuthorizationAction {
    PERMISSIONS_REQUEST = 'PERMISSIONS_REQUEST',
    PERMISSIONS_SUCCESS = 'PERMISSIONS_SUCCESS',
    PERMISSIONS_FAILURE = 'PERMISSIONS_FAILURE',
    PERMISSIONS_SET = 'PERMISSIONS_SET',
    PERMISSIONS_RESET = 'PERMISSIONS_RESET',
}

/**
 * @typedef {Object} PermissionsRequestAction
 * @property {string} type
 */
export interface PermissionsRequestAction {
    type: typeof AuthorizationAction.PERMISSIONS_REQUEST;
}

/**
 * @typedef {Object} PermissionsSuccessAction
 * @property {string} type
 * @property {RolePermission[]} payload
 */
export interface PermissionsSuccessAction {
    type: typeof AuthorizationAction.PERMISSIONS_SUCCESS;
    payload: RolePermission[];
}

/**
 * @typedef {Object} PermissionsFailureAction
 * @property {string} type
 * @property {object} payload
 */
export interface PermissionsFailureAction {
    type: typeof AuthorizationAction.PERMISSIONS_FAILURE;
    payload: object;
}

/**
 * @typedef {Object} SetPermissionsAction
 * @property {string} type
 * @property {object} payload
 */
export interface SetPermissionsAction {
    type: typeof AuthorizationAction.PERMISSIONS_SET;
    payload: object;
}

/**
 * @typedef {Object} ResetPermissionsAction
 * @property {string} type
 */
export interface ResetPermissionsAction {
    type: typeof AuthorizationAction.PERMISSIONS_RESET;
}

export type AuthorizationActionTypes = PermissionsRequestAction | PermissionsSuccessAction | PermissionsFailureAction | SetPermissionsAction | ResetPermissionsAction;

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import L, { Icon, LatLng, LeafletMouseEvent } from 'leaflet';
import { Map, Marker, TileLayer } from 'react-leaflet';
import { Col, Row } from 'react-bootstrap';

import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { AuthenticationContext, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import mapMarker from '../../../assets/images/map_marker.svg';
import Loader from '../../elements/Loader';
import { Store } from '../../../constants/types';
import FormTextField from '../../elements/FormTextField';
import Button from '../../elements/Button';
import { storeURL } from '../../../services/stores';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import {
    getFormErrors, IFormError, validateField, VALIDATIONS,
} from '../../../utils/validation';
import { parseTimeToSend } from '../../../utils/schedules';

interface OwnProps extends TranslationContext, AuthenticationContext {}

interface OwnState {
    isFetching: boolean;
    showSaveChanges: boolean;
    formErrors: any;
    store: Store;
    fields: {
        latitude: number | null;
        longitude: number | null;
        distanceMax: number | null;
        shippingPriceMin: number | null;
    }
}

const initialState: OwnState = {
    isFetching: false,
    showSaveChanges: false,
    formErrors: null,
    store: {
        id: -1,
        address: '',
        closeToLaunch: false,
        closeToLaunchOn: '',
        openFromLaunchOn: '',
        contact: '',
        description: '',
        email: '',
        homeDelivery: false,
        latitude: null,
        longitude: null,
        name: '',
        thumbnailHash: '',
        website: '',
        postalCode: '',
        thumbnail: null,
        categoryId: null,
        tags: [],
        weekSchedule: [],
        iban: '',
        nif: '',
        certCode: '',
        photosHash: [],
        photos: null,
        bannerHash: '',
        history: {
            description: '',
            photo1: '',
            photo2: '',
            photo3: '',
            photo4: '',
            title: '',
        },
    },
    fields: {
        distanceMax: null,
        shippingPriceMin: null,
        latitude: null,
        longitude: null,
    },
};

const marker: Icon = new L.Icon({
    iconUrl: mapMarker,
    iconSize: [55, 75],
    iconAnchor: [27, 75],
    popupAnchor: [0, -76],
});

class ShippingConfig extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    componentDidMount() {
        this.prepare();
    }

    onMapClick = (e: LeafletMouseEvent):void => {
        const { fields } = this.state;
        const { lat, lng } = e.latlng;

        const latitude = Number(lat.toFixed(5));
        const longitude = Number(lng.toFixed(5));

        this.setState({
            showSaveChanges: true,
            fields: {
                ...fields,
                latitude,
                longitude,
            },
        });
    }

    prepare = () => {
        const { user } = this.props;

        if (user) {
            this.setState({
                isFetching: true,
            });

            const { store: userStore } = user;

            const storeGetURL = userStore ? storeURL(userStore.id) : '';

            const urls = [storeGetURL];

            axios.all(urls.map(url => axios.get(url)))
                .then(axios.spread(storeResponse => {
                    const store = storeResponse.data;
                    this.setState({
                        store,
                        fields: {
                            ...storeResponse.data.deliveryData,
                        },
                    });
                }))
                .catch(() => {
                    displayNotification(NOTIFICATION_TYPE.ERROR, 'Erro a carregar dados');
                })
                .finally(() => {
                    this.setState({
                        isFetching: false,
                    });
                });
        }
    }

    renderMapComponent = ():JSX.Element => {
        const { t } = this.props;
        const { fields: { latitude, longitude } } = this.state;

        let position: LatLng = new LatLng(39.7541222, -8.8409781);

        if (latitude && String(latitude).trim() !== '' && longitude && String(longitude).trim() !== '') {
            position = new LatLng(latitude, longitude);
        }

        return (
            <>
                <p>{t('storeAdmin.shipping.mapTitle')}</p>
                <Map center={position} zoom={13} onclick={this.onMapClick}>
                    <TileLayer url="https://c.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Marker position={position} icon={marker} />
                </Map>
            </>
        );
    }

    onSubmit = async () => {
        const { t } = this.props;
        const { store, fields } = this.state;

        if (this.validateFields()) {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            this.setState({
                isFetching: true,
            });

            const formData = new FormData();

            const weekSchedule: Array<any> = [];
            Object.keys(store.weekSchedule).forEach(k => {
                const s = store.weekSchedule[Number(k)];
                const initialWeekDay = String(s.initialWeekDay).trim() === '' ? null : Number(s.initialWeekDay);

                weekSchedule.push({
                    initialWeekDay,
                    endWeekDay: String(s.endWeekDay).trim() === '' ? initialWeekDay : Number(s.endWeekDay),
                    closeOn: s.closeOn ? parseTimeToSend(s.closeOn) : null,
                    openOn: s.openOn ? parseTimeToSend(s.openOn) : null,
                });
            });

            const formContent = {
                ...store,
                deliveryData: fields,
                weekSchedule,
                categoryId: store.category?.id,
            };

            formData.append('store', new Blob([JSON.stringify(formContent)], {
                type: 'application/json',
            }));

            await axios.put(storeURL(store.id), formData, config)
                .then(() => {
                    displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.stores.editStoreSuccess'));
                    this.setState({
                        isFetching: false,
                        showSaveChanges: false,
                    }, () => this.prepare());
                })
                .catch(() => {
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.editStoreError'));
                    this.setState({ isFetching: false });
                });
        }
    };

    onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { fields } = this.state;
        const { name, value } = e.currentTarget;
        
        if (name === 'shippingPriceMin') {
            if (value.length > 0 && validateField(name, value, VALIDATIONS.SHIPPING_FORM)) {
                return;
            }
        }

        this.setState({
            showSaveChanges: true,
            fields: {
                ...fields,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    }

    validateFields = (): boolean => {
        const { fields } = this.state;
        const errors: IFormError | null = getFormErrors(fields, VALIDATIONS.SHIPPING_FORM);

        this.setState({ formErrors: errors ? { fields: errors } : errors }, () => {
            const { formErrors } = this.state;
            const error = get(formErrors, 'fields.distanceMax', null);
            if (error && error[0].typeOfViolation === 'Pattern') {
                displayNotification(NOTIFICATION_TYPE.ERROR, 'Na distância máxima insira apenas números inteiros.');
            }
        });

        return errors === null;
    }

    useStoreLocation = () => {
        const { store, fields } = this.state;

        if (store) {
            const { latitude, longitude } = store;
            if (latitude && longitude) {
                this.setState({
                    showSaveChanges: true,
                    fields: {
                        ...fields,
                        latitude,
                        longitude,
                    },
                });
            }
        }
    }

    render() {
        const { t } = this.props;

        const {
            isFetching,
            formErrors,
            fields,
            showSaveChanges,
        } = this.state;

        const {
            distanceMax,
            shippingPriceMin,
            latitude,
            longitude,
        } = fields;

        return (
            <div className="app-tabs__tab-content store-admin">
                {isFetching && (
                <div className="loader-wrapper">
                    <Loader />
                </div>
                )}
                <Row className="form-centered form-centered__form-centered-large justify-content-between">
                    <Col xl={5}>
                        {this.renderMapComponent()}
                        <FormTextField
                            label={t('storeAdmin.shipping.labels.latitude')}
                            name="latitude"
                            value={latitude}
                            errors={get(formErrors, 'fields.latitude', null)}
                            onChange={this.onInputChange}
                        />
                        <FormTextField
                            label={t('storeAdmin.shipping.labels.longitude')}
                            name="longitude"
                            value={longitude}
                            errors={get(formErrors, 'fields.longitude', null)}
                            onChange={this.onInputChange}
                        />
                        <div className="button-wrapper">
                            <Button
                                styles="button--dark-blue"
                                text={t('storeAdmin.shipping.buttons.useStoreLoc')}
                                callback={this.useStoreLocation}
                            />
                        </div>
                    </Col>
                    <Col xl={6} className="d-flex flex-column justify-content-between">
                        <div className="w-100">
                            <FormTextField
                                label={t('storeAdmin.shipping.labels.maxDist')}
                                name="distanceMax"
                                value={distanceMax}
                                placeholder="ex: 8"
                                errors={get(formErrors, 'fields.distanceMax', null)}
                                onChange={this.onInputChange}
                            />
                            <FormTextField
                                label={t('storeAdmin.shipping.labels.minCost')}
                                name="shippingPriceMin"
                                value={String(shippingPriceMin)}
                                placeholder="ex: 4.00"
                                errors={get(formErrors, 'fields.shippingPriceMin', null)}
                                onChange={this.onInputChange}
                            />
                        </div>
                        <div className="align-self-end">
                            <div className="button-wrapper">
                                <Button
                                    styles="button--dark-blue"
                                    text={t('global.buttons.saveChanges')}
                                    callback={this.onSubmit}
                                    disabled={!showSaveChanges}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(ShippingConfig));

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const shoppingURL = (): string => {
    return `${API_URL}/orders/checkout`;
};

export const shoppingCartsURL = (id:number, params: Record<string, unknown> = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/stores/${id}/orders/${queryString}`;
};

export const singleShoppingCartURL = (storeId: number | string, orderId: number | string) => {
    return `${API_URL}/stores/${storeId}/orders/${orderId}`;
};

export const shoppingOrdersURL = (params: Record<string, unknown> = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/orders${queryString}`;
};

export const singleShoppingOrderURL = (orderId: number | string) => {
    return `${API_URL}/orders/${orderId}`;
};

export const shoppingOrdersStatusUpdateURL = (orderId: number, storeId: number, status: string): string => {
    return `${API_URL}/stores/${storeId}/orders/${orderId}/status?status=${status}`;
};

export const shoppingOrdersStatusUpdateConfirmURL = (orderId: number, storeId: number): string => {
    return `${API_URL}/stores/${storeId}/orders/${orderId}/confirm-stock`;
};
    
export const shoppingOrdersDetailsURL = (orderId: number, params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/orders/${orderId}/items${queryString}`;
};

export const paymentFees = (): string => {
    return `${API_URL}/payment/fees`;
};

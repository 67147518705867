/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { CharacteristicType, PaymentMethods, SelectOption } from './misc';

export interface Category {
    id: number;
    description: string;
    longDescription: string;
    thumbnailUrl: string;
    headerUrl: string;
    disabled?: boolean;
    inUse?: boolean;
    subcategoriesStrList?: string;
    thumbnail?: File | null;
    subcategories?: Array<Subcategory>;
}

export interface Subcategory {
    id: number;
    description: string;
    disabled?: boolean;
    inUse?: boolean;
    mainCategory?: Category;
    mainCategoryId?: number | null;
}

export interface DeliveryData {
    id: string | number;
    distanceMax: number;
    shippingPriceMin: number;
    latitude: number,
    longitude: number,
}

export interface Store {
    id: number;
    address: string;
    closeToLaunch: boolean;
    closeToLaunchOn?: string | number;
    openFromLaunchOn?: string | number;
    contact: string;
    description: string;
    disabled?: boolean;
    email: string;
    homeDelivery: boolean;
    latitude: number | null;
    longitude: number | null;
    name: string;
    thumbnailHash: string;
    photos?: Array<File> | null;
    photosHash: Array<string>;
    bannerHash: string;
    website: string;
    paymentInfoValid?: boolean;
    postalCode: string;
    iban?: string;
    nif?: string;
    certCode?: string;
    bicSwift?: string;
    country?: string;
    location?: string;
    category?: Category | null;
    categoryId?: number | null;
    tags: Array<StoreTag> | Array<SelectOption>;
    thumbnailUrl?: string;
    thumbnail?: File | null;
    weekSchedule: Array<ScheduleItem>;
    history: StoreHistory;
    courier?: boolean;
    allowVouchers?: boolean;
    deliveryData?: DeliveryData;
    productFormat?: string;
    customStoreCategories?: Array<Record<string, string>>;
    exhibition?: boolean;
    productsFeedURL?: string;
    slug?: string;
    contactFeeType?: string;
}

export interface StoreHistory {
    description: string;
    photo1: string;
    photo2: string;
    photo3: string;
    photo4: string;
    title: string;
}

export interface CollectionPoint {
    id: number;
    address: string;
    closeToLaunch: boolean;
    closeToLaunchOn: string | number;
    openFromLaunchOn: string | number;
    contact: string;
    description: string;
    disabled?: boolean;
    email: string;
    latitude: number | null;
    longitude: number | null;
    name: string;
    thumbnailHash: string;
    postalCode: string;
    thumbnailUrl?: string;
    thumbnail?: File | null;
    weekSchedule: Array<ScheduleItem>;
}

export interface Sellable {
    id: number;
    description: string;
    shortDescription: string;
    categoryId?: number | null;
    subCategoryId?: number | null;
    category?: Category | null;
    subCategory?: Subcategory | null;
    price: number | null;
    thumbnail?: File | null;
    visibleOnlyOnGroup?: boolean;
    groupId: string | null;
    thumbnailUrl?: string;
    thumbnailHash: string;
    inStock: boolean;
    reference: string;
    voucher?: boolean;
    highlight?: boolean;
    banners?: Array<number> | Array<SelectOption> | Array<Campaign>;
}

export interface Product extends Sellable {
    storeId?: number | null;
    inPromotion: boolean;
    lastPrice: number | null;
    tags: Array<StoreTag> | Array<SelectOption>;
    brand: string;
    photos?: Array<File> | null;
    tax: number | null;
    photosHash: Array<string>;
    packingWeight: number | null;
    color: string;
    colorHex: string;
    size: string;
    storeInfo: {
        id: number;
        name: string;
        slug: string;
    };
    length: number | null;
    width: number | null;
    height: number | null;
    availableDeliveryMethods: Array<DeliveryMethods> | Array<SelectOption>;
    discountStart?: string;
    discountEnd?: string;
    discountPrice?: number;
    productURL?: string;
    disabled?: boolean;
}

export interface Voucher extends Sellable {
    email: string;
    voucherBonus: number;
}

export type CompoundSellable = Product & Voucher;

export interface HealthProduct {
    id: number;
    description: string;
    shortDescription: string;
    thumbnailHash: string;
    thumbnail?: File | null;
    thumbnailUrl?: string;
    collectionPointId?: number | null;
    collectionPoint?: CollectionPoint;
}

export interface HealthOrder {
    id: number;
    claimant: Claimant;
    collectionPointId?: number | null;
    collectionPoint?: CollectionPoint;
    observations: string;
    orderLines: Array<HealthOrderLine>;
    currentStatus: HealthOrderStatusProgressTypes;
    stateProgress: Array<HealthOrderStateProgress>;
}

export interface Claimant {
    nif: string;
    postalCode: string;
    name: string;
    email: string;
    contact: string;
    address: string;
}

export interface HealthOrderLine {
    id: string;
    description?: string;
    nif: string;
    productId: number | string | null;
    productName?: string;
    quantity?: number | null;
}

export interface HealthOrderStateProgress {
    onDate: string;
    status: HealthOrderStatusProgressTypes;
}

export interface StoreTag {
    id: string | number;
    description: string;
}

export interface ScheduleItem {
    id: string | number;
    endWeekDay: string | number | null;
    initialWeekDay: string | number | null;
    openOn: string | number | null;
    closeOn: string | number | null;
    isInterval?: boolean;
}

export interface HelpdeskScheduleItem {
    id: string | number;
    day: string | number;
    openOn: string | number | null;
    closeOn: string | number | null;
    isInterval?: boolean;
}

export interface FormattedSchedule {
    weekday: string;
    schedule1: ScheduleItem | HelpdeskScheduleItem;
    schedule2: ScheduleItem | HelpdeskScheduleItem | null;
}

export interface WhatsAppScheduleItem {
    id: number,
    openOn: string,
    closeOn: string,
    day: number,
}

export interface ProductVariant {
    id: number | string;
    characteristics: Array<Characteristic>;
}

export interface Characteristic {
    id: string;
    characteristicType: string | CharacteristicType | null;
    value: string;
}

export interface StoreManager {
    id: number;
    email: string;
    name: string;
    password?: string;
    passwordConfirmation?: string;
    storeId?: number | null;
    myStores?: Array<Store>;
}

export interface Customer {
    id:number;
    name: string;
    email: string;
    contact: string;
    address: string;
    postalCode: string;
    newsletter: boolean;
    createdDate: number;
    lastAccess: string;
    city?: string;
}
export interface ShoppingCart {
    storeCarts: Array<StoreCart>;
    productsCounter: number;
    total: number;
}

export interface StoreCart {
    id: string;
    storeId: number;
    storeName: string;
    storeDeliveryMethods: Array<SelectOption>;
    shoppingLines: Array<ShoppingLine>;
    deliveryPrice?: number;
}

export interface ShoppingLine {
    id: string;
    availableDeliveryMethods: Array<SelectOption>;
    productId: number;
    productThumbnailUrl: string;
    productName: string;
    productSize: string;
    productColor: string;
    quantity: number;
    productPrice: number;
    deliveryMethod: string;
    subtotal: number;
    inStock: boolean;
    isVoucher?: boolean;
    bonusPerVoucher?: number;
    voucherBonus?: number;
    storeInfo: {
        id: number;
        name: string;
        slug: string;
    };
}

export interface Order {
    address: string;
    contact: string;
    createdDate: string;
    id: number;
    orderState: ShoppingOrderState;
    paymentReferenceInfo: PaymentReferenceInfo;
    postalCode: string;
    storeCarts: Array<DeliveryStoreOrder>;
}

export interface ShoppingOrder {
    deliveryMethod: string;
    id: number;
    orderLines: Array<ShoppingOrderLine>;
    total: number;
    storeInfo: Store;
    orderState: ShoppingOrderState;
    createdDate: string;
    address: string;
    billingAddress: UserAddress;
    postalCode: string;
    contact: string;
    invoiceNif: string;
    customer: {
        email: string;
        name: string;
    };
    city: string;
    deliveryDate: string;
    latitude?: number;
    longitude?: number;
    homeDeliveryPrice?: number;
}

export interface ShoppingOrderLine {
    description: string;
    productId: number;
    productReference: string;
    quantity: number;
    subTotal: number;
    tax: number;
    previousPrice?: number;
    unitPrice: number;
    brand?: string;
    size?: string;
    color?: string;
}

export interface DeliveryStoreOrder {
    deliveryMethod: string;
    id: number;
    orderLines: Array<ShoppingOrderLine>;
    total: number;
    orderState: ShoppingOrderState;
    createdDate: string;
    address: string;
    postalCode: string;
    contact: string;
    storeCartId: number;
    storeName: string;
    customer: {
        email: string;
        name: string;
    };
}

export interface VariantItem {
    id: string;
    variantId: number;
    value: string;
    active: boolean;
    disabled: boolean;
}

export interface PaymentReferenceInfo {
    amount: number;
    entity: string;
    paymentMethod?: string;
    reference: string;
    fee: number;
    transationStatus?: string;
}

export interface PaymentFee {
    id: number;
    fee: number;
    feeVat: number;
    feePercentage: number;
    paymentMethod: PaymentMethods;
    minFee: number;
    maxFee: number;
    defaultFee: number;
    defaultMinFee: number;
    defaultMaxFee: number;
    defaultFeePercentage: number;
}

export interface ShippingPrice {
    id: number;
    maxWeight: number | null;
    price: number | null;
}

export enum DeliveryMethods {
    PRIVATE = 'PRIVATE',
    THIRD_PARTY = 'THIRD_PARTY',
    COURIER = 'COURIER',
    EMAIL = 'EMAIL',
    HOME = 'HOME',
}

export enum HealthOrderStatusProgressTypes {
    CANCELED = 'CANCELED',
    FINISHED = 'FINISHED',
    IN_PROGRESS = 'IN_PROGRESS',
    REQUESTED = 'REQUESTED',
    WAITING_FOR_PICKUP = 'WAITING_FOR_PICKUP',
}

export enum ContactMessageType {
    HEALTH = 'HEALTH',
    MARKET = 'MARKET'
}

export enum ShoppingOrderState {
    IDLE = 'IDLE',
    PAID = 'PAID',
    READY_TO_PICKUP = 'READY_TO_PICKUP',
    WAITING_PAYMENT = 'WAITING_PAYMENT',
    DELIVERY_NOT_POSSIBLE = 'DELIVERY_NOT_POSSIBLE',
    DELIVERED = 'DELIVERED',
    CANCELED = 'CANCELED',
    PROCESSING = 'PROCESSING',
    IN_TRANSIT = 'IN_TRANSIT',
    CANCELED_NO_PAYMENT = 'CANCELED_NO_PAYMENT'
}

export interface JoinUsFormFields {
    address: string;
    categoryId: number;
    city: string;
    email: string;
    name: string;
    phone: string;
    postalCode: string;
    storeName: string;
}

export interface UserAddress {
    id: number;
    address: string;
    city: string;
    name: string;
    phone: string;
    postalCode: string;
}

export interface StoreFiltersList {
    category: Array<Category>;
    location: Array<string>;
}

export interface StoreFiltersSelection {
    categoryId: number | null;
    location: string;
    online: string;
    time: number | null;
    q: string | null;
}

export interface StoreFiltersSearch {
    category: string;
    location: string;
}

export interface ProductFiltersList {
    brand: Array<string>;
    color: Array<string>;
    size: Array<string>;
    category: Array<Category>;
    campaign: Array<ProductListScreenBanner>;
}

export interface ProductFiltersSelection {
    brand: string;
    color: string;
    size: string;
    categoryId: number | null;
    sort: string;
    order: string;
    stock: string;
    minPrice: number | null;
    maxPrice: number | null;
    q: string | null;
    banner: number | null;
    promotion: string;
}

export interface ProductFiltersSearch {
    brand: string;
    color: string;
    size: string;
    category: string;
    campaign: string;
}

export interface LiveSearchValue {
    id: number | null,
    name: string,
    type: string,
    item?: Product | Store,
}

export interface Campaign {
    id: number;
    desktopImageHash: string;
    desktopImage: File | null;
    mobileImageHash: string;
    mobileImage: File | null;
    headerImageHash: string;
    headerImage: File | null;
    mobileHeaderImageHash: string;
    mobileHeaderImage: File | null;
    name: string;
    title: string;
    subtitle: string;
    titleColor: string;
    subtitleColor: string;
    button?: boolean;
    buttonText: string;
    buttonUrl?: string;
    bannerUrl: string;
    start: string;
    finish: string;
    inHomepage: boolean;
    anchorRight: boolean;
}

export enum BannerTypes {
    CATEGORY,
    CAMPAIGN,
}

export interface ProductListScreenBanner {
    id: number | null,
    name: string,
    type: BannerTypes | null;
    mobileUrl: string | null;
    desktopUrl: string | null;
}

export enum ContactFeeType {
    LANDLINE = 'LANDLINE',
    MOBILE = 'MOBILE',
}

export type FormTextFieldTypes = 'text' | 'password' | 'number' | 'date';

import React from 'react';

export const TermsAndConditionsOfUse = () => {
    return (
        <div className="terms-screen__content">
            <h4>Termos e Condições de Utilização</h4>
            <hr className="divider my-4" />
            <div className="text-container">
                <p>
                    O domínio do Site pertence à <b>Startup Leiria Associação para o
                        Empreendedorismo e Inovação
                                                 </b>, cabendo a esta a sua exploração e gestão de operação.
                </p>
            </div>
            <div className="text-container">
                <p>
                    A utilização do Site por terceiros implica, desde já, a aceitação dos presentes Termos e
                    Condições de Utilização (“TCU”) pelos seus Utilizadores.
                </p>
            </div>
            <div className="text-container">
                <p className="bold">
                    1. Acesso ao Site
                </p>
                <p>
                    A <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b>, enquanto proprietária do Site,
                    poderá, a qualquer momento, vedar o seu acesso a terceiros, designadamente para efeitos de reparação ou
                    manutenção. Adicionalmente, caberá à <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b> o direito de o
                    encerrar a qualquer momento caso ocorra qualquer circunstância que, em seu entender, o justifique.
                </p>
            </div>
            <div className="text-container">
                <p className="bold">
                    2. Direitos de Propriedade Intelectual
                </p>
                <p>
                    Pertence à <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b> os direitos de
                    autor sobre todos os textos, desenhos, imagens, gráficos e demais conteúdos que estejam dispostos no Site
                </p>
            </div>
            <div className="text-container">
                <p>
                    Os Utilizadores não estão autorizados a reproduzir, copiar, alterar, vender ou utilizar
                    estes elementos sem obterem previamente por escrito a necessária autorização por parte da <b>Startup
                        Leiria Associação para o Empreendedorismo e Inovação
                                                                                                              </b>.
                </p>
            </div>

            <div className="text-container">
                <p className="bold">
                    3. Privacidade e tratamento de dados pessoais
                </p>
                <p>
                    Para efeitos de criação de conta dos Utilizadores do Site, será sempre solicitado a estes a
                    indicação de um contacto de e-mail e de uma senha de acesso. Caberá ao Utilizador manter esses dados
                    em local seguro e alterá-los caso existam indícios de utilização abusiva da conta por terceiros não autorizados.
                </p>
            </div>
            <div className="text-container">
                <p>
                    Do mesmo modo, se os dados ou informações pessoais prestadas pelos Utilizadores vierem a sofrer alterações,
                    será da responsabilidade destes a atualização daqueles elementos.
                </p>
            </div>
            <div className="text-container">
                <p>
                    Sem prejuízo, a privacidade dos Utilizadores do Site constitui uma especial preocupação para a <b>Startup
                        Leiria Associação para o Empreendedorismo e Inovação
                                                                                                                   </b>. Nesse sentido, aprovámos uma
                    Política de Privacidade onde estão definidos os princípios gerais aplicáveis em matéria de recolha e
                    tratamento de dados pessoais aos quais daremos estrito cumprimento.
                </p>
            </div>
            <div className="text-container">
                <p>
                    A nossa Política de Privacidade é complementada pela nossa <b>Política de Cookies</b>.
                </p>
            </div>

            <div className="text-container">
                <p className="bold">
                    4. Links de terceiros
                </p>
                <p>
                    O Site disponibiliza, através de hiperligação, o acesso a Websites de entidades terceiras.
                </p>
            </div>

            <div className="text-container">
                <p>
                    Contudo, como tais Websites não são controlados ou operados pela <b>Startup Leiria Associação para o
                        Empreendedorismo e Inovação
                                                                                     </b>, os respetivos termos e condições de utilização não lhes é aplicável.
                    Nesse sentido, a <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b> não se responsabiliza
                    pelo conteúdo ou teor dos termos e condições de utilização de Websites de terceiros.
                </p>
            </div>

            <div className="text-container">
                <p className="bold">
                    5. Alterações aos TCU
                </p>
                <p>
                    A <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b> reserva-se no direito de
                    proceder às alterações que se revelarem necessárias nestes TCU. Sempre que tal aconteça,
                    os mesmos serão publicados no Site, pelo que é aconselhável que os Utilizadores do Site o consultem regularmente.
                </p>
            </div>

            <div className="text-container">
                <p>Sugestões e reclamações </p>
                <p>
                    Caso surja aos Utilizadores qualquer questão relacionada com os presentes TCU,
                    poderão ser utilizados os seguintes contactos:
                    <a href="mailto:apoiocliente@leiriamarket.pt"> apoiocliente@leiriamarket.pt</a>
                </p>
            </div>
        </div>
    );
};

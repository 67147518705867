import React from 'react';
import ReactPlayer from 'react-player';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { MatchParams } from '../../../constants/misc';
import { RECOVER_PASSWORD_ROUTE, REGISTER_ROUTE } from '../../../constants/routes';
import imgBuyer31 from '../../../assets/images/faqs/buyer/image-3-1.png';
import imgBuyer32 from '../../../assets/images/faqs/buyer/image-3-2.png';
import imgBuyer33 from '../../../assets/images/faqs/buyer/image-3-3.png';
import imgBuyer34 from '../../../assets/images/faqs/buyer/image-3-4.png';
import shippingTariffs from '../../../assets/images/faqs/shipping/shippingTariffs.png';
import guiaVestuario from '../../../assets/documents/tamanhos_vestuário.pdf';

type OwnProps = RouteComponentProps<MatchParams>

interface OwnState {
    playBuyerVideo: boolean,
    playSellerVideo: boolean,
}

const initialState = {
    playBuyerVideo: false,
    playSellerVideo: false,
};

class FrequentlyAskedQuestions extends React.Component<OwnProps, OwnState> {
    state = initialState;

    private historyUnlistener: Function = () => {};
    
    componentDidMount() {
        const { history } = this.props;
        this.historyUnlistener = history.listen(() => this.stopVideos());
    }

    componentWillUnmount() {
        this.historyUnlistener();
    }

    stopVideos = () => {
        this.setState({
            playBuyerVideo: false,
            playSellerVideo: false,
        });
    }

    renderVideos = () => {
        const { playBuyerVideo, playSellerVideo } = this.state;

        return (
            <>
                <div className="text-container">
                    <h3>V - Tutoriais</h3>
                </div>
                <div className="text-container">
                    <h5 className="font-weight-bolder">Tutorial Clientes</h5>
                    <ReactPlayer
                        url="/videos/tutorial_cliente.mp4"
                        controls
                        playing={playBuyerVideo}
                        onPlay={() => {
                            this.setState({
                                playBuyerVideo: true,
                            });
                        }}
                    />
                </div>
                <div className="text-container">
                    <h5 className="font-weight-bolder">Tutorial Lojistas</h5>
                    <ReactPlayer
                        url="/videos/tutorial_lojista.mp4"
                        controls
                        playing={playSellerVideo}
                        onPlay={() => {
                            this.setState({
                                playSellerVideo: true,
                            });
                        }}
                    />
                </div>
            </>
        );
    }

    render() {
        return (
            <div className="terms-screen__content">
                <h4>Perguntas Mais Frequentes (FAQs)</h4>
                <hr className="divider my-4" />
                <div className="text-container">
                    <h3>I - Cliente</h3>
                </div>
                <div className="text-container">
                    <h5 className="font-weight-bolder">1. Como encomendar no leiriamarket.pt?</h5>
                    <p>
                        Para encomendar no <b>Leiriamarket</b> basta seguir os seguintes passos:
                        <ol>
                            <li>Procurar a Loja ou o produto que pretende;</li>
                            <li>Clicar no produto pretendido e adicioná-lo ao seu cesto de compras;</li>
                            <li>Clicar no símbolo do seu cesto de compras, que fica no canto superior direito do seu
                                ecrã;
                            </li>
                            <li>Confirmar a encomenda;</li>
                            <li>Registar-se no <b>Leiriamarket</b> ou comprar sem registo;</li>
                            <li>Escolher onde pretende receber a sua encomenda ou a recolha em loja;</li>
                            <li>Escolher o método de pagamento pretendido;</li>
                            <li>Finalizar a sua encomenda.</li>
                        </ol>
                    </p>
                    <p>Depois é só aguardar a chegada da mesma!</p>
                    <p>
                        Caso necessite de devolver a sua compra pode fazê-lo gratuitamente sem sair
                        de casa e sem preocupações durante os 14 dias a seguir a receber a encomenda.
                        Veja a questão: <a href="#return-product">Como posso devolver um produto?</a>
                    </p>
                    <p>Se surgir alguma dúvida durante o processo poderá sempre contactar a nossa equipa de suporte,
                        clicando no botão de chat no canto inferior direito do nosso site ou enviando um email para&nbsp;
                        <a href="mailto:apoiocliente@leiriamarket.pt">apoiocliente@leiriamarket.pt</a>!
                    </p>
                    <p>Boas compras!</p>
                </div>
                <div className="text-container">
                    <h5 className="font-weight-bolder">2. Como efectuar o seu registo no leiriamarket.pt?</h5>
                    <p>
                        Para criar uma conta <b>Leiriamarket</b> basta clicar <a href={REGISTER_ROUTE}>aqui</a> e
                        inserir os seus dados.
                    </p>
                    <p>De seguida, irá receber um email para confirmar o endereço configurado na sua conta.</p>
                </div>
                <div className="text-container">
                    <h5 className="font-weight-bolder">3. Tenho de me registar para comprar no Leiriamarket?</h5>
                    <p>
                        Não tem que ter conta <b>Leiriamarket</b> para finalizar uma compra. Quando carregar "Finalizar
                        compra",
                        pode escolher a opção de efetuar a compra como convidado!
                    </p>
                    <img src={imgBuyer31} alt="ecrã de compra como convidado" />
                    <p>
                        Nesta página deverá preencher os campos obrigatórios. Será enviado um email com um pedido de
                        confirmação de identidade
                        e o <i>link</i> para continuar a sua compra.
                    </p>
                    <img src={imgBuyer32} alt="mensagem de verificação de email" />
                    <img src={imgBuyer33} alt="email de confirmação de convidado" />
                    <p>
                        Ao carregar o <i>link</i> que segue no corpo do email, este fica automaticamente validado e pode
                        prosseguir para o pagamento
                        e confirmação da encomenda.
                    </p>
                    <img src={imgBuyer34} alt="ecrã de confirmação de registo" />
                </div>
                <div className="text-container">
                    <h5 className="font-weight-bolder">4. Se fizer uma compra, onde posso encontrar a fatura?</h5>
                    <p>
                        Caso compre como cliente registado ou convidado a sua fatura será enviada para o
                        seu <i>email</i>. Caso não receba
                        deverá solicitar o envio da fatura enviando o pedido para <a
                            href="mailto:apoiocliente@leiriamarket.pt"
                        >
                            apoiocliente@leiriamarket.pt
                                                                                  </a>.
                        Se criar uma conta, também poderá encontrar a sua encomenda e <b>respetiva fatura</b> na área de
                        cliente.
                    </p>
                </div>
                <div className="text-container">
                    <h5 className="font-weight-bolder">5. Esqueci-me da minha palavra passe. Como a posso repor ou
                        alterar?
                    </h5>
                    <p>Não sabe a sua password? Nós ajudamos. Carregue <a href={RECOVER_PASSWORD_ROUTE}>aqui</a> para
                        repor a palavra passe.
                    </p>
                </div>
                <hr className="divider my-4" />
                <div className="text-container">
                    <h3>II - Produtos</h3>
                </div>
                <div className="text-container">
                    <h5 className="font-weight-bolder">1. O Leiriamarket tem loja física?</h5>
                    <p>O Leiriamarket é uma plataforma online e não tem loja física. Os produtos que vendemos são
                        disponibilizados pelos nossos vendedores a partir das suas instalações.
                    </p>
                </div>
                <div className="text-container">
                    <h5 className="font-weight-bolder">2. Onde posso consultar um Guia de Tamanhos?</h5>
                    <p>O Leiriamarket tem presentes, através dos seus lojistas, Marcas diferentes e que podem ter como
                        referência tabelas de medidas e tamanhos que podem variar. Assim, e para o ajudar na tomada de
                        decisão acerca do tamanho certo a escolher, sugerimos que consulte os valores de referência.
                    </p>
                    <a href={guiaVestuario}>Guias de Tamanho e Tabelas de Medidas</a>
                </div>
                <div className="text-container">
                    <h5 className="font-weight-bolder">3. Onde posso encontrar informações sobre os produtos?</h5>
                    <p>Basta clicar na imagem do produto que pretende e vai poder ver toda a informação ao detalhe, bem
                        como diversas fotos.
                    </p>
                    <p>Estamos disponíveis no nosso <i>chat</i> no canto inferior direito e no email&nbsp;<a
                        href="mailto:apoiocliente@leiriamarket.pt"
                    >apoiocliente@leiriamarket.pt
                                                                                                          </a>.
                    </p>
                </div>
                <div className="text-container">
                    <h5 className="font-weight-bolder">4. Os preços incluem taxas e impostos?</h5>
                    <p>Os preços dos produtos assinalados já incluem todo o tipo de taxas e impostos, isto é, os preços
                        são PVP (Preço de Venda ao Público).
                    </p>
                    <p>Os custos da transação, bem como os portes de envio, são adicionados acima do preço do produto
                        dependendo da sua escolha entre os serviços de entrega disponíveis e o seu meio de pagamento,
                        multibanco ou Mbway.
                    </p>
                </div>
                <div className="text-container">
                    <h5 className="font-weight-bolder">5. Os produtos à venda no Leiriamarket são novos ou também têm
                        usados?
                    </h5>
                    <p>Todos os produtos presentes no Leiriamarket são novos. Se receber um produto que suspeita ser
                        usado, deve contactar-nos através do <a
                            href="mailto:apoiocliente@leiriamarket.pt"
                        >apoiocliente@leiriamarket.pt
                                                             </a>.
                    </p>
                </div>
                <hr className="divider my-4" />
                <div className="text-container">
                    <h3>III - Pagamento e Faturação</h3>
                </div>
                <div className="text-container">
                    <h5 className="font-weight-bolder">1. Como posso pagar as minhas encomendas no Leiriamarket?</h5>
                    <p>Pode pagar as suas encomendas das seguintes formas:</p>
                    <ul>
                        <li>
                            <p>Referência Multibanco – como funciona?</p>
                            <ol>
                                <li>Depois de confirmar a sua encomenda, receberá um e-mail com as informações
                                    necessárias (entidade e referência) para prosseguir com o pagamento;
                                </li>
                                <li>Terá 24 horas para efectuar o pagamento;</li>
                                <li>O pagamento pode ser efetuado numa caixa multibanco ou através do serviço de
                                    homebanking na internet;
                                </li>
                            </ol>
                        </li>
                        <p>Nota: se pagar com referência MB, o valor da encomenda será logo cobrado (ao contrário do que
                            acontece com o cartão, em que o valor é inicialmente cativado do cartão). Nesse sentido, se
                            o vendedor ficar com o artigo esgotado, prosseguirá com o reembolso total do valor.
                        </p>
                        <li>
                            <p>MB Way – como funciona?</p>
                            <ol>
                                <li>Na confirmação da encomenda deverá inserir o número de telefone associado à sua
                                    conta MB Way;
                                </li>
                                <li>Confirmar a notificação na App MB Way</li>
                                <li>Tem de confirmar a compra num período de 4 minutos (dependendo do comerciante). Após
                                    este tempo limite o pedido de compra expira, nestes casos será necessário repetir o
                                    processo de compra para receber uma nova notificação.
                                </li>
                            </ol>
                        </li>
                    </ul>
                </div>
                <div className="text-container">
                    <h5 className="font-weight-bolder">2. Posso efectuar o pagamento com cartão?</h5>
                    <p>Ainda não temos essa funcionalidade! Mas estamos a trabalhar nela, e teremos novidades em
                        breve.
                    </p>
                </div>
                <div className="text-container">
                    <h5 className="font-weight-bolder">3. Posso escolher para pagar uma encomenda aquando da
                        entrega?
                    </h5>
                    <p>Actualmente não oferecemos essa opção, por questões de segurança. Apenas aceitamos pré-pagamento
                        de produtos por referência multibanco e MBway.
                    </p>
                </div>
                <hr className="divider my-4" />
                <div className="text-container">
                    <h3>IV –Entrega, Envio e Devoluções</h3>
                </div>
                <div className="text-container">
                    <p>O Leiriamarket é um marketplace, ou seja, um site de compras online onde se podem comprar
                        inúmeras marcas e produtos, vendidos por diferentes lojas, todas dentro do Leiriamarket. Cada
                        loja pode optar pelos seguintes serviços: Recolha em Loja e/ou Entrega ao domicilio pelos
                        CTT.
                    </p>
                    <p>A Recolha em Loja não tem qualquer custo para si. A Entrega ao domicílio pelos CTT tem um custo
                        associado de acordo com o tarifário que poderá consultar aqui.
                    </p>
                    <img src={shippingTariffs} alt="Tarifas de envio CTT" />
                    <p>De momento ainda não dispomos da opção para as nossas lojas e clientes da oferta dos custos de
                        envio. No futuro poderá dispor dessa opção. Assim, consoante a loja, pode ter: oferta total dos custos de envio,
                        oferta dos custos de envio em alguns produtos ou oferta dos custos de envio a partir de um valor
                        mínimo de compra.
                    </p>
                </div>
                <div className="text-container">
                    <h5 className="font-weight-bolder">1. Entrega garantida ou devolvemos o seu dinheiro</h5>
                    <p>“A minha encomenda não chegou!” Nós estamos aqui para ajudar e caso não tenha recebido a sua
                        encomenda por falta de stock, extravio, quebra ou dano durante o transporte ou morada errada, o
                        Leiriamarket reembolsa-o de imediato para que possa comprar de novo.
                    </p>
                    <p>Caso tenha pago a encomenda através de referência multibanco ou MBway, iremos entrar em contacto
                        para solicitar o NIB, de forma a conseguirmos realizar a transferência bancária.
                    </p>
                    <h5 id="return-product" className="font-weight-bolder">2. Posso devolver um produto?</h5>
                    <p>
                        Pode devolver qualquer produto, exceptuando produtos de grandes dimensões, roupa interior e perecíveis,
                        e num prazo de 14 dias a contar da data de receção da encomenda. O artigo deve estar completo, com todos
                        os seus componentes e acessórios, manual de instruções (se aplicável) e embalagens originais em bom estado.
                        O artigo não deve ter sinais de uso.
                    </p>
                    <h5 className="font-weight-bolder">3. Como posso devolver um produto?</h5>
                    <p>
                        Se tiver questões relativamente ao processo de devolução de um produto, por favor contacte-nos através
                        do nosso contacto telefónico 969110225 ou através do endereço de e-mail
                        <a href="mailto:apoiocliente@leiriamarket.pt">apoiocliente@leiriamarket.pt</a>.
                    </p>
                </div>
                <hr className="divider my-4" />
                {this.renderVideos()}
            </div>
        );
    }
}

export default withRouter(FrequentlyAskedQuestions);

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { RouteComponentProps } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import CustomerOrdersList from './CustomerOrdersList';
import StoreManagerProfile from '../UserProfile';
import { MatchParams, RouteState } from '../../../constants/misc';
import AddressManagement from '../AddressManagement';

interface OwnProps extends RouteComponentProps<MatchParams, Record<string, unknown>, RouteState>, TranslationContext {}

interface OwnState {
    activeTabKey: string;
}

class CustomerAdminScreen extends Component<OwnProps, OwnState> {
    state = { activeTabKey: '' }

    componentDidMount() {
        const { location, match } = this.props;
        const { params } = match;
        const { id } = params;

        if (id) {
            this.setState({
                activeTabKey: 'orders',
            });
        } else {
            this.setState({ activeTabKey: location.state?.activeTabKey || 'profile' });
        }
    }

    onTabChange = (eventKey: string) => {
        this.setState({
            activeTabKey: eventKey,
        });
    };

    render() {
        const { t } = this.props;

        const { activeTabKey } = this.state;
        return (
            <div className="container-fluid app_screen">
                <div className="app_screen__centered margin-top-bar">
                    <div className="page-title">
                        {activeTabKey === 'profile' && t('customerAdmin.tabs.profile')}
                        {activeTabKey === 'orders' && t('customerAdmin.tabs.orders')}
                        {activeTabKey === 'addresses' && t('customerAdmin.tabs.addresses')}
                    </div>
                </div>
                <div className="app-tabs top-margin">
                    <Tabs
                        id="tab"
                        defaultActiveKey="categories"
                        activeKey={activeTabKey}
                        onSelect={this.onTabChange}
                    >
                        <Tab eventKey="profile" title={t('customerAdmin.tabs.profile')}>
                            {(activeTabKey === 'profile') && (
                                <StoreManagerProfile />
                            )}
                        </Tab>
                        <Tab eventKey="orders" title={t('customerAdmin.tabs.orders')}>
                            {(activeTabKey === 'orders') && (
                                <CustomerOrdersList />
                            )}
                        </Tab>
                        <Tab eventKey="addresses" title={t('customerAdmin.tabs.addresses')}>
                            {(activeTabKey === 'addresses') && (
                                <AddressManagement />
                            )}
                        </Tab>
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(CustomerAdminScreen);

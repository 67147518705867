/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

export const shippingPricesURL = (): string => {
    return `${API_URL}/shipping`;
};

export const shippingPriceURL = (id: number | string): string => {
    return `${API_URL}/shipping/${id}`;
};

export const shippingPriceCalcultateURL = (params: string): string => {
    return `${API_URL}/shipping/calculate?${params}`;
};

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavLink } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import axios from 'axios';
import moment from 'moment';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import FormTextAreaField from './FormTextAreaField';
import { TERMS_AND_CONDITIONS_OF_USE_ROUTE } from '../../constants/routes';
import { RECAPTCHA_KEY } from '../../settings';
import Button from './Button';
import { helpdeskSchedulesURL, whatsAppNumberGetURL } from '../../services/admin';
import {
    FormattedSchedule, HelpdeskScheduleItem,
} from '../../constants/types';
import { schedulesGrouper } from '../../utils/schedules';

type OwnProps = TranslationContext

interface OwnState {
    number: string;
    schedules: Array<HelpdeskScheduleItem>;
    message: string;
    acceptedTermsAndConditions: boolean;
    hasTermsAndConditionsError: boolean;
    hasReCaptchaError: boolean;
    recaptchaResponse: string;
    isAvailable: boolean;
}

const initialState: OwnState = {
    number: '',
    message: '',
    acceptedTermsAndConditions: false,
    hasTermsAndConditionsError: false,
    hasReCaptchaError: false,
    recaptchaResponse: '',
    schedules: [],
    isAvailable: true,
};

class WhatsAppContactForm extends React.Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const urls = [whatsAppNumberGetURL(), helpdeskSchedulesURL()];

        axios.all(urls.map(url => axios.get(url)))
            .then(
                axios.spread((numberResponse, schedulesResponse) => {
                    this.setState({
                        number: numberResponse.data.value,
                        schedules: schedulesResponse.data,
                    }, () => {
                        this.checkTimeAvailability();
                    });
                }),
            );
    }

    checkTimeAvailability = () => {
        const { schedules } = this.state;
        const now = moment();
        const todaySch = schedules.filter(el => {
            return (el.day) === Number(now.day());
        });
        let isAvailable = false;

        todaySch.forEach(sch => {
            const { openOn, closeOn } = sch;
            const nowAux = moment(now, 'hh:mm:ss');
            const openAux = moment(String(openOn), 'hh:mm:ss');
            const closeAux = moment(String(closeOn), 'hh:mm:ss');

            if (nowAux.isBetween(openAux, closeAux)) {
                isAvailable = true;
            }
        });

        this.setState({
            isAvailable,
        });
    };

    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { message, recaptchaResponse, number } = this.state;

        if (!recaptchaResponse || recaptchaResponse === '') {
            this.setState({
                hasReCaptchaError: true,
            });
            return;
        }

        if (message !== '') {
            const url = `https://wa.me/351${number}?text=${message}`;
            window.open(url);
        }
    };

    onRecaptchaVerify = (response: string | null) => {
        if (response) {
            this.setState({
                hasReCaptchaError: false,
                recaptchaResponse: response,
            });
        } else {
            this.setState({
                hasReCaptchaError: true,
            });
        }
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            message: e.currentTarget.value,
        });
    };

    onAcceptedTermsChanged = () => {
        const { acceptedTermsAndConditions } = this.state;

        this.setState({
            acceptedTermsAndConditions: !acceptedTermsAndConditions,
        });
    }

    renderSchedule = () => {
        const { t } = this.props;
        const { schedules } = this.state;
        const weekDays: string[] = moment.weekdays();
        const schedulesAux = schedules;
        const schedulesArray: Array<FormattedSchedule> = [];

        weekDays.forEach((day, idx) => {
            const daySchedules = schedulesAux.filter(el => {
                return el.day === idx;
            });

            if (daySchedules.length > 0) {
                const formatted: FormattedSchedule = {
                    schedule1: daySchedules[0],
                    schedule2: daySchedules[1] || null,
                    weekday: day,
                };

                schedulesArray.push(formatted);
            }
        });

        const scheduleGroups: Array<Array<FormattedSchedule>> = schedulesGrouper(schedulesArray);

        return scheduleGroups.map((el: Array<FormattedSchedule>) => {
            const beginning: FormattedSchedule = el[0];
            const end: FormattedSchedule = el[el.length - 1];

            const dayHeader = beginning.weekday === end.weekday ? beginning.weekday : `${beginning.weekday} - ${end.weekday}`;

            return (
                <>
                    <div>{dayHeader}</div>
                    <p>{`${t('stores.fromSchedule')} ${beginning.schedule1.openOn}h ${t('stores.toTimeSchedule')} ${beginning.schedule1.closeOn}h`}</p>
                    {beginning.schedule2 && (
                        <p>{`${t('stores.fromSchedule')} ${beginning.schedule2.openOn}h ${t('stores.toTimeSchedule')} ${beginning.schedule2.closeOn}h`}</p>
                    )}
                </>
            );
        });
    }

    render() {
        const { t } = this.props;
        const {
            message,
            acceptedTermsAndConditions,
            hasTermsAndConditionsError,
            hasReCaptchaError,
            isAvailable,
        } = this.state;

        return (
            <form onSubmit={this.onSubmit}>
                <Row>
                    <Col xs={12} lg={12}>
                        <h6>Horário de atendimento:</h6>
                        {this.renderSchedule()}
                    </Col>
                    {!isAvailable && (
                        <Col xs={12} lg={12}>
                            <p className="recaptcha-error">
                                Neste momento não estamos disponíveis. Retomaremos o contacto assim que possível, por favor deixe a sua mensagem.
                            </p>
                        </Col>
                    )}
                    <Col xs={12} lg={12}>
                        <FormTextAreaField
                            name="message"
                            value={message}
                            placeholder={t('home.contact.labels.message')}
                            required
                            onChange={this.onInputChange}
                        />
                    </Col>
                    <Col xs={12} lg={12}>
                        <div className="terms-checkbox-container">
                            <div>
                                <input
                                    type="checkbox"
                                    name="acceptedTermsAndConditions"
                                    checked={acceptedTermsAndConditions}
                                    onChange={this.onAcceptedTermsChanged}
                                />
                                <label>
                                    <span>{t('pickup.labels.readAndAccept')}&nbsp;</span>
                                    <NavLink to={TERMS_AND_CONDITIONS_OF_USE_ROUTE} target="_blank">{t('pickup.labels.termsAndConditions')}</NavLink>
                                </label>
                            </div>
                            {hasTermsAndConditionsError && (
                            <p className="error-field">{t('errors.Mandatory')}</p>
                            )}
                        </div>
                    </Col>
                    <Col xs={12} lg={12} className="w-100 d-flex flex-column align-items-center justify-content-center">
                        <ReCAPTCHA
                            sitekey={String(RECAPTCHA_KEY)}
                            onChange={this.onRecaptchaVerify}
                            hl="pt-PT"
                        />
                        {hasReCaptchaError && (
                        <p className="recaptcha-error">
                            {t('home.reCaptchaError')}
                        </p>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={12} className="w-100 d-flex justify-content-end mt-2">
                        <Button
                            type="submit"
                            text={t('home.contact.labels.sendMessage')}
                            styles="button--blue button--small"
                        />
                    </Col>
                </Row>
            </form>
        );
    }
}

export default withTranslationContext(WhatsAppContactForm);

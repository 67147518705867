/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
    NavLink, RouteComponentProps, withRouter,
} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { ModalFooter } from 'react-bootstrap';

import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';
import FormTextField from '../elements/FormTextField';
import Button from '../elements/Button';
import Loader from '../elements/Loader';
import { LOGIN_ROUTE, PRIVACY_POLICY_ROUTE, TERMS_AND_CONDITIONS_OF_USE_ROUTE } from '../../constants/routes';
import { MatchParams } from '../../constants/misc';
import FormCheckbox from '../elements/FormCheckbox';
import { GuestFormFields } from '../../constants/authentication';
import { registerGuestURL } from '../../services/authentication';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext, AuthenticationContext {}

interface OwnState {
    formErrors: any;
    isFetching: boolean;
    registerSuccess: boolean;
    acceptedTermsAndConditions: boolean;
    hasTermsAndConditionsError: boolean;
    showConfirmModal: boolean;
    fields: GuestFormFields;
}

const initialState: OwnState = {
    formErrors: null,
    isFetching: false,
    registerSuccess: false,
    acceptedTermsAndConditions: false,
    hasTermsAndConditionsError: false,
    showConfirmModal: false,
    fields: {
        name: '',
        email: '',
        contact: '',
    },
};

class GuestRegisterScreen extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        if (this.validateFields()) {
            this.createGuest();
        }
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onAcceptedTermsChanged = (): void => {
        const { acceptedTermsAndConditions } = this.state;

        this.setState({
            acceptedTermsAndConditions: !acceptedTermsAndConditions,
        });
    }

    validateFields = (): boolean => {
        let errors: IFormError | null = getFormErrors(this.state.fields, VALIDATIONS.CUSTOMER_FORM);
        const { acceptedTermsAndConditions } = this.state;

        if (!errors) errors = {};

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({
            formErrors: errors ? { fields: errors } : errors,
            hasTermsAndConditionsError: !acceptedTermsAndConditions,
        });
        return errors === null && acceptedTermsAndConditions;
    };

    createGuest = async (): Promise<void> => {
        const { fields } = this.state;
        const { t } = this.props;

        const payload = {
            name: fields.name,
            email: fields.email,
            phone: fields.contact,
        };

        axios.post(registerGuestURL(), payload)
            .then(() => {
                this.setState({
                    showConfirmModal: true,
                });
            })
            .catch(error => {
                if (error.response.status === 409) {
                    const { history, location } = this.props;
                    const { fields: { email } } = this.state;

                    displayNotification(NOTIFICATION_TYPE.ERROR, t('guestRegister.duplicateEmail'));
                    history.push({
                        pathname: LOGIN_ROUTE,
                        state: {
                            from: location,
                            isRedirect: true,
                            userEmail: email,
                        },
                    });
                } else {
                    const { status, data } = error.response;
                    if (status === 400 && data.fields?.email[0].message === t('guestRegister.emailFormatErrorMessage')) {
                        displayNotification(NOTIFICATION_TYPE.ERROR, t('guestRegister.emailFormatError'));
                    } else {
                        displayNotification(NOTIFICATION_TYPE.ERROR, t('guestRegister.defaultError'));
                    }
                }
            });
    };

    onModalConfirm = () => {
        this.setState({
            ...initialState,
        });
    }

    render() {
        const { t } = this.props;
        const {
            fields,
            formErrors,
            isFetching,
            hasTermsAndConditionsError,
            acceptedTermsAndConditions,
            showConfirmModal,
        } = this.state;

        return (
            <div className="container-fluid app_screen">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="app_screen__centered margin-top-bar">
                    <div className="page-title">
                        {t('guestRegister.title')}
                    </div>
                    <form className="register-form-content" onSubmit={this.onSubmit}>
                        <Row>
                            <Col sm={12} md={12}>
                                <FormTextField
                                    name="name"
                                    value={fields.name}
                                    placeholder={`${t('customerRegister.labels.name')} *`}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.name', null)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <FormTextField
                                    name="email"
                                    value={fields.email}
                                    placeholder={`${t('customerRegister.labels.email')} *`}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.email', null)}
                                />
                            </Col>
                            <Col sm={12} md={6}>
                                <FormTextField
                                    name="contact"
                                    value={fields.contact}
                                    placeholder={`${t('customerRegister.labels.contact')} *`}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.contact', null)}
                                    maxLength={9}
                                />
                            </Col>
                        </Row>
                        <div className="terms-checkbox-container">
                            <div>
                                <FormCheckbox
                                    name="acceptedTermsAndConditions"
                                    value={acceptedTermsAndConditions}
                                    onChange={this.onAcceptedTermsChanged}
                                />
                                <p>{t('customerRegister.readAndAccept')}&nbsp;
                                    <NavLink to={TERMS_AND_CONDITIONS_OF_USE_ROUTE} target="_blank">{t('customerRegister.termsAndConditions')}</NavLink>
                                    &nbsp;{t('customerRegister.and')}&nbsp;
                                    <NavLink to={PRIVACY_POLICY_ROUTE} target="_blank">{t('customerRegister.privacyPolicy')}</NavLink>
                                </p>
                            </div>
                            {hasTermsAndConditionsError && (
                            <p className="error-field">{t('errors.Mandatory')}</p>
                            )}
                        </div>
                        <div className="button-wrapper">
                            <Button
                                type="submit"
                                text={t('guestRegister.confirmEmail')}
                                styles="button--blue"
                            />
                        </div>
                    </form>
                    <Modal className="modal-custom modal-custom__no-borders modal-custom__guest-register" show={showConfirmModal} onHide={() => {}} centered>
                        <Modal.Header />
                        <Modal.Body className="guest-modal-body">
                            <h4>{t('guestRegister.modalText1')}</h4>
                            <h5>{t('guestRegister.modalText2', { email: fields.email })}</h5>
                        </Modal.Body>
                        <ModalFooter>
                            <Button styles="button--blue" callback={this.onModalConfirm} text={(t('global.buttons.ok'))} />
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(withRouter((GuestRegisterScreen))));

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const messagesURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/messages${queryString}`;
};

export const joinMessageURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/messages/store${queryString}`;
};

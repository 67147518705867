/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { TranslationContextProvider } from './TranslationContext';

import { AppState } from '../../../reducers/types';
import { KeyedObject } from '../../../constants/misc';
import { getTranslation } from '../../../utils/translation';

import en from '../../../locales/en/translation.json';
import pt from '../../../locales/pt/translation.json';

/**
 * @typedef {Object} StateProps
 * @property {Language} language
 */
interface StateProps {
    language: string;
}

/**
 * @typedef {Object} DispatchProps
 */
interface DispatchProps {}

/**
 * @typedef {Object} OwnProps
 * @extends {StateProps}
 * @property {any} children
 * @property {KeyedObject} translations
 */
interface OwnProps extends StateProps {
    children: any;
    translations: KeyedObject;
}

/**
 * @typedef {Object} Props
 */
type Props = StateProps & OwnProps;

/**
 * @typedef {Object} OwnState
 */
interface OwnState {}

/**
 * @typedef {Object} State
 */
type State = OwnState;

/**
 * provides translation method access to its consumers
 * @extends {Component<Props, State>}
 */
export class TranslationController extends Component<Props, State> {
    static defaultProps = {
        translations: { en, pt },
    };

    /**
     * translate to string in language
     * @param {string} literal
     * @param {KeyedObject} [params]
     */
    t = (literal: string, params?: KeyedObject): string => {
        const { translations, language } = this.props;
        return getTranslation(translations, language, literal, params);
    };

    render() {
        const {
            children,
            language,
        } = this.props;

        return (
            <TranslationContextProvider
                value={{
                    language,
                    t: this.t,
                }}
            >
                {children}
            </TranslationContextProvider>
        );
    }
}

/**
 * mapStateToProps
 * @param {AppState} state
 * @returns {StateProps}
 */
const mapStateToProps = (state: AppState): StateProps => {
    return {
        language: state.preferences.language,
    };
};

/**
 * mapDispatchToProps
 * @returns {DispatchProps}
 */
export const mapDispatchToProps = (): DispatchProps => ({});

export const ConnectedTranslationController = connect(mapStateToProps, mapDispatchToProps)(TranslationController);

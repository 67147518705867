/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, RefObject } from 'react';
import axios from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import FilterResults from 'react-filter-search';

import TagManager, { DataLayerArgs } from 'react-gtm-module';
import MediaQuery from 'react-responsive';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import {
    cssControlClasses,
    enabledProductCampaignFilter,
    MatchParams,
    RouteState,
    SelectOption,
} from '../../constants/misc';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { ScrollMemoryContext, withScrollMemoryContext } from '../controllers/scroll/ScrollMemoryContext';
import {
    BannerTypes,
    Campaign,
    Category,
    Product,
    ProductFiltersList,
    ProductFiltersSearch,
    ProductFiltersSelection,
    ProductListScreenBanner,
} from '../../constants/types';
import {
    productMaxPriceURL,
    productsBrandsURL,
    productsCampaignsURL,
    productsCategoriesURL,
    productsColorsURL,
    productsFindPublicURL,
    productsSizesURL,
} from '../../services/products';
import Loader from '../elements/Loader';
import {
    checkProductWithinPromotion, numberToCurrency, objectToParams, scrollToTop,
} from '../../utils/misc';
import { getFormattedProductURL } from '../../utils/urls';
import { ALL_PRODUCTS_VIEW_ROUTE, INDEX_ROUTE, STORE_VIEW_ROUTE } from '../../constants/routes';
import arrow from '../../assets/images/categories/ic-seta-branca.svg';
import FilterField from '../elements/FilterField';
import SortFilterButton from '../elements/SortFilterButton';
import Button from '../elements/Button';

import iconSearch from '../../assets/images/icon-search.svg';
import iconClose from '../../assets/images/icon-cross_circle.svg';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import { categoriesPublicURL } from '../../services/categories';
import DiscountBox from '../elements/DiscountBox';
import { resourcesURL } from '../../services/resources';

interface OwnProps extends RouteComponentProps<MatchParams, {}, RouteState>, TranslationContext, ScrollMemoryContext {
    storeId?: number;
    storeSlug?: string;
}

interface OwnState {
    isFetching: boolean;
    products: Array<Product>;
    allCategories: Array<Category>;
    _limit: number;
    totalResults: number;
    searchValue: string;
    page: number;
    availableFilters: ProductFiltersList;
    selectedFilters: ProductFiltersSelection;
    searchFilters: ProductFiltersSearch;
    maxPriceSearch: number;
    showFilters: boolean;
    hasReachedEndOfList: boolean;
    currentBanner: ProductListScreenBanner;
}

const initialState: OwnState = {
    isFetching: false,
    products: [],
    allCategories: [],
    _limit: 25,
    totalResults: 0,
    searchValue: '',
    page: 0,
    availableFilters: {
        brand: [],
        color: [],
        size: [],
        category: [],
        campaign: [],
    },
    selectedFilters: {
        brand: '',
        color: '',
        size: '',
        categoryId: null,
        sort: '',
        order: '',
        stock: '',
        minPrice: null,
        maxPrice: null,
        q: null,
        banner: null,
        promotion: '',
    },
    searchFilters: {
        brand: '',
        color: '',
        size: '',
        category: '',
        campaign: '',
    },
    maxPriceSearch: 999,
    showFilters: false,
    hasReachedEndOfList: false,
    currentBanner: {
        id: 0,
        name: '',
        type: null,
        mobileUrl: null,
        desktopUrl: null,
    },
};

const filterRefs = {
    brandFilterFieldRef: React.createRef<FilterField>(),
    sizeFilterFieldRef: React.createRef<FilterField>(),
    colorFilterFieldRef: React.createRef<FilterField>(),
    categoryFilterFieldRef: React.createRef<FilterField>(),
    stockFilterFieldRef: React.createRef<FilterField>(),
    campaignFilterFieldRef: React.createRef<FilterField>(),
    promotionFilterFieldRef: React.createRef<FilterField>(),
} as Record<string, RefObject<FilterField>>;

const RangeSlider = createSliderWithTooltip(Slider.Range);
const filterClass = 'products-list-screen__filters-container__filter';

const stockOptions = [
    {
        label: 'Disponível',
        value: 'true',
    },
    {
        label: 'Indisponível',
        value: 'false',
    },
];

const promoOptions = [
    {
        label: 'Em Promoção',
        value: 'true',
    },
    {
        label: 'Sem Promoção',
        value: 'false',
    },
];

class ProductsListScreen extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    componentDidMount(): void {
        this.parseFilters()
            .then(async () => {
                await this.loadCategories();
                await this.prepare();
                await this.checkForScrollHistory();
            });
        document.addEventListener('scroll', this.onScroll);
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const { location, storeId } = this.props;
        const prevSearch = prevProps.location.search;
        const currentSearch = location.search;
        const prevId = prevProps.storeId;

        if (prevSearch !== currentSearch || prevId !== storeId) {
            this.setState({
                products: [],
                page: 0,
            }, () => {
                this.parseFilters()
                    .then(() => {
                        this.prepare();
                    });
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        const bottom = document.body.scrollHeight - window.pageYOffset - window.innerHeight <= 300;
        const {
            page,
            isFetching,
            hasReachedEndOfList,
            products,
        } = this.state;

        if (bottom && !isFetching && !hasReachedEndOfList && products.length > 0) {
            this.setState({ page: page + 1 }, () => this.getProducts());
        }
    }

    onProductClick = (product: Product) => {
        const { history, setScrollMemoryProducts } = this.props;

        const gtmECommerceTag: DataLayerArgs = {
            dataLayer: {
                event: (checkProductWithinPromotion(product)) ? 'promotion_click' : 'product_click',
                ecommerce: {
                    click: {
                        actionField: { list: 'Home Screen' },
                    },
                    products: [{
                        name: product.shortDescription,
                        id: product.id,
                        price: product.price,
                        brand: product.brand,
                        category: product.category?.description,
                    }],
                },
            },
        };

        TagManager.dataLayer(gtmECommerceTag);
        if (product.storeInfo) {
            const { page } = this.state;
            const { location } = history;
            const coordinate = window.scrollY;

            setScrollMemoryProducts(coordinate, page, location.pathname);

            history.push(getFormattedProductURL(product),
                {
                    from: location.pathname,
                });
        }
    }

    onFilterChange = (name: keyof ProductFiltersSelection, value: string | number | boolean) => {
        const { history, storeSlug } = this.props;
        const prevState = this.state;
        const { selectedFilters } = this.state;
         
        const valueToChange = (String(value) === decodeURIComponent(String(selectedFilters[name]))) ? initialState.selectedFilters[name] : value;

        if (value !== -1) {
            this.setState({
                ...prevState,
                page: 0,
                products: [],
                selectedFilters: {
                    ...selectedFilters,
                    [name]: String(valueToChange) === 'null' ? null : String(valueToChange),
                },
            }, () => {
                const { selectedFilters: newFilters } = this.state;

                const queryString = objectToParams(newFilters);

                if (name === 'categoryId' || name === 'banner') this.setBanners();

                if (queryString === '') {
                    if (storeSlug) {
                        history.push(`${STORE_VIEW_ROUTE}/${storeSlug}`);
                    } else {
                        history.push(ALL_PRODUCTS_VIEW_ROUTE);
                    }
                } else {
                    history.push(queryString);
                }
                scrollToTop();
                this.prepare();
            });
        }
    }

    onPriceRangeChange = (array: Array<number>) => {
        const { selectedFilters, maxPriceSearch } = this.state;

        if ((array[0] || array[1])) {
            this.setState({
                page: 0,
                selectedFilters: {
                    ...selectedFilters,
                    minPrice: array[0] ? array[0] : 0,
                    maxPrice: array[1] ? array[1] : maxPriceSearch,
                },
            });
        }
    }

    onAfterPriceRangeChange = () => {
        const { history } = this.props;
        const { selectedFilters: newFilters } = this.state;

        const queryString = objectToParams(newFilters);
        if (queryString !== '') {
            history.push(queryString);
        }
        this.setState({
            products: [],
        }, () => {
            this.prepare();
        });
    }

    onSortChange = (sort: string, order: string) => {
        const { history, storeSlug } = this.props;
        const prevState = this.state;
        const { selectedFilters } = this.state;

        const isDisabling = (sort === selectedFilters.sort && order === selectedFilters.order);

        this.setState({
            ...prevState,
            page: 0,
            products: [],
            selectedFilters: {
                ...selectedFilters,
                sort: isDisabling ? '' : sort,
                order: isDisabling ? '' : order,
            },
        }, () => {
            const { selectedFilters: newFilters } = this.state;

            const queryString = objectToParams(newFilters);

            if (queryString === '') {
                if (storeSlug) {
                    history.push(`${STORE_VIEW_ROUTE}/${storeSlug}`);
                } else {
                    history.push(ALL_PRODUCTS_VIEW_ROUTE);
                }
            } else {
                history.push(queryString);
            }
            this.getProducts();
        });
    }

    parseFilters = async () => {
        const { location, storeId, productsScrollMemory } = this.props;
        const { search } = location;
        const hasScrollMemory = productsScrollMemory !== null
            && productsScrollMemory.location !== ''
            && productsScrollMemory.coordinate !== 0;

        if (search === '' && !storeId && !hasScrollMemory) {
            await this.clearFilters();
            this.forceCloseAllFilterFields();
        }
        if (search) {
            const filters: ProductFiltersSelection = this.productFiltersToObject(search);

            this.setState({
                selectedFilters: {
                    ...filters,
                },
            });
        }
    }

    forceCloseAllFilterFields = () => {
        Object.keys(filterRefs).forEach((key: keyof typeof filterRefs) => {
            const ref: RefObject<FilterField> = filterRefs[key];
            if (ref.current) ref.current.forceClose();
        });
    }

    productFiltersToObject = (params: string): ProductFiltersSelection => {
        const hasQ = params.charAt(0) === '?';
        const paramList = params.substr(hasQ ? 1 : 0).split('&');

        let object: ProductFiltersSelection = {
            brand: '',
            color: '',
            size: '',
            categoryId: null,
            sort: '',
            order: '',
            stock: '',
            minPrice: null,
            maxPrice: null,
            q: null,
            banner: null,
            promotion: '',
        };

        paramList.forEach(filter => {
            const paramSplit = filter.split('=');
            object = {
                ...object,
                [paramSplit[0]]: decodeURIComponent(paramSplit[1]),
            };
        });

        return object;
    };

    checkForFilters = (): boolean => {
        const { selectedFilters } = this.state;

        return ((selectedFilters.categoryId !== -1 && selectedFilters.categoryId !== null)
            || selectedFilters.brand !== ''
            || selectedFilters.color !== ''
            || selectedFilters.size !== ''
            || selectedFilters.order !== ''
            || selectedFilters.sort !== ''
            || selectedFilters.stock !== ''
            || selectedFilters.minPrice !== null
            || selectedFilters.maxPrice !== null
            || selectedFilters.q !== null
            || selectedFilters.promotion !== ''
            || selectedFilters.banner !== null);
    }

    prepare = async () => {
        const { selectedFilters } = this.state;
        const { storeId, t } = this.props;
        const hasStore = storeId !== undefined;

        const filters = {
            brand: selectedFilters.brand,
            categoryId: selectedFilters.categoryId,
            color: selectedFilters.color,
            size: selectedFilters.size,
            order: selectedFilters.order,
            sort: selectedFilters.sort,
            stock: selectedFilters.stock,
            minPrice: selectedFilters.minPrice,
            maxPrice: selectedFilters.maxPrice,
            q: selectedFilters.q,
            storeId: hasStore ? storeId : null,
            banner: selectedFilters.banner,
        };

        const urls = [productMaxPriceURL(filters), productsBrandsURL(filters), productsColorsURL(filters), productsSizesURL(filters), productsCategoriesURL(filters), productsCampaignsURL(filters)];

        axios.all(urls.map(url => axios.get(url)))
            .then(axios.spread((maxPriceResponse, brandsResponse, colorsResponse, sizesResponse, categoriesResponse, campaignsResponse) => {
                this.setState({
                    maxPriceSearch: maxPriceResponse.data,
                    availableFilters: {
                        brand: brandsResponse.data,
                        color: colorsResponse.data,
                        size: sizesResponse.data,
                        category: categoriesResponse.data.map((el: Array<string>) => {
                            return {
                                headerUrl: '',
                                longDescription: '',
                                thumbnailUrl: '',
                                id: Number(el[0]),
                                description: el[1],
                            };
                        }),
                        campaign: campaignsResponse.data.map((el: Array<string>) => {
                            return {
                                id: Number(el[0]),
                                name: el[1],
                                desktopUrl: el[5],
                                mobileUrl: el[6],
                            };
                        }),
                    },
                    hasReachedEndOfList: false,
                }, () => {
                    this.getProducts();
                    this.setBanners();
                });
            }))
            .catch(() => {
                this.getProducts();
                displayNotification(NOTIFICATION_TYPE.ERROR, t('errors.couldNotFetchFilters'));
            });
    }

    getProducts = async (customLimit?: number) => {
        const { storeId } = this.props;
        const {
            _limit,
            isFetching,
            page,
            products,
            selectedFilters,
        } = this.state;

        if (isFetching) return;

        const hasFilters = this.checkForFilters();

        const hasStore = storeId !== undefined;

        this.setState({
            isFetching: true,
        });

        let url = productsFindPublicURL({
            pageSize: customLimit || _limit,
            page: customLimit ? 0 : page,
            storeId: storeId || null,
        });

        if (hasFilters) {
            url = productsFindPublicURL({
                q: selectedFilters.q,
                pageSize: customLimit || _limit,
                page: customLimit ? 0 : page,
                brand: selectedFilters.brand,
                categoryId: selectedFilters.categoryId,
                color: selectedFilters.color,
                size: selectedFilters.size,
                order: selectedFilters.order,
                sort: selectedFilters.sort,
                stock: selectedFilters.stock,
                promotion: selectedFilters.promotion,
                time: selectedFilters.promotion !== '' ? moment().unix() : null,
                minPrice: selectedFilters.minPrice,
                maxPrice: selectedFilters.maxPrice,
                storeId: hasStore ? storeId : null,
                banner: selectedFilters.banner,
            });
        }

        try {
            const { data, headers } = await axios.get(url);

            const newTotalResults: number = parseInt(headers['x-total-count']);

            if (newTotalResults === 0 && page === 0) {
                this.setState({
                    isFetching: false,
                    totalResults: newTotalResults,
                });
            }
            if (products.length <= newTotalResults) {
                if (data && data.length === 0) {
                    const { page: currentPage } = this.state;
                    this.setState({
                        isFetching: false,
                        hasReachedEndOfList: true,
                        page: currentPage <= 0 ? 0 : currentPage - 1,
                    });
                } else {
                    this.setState({
                        isFetching: false,
                        products: [...products, ...data],
                        totalResults: newTotalResults,
                    }, () => {
                        const impressionProducts = data.map((el: Product, idx: number) => {
                            return ({
                                name: el.shortDescription,
                                id: el.id,
                                price: el.price,
                                brand: el.brand,
                                category: el.category,
                                list: 'AllProductsList',
                                position: idx,
                            });
                        });

                        const gtmECommerceProductImpressions: DataLayerArgs = {
                            dataLayer: {
                                ecommerce: {
                                    currencyCode: 'EUR',
                                    impressions: impressionProducts,
                                },
                            },
                        };

                        TagManager.dataLayer(gtmECommerceProductImpressions);
                    });
                }
            } else {
                this.setState({
                    isFetching: false,
                });
            }
        } catch (e) {
            this.setState({
                isFetching: false,
            });
        }
    }

    loadCategories = async () => {
        const { data } = await axios.get(categoriesPublicURL());

        this.setState({
            allCategories: data,
        });
    }

    checkForScrollHistory = async () => {
        const { productsScrollMemory: scrollMemory, history, clearScrollMemoryProducts: clearScrollMemory } = this.props;
        const { _limit } = this.state;

        if (scrollMemory && history.location.pathname === scrollMemory.location) {
            const { coordinate, page } = scrollMemory;
            const numberOfElements = (page + 1) * _limit;
            await this.getProducts(numberOfElements);
            this.setState({
                page,
            });
            setTimeout(() => {
                window.scroll({
                    top: coordinate,
                    left: 0,
                    behavior: 'smooth',
                });
            }, 200);
        }
        clearScrollMemory();
    }

    clearFilters = async () => {
        const { history, storeSlug } = this.props;

        this.setState({
            page: 0,
            products: [],
            selectedFilters: {
                brand: '',
                color: '',
                size: '',
                categoryId: null,
                sort: '',
                order: '',
                stock: '',
                minPrice: null,
                maxPrice: null,
                q: null,
                banner: null,
                promotion: '',
            },
            hasReachedEndOfList: false,
        }, () => {
            if (storeSlug) {
                history.push(`${STORE_VIEW_ROUTE}/${storeSlug}`);
            } else {
                history.push(ALL_PRODUCTS_VIEW_ROUTE);
            }
        });
    }

    getCategoryNameFromID = (id: number | null) => {
        const { allCategories } = this.state;
        if (id) {
            const cat = allCategories.find(el => {
                return (Number(el.id) === Number(id));
            });
            if (cat) {
                return cat.description;
            }
        }
        return '';
    }

    getCategoryBannerFromId = (id: number | null): ProductListScreenBanner => {
        const { allCategories } = this.state;

        if (id) {
            const cat = allCategories.find(el => {
                return (Number(el.id) === Number(id));
            });
            if (cat) {
                return {
                    id,
                    name: cat.description,
                    type: BannerTypes.CATEGORY,
                    desktopUrl: cat.headerUrl,
                    mobileUrl: cat.headerUrl,
                };
            }
        }
        return {
            id: -1,
            name: '',
            type: null,
            desktopUrl: null,
            mobileUrl: null,
        };
    }

    getCampaignNameFromID = (id: number | null) => {
        const { availableFilters: { campaign } } = this.state;
        if (id) {
            const cmp = campaign.find(el => {
                return (Number(el.id) === Number(id));
            });
            if (cmp) {
                return cmp.name;
            }
        }
        return '';
    }

    getCampaignBannerFromId = (id: number | null): ProductListScreenBanner => {
        const { availableFilters: { campaign } } = this.state;

        if (id) {
            const cmp = campaign.find(el => {
                return (el.id === Number(id));
            });
            if (cmp) {
                return {
                    ...cmp,
                    type: BannerTypes.CAMPAIGN,
                };
            }
        }

        return {
            id: -1,
            name: '',
            type: null,
            desktopUrl: null,
            mobileUrl: null,
        };
    }

    setSearchFields = (e: React.ChangeEvent<HTMLInputElement>) => {
        const prevState = this.state;
        const { searchFilters } = this.state;

        this.setState({
            ...prevState,
            searchFilters: {
                ...searchFilters,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    }

    toggleMobileFilters = (): void => {
        const { showFilters } = this.state;
        this.setState({
            showFilters: !showFilters,
        });
    }

    setBanners = () => {
        const { selectedFilters: { categoryId, banner }, currentBanner: { type } } = this.state;

        if (categoryId === null && banner === null) {
            this.setState({
                currentBanner: {
                    id: null,
                    name: '',
                    type: null,
                    desktopUrl: null,
                    mobileUrl: null,
                },
            });
        } else {
            if (!banner || (banner && categoryId && !type)) {
                this.setState({
                    currentBanner: this.getCategoryBannerFromId(categoryId),
                });
            }
            if (!categoryId) {
                this.setState({
                    currentBanner: this.getCampaignBannerFromId(banner),
                });
            }
        }
    }

    renderFilterResults = (results: Array<string>, field: keyof ProductFiltersSelection) => {
        const { t } = this.props;
        const { selectedFilters } = this.state;
        if (results.length > 0) {
            return results.map(filter => {
                return (
                    <div
                        className={`${filterClass}${String(filter) === decodeURIComponent(String(selectedFilters[field])) ? cssControlClasses.active : cssControlClasses.inactive}`}
                        onClick={() => this.onFilterChange(field, filter)}
                        key={String(filter)}
                    >
                        {filter}
                    </div>
                );
            });
        }

        return <p>{t('errors.noFiltersFound')}</p>;
    }

    renderFilterFields = () => {
        const { t } = this.props;

        const {
            availableFilters,
            selectedFilters,
            searchFilters,
            maxPriceSearch,
        } = this.state;

        return (
            <>
                { availableFilters.brand.length > 0 && (
                <FilterField
                    title="Marca"
                    startsOpen={selectedFilters.brand !== ''}
                    ref={filterRefs.brandFilterFieldRef}
                >
                    <div className="products-list-screen__filters-container__search-field">
                        <input
                            value={searchFilters.brand}
                            type="text"
                            placeholder="Pesquise por marca"
                            name="brand"
                            onChange={this.setSearchFields}
                        />
                        <img src={iconSearch} alt="" />
                    </div>
                    <FilterResults
                        value={searchFilters.brand}
                        data={availableFilters.brand}
                        renderResults={(results: Array<string>) => this.renderFilterResults(results, 'brand')}
                    />
                </FilterField>
                )
                }
                { availableFilters.size.length > 0 && (
                    <FilterField
                        title="Tamanho"
                        startsOpen={selectedFilters.size !== ''}
                        ref={filterRefs.sizeFilterFieldRef}
                    >
                        <div className="products-list-screen__filters-container__search-field">
                            <input
                                value={searchFilters.size}
                                type="text"
                                placeholder="Pesquise por tamanho"
                                name="size"
                                onChange={this.setSearchFields}
                            />
                            <img src={iconSearch} alt="" />
                        </div>
                        <FilterResults
                            value={searchFilters.size}
                            data={availableFilters.size}
                            renderResults={(results: Array<string>) => this.renderFilterResults(results, 'size')}
                        />
                    </FilterField>
                )}
    
                { availableFilters.color.length > 0 && (
                <FilterField
                    title="Cor"
                    startsOpen={selectedFilters.color !== ''}
                    ref={filterRefs.colorFilterFieldRef}
                >
                    <div className="products-list-screen__filters-container__search-field">
                        <input
                            value={searchFilters.color}
                            type="text"
                            placeholder="Pesquise por cor"
                            name="color"
                            onChange={this.setSearchFields}
                        />
                        <img src={iconSearch} alt="" />
                    </div>
                    <FilterResults
                        value={searchFilters.color}
                        data={availableFilters.color}
                        renderResults={(results: Array<string>) => this.renderFilterResults(results, 'color')}
                    />
                </FilterField>
                )}
                { availableFilters.category.length > 0 && (
                    <FilterField
                        title="Categoria"
                        startsOpen={selectedFilters.categoryId !== null && selectedFilters.categoryId > -1}
                        ref={filterRefs.categoryFilterFieldRef}
                    >
                        <div className="products-list-screen__filters-container__search-field">
                            <input
                                value={searchFilters.category}
                                type="text"
                                placeholder="Pesquise por categoria"
                                name="category"
                                onChange={this.setSearchFields}
                            />
                            <img src={iconSearch} alt="" />
                        </div>
                        <FilterResults
                            value={searchFilters.category}
                            data={availableFilters.category}
                            renderResults={(results: Array<Category>) => {
                                if (results.length > 0) {
                                    return results.map((cat: Category) => {
                                        return (
                                            <div
                                                data-testid="filter-field-category__results"
                                                className={`${filterClass}${Number(cat.id) === Number(selectedFilters.categoryId) ? cssControlClasses.active : cssControlClasses.inactive}`}
                                                onClick={() => this.onFilterChange('categoryId', cat.id)}
                                                key={cat.id}
                                            >
                                                {cat.description}
                                            </div>
                                        );
                                    });
                                }
                                return <p>{t('errors.noFiltersFound')}</p>;
                            }}
                        />
                    </FilterField>
                )}
                {enabledProductCampaignFilter && availableFilters.campaign.length > 0 && (
                    <FilterField
                        title="Campanha"
                        startsOpen={selectedFilters.banner !== null && selectedFilters.banner > -1}
                        ref={filterRefs.campaignFilterFieldRef}
                    >
                        <div className="products-list-screen__filters-container__search-field">
                            <input
                                value={searchFilters.campaign}
                                type="text"
                                placeholder="Pesquise por campanha"
                                name="campaign"
                                onChange={this.setSearchFields}
                            />
                            <img src={iconSearch} alt="" />
                        </div>
                        <FilterResults
                            value={searchFilters.campaign}
                            data={availableFilters.campaign}
                            renderResults={(results: Array<Campaign>) => {
                                if (results.length > 0) {
                                    return results.map((cmp: Campaign) => {
                                        return (
                                            <div
                                                className={`${filterClass}${Number(cmp.id) === Number(selectedFilters.banner) ? cssControlClasses.active : cssControlClasses.inactive}`}
                                                onClick={() => this.onFilterChange('banner', cmp.id)}
                                                key={cmp.id}
                                            >
                                                {cmp.name}
                                            </div>
                                        );
                                    });
                                }
                                return <p>{t('errors.noFiltersFound')}</p>;
                            }}
                        />
                    </FilterField>
                )}
                <FilterField
                    title="Stock"
                    startsOpen={selectedFilters.stock !== ''}
                    ref={filterRefs.stockFilterFieldRef}
                >
                    <FilterResults
                        value=""
                        data={stockOptions}
                        renderResults={(results: Array<SelectOption>) => {
                            if (results.length > 0) {
                                return results.map((opt: SelectOption) => {
                                    return (
                                        <div
                                            className={`${filterClass}${opt.value === selectedFilters.stock ? cssControlClasses.active : cssControlClasses.inactive}`}
                                            onClick={() => this.onFilterChange('stock', opt.value)}
                                            key={`${opt.label}-${opt.value}`}
                                        >
                                            {opt.label}
                                        </div>
                                    );
                                });
                            }
                            return <p>{t('errors.noFiltersFound')}</p>;
                        }}
                    />
                </FilterField>
                <FilterField
                    title="Promoção"
                    startsOpen={selectedFilters.promotion !== null && selectedFilters.promotion !== ''}
                    ref={filterRefs.promotionFilterFieldRef}
                >
                    <FilterResults
                        value=""
                        data={promoOptions}
                        renderResults={(results: Array<SelectOption>) => {
                            if (results.length > 0) {
                                return results.map((opt: SelectOption) => {
                                    return (
                                        <div
                                            className={`${filterClass}${opt.value === selectedFilters.promotion ? cssControlClasses.active : cssControlClasses.inactive}`}
                                            onClick={() => this.onFilterChange('promotion', opt.value)}
                                            key={`${opt.label}-${opt.value}`}
                                        >
                                            {opt.label}
                                        </div>
                                    );
                                });
                            }
                            return <p>{t('errors.noFiltersFound')}</p>;
                        }}
                    />
                </FilterField>
                <div className="products-list-screen__filters-container__range">
                    <h6>Preço</h6>
                    <RangeSlider
                        min={0}
                        max={maxPriceSearch}
                        value={[selectedFilters.minPrice ? selectedFilters.minPrice : 0,
                            selectedFilters.maxPrice ? selectedFilters.maxPrice : maxPriceSearch]}
                        allowCross={false}
                        onChange={value => this.onPriceRangeChange(value)}
                        onAfterChange={() => this.onAfterPriceRangeChange()}
                    />
                </div>
            </>
        );
    }

    renderCategoryBanner = () => {
        const { t } = this.props;
        const { selectedFilters, currentBanner } = this.state;

        const catString = this.getCategoryNameFromID(selectedFilters.categoryId);

        return (
            <div className="products-list-header">
                <div
                    style={{ backgroundImage: `url(${currentBanner.desktopUrl})` }}
                    className="products-list-header__content"
                    data-testid="header-section"
                >
                    <div className="products-list-header__content__links">
                        <div>
                            <a href={INDEX_ROUTE}>{t('productDetails.backLinks.index')}</a>
                            &nbsp;/&nbsp;
                            <div>Categorias</div>
                            &nbsp;/&nbsp;
                            <div className="product-details__top-container__links--current">{catString}</div>
                        </div>
                        <h3>{catString}</h3>
                    </div>
                </div>
            </div>
        );
    }

    renderCampaignBanner = () => {
        const { currentBanner, selectedFilters: { banner } } = this.state;

        const bannerString = this.getCampaignNameFromID(banner);

        return (
            <>
                <MediaQuery minWidth={601}>
                    <div className="products-list-header products-list-header--campaign">
                        <div
                            style={{ backgroundImage: `url(${resourcesURL(String(currentBanner.desktopUrl))})` }}
                            className="products-list-header__content"
                            data-testid="header-section"
                        >
                            <div className="products-list-header__content__links">
                                <h3>{bannerString}</h3>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={600}>
                    <div className="products-list-header products-list-header--campaign products-list-header--campaign__mobile">
                        <div
                            style={{ backgroundImage: `url(${resourcesURL(String(currentBanner.mobileUrl))})` }}
                            className="products-list-header__content"
                            data-testid="header-section"
                        >
                            <div className="products-list-header__content__links">
                                <h3>{bannerString}</h3>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
            </>
        );
    }

    renderProduct = (product: Product, isExposition = false) => {
        const isProductInPromotion = checkProductWithinPromotion(product);
        const { t } = this.props;
        return (
            <div
                className="products-list-screen__products-results-container__product-container"
                onClick={!isExposition ? () => this.onProductClick(product) : () => { }}
                key={product.id}
            >
                <div
                    className="products-list-screen__products-results-container__product-container__image-container"
                    style={{
                        backgroundImage: `url(${product.thumbnailUrl})`,
                    }}
                >
                    {isProductInPromotion && <DiscountBox product={product} />}
                    <div
                        className="products-list-screen__products-results-container__product-container__image-container__overlay"
                    >{t('products.seeMoreLabel')} <img src={arrow} alt="" />
                    </div>
                </div>
                <div className="products-list-screen__products-results-container__product-container__caption-container">
                    {product.storeInfo && <h6>{product.storeInfo.name}</h6>}
                    <h5>{product.shortDescription}</h5>
                    <div className="products-list-screen__products-results-container__product-container__caption-container__prices">
                        {isProductInPromotion
                            ? (
                                product.discountPrice && product.price && (
                                    <>
                                        <div className="products-list-screen__products-results-container__product-container__caption-container__prices--old-price">
                                            {numberToCurrency(product.price)}
                                        </div>
                                        <div className="products-list-screen__products-results-container__product-container__caption-container__prices--price">
                                            {numberToCurrency(product.discountPrice)}
                                        </div>
                                    </>
                                ))
                            : (
                                <div className="products-list-screen__products-results-container__product-container__caption-container__prices--price">
                                    {numberToCurrency(product.price || 0)}
                                </div>
                            )}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { storeId } = this.props;

        const {
            isFetching,
            products,
            totalResults,
            showFilters,
            selectedFilters,
            currentBanner,
        } = this.state;

        const hasStore = storeId !== undefined;

        return (
            <>
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                {(!hasStore && currentBanner.type === null) && <div className="products-list-header--spaced" />}
                {!hasStore && currentBanner.type === BannerTypes.CATEGORY && this.renderCategoryBanner()}
                {!hasStore && currentBanner.type === BannerTypes.CAMPAIGN && this.renderCampaignBanner()}
                <div className="products-list-screen">
                    <div className="products-list-screen__order-by">
                        {!hasStore && (
                            <div className="products-list-screen__order-by__results">
                                <div>Encontrámos</div>
                                <div className="blue">&nbsp;{totalResults}&nbsp;</div>
                                <div>resultados para a sua pesquisa</div>
                            </div>
                        )}
                        <div className="products-list-screen__order-by__controls">
                            <h6>Ordenar por</h6>
                            <SortFilterButton
                                label="Mais Recente"
                                callback={() => { this.onSortChange('createdDate', 'desc'); }}
                                isActive={selectedFilters.sort === 'createdDate' && selectedFilters.order === 'desc'}
                            />
                            <SortFilterButton
                                label="Preço"
                                ascending
                                callback={() => { this.onSortChange('price', 'asc'); }}
                                isActive={selectedFilters.sort === 'price' && selectedFilters.order === 'asc'}
                            />
                            <SortFilterButton
                                label="Preço"
                                descending
                                callback={() => { this.onSortChange('price', 'desc'); }}
                                isActive={selectedFilters.sort === 'price' && selectedFilters.order === 'desc'}
                            />
                            <MediaQuery maxWidth={1023}>
                                <SortFilterButton
                                    data-testid="filter-modal-button"
                                    label="Filtrar"
                                    callback={() => { this.toggleMobileFilters(); }}
                                    isActive={showFilters}
                                />
                            </MediaQuery>
                        </div>
                    </div>
                    <MediaQuery maxWidth={1023}>
                        <Modal show={showFilters} onHide={() => { this.toggleMobileFilters(); }}>
                            <Modal.Header className="products-list-screen__filters-container__modal-header">
                                <h6 className="products-list-screen__filters-container__filter-title">Filtrar por</h6>
                                <img src={iconClose} alt="close mdoal" onClick={() => { this.toggleMobileFilters(); }} />
                            </Modal.Header>
                            <div className="products-list-screen__filters-container">
                                {this.renderFilterFields()}
                                <div className="products-list-screen__filters-container__modal-buttons">
                                    <Button
                                        disabled={!this.checkForFilters}
                                        callback={() => this.clearFilters()}
                                        text="Limpar"
                                    />
                                    <Button
                                        callback={() => {
                                            setTimeout(() => {
                                                scrollToTop();
                                            },
                                            200);
                                            this.toggleMobileFilters();
                                        }}
                                        text="Filtrar"
                                    />
                                </div>
                            </div>
                        </Modal>
                    </MediaQuery>
                    <MediaQuery minWidth={1024}>
                        <div data-testid="filters-container" className="products-list-screen__filters-container">
                            <h6 className="products-list-screen__filters-container__filter-title">Filtrar por</h6>
                            {this.renderFilterFields()}
                            <div className="products-list-screen__filters-container__clear-button">
                                <Button
                                    data-testid="clear-filters-button"
                                    disabled={!this.checkForFilters}
                                    callback={() => this.clearFilters()}
                                    text="Limpar filtros"
                                />
                            </div>
                        </div>
                    </MediaQuery>
                    <div className="products-list-screen__products-results-container" data-testid="products-results-container">
                        {products.map(product => {
                            if (product.productURL) {
                                return (
                                    <a href={product.productURL} target="_blank" rel="noopener noreferrer">
                                        {this.renderProduct(product, true)}
                                    </a>
                                );
                            }
                            return (
                                this.renderProduct(product, false)
                            );
                        })}
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslationContext(withScrollMemoryContext(withRouter(ProductsListScreen)));

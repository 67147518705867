/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';

import iconOpen from '../../assets/images/ic-open.svg';

interface OwnProps {
    icon?: string;
    title?: string;
    startsExpanded?: boolean;
}

interface OwnState {
    isExpanded: boolean;
}

const initialState = {
    isExpanded: false,
};

class ShowHideField extends React.Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { startsExpanded } = this.props;

        if (startsExpanded) {
            this.setState({
                isExpanded: true,
            });
        }
    }

    toggleShowHide = () => {
        const { isExpanded } = this.state;
        this.setState({
            isExpanded: !isExpanded,
        });
    }

    render() {
        const {
            icon,
            title,
            children,
        } = this.props;

        const { isExpanded } = this.state;

        const buttonClass = isExpanded ? 'show-hide-field__header__button--expanded' : 'show-hide-field__header__button';

        return (
            <div className="show-hide-field">
                <div className="show-hide-field__header" onClick={() => { this.toggleShowHide(); }}>
                    <div className="show-hide-field__header__left">
                        {icon && <img src={icon} alt="" />}
                        {title && (<p>{title}</p>)}
                    </div>
                    <div className={buttonClass}>
                        <img src={iconOpen} alt="" />
                    </div>
                </div>
                {isExpanded && (
                    <div className="show-hide-field__content">
                        {children}
                    </div>
                )}
            </div>
        );
    }
}

export default ShowHideField;

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import moment from 'moment';
import { FormattedSchedule, ScheduleItem } from '../constants/types';

export const schedulesComparator = (a:ScheduleItem, b:ScheduleItem): number => {
    if (a.initialWeekDay && b.initialWeekDay && a.openOn && b.openOn) {
        if (a.initialWeekDay < b.initialWeekDay) {
            return -1;
        }
        if (a.initialWeekDay > b.initialWeekDay) {
            return 1;
        }
        if (a.openOn < b.openOn) {
            return -1;
        }
        if (a.openOn > b.openOn) {
            return 1;
        }
    }
    return -1;
};

export const areSchedulesSameHours = (a: FormattedSchedule, b: FormattedSchedule): boolean => {
    return (a.schedule1.openOn === b.schedule1.openOn
        && a.schedule2?.openOn === b.schedule2?.openOn
        && a.schedule1.closeOn === b.schedule1.closeOn
        && a.schedule2?.closeOn === b.schedule2?.closeOn);
};

export const schedulesGrouper = (arr: Array<FormattedSchedule>): Array<Array<FormattedSchedule>> => {
    const groupArr: Array<Array<FormattedSchedule>> = [];

    arr.forEach((el, idx) => {
        const group = [el];
        for (let i = idx + 1; i < arr.length; i++) {
            const nextEl = arr[i];
            if (nextEl && areSchedulesSameHours(el, nextEl)) {
                group.push(nextEl);
                arr.splice(i, 1);
                i--;
            } else if (!nextEl || !areSchedulesSameHours(el, nextEl)) {
                break;
            }
        }
        groupArr.push(group);
    });

    return groupArr;
};

export const validateHasOverlappingSchedules = (weekSchedule: Array<ScheduleItem>): boolean => {
    let hasOverlaps = false;

    if (weekSchedule && weekSchedule.length > 0) {
        Object.keys(weekSchedule).forEach(k => {
            const item = weekSchedule[Number(k)];

            const initialDay = item.initialWeekDay || item.initialWeekDay === 0 ? Number(item.initialWeekDay) : null;
            const endDay = item.endWeekDay || item.endWeekDay === 0 ? Number(item.endWeekDay) : null;
            const startTime = item.openOn ? Number(item.openOn) : null;
            const endTime = item.closeOn ? Number(item.closeOn) : null;

            if (initialDay === null || endDay === null || startTime === null || endTime === null) return false;

            const otherSchedulesSameDay = weekSchedule.filter(schedule => schedule.id !== item.id
                && schedule.initialWeekDay === initialDay
                && schedule.endWeekDay === endDay);

            if (otherSchedulesSameDay.length > 0) {
                otherSchedulesSameDay.forEach(schedule => {
                    const otherStartTime = schedule.openOn ? Number(schedule.openOn) : null;
                    const otherEndTime = schedule.closeOn ? Number(schedule.closeOn) : null;
                    if (
                        otherStartTime && otherEndTime && (
                            (startTime >= otherStartTime && startTime <= otherEndTime)
                            || (endTime >= otherStartTime && endTime <= otherEndTime)
                            || (otherStartTime >= startTime && otherStartTime <= endTime)
                            || (otherEndTime >= startTime && otherEndTime <= endTime)
                        )
                    ) hasOverlaps = true;
                });
            }
        });
    }

    return hasOverlaps;
};

export const validateHasEmptySchedule = (weekSchedule: Array<ScheduleItem>): boolean => {
    let hasEmpty = false;
    if (weekSchedule && weekSchedule.length > 0) {
        Object.keys(weekSchedule).forEach(k => {
            const item = weekSchedule[Number(k)];

            const emptyInitialWeekDay: boolean = item.initialWeekDay === null || item.initialWeekDay === undefined || String(item.initialWeekDay).trim() === '';
            const emptyEndWeekDay: boolean = !!item.isInterval && (item.endWeekDay === null || item.endWeekDay === undefined || String(item.endWeekDay).trim() === '');
            const emptyCloseOn: boolean = !item.closeOn || String(item.closeOn).trim() === '';
            const emptyOpenOn: boolean = !item.openOn || String(item.openOn).trim() === '';

            if (emptyCloseOn || emptyEndWeekDay || emptyInitialWeekDay || emptyOpenOn) hasEmpty = true;
        });
    }

    return hasEmpty;
};

export const validateScheduleOpensBeforeCloses = (weekSchedule: Array<ScheduleItem>) : boolean => {
    return weekSchedule.some(schedule => (schedule.openOn && schedule.closeOn ? Number(schedule.openOn) > Number(schedule.closeOn) : true));
};

export const parseTimeToSend = (date: string | number): string | null => {
    if (!date || String(date).trim() === '') {
        return null;
    }

    if (isNaN(Number(date))) {
        return String(date);
    }

    return moment(date).format('HH:mm:ss.SSSSSSSSS');
};

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import Col from 'react-bootstrap/Col';
import { Link, RouteComponentProps } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { ReactCookieProps, withCookies } from 'react-cookie';
import TagManager, { DataLayerArgs } from 'react-gtm-module';
import MediaQuery from 'react-responsive';
import { CartContext, withCartContext } from '../../controllers/cart/CartContext';
import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import Loader from '../../elements/Loader';
import { MatchParams, PaymentMethods } from '../../../constants/misc';
import {
    CHECKOUT_ROUTE, INDEX_ROUTE, PRODUCT_VIEW_ROUTE, STORE_VIEW_ROUTE,
} from '../../../constants/routes';
import {
    checkProductWithinPromotion,
    constructVoucherBonusString,
    numberToCurrency,
    scrollToTop,
} from '../../../utils/misc';
import Button from '../../elements/Button';
import {
    PaymentFee, ShoppingLine, StoreCart,
} from '../../../constants/types';
import { ICON, SvgIcon } from '../../elements/SvgIcon';

import trash from '../../../assets/images/icon-trash.svg';

import { productURL } from '../../../services/products';
import ButtonImgIcon from '../../elements/ButtonImgIcon';

interface OwnProps extends RouteComponentProps<MatchParams>, CartContext, AuthenticationContext, TranslationContext, ReactCookieProps {}

interface OwnState {
    isFetching: boolean;
    formErrors: any;
    showErrorModal: boolean;
    address: string;
    contact: string;
    postalCode: string;
    hasDeliveryHome: boolean;
    showRevisionModal: boolean;
    paymentMethod: PaymentMethods | null;
    mbWayContact: string;
    cartTotal: number;
    nif: string;
    name: string;
    hasDeliveryTaxes: boolean;
    city: string;
    fees: Array<PaymentFee>;
}

const initialState: OwnState = {
    isFetching: false,
    formErrors: null,
    showErrorModal: false,
    hasDeliveryHome: false,
    showRevisionModal: false,
    paymentMethod: PaymentMethods.DEBIT_CARD,
    nif: '',
    name: '',
    address: '',
    contact: '',
    city: '',
    postalCode: '',
    mbWayContact: '',
    cartTotal: 0,
    hasDeliveryTaxes: false,
    fees: [],
};

class CartScreen extends Component<OwnProps> {
    state = { ...initialState };

    componentDidMount(): void {
        const {
            shoppingCart, fetchCart, history, user,
        } = this.props;

        setTimeout(() => {
            scrollToTop();
        },
        100);

        fetchCart();

        if (!shoppingCart) {
            history.push(INDEX_ROUTE);
        } else {
            const { productsCounter } = shoppingCart;
            if (productsCounter > 0) {
                this.setState({
                    address: user && user.address ? user.address : '',
                    postalCode: user && user.postalCode ? user.postalCode : '',
                    contact: user && user.contact ? user.contact : '',
                    city: user && user.city ? user.city : '',
                    cartTotal: shoppingCart.total,
                });
            } else {
                history.push(INDEX_ROUTE);
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<{}>, snapshot?: any) {
        const { shoppingCart: oldCart } = prevProps;
        const { shoppingCart, history } = this.props;

        if (oldCart && shoppingCart && oldCart.total !== shoppingCart.total) {
            if (shoppingCart.productsCounter === 0) {
                history.push(INDEX_ROUTE);
            }
        }
    }

    onProductClick = (productId: number) => {
        const { history } = this.props;

        history.push(`${PRODUCT_VIEW_ROUTE}/${productId}`);
    }

    onProductStoreClick = (storeSlug: string) => {
        const { history } = this.props;
        history.push(`${STORE_VIEW_ROUTE}/${storeSlug}`);
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value,
        });
    };

    onCloseErrorModal = (): void => {
        this.setState({
            showErrorModal: false,
        });
    }

    onRemoveItem = (cartId: string, lineId: string): void => {
        const { shoppingCart, removeProduct } = this.props;

        if (shoppingCart) {
            this.setState({
                isFetching: true,
            });

            const { storeCarts } = shoppingCart;
            const cart: StoreCart | undefined = storeCarts.find(c => c.id === cartId);

            if (cart) {
                const { shoppingLines } = cart;
                const line: ShoppingLine | undefined = shoppingLines.find(l => l.id === lineId);

                if (line) {
                    removeProduct(line.id);
                    this.sendProductRemovalGoogleTag(line.productId);
                }
            }

            this.setState({
                isFetching: false,
            });
        }
    }

    onDecrementQuantity = (cartId: string, lineId: string): void => {
        const { shoppingCart, updateProduct } = this.props;

        if (shoppingCart) {
            this.setState({
                isFetching: true,
            });

            const { storeCarts } = shoppingCart;
            const cart: StoreCart | undefined = storeCarts.find(c => c.id === cartId);

            if (cart) {
                const { shoppingLines } = cart;
                const line: ShoppingLine | undefined = shoppingLines.find(l => l.id === lineId);

                if (line) {
                    if (line.quantity > 1) {
                        updateProduct(line.id, line.productId, line.quantity - 1);
                    } else {
                        this.onRemoveItem(cartId, lineId);
                    }
                }
            }

            this.setState({
                isFetching: false,
            });
        }
    }

    onIncrementQuantity = (cartId: string, lineId: string): void => {
        const { shoppingCart, updateProduct } = this.props;

        if (shoppingCart) {
            this.setState({
                isFetching: true,
            });

            const { storeCarts } = shoppingCart;
            const cart: StoreCart | undefined = storeCarts.find(c => c.id === cartId);

            if (cart) {
                const { shoppingLines } = cart;
                const line: ShoppingLine | undefined = shoppingLines.find(l => l.id === lineId);

                if (line) {
                    if (line.quantity < 99 && shoppingCart.productsCounter < 99) {
                        updateProduct(line.id, line.productId, line.quantity + 1);
                        this.sendProductAdditionGoogleTag(line.productId);
                    }
                }
            }

            this.setState({
                isFetching: false,
            });
        }
    }

    sendProductRemovalGoogleTag = async (productId: number) => {
        const { data } = await axios.get(productURL(productId));

        const price = checkProductWithinPromotion(data) ? data.discountPrice : data.price;

        const impressionProduct = {
            name: data.shortDescription,
            id: data.id,
            price,
            brand: data.brand,
            category: data.category,
        };

        const gtmECommerceTag: DataLayerArgs = {
            dataLayer: {
                event: 'removeFromCart',
                ecommerce: {
                    remove: {
                        products: [impressionProduct],
                    },
                },
            },
        };

        TagManager.dataLayer(gtmECommerceTag);
    }

    sendProductAdditionGoogleTag = async (productId: number) => {
        const { data } = await axios.get(productURL(productId));

        const price = checkProductWithinPromotion(data) ? data.discountPrice : data.price;

        const impressionProduct = {
            name: data.shortDescription,
            id: data.id,
            price,
            brand: data.brand,
            category: data.category,
            quantity: 1,
        };

        const gtmECommerceTag: DataLayerArgs = {
            dataLayer: {
                event: 'addToCart',
                ecommerce: {
                    currencyCode: 'EUR',
                    add: {
                        products: [impressionProduct],
                    },
                },
            },
        };

        TagManager.dataLayer(gtmECommerceTag);
    }

    sendToCheckout = (): void => {
        const { history } = this.props;
        history.push(CHECKOUT_ROUTE);
    }

    render() {
        const {
            t,
            shoppingCart,
        } = this.props;
        const {
            isFetching,
            showErrorModal,
        } = this.state;

        let storeCount = 0;

        let shoppingCartLength = 0;
        if (shoppingCart) {
            shoppingCartLength = Object.keys(shoppingCart).length;
        }

        return (
            <div className="app_screen">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="app_screen__cart container">
                    <MediaQuery maxWidth={991}>
                        <div className="app_screen__cart__links">
                            <div>
                                <a href={INDEX_ROUTE}>{t('productDetails.backLinks.index')}</a>
                                &nbsp;/&nbsp;
                                <div className="app_screen__cart__links--current">{t('topBar.shoppingCart')}</div>
                            </div>
                        </div>
                    </MediaQuery>
                    <div className="page-title">
                        {t('shoppingCart.title')}
                    </div>
                    <div className="cart-container">
                        <div className="cart-container">
                            {(shoppingCart && shoppingCartLength > 0) ? (
                                Object.keys(shoppingCart.storeCarts).map(k => {
                                    const cart = shoppingCart.storeCarts[Number(k)];
                                    storeCount++;
                                    return (
                                        <div key={cart.id} className="store-cart-container">
                                            {Object.keys(cart.shoppingLines).map((key, idx) => {
                                                const line = cart.shoppingLines[Number(key)];
                                                const storeSlug = line.storeInfo.slug;
                                                const isLastElement = (idx === Object.keys(cart.shoppingLines).length - 1);

                                                return (
                                                    <React.Fragment key={line.id}>
                                                        <div key={line.id} className="line-container">
                                                            <img
                                                                src={line.productThumbnailUrl}
                                                                alt={line.productName}
                                                                onClick={(): void => this.onProductClick(line.productId)}
                                                            />
                                                            <div className="line-info">
                                                                <div className="line-info__top">
                                                                    <div className="line-info__top__text">
                                                                        <h5 className="store-name" onClick={(): void => this.onProductStoreClick(storeSlug)}>{cart.storeName}</h5>
                                                                        <span
                                                                            className="product-title"
                                                                            onClick={(): void => this.onProductClick(line.productId)}
                                                                        >
                                                                            {line.productName}
                                                                            {line.isVoucher
                                                                            && (
                                                                                <div className="bonus">{constructVoucherBonusString(line)}</div>
                                                                            )}
                                                                            <div className="attributes">
                                                                                <div className="grey">
                                                                                    {line.productSize && line.productSize !== ''
                                                                                && <p className="line">{t('checkout.size')}&nbsp;{line.productSize}</p>
                                                                                }
                                                                                    {line.productColor && line.productColor !== ''
                                                                                && <p className="line">{t('checkout.color')}&nbsp;<div style={{ background: line.productColor }} /></p>
                                                                                }
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                    <MediaQuery minWidth={992}>
                                                                        <ButtonImgIcon
                                                                            icon={trash}
                                                                            styles="button--blue button--small"
                                                                            callback={(): void => this.onRemoveItem(cart.id, line.id)}
                                                                        />
                                                                    </MediaQuery>
                                                                </div>
                                                                <MediaQuery minWidth={992}>
                                                                    <div className="controls-container">
                                                                        <div className="quantity-controls">
                                                                            <div className="controls minus" onClick={(): void => this.onDecrementQuantity(cart.id, line.id)}>
                                                                                <SvgIcon icon={ICON.MINUS} />
                                                                            </div>
                                                                            <div className="input-container">
                                                                                <input
                                                                                    name="quantity"
                                                                                    value={line.quantity}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div className="controls plus" onClick={(): void => this.onIncrementQuantity(cart.id, line.id)}>
                                                                                <SvgIcon icon={ICON.PLUS} />
                                                                            </div>
                                                                        </div>
                                                                        <p className="bold-blue">{numberToCurrency(line.productPrice)}</p>
                                                                    </div>
                                                                </MediaQuery>
                                                            </div>
                                                            <MediaQuery maxWidth={991}>
                                                                <div className="controls-container">
                                                                    <div>
                                                                        <div className="quantity-controls">
                                                                            <div className="controls minus" onClick={(): void => this.onDecrementQuantity(cart.id, line.id)}>
                                                                                <SvgIcon icon={ICON.MINUS} />
                                                                            </div>
                                                                            <div className="input-container">
                                                                                <input
                                                                                    name="quantity"
                                                                                    value={line.quantity}
                                                                                    disabled
                                                                                />
                                                                            </div>
                                                                            <div className="controls plus" onClick={(): void => this.onIncrementQuantity(cart.id, line.id)}>
                                                                                <SvgIcon icon={ICON.PLUS} />
                                                                            </div>
                                                                        </div>
                                                                        <ButtonImgIcon
                                                                            icon={trash}
                                                                            styles="button--blue button--small"
                                                                            callback={(): void => this.onRemoveItem(cart.id, line.id)}
                                                                        />
                                                                    </div>
                                                                    <p className="bold-blue">{numberToCurrency(line.subtotal)}</p>
                                                                </div>
                                                            </MediaQuery>
                                                        </div>

                                                        {!isLastElement && <hr className="divider my-3" />}
                                                    </React.Fragment>
                                                );
                                            })}
                                            {storeCount < shoppingCart.storeCarts.length && (
                                                <hr className="divider store-divider" />
                                            )}
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="no-data">
                                    {t('checkout.noShoppingCart')}
                                </div>
                            )}
                        </div>
                        <div className="fullwidth-container">
                            <Col lg={12} className="info-container">
                                {shoppingCart && shoppingCartLength > 0 && (
                                <div className="content">
                                    <div className="price-container">
                                        <span>{t('checkout.subtotal')}&nbsp;</span>
                                        <span className="bold">{numberToCurrency(shoppingCart.total)}</span>
                                    </div>
                                    <hr className="divider store-divider" />
                                    <div className="button-wrapper">
                                        <Button
                                            text={t('shoppingCart.finishBtn')}
                                            styles="button--dark-blue button--bigger"
                                            callback={this.sendToCheckout}
                                            disabled={shoppingCart.total < 1}
                                        />
                                    </div>
                                    <div className="footer-link-wrapper">
                                        <Link to={INDEX_ROUTE}>{t('shoppingCart.continue')}</Link>
                                    </div>
                                    {shoppingCart.total < 1 && (
                                        <div className="error">
                                            {t('checkout.minor1EurError')}
                                        </div>
                                    )}
                                </div>
                                )}
                            </Col>
                        </div>
                    </div>
                </div>
                <Modal className="modal-custom" show={showErrorModal} onHide={this.onCloseErrorModal} centered>
                    <ModalHeader className="success-modal-header" closeButton>
                        <ModalTitle>
                            <div className="success-modal">
                                <h2 className="my-2 red-text">{t('checkout.error')}</h2>
                                <h5>{t('checkout.requestErrorMessage')}</h5>
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                </Modal>
            </div>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(withCartContext(withCookies((CartScreen)))));

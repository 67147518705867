/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import Modal from 'react-bootstrap/Modal';

import Row from 'react-bootstrap/Row';
import { get } from 'lodash';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { DeliveryMethods, Voucher } from '../../../constants/types';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import ImageUpload from '../../elements/ImageUpload';
import Button from '../../elements/Button';
import FormTextField from '../../elements/FormTextField';
import FormSelectField from '../../elements/FormSelectField';
import FormCheckbox from '../../elements/FormCheckbox';
import { globalButtonLabels, SelectOption } from '../../../constants/misc';
import { categoriesPublicURL } from '../../../services/categories';
import Loader from '../../elements/Loader';
import { getFormErrors, IFormError, VALIDATIONS } from '../../../utils/validation';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { productGroupURL, productsAdminURL, productURL } from '../../../services/products';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import displayConfirm from '../../elements/displayConfirm';
import FormTextAreaField from '../../elements/FormTextAreaField';
import { asyncForEach } from '../../../utils/misc';
import { AuthenticationContext, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { UserRoles } from '../../../constants/authorization';

interface OwnProps extends TranslationContext, AuthenticationContext {
    voucherEdit: Voucher | null;
    saveCallback: Function;
    closeCallback: Function;
    onVoucherClickCallback: Function;
    onVoucherDeleteCallback: Function;
    isVoucherAllowed: boolean;
}

interface OwnState {
    isFetching: boolean,
    hasUnsavedChanges: boolean,
    categoriesOptions: Array<SelectOption>;
    voucher: Voucher;
    formErrors: any;
    isCreatingVoucherVariant: boolean;
    voucherVariants: Array<Voucher>;
    parentVoucherName: string;
}

const initialState: OwnState = {
    isFetching: false,
    hasUnsavedChanges: true,
    categoriesOptions: [],
    voucher: {
        reference: '',
        shortDescription: '',
        id: -1,
        description: '',
        price: 20,
        thumbnailHash: '',
        inStock: true,
        thumbnail: null,
        categoryId: null,
        groupId: '',
        email: '',
        voucherBonus: 3,
    },
    formErrors: null,
    isCreatingVoucherVariant: false,
    voucherVariants: [],
    parentVoucherName: '',
};

const createErrorLabel = 'storeAdmin.vouchers.createError';
const categoryLabel = 'storeAdmin.products.labels.category';
const applicationJSONType = 'application/json';

class VoucherForm extends React.Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { voucherEdit } = this.props;

        if (voucherEdit) {
            this.setState({
                hasUnsavedChanges: false,
                voucher: {
                    ...voucherEdit,
                    categoryId: voucherEdit.category?.id,
                },
            });
        }

        this.prepare();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const { voucherEdit: prevVoucher } = prevProps;
        const { voucherEdit } = this.props;
        
        if (prevVoucher !== voucherEdit) {
            if (voucherEdit) {
                this.setState({
                    hasUnsavedChanges: false,
                    voucher: {
                        ...voucherEdit,
                        categoryId: voucherEdit.category?.id,
                    },
                });
            } else {
                this.setState({
                    hasUnsavedChanges: true,
                    voucher: initialState.voucher,
                });
            }

            this.prepare();
        }
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
        const { voucher } = this.state;
        const { inStock } = voucher;
        const { name } = e.currentTarget;

        if (name === 'inStock') {
            this.setState({
                hasUnsavedChanges: true,
                voucher: {
                    ...voucher,
                    inStock: !inStock,
                },
            });

            return;
        }

        this.setState({
            hasUnsavedChanges: true,
            voucher: {
                ...voucher,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onThumbnailSelected = (file: File): void => {
        const { voucher } = this.state;

        this.setState({
            hasUnsavedChanges: true,
            voucher: {
                ...voucher,
                thumbnail: file,
            },
        });
    };

    loadCategories = async () => {
        this.setState({
            isFetching: true,
        });
        axios.get(categoriesPublicURL())
            .then(res => {
                const { t } = this.props;

                const categorySelectOptList: Array<SelectOption> = [];

                categorySelectOptList.push({
                    value: '',
                    label: t(categoryLabel),
                });

                Object.keys(res.data).forEach(k => {
                    const c = res.data[Number(k)];
                    categorySelectOptList.push({
                        value: c.id,
                        label: c.description,
                    });
                });

                this.setState({
                    categoriesOptions: [...categorySelectOptList],
                });
            })
            .catch(() => {

            })
            .finally(() => {
                this.setState({
                    isFetching: false,
                });
            });
    }
    
    validateFields = () => {
        const { voucherEdit } = this.props;
        const { voucher } = this.state;

        let errors: IFormError | null = getFormErrors(voucher, VALIDATIONS.VOUCHER_FORM);

        if (!errors) errors = {};
        if (!voucherEdit && !voucher.thumbnail) {
            errors.thumbnail = [{ typeOfViolation: 'NotBlank' }];
        }

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    submitVoucher = () => {
        const { voucherEdit, t } = this.props;

        if (this.validateFields()) {
            if (voucherEdit) {
                this.editVoucherRequest();
            } else {
                this.createVoucherRequest();
            }
        } else {
            if (voucherEdit) displayNotification(NOTIFICATION_TYPE.ERROR, t('storeAdmin.products.editFieldsError'));
            else displayNotification(NOTIFICATION_TYPE.ERROR, t('storeAdmin.products.createFieldsError'));
        }
    }

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    editVoucherRequest = (): void => {
        const { saveCallback, voucherEdit, t } = this.props;
        const { voucher } = this.state;
        this.setState({ isFetching: true });

        const formData = new FormData();
        const fields = {
            voucher: true,
            shortDescription: String(voucher.shortDescription).trim(),
            description: String(voucher.description).trim(),
            categoryId: voucher.categoryId,
            price: String(voucher.price).trim(),
            inStock: voucher.inStock,
            reference: voucher.reference,
            availableDeliveryMethods: [DeliveryMethods.EMAIL],
            voucherBonus: voucher.voucherBonus,
            email: voucher.email,
            length: 1,
            width: 1,
            height: 1,
            brand: 'Viva Leiria',
            tags: [],
            groupId: voucher.groupId ? String(voucher.groupId).trim() : '',
        };

        if (voucher.thumbnail) {
            formData.append('thumbnail', voucher.thumbnail, voucher.thumbnail.name);
        }

        formData.append('product', new Blob([JSON.stringify(fields)], {
            type: applicationJSONType,
        }));

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        axios.put(productURL(voucher.id), formData, config)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('storeAdmin.products.editSuccess'));

                if (voucherEdit) {
                    this.setState({
                        isFetching: false,
                    }, () => {
                        saveCallback();
                    });
                } else {
                    this.setState({
                        voucher: {
                            ...initialState.voucher,
                        },
                        isFetching: false,
                        hasUnsavedChanges: false,
                    }, () => saveCallback());
                }
            })
            .catch(error => this.handleErrors(error));
    }

    handleErrors = (error: any): void => {
        const { t } = this.props;

        if (error.response) {
            const { data, status } = error.response;
            if (data && data.result) {
                displayNotification(NOTIFICATION_TYPE.ERROR, t(createErrorLabel));
                this.setState({
                    isFetching: false,
                });
            } else {
                let responseError;
                if (status === 413) {
                    responseError = { fields: { thumbnail: [{ typeOfViolation: 'FileSize' }] } };
                }
                displayNotification(NOTIFICATION_TYPE.ERROR, t(createErrorLabel));
                this.handleResponse(responseError || error.response.data);
            }
        } else {
            displayNotification(NOTIFICATION_TYPE.ERROR, t(createErrorLabel));
            this.setState({
                isFetching: false,
            });
        }
    }

    createVoucherRequest = async () => {
        const { saveCallback, t } = this.props;
        const { voucher } = this.state;

        this.setState({ isFetching: true });

        try {
            const priceRanges = [40, 80];
            const bonusRanges = [6, 12];

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            const firstFormData = new FormData();
            const firstFields = {
                voucher: true,
                shortDescription: String(voucher.shortDescription).trim(),
                description: String(voucher.description).trim(),
                categoryId: voucher.categoryId,
                price: String(voucher.price).trim(),
                inStock: voucher.inStock,
                reference: 'voucher',
                availableDeliveryMethods: [DeliveryMethods.EMAIL],
                voucherBonus: String(voucher.voucherBonus).trim(),
                email: voucher.email,
                length: 1,
                width: 1,
                height: 1,
                brand: 'Viva Leiria',
                tags: [],
            };
            if (voucher.thumbnail) {
                firstFormData.append('thumbnail', voucher.thumbnail, voucher.thumbnail.name);
            }
            firstFormData.append('product', new Blob([JSON.stringify(firstFields)], {
                type: applicationJSONType,
            }));

            const { status, data } = await axios.post(productsAdminURL(), firstFormData, config);

            if (status === 200) {
                const { groupId } = data;

                await asyncForEach(priceRanges, (price: number, idx: number) => {
                    const bonus = bonusRanges[idx];
                    const formData = new FormData();
                    const fields = {
                        ...firstFields,
                        groupId,
                        price: String(price).trim(),
                        voucherBonus: String(bonus).trim(),
                    };
                    if (voucher.thumbnail) {
                        formData.append('thumbnail', voucher.thumbnail, voucher.thumbnail.name);
                    }
                    formData.append('product', new Blob([JSON.stringify(fields)], {
                        type: applicationJSONType,
                    }));

                    axios.post(productsAdminURL(), formData, config)
                        .then(() => {
                            displayNotification(NOTIFICATION_TYPE.SUCCESS, t('storeAdmin.products.createSuccess'));
                            if (idx === priceRanges.length - 1) {
                                saveCallback();
                            }
                        });
                });
            }
        } catch (error) {
            this.handleErrors(error);
        } finally {
            this.setState({
                isFetching: false,
            });
        }
    }

    onAddVoucherVariant = (): void => {
        const { t } = this.props;
        const {
            hasUnsavedChanges, voucher,
        } = this.state;

        if (!voucher.groupId || String(voucher.groupId).trim() === '') {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('storeAdmin.products.doesNotHaveGroupId'));
            return;
        }

        if (hasUnsavedChanges) {
            displayConfirm({
                acceptButtonText: t('global.buttons.ok'),
                onAccept: () => this.showAddVoucherVariant(true),
                onReject: () => {},
                rejectButtonText: t(globalButtonLabels.cancel),
                message: t('storeAdmin.products.hasUnsavedChangesMessage'),
            });
            return;
        }

        this.showAddVoucherVariant(true);
    }

    prepare = async () => {
        await this.loadCategories();
        await this.getVoucherVariants();
    }

    getModalTitle = (): string => {
        const { voucherEdit, t } = this.props;
        const { isCreatingVoucherVariant, parentVoucherName } = this.state;

        if (voucherEdit && !isCreatingVoucherVariant) return t('storeAdmin.vouchers.editVoucher');
        if (isCreatingVoucherVariant) return t('storeAdmin.vouchers.addVoucherVariant', { name: parentVoucherName });
        return t('storeAdmin.vouchers.addVoucher');
    }

    showAddVoucherVariant = (resetChanges = false) => {
        const { voucher } = this.state;
        const initialVoucher = initialState.voucher;

        if (resetChanges && initialVoucher) {
            this.setState({
                isCreatingVoucherVariant: true,
                parentVoucherName: voucher.shortDescription,
                voucher: {
                    ...initialVoucher,
                    email: voucher.email,
                    groupId: voucher.groupId,
                    thumbnail: null,
                    thumbnailHash: '',
                    thumbnailUrl: '',
                },
            });
        } else {
            this.setState({
                isCreatingVoucherVariant: true,
                parentVoucherName: voucher.shortDescription,
                voucher: {
                    ...voucher,
                    thumbnail: null,
                    thumbnailHash: '',
                    thumbnailUrl: '',
                },
            });
        }
    }

    getVoucherVariants = async () => {
        const { voucher } = this.state;

        if (voucher && voucher.groupId && String(voucher.groupId).trim() !== '') {
            this.setState({ isFetching: true });
            axios.get(productGroupURL(voucher.groupId))
                .then(response => {
                    const { data } = response;

                    this.setState({
                        isFetching: false,
                        voucherVariants: [...data],
                    });
                })
                .catch(() => {
                    this.setState({
                        isFetching: false,
                    });
                });
        }
    }

    render() {
        const {
            t,
            closeCallback,
            voucherEdit,
            onVoucherClickCallback,
            onVoucherDeleteCallback,
            user,
        } = this.props;

        const {
            isFetching,
            voucher,
            formErrors,
            categoriesOptions,
            isCreatingVoucherVariant,
            voucherVariants,
            hasUnsavedChanges,
        } = this.state;

        const isVariantCreationDisabled = isCreatingVoucherVariant || (voucherEdit !== null);
        const isSuperAdmin = user?.groups[0] === UserRoles.ADMIN;

        return (
            <>
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.getModalTitle()}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container modal-custom product-form">
                        <p>{t('storeAdmin.products.headers.mainPhoto')}</p>
                        <Row>
                            <ImageUpload
                                imageUrl={voucher.thumbnailUrl}
                                onSelected={this.onThumbnailSelected}
                                buttonText={t('global.buttons.upload')}
                                helpText={t('admin.stores.labels.thumbnailHelpText')}
                                errors={get(formErrors, 'fields.thumbnail', null)}
                                disabled={isSuperAdmin}
                            />
                        </Row>
                        <Row className="show-grid">
                            <Col xs={12} md={6}>
                                <FormTextField
                                    label={t('storeAdmin.products.labels.shortDescription')}
                                    placeholder={t('storeAdmin.products.labels.shortDescription')}
                                    name="shortDescription"
                                    value={voucher.shortDescription}
                                    onChange={this.onInputChange}
                                    maxLength={60}
                                    errors={get(formErrors, 'fields.shortDescription', null)}
                                    disabled={isSuperAdmin}
                                />
                            </Col>
                            <Col xs={12} md={3}>
                                <FormTextField
                                    label={t('storeAdmin.products.labels.price')}
                                    placeholder={t('storeAdmin.products.labels.price')}
                                    name="price"
                                    value={voucher.price}
                                    maxLength={30}
                                    disabled
                                />
                            </Col>
                            <Col xs={12} md={3}>
                                <FormTextField
                                    label={t('storeAdmin.vouchers.labels.bonus')}
                                    placeholder={t('storeAdmin.vouchers.labels.bonus')}
                                    name="voucherBonus"
                                    value={voucher.voucherBonus}
                                    maxLength={30}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col xs={12} md={12}>
                                <FormTextAreaField
                                    fieldStyles="fixed-area"
                                    label={t('storeAdmin.products.labels.description')}
                                    placeholder={t('storeAdmin.products.labels.description')}
                                    name="description"
                                    value={voucher.description}
                                    onChange={this.onInputChange}
                                    maxLength={120}
                                    errors={get(formErrors, 'fields.description', null)}
                                    disabled={isSuperAdmin}
                                />
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col xs={12} md={6}>
                                <FormSelectField
                                    name="categoryId"
                                    label={t(categoryLabel)}
                                    placeholder={t(categoryLabel)}
                                    value={String(voucher.categoryId)}
                                    options={categoriesOptions}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.categoryId', null)}
                                    disabled={isSuperAdmin}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <FormTextField
                                    label={t('storeAdmin.vouchers.labels.storeEmail')}
                                    placeholder={t('storeAdmin.vouchers.labels.storeEmail')}
                                    name="email"
                                    value={voucher.email}
                                    onChange={this.onInputChange}
                                    maxLength={80}
                                    errors={get(formErrors, 'fields.email', null)}
                                    disabled={isVariantCreationDisabled || isSuperAdmin}
                                />
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col xs={12} md={12} className="mb-0">
                                <FormCheckbox
                                    name="inStock"
                                    value={voucher.inStock}
                                    onChange={this.onInputChange}
                                    label={t('storeAdmin.products.labels.inStock')}
                                    disabled={isSuperAdmin}
                                />
                            </Col>
                        </Row>
                        <React.Fragment>
                            {voucherEdit && (
                            <Row>
                                <Col xs={12} md={6}>
                                    <FormTextField
                                        label={t('storeAdmin.products.labels.groupId')}
                                        placeholder={t('storeAdmin.products.labels.groupId')}
                                        name="groupId"
                                        value={voucher.groupId}
                                        onChange={this.onInputChange}
                                        maxLength={80}
                                        errors={get(formErrors, 'fields.groupId', null)}
                                        disabled={isVariantCreationDisabled || isSuperAdmin}
                                    />
                                </Col>
                            </Row>
                            )}
                            {voucherEdit && voucherVariants.length > 0 && (
                            <ul>
                                <h5 className="small-title">{t('storeAdmin.vouchers.labels.voucherVariants')}</h5>
                                {Object.keys(voucherVariants).map(k => {
                                    const p = voucherVariants[Number(k)];

                                    return (
                                        <li key={p.id} className={`list-group-item variant-item ${p.id === voucher.id ? 'disabled' : ''}`} onClick={() => onVoucherClickCallback(p)}>
                                            <div className="d-flex w-100 justify-content-between">
                                                <p className={p.id === voucher.id ? 'bold' : ''}>{p.shortDescription}</p>
                                                {p.id !== voucher.id && (
                                                <SvgIcon icon={ICON.TRASH} callback={(e: React.MouseEvent) => onVoucherDeleteCallback(e, p, true)} />

                                                )}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                            )}
                        </React.Fragment>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        styles="button--blue button--small"
                        text={t(globalButtonLabels.cancel)}
                        callback={() => closeCallback()}
                    />
                    <Button
                        styles="button--dark-blue button--small"
                        text={
                            voucherEdit !== null
                                ? t('storeAdmin.products.buttons.save')
                                : t('storeAdmin.vouchers.labels.createVoucherButton')
                        }
                        callback={() => this.submitVoucher()}
                        disabled={!hasUnsavedChanges}
                    />
                </Modal.Footer>
            </>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(VoucherForm));

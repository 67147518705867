/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const tagsURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/tags${queryString}`;
};

export const tagURL = (id: number | string): string => {
    return `${API_URL}/tags/${id}`;
};

export const tagsPublicURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/tags/find/public${queryString}`;
};

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TagManager, { DataLayerArgs } from 'react-gtm-module';
import 'animate.css';

import Carousel from 'react-bootstrap/Carousel';
import MediaQuery from 'react-responsive';
import { CarouselItem } from 'react-bootstrap';
import CarouselCaption from 'react-bootstrap/CarouselCaption';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { MatchParams } from '../../constants/misc';
import Loader from '../elements/Loader';
import { Campaign, Product, Store } from '../../constants/types';
import {
    ALL_PRODUCTS_VIEW_ROUTE,
    JOIN_US_ROUTE,
    ALL_STORES_ROUTE,
} from '../../constants/routes';
import { storesPublicURL, storesRandomURL } from '../../services/stores';
import { resourcesURL } from '../../services/resources';

import iconEasyUse from '../../assets/images/icon-easy_use.svg';
import iconCommerce from '../../assets/images/icon-commerce.svg';
import iconPayOnline from '../../assets/images/icon-online_pay.svg';
import cmlLogo from '../../assets/images/sponsors/cml_logo.png';
import acilisLogo from '../../assets/images/sponsors/acilis_logo.png';
import agencyLogo from '../../assets/images/sponsors/agency-01.png';
import nerleiLogo from '../../assets/images/sponsors/nerlei_logo.png';
import suLeiriaLogo from '../../assets/images/sponsors/suleiria_logo.png';
import voidLogo from '../../assets/images/sponsors/void_logo.jpg';
import euPagoLogo from '../../assets/images/sponsors/eupago.png';
import carouselArrow from '../../assets/images/carousels/ic-home-carousel-arrow.svg';

import { productsRandomURL, productURL } from '../../services/products';
import { GeneralContext, withGeneralContext } from '../controllers/general/GeneralContext';
import {
    checkProductWithinPromotion, numberToCurrency, scrollToElement,
} from '../../utils/misc';
import iconGratis from '../../assets/images/ic-gratis.svg';
import iconOnline from '../../assets/images/ic-totally-online.svg';
import iconParcerias from '../../assets/images/ic-parcerias.svg';
import HomeCategoriesSelector from '../elements/HomeCategoriesSelector';
import DiscountBox from '../elements/DiscountBox';
import { campaignsAdminURL } from '../../services/campaigns';
import { getFormattedProductURL, getFormattedStoreURL } from '../../utils/urls';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext, GeneralContext {}

interface OwnState {
    isFetching: boolean;
    campaigns: Array<Campaign>;
    products: Array<Product>;
    productsCarouselIndex: number;
    stores: Array<Store>;
    highlightedStores: Array<Store>;
    storesCarouselIndex: number;
    formErrors: any;
    productsLastSeen: Array<Product>;
    productsLastSeenCarouselIndex: number;
    windowWidth: number;
    charityStoreId: string | number;
}

const initialState: OwnState = {
    isFetching: false,
    productsCarouselIndex: 0,
    storesCarouselIndex: 0,
    campaigns: [],
    stores: [],
    highlightedStores: [],
    products: [],
    formErrors: null,
    productsLastSeen: [],
    productsLastSeenCarouselIndex: 0,
    windowWidth: window.innerWidth,
    charityStoreId: -1,
};

class HomeScreen extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount(): void {
        const { history } = this.props;
        setTimeout(() => {
            scrollToElement(history.location.hash);
            if (history.location.hash) history.location.hash = '';
        },
        400);
        this.prepare();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any): void {
        const {
            productsCarouselIndex: oldIndex,
            storesCarouselIndex: oldStoresIndex,
            productsLastSeenCarouselIndex: oldProductsLastSeenIndex,
        } = prevState;
        const {
            productsCarouselIndex,
            storesCarouselIndex,
            productsLastSeenCarouselIndex,
        } = this.state;
        const { location: oldLocation } = prevProps;
        const { location, history } = this.props;
        const { hash: oldHash } = oldLocation;
        const { hash } = location;

        if (oldIndex === productsCarouselIndex && oldStoresIndex === storesCarouselIndex
            && oldProductsLastSeenIndex === productsLastSeenCarouselIndex && hash !== oldHash && hash !== '') {
            scrollToElement(history.location.hash);
            if (history.location.hash) history.location.hash = '';
        }
    }

    handleProductClick = (product: Product, lastSeen = false) => {
        const { history } = this.props;

        const gtmECommerceTag: DataLayerArgs = {
            dataLayer: {
                event: (product.inPromotion && product.price === product.lastPrice) ? 'promotion_click' : 'product_click',
                ecommerce: {
                    click: {
                        actionField: { list: 'Home Screen' },
                    },
                    products: [{
                        name: product.shortDescription,
                        id: product.id,
                        price: product.price,
                        brand: product.brand,
                        category: product.category?.description,
                    }],
                },
            },
        };

        TagManager.dataLayer(gtmECommerceTag);

        history.push({
            pathname: getFormattedProductURL(product),
            state: {
                from: lastSeen ? 'products_last_seen' : 'products_random',
            },
        });
    };

    handleStoreClick = (store: Store) => {
        const { history } = this.props;

        const gtmECommerceTag: DataLayerArgs = {
            dataLayer: {
                event: 'store_click',
                ecommerce: {
                    items: [{
                        item_name: store.name,
                        item_id: store.id,
                        item_category: store.category?.description,
                    }],
                },
            },
        };

        TagManager.dataLayer(gtmECommerceTag);

        history.push(getFormattedStoreURL(store));
    };

    seeAllProducts = () => {
        const { history } = this.props;
        history.push(ALL_PRODUCTS_VIEW_ROUTE);
    }

    getLastSeenProducts = () => {
        const { lastSeenProducts } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        axios.all(lastSeenProducts.map(id => axios.get(productURL(id))))
            .then(results => {
                const dataResults: Array<any> = results.map(r => r.data);

                this.setState({
                    isFetching: false,
                    productsLastSeen: [...dataResults],
                });
            })
            .catch(() => {
                this.setState({
                    isFetching: false,
                });
            });
    }

    prepare = () => {
        const { lastSeenProducts } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        const urls = [campaignsAdminURL({ active: true }), storesRandomURL(), productsRandomURL(), storesPublicURL({ vouchers: true })];

        axios.all(urls.map(url => axios.get(url)))
            .then(axios.spread((campaignsResponse, storesResponse, productsResponse, highlightedStoresResponse) => {
                this.setState({
                    isFetching: false,
                    campaigns: campaignsResponse.data,
                    stores: storesResponse.data.slice(0, 8),
                    highlightedStores: [...highlightedStoresResponse.data],
                    products: productsResponse.data,
                }, () => {
                    if (lastSeenProducts.length > 0) {
                        this.getLastSeenProducts();
                    }
                });
            }))
            .catch(error => {
                this.setState({ isFetching: false });
                if (error.response?.status === 401) {
                    this.prepare();
                }
            });
    };

    sendGTMImpressions = () => {
        const {
            products,
            stores,
            productsLastSeen,
        } = this.state;

        const impressionStores = stores.map((store, idx) => {
            return ({
                item_id: store.id,
                item_name: store.name,
                item_category: store.category?.description,
                item_list_name: 'Homepage Stores',
                index: idx,
            });
        });

        const impressionProducts = [...products, ...productsLastSeen].map((el, idx) => {
            if (el.price === el.lastPrice) {
                return ({
                    name: el.shortDescription,
                    id: el.id,
                    price: el.price,
                    brand: el.brand,
                    category: el.category,
                    list: 'Homepage',
                    position: idx,
                });
            }
            return {};
        });

        const impressionDiscounts = [...products, ...productsLastSeen].map((el, idx) => {
            if (el.inPromotion && el.price !== el.lastPrice) {
                return ({
                    name: el.shortDescription,
                    id: el.id,
                    price: el.price,
                    brand: el.brand,
                    category: el.category,
                    list: 'Homepage',
                    position: idx,
                });
            }
            return {};
        });

        const gtmECommerceProductImpressions: DataLayerArgs = {
            dataLayer: {
                ecommerce: {
                    currencyCode: 'EUR',
                    impressions: impressionProducts,
                },
            },
        };

        const gtmGA4StoreImpressions: DataLayerArgs = {
            dataLayer: {
                event: 'view_stores_list',
                ecommerce: {
                    items: impressionStores,
                },
            },
        };

        const gtmGA4PromoImpressions: DataLayerArgs = {
            dataLayer: {
                event: 'view_promos_list',
                ecommerce: {
                    currencyCode: 'EUR',
                    impressions: impressionDiscounts,
                },
            },
        };

        TagManager.dataLayer(gtmECommerceProductImpressions);
        TagManager.dataLayer(gtmGA4StoreImpressions);
        TagManager.dataLayer(gtmGA4PromoImpressions);
    }

    onBannerClick = (e: React.MouseEvent<HTMLDivElement>, campaign: Campaign) => {
        if (campaign.bannerUrl && campaign.bannerUrl !== '') {
            window.location.href = campaign.bannerUrl;
        }
    }

    renderCampaignCarousels = (): JSX.Element[] => {
        const { campaigns } = this.state;
        
        return campaigns.map((campaign: Campaign) => {
            const imageClassName = 'home-screen__carousel-container__header__carousel__carousel-image';
            const imageAttachment = campaign.anchorRight ? '--right' : '--left';

            return (
                <Carousel.Item onClick={e => this.onBannerClick(e, campaign)} key={campaign.id}>
                    <MediaQuery maxWidth={550}>
                        <div
                            style={{ backgroundImage: `url(${resourcesURL(campaign.mobileImageHash)}` }}
                            className={`${imageClassName} ${imageClassName}${imageAttachment}`}
                        />
                    </MediaQuery>
                    <MediaQuery minWidth={551}>
                        <div
                            style={{ backgroundImage: `url(${resourcesURL(campaign.desktopImageHash)}` }}
                            className={`${imageClassName} ${imageClassName}${imageAttachment}`}
                        />
                    </MediaQuery>
                    <div className={`home-screen__carousel-container__header__carousel__carousel-item custom ${campaign.bannerUrl ? '' : 'not-clickable'}`} data-testid="carousel-item">
                        <Carousel.Caption>
                            <div className="home-screen__carousel-container__header__carousel__carousel-item__caption custom">
                                <h5 className="animate__animated animate__fadeInDown" style={{ color: campaign.titleColor }}>{campaign.title}</h5>
                                <h1 className="animate__animated animate__fadeInUp" style={{ color: campaign.subtitleColor }}>{campaign.subtitle}</h1>
                                {campaign.button && campaign.buttonUrl && campaign.buttonText && (
                                    <a
                                        className="btn btn-light-blue"
                                        href={campaign.buttonUrl}
                                    >
                                        {campaign.buttonText}
                                    </a>
                                )}
                            </div>
                        </Carousel.Caption>
                    </div>
                </Carousel.Item>
            );
        });
    }

    renderHeader = () => {
        const { campaigns } = this.state;
        return (
            <Carousel className="home-screen__carousel-container__header__carousel" controls={campaigns.length > 1}>
                {this.renderCampaignCarousels()}
            </Carousel>
        );
    }

    renderSubHeader = () => {
        const { t } = this.props;

        return (
            <div className="home-screen__carousel-container__subheader">
                <MediaQuery minWidth={1220}>
                    <div className="home-screen__carousel-container__subheader__item">
                        <div className="home-screen__carousel-container__subheader__item__image">
                            <img src={iconCommerce} alt="" />
                        </div>
                        <div className="home-screen__carousel-container__subheader__item__caption">
                            <h5>{t('home.benefits.localCommerce')}</h5>
                            <h6>{t('home.benefits.localCommerceText')}</h6>
                        </div>
                    </div>
                    <div className="home-screen__carousel-container__subheader__item">
                        <div className="home-screen__carousel-container__subheader__item__image">
                            <img src={iconEasyUse} alt="" />
                        </div>
                        <div className="home-screen__carousel-container__subheader__item__caption">
                            <h5>{t('home.benefits.easyToUse')}</h5>
                            <h6>{t('home.benefits.easyToUseText')}</h6>
                        </div>
                    </div>
                    <div className="home-screen__carousel-container__subheader__item">
                        <div className="home-screen__carousel-container__subheader__item__image">
                            <img src={iconPayOnline} alt="" />
                        </div>
                        <div className="home-screen__carousel-container__subheader__item__caption">
                            <h5>{t('home.benefits.payOnline')}</h5>
                            <h6>{t('home.benefits.payOnlineText')}</h6>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1220}>
                    <Carousel
                        indicators={false}
                        controls={false}
                    >
                        <CarouselItem>
                            <div className="home-screen__carousel-container__subheader__item">
                                <div className="home-screen__carousel-container__subheader__item__image">
                                    <img src={iconCommerce} alt="" />
                                </div>
                                <CarouselCaption className="home-screen__carousel-container__subheader__item__caption">
                                    <h5>{t('home.benefits.localCommerce')}</h5>
                                    <h6>{t('home.benefits.localCommerceText')}</h6>
                                </CarouselCaption>
                            </div>
                        </CarouselItem>
                        <CarouselItem>
                            <div className="home-screen__carousel-container__subheader__item">
                                <div className="home-screen__carousel-container__subheader__item__image">
                                    <img src={iconEasyUse} alt="" />
                                </div>
                                <CarouselCaption className="home-screen__carousel-container__subheader__item__caption">
                                    <h5>{t('home.benefits.easyToUse')}</h5>
                                    <h6>{t('home.benefits.easyToUseText')}</h6>
                                </CarouselCaption>
                            </div>
                        </CarouselItem>
                        <CarouselItem>
                            <div className="home-screen__carousel-container__subheader__item">
                                <div className="home-screen__carousel-container__subheader__item__image">
                                    <img src={iconPayOnline} alt="" />
                                </div>
                                <CarouselCaption className="home-screen__carousel-container__subheader__item__caption">
                                    <h5>{t('home.benefits.payOnline')}</h5>
                                    <h6>{t('home.benefits.payOnlineText')}</h6>
                                </CarouselCaption>
                            </div>
                        </CarouselItem>
                    </Carousel>
                </MediaQuery>
            </div>
        );
    }

    renderProductCarousel = (products: Array<Product>): JSX.Element => {
        return (
            <Slider
                autoplay
                arrows={false}
                dots
                slidesToShow={products.length < 4 ? products.length : 4}
                slidesToScroll={4}
                responsive={
                    [{
                        breakpoint: 1081,
                        settings: {
                            slidesToShow: products.length < 3 ? products.length : 3,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: products.length < 2 ? products.length : 2,
                            slidesToScroll: 2,
                        },
                    }]
                }
            >
                {Object.keys(products).map(k => {
                    const product = products[Number(k)];
                    const isProductInPromotion = checkProductWithinPromotion(product);

                    return (
                        <div key={product.id} className="home-screen__small-carousel__item-container--product">
                            <div
                                className="home-screen__small-carousel__item-container--product__item"
                                onClick={() => this.handleProductClick(product)}
                            >
                                <div
                                    className="home-screen__small-carousel__item-container--product__item__image-container"
                                >
                                    {isProductInPromotion && <DiscountBox product={product} />}
                                    <div
                                        role="img"
                                        aria-label={product.shortDescription}
                                        style={{ backgroundImage: `url(${resourcesURL(product.thumbnailHash)})` }}
                                    />
                                </div>
                                <div className="home-screen__small-carousel__item-container--product__item__caption-container">
                                    <div className="home-screen__small-carousel__item-container__item__caption-container__text">
                                        {product.storeInfo && (
                                            <p className="card-text">{String(product.storeInfo.name)}</p>
                                        )}
                                        <h4 className="card-title h5">{product.shortDescription}</h4>
                                    </div>
                                    <div className="home-screen__small-carousel__item-container--product__item__caption-container__prices">
                                        {isProductInPromotion
                                            ? (
                                                product.discountPrice && product.price && (
                                                    <>
                                                        <div className="home-screen__small-carousel__item-container--product__item__caption-container__prices--old-price">
                                                            {numberToCurrency(product.price)}
                                                        </div>
                                                        <div className="home-screen__small-carousel__item-container--product__item__caption-container__prices--price">
                                                            {numberToCurrency(product.discountPrice)}
                                                        </div>
                                                    </>
                                                ))
                                            : (
                                                <div className="home-screen__small-carousel__item-container--product__item__caption-container__prices--price">
                                                    {numberToCurrency(product.price || 0)}
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        );
    }

    renderSponsors = ():JSX.Element => {
        return (
            <div className="home-screen__sponsors" id="parceiros">
                <h3>Promotores e Parceiros</h3>
                <div className="home-screen__sponsors__image-container">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.cm-leiria.pt/">
                        <img
                            className="img-fluid d-block mx-auto"
                            src={cmlLogo}
                            alt=""
                        />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.startupleiria.com/">
                        <img
                            className="img-fluid d-block mx-auto"
                            src={suLeiriaLogo}
                            alt=""
                        />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://void.pt/">
                        <img
                            className="img-fluid d-block mx-auto"
                            src={voidLogo}
                            alt=""
                        />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://agency.rotauto.com/">
                        <img
                            className="img-fluid d-block mx-auto"
                            src={agencyLogo}
                            alt=""
                        />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.nerlei.pt/">
                        <img
                            className="img-fluid d-block mx-auto"
                            src={nerleiLogo}
                            alt=""
                        />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://acilis.pt/">
                        <img
                            className="img-fluid d-block mx-auto"
                            src={acilisLogo}
                            alt=""
                        />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.eupago.pt/">
                        <img
                            className="img-fluid d-block mx-auto"
                            src={euPagoLogo}
                            alt=""
                        />
                    </a>
                </div>
            </div>
        );
    }

    renderStoresCarousel = (stores: Array<Store>) => {
        return (
            <Slider
                autoplay
                arrows={false}
                dots
                slidesToShow={3}
                slidesToScroll={stores.length < 3 ? 0 : 3}
                infinite={stores.length > 3}
                responsive={
                [{
                    breakpoint: 1081,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: stores.length > 1,
                    },
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                }]
            }
            >
                {Object.keys(stores).map(k => {
                    const store = stores[Number(k)];

                    return (
                        <div
                            key={store.id}
                            className="home-screen__small-carousel__item-container"
                        >
                            <div
                                className="home-screen__small-carousel__item-container__item"
                                onClick={() => this.handleStoreClick(store)}
                            >
                                <img
                                    src={resourcesURL(store.thumbnailHash)}
                                    alt={store.name}
                                />
                                <div className="home-screen__small-carousel__item-container__item__caption-container">
                                    <div className="home-screen__small-carousel__item-container__item__caption-container__text">
                                        {store.category && (
                                        <p className="card-text">{String(store.category.description)}</p>
                                        )}
                                        <h4 className="card-title h5">{store.name}</h4>
                                    </div>
                                    <img src={carouselArrow} alt="visit store" />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        );
    }

    render() {
        const { t } = this.props;
        const {
            isFetching,
            products,
            stores,
            highlightedStores,
            productsLastSeen,
        } = this.state;
        this.sendGTMImpressions();

        return (
            <div id="pagina_inicial" className="home-screen">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="home-screen__carousel-container">
                    {this.renderHeader()}
                    {this.renderSubHeader()}
                </div>

                <div className="home-screen__tagline">
                    <h3>{t('home.benefits.supportLocalCommerce')}</h3>
                    <p className="home-screen__tagline__p-regular">
                        {t('home.benefits.supportLocalCommerceText')}
                    </p>
                </div>

                {/* HIGHLIGHTED STORES */}
                {(highlightedStores && highlightedStores.length > 0) && (
                    <>
                        <div className="home-screen__tagline">
                            <h3>{t('home.storesHighlighted')}</h3>
                            <div className="home-screen__small-carousel" id="lojas">
                                {this.renderStoresCarousel(highlightedStores)}
                            </div>
                        </div>
                    </>
                )}

                {/* PRODUCTS CAROUSEL */}
                {(products && products.length > 0) && (
                    <>
                        <div className="home-screen__tagline">
                            <h3>{t('home.productsHighlighted')}</h3>
                            <div className="home-screen__small-carousel--product" id="produtos">
                                {this.renderProductCarousel(products)}
                            </div>
                        </div>
                    </>
                )}

                {/* STORES CAROUSEL */}
                {(stores && stores.length > 0) && (
                    <>
                        <div className="home-screen__tagline">
                            <h3>{t('home.storesAvailable')}</h3>
                            <div className="home-screen__small-carousel" id="lojas">
                                {this.renderStoresCarousel(stores)}
                            </div>
                        </div>
                        <div className="home-screen__tagline home-screen__tagline--button">
                            <Link
                                className="btn btn-light-blue"
                                to={ALL_STORES_ROUTE}
                            >Ver todas
                            </Link>
                        </div>
                    </>
                )}

                <div className="home-screen__tagline" id="categorias">
                    <h3>{t('home.featuredCategories')}</h3>
                    <HomeCategoriesSelector />
                </div>

                {/* PRODUCTS LAST SEEN CAROUSEL */}
                {(productsLastSeen && productsLastSeen.length > 0) && (
                    <>
                        <div className="home-screen__tagline">
                            <h3>{t('home.lastSeenProducts')}</h3>
                            <div className="home-screen__small-carousel--product" id="produtos-vistos-recentemente">
                                {this.renderProductCarousel(productsLastSeen)}
                            </div>
                        </div>
                    </>
                )}

                <div className="home-screen__tagline" id="benefícios">
                    <h3>{t('home.commerce.haveStoreInLeiria')}</h3>
                    <p className="home-screen__tagline__p-regular">
                        {t('home.commerce.text')}
                    </p>
                    <p className="home-screen__tagline__p-bold">{t('home.commerce.textLast')}</p>
                </div>

                {/* COMMERCE */}
                <MediaQuery minWidth={900}>
                    <div className="join-us-screen__features--desktop">
                        <div className="join-us-screen__features--desktop__item">
                            <div className="join-us-screen__features--desktop__item__icon-container">
                                <img src={iconGratis} alt="" />
                            </div>
                            <h2>{t('joinUs.features.free.title')}</h2>
                            <h3>{t('joinUs.features.free.body')}</h3>
                        </div>
                        <div className="join-us-screen__features--desktop__item">
                            <div className="join-us-screen__features--desktop__item__icon-container">
                                <img src={iconOnline} alt="" />
                            </div>
                            <h2>{t('joinUs.features.online.title')}</h2>
                            <h3>{t('joinUs.features.online.body')}</h3>
                        </div>
                        <div className="join-us-screen__features--desktop__item">
                            <div className="join-us-screen__features--desktop__item__icon-container">
                                <img src={iconParcerias} alt="" />
                            </div>
                            <h2>{t('joinUs.features.partners.title')}</h2>
                            <h3>{t('joinUs.features.partners.body')}</h3>
                        </div>
                    </div>
                    <div className="home-screen__tagline">
                        <Link
                            className="btn btn-light-blue"
                            to={JOIN_US_ROUTE}
                        >Aderir
                        </Link>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={900}>
                    <div className="join-us-screen__features--mobile">
                        <div className="join-us-screen__features--mobile__item">
                            <div className="join-us-screen__features--mobile__item__icon-container">
                                <img src={iconGratis} alt="" />
                            </div>
                            <div className="join-us-screen__features--mobile__item__text-container">
                                <h2>{t('joinUs.features.free.title')}</h2>
                                <h3>{t('joinUs.features.free.body')}</h3>
                            </div>
                        </div>
                        <div className="join-us-screen__features--mobile__item">
                            <div className="join-us-screen__features--mobile__item__icon-container">
                                <img src={iconOnline} alt="" />
                            </div>
                            <div className="join-us-screen__features--mobile__item__text-container">
                                <h2>{t('joinUs.features.online.title')}</h2>
                                <h3>{t('joinUs.features.online.body')}</h3>
                            </div>
                        </div>
                        <div className="join-us-screen__features--mobile__item">
                            <div className="join-us-screen__features--mobile__item__icon-container">
                                <img src={iconParcerias} alt="" />
                            </div>
                            <div className="join-us-screen__features--mobile__item__text-container">
                                <h2>{t('joinUs.features.partners.title')}</h2>
                                <h3>{t('joinUs.features.partners.body')}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="home-screen__tagline">
                        <Link
                            className="btn btn-light-blue"
                            to={JOIN_US_ROUTE}
                        >Adira já!
                        </Link>
                    </div>
                </MediaQuery>

                {this.renderSponsors()}
            </div>
        );
    }
}

export default withGeneralContext(withTranslationContext(HomeScreen));

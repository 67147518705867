/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const campaignsAdminURL = (params: object = {}) => {
    const queryString = objectToParams(params);
    return `${API_URL}/banners/${queryString}`;
};

export const singleBannerAdminURL = (id: string | number) => {
    return `${API_URL}/banners/${id}`;
};

export const campaignBannerOrderURL = () => {
    return `${API_URL}/banners/order`;
};

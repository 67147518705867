/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { GeneralState } from './types';
import { GeneralAction } from '../actions/general_types';

/**
 * @typedef {GeneralState} generalInitialState
 */
export const generalInitialState: GeneralState = {
    lastSeenProducts: [],
};

/**
 * general reducer
 * @param {GeneralState} state
 * @param {any} action
 * @returns {GeneralState}
 */
export default function (state = generalInitialState, action: any): GeneralState {
    switch (action.type) {
        case GeneralAction.SET_LAST_SEEN_PRODUCTS:
            return {
                ...state,
                lastSeenProducts: [...action.payload],
            };
        default:
            return state;
    }
}

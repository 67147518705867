/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */
import axios from 'axios';
import React, { Component } from 'react';
import {
    Col, Modal, OverlayTrigger, Row, Table, Tooltip,
} from 'react-bootstrap';
import { get, throttle } from 'lodash';
import moment from 'moment';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import Loader from '../../elements/Loader';
import Button from '../../elements/Button';
import { downloadXLS } from '../../../utils/misc';
import { customersXLSURL } from '../../../services/admin';
import { userDeleteURL, usersURL } from '../../../services/users';
import { Customer } from '../../../constants/types';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import FormTextField from '../../elements/FormTextField';
import iconInfo from '../../../assets/images/ic-info.svg';
import FormCheckbox from '../../elements/FormCheckbox';
import displayConfirm from '../../elements/displayConfirm';
import { globalButtonLabels } from '../../../constants/misc';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import TablePaging from '../../elements/TablePaging';
import withPaging, { WithPagingProps } from '../../hocs/withPaging';
import { AuthenticationContext, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { UserRoles } from '../../../constants/authorization';
import SortArrow from '../../elements/SortArrow';

interface OwnProps extends TranslationContext, AuthenticationContext, WithPagingProps { }

interface OwnState {
    isFetching: boolean;
    showUserModal: boolean;
    users: Customer[];
    selectedUser: Customer;
    formErrors: any;
    _limit : number;
    totalResults: number;
    searchString: string;
    sortField: string;
    sortOrder: string;

}

const initialState: OwnState = {
    isFetching: false,
    users: [],
    showUserModal: false,
    selectedUser: {
        id: 1,
        name: '',
        email: '',
        contact: '',
        address: '',
        postalCode: '',
        newsletter: false,
        createdDate: 0,
        lastAccess: '',
    },
    formErrors: null,
    _limit: 25,
    totalResults: 0,
    searchString: '',
    sortField: 'NAME',
    sortOrder: 'ASC',
};

class Customers extends Component<OwnProps, OwnState> {
    state = {
        ...initialState,
    };

    private readonly throttledGetCustomers = () => { };

    constructor(props: OwnProps) {
        super(props);
        this.throttledGetCustomers = throttle(this.getCustomers, 900);
    }

    componentDidMount(): void {
        this.prepare();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const { currentPage } = this.props;
        const { currentPage: oldCurrentPage } = prevProps;

        const hasPagingChanged: boolean = currentPage !== oldCurrentPage;

        if (hasPagingChanged) {
            this.getCustomers();
        }
    }

    onCloseUserModal = () => {
        this.setState({
            showUserModal: false,
            formErrors: null,
            selectedUser: {
                ...initialState.selectedUser,
            },
        });
    };

    onUserClick = (user: Customer) => {
        this.setState({
            showUserModal: true,
            selectedUser: user,
        });
    };

    onDeleteUser = (e: React.MouseEvent, user: Customer) => {
        e.stopPropagation();

        const { t } = this.props;

        displayConfirm({
            acceptButtonText: t(globalButtonLabels.ok),
            onAccept: () => this.deleteUser(user.id),
            onReject: () => {},
            rejectButtonText: t(globalButtonLabels.cancel),
            message: t('admin.users.removeClientConfirmation', { name: user.name }),
        });
    };

    onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { onPagingChange } = this.props;
        const { value } = e.currentTarget;

        onPagingChange(1);
        this.setState({
            searchString: value,
        }, () => {
            this.throttledGetCustomers();
        });
    }

    onSortChange = (sortField: string, sortOrder : string) => {
        this.setState({
            sortField,
            sortOrder,
        }, this.prepare);
    }

    checkNewsletter = ():void => {
        const prevState = this.state;
        const { selectedUser: user } = this.state;
        this.setState({
            ...prevState,
            selectedUser: {
                ...user,
                newsletter: !user.newsletter,
            },
        });
    }

    deleteUser = (id: number) => {
        const { t } = this.props;
        this.setState({ isFetching: true });

        axios.delete(userDeleteURL(id))
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.users.removeClientSuccess'));

                this.setState({ isFetching: false }, () => this.prepare());
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.users.removeClientError'));

                this.setState({ isFetching: false });
            });
    };

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    getCustomers = async () => {
        const { currentPage, onPagingChange, user } = this.props;
        const {
            isFetching, _limit, searchString, sortOrder, sortField,
        } = this.state;

        if (isFetching && !user) return;

        this.setState({ isFetching: true });
        const url = usersURL({
            pageSize: _limit,
            page: currentPage - 1,
            q: searchString === '' ? null : searchString,
            sort: sortField,
            order: sortOrder,
            role: UserRoles.CUSTOMER,
        });

        try {
            const { data, headers } = await axios.get(url);

            const newTotalResults: number = parseInt(headers['x-total-count']);

            if (data && data.length === 0 && currentPage !== 1) {
                this.setState({ isFetching: false }, () => {
                    onPagingChange(currentPage - 1);
                    this.getCustomers();
                });
            } else {
                this.setState({
                    isFetching: false,
                    users: data,
                    totalResults: newTotalResults,
                });
            }
        } catch (e) {
            this.setState({ isFetching: false });
        }
    }

    prepare() {
        const { isFetching } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        this.getCustomers();
    }

    renderTooltip = (text: string) => {
        return (
            <OverlayTrigger
                trigger="hover"
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={props => {
                    return (
                        <Tooltip
                            className="highlight-tooltip"
                            id="highlight-tooltip"
                            {...props}
                        >
                            {text}
                        </Tooltip>
                    );
                }}
            >
                <img className="mb-1" src={iconInfo} alt="highlight info" />
            </OverlayTrigger>
        );
    }

    render() {
        const {
            isFetching, users, selectedUser, showUserModal, formErrors, _limit, totalResults, sortOrder, sortField, searchString,
        } = this.state;
        const {
            t, currentPage, onPagingChange,
        } = this.props;
        const hasData = users && users.length > 0;

        return (
            <div className="app-tabs__tab-content">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="d-flex flex-row justify-content-between">
                    <FormTextField
                        placeholder="Pesquisar Clientes"
                        name="query"
                        value={searchString}
                        label="Pesquisar"
                        onChange={this.onFilterChange}
                    />
                    <div className="buttons-container__buttons">
                        <div className="button-wrapper">
                            <Button
                                styles="button--dark-blue"
                                text={t('admin.stores.export')}
                                callback={() => downloadXLS(customersXLSURL())}
                            />
                        </div>
                    </div>
                </div>
                <Table responsive="sm">
                    <thead>
                        <tr>
                            <th>
                                {t('admin.users.headers.name')}
                                <SortArrow
                                    active={sortField === 'NAME'}
                                    ascending={sortOrder === 'ASC'}
                                    callback={() => { this.onSortChange('NAME', sortField === 'NAME' && sortOrder === 'ASC' ? 'DESC' : 'ASC'); }}
                                />
                            </th>
                            <th>{t('admin.users.headers.email')}</th>
                            <th>{t('admin.users.headers.phone')}</th>
                            <th>
                                {t('admin.users.headers.city')}
                                <SortArrow
                                    active={sortField === 'CITY'}
                                    ascending={sortOrder === 'ASC'}
                                    callback={() => { this.onSortChange('CITY', sortField === 'CITY' && sortOrder === 'ASC' ? 'DESC' : 'ASC'); }}
                                />
                            </th>
                            <th />
                        </tr>
                    </thead>
                    {hasData ? (
                        <tbody>
                            {Object.keys(users).map(k => {
                                const user = users[Number(k)];
                                return (
                                    <tr key={user.id} className="clickable" onClick={() => this.onUserClick(user)}>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.contact}</td>
                                        <td>{user?.city ? user.city : '-'}</td>
                                        <td className="d-flex w-100 justify-content-end trash-icon-cell">
                                            <SvgIcon icon={ICON.TRASH} callback={(e: React.MouseEvent) => this.onDeleteUser(e, user)} />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan={4}>
                                    {t('global.noData')}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
                {hasData && (
                    <TablePaging
                        currentPage={currentPage}
                        limit={_limit}
                        totalResults={totalResults}
                        onStartChange={onPagingChange}
                    />
                )}
                <Modal show={showUserModal} onHide={this.onCloseUserModal} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{ t('admin.users.editUser') }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body modal-custom">
                            <Row>
                                <Col xs={12} md={12}>
                                    <FormTextField
                                        label={t('storeAdmin.profile.labels.name')}
                                        placeholder={t('storeAdmin.profile.labels.name')}
                                        name="name"
                                        value={selectedUser.name}
                                        maxLength={80}
                                        errors={get(formErrors, 'fields.name', null)}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={4}>
                                    <FormTextField
                                        label={t('storeAdmin.profile.labels.address')}
                                        placeholder={t('storeAdmin.profile.labels.address')}
                                        name="address"
                                        value={selectedUser.address}
                                        maxLength={100}
                                        errors={get(formErrors, 'fields.address', null)}
                                        disabled
                                    />
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormTextField
                                        label={t('storeAdmin.profile.labels.postalCode')}
                                        placeholder={t('storeAdmin.profile.labels.postalCode')}
                                        name="postalCode"
                                        value={selectedUser.postalCode}
                                        maxLength={20}
                                        errors={get(formErrors, 'fields.postalCode', null)}
                                        disabled
                                    />
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormTextField
                                        label={t('storeAdmin.profile.labels.city')}
                                        placeholder={t('storeAdmin.profile.labels.city')}
                                        name="city"
                                        value={selectedUser?.city ? selectedUser.city : ''}
                                        maxLength={40}
                                        errors={get(formErrors, 'fields.city', null)}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <FormTextField
                                        label={t('storeAdmin.profile.labels.contact')}
                                        placeholder={t('storeAdmin.profile.labels.contact')}
                                        name="contact"
                                        value={selectedUser.contact}
                                        maxLength={25}
                                        errors={get(formErrors, 'fields.contact', null)}
                                        disabled
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormTextField
                                        label={t('storeAdmin.profile.labels.email')}
                                        placeholder={t('storeAdmin.profile.labels.email')}
                                        name="email"
                                        value={selectedUser.email}
                                        maxLength={70}
                                        errors={get(formErrors, 'fields.email', null)}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <FormTextField
                                        name="createdAt"
                                        label={t('storeAdmin.profile.labels.createdAt')}
                                        placeholder={t('storeAdmin.profile.labels.createdAt')}
                                        value={moment(selectedUser.createdDate * 1000).format('DD/MM/YY HH:MM')}
                                        maxLength={80}
                                        errors={get(formErrors, 'fields.name', null)}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <FormTextField
                                        label={t('storeAdmin.profile.labels.lastLogin')}
                                        placeholder={t('storeAdmin.profile.labels.lastLogin')}
                                        name="lastLogin"
                                        value={moment(selectedUser.lastAccess).format('DD/MM/YY HH:MM')}
                                        maxLength={80}
                                        errors={get(formErrors, 'fields.name', null)}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={10}>
                                    <FormCheckbox
                                        name="newsletter"
                                        label={t('customerRegister.optInEmails')}
                                        value={selectedUser.newsletter}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default withPaging(withAuthenticationContext(withTranslationContext(Customers)));

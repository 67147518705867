/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { rafflesXLSURL, salesXLSURL } from '../../../services/admin';
import Button from '../../elements/Button';
import { downloadXLS } from '../../../utils/misc';

type OwnProps = TranslationContext

interface OwnState {
    isFetching: boolean;
}

const initialState: OwnState = {
    isFetching: false,
};

class ProductsCSV extends Component<OwnProps, OwnState> {
    state = {
        ...initialState,
    };

    render() {
        const { t } = this.props;

        return (
            <div className="app-tabs__tab-content">
                <div className="d-flex flex-row justify-content-end">
                    <div className="align-self-end">
                        <Button
                            styles="button--dark-blue button--small mr-2"
                            text={t('admin.stores.exportValidatedRaffles')}
                            callback={() => {
                                downloadXLS(rafflesXLSURL());
                            }}
                        />
                        <Button
                            styles="button--dark-blue button--small"
                            text={t('admin.stores.export')}
                            callback={() => downloadXLS(salesXLSURL())}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(ProductsCSV);

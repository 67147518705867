/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { FunctionComponent } from 'react';

const buyAndSell = () => {
    return (
        <div className="text-container">
            <strong>A. Compra e venda</strong>
            <p className="text-container__index">
                1) Os Produtos são apresentados no Website com um descritivo que permite ao Comprador conhecer as respetivas características essenciais e o seu preço;
            </p>
            <p className="text-container__index">
                2) O Comprador seleciona o(s) Produto(s) que pretende comprar;
            </p>
            <p className="text-container__index">
                3) O Comprador confirma a sua escolha de Produto(s) e toma conhecimento e aceita as presentes CGV por um clique de validação;
            </p>
            <p className="text-container__index">
                4) O Comprador recebe uma mensagem de e-mail a confirmar a aceitação da sua encomenda. No entanto, o contrato de compra e venda celebrado
                entre o Comprador e o Vendedor fica sujeito à condição resolutiva da disponibilidade do Produto;
            </p>
            <p className="text-container__index">
                5) O Vendedor é informado pela STARTUPLRA que um ou mais Produtos que colocou em linha foi objeto de uma encomenda;
            </p>
            <p className="text-container__index">
                6) O Vendedor compromete-se a confirmar e/ou a informar a disponibilidade do(s) Produto(s) encomendado(s) pelo Comprador no prazo
                máximo de 24 horas (exceto sábados, domingos e feriados nacionais) a contar da receção da informação no número anterior.
            </p>
            <p className="text-container__index">
                7) Caso o mesmo Produto seja objeto de uma encomenda por vários Compradores ao mesmo tempo, e em função da disponibilidade
                deste Produto, este será vendido ao primeiro Comprador que registe e pague a sua encomenda. A encomenda submetida
                pelos restantes Compradores será anulada;
            </p>
            <p className="text-container__index">
                8) Após a encomenda ser validada ou informada pelo Vendedor, é enviada uma comunicação ao Comprador (mensagem de correio eletrónico
                ou outra) para o informar do envio ou cancelamento da encomenda;
            </p>
            <p className="text-container__index">
                9) Em caso de confirmação total ou parcial da encomenda pelo Vendedor, a condição resolutiva que integra o contrato de compra e
                venda celebrado entre o Comprador e o Vendedor não se verifica, assumindo deste modo o Vendedor o compromisso definitivo de
                enviar a encomenda no prazo previsto. Nesse momento, o valor da encomenda será debitado, excetuando-se os casos em que o
                pagamento foi realizado previamente (nomeadamente através de referência Multibanco, MB Way ou outro).
            </p>
            <p className="text-container__index">
                10) A ausência de confirmação da disponibilidade do(s) Produto(s) no prazo previsto no ponto 6) supra, constitui um
                incumprimento ao presente acordo, sendo, em casos reiterados, suscetível de motivar a sua resolução
            </p>
            <p className="text-container__index">
                11) Em caso de confirmação da disponibilidade de todos ou de parte dos Produtos encomendados pelo Comprador, os referidos
                Produtos são expedidos pelo Vendedor.
            </p>
            <p className="text-container__index">
                12) O Comprador deve confirmar:
                <p className="text-container__index">
                    a. Recepção do(s) produto(s),
                </p>
                <p className="text-container__index">
                    b. Conformidade do(s) mesmo(s) (conformidade de produto entende-se que corresponde ao que foi encomendado)
                </p>
                <p className="text-container__index">
                    c. Estado de produto (que corresponde ao descritivo no Website no momento da aquisição) na sua área de cliente.
                    Na falta de confirmação, o Produto presume-se recebido conforme e em bom estado no prazo de 21 (vinte e um)
                    dias a contar da data da respetiva expedição. Esta Cláusula não prejudica as disposições constantes do
                    Decreto-Lei n.º 67/2003, de 08.04 com as alterações que lhe foram introduzidas pelo Decreto-Lei n.º 84/2008, de 21.05.
                </p>
            </p>
        </div>
    );
};

const princeAndPayment = () => {
    return (
        <div className="text-container">
            <strong>B. Preço e Pagamento</strong>
            <p className="text-container__index">
                1) O preço de compra do Produto é determinado pelo Vendedor. O valor é indicado em euros com todos os impostos e taxas incluídos
                na ficha descritiva, mas com exclusão das despesas de entrega e taxa de transação, sendo estes acrescentados antes da
                validação da encomenda.
            </p>
            <p className="text-container__index">
                2) O pagamento das compras efetuadas através do Serviço realiza-se à plataforma de pagamentos online contratada para o
                efeito pela ACILIS, cujos termos e condições que se aplicam ao presente acordo, e que estarão disponíveis em
                <a href="https://leiriamarket.pt"> www.leiriamarket.pt</a>,  e aos quais serão comunicados ao Vendedor e este deve aderir.
            </p>
            <p className="text-container__index">
                3) Para o efeito previsto no número anterior, o Vendedor compromete-se a fornecer todas as informações e tomar
                todas as diligências necessárias à implementação e à sua integração na plataforma de pagamentos online contratada pela ACILIS.
            </p>
            <p className="text-container__index">
                4) Em caso de alteração da plataforma de pagamentos online, a ACILIS notificará o Vendedor, com uma antecedência
                de 5 dias úteis em relação à entrada em funcionamento, dos novos Termos & Condições aplicáveis.
            </p>
            <p className="text-container__index">
                5) A fatura da compra dos Produtos é emitida pelo VENDEDOR, sendo a componente de taxas de transação e
                custos de envio faturada pela ACILIS.
            </p>
        </div>
    );
};

const modalitiesAndDeliveryCost = () => {
    return (
        <div className="text-container">
            <strong>C. Modalidades e despesas de envio</strong>
            <p className="text-container__index">
                1) A ACILIS colocará à disposição do Comprador diversas modalidades de entrega, a definir em função dos Produtos.
            </p>
            <p className="text-container__index">
                2) A modalidade de envio será escolhidas pelo Comprador no momento da compra.
            </p>
            <p className="text-container__index">
                3) Os encargos decorrentes do envio dos Produtos ao Comprador são da responsabilidade deste, no entanto em caso
                de envios que excedam o valor pago pelo COMPRADOR (peso acima do indicado em backoffice), o acerto será cobrado pela ACILIS ao VENDEDOR.
            </p>
            <p className="text-container__index">
                4) As Condições Gerais da Prestação de Serviços de Distribuição contratados pela ACILIS para efeitos de envio
                encontrar-se-ão a todo o tempo disponíveis no site <a href="https://leiriamarket.pt"> www.leiriamarket.pt</a>.
            </p>
        </div>
    );
};

const freeResolution = () => {
    return (
        <div className="text-container">
            <strong>D. Direito de livre resolução</strong>
            <p className="text-container__index">
                1) Nos termos da legislação em vigor, o Comprador tem um prazo de 14 (catorze) dias a contar da receção
                do(s) Produto(s) encomendado(s) para exercer, junto do referido Vendedor, o seu direito de livre
                resolução sem pagamento de indemnização e sem necessidade de indicar o motivo.
            </p>
            <p className="text-container__index">
                2) Em caso de exercício do direito de livre resolução no prazo supra referido, são reembolsados
                somente o preço do(s) Produto(s) adquirido(s) e as despesas de expedição. As despesas de devolução
                ficam a cargo do Comprador.
            </p>
            <p className="text-container__index">
                3) O(s) Produto(s) deve(m) ser devolvido(s) no estado original e completo(s) (embalagem, acessórios,
                manual de instruções, etc.) acondicionado(s) da mesma forma que na expedição, no prazo de 30 (trinta)
                dias a contar da sua receção.
            </p>
            <p className="text-container__index">
                4) Nos termos legais aplicáveis, o direito de livre resolução não pode ser exercido relativamente
                a gravações áudio e vídeo, de discos e de programas informáticos a que o Comprador tenha retirado
                o selo de garantia e de inviolabilidade.
            </p>
            <p className="text-container__index">
                5) O Comprador exerce o seu direito de livre resolução diretamente junto do Vendedor em questão,
                por intermédio da ferramenta de mensagens colocada à sua disposição no âmbito do Serviço da
                plataforma <a href="https://leiriamarket.pt"> www.leiriamarket.pt</a>.
            </p>
            <p className="text-container__index">
                6) O reembolso dos produtos devolvidos é efetuado pelo Vendedor ao Comprador no prazo
                mais curto possível e dentro do prazo de 14 (catorze) dias a contar da data do
                exercício do direito de livre resolução.
            </p>
        </div>
    );
};

const sellerObligations = () => {
    return (
        <div className="text-container">
            <strong>E. Obrigações do vendedor</strong>
            <p className="text-container__index">
                1) O Vendedor compromete-se a cumprir a legislação a que está sujeito na sua qualidade de profissional,
                relativamente aos Produtos que vende no website, incluindo a legislação relativa à garantia dos
                bens de consumo.
            </p>
            <p className="text-container__index">
                2) O Vendedor compromete-se a envidar todos os esforços para cumprir da melhor forma as suas
                obrigações prestando um serviço de qualidade aos Compradores. Em particular, o Vendedor
                compromete-se a responder às mensagens de e-mail do Serviço de Apoio ao Cliente da
                Plataforma <a href="https://leiriamarket.pt"> www.leiriamarket.pt</a>, bem como às mensagens de e-mail dos Compradores
                no prazo máximo de 24 horas a contar da respetiva receção.
            </p>
            <p className="text-container__index">
                3) O Vendedor compromete-se e garante que só venderá Produtos dos quais é proprietário ou sobre os
                quais dispõe de direitos que lhe permitam vendê-los.
            </p>
            <p className="text-container__index">
                4) O Vendedor garante que os Produtos cumprem a legislação em vigor e as normas aplicáveis,
                imperativas ou não e que não prejudicam direitos de terceiros, incluindo de propriedade intelectual,
                industrial e/ou direitos de autor.
            </p>
            <p className="text-container__index">
                5) O Vendedor obriga-se a prestar à ACILIS, com exatidão, as informações e ou elementos
                relativos aos Produtos que pretende comercializar, nomeadamente, características, estado,
                menções especiais definidas por lei e preço, de modo a que estas não possam ser
                suscetíveis de induzir em erro os potenciais Compradores.
            </p>
            <p className="text-container__index">
                6) Os Produtos comercializados no website devem estar abrangidos por uma garantia de conformidade,
                assegurada pelo Vendedor, nos termos da legislação aplicável à categoria de bens em que se insiram.
            </p>
        </div>
    );
};

const consumeCompenteces = () => {
    return (
        <div className="text-container">
            <strong>F. Competência para dirimir litígios de consumo</strong>
            <p className="text-container__index">
                1) Em caso de litígio de consumo, definido nos termos do disposto na Lei n.º 144/2015,
                de 8 de setembro, o Comprador pode recorrer à entidade de resolução alternativa de
                litígios de consumo competente.
            </p>
            <p className="text-container__index">
                2) Sem prejuízo do disposto na legislação, nos estatutos e nos regulamentos a que as
                entidades de resolução alternativa de litígios de consumo se encontram vinculadas,
                o consumidor pode optar pela plataforma europeia de resolução de litígios em
                linha disponível em <a href="https://webgate.ec.europa.eu/odr"> https://webgate.ec.europa.eu/odr</a>, pela entidade de resolução
                alternativa de litígios de consumo do local do seu domicílio ou pela entidade
                de resolução alternativa de competência especializada, caso exista para o
                setor em questão. Poderá consultar a lista atualizada de todas as entidades de
                resolução alternativa de consumo disponíveis em <a href="https://www.consumidor.gov.pt"> www.consumidor.gov.pt</a> na lista das
                Entidades de Resolução Alternativa de Litígios (Centros de Arbitragem de
                Conflitos de Consumo), e dos Centros de Informação Autárquicos ao Consumidor.
            </p>
        </div>
    );
};

const SaleConditions: FunctionComponent = () => {
    return (
        <div className="terms-screen__content">
            <h4>Condições Gerais de Venda (CGV)</h4>
            <hr className="divider my-4" />
            <div className="text-container">
                <p>
                    As transações de produtos realizadas por intermédio do Leiria Market estão sujeitas à legislação portuguesa e regulam-se pelas seguintes condições:
                </p>
            </div>
            { buyAndSell() }
            { princeAndPayment() }
            { modalitiesAndDeliveryCost() }
            { freeResolution() }
            { sellerObligations() }
            { consumeCompenteces() }
        </div>
    );
};

export default SaleConditions;

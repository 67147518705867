/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { AuthenticationContextProvider } from './AuthenticationContext';

import { AppState } from '../../../reducers/types';
import { AuthenticationUser, LoginFormFields } from '../../../constants/authentication';
import {
    activateAccount,
    requestLogin,
    requestLogout,
    setAuthenticationActionCreator,
} from '../../../actions/authentication';

/**
 * @typedef {Object} StateProps
 * @property {boolean} isAuthenticated
 * @property {boolean} isFetching
 * @property {string | null} token
 */
interface StateProps {
    isAuthenticated: boolean;
    isFetching: boolean;
    token: string | null;
    user: AuthenticationUser | null;
    formErrors: object | null;
}

/**
 * @typedef {Object} DispatchProps
 * @property {Function} requestLogin
 */
interface DispatchProps {
    requestLogin: Function;
    requestLogout: Function;
    setAuthentication: Function;
    activateAccount: Function;
}

/**
 * @typedef {Object} OwnProps
 * @property {any} children
 */
interface OwnProps {
    children: any;
}

/**
 * @typedef {Object} Props
 */
type Props = StateProps & DispatchProps & OwnProps;

/**
 * @typedef {Object} OwnState
 */
interface OwnState {}

/**
 * @typedef {Object} State
 */
type State = OwnState;

/**
 * provides store properties and actions access to its consumers
 * @extends {Component<Props, State>}
 */
export class AuthenticationController extends Component<Props, State> {
    /**
     * calls the login action creator
     * @param {LoginFormFields} fields
     * @param {Function} callback
     */
    login = (fields: LoginFormFields, callback: Function) => {
        const {
            requestLogin,
        } = this.props;
        requestLogin(fields, callback);
    };

    logout = () => {
        const { requestLogout } = this.props;
        requestLogout();
    };

    setAuthentication = (auth: object) => {
        const { setAuthentication } = this.props;
        setAuthentication(auth);
    }

    activateAccount = (token: string, cartId: string, onSuccess: Function, onFailure: Function) => {
        const { activateAccount } = this.props;
        activateAccount(token, cartId, onSuccess, onFailure);
    }

    render() {
        const {
            children,
            isAuthenticated,
            isFetching,
            token,
            user,
            formErrors,
        } = this.props;

        return (
            <AuthenticationContextProvider
                value={{
                    isAuthenticated,
                    isFetching,
                    login: this.login,
                    logout: this.logout,
                    token,
                    user,
                    formErrors,
                    setAuthentication: this.setAuthentication,
                    activateAccount: this.activateAccount,
                }}
            >
                {children}
            </AuthenticationContextProvider>
        );
    }
}

/**
 * mapStateToProps
 * @param {AppState} state
 * @returns {StateProps}
 */
const mapStateToProps = (state: AppState): StateProps => {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        isFetching: state.authentication.isFetching,
        token: state.authentication.token,
        user: state.authentication.user,
        formErrors: state.authentication.formErrors,
    };
};

/**
 * mapDispatchToProps
 * @param {Dispatch} dispatch
 * @returns {DispatchProps}
 */
export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    requestLogin: (formData: LoginFormFields, onSuccess: Function) => dispatch(requestLogin(formData, onSuccess)),
    requestLogout: () => dispatch(requestLogout()),
    setAuthentication: (auth: object) => dispatch(setAuthenticationActionCreator(auth)),
    activateAccount: (token: string, cartId: string, onSuccess: Function, onFailure: Function) => dispatch(activateAccount(token, cartId, onSuccess, onFailure)),
});

export const ConnectedAuthenticationController = connect(mapStateToProps, mapDispatchToProps)(AuthenticationController);

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

/**
 * ScrollMemoryAction
 * @enum {string}
 */
import { ScrollMemory } from '../constants/scroll';

export enum ScrollMemoryAction {
    SET_SCROLL_MEMORY_PRODUCTS = 'SET_SCROLL_MEMORY_PRODUCTS',
    CLEAR_SCROLL_MEMORY_PRODUCTS = 'CLEAR_SCROLL_MEMORY_PRODUCTS',
    SET_SCROLL_MEMORY_STORES = 'SET_SCROLL_MEMORY_STORES',
    CLEAR_SCROLL_MEMORY_STORES = 'CLEAR_SCROLL_MEMORY_STORES'
}

/**
 * @typedef {Object} SetScrollMemoryActionProducts
 * @property {string} type
 * @property {object} payload
 */
export interface SetScrollMemoryActionProducts {
    type: typeof ScrollMemoryAction.SET_SCROLL_MEMORY_PRODUCTS,
    payload: ScrollMemory,
}

/**
 * @typedef {Object} ClearScrollMemoryActionProducts
 * @property {string} type
 */
export interface ClearScrollMemoryActionProducts {
    type: typeof ScrollMemoryAction.CLEAR_SCROLL_MEMORY_PRODUCTS,
}

/**
 * @typedef {Object} SetScrollMemoryActionStores
 * @property {string} type
 * @property {object} payload
 */
export interface SetScrollMemoryActionStores {
    type: typeof ScrollMemoryAction.SET_SCROLL_MEMORY_STORES,
    payload: ScrollMemory,
}

/**
 * @typedef {Object} ClearScrollMemoryActionStores
 * @property {string} type
 */
export interface ClearScrollMemoryActionStores {
    type: typeof ScrollMemoryAction.CLEAR_SCROLL_MEMORY_STORES,
}

export type ScrollMemoryTypes = SetScrollMemoryActionProducts | SetScrollMemoryActionStores | ClearScrollMemoryActionProducts | ClearScrollMemoryActionStores;

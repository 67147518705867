/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const vouchersAdminURL = (): string => {
    return `${API_URL}/vouchers`;
};

export const hasVoucherURL = (email: string, vat: string): string => {
    const params = objectToParams({ email, vat });
    return `${API_URL}/vouchers/hasvoucher${params}`;
};

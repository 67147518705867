/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import {
    Route,
    Redirect,
    RouteProps,
} from 'react-router-dom';

import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../controllers/authentication/AuthenticationContext';
import { LOGIN_ROUTE, NOT_FOUND_ROUTE } from '../../constants/routes';
import {
    AuthorizationContext,
    withAuthorizationContext,
} from '../controllers/authorization/AuthorizationContext';
import { checkPermission } from '../../utils/authorization';

/**
 * @typedef {Object} OwnProps
 * @extends {RouteProps, AuthenticationContext}
 */
interface OwnProps extends RouteProps, AuthenticationContext, AuthorizationContext {
    actions?: Array<string>;
}

/**
 * @typedef {Object} Props
 */
type Props = OwnProps;

/**
 * extends route to check if use is authenticated, if not it redirects to login
 * @param {Props} props
 * @returns {React.FC<Props>}
 */
const AuthenticatedRoute: React.FC<Props> = props => {
    if (!props.isAuthenticated) {
        const renderComponent = () => (
            <Redirect to={{
                pathname: LOGIN_ROUTE,
                state: {
                    from: props.location,
                    isRedirect: true,
                },

            }}
            />
        );

        return (
            <Route
                {...props}
                component={renderComponent}
                render={undefined}
            />
        );
    }

    if (props.actions && !checkPermission(props.permissions, props.actions)) {
        const renderComponent = () => (
            <Redirect to={{
                pathname: NOT_FOUND_ROUTE,
                state: {
                    from: props.location,
                },
            }}
            />
        );

        return (
            <Route
                {...props}
                component={renderComponent}
                render={undefined}
            />
        );
    }

    return (
        <Route {...props} />
    );
};

export default withAuthorizationContext(withAuthenticationContext(AuthenticatedRoute));

import React, { Component } from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { get } from 'lodash';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { userProfileURL } from '../../services/users';
import Loader from '../elements/Loader';
import FormTextField from '../elements/FormTextField';
import Button from '../elements/Button';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import FormCheckbox from '../elements/FormCheckbox';

interface OwnProps extends TranslationContext, AuthenticationContext {}

interface OwnState {
    isFetching: boolean;
    showChangePassword: boolean;
    showSaveChanges: boolean;
    name: string;
    email: string;
    contact: string;
    address: string;
    postalCode: string;
    city: string;
    newsletter: boolean;
    myStoreName: string | null;
    newPassword: string;
    passwordConfirmation: string;
    formErrors: any;
}

const initialState: OwnState = {
    isFetching: false,
    showChangePassword: false,
    showSaveChanges: false,
    name: '',
    email: '',
    contact: '',
    address: '',
    postalCode: '',
    city: '',
    newsletter: false,
    myStoreName: null,
    newPassword: '',
    passwordConfirmation: '',
    formErrors: null,
};

class UserProfile extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    componentDidMount(): void {
        this.prepare();
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            showSaveChanges: true,
            [e.currentTarget.name]: e.currentTarget.value,
        });
    }

    onClickChangePassword = () => {
        const { showChangePassword, passwordConfirmation, newPassword } = this.state;
        this.setState({
            showChangePassword: !showChangePassword,
            passwordConfirmation: showChangePassword ? '' : passwordConfirmation,
            newPassword: showChangePassword ? '' : newPassword,
        });
    }

    onSaveChanges = () => {
        const {
            name,
            email,
            contact,
            postalCode,
            city,
            address,
            newsletter,
            newPassword,
            showChangePassword,
        } = this.state;
        
        if (this.validateFields()) {
            const fields = {
                name: String(name).trim(),
                email: String(email).trim(),
                contact: String(contact).trim(),
                postalCode: String(postalCode).trim(),
                city: String(city).trim(),
                address: String(address).trim(),
                newsletter,
                password: showChangePassword ? String(newPassword) : null,
            };

            this.editProfileRequest(fields);
        }
    }

    checkNewsletter = ():void => {
        const prevState = this.state;
        const { newsletter } = this.state;
        this.setState({
            ...prevState,
            showSaveChanges: true,
            newsletter: !newsletter,
        });
    }

    editProfileRequest = (fields: object) => {
        const { t, setAuthentication, user } = this.props;
        this.setState({ isFetching: true });

        axios.put(userProfileURL(), fields)
            .then(response => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('storeAdmin.profile.editSuccess'));

                this.setState({
                    isFetching: false,
                    newPassword: '',
                    passwordConfirmation: '',
                    showSaveChanges: false,
                    showChangePassword: false,
                });
                setAuthentication({
                    user: {
                        ...user,
                        name: response.data.name || '',
                        contact: response.data.contact || '',
                        city: response.data.city || '',
                        postalCode: response.data.postalCode || '',
                        address: response.data.address || '',
                    },
                });
            })
            .catch(error => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('storeAdmin.profile.editError'));

                if (error.response) {
                    this.handleResponse(error.response.data);
                } else {
                    this.setState({
                        isFetching: false,
                    });
                }
            });
    }

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    validateFields = () => {
        let errors: IFormError | null = getFormErrors(this.state, VALIDATIONS.USER_PROFILE);

        if (!errors) errors = {};

        const { newPassword, passwordConfirmation, showChangePassword } = this.state;
        if (showChangePassword) {
            if (passwordConfirmation && newPassword !== passwordConfirmation) {
                errors.passwordConfirmation = [{ typeOfViolation: 'passwordsDontMatch' }];
            }

            if (String(newPassword).trim().length < 4) {
                errors.newPassword = [{ typeOfViolation: 'MinLength', min: 4 }];
            }

            if (String(passwordConfirmation).trim().length < 4) {
                errors.passwordConfirmation = [{ typeOfViolation: 'MinLength', min: 4 }];
            }
        }

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    }

    prepare = () => {
        const { isFetching } = this.state;

        if (isFetching) return;

        axios.get(userProfileURL())
            .then(response => {
                const { data } = response;

                this.setState({
                    isFetching: false,
                    name: data.name,
                    email: data.email,
                    contact: data.contact,
                    address: data.address,
                    postalCode: data.postalCode,
                    city: data.city,
                    myStoreName: data.myStores && data.myStores[0] ? data.myStores[0].name : null,
                    newsletter: data.newsletter,
                });
            })
            .catch(() => {
                this.setState({
                    isFetching: false,
                });
            });
    }

    render() {
        const { t } = this.props;
        const {
            isFetching,
            showChangePassword,
            name,
            email,
            contact,
            address,
            postalCode,
            city,
            newsletter,
            formErrors,
            passwordConfirmation,
            newPassword,
            myStoreName,
            showSaveChanges,
        } = this.state;

        return (
            <div className="app-tabs__tab-content user-admin">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="form-centered">
                    <Row>
                        <Col xs={12} md={12}>
                            <FormTextField
                                label={t('storeAdmin.profile.labels.name')}
                                placeholder={t('storeAdmin.profile.labels.name')}
                                name="name"
                                value={name}
                                maxLength={80}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.name', null)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <FormTextField
                                label={t('storeAdmin.profile.labels.address')}
                                placeholder={t('storeAdmin.profile.labels.address')}
                                name="address"
                                value={address}
                                maxLength={100}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.address', null)}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <FormTextField
                                label={t('storeAdmin.profile.labels.postalCode')}
                                placeholder={t('storeAdmin.profile.labels.postalCode')}
                                name="postalCode"
                                value={postalCode}
                                maxLength={20}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.postalCode', null)}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <FormTextField
                                label={t('storeAdmin.profile.labels.city')}
                                placeholder={t('storeAdmin.profile.labels.city')}
                                name="city"
                                value={city}
                                maxLength={40}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.city', null)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6}>
                            <FormTextField
                                label={t('storeAdmin.profile.labels.contact')}
                                placeholder={t('storeAdmin.profile.labels.contact')}
                                name="contact"
                                value={contact}
                                maxLength={25}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.contact', null)}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <FormTextField
                                label={t('storeAdmin.profile.labels.email')}
                                placeholder={t('storeAdmin.profile.labels.email')}
                                name="email"
                                value={email}
                                maxLength={70}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.email', null)}
                                disabled
                            />
                        </Col>
                    </Row>
                    {myStoreName && (
                        <Row>
                            <Col xs={12} md={6}>
                                <FormTextField
                                    label={t('storeAdmin.profile.labels.store')}
                                    placeholder={t('storeAdmin.profile.labels.store')}
                                    name="myStoreName"
                                    value={myStoreName}
                                    maxLength={80}
                                    onChange={this.onInputChange}
                                    disabled
                                />
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col xs={10}>
                            <FormCheckbox
                                name="newsletter"
                                label={t('customerRegister.optInEmails')}
                                value={newsletter}
                                onChange={this.checkNewsletter}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="mt-3 mb-1">
                            <Button
                                text={t('global.buttons.changePassword')}
                                styles="button--blue button--small"
                                callback={this.onClickChangePassword}
                            />
                        </Col>
                    </Row>
                    {showChangePassword && (
                        <Row>
                            <Col xs={12} lg={6}>
                                <FormTextField
                                    label={t('storeAdmin.profile.labels.newPassword')}
                                    placeholder={t('storeAdmin.profile.labels.newPassword')}
                                    type="password"
                                    name="newPassword"
                                    value={newPassword}
                                    maxLength={70}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.newPassword', null)}
                                />
                            </Col>
                            <Col xs={12} lg={6}>
                                <FormTextField
                                    label={t('storeAdmin.profile.labels.passwordConfirmation')}
                                    placeholder={t('storeAdmin.profile.labels.passwordConfirmation')}
                                    type="password"
                                    name="passwordConfirmation"
                                    value={passwordConfirmation}
                                    maxLength={70}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.passwordConfirmation', null)}
                                />
                            </Col>
                        </Row>
                    )}
                    <hr className="divider" />
                    <Row className="w-100">
                        <Col xs={12} className="w-100 d-flex justify-content-end">
                            <Button
                                text={t('global.buttons.saveChanges')}
                                styles="button--dark-blue"
                                callback={this.onSaveChanges}
                                disabled={!showSaveChanges}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(UserProfile));

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import ErrorMessage from './ErrorMessage';
import 'react-widgets/dist/css/react-widgets.css';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

momentLocalizer();

interface OwnProps {
    name: string;
    value: Date | number | string | null;
    onChange?: Function;
    label?: string;
    containerStyles?: string;
    placeholder?: string;
    fieldStyles?: string;
    errors?: any;
    disabled?: boolean;
}

class FormTimeField extends Component<OwnProps> {
    onChange = (name: string, date?: Date | null | string) => {
        const { onChange } = this.props;

        if (onChange) onChange(name, date);
    };

    renderErrors = () => {
        const { name, errors } = this.props;

        if (errors) {
            return (
                <ErrorMessage errors={errors} field={name} />
            );
        }
        return <React.Fragment />;
    };

    render() {
        const {
            name,
            value,
            label,
            disabled,
            placeholder,
        } = this.props;

        return (
            <div className="form-group">
                {label && (
                    <label htmlFor={name}>{label}</label>
                )}
                <DateTimePicker
                    placeholder={placeholder}
                    format="HH:mm"
                    time
                    date={false}
                    value={value ? new Date(value) : undefined}
                    onChange={date => this.onChange(name, date)}
                    step={15}
                    disabled={disabled}
                />
                <p className="error-field">{this.renderErrors()}</p>
            </div>
        );
    }
}

export default FormTimeField;

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const storesURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/stores${queryString}`;
};

export const storeURL = (id: number | string): string => {
    return `${API_URL}/stores/${id}`;
};

export const storeDisableUrl = (id : number | string, disable: boolean) => {
    return `${API_URL}/stores/${id}/disable?disable=${disable}`;
};

export const storePublicURL = (id: number | string): string => {
    return `${API_URL}/stores/public/${id}`;
};

export const storeSlugURL = (slug: string): string => `${API_URL}/stores/slug/${slug}`;

export const storesPublicURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/stores/public${queryString}`;
};

export const storesLocationsURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/stores/locations${queryString}`;
};

export const storesRandomURL = (): string => {
    return `${API_URL}/stores/public/highlight`;
};

export const storeDeliveryMethodsURL = (): string => {
    return `${API_URL}/stores/available-delivery-methods`;
};

export const storesByCategoryPublicURL = (categoryId: number | string, params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/stores/with-category/${categoryId}/public${queryString}`;
};

export const storeXLSURL = (): string => {
    return `${API_URL}/stores/export`;
};

export const storeUploadURL = (storeId: number | string, link: string, format: string): string => {
    return `${API_URL}/stores/${storeId}/setfeed?format=${format}&url=${link}`;
};

export const storesCourierURL = () => {
    return `${API_URL}/stores/courier`;
};

export const storesCategoriesURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/stores/categories${queryString}`;
};

export const homeShippingValidateURL = (storeId: number | string, latitude: number, longitude: number) => {
    return `${API_URL}/stores/${storeId}/homedelivery?latitude=${latitude}&longitude=${longitude}`;
};

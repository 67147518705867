/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import arrow from '../../assets/images/arrow-long-up.svg';

interface OwnProps {
    label: string;
    ascending?: boolean;
    descending?: boolean;
    callback: () => void;
    isActive: boolean;
}
interface OwnState {
    isActive: boolean;
}

const initialState = {
    isActive: false,
};

class SortFilterButton extends React.Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { isActive } = this.props;
        if (isActive) {
            this.setState({
                isActive,
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const { isActive } = this.props;
        if (prevProps && prevProps.isActive !== isActive) {
            this.setState({
                isActive,
            });
        }
    }

    render() {
        const {
            label,
            ascending,
            descending,
            callback,
        } = this.props;

        const { isActive } = this.state;

        return (
            <div className="sort-filter-button">
                <div className={`sort-filter-button${isActive ? '__active' : '__inactive'}${ascending ? '--ascending' : ''}${descending ? '--descending' : ''}`} onClick={callback}>
                    <div>{label}</div>
                    {(ascending || descending) && <img src={arrow} alt="" />}
                </div>
            </div>
        );
    }
}

export default SortFilterButton;

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';

export const customersXLSURL = (): string => {
    return `${API_URL}/users/export`;
};

export const salesXLSURL = (): string => {
    return `${API_URL}/orders/items/export`;
};

export const rafflesXLSURL = (): string => {
    return `${API_URL}/raffles/users`;
};

export const whatsAppNumberConfigURL = (): string => {
    return `${API_URL}/config`;
};

export const whatsAppNumberGetURL = (): string => {
    return `${API_URL}/config/whatsapp`;
};

export const helpdeskSchedulesURL = (id?: string | number): string => {
    return `${API_URL}/schedules/${id || ''}`;
};

export const setProductHighlightURL = (id: string | number, highlight: boolean): string => {
    return `${API_URL}/products/${id}/highlight?highlight=${highlight}`;
};

export const getProductAllowedHighlightURL = (): string => {
    return `${API_URL}/products/allowhighlight`;
};

export const feesAdmin = (id: number): string => {
    return `${API_URL}/payment/${id}`;
};

/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
 
import { Col } from 'react-bootstrap';
import axios from 'axios';
import { get } from 'lodash';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import Button from '../elements/Button';
import { MatchParams } from '../../constants/misc';
import Loader from '../elements/Loader';
import { CartContext, withCartContext } from '../controllers/cart/CartContext';
import FormTextField from '../elements/FormTextField';
import { UserRoles } from '../../constants/authorization';
import { LOGIN_ROUTE, REGISTER_ROUTE } from '../../constants/routes';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import { raffleURL } from '../../services/users';
import { validateField, VALIDATIONS } from '../../utils/validation';
import { ICON, SvgIcon } from '../elements/SvgIcon';
import bannerImage from '../../assets/images/Banner_validacao_rifas.jpg';
 
 interface OwnState {
     raffles: string[]
     formErrors : any
 }
 
const initialState : OwnState = {
    raffles: [''],
    formErrors: null,
};
 
class RaffleScreen extends Component<RouteComponentProps<MatchParams> & AuthenticationContext & TranslationContext & CartContext, OwnState> {
     state = {
         ...initialState,
     };

     onInputChange = (e: React.FormEvent<HTMLInputElement>, idx: number) => {
         e.preventDefault();
         const { value } = e.currentTarget;
         const { raffles } = this.state;
         const auxRaffles = [...raffles];

         auxRaffles[idx] = value;
         this.setState({
             raffles: auxRaffles,
         });
     };
 
     onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
         const { raffles } = this.state;
         const { t } = this.props;
         e.preventDefault();
        
         if (!this.validateForm()) {
             displayNotification(NOTIFICATION_TYPE.ERROR, t('raffle.errorFormat'));
             return;
         }
         axios.post(raffleURL(raffles)).then(() => {
             this.setState({
                 raffles: [''],
             });
             displayNotification(NOTIFICATION_TYPE.SUCCESS, t('raffle.success'));
         }).catch(error => {
             // This code is only for now as a hotfix to the raffles,
             // because the api cannot figure ou the issue, this should be removed at a later point
             if (error.response.status === 500) {
                 this.setState({
                     raffles: [''],
                 });
             } else if (error.response.data.length > 0) {
                 this.setState({
                     raffles: error.response.data,
                 });
                 displayNotification(NOTIFICATION_TYPE.ERROR, t('raffle.someValidError'));
             } else {
                 displayNotification(NOTIFICATION_TYPE.ERROR, t('raffle.submitError'));
             }
         });
     };

     onAddRaffle = () => {
         this.setState(prevState => ({
             raffles: [...prevState.raffles, ''],
         }));
     }

     onLoginClick = (): void => {
         const { history, location } = this.props;
         history.push({
             pathname: LOGIN_ROUTE,
             state: {
                 from: location,
                 isRedirect: true,
             },
         });
     }

     onRegisterClick = (): void => {
         const { history, location } = this.props;
         history.push({
             pathname: REGISTER_ROUTE,
             state: {
                 from: location,
                 isRedirect: true,
             },
         });
     }

     onRemove = (idx: number) => {
         const { raffles } = this.state;
         const auxRaffles = [...raffles];
         auxRaffles.splice(idx, 1);

         this.setState({
             raffles: auxRaffles,
         });
     }

     validateForm = () => {
         const { raffles } = this.state;
         let formErrors = {};
         raffles.forEach((raffle, idx) => {
             const errors = validateField('raffle', raffle, VALIDATIONS.RAFFLE);
             if (errors) {
                 formErrors = {
                     ...formErrors,
                     [`raffle${idx}`]: validateField('raffle', raffle, VALIDATIONS.RAFFLE),
                 };
             }
         });
         this.setState({
             formErrors,
         });

         return Object.keys(formErrors).length === 0;
     }

     render() {
         const { t, isFetching, user } = this.props;
         const {
             raffles,
             formErrors,
         } = this.state;
         
         const isCustomer = user?.groups.includes(UserRoles.CUSTOMER);
         return (
             <div className="container-fluid app_screen app_screen__raffle-screen">
                 {isFetching && (
                     <div className="loader-wrapper">
                         <Loader />
                     </div>
                 )}
                 <div className="app_screen__centered margin-top-bar">
                     { isCustomer && (
                         <>
                             <div className="raffle-banner">
                                 <img src={bannerImage} alt="Leiria Christmas Market 2021" />
                             </div>
                             <div className="page-title">
                                 <h2>{t('raffle.validate')}</h2>
                             </div>
                             <div className="home-screen__tagline">
                                 <p>{t('raffle.text1')}</p>
                                 <p>{t('raffle.text2')}</p>
                                 <p>{t('raffle.text3')}</p>
                             </div>
                             <Col className="raffle-form">
                                 {raffles.map((raffle, index) => (
                                     <div className="raffle-line">
                                         <FormTextField
                                             disabled={!isCustomer}
                                             label={t('raffle.ticket')}
                                             name={`raffle${index}`}
                                             value={raffle}
                                             onChange={(e : React.FormEvent<HTMLInputElement>) => this.onInputChange(e, index)}
                                             errors={get(formErrors, `raffle${index}`, null)}
                                             fieldStyles="raffle-button"
                                             containerStyles="raffle-input"
                                         />
                                
                                         { raffles.length > 1 && (
                                         <div className="svg-icon" data-testid="deleteRaffleIcon">
                                             <SvgIcon
                                                 icon={ICON.CROSS_CIRCLE}
                                                 callback={() => this.onRemove(index)}
                                                 className="svg-icon--red"
                                             />
                                         </div>
                                         )}
                                         {(index === raffles.length - 1 && raffles.length < 20) && (
                                         <div className="svg-icon" data-testid="addRaffleIcon" onClick={this.onAddRaffle}>
                                             <SvgIcon
                                                 icon={ICON.PLUS}
                                                 className="svg-icon--blue"
                                             />
                                         </div>
                                         )
                                        }
                                     </div>
                                 ))}
                             </Col>

                             <div className="login-form-register">
                                 <Button
                                     type="button"
                                     text={t('raffle.validateBtn')}
                                     styles="button--dark-blue"
                                     callback={this.onSubmit}
                                     disabled={!isCustomer}
                                 />
                             </div>
                         </>
                     )}
                     { !isCustomer && (
                         
                         <div className="message">
                             <div className="title">
                                 {t('raffle.loggedInOnly')}
                             </div>
                             <hr />
                             <div className="d-flex flex-wrap align-items-center justify-content-center gap-4">
                                 <Button
                                     text={t('customerRegister.loginBtn')}
                                     styles="button--dark-blue button--bigger"
                                     callback={this.onLoginClick}
                                 />
                                 <Button
                                     text={t('customerRegister.title')}
                                     styles="button--dark-blue button--bigger"
                                     callback={this.onRegisterClick}
                                 />
                             </div>
                            
                         </div>
                     )}
                 </div>

             </div>
         );
     }
}
 
export default withTranslationContext(withAuthenticationContext(withCartContext(RaffleScreen)));

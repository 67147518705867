/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { debounce, get } from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { SelectOption } from '../../constants/misc';
import FormSelectField from './FormSelectField';
import FormTimeField from './FormTimeField';
import Button from './Button';
import { ScheduleItem } from '../../constants/types';

interface OwnProps extends TranslationContext {
    schedule: ScheduleItem;
    errors: any;
    onScheduleChange(schedule: any): void;
    onRemove(id: string | number): void;
}

interface OwnState {
    schedule: ScheduleItem,
    weekDaysOptions: Array<SelectOption>;
    debounceOnChange: any;
}

class StoreSchedule extends Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);

        const { t, schedule } = props;

        const weekDaysOptions: Array<SelectOption> = [];
        const weekdays: string[] = moment.weekdays();

        weekDaysOptions.push({
            value: '',
            label: t('global.select'),
        });

        Object.keys(weekdays).forEach(k => {
            const day = weekdays[Number(k)];

            weekDaysOptions.push({
                value: Number(k) + 1,
                label: day,
            });
        });

        this.state = {
            schedule: { ...schedule },
            weekDaysOptions,
            debounceOnChange: debounce(this.triggerChange, 500),
        };
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { schedule } = this.state;
        const { name } = e.currentTarget;

        if (name === 'isInterval') {
            this.setState({
                ...this.state,
                schedule: {
                    ...this.state.schedule,
                    isInterval: !schedule.isInterval,
                },
            }, () => this.state.debounceOnChange());
            return;
        }

        if (name === 'initialWeekDay' && !schedule.isInterval) {
            this.setState({
                ...this.state,
                schedule: {
                    ...this.state.schedule,
                    initialWeekDay: e.currentTarget.value,
                    endWeekDay: e.currentTarget.value,
                },
            }, () => this.state.debounceOnChange());
            return;
        }

        this.setState({
            ...this.state,
            schedule: {
                ...this.state.schedule,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        }, () => this.state.debounceOnChange());
    };

    onDateChange = (name: string, date: Date | null | string) => {
        const { schedule } = this.state;
        const { openOn, closeOn } = schedule;
        let isValid = true;
        if (date) {
            const time = new Date(date).getTime();
            if (name === 'openOn') {
                if (closeOn && time >= closeOn) isValid = false;
            } else if (name === 'closeOn') {
                if (openOn && time <= openOn) isValid = false;
            }
        }
        if (isValid || !closeOn || !openOn) {
            this.setState({
                ...this.state,
                schedule: {
                    ...this.state.schedule,
                    [name]: date ? new Date(date).getTime() : date,
                },
            }, () => this.state.debounceOnChange());
        }
    };

    onRemove = () => {
        const { onRemove } = this.props;

        const { schedule } = this.state;

        onRemove(schedule.id);
    };

    triggerChange = () => {
        const { onScheduleChange } = this.props;

        const {
            schedule,
        } = this.state;

        onScheduleChange({
            ...schedule,
        });
    };

    render() {
        const { t, errors } = this.props;
        const { schedule, weekDaysOptions } = this.state;

        return (
            <Row className="schedule-row">
                <Col xs={12} md={4}>
                    <FormSelectField
                        name="initialWeekDay"
                        label={schedule.isInterval ? t('admin.stores.labels.initialWeekDay') : t('admin.stores.labels.weekDay')}
                        placeholder={schedule.isInterval ? t('admin.stores.labels.initialWeekDay') : t('admin.stores.labels.weekDay')}
                        value={schedule.initialWeekDay}
                        options={weekDaysOptions}
                        onChange={this.onInputChange}
                        errors={get(errors, 'initialWeekDay', null)}
                    />
                </Col>
                {schedule.isInterval && (
                    <Col xs={12} md={3}>
                        <FormSelectField
                            name="endWeekDay"
                            label={t('admin.stores.labels.endWeekDay')}
                            placeholder={t('admin.stores.labels.endWeekDay')}
                            value={schedule.endWeekDay}
                            options={weekDaysOptions}
                            onChange={this.onInputChange}
                            errors={get(errors, 'fields.endWeekDay', null)}
                        />
                    </Col>
                )}
                <Col xs={12} md={2}>
                    <FormTimeField
                        name="openOn"
                        value={schedule.openOn}
                        label={t('admin.stores.labels.open')}
                        placeholder={t('admin.stores.labels.open')}
                        onChange={this.onDateChange}
                        errors={get(errors, 'fields.openOn', null)}
                    />
                </Col>
                <Col xs={12} md={2}>
                    <FormTimeField
                        name="closeOn"
                        value={schedule.closeOn}
                        label={t('admin.stores.labels.close')}
                        placeholder={t('admin.stores.labels.close')}
                        onChange={this.onDateChange}
                        errors={get(errors, 'fields.closeOn', null)}
                    />
                </Col>
                <Col xs={12} md={2} className="schedule-remove-column">
                    <Button
                        text={t('global.buttons.remove')}
                        styles="button--light-red button--smaller"
                        callback={this.onRemove}
                    />
                </Col>
            </Row>
        );
    }
}

export default withTranslationContext(StoreSchedule);

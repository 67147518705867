/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { OverlayInjectedProps } from 'react-bootstrap/Overlay';
import MediaQuery from 'react-responsive';

import iconInfo from '../../assets/images/ic-info.svg';

interface OwnProps {
    text: string;
}

export const BackofficeTooltip = (props: OwnProps): JSX.Element => {
    const { text } = props;

    const renderTooltip = (overlayProps : OverlayInjectedProps) => (
        <Tooltip
            className="highlight-tooltip"
            id="highlight-tooltip"
            {...overlayProps}
        >
            {text}
        </Tooltip>
    );

    return (
        <>
            <MediaQuery maxWidth={450}>
                <OverlayTrigger
                    trigger="click"
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={overlayProps => renderTooltip(overlayProps)}
                >
                    <img className="mb-1" src={iconInfo} alt="highlight info" />
                </OverlayTrigger>
            </MediaQuery>
            <MediaQuery minWidth={451}>
                <OverlayTrigger
                    trigger="hover"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={overlayProps => renderTooltip(overlayProps)}
                >
                    <img className="mb-1" src={iconInfo} alt="highlight info" />
                </OverlayTrigger>
            </MediaQuery>
        </>
    );
};

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const usersURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/users${queryString}`;
};

export const userURL = (id: number | string): string => {
    return `${API_URL}/users/${id}`;
};

export const userDeleteURL = (ids: number | string | Array<number | string>): string => {
    return `${API_URL}/users?ids=${Array.isArray(ids) ? ids.join('&ids=') : ids}`;
};

export const customerUserURL = (): string => {
    return `${API_URL}/authentication/register`;
};

export const storeManagerUserURL = (id?: number | string): string => {
    if (id) return `${API_URL}/users/${id}`;
    return `${API_URL}/users`;
};

export const usersQueryURL = (params: Record<string, unknown> = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/users${queryString}`;
};

export const storeManagersURL = (): string => {
    return `${API_URL}/users?role=STORE_MANAGER`;
};

export const userProfileURL = (): string => {
    return `${API_URL}/users/my-profile`;
};

export const userForgetPassword = (): string => {
    return `${API_URL}/authentication/recover-password`;
};

export const userResetPassword = (): string => {
    return `${API_URL}/authentication/reset-password`;
};

export const userResetToken = (params: object): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/authentication/resendValidation/${queryString}`;
};

export const userFirstAddressURL = (): string => {
    return `${API_URL}/addresses/first`;
};

export const userAddressListURL = (params: Record<string, unknown> = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/addresses${queryString}`;
};

export const userAddressURL = (addressId: string | number): string => {
    return `${API_URL}/addresses/${addressId}`;
};
export const raffleURL = (codes : Array<string>): string => {
    return `${API_URL}/raffles/validation?codes=${codes.join('&codes=')}`;
};

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export interface IError {
    typeOfViolation?: string;
    errorCode?: number;
    min?: number;
    max?: number;
    size?: number;
    result?: string;
}

export interface IFormError {
    [key: string]: IError[] | null;
}

const NOT_BLANK = 'NotBlank';
const NOT_EMPTY = 'NotEmpty';
const NOT_ZERO = 'NotZero';
const MIN = 'Min';
const MAX = 'Max';
const REGEX = 'Regex';
const MIN_LENGTH = 'MinLength';
const MAX_LENGTH = 'MaxLength';
const SIZE_EXACT = 'SizeExact';
const DIMENSIONS = 'Dimensions';
const ALPHANUMERIC = 'Alphanumeric';
const WHITESPACE = 'Whitespace';

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
const integerRegex = /^[0-9]+?$/;
const decimalNumberRegex = /^-?[0-9]+[.]?[0-9]{0,2}$/;
const latLongNumberRegex = /^-?[0-9]+[.]?[0-9]{0,8}$/;
const postalCodeRegex = /^[1-8]\d{3}-\d{3}$/; // exclude  Madeira Islands and Azores
const hexRegex = /^#[A-Fa-f0-9]{6}$/;
const phoneRegex = /^9[1236][0-9]{7}|2[0-9]{8}/;
const mobilePhoneRegex = /^9[1236][0-9]{7}/;
const alphaNumericRegex = /^[a-zA-Z0-9]+$/;

export const VALIDATIONS = {
    LOGIN_FORM: {
        email: {
            validations: [NOT_BLANK, REGEX],
            regex: emailRegex,
        },
        password: {
            validations: [NOT_BLANK, MIN_LENGTH],
            min: 4,
        },
    },
    RESET_PASSWORD: {
        password: {
            validations: [NOT_BLANK, MIN_LENGTH],
            min: 4,
        },
        passwordConfirmation: {
            validations: [NOT_BLANK],
        },
    },
    RECOVER_PASSWORD: {
        email: {
            validations: [NOT_BLANK, REGEX],
            regex: emailRegex,
        },
    },
    NEW_CATEGORY_FORM: {
        description: {
            validations: [NOT_BLANK],
        },
        thumbnail: {
            validations: [NOT_BLANK],
        },
    },
    NEW_SUBCATEGORY_FORM: {
        mainCategoryId: {
            validations: [NOT_BLANK],
        },
        description: {
            validations: [NOT_BLANK],
        },
    },
    NEW_SHIPPING_PRICE_FORM: {
        maxWeight: {
            validations: [NOT_BLANK, REGEX, MIN, MAX],
            regex: decimalNumberRegex,
            min: 0,
            max: 1000000,
        },
        price: {
            validations: [NOT_BLANK, REGEX, MIN, MAX],
            regex: decimalNumberRegex,
            min: 0,
            max: 1000000,
        },
    },
    CUSTOMER_FORM: {
        name: {
            validations: [NOT_BLANK],
        },
        email: {
            validations: [NOT_BLANK, REGEX],
            regex: emailRegex,
        },
        address: {
            validations: [NOT_BLANK],
        },
        contact: {
            validations: [NOT_BLANK, REGEX, SIZE_EXACT],
            regex: mobilePhoneRegex,
            size: 9,
        },
        city: {
            validations: [NOT_BLANK],
        },
        postalCode: {
            validations: [NOT_BLANK, REGEX],
            regex: postalCodeRegex,
        },
        password: {
            validations: [NOT_BLANK, MIN_LENGTH],
            min: 4,
        },
        passwordConfirmation: {
            validations: [NOT_BLANK, MIN_LENGTH],
            min: 4,
        },
    },
    JOIN_FORM: {
        name: {
            validations: [NOT_BLANK],
        },
        email: {
            validations: [NOT_BLANK],
        },
        storeName: {
            validations: [NOT_BLANK],
        },
        phone: {
            validations: [NOT_BLANK],
        },
        categoryId: {
            validations: [NOT_ZERO],
        },
        address: {
            validations: [NOT_BLANK],
        },
        postalCode: {
            validations: [NOT_BLANK, REGEX],
            regex: postalCodeRegex,
        },
        city: {
            validations: [NOT_BLANK],
        },
    },
    CONTACT_FORM: {
        name: {
            validations: [NOT_BLANK],
        },
        email: {
            validations: [NOT_BLANK, REGEX],
            regex: emailRegex,
        },
        phone: {
            validations: [NOT_BLANK],
        },
        message: {
            validations: [NOT_BLANK],
        },
        messageType: {
            validations: [NOT_BLANK],
        },
    },
    STORE_FORM: {
        latitude: {
            validations: [REGEX],
            regex: latLongNumberRegex,
        },
        longitude: {
            validations: [REGEX],
            regex: latLongNumberRegex,
        },
        email: {
            validations: [NOT_BLANK, REGEX],
            regex: emailRegex,
        },
        availableDeliveryMethods: {
            validations: [NOT_BLANK],
        },
        description: {
            validations: [NOT_BLANK],
        },
        contact: {
            validations: [NOT_BLANK],
        },
        address: {
            validations: [NOT_BLANK],
        },
        name: {
            validations: [NOT_BLANK],
        },
        contactFeeType: {
            validations: [NOT_BLANK],
        },
        postalCode: {
            validations: [NOT_BLANK, REGEX],
            regex: postalCodeRegex,
        },
        categoryId: {
            validations: [NOT_ZERO],
        },
        thumbnail: {
            validations: [NOT_BLANK],
        },
        initialWeekDay: {
            validations: [NOT_BLANK],
        },
        nif: {
            validations: [NOT_BLANK],
        },
        iban: {
            validations: [NOT_BLANK, ALPHANUMERIC, WHITESPACE],
            regex: alphaNumericRegex,
        },
        country: {
            validations: [NOT_BLANK],
        },
        bicSwift: {
            validations: [NOT_BLANK],
        },
        location: {
            validations: [NOT_BLANK],
        },
    },
    EXHIBITION_STORE_FORM: {
        latitude: {
            validations: [REGEX],
            regex: latLongNumberRegex,
        },
        longitude: {
            validations: [REGEX],
            regex: latLongNumberRegex,
        },
        email: {
            validations: [NOT_BLANK, REGEX],
            regex: emailRegex,
        },
        availableDeliveryMethods: {
            validations: [NOT_BLANK],
        },
        description: {
            validations: [NOT_BLANK],
        },
        contact: {
            validations: [NOT_BLANK],
        },
        address: {
            validations: [NOT_BLANK],
        },
        name: {
            validations: [NOT_BLANK],
        },
        postalCode: {
            validations: [NOT_BLANK, REGEX],
            regex: postalCodeRegex,
        },
        categoryId: {
            validations: [NOT_BLANK],
        },
        thumbnail: {
            validations: [NOT_BLANK],
        },
        initialWeekDay: {
            validations: [NOT_BLANK],
        },
    },
    UPLOAD_PRODUCTS_FORM: {
        productsFeedURL: {
            validations: [NOT_BLANK],
        },
    },
    COLLECTION_POINT_FORM: {
        latitude: {
            validations: [REGEX],
            regex: latLongNumberRegex,
        },
        longitude: {
            validations: [REGEX],
            regex: latLongNumberRegex,
        },
        email: {
            validations: [NOT_BLANK, REGEX],
            regex: emailRegex,
        },
        description: {
            validations: [NOT_BLANK],
        },
        contact: {
            validations: [NOT_BLANK],
        },
        address: {
            validations: [NOT_BLANK],
        },
        name: {
            validations: [NOT_BLANK],
        },
        postalCode: {
            validations: [NOT_BLANK, REGEX],
            regex: postalCodeRegex,
        },
        categoryId: {
            validations: [NOT_BLANK],
        },
        thumbnail: {
            validations: [NOT_BLANK],
        },
        initialWeekDay: {
            validations: [NOT_BLANK],
        },
    },
    STORE_MANAGER_EDIT_FORM: {
        name: {
            validations: [NOT_BLANK],
        },
        email: {
            validations: [NOT_BLANK],
        },
        storeId: {
            validations: [NOT_BLANK],
        },
    },
    STORE_MANAGER_CREATE_FORM: {
        name: {
            validations: [NOT_BLANK],
        },
        email: {
            validations: [NOT_BLANK],
        },
        password: {
            validations: [NOT_BLANK, MIN_LENGTH],
            min: 4,
        },
        passwordConfirmation: {
            validations: [NOT_BLANK],
        },
        storeId: {
            validations: [NOT_BLANK],
        },
    },
    SHIPPING_FORM: {
        distanceMax: {
            validations: [NOT_BLANK, NOT_EMPTY, MIN, REGEX],
            regex: integerRegex,
            min: 1,
        },
        shippingPriceMin: {
            validations: [NOT_BLANK, NOT_EMPTY, MIN, MAX, REGEX],
            regex: decimalNumberRegex,
            min: 0,
            max: 1000000,
        },
        latitude: {
            validations: [NOT_BLANK, NOT_EMPTY, REGEX, MIN, MAX],
            regex: latLongNumberRegex,
            min: -90,
            max: 90,
        },
        longitude: {
            validations: [NOT_BLANK, NOT_EMPTY, REGEX, MIN, MAX],
            regex: latLongNumberRegex,
            min: -90,
            max: 90,
        },
    },
    PRODUCT_FORM: {
        name: {
            validations: [NOT_BLANK],
        },
        shortDescription: {
            validations: [NOT_BLANK],
        },
        brand: {
            validations: [NOT_BLANK],
        },
        reference: {
            validations: [NOT_BLANK],
        },
        price: {
            validations: [NOT_BLANK, REGEX, MAX, MIN],
            regex: decimalNumberRegex,
            max: 1000000,
            min: 0,
        },
        packingWeight: {
            validations: [NOT_BLANK, REGEX, MAX, MIN],
            regex: decimalNumberRegex,
            max: 1000000,
            min: 0,
        },
        length: {
            validations: [NOT_BLANK, REGEX, MAX, MIN, DIMENSIONS],
            regex: integerRegex,
            max: 1000000,
            min: 0,
        },
        width: {
            validations: [NOT_BLANK, REGEX, MAX, MIN, DIMENSIONS],
            regex: integerRegex,
            max: 1000000,
            min: 0,
        },
        height: {
            validations: [NOT_BLANK, REGEX, MAX, MIN, DIMENSIONS],
            regex: integerRegex,
            max: 1000000,
            min: 0,
        },
        categoryId: {
            validations: [NOT_BLANK],
        },
        characteristicType: {
            validations: [NOT_BLANK],
        },
        value: {
            validations: [NOT_BLANK],
        },
        colorHex: {
            validations: [REGEX],
            regex: hexRegex,
        },
    },
    VOUCHER_FORM: {
        name: {
            validations: [NOT_BLANK],
        },
        description: {
            validations: [NOT_BLANK],
        },
        price: {
            validations: [NOT_BLANK, REGEX, MAX, MIN],
            regex: decimalNumberRegex,
            max: 1000000,
            min: 0,
        },
        categoryId: {
            validations: [NOT_BLANK],
        },
        email: {
            validations: [NOT_BLANK, REGEX],
            regex: emailRegex,
        },
    },
    HEALTH_PRODUCT_FORM: {
        description: {
            validations: [NOT_BLANK],
        },
        shortDescription: {
            validations: [NOT_BLANK],
        },
        collectionPointId: {
            validations: [NOT_BLANK],
        },
    },
    ORDER_LINE_FORM: {
        productId: {
            validations: [NOT_BLANK],
        },
        orderNif: {
            validations: [NOT_BLANK, SIZE_EXACT],
            size: 9,
        },
    },
    CLAIMANT_FORM: {
        name: {
            validations: [NOT_BLANK],
        },
        email: {
            validations: [NOT_BLANK],
        },
        nif: {
            validations: [NOT_BLANK, SIZE_EXACT],
            size: 9,
        },
        contact: {
            validations: [NOT_BLANK],
        },
    },
    CHECKOUT: {
        deliveryMethod: {
            validations: [NOT_BLANK],
        },
        name: {
            validations: [NOT_BLANK],
        },
        surname: {
            validations: [NOT_BLANK],
        },
        address: {
            validations: [NOT_BLANK],
        },
        contact: {
            validations: [NOT_BLANK],
        },
        postalCode: {
            validations: [NOT_BLANK, REGEX],
            regex: postalCodeRegex,
        },
        city: {
            validations: [NOT_BLANK],
        },
        mbWayContact: {
            validations: [NOT_BLANK, REGEX],
            regex: phoneRegex,
        },
        latitude: {
            validations: [NOT_BLANK, NOT_EMPTY, REGEX, MIN, MAX],
            regex: latLongNumberRegex,
            min: -90,
            max: 90,
        },
        longitude: {
            validations: [NOT_BLANK, NOT_EMPTY, REGEX, MIN, MAX],
            regex: latLongNumberRegex,
            min: -90,
            max: 90,
        },
    },
    BILLING_ADDRESS: {
        billingAddressName: {
            validations: [NOT_BLANK],
        },
        billingAddress: {
            validations: [NOT_BLANK],
        },
        billingAddressPhone: {
            validations: [NOT_BLANK],
        },
        billingAddressPostalCode: {
            validations: [NOT_BLANK, REGEX],
            regex: postalCodeRegex,
        },
        billingAddressCity: {
            validations: [NOT_BLANK],
        },
    },
    USER_ADDRESS: {
        name: {
            validations: [NOT_BLANK],
        },
        address: {
            validations: [NOT_BLANK],
        },
        phone: {
            validations: [NOT_BLANK],
        },
        postalCode: {
            validations: [NOT_BLANK, REGEX],
            regex: postalCodeRegex,
        },
        city: {
            validations: [NOT_BLANK],
        },
    },
    USER_PROFILE: {
        name: {
            validations: [NOT_BLANK],
        },
        address: {
            validations: [NOT_BLANK],
        },
        city: {
            validations: [NOT_BLANK],
        },
        contact: {
            validations: [NOT_BLANK],
        },
        postalCode: {
            validations: [NOT_BLANK, REGEX],
            regex: postalCodeRegex,
        },
    },
    PAYMENT_FEES: {
        fee: {
            validations: [REGEX],
            regex: decimalNumberRegex,
        },
        feePercentage: {
            validations: [REGEX],
            regex: decimalNumberRegex,
        },
        maxFee: {
            validations: [REGEX],
            regex: decimalNumberRegex,
        },
        minFee: {
            validations: [REGEX],
            regex: decimalNumberRegex,
        },
    },
    RAFFLE: {
        raffle: {
            validations: [REGEX, SIZE_EXACT],
            regex: alphaNumericRegex,
            size: 6,
        },
    },
    WHATSAPP_FORM: {
        contact: {
            validations: [NOT_BLANK, REGEX],
            regex: integerRegex,
        },
    },
};

export const validateField = (fieldName: string, fieldValue: any, formValidations: { [key: string]: any }) => {
    let errors: IError[] | null = null;
    let isFilled = true;

    if (!formValidations[fieldName]) return errors;

    const {
        validations, regex, max, min, size,
    } = formValidations[fieldName];

    if (validations.includes(NOT_BLANK)) {
        if (fieldValue === null || fieldValue === undefined || fieldValue.toString().trim() === '') {
            errors = [{ typeOfViolation: 'NotBlank' }];
            isFilled = false;
        }
    } else if (validations.includes(NOT_EMPTY)) {
        if (Array.isArray(fieldValue) && fieldValue.length === 0) {
            errors = [{ typeOfViolation: 'NotNull' }];
            isFilled = false;
        }
    } else if (validations.includes(NOT_ZERO)) {
        if (fieldValue === 0) {
            errors = [{ typeOfViolation: 'NotBlank' }];
            isFilled = false;
        }
    }

    if (isFilled) {
        if (validations.includes(MAX)) {
            if (fieldValue && fieldValue > max) {
                errors = [
                    {
                        typeOfViolation: 'Max',
                        max,
                    },
                ];
            }
        }

        if (validations.includes(REGEX)) {
            if (fieldValue && String(fieldValue) !== '' && !regex.test(fieldValue)) {
                errors = [{ typeOfViolation: 'Pattern' }];
            }
        }

        if (validations.includes(MIN)) {
            if (fieldValue !== undefined && Number(fieldValue) < min) {
                errors = [
                    {
                        typeOfViolation: 'Min',
                        min,
                    },
                ];
            }
        }

        if (validations.includes(MIN_LENGTH)) {
            if (fieldValue !== undefined && String(fieldValue).length < min) {
                errors = [
                    {
                        typeOfViolation: 'MinLength',
                        min,
                    },
                ];
            }
        }

        if (validations.includes(MAX_LENGTH)) {
            if (fieldValue !== undefined && String(fieldValue).length > max) {
                errors = [
                    {
                        typeOfViolation: 'MaxLength',
                        max,
                    },
                ];
            }
        }

        if (validations.includes(SIZE_EXACT)) {
            if (fieldValue !== undefined && String(fieldValue).length !== size) {
                errors = [
                    {
                        typeOfViolation: 'SizeExact',
                        size,
                    },
                ];
            }
        }

        if (validations.includes(DIMENSIONS)) {
            if (fieldValue && String(fieldValue) !== '' && !regex.test(fieldValue)) {
                errors = [{ typeOfViolation: 'Integer' }];
            }
        }

        if (validations.includes(ALPHANUMERIC)) {
            if (fieldValue && String(fieldValue) !== '' && !regex.test(fieldValue)) {
                errors = [{ typeOfViolation: 'SpecialChars' }];
            }
        }
        if (validations.includes(WHITESPACE)) {
            if (fieldValue && String(fieldValue) !== '' && String(fieldValue).includes(' ')) {
                errors = [{ typeOfViolation: 'Whitespace' }];
            }
        }
    }

    return errors;
};

export const getFormErrors: Function = (data: { [key: string]: any }, formValidations: { [key: string]: any }) => {
    let errors: IFormError | null = {};

    Object.keys(data).forEach(field => {
        const { [field]: fieldValue } = data;

        if (field in formValidations) {
            const { manual } = formValidations[field];

            if (!manual && errors) {
                errors[field] = validateField(
                    field,
                    fieldValue,
                    formValidations,
                );

                if (!errors[field]) delete errors[field];
            }
        }
    });

    if (Object.keys(errors).length === 0) errors = null;
    return errors;
};

/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

export enum csvUploadErrorCodes {
    CATEGORY_NOT_FOUND = 13,
    PROMOTION_TOO_LONG = 14,
    DISCOUNT_PRICE_IS_EQUAL_TO_NORMAL_PRICE = 15,
    DISCOUNT_PRICE_IS_HIGHER_THAN_NORMAL_PRICE = 16,
    STORE_NOT_FOUND = 17,
}

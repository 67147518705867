/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { ScrollMemoryState } from './types';
import { ScrollMemoryAction } from '../actions/scroll_types';

/**
 * @typedef {ScrollMemoryState} scrollMemoryInitialState
 */

export const scrollMemoryInitialState : ScrollMemoryState = {
    productsScrollMemory: {
        coordinate: 0,
        location: '',
        page: 0,
    },
    storesScrollMemory: {
        coordinate: 0,
        location: '',
        page: 0,
    },
};

/**
 * scroll memory reducer
 * @param {ScrollMemoryState} state
 * @param {any} action
 * @returns {ScrollMemoryState}
 */

export default function (state = scrollMemoryInitialState, action: any): ScrollMemoryState {
    switch (action.type) {
        case ScrollMemoryAction.SET_SCROLL_MEMORY_PRODUCTS:
            return {
                ...state,
                productsScrollMemory: {
                    coordinate: action.payload.coordinate,
                    location: action.payload.location,
                    page: action.payload.page,
                },
            };
        case ScrollMemoryAction.CLEAR_SCROLL_MEMORY_PRODUCTS:
            return {
                ...state,
                productsScrollMemory: {
                    ...scrollMemoryInitialState.productsScrollMemory,
                },
            };
        case ScrollMemoryAction.SET_SCROLL_MEMORY_STORES:
            return {
                ...state,
                storesScrollMemory: {
                    coordinate: action.payload.coordinate,
                    location: action.payload.location,
                    page: action.payload.page,
                },
            };
        case ScrollMemoryAction.CLEAR_SCROLL_MEMORY_STORES:
            return {
                ...state,
                storesScrollMemory: {
                    ...scrollMemoryInitialState.storesScrollMemory,
                },
            };
        default:
            return state;
    }
}

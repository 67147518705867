/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { get } from 'lodash';
import { RouteComponentProps, NavLink, matchPath } from 'react-router-dom';

import { ReactCookieProps, withCookies } from 'react-cookie';
import MediaQuery from 'react-responsive';
import registerIcon from '../../assets/images/ic-criar-conta.svg';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { LoginFormFields } from '../../constants/authentication';
import Button from '../elements/Button';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';
import { MatchParams } from '../../constants/misc';
import {
    ACCOUNT_ACTIVATION_ROUTE,
    ADMIN_ROUTE,
    CHECKOUT_ROUTE,
    CUSTOMER_ADMIN_ROUTE,
    INDEX_ROUTE,
    RECOVER_PASSWORD_ROUTE,
    REGISTER_GUEST_ROUTE,
    REGISTER_ROUTE,
    RESET_TOKEN_ROUTE,
    STORE_ADMIN_ROUTE,
} from '../../constants/routes';
import FormTextField from '../elements/FormTextField';
import ErrorMessage, { ERROR_MESSAGE_TYPE } from '../elements/ErrorMessage';
import Loader from '../elements/Loader';
import { CartContext, withCartContext } from '../controllers/cart/CartContext';
import { UserRoles } from '../../constants/authorization';
import { scrollToTop } from '../../utils/misc';

interface OwnProps extends RouteComponentProps<MatchParams>, AuthenticationContext, TranslationContext, ReactCookieProps, CartContext {
    userEmail?: string;
}

interface OwnState {
    fields: LoginFormFields;
    formErrors: any;
    isRedirect: boolean;
    previousRoute: string;
    previousSearch: string;
}

const initialState : OwnState = {
    fields: {
        email: '',
        password: '',
        cartId: '',
    },
    formErrors: null,
    isRedirect: false,
    previousRoute: '',
    previousSearch: '',
};

class LoginScreen extends Component<OwnProps, OwnState> {
    state = {
        ...initialState,
    };

    componentDidMount(): void {
        const prevState = this.state;
        const { isAuthenticated, logout, location } = this.props;

        setTimeout(() => {
            scrollToTop();
        },
        100);

        if (isAuthenticated) {
            logout();
        }

        if (location && location.state) {
            const locationState = { ...location.state as { isRedirect: boolean } };
            const previousLocation = { ...location.state as { from: { pathname: string, search : string}} };
            const emailState = { ...location.state as { userEmail: string } };

            this.setState({
                ...prevState,
                isRedirect: locationState.isRedirect,
                previousRoute: previousLocation.from.pathname,
                previousSearch: previousLocation.from.search,
                fields: {
                    ...prevState.fields,
                    email: emailState.userEmail === '' ? prevState.fields.email : emailState.userEmail,
                },
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any): void {
        const { formErrors: prevFormErrors } = prevProps;
        const { formErrors } = this.props;

        if (formErrors !== prevFormErrors) {
            this.setState({ formErrors: { ...formErrors } });
        }
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            fields: {
                ...this.state.fields,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        const { login, cartId, fetchCart } = this.props;
        e.preventDefault();

        if (this.validateLogin()) {
            const { fields } = this.state;

            login({
                email: fields.email,
                password: fields.password,
                cartId,
            }, async () => {
                fetchCart(() => {
                    this.redirectUser();
                });
            });
        }
    };

    redirectUser = () => {
        const { isRedirect, previousRoute, previousSearch } = this.state;
        const {
            history,
            user,
            t,
        } = this.props;
        if (matchPath(previousRoute, { path: `${ACCOUNT_ACTIVATION_ROUTE}/:token` }) || !isRedirect) {
            return history.push(`${INDEX_ROUTE}#${t('login.homePage')}`);
        }
        
        if (this.isBackofficeRoute()) {
            if (user && user.groups) {
                switch (user.groups[0]) {
                    case UserRoles.CUSTOMER:
                        return history.push(CUSTOMER_ADMIN_ROUTE);
                    case UserRoles.ADMIN:
                        return history.push(ADMIN_ROUTE);
                    case UserRoles.STORE_MANAGER:
                        return history.push(STORE_ADMIN_ROUTE);
                    default:
                        return history.goBack();
                }
            } else {
                return history.goBack();
            }
        } else if (previousRoute === REGISTER_GUEST_ROUTE) {
            return history.push(CHECKOUT_ROUTE);
        }

        return history.push(`${previousRoute}${previousSearch}`);
    }

    isBackofficeRoute = ():boolean => {
        const { previousRoute } = this.state;

        return previousRoute === ADMIN_ROUTE
            || previousRoute === CUSTOMER_ADMIN_ROUTE
            || previousRoute === STORE_ADMIN_ROUTE;
    }

    validateLogin = (): boolean => {
        let errors: IFormError | null = getFormErrors(this.state.fields, VALIDATIONS.LOGIN_FORM);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    redirectToRegister = (): void => {
        const { history } = this.props;
        history.push(REGISTER_ROUTE);
    }

    redirectToGuestRegister = (): void => {
        const { history } = this.props;
        history.push(REGISTER_GUEST_ROUTE);
    }

    render() {
        const { t, isFetching } = this.props;
        const {
            fields, formErrors, previousRoute, isRedirect,
        } = this.state;

        const isRedirectRoute = (previousRoute === CHECKOUT_ROUTE || previousRoute === REGISTER_GUEST_ROUTE);

        return (
            <div className="container-fluid app_screen">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="app_screen__centered margin-top-bar">
                    {isRedirect && isRedirectRoute && <p>{t('login.finishPurchase')}</p>}
                    <div className="page-title">
                        {t('login.hasAccount')}
                    </div>
                    <form className="login-form-content" onSubmit={this.onSubmit}>
                        <FormTextField
                            name="email"
                            value={fields.email}
                            placeholder={`${t('login.labels.username')}*`}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.email', null)}
                        />
                        <FormTextField
                            name="password"
                            value={fields.password}
                            type="password"
                            placeholder={`${t('login.labels.password')}*`}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.password', null)}
                        />
                        <div className="form-error-container">
                            <ErrorMessage
                                type={ERROR_MESSAGE_TYPE.LOGIC}
                                errors={formErrors && formErrors.result}
                                linkTo={formErrors && formErrors.result === t('errors.NotValidated') ? RESET_TOKEN_ROUTE : undefined}
                            />
                        </div>
                        <div className="align-left">
                            <NavLink to={RECOVER_PASSWORD_ROUTE}>{t('login.forgotPassword')}</NavLink>
                        </div>
                        <MediaQuery maxWidth={768}>
                            <div className="login-form-content__button-container">
                                <button type="submit" className="button button--dark-blue button--big">
                                    {t('login.loginBtn')}
                                </button>
                                {isRedirect && isRedirectRoute && (
                                    <>
                                        <div className="login-form-content__button-container__divider">
                                            <hr />
                                            <p>OU</p>
                                            <hr />
                                        </div>
                                        <Button
                                            type="button"
                                            text={t('login.guestLogin')}
                                            styles="button--blue button--big"
                                            callback={this.redirectToGuestRegister}
                                        />
                                    </>
                                )}
                            </div>
                        </MediaQuery>
                        <MediaQuery minWidth={769}>
                            <div className="login-form-content__button-container">
                                <button type="submit" className="button button--dark-blue">
                                    { t('login.loginBtn')}
                                </button>
                                {isRedirect && isRedirectRoute && (
                                    <>
                                        <div className="login-form-content__button-container__divider">
                                            <hr />
                                            <p>OU</p>
                                            <hr />
                                        </div>
                                        <Button
                                            type="button"
                                            text={t('login.guestLogin')}
                                            styles="button--dark-blue button--big"
                                            callback={this.redirectToGuestRegister}
                                        />
                                    </>
                                )}
                            </div>
                        </MediaQuery>
                    </form>
                    <div className="login-form-register">
                        <img src={registerIcon} alt="" />
                        <p className="login-form-register__line1">{t('login.noAccount')}</p>
                        <p className="login-form-register__line2">{t('login.registerHere')}</p>
                        <Button
                            type="button"
                            text={t('login.registerBtn')}
                            styles="button--dark-blue"
                            callback={this.redirectToRegister}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(withCookies(withCartContext(LoginScreen))));

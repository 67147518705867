/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import Col from 'react-bootstrap/Col';
import { get } from 'lodash';

import moment from 'moment';
import FormTextField from '../../elements/FormTextField';
import { ScheduleItem, WhatsAppScheduleItem } from '../../../constants/types';
import { generateId } from '../../../utils/misc';
import StoreSchedule from '../../elements/StoreSchedule';
import Button from '../../elements/Button';
import { validateHasEmptySchedule, validateHasOverlappingSchedules, validateScheduleOpensBeforeCloses } from '../../../utils/schedules';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { helpdeskSchedulesURL, whatsAppNumberConfigURL, whatsAppNumberGetURL } from '../../../services/admin';
import Loader from '../../elements/Loader';
import { validateField, VALIDATIONS } from '../../../utils/validation';

type OwnProps = TranslationContext

interface OwnState {
    formErrors: any;
    isFetching: boolean,
    showSaveChanges: boolean;
    number: string;
    weekSchedule: Array<ScheduleItem>;
    newWeekSchedules: Array<ScheduleItem>;
    weekSchedulesToDelete: Array<ScheduleItem>;
}

const initialState: OwnState = {
    formErrors: null,
    isFetching: false,
    showSaveChanges: false,
    number: '',
    weekSchedule: [],
    newWeekSchedules: [],
    weekSchedulesToDelete: [],
};

class WhatsAppConfig extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    componentDidMount() {
        this.prepare();
    }

    prepare = () => {
        const urls = [helpdeskSchedulesURL(), whatsAppNumberGetURL()];
        this.setState({
            isFetching: true,
        });

        axios.all(urls.map(el => { return axios.get(el); }))
            .then(axios.spread((schedulesResponse, numberResponse) => {
                this.setState({
                    number: numberResponse.data.value,
                    weekSchedule: schedulesResponse.data.map((el: WhatsAppScheduleItem) => {
                        const dayAux = Number(el.day) + 1;
                        return {
                            id: el.id,
                            openOn: el.openOn ? moment(el.openOn, 'HH:mm:ss').unix() * 1000 : '',
                            closeOn: el.closeOn ? moment(el.closeOn, 'HH:mm:ss').unix() * 1000 : '',
                            initialWeekDay: dayAux,
                            endWeekDay: dayAux,
                        };
                    }),
                    isFetching: false,
                });
            }))
            .catch(() => {
                this.setState({
                    isFetching: false,
                });
            });
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length <= 9 && /\d*/.test(e.currentTarget.value)) {
            this.setState({
                number: e.currentTarget.value,
                showSaveChanges: true,
            });
        }
    };

    onAddSchedule = () => {
        const { weekSchedule, newWeekSchedules } = this.state;
        const id = generateId();

        const hasEmptySchedule: boolean = validateHasEmptySchedule(weekSchedule);
        const currentNumberOfSchedules = weekSchedule.length + newWeekSchedules.length;

        if (!hasEmptySchedule && currentNumberOfSchedules < 14) {
            const newSchedule = {
                id,
                initialWeekDay: '',
                endWeekDay: '',
                openOn: '',
                closeOn: '',
                isInterval: false,
            };

            newWeekSchedules.push(newSchedule);

            this.setState({
                newWeekSchedules: [...newWeekSchedules],
            });
        }
    };

    onScheduleRemove = (item: ScheduleItem) => {
        const { newWeekSchedules, weekSchedule, weekSchedulesToDelete } = this.state;

        const indexInNewSchedules = newWeekSchedules.indexOf(item);
        const indexInExistingSchedules = weekSchedule.indexOf(item);

        if (indexInExistingSchedules !== -1 && indexInNewSchedules === -1) {
            const newSchedule: Array<ScheduleItem> = weekSchedule.filter(el => item.id !== el.id);
            weekSchedulesToDelete.push(item);

            this.setState({
                weekSchedule: [...newSchedule],
                weekSchedulesToDelete,
            });
        } else if (indexInExistingSchedules === -1 && indexInNewSchedules !== -1) {
            const newSchedule: Array<ScheduleItem> = newWeekSchedules.filter(el => item.id !== el.id);
            this.setState({
                newWeekSchedules: newSchedule,
            });
        }

        this.setState({
            showSaveChanges: true,
        });
    };

    onScheduleChange = (schedule: ScheduleItem) => {
        const { weekSchedule, newWeekSchedules } = this.state;

        const weekScheduleIndex = weekSchedule.findIndex(s => {
            return s.id.toString().localeCompare(schedule.id.toString()) === 0;
        });
        const newScheduleIndex = newWeekSchedules.findIndex(s => {
            return s.id.toString().localeCompare(schedule.id.toString()) === 0;
        });

        if (weekScheduleIndex !== -1) {
            weekSchedule[weekScheduleIndex] = {
                ...schedule,
                initialWeekDay: Number(schedule.initialWeekDay),
                endWeekDay: Number(schedule.endWeekDay),
            };

            this.setState({
                showSaveChanges: true,
                weekSchedule: [...weekSchedule],
            });
        } else if (newScheduleIndex !== -1) {
            newWeekSchedules[newScheduleIndex] = {
                ...schedule,
                initialWeekDay: Number(schedule.initialWeekDay),
                endWeekDay: Number(schedule.endWeekDay),
            };

            this.setState({
                showSaveChanges: true,
                newWeekSchedules: [...newWeekSchedules],
            });
        }
    };

    onSaveChanges = () => {
        const { t } = this.props;
        const {
            weekSchedule,
            newWeekSchedules,
            weekSchedulesToDelete,
            number: whatsapp,
        } = this.state;

        if (validateField('contact', whatsapp, VALIDATIONS.WHATSAPP_FORM)) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.whatsApp.messages.invalidNumber'));
            return;
        }

        if (validateHasEmptySchedule(weekSchedule)) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.emptyScheduleError'));
            return;
        }

        if (validateHasOverlappingSchedules(weekSchedule) || validateHasOverlappingSchedules(newWeekSchedules)) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.scheduleOverlapError'));
            return;
        }
                
        if (validateScheduleOpensBeforeCloses(weekSchedule) || validateScheduleOpensBeforeCloses(newWeekSchedules)) {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.scheduleOpensBeforeCloses'));
            return;
        }
        this.setState({
            isFetching: true,
        });

        const requests = [
            axios.put(whatsAppNumberConfigURL(), { whatsapp }),
            ...weekSchedule.map(el => {
                return axios.put(helpdeskSchedulesURL(el.id), {
                    openOn: el.openOn ? moment.unix(Number(el.openOn) / 1000).format('HH:mm:ss') : '',
                    closeOn: el.closeOn ? moment.unix(Number(el.closeOn) / 1000).format('HH:mm:ss') : '',
                    day: Number(el.initialWeekDay) - 1,
                });
            }),
            ...weekSchedulesToDelete.map(el => { return axios.delete(helpdeskSchedulesURL(el.id)); }),
            ...newWeekSchedules.map(el => {
                return axios.post(helpdeskSchedulesURL(),
                    {
                        openOn: el.openOn ? moment.unix(Number(el.openOn) / 1000).format('HH:mm:ss') : '',
                        closeOn: el.closeOn ? moment.unix(Number(el.closeOn) / 1000).format('HH:mm:ss') : '',
                        day: Number(el.initialWeekDay) - 1,
                    });
            }),
        ];

        axios.all(requests).then(() => {
            displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.whatsApp.messages.success'));
            this.setState({
                weekSchedulesToDelete: [],
                newWeekSchedules: [],
            }, this.prepare);
        }).catch(() => {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.whatsApp.messages.error'));
        }).finally(() => {
            this.setState({
                isFetching: false,
                showSaveChanges: false,
            });
        });
    };

    render() {
        const { t } = this.props;
        const {
            number,
            weekSchedule,
            newWeekSchedules,
            formErrors,
            showSaveChanges,
            isFetching,
        } = this.state;

        const allSchedules: Array<ScheduleItem> = [...weekSchedule, ...newWeekSchedules];

        return (
            <div className="app-tabs__tab-content store-admin">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="form-centered">
                    <Row>
                        <Col lg={4} xs={4}>
                            <FormTextField
                                name="number"
                                value={number}
                                type="number"
                                label={t('customerAdmin.profile.labels.contact')}
                                placeholder={t('customerAdmin.profile.labels.contact')}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.name', null)}
                            />
                        </Col>
                    </Row>
                    {Object.keys(allSchedules).map(key => {
                        const s = allSchedules[Number(key)];
                        return (
                            <StoreSchedule
                                key={s.id}
                                schedule={s}
                                onScheduleChange={this.onScheduleChange}
                                errors={get(formErrors, `fields.${s.id}`, null)}
                                onRemove={() => this.onScheduleRemove(s)}
                            />
                        );
                    })}
                    <Row className="show-grid">
                        {weekSchedule.length < 14 && (
                            <Col xs={12} md={12} className="schedule-add-btn-wrapper">
                                <Button
                                    text={t('storeAdmin.editStore.labels.addSchedule')}
                                    styles="button--blue button--smaller"
                                    callback={this.onAddSchedule}
                                />
                            </Col>
                        )}
                    </Row>
                    <hr className="divider" />
                    <div className="row justify-content-end buttons-container">
                        <div className="button-wrapper">
                            <Button
                                text={t('storeAdmin.editStore.saveChanges')}
                                callback={this.onSaveChanges}
                                styles="button--dark-blue"
                                disabled={!showSaveChanges}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(WhatsAppConfig);

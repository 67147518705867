/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { CartContextProvider } from './CartContext';
import { AppState } from '../../../reducers/types';
import {
    DeliveryMethods,
    ShoppingCart, ShoppingLine, StoreCart,
} from '../../../constants/types';
import { resetCartAction, setCartIdAction, updateCartAction } from '../../../actions/cart';
import { shoppingCartURL, updateCartProductURL } from '../../../services/cart';
import { checkProductWithinPromotion, generateId } from '../../../utils/misc';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { TranslationContext, withTranslationContext } from '../translation/TranslationContext';

interface StateProps {
    cartId: string | null;
    shoppingCart: ShoppingCart | null;
}

interface OwnProps {
    children: any;
}

interface DispatchProps {
    setCartId: Function;
    updateCart: Function;
    resetCart: Function;
}

type Props = StateProps & DispatchProps & OwnProps & TranslationContext;

interface OwnState {}

type State = OwnState;

export class CartController extends Component<Props, State> {
    generateCartId = async () : Promise<string> => {
        const { setCartId, cartId } = this.props;
        if (!cartId) {
            const token = uuidv4();
            setCartId(token);
            return token;
        }
        return cartId;
    }

    addToCart = async (product: number, quantity: number) => {
        await this.generateCartId();
        const { cartId, t } = this.props;
        await axios.post(shoppingCartURL(), { cartId, product, quantity }).then(() => {
            this.fetchCart();
        }).catch(() => {
            displayNotification(NOTIFICATION_TYPE.ERROR, t('errors.productDisabled'));
        });
    }

    fetchCart = async (callback?: () => void) => {
        await this.generateCartId();
        const { cartId, t } = this.props;
        
        const { data, status } = await axios.get(shoppingCartURL({ cartId }));

        if (status === 200) {
            if (data) {
                let total = 0;
                let productsCounter = 0;

                const storeCartsAux : StoreCart[] = [];

                data.forEach(async (line: any) => {
                    const { product } = line;
                    if (!product.disabled) {
                        const indexAux = storeCartsAux.findIndex(el => {
                            return el.storeId === product.storeInfo.id;
                        });
    
                        const productPrice = checkProductWithinPromotion(product) ? product.discountPrice : product.price;
                        const auxDeliveryMethod = product.availableDeliveryMethods[0]?.value ? product.availableDeliveryMethods[0]?.value : '';
                        const shoppingLineAux: ShoppingLine = {
                            availableDeliveryMethods: product.availableDeliveryMethods,
                            deliveryMethod: product.voucher ? DeliveryMethods.EMAIL : auxDeliveryMethod,
                            id: line.id,
                            productId: product.id,
                            productName: product.shortDescription,
                            productPrice,
                            productThumbnailUrl: product.thumbnailUrl,
                            productColor: product.colorHex,
                            productSize: product.size,
                            quantity: line.quantity,
                            subtotal: productPrice * line.quantity,
                            inStock: product.inStock,
                            isVoucher: product.voucher,
                            voucherBonus: line.voucherBonus,
                            bonusPerVoucher: product.voucherBonus,
                            storeInfo: product.storeInfo,
                        };
    
                        if (indexAux !== -1) {
                            storeCartsAux[indexAux].shoppingLines.push(shoppingLineAux);
                            productsCounter += line.quantity;
                            total += shoppingLineAux.subtotal;
                        } else {
                            const StoreCartAux: StoreCart = {
                                id: generateId(),
                                shoppingLines: [shoppingLineAux],
                                storeDeliveryMethods: product.availableDeliveryMethods,
                                storeId: product.storeInfo.id,
                                storeName: product.storeInfo.name,
                            };
                            storeCartsAux.push(StoreCartAux);
                            productsCounter += line.quantity;
                            total += shoppingLineAux.subtotal;
                        }
                    } else {
                        await this.removeProduct(line.id);
                        displayNotification(NOTIFICATION_TYPE.ERROR, t('errors.productNotAvailable', { name: product.shortDescription }));
                    }
                });

                const shoppingCartAux: ShoppingCart = {
                    productsCounter,
                    storeCarts: storeCartsAux,
                    total,
                };
                this.updateShoppingCart(shoppingCartAux);
            }
            if (callback) {
                callback();
            }
        }
    }

    removeProduct = async (lineId: string) => {
        const { shoppingCart, cartId } = this.props;
        await axios.delete(updateCartProductURL(lineId, { cartId })).then(() => {
            if (shoppingCart) {
                this.fetchCart();
            }
        }).catch(() => {});
    }

    updateProductServerInfo = async (lineId: string, product: number, quantity: number) => {
        const { shoppingCart, cartId } = this.props;
        const { data, status } = await axios.put(updateCartProductURL(lineId), { cartId, product, quantity });

        if (shoppingCart) {
            if (status === 200) {
                if (data) {
                    this.fetchCart();
                }
            }
        }
    }

    updateShoppingCart = (shoppingCart: ShoppingCart | null) => {
        const { updateCart } = this.props;
        updateCart(shoppingCart);
    }

    resetCart = () => {
        const { resetCart } = this.props;
        resetCart();
    }

    render() {
        const {
            children,
            shoppingCart,
            cartId,
        } = this.props;

        return (
            <CartContextProvider
                value={{
                    shoppingCart,
                    cartId,
                    generateCartId: this.generateCartId,
                    fetchCart: this.fetchCart,
                    addToCart: this.addToCart,
                    updateProduct: this.updateProductServerInfo,
                    removeProduct: this.removeProduct,
                    updateCart: this.updateShoppingCart,
                    resetCart: this.resetCart,
                }}
            >
                {children}
            </CartContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        cartId: state.cart.cartId,
        shoppingCart: state.cart.shoppingCart,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    setCartId: (cartId: string) => dispatch(setCartIdAction(cartId)),
    updateCart: (shoppingCart: ShoppingCart) => dispatch(updateCartAction(shoppingCart)),
    resetCart: () => dispatch(resetCartAction()),
});

export const ConnectedCartController = connect(mapStateToProps, mapDispatchToProps)(withTranslationContext(CartController));

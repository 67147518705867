/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { RolePermission } from '../constants/authorization';

/**
 * permission operators
 * @enum {string}
 */
export enum PermissionOperators {
    OR = 'OR',
    AND = 'AND',
}

/**
 * checks if the role has permission for the action
 * @param {RolePermission[]} perms
 * @param {Array<string>} actions
 * @param {string} operator
 * @returns {boolean}
 */
export const checkPermission = (perms: RolePermission[], actions: Array<string>, operator: string = PermissionOperators.OR): boolean => {
    let res = false;
    let found = 0;

    if (perms) {
        if (operator === PermissionOperators.OR) {
            actions.forEach((action: string) => {
                perms.forEach((perm: RolePermission) => {
                    if (perm.key === action) {
                        res = true;
                    }
                });
            });
        } else if (operator === PermissionOperators.AND) {
            actions.forEach((action: string) => {
                perms.forEach((perm: RolePermission) => {
                    if (perm.key === action) {
                        found++;
                    }
                });
            });
            if (found === actions.length) res = true;
        }
    }

    return res;
};

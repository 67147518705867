/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedTranslationController } from './TranslationController';

/**
 * @typedef {Object} TranslationContext
 * @property {Language} language
 * @property {function} t
 */
export interface TranslationContext {
    language: string;
    t(literal: string, params?: object): string;
}

// Create the context

export const translationContextDefaultValue = {
    language: 'pt',
    t: () => '',
};

const TranslationContext = createContext<TranslationContext | null>(translationContextDefaultValue);

// Export Provider and Consumer

export const TranslationContextProvider = TranslationContext.Provider;
export const TranslationContextConsumer = TranslationContext.Consumer;

/**
 * provides a context HOC that components can use to get translations
 * @param Component
 * @returns {React.FC}
 */
export const withTranslationContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof TranslationContext>> => props => (
    <ConnectedTranslationController>
        <TranslationContextConsumer>
            {ctx => <Component {...props as P} {...ctx} />}
        </TranslationContextConsumer>
    </ConnectedTranslationController>
    );

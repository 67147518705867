/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { AppState } from '../../../reducers/types';
import { setLastSeenProducts } from '../../../actions/general';
import { GeneralContextProvider } from './GeneralContext';

interface StateProps {
    lastSeenProducts: Array<number>;
}

interface OwnProps {
    children: any;
}

interface DispatchProps {
    setLastSeenProducts: Function;
}

type Props = StateProps & DispatchProps & OwnProps;

interface OwnState {}

type State = OwnState;

export class GeneralController extends Component<Props, State> {
    addToLastSeenProducts = (productId: number) => {
        const { setLastSeenProducts, lastSeenProducts } = this.props;

        if (!lastSeenProducts.includes(productId)) {
            if (lastSeenProducts.length < 10) {
                lastSeenProducts.unshift(productId);
            } else {
                lastSeenProducts.splice(lastSeenProducts.length - 1, 1);
                lastSeenProducts.unshift(productId);
            }

            setLastSeenProducts([...lastSeenProducts]);
        } else {
            const index = lastSeenProducts.findIndex(p => p === productId);
            lastSeenProducts.splice(index, 1);
            lastSeenProducts.unshift(productId);
            setLastSeenProducts([...lastSeenProducts]);
        }
    }

    render() {
        const {
            children,
            lastSeenProducts,
        } = this.props;

        return (
            <GeneralContextProvider
                value={{
                    lastSeenProducts,
                    addToLastSeenProducts: this.addToLastSeenProducts,
                }}
            >
                {children}
            </GeneralContextProvider>
        );
    }
}

const mapStateToProps = (state: AppState): StateProps => {
    return {
        lastSeenProducts: state.general.lastSeenProducts,
    };
};

export const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
    setLastSeenProducts: (lastSeenProducts: Array<number>) => dispatch(setLastSeenProducts(lastSeenProducts)),
});

export const ConnectedGeneralController = connect(mapStateToProps, mapDispatchToProps)(GeneralController);

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { Product, Store } from '../constants/types';
import { PRODUCT_VIEW_ROUTE, STORE_VIEW_ROUTE } from '../constants/routes';

export const formatNamesForUrl = (name: string): string => {
    return name.normalize('NFD').toLowerCase().replace(/[\u0300-\u036f]/g, '').replace(/\s/g, '-')
        .replace(/---/, '-')
        .replace(/,g/, '')
        .replace(/\//g, '');
};

export const getFormattedProductURL = (product: Product): string => {
    return `/${product.storeInfo.slug}${PRODUCT_VIEW_ROUTE}/${encodeURIComponent(formatNamesForUrl(String(product.category?.description)))}/${encodeURIComponent(formatNamesForUrl(product.shortDescription))}-${product.id}`;
};

export const getFormattedStoreURL = (store: Store): string => {
    return `${STORE_VIEW_ROUTE}/${encodeURIComponent(String(store.slug))}`;
};

export const getTrueID = (nameAndId: string): string => {
    return nameAndId.substr(nameAndId.lastIndexOf('-') + 1);
};

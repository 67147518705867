/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export const INDEX_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/registar';
export const REGISTER_GUEST_ROUTE = '/registar-convidado';
export const NOT_FOUND_ROUTE = '/404';
export const ADMIN_ROUTE = '/administracao';
export const STORE_ADMIN_ROUTE = '/gestao-loja';
export const CUSTOMER_ADMIN_ROUTE = '/area-cliente';
export const CHECKOUT_SUCCESS_ROUTE = '/checkout-sucesso';
export const ALL_STORES_ROUTE = '/lojas';
export const STORE_VIEW_ROUTE = '/loja';
export const ALL_PRODUCTS_VIEW_ROUTE = '/produtos';
export const CHECKOUT_ROUTE = '/checkout';
export const CART_ROUTE = '/carrinho-de-compras';
export const TERMS_AND_CONDITIONS_OF_USE_ROUTE = '/termos-e-condicoes/termos-de-utilizacao';
export const PRIVACY_POLICY_ROUTE = '/termos-e-condicoes/politica-privacidade-dados-pessoais';
export const COOKIES_POLICY_ROUTE = '/termos-e-condicoes/politica-cookies';
export const FAQS_ROUTE = '/faqs';
export const SALE_CONDITIONS_ROUTE = '/condicoes-de-venda';
export const PRODUCT_VIEW_ROUTE = '/produto';
export const OLD_PRODUCT_VIEW_ROUTE = '/product';
export const STORES_CATEGORY_ROUTE = '/lojas-por-categoria';
export const RECOVER_PASSWORD_ROUTE = '/recuperar-password';
export const RESET_PASSWORD_ROUTE = '/repor-password';
export const RESET_TOKEN_ROUTE = '/reset-token';
export const JOIN_US_ROUTE = '/junte-se-a-nos';
export const ACCOUNT_ACTIVATION_ROUTE = '/ativacao-conta';
export const STORE_HISTORY_ROUTE = '/historia';
export const STORE_PRODUCTS_ROUTE = '/produtos';
export const STORE_CONTACTS_ROUTE = '/contactos';
export const RAFFLE_ROUTE = '/validar-rifas';

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { createContext, ComponentType, FC } from 'react';

import { ConnectedAuthenticationController } from './AuthenticationController';
import { AuthenticationUser, LoginFormFields } from '../../../constants/authentication';

/**
 * @typedef {Object} AuthenticationContext
 * @property {boolean} isAuthenticated
 * @property {boolean} isFetching
 * @property {Function} login
 * @property {string | null} token
 */
export interface AuthenticationContext {
    isAuthenticated: boolean;
    isFetching: boolean;
    user: AuthenticationUser | null,
    login(fields: LoginFormFields, callback: Function): void;
    logout(): void;
    setAuthentication(auth: object): void;
    activateAccount(token: string, cartId: string, onSuccess: Function, onFailure: Function): void;
    token: string | null;
    formErrors: object | null,
}

// Create the context

export const authenticationContextDefaultValue = {
    isAuthenticated: false,
    isFetching: false,
    login: () => {},
    logout: () => {},
    setAuthentication: () => {},
    activateAccount: () => {},
    user: null,
    token: null,
    formErrors: null,
};

const AuthenticationContext = createContext<AuthenticationContext | null>(authenticationContextDefaultValue);

// Export Provider and Consumer

export const AuthenticationContextProvider = AuthenticationContext.Provider;
export const AuthenticationContextConsumer = AuthenticationContext.Consumer;

/**
 * provides a context HOC that components can use to subscribe as consumers
 * @param Component
 * @returns {React.FC}
 */
export const withAuthenticationContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof AuthenticationContext>> => props => (
    <ConnectedAuthenticationController>
        <AuthenticationContextConsumer>
            {ctx => <Component {...props as P} {...ctx} />}
        </AuthenticationContextConsumer>
    </ConnectedAuthenticationController>
    );

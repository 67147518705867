/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import { get } from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ModalFooter from 'react-bootstrap/ModalFooter';

import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { PaymentFee } from '../../../constants/types';
import Loader from '../../elements/Loader';
import Button from '../../elements/Button';
import FormTextField from '../../elements/FormTextField';
import { getFormErrors, IFormError, VALIDATIONS } from '../../../utils/validation';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { PaymentMethods } from '../../../constants/misc';
import { paymentFees } from '../../../services/shopping';
import { numberToCurrency } from '../../../utils/misc';
import { feesAdmin } from '../../../services/admin';
import { BackofficeTooltip } from '../../elements/BackofficeTooltip';

type OwnProps = TranslationContext

interface OwnState {
    isFetching: boolean;
    fees: Array<PaymentFee>;
    showModal: boolean;
    formErrors: any;
    fee: PaymentFee;
}

const initialState: OwnState = {
    isFetching: false,
    fees: [],
    showModal: false,
    formErrors: null,
    fee: {
        id: -1,
        defaultFee: 0,
        fee: 0,
        feeVat: 0,
        feePercentage: 0,
        maxFee: 0,
        minFee: 0,
        paymentMethod: PaymentMethods.DEBIT_CARD,
        defaultMinFee: 0,
        defaultMaxFee: 0,
        defaultFeePercentage: 0,
    },
};

class PaymentFees extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    componentDidMount(): void {
        this.getFees();
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { name, value } = e.currentTarget;
        const { fee } = this.state;

        this.setState({
            fee: {
                ...fee,
                [name]: value,
            },
        });
    };

    onCloseModal = () => {
        this.setState({
            showModal: false,
            fee: {
                ...initialState.fee,
            },
            formErrors: null,
        });
    };

    onFeeClick = (fee: PaymentFee) => {
        this.setState({
            showModal: true,
            fee: {
                ...fee,
            },
        });
    };

    saveFee = () => {
        const { t } = this.props;
        const { fee } = this.state;

        if (this.validateFields()) {
            this.setState({
                isFetching: true,
            });

            const body = {
                fee: fee.fee,
                feePercentage: fee.feePercentage,
                maxFee: fee.maxFee,
                minFee: fee.minFee,
            };

            axios.put(feesAdmin(fee.id), body)
                .then(() => {
                    displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.paymentFees.messages.success'));
                    this.onCloseModal();
                })
                .catch(() => {
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.paymentFees.messages.error'));
                })
                .finally(() => {
                    this.setState({
                        isFetching: false,
                    }, () => {
                        this.getFees();
                    });
                });
        }
    };

    validateFields = (): boolean => {
        const { fee } = this.state;

        let errors: IFormError | null = getFormErrors(fee, VALIDATIONS.PAYMENT_FEES);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    getFees = async () => {
        const { isFetching } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        try {
            const { data } = await axios.get(paymentFees());

            this.setState({
                isFetching: false,
                fees: data,
            });
        } catch (e) {
            this.setState({ isFetching: false });
            displayNotification(NOTIFICATION_TYPE.ERROR, '');
        }
    }

    setDefaultFeeValue = () => {
        const { fee } = this.state;

        this.setState({
            fee: {
                ...fee,
                fee: fee.defaultFee,
                feePercentage: fee.defaultFeePercentage,
                minFee: fee.defaultMinFee,
                maxFee: fee.defaultMaxFee,
            },
        });
    }

    renderModal = (): JSX.Element => {
        const { t } = this.props;

        const {
            fee,
            formErrors,
        } = this.state;

        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('admin.tabs.paymentFees')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <Row>
                            <Col>

                                <FormTextField
                                    tooltip={<BackofficeTooltip text={t('admin.paymentFees.messages.feeTax', { fee: fee.fee })} />}
                                    label={t('admin.paymentFees.labels.fee')}
                                    name="fee"
                                    value={fee.fee}
                                    type="number"
                                    placeholder="0"
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.fee', null)}
                                />

                            </Col>

                        </Row>
                        { fee.paymentMethod === PaymentMethods.MBWAY && (
                            <>
                                <Row className="show-grid">
                                    <Col>
                                        <FormTextField
                                            label={t('admin.paymentFees.labels.feePercentage')}
                                            name="feePercentage"
                                            value={fee.feePercentage}
                                            type="number"
                                            placeholder="0"
                                            onChange={this.onInputChange}
                                            errors={get(formErrors, 'fields.feePercentage', null)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="show-grid">
                                    <Col>
                                        <FormTextField
                                            label={t('admin.paymentFees.labels.minFee')}
                                            name="minFee"
                                            value={fee.minFee}
                                            type="number"
                                            placeholder="0"
                                            onChange={this.onInputChange}
                                            errors={get(formErrors, 'fields.minFee', null)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="show-grid">
                                    <Col>
                                        <FormTextField
                                            label={t('admin.paymentFees.labels.maxFee')}
                                            name="maxFee"
                                            value={fee.maxFee}
                                            type="number"
                                            placeholder="0"
                                            onChange={this.onInputChange}
                                            errors={get(formErrors, 'fields.maxFee', null)}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )
                        }

                    </div>
                </Modal.Body>
                <ModalFooter>
                    <Button
                        callback={() => this.setDefaultFeeValue()}
                        styles="button--blue button--small form-group"
                        text={t('admin.paymentFees.labels.resetButton')}
                    />
                    <BackofficeTooltip text={t('admin.paymentFees.messages.resetFees')} />
                    <Button
                        styles="button--blue button--small"
                        text={t('global.buttons.cancel')}
                        callback={this.onCloseModal}
                    />
                    <Button
                        styles="button--dark-blue button--small"
                        text={t('global.buttons.ok')}
                        callback={this.saveFee}
                    />
                </ModalFooter>
            </>
        );
    }

    render() {
        const { t } = this.props;

        const {
            showModal,
            isFetching,
            fees,
        } = this.state;

        const hasData = fees && fees.length > 0;

        return (
            <div className="app-tabs__tab-content">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <Table responsive="sm">
                    <thead>
                        <tr>
                            <th>{t('admin.paymentFees.headers.paymentMethod')}</th>
                            <th>{t('admin.paymentFees.headers.fee')}</th>
                            <th>{t('admin.paymentFees.headers.feePercentage')}</th>
                            <th>{t('admin.paymentFees.headers.maxFee')}</th>
                            <th>{t('admin.paymentFees.headers.minFee')}</th>
                        </tr>
                    </thead>
                    {hasData ? (
                        <>
                            <tbody>
                                {fees.filter(fee => fee.paymentMethod !== PaymentMethods.CREDIT_CARD).map(f => {
                                    return (
                                        <tr key={f.id} onClick={() => this.onFeeClick(f)} className="clickable">
                                            <td>{t(`admin.paymentFees.labels.paymentMethods.${f.paymentMethod}`)}</td>
                                            <td>{numberToCurrency(f.fee)}</td>
                                            <td>{f.feePercentage || 'N.A.'}</td>
                                            <td>{f.maxFee ? numberToCurrency(f.maxFee) : 'N.A.'}</td>
                                            <td>{f.minFee ? numberToCurrency(f.minFee) : 'N.A.'}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan={4}>
                                    {t('global.noData')}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
                <Modal show={showModal} onHide={this.onCloseModal} centered>
                    {this.renderModal()}
                </Modal>
            </div>
        );
    }
}

export default withTranslationContext(PaymentFees);

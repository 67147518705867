/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import {
    AuthorizationAction,
    AuthorizationActionTypes,
} from './authorization_types';
import { RolePermission } from '../constants/authorization';

/**
 * signals the permissions request is being made
 * @returns {AuthorizationActionTypes}
 */
export const permissionsRequestActionCreator = (): AuthorizationActionTypes => {
    return {
        type: AuthorizationAction.PERMISSIONS_REQUEST,
    };
};

/**
 * received permissions success
 * @param {RolePermission[]} permissions
 * @returns {AuthorizationActionTypes}
 */
export const permissionsSuccessActionCreator = (permissions: RolePermission[]): AuthorizationActionTypes => {
    return {
        type: AuthorizationAction.PERMISSIONS_SUCCESS,
        payload: permissions,
    };
};

/**
 * received permissions failure
 * @param {object} formErrors
 * @returns {AuthorizationActionTypes}
 */
export const permissionsFailureActionCreator = (formErrors: object): AuthorizationActionTypes => {
    return {
        type: AuthorizationAction.PERMISSIONS_FAILURE,
        payload: formErrors,
    };
};

/**
 * sets the permissions
 * @param {RolePermission[]} perms
 * @returns {AuthorizationActionTypes}
 */
export const permissionsSetActionCreator = (perms: RolePermission[]): AuthorizationActionTypes => {
    return {
        type: AuthorizationAction.PERMISSIONS_SET,
        payload: perms,
    };
};

/**
 * resets the permissions
 * @returns {AuthorizationActionTypes}
 */
export const permissionsResetActionCreator = (): AuthorizationActionTypes => {
    return {
        type: AuthorizationAction.PERMISSIONS_RESET,
    };
};

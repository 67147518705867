import React, { Component } from 'react';

import ErrorMessage from './ErrorMessage';
import { SelectOption } from '../../constants/misc';
import noColor from '../../assets/images/no-color.png';

interface OwnProps {
    name: string;
    options: Array<SelectOption>;
    onChange: (value: SelectOption) => void;
    errors?: any;
    initialSelected?: SelectOption;
}

interface OwnState {
    currentlySelected: SelectOption;
}

const initialState = {
    currentlySelected: {
        label: '',
        value: '',
    },
};

class ProductColorButtons extends Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { initialSelected } = this.props;
        if (initialSelected) {
            this.setState({
                currentlySelected: initialSelected,
            });
        }
    }

    onSelectOption = (opt: SelectOption): void => {
        const { onChange } = this.props;
        this.setState({
            currentlySelected: opt,
        }, () => {
            onChange(opt);
        });
    }

    renderErrors = () => {
        const { name, errors } = this.props;
        if (errors) {
            return (
                <ErrorMessage errors={errors} field={name} />
            );
        }
        return <React.Fragment />;
    };

    isCurrentlySelected = (opt: SelectOption): boolean => {
        const { currentlySelected } = this.state;

        return opt.label === currentlySelected.label && opt.value === currentlySelected.value;
    }

    render() {
        const {
            options,
            name,
        } = this.props;

        return (
            <form className="product-color-buttons" data-testid="product-color-buttons">
                {options.map(opt => {
                    return (
                        <div className="product-color-buttons__item" key={`productColorButtons-${opt.label}-${opt.value}`}>
                            <label>
                                <div data-testid="product-color-buttons-symbol" className={`product-color-buttons__item__fake${this.isCurrentlySelected(opt) ? '--checked' : ''}`}>
                                    <div style={(opt.value !== '' ? { background: opt.value } : { backgroundImage: `url(${noColor})` })} />
                                </div>
                                <input
                                    data-testid="product-color-buttons-input"
                                    type="radio"
                                    name={name}
                                    value={opt.value}
                                    checked={this.isCurrentlySelected(opt)}
                                    onChange={() => this.onSelectOption(opt)}
                                />
                            </label>
                        </div>
                    );
                })
                    }
                <p className="error-field">{this.renderErrors()}</p>
            </form>
        );
    }
}

export default ProductColorButtons;

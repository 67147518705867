/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { MSG_EMAIL } from '../../settings';
import iconFb from '../../assets/images/icon-facebook.svg';
import iconIg from '../../assets/images/icon-instagram.svg';
import { MatchParams } from '../../constants/misc';
import {
    ALL_PRODUCTS_VIEW_ROUTE,
    ALL_STORES_ROUTE,
    COOKIES_POLICY_ROUTE,
    FAQS_ROUTE,
    JOIN_US_ROUTE,
    PRIVACY_POLICY_ROUTE,
    SALE_CONDITIONS_ROUTE,
    TERMS_AND_CONDITIONS_OF_USE_ROUTE,
} from '../../constants/routes';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import Button from './Button';
import { ICON } from './SvgIcon';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext {}

interface OwnState {}

class Footer extends Component<OwnProps, OwnState> {
    onPhoneNumberClick = () => {
        const { t } = this.props;
        navigator.clipboard.writeText(t('footer.phone'));
        toast.info(t('footer.copyMessage'), {
            position: 'bottom-right',
        });
    }

    onEmailClick = () => {
        window.location.href = `mailto:${MSG_EMAIL}`;
    }

    render() {
        const { t } = this.props;

        return (
            <div className="app-footer">
                <div className="footer-top" id="contactos">
                    <h4>{t('footer.title')}</h4>
                    <div className="app-footer__contact-button-container">
                        <div>
                            <Button
                                styles="button--dark-blue"
                                text={t('footer.email')}
                                icon={ICON.EMAIL}
                                iconPosition="left"
                                callback={this.onEmailClick}
                            />
                        </div>
                        <div>
                            <Button
                                styles="button--dark-blue"
                                text={t('footer.phone')}
                                icon={ICON.PHONE}
                                iconPosition="left"
                                callback={this.onPhoneNumberClick}
                            />
                            <p>{t('contactFeeType.MOBILE')}</p>
                        </div>
                    </div>
                    <h4>{t('footer.followUs')}</h4>
                    <div className="app-footer__soc-media-container">
                        <a
                            className="social"
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/leiriamarket.pt"
                            target="_blank"
                        >
                            <img className="" src={iconFb} alt="" />
                        </a>
                        <a
                            className="social"
                            href="https://www.instagram.com/leiriamarket.pt/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <img className="" src={iconIg} alt="" />
                        </a>
                    </div>
                </div>
                <div className="app-footer__footer-links">
                    <NavLink className="app-footer__footer-links__link" to={ALL_STORES_ROUTE}>{t('footer.links.stores')}</NavLink>
                    <NavLink className="app-footer__footer-links__link" to={ALL_PRODUCTS_VIEW_ROUTE}>{t('footer.links.products')}</NavLink>
                    <NavLink className="app-footer__footer-links__link" to={JOIN_US_ROUTE}>{t('footer.links.sell')}</NavLink>
                </div>
                <div className="terms-container">
                    <p>Copyright © 2021 - Leiria Market</p>
                    <div>
                        <NavLink to={FAQS_ROUTE}>
                            <span>{t('footer.faqs.1')}</span>
                            <span>{t('footer.faqs.2')}</span>
                        </NavLink>
                        <NavLink to={SALE_CONDITIONS_ROUTE}>
                            <span>{t('footer.saleConditions.1')}</span>
                            <span>{t('footer.saleConditions.2')}</span>
                        </NavLink>
                        <a href="https://www.livroreclamacoes.pt/inicio">
                            <span>{t('footer.complaintsBook.1')}</span>
                            <span>{t('footer.complaintsBook.2')}</span>
                        </a>
                        <NavLink to={TERMS_AND_CONDITIONS_OF_USE_ROUTE}>
                            <span>{t('footer.termsAndConditionsOfUse.1')}</span>
                            <span>{t('footer.termsAndConditionsOfUse.2')}</span>
                        </NavLink>
                        <NavLink to={PRIVACY_POLICY_ROUTE}>
                            <span>{t('footer.privacyPolicy.1')}</span>
                            <span>{t('footer.privacyPolicy.2')}</span>
                        </NavLink>
                        <NavLink to={COOKIES_POLICY_ROUTE}>
                            <span>{t('footer.cookiesPolicy.1')}</span>
                            <span>{t('footer.cookiesPolicy.2')}</span>
                        </NavLink>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(withRouter(Footer));

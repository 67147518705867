/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, createRef, RefObject } from 'react';
import axios from 'axios';
import MediaQuery from 'react-responsive';
import {
    Link, NavLink, RouteComponentProps, withRouter,
} from 'react-router-dom';
import Overlay, { OverlayChildren } from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { ReactSVG } from 'react-svg';
import { withCookies, ReactCookieProps } from 'react-cookie';
import Autocomplete from 'react-autocomplete';
import { throttle } from 'lodash';

import TagManager, { DataLayerArgs } from 'react-gtm-module';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { AuthorizationContext, withAuthorizationContext } from '../controllers/authorization/AuthorizationContext';

import iconOkCircle from '../../assets/images/icon-ok-circle.svg';
import lmLogo from '../../assets/images/navbar/logo_leiria_market_navbar.png';
import avatar from '../../assets/images/navbar/ic-user.svg';
import menu from '../../assets/images/navbar/ic-menu.svg';
import close from '../../assets/images/navbar/ic-close.svg';
import iconCart from '../../assets/images/navbar/ic-cart.svg';
import iconSearch from '../../assets/images/navbar/ic-search.svg';

import { authenticationLogoutURL } from '../../services/authentication';
import { checkPermission } from '../../utils/authorization';
import { PermissionKey, UserRoles } from '../../constants/authorization';
import {
    ADMIN_ROUTE,
    INDEX_ROUTE,
    STORE_ADMIN_ROUTE,
    LOGIN_ROUTE,
    CART_ROUTE,
    CUSTOMER_ADMIN_ROUTE,
    REGISTER_ROUTE,
    ALL_STORES_ROUTE,
    ALL_PRODUCTS_VIEW_ROUTE, CHECKOUT_ROUTE, STORE_VIEW_ROUTE, JOIN_US_ROUTE,
} from '../../constants/routes';
import { MatchParams, SelectOption } from '../../constants/misc';
import { CartContext, withCartContext } from '../controllers/cart/CartContext';
import Button from './Button';
import { scrollToElement } from '../../utils/misc';
import Loader from './Loader';
import storeIcon from '../../assets/images/ic-gratis.svg';
import productIcon from '../../assets/images/ic-shopping-cart-small.svg';
import { LiveSearchValue, Product, Store } from '../../constants/types';
import { storesPublicURL } from '../../services/stores';
import { productsFindPublicURL } from '../../services/products';
import { categoriesPublicURL } from '../../services/categories';
import { getFormattedProductURL, getFormattedStoreURL } from '../../utils/urls';
import { API_URL } from '../../settings';

enum SearchSelectOptions {
    PRODUCTS = 'Product',
    STORES = 'Store',
}

interface OwnProps extends RouteComponentProps<MatchParams>,
    AuthenticationContext,
    TranslationContext,
    AuthorizationContext,
    CartContext,
    ReactCookieProps {}

interface OwnState {
    showLeiriaMarket: boolean;
    showCartPopover: boolean;
    showUserPopover: boolean;
    isFetching: boolean;
    searchValue: string;
    selectSearchValue: string;
    isTopBarTransparent: boolean;
    isSideBarOpen: boolean;
    liveSearchResults: Array<LiveSearchValue>;
    firstSuggestion: LiveSearchValue | null;
    typingTimeout: number;
    isShowingCategories: boolean;
    categoryList: Array<SelectOption>;
}

const initialState : OwnState = {
    showLeiriaMarket: true,
    showCartPopover: false,
    showUserPopover: false,
    isFetching: false,
    searchValue: '',
    selectSearchValue: SearchSelectOptions.PRODUCTS,
    isTopBarTransparent: true,
    isSideBarOpen: false,
    liveSearchResults: [],
    typingTimeout: 0,
    firstSuggestion: null,
    isShowingCategories: false,
    categoryList: [],
};

const topBarTabLabels = {
    administration: 'topBar.administration',
    customerArea: 'topBar.customerArea',
    logout: 'topBar.logout',
};

class TopBar extends Component<OwnProps> {
    state = { ...initialState };

    private readonly divRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    private readonly divRefMobile: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    private readonly userRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    private readonly userRefMobile: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    private readonly navRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    private throttledListener: (() => void) = () => {};

    componentDidMount() {
        this.throttledListener = throttle(this.setTopBarStyleByScrollPosition, 400);

        document.addEventListener('scroll', this.throttledListener);

        this.loadSearchTermFromParams();
        this.getCategories();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<{}>, snapshot?: any): void {
        const {
            shoppingCart,
            location: {
                pathname,
                search,
            },
        } = this.props;
        const { shoppingCart: oldShoppingCart, location: { search: prevSearch } } = prevProps;

        const inNoPopoverViews: boolean = pathname.startsWith(CART_ROUTE) || pathname.startsWith(CHECKOUT_ROUTE);

        if (shoppingCart && oldShoppingCart && !inNoPopoverViews && !pathname.startsWith(LOGIN_ROUTE)) {
            const len = shoppingCart.productsCounter;
            const oldLen = oldShoppingCart.productsCounter;

            if (len > oldLen) {
                this.showPopover();
            }
        }

        if (search !== prevSearch && search === '') {
            this.setState({
                searchValue: '',
                liveSearchResults: [],
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.throttledListener);
    }

    getCategories = () => {
        axios.get(categoriesPublicURL()).then(res => {
            const categoryOptions = res.data.map((cat: {id: number, description: string}) => {
                return {
                    value: cat.id,
                    label: cat.description,
                };
            });

            this.setState({
                categoryList: categoryOptions,
            });
        });
    }

    onShoppingCartClick = () => {
        const { history, shoppingCart } = this.props;
        this.onLinkClick();
        if (shoppingCart && shoppingCart.productsCounter) {
            if (shoppingCart.productsCounter > 0) {
                history.push(CART_ROUTE);
            }
        }
    }

    onClickShowLeiriaMarket = () => {
        this.setState({
            showLeiriaMarket: true,
            showUserPopover: false,
        }, () => this.onLinkClick());
    }

    onEmptyCart = () => {
        const { resetCart } = this.props;
        this.setState({ showCartPopover: false }, () => {
            resetCart();
            this.onShoppingCartClick();
        });
    }

    onFinishCheckout = () => {
        const { history, user, location } = this.props;
        if (!user) {
            history.push({
                pathname: LOGIN_ROUTE,
                state: {
                    location,
                    isRedirect: true,
                },
            });
        } else {
            history.push(CART_ROUTE);
        }

        this.setState({
            showCartPopover: false,
        });
    }

    onLogout = async () => {
        const { logout, fetchCart, generateCartId } = this.props;
        await axios.get(authenticationLogoutURL());
        this.onLinkClick();
        logout();
        generateCartId();
        fetchCart();
    };

    onPopoverClose = () => {
        this.setState({
            showCartPopover: false,
        });
    }

    onLinkClick = () => {
        const { history } = this.props;
        scrollToElement(history.location.hash);
        if (history.location.hash) history.location.hash = '';

        if (this.navRef && this.navRef.current) {
            const { current } = this.navRef;
            if (current && current.classList.contains('show')) {
                current.classList.remove('show');
            }
        }

        this.setState({
            isSideBarOpen: false,
            isShowingCategories: false,
            showUserPopover: false,
        });
    }

    onCategoriesClick = () => {
        this.setState({
            isShowingCategories: true,
        });
    }

    onCategoriesClose = () => {
        this.setState({
            isShowingCategories: false,
        });
    }

    onSearchClick = () => {
        const { history } = this.props;
        const { searchValue, selectSearchValue } = this.state;

        switch (selectSearchValue) {
            case SearchSelectOptions.PRODUCTS:
                history.push(`${ALL_PRODUCTS_VIEW_ROUTE}?q=${encodeURIComponent(searchValue)}`);
                break;
            case SearchSelectOptions.STORES:
                history.push(`${ALL_STORES_ROUTE}?q=${encodeURIComponent(searchValue)}`);
                break;
            default:
        }
    }

    onSearchClickSmallDevice = () => {
        const { history } = this.props;
        const { searchValue } = this.state;

        history.push({
            pathname: ALL_PRODUCTS_VIEW_ROUTE,
            state: {
                search: searchValue,
            },
        });
    }

    getSearchAutocompleteResults = async () => {
        const { searchValue } = this.state;

        let searchSuggestions: Array<LiveSearchValue> = [
            {
                id: null,
                name: `Pesquisar por "${searchValue}" em Produtos`,
                type: SearchSelectOptions.PRODUCTS,
            },
            {
                id: null,
                name: `Pesquisar por "${searchValue}" em Lojas`,
                type: SearchSelectOptions.STORES,
            },
        ];

        const paramObject = {
            q: encodeURIComponent(searchValue),
            pageSize: 4,
        };

        const storeResponse = await axios.get(storesPublicURL(paramObject)).catch(() => ({ data: [] }));
        const storeSearchData = storeResponse.data.map((store: Store) => {
            return {
                id: store.id,
                name: store.name,
                type: SearchSelectOptions.STORES,
                item: store,
            };
        });

        const productResponse = await axios.get(productsFindPublicURL(paramObject)).catch(() => ({ data: [] }));
        const productSearchData = productResponse.data.map((product: Product) => {
            return {
                id: product.id,
                name: product.shortDescription,
                type: SearchSelectOptions.PRODUCTS,
                item: product,
            };
        });

        searchSuggestions = [...searchSuggestions, ...productSearchData, ...storeSearchData];

        this.setState({
            liveSearchResults: searchSuggestions,
            firstSuggestion: searchSuggestions[2],
        });
    }

    onSearchResultClick = (liveSearchValue: LiveSearchValue) => {
        const { history } = this.props;
        const { searchValue } = this.state;
        const { id, type, item } = liveSearchValue;

        const searchTermTag: DataLayerArgs = {
            dataLayer: {
                event: 'onSearch',
                searchTerm: searchValue,
            },
        };

        TagManager.dataLayer(searchTermTag);
        if (id) {
            let url = '';
            switch (type.valueOf()) {
                case SearchSelectOptions.PRODUCTS:
                    url = getFormattedProductURL(item as Product);
                    break;
                case SearchSelectOptions.STORES:
                    url = getFormattedStoreURL(item as Store);
                    break;
                default:
            }

            // go to external website if exposition store
            const productItem = (item as Product).productURL;
            if (type === SearchSelectOptions.PRODUCTS && productItem && !productItem.includes(API_URL || '')) {
                window.open(productItem);
                return;
            }

            history.push(url);
        } else {
            switch (type.valueOf()) {
                case SearchSelectOptions.PRODUCTS:
                    history.push(`${ALL_PRODUCTS_VIEW_ROUTE}?q=${encodeURIComponent(searchValue)}`);
                    break;
                case SearchSelectOptions.STORES:
                    history.push(`${ALL_STORES_ROUTE}?q=${encodeURIComponent(searchValue)}`);
                    break;
                default:
            }
        }
    }

    onSearchInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { typingTimeout } = this.state;

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        this.setState({
            searchValue: e.currentTarget.value,
            typingTimeout: setTimeout(() => { this.getSearchAutocompleteResults(); }, 500),
        });
    };

    loadSearchTermFromParams = () => {
        const { location: { search } } = this.props;

        const hasQ = search.charAt(0) === '?';
        const paramList = search.substr(hasQ ? 1 : 0).split('&');

        paramList.forEach(filter => {
            const paramSplit = filter.split('=');
            if (paramSplit[0] === 'q') {
                this.setState({
                    searchValue: paramSplit[1],
                });
            }
        });
    }

    setTopBarStyleByScrollPosition = () => {
        const top = window.pageYOffset <= 10;

        this.setState({
            isTopBarTransparent: top,
        });
    }

    showPopover = () => {
        this.setState({
            showCartPopover: true,
        });
    }

    showUserPopover = () => {
        this.setState({
            showUserPopover: true,
        });
    }

    hideUserPopover = () => {
        this.setState({
            showUserPopover: false,
        });
    }

    userPopover = (): OverlayChildren => {
        const {
            t, user, permissions, isAuthenticated,
        } = this.props;

        return (
            <Popover
                id="user-popover"
                className="user-popover"
            >
                {isAuthenticated && user && (
                    <div className="cart-popover">
                        <p className="dropdown-item-text">{user.name}</p>
                        {checkPermission(permissions, [PermissionKey.ALL]) && (
                            <NavLink className="dropdown-item" to={ADMIN_ROUTE} onClick={this.onClickShowLeiriaMarket}>{t(topBarTabLabels.administration)}</NavLink>
                        )}
                        {checkPermission(permissions, [PermissionKey.STORE_MANAGER]) && (
                            <NavLink className="dropdown-item" to={STORE_ADMIN_ROUTE} onClick={this.onClickShowLeiriaMarket}>{t(topBarTabLabels.administration)}</NavLink>
                        )}
                        {checkPermission(permissions, [PermissionKey.CUSTOMER]) && (
                            <NavLink className="dropdown-item" to={CUSTOMER_ADMIN_ROUTE} onClick={this.onClickShowLeiriaMarket}>{t(topBarTabLabels.customerArea)}</NavLink>
                        )}
                        <button type="button" className="dropdown-item" onClick={this.onLogout}>
                            {t(topBarTabLabels.logout)}
                        </button>
                    </div>
                )}
            </Popover>
        );
    }

    renderPopover = (): OverlayChildren => {
        const { t } = this.props;

        return (
            <Popover
                id="cart-popover"
                className="cart-popover"
            >
                <div className="cart-popover added-to-cart">
                    <div className="added-to-cart__header">
                        <ReactSVG src={iconOkCircle} />
                        <h3>{t('shoppingCart.popover.title')}</h3>
                    </div>
                    <Button
                        callback={() => {
                            this.onShoppingCartClick();
                            this.onPopoverClose();
                        }}
                        text={t('shoppingCart.popover.button')}
                        styles="button--dark-blue button--bigger"
                    />
                </div>
            </Popover>
        );
    }

    onMenuClick = () => {
        const { isSideBarOpen } = this.state;

        this.setState({
            isSideBarOpen: !isSideBarOpen,
            isShowingCategories: false,
        });
    }

    onMenuClose = () => {
        this.setState({
            isSideBarOpen: false,
            isShowingCategories: false,
        });
    }

    onSearchBarKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            this.onSearchClick();
        }
    }

    renderSideBar = () => {
        const {
            t,
            isAuthenticated,
            user,
            permissions,
            history,
            location,
            shoppingCart,
        } = this.props;

        const {
            isSideBarOpen,
            isShowingCategories,
            categoryList,
        } = this.state;

        const showCart: boolean = (!user || checkPermission(permissions, [PermissionKey.CUSTOMER]));
        const userRole: string = user?.groups[0] || '';

        return (
            <>
                <div
                    className={`sidebar-blackout ${isSideBarOpen ? 'sidebar-blackout--show' : 'sidebar-blackout--hide'}`}
                    onClick={() => this.onMenuClose()}
                />
                <div className={`sidebar ${isSideBarOpen ? 'sidebar--show' : 'sidebar--hide'}`}>
                    {isShowingCategories
                        ? (
                            <div className="sidebar__content">
                                <div className="sidebar__content__header">
                                    <img className="sidebar__content__header__logo" src={lmLogo} alt="leiria market logo" />
                                    <img onClick={() => this.onMenuClick()} className="sidebar__content__header__close-button" src={close} alt="leiria market logo" />
                                </div>
                                <div className="sidebar__content__categories-section">
                                    <div className="sidebar__content__categories-section__title">
                                        <p onClick={() => this.onCategoriesClose()}>{t('topBar.categories')}</p>
                                    </div>
                                    <div className="sidebar__content__categories-section__items-container">
                                        {categoryList.map(cat => {
                                            return <Link className="sidebar__content__categories-section__items-container__item" onClick={this.onMenuClick} to={`${ALL_PRODUCTS_VIEW_ROUTE}?categoryId=${cat.value}`}>{cat.label}</Link>;
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                        : (
                            <>
                                <div className="sidebar__content">
                                    <div className="sidebar__content__header">
                                        <img className="sidebar__content__header__logo" src={lmLogo} alt="leiria market logo" />
                                        <img onClick={() => this.onMenuClick()} className="sidebar__content__header__close-button" src={close} alt="leiria market logo" />
                                    </div>
                                    <div className="sidebar__content__links">
                                        <Link className="sidebar__content__links__item" to={ALL_STORES_ROUTE} onClick={this.onLinkClick}>{t('topBar.stores')}</Link>
                                        <Link className="sidebar__content__links__item" to={ALL_PRODUCTS_VIEW_ROUTE} onClick={this.onLinkClick}>{t('topBar.products')}</Link>
                                        <div className="sidebar__content__links__item" onClick={this.onCategoriesClick}>{t('topBar.categories')}</div>
                                        <Link className="sidebar__content__links__item" to={`${INDEX_ROUTE}#contactos`} onClick={this.onLinkClick}>{t('topBar.contacts')}</Link>
                                    </div>
                                </div>
                                <hr className="sidebar__divider" />
                                <div className="sidebar__content">
                                    <div className="sidebar__content__links">
                                        {(!isAuthenticated || userRole === UserRoles.GUEST) && (
                                            <>
                                                <button
                                                    type="button"
                                                    className="sidebar__content__links__item"
                                                    onClick={() => {
                                                        history.push({
                                                            pathname: LOGIN_ROUTE,
                                                            state: {
                                                                from: location,
                                                                isRedirect: true,
                                                            },
                                                        });
                                                        this.onLinkClick();
                                                    }}
                                                >
                                                    {t('topBar.login')}
                                                </button>
                                                <Link className="sidebar__content__links__item" to={REGISTER_ROUTE} onClick={this.onLinkClick}>{t('topBar.register')}</Link>
                                            </>
                                        )}
                                        {showCart && shoppingCart && shoppingCart.productsCounter > 0 && (
                                            <>
                                                <Link className="sidebar__content__links__item" to={CART_ROUTE} onClick={this.onShoppingCartClick}>{t('topBar.shoppingCart')}</Link>
                                            </>
                                        )}
                                        {isAuthenticated && userRole !== UserRoles.GUEST && (
                                            <>
                                                {checkPermission(permissions, [PermissionKey.ALL]) && (
                                                <Link className="sidebar__content__links__item" to={ADMIN_ROUTE} onClick={this.onLinkClick}>{t(topBarTabLabels.administration)}</Link>
                                                )}
                                                {checkPermission(permissions, [PermissionKey.STORE_MANAGER]) && (
                                                <Link className="sidebar__content__links__item" to={STORE_ADMIN_ROUTE} onClick={this.onLinkClick}>{t(topBarTabLabels.administration)}</Link>
                                                )}
                                                {checkPermission(permissions, [PermissionKey.CUSTOMER]) && (
                                                <Link className="sidebar__content__links__item" to={CUSTOMER_ADMIN_ROUTE} onClick={this.onLinkClick}>{t(topBarTabLabels.customerArea)}</Link>
                                                )}
                                                <Link className="sidebar__content__links__item" to="#" onClick={this.onLogout}>{t(topBarTabLabels.logout)}</Link>
                                            </>
                                        )}
                                    </div>
                                    <div className="sidebar__content__button">
                                        <Link
                                            onClick={this.onLinkClick}
                                            className="btn btn-light-blue"
                                            to={JOIN_US_ROUTE}
                                        >
                                            Vender no Leiria Market
                                        </Link>
                                    </div>
                                </div>
                            </>
                        )}
                </div>
            </>
        );
    }

    renderSearchField = ():JSX.Element => {
        const { t } = this.props;

        const {
            searchValue,
            liveSearchResults,
        } = this.state;

        return (
            <div className="navbar__search-container">
                <div className="search">
                    <div className="search-field">
                        <Autocomplete
                            inputProps={
                                {
                                    placeholder: t('topBar.search'),
                                    onKeyDown: this.onSearchBarKeyDown,
                                }
                            }
                            getItemValue={item => item.name}
                            items={liveSearchResults}
                            value={searchValue}
                            onChange={this.onSearchInputChange}
                            onSelect={(value, item) => this.onSearchResultClick(item)}
                            renderItem={(item: LiveSearchValue, isHighlighted) => {
                                let img = '';

                                switch (item.type.valueOf()) {
                                    case SearchSelectOptions.PRODUCTS: img = productIcon;
                                        break;
                                    case SearchSelectOptions.STORES: img = storeIcon;
                                        break;
                                    default:
                                }

                                return (
                                    <div
                                        className="search-result"
                                        style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                                        key={item.id || item.name}
                                    >
                                        <img src={img} alt="" />{item.name}
                                    </div>
                                );
                            }}
                        />
                    </div>
                    <div className="search-icon" onClick={() => this.onSearchClick()}>
                        <img src={iconSearch} alt="" />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            isAuthenticated,
            permissions,
            user,
            shoppingCart,
            history, location,
        } = this.props;

        const {
            showLeiriaMarket,
            showCartPopover,
            showUserPopover,
            isFetching,
            isTopBarTransparent,
        } = this.state;

        const showCartNonLoggedIn = !user && shoppingCart && shoppingCart.productsCounter > 0;

        const showCart: boolean = showLeiriaMarket && (showCartNonLoggedIn || checkPermission(permissions, [PermissionKey.CUSTOMER]));

        let userPopOverProps: any = {
            placement: 'bottom',
            show: showUserPopover,
            rootCloseEvent: 'click',
            rootClose: true,
            onHide: this.hideUserPopover,
        };

        let userPopOverMobileProps: any = {
            placement: 'bottom',
            show: showUserPopover,
            rootCloseEvent: 'click',
            rootClose: true,
            onHide: this.hideUserPopover,
        };

        let popOverProps: any = {
            placement: 'bottom',
            show: showCartPopover,
            rootCloseEvent: 'click',
            rootClose: true,
            onHide: this.onPopoverClose,
        };

        let popOverMobileProps: any = {
            placement: 'bottom',
            show: showCartPopover,
            rootCloseEvent: 'click',
            rootClose: true,
            onHide: this.onPopoverClose,
        };

        if (this.divRef.current) {
            popOverProps = {
                placement: 'bottom',
                target: this.divRef.current,
                show: showCartPopover,
                rootCloseEvent: 'click',
                rootClose: true,
                onHide: this.onPopoverClose,
            };
        }

        if (this.divRefMobile.current) {
            popOverMobileProps = {
                placement: 'bottom',
                target: this.divRefMobile.current,
                show: showCartPopover,
                rootCloseEvent: 'click',
                rootClose: true,
                onHide: this.onPopoverClose,
            };
        }

        if (this.userRef.current) {
            userPopOverProps = {
                placement: 'bottom',
                target: this.userRef.current,
                show: showUserPopover,
                rootCloseEvent: 'click',
                rootClose: true,
                onHide: this.hideUserPopover,
            };
        }

        if (this.userRefMobile.current) {
            userPopOverMobileProps = {
                placement: 'bottom',
                target: this.userRefMobile.current,
                show: showUserPopover,
                rootCloseEvent: 'click',
                rootClose: true,
                onHide: this.hideUserPopover,
            };
        }

        let navBarClass = '';
        const isInTransparencyRoute = history.location.pathname.valueOf() === JOIN_US_ROUTE
            || history.location.pathname.valueOf() === INDEX_ROUTE
            || history.location.pathname.valueOf() === ALL_PRODUCTS_VIEW_ROUTE
            || history.location.pathname.valueOf().includes(`${STORE_VIEW_ROUTE}/`);

        if (isInTransparencyRoute) {
            if (isTopBarTransparent) {
                navBarClass = '';
            } else {
                navBarClass = 'navbar--blue';
            }
        } else {
            navBarClass = 'navbar--blue';
        }

        return (
            <>
                {isFetching && (
                <div className="loader-wrapper">
                    <Loader />
                </div>
                )}
                <MediaQuery minWidth={992} onChange={() => { this.onPopoverClose(); }}>
                    <nav id="top_bar" className={`navbar ${navBarClass} navbar-expand-lg navbar-light fixed-top`}>
                        {this.renderSideBar()}
                        <div className="container d-flex navbar-container">
                            <div className="container__left">
                                <div onClick={() => this.onMenuClick()}>
                                    <img src={menu} alt="menu button" />
                                </div>
                                <NavLink className="navbar-brand" to={`${INDEX_ROUTE}#pagina_inicial`} onClick={() => { this.setState({ showLeiriaMarket: true }); }}>
                                    <img className="img-fluid" src={lmLogo} alt="Leiria Market Logo" />
                                </NavLink>
                            </div>
                            <div className="container__right">
                                {this.renderSearchField()}
                                <div
                                    ref={this.userRef}
                                    className="nav-item dropdown avatar"
                                    onClick={isAuthenticated && user && user.groups[0] !== UserRoles.GUEST ? () => this.showUserPopover() : () => history.push({
                                        pathname: LOGIN_ROUTE,
                                        state: {
                                            from: location,
                                            isRedirect: true,
                                        },
                                    })}
                                >
                                    <div className="container__right__img">
                                        <img src={avatar} alt="avatar" />
                                    </div>
                                    {isAuthenticated && user && user.groups[0] !== UserRoles.GUEST && (
                                        <Overlay {...userPopOverProps}>
                                            {this.userPopover()}
                                        </Overlay>
                                    )}
                                </div>
                                {showCart && shoppingCart && shoppingCart.productsCounter > 0 && (
                                <div ref={this.divRef} className="icon-wrapper" onClick={this.onShoppingCartClick}>
                                    <div className="count-container">
                                        <span>{shoppingCart.productsCounter}</span>
                                    </div>
                                    <div className="container__right__img">
                                        <img src={iconCart} alt="shopping cart" />
                                    </div>
                                    <Overlay {...popOverProps}>
                                        {this.renderPopover()}
                                    </Overlay>
                                </div>
                                )}
                            </div>
                        </div>
                    </nav>
                </MediaQuery>
                <MediaQuery maxWidth={991} onChange={() => { this.onPopoverClose(); }}>
                    <nav id="top_bar" className={`navbar--mobile ${navBarClass} navbar-light fixed-top`}>
                        {this.renderSideBar()}
                        <div className="container container--mobile d-flex navbar-container">
                            <div className="container--mobile__top">
                                <div className="container--mobile__top__left">
                                    <div onClick={() => this.onMenuClick()}>
                                        <img src={menu} alt="menu button" />
                                    </div>
                                    <NavLink className="navbar-brand--mobile" to={`${INDEX_ROUTE}#pagina_inicial`} onClick={() => { this.setState({ showLeiriaMarket: true }); }}>
                                        <img className="img-fluid" src={lmLogo} alt="Leiria Market Logo" />
                                    </NavLink>
                                </div>
                                <div className="container--mobile__top__right">
                                    <div
                                        ref={this.userRefMobile}
                                        className="nav-item dropdown avatar"
                                        onClick={isAuthenticated && user && user.groups[0] !== UserRoles.GUEST ? () => this.showUserPopover() : () => history.push({
                                            pathname: LOGIN_ROUTE,
                                            state: {
                                                from: location,
                                                isRedirect: true,
                                            },
                                        })}
                                    >
                                        <div className="container__right__img">
                                            <img src={avatar} alt="avatar" />
                                        </div>
                                        {isAuthenticated && user && user.groups[0] !== UserRoles.GUEST && (
                                            <Overlay {...userPopOverMobileProps}>
                                                {this.userPopover()}
                                            </Overlay>
                                        )}
                                    </div>
                                    {showCart && shoppingCart && shoppingCart.productsCounter > 0 && (
                                        <div ref={this.divRefMobile} className="icon-wrapper" onClick={this.onShoppingCartClick}>
                                            <div className="count-container">
                                                <span>{shoppingCart.productsCounter}</span>
                                            </div>
                                            <div className="container__right__img">
                                                <img src={iconCart} alt="shopping cart" />
                                            </div>
                                            <Overlay {...popOverMobileProps}>
                                                {this.renderPopover()}
                                            </Overlay>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {this.renderSearchField()}
                        </div>
                    </nav>
                </MediaQuery>
            </>
        );
    }
}

export default withCartContext(withRouter(withAuthorizationContext(withAuthenticationContext(withTranslationContext(withCookies((TopBar)))))));

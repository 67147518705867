/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import Table from 'react-bootstrap/Table';
import { get } from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import ModalFooter from 'react-bootstrap/ModalFooter';
import { HexColorPicker } from 'react-colorful';
import { FormLabel } from 'react-bootstrap';

import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { Campaign } from '../../../constants/types';
import Loader from '../../elements/Loader';
import Button from '../../elements/Button';
import FormTextField from '../../elements/FormTextField';
import ImageUpload from '../../elements/ImageUpload';
import { IFormError } from '../../../utils/validation';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import { resourcesURL } from '../../../services/resources';
import displayConfirm from '../../elements/displayConfirm';
import withPaging, { WithPagingProps } from '../../hocs/withPaging';
import TablePaging from '../../elements/TablePaging';
import FormCheckbox from '../../elements/FormCheckbox';
import { BackofficeTooltip } from '../../elements/BackofficeTooltip';
import { campaignBannerOrderURL, campaignsAdminURL, singleBannerAdminURL } from '../../../services/campaigns';
import chevron from '../../../assets/images/ic-down-small.svg';
import { KeyedObject } from '../../../constants/misc';

interface OwnProps extends TranslationContext, WithPagingProps {}

interface OwnState {
    isFetching: boolean;
    campaign: Campaign;
    showCampaignModal: boolean;
    isEditingCampaign: boolean;
    formErrors: any;
    campaignsHomePage: Array<Campaign>;
    campaignsNotHomePage: Array<Campaign>;
    _limit: number;
    totalResults: number;
}

const initialState: OwnState = {
    isFetching: false,
    campaignsHomePage: [],
    campaignsNotHomePage: [],
    showCampaignModal: false,
    isEditingCampaign: false,
    formErrors: null,
    _limit: 25,
    totalResults: 0,
    campaign: {
        id: -1,
        name: '',
        desktopImage: null,
        desktopImageHash: '',
        mobileImage: null,
        mobileImageHash: '',
        headerImage: null,
        headerImageHash: '',
        mobileHeaderImage: null,
        mobileHeaderImageHash: '',
        title: '',
        subtitle: '',
        titleColor: '',
        subtitleColor: '',
        button: false,
        buttonUrl: '',
        buttonText: '',
        bannerUrl: '',
        finish: '',
        start: '',
        inHomepage: false,
        anchorRight: false,
    },
};

enum BannerMoveDirections {
    UP = 'UP',
    DOWN = 'DOWN'
}

class Campaigns extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    componentDidMount(): void {
        this.getCampaigns();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const { currentPage } = this.props;
        const { currentPage: oldCurrentPage } = prevProps;

        const hasPagingChanged: boolean = currentPage !== oldCurrentPage;

        if (hasPagingChanged) {
            this.getCampaigns();
        }
    }

    onCampaignDesktopImageSelected = (file: File) => {
        const { campaign } = this.state;
        this.setState({
            campaign: {
                ...campaign,
                desktopImage: file,
            },
        });
    };

    onCampaignMobileImageSelected = (file: File) => {
        const { campaign } = this.state;
        this.setState({
            campaign: {
                ...campaign,
                mobileImage: file,
            },
        });
    };

    onCampaignDesktopHeaderImageSelected = (file: File) => {
        const { campaign } = this.state;
        this.setState({
            campaign: {
                ...campaign,
                headerImage: file,
            },
        });
    };

    onCampaignMobileHeaderImageSelected = (file: File) => {
        const { campaign } = this.state;
        this.setState({
            campaign: {
                ...campaign,
                mobileHeaderImage: file,
            },
        });
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { name } = e.currentTarget;
        const { campaign } = this.state;
        const { button, inHomepage, anchorRight } = campaign;

        if (name === 'isHomepage') {
            this.setState({
                campaign: {
                    ...campaign,
                    inHomepage: !inHomepage,
                },
            });
            return;
        }

        if (name === 'isAnchoredRight') {
            this.setState({
                campaign: {
                    ...campaign,
                    anchorRight: !anchorRight,
                },
            });
            return;
        }

        if (name === 'hasButton') {
            this.setState({
                campaign: {
                    ...campaign,
                    button: !button,
                },
            });
            return;
        }

        this.setState({
            campaign: {
                ...campaign,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onSliderColorChange = (colorHex: string, name: string) => {
        const { campaign } = this.state;

        if (colorHex !== '#NaNNaNNaN') {
            this.setState({
                campaign: {
                    ...campaign,
                    [name]: colorHex,
                },
            });
        } else {
            this.setState({
                campaign: {
                    ...campaign,
                    [name]: '#ffffff',
                },
            });
        }
    }

    onAddCampaignClick = () => {
        this.setState({
            showCampaignModal: true,
        });
    };

    onCloseCampaignModal = () => {
        this.setState({
            showCampaignModal: false,
            campaign: {
                ...initialState.campaign,
            },
            isEditingCampaign: false,
            formErrors: null,
        });
    };

    onCampaignClick = (campaign: Campaign) => {
        this.setState({
            showCampaignModal: true,
            isEditingCampaign: true,
            campaign: {
                ...campaign,
                desktopImageHash: resourcesURL(campaign.desktopImageHash),
                mobileImageHash: resourcesURL(campaign.mobileImageHash),
            },
        });
    };

    onDeleteCampaign = (e: React.MouseEvent, campaign: Campaign) => {
        e.stopPropagation();

        const { t } = this.props;

        displayConfirm({
            acceptButtonText: t('global.buttons.ok'),
            onAccept: () => this.deleteCampaign(campaign.id),
            onReject: () => {},
            rejectButtonText: t('global.buttons.cancel'),
            message: t('admin.campaigns.messages.deleteCampaignPrompt', { name: campaign.name }),
        });
    };

    deleteCampaign = async (id: number) => {
        const { t } = this.props;
        this.setState({ isFetching: true });

        await axios.delete(singleBannerAdminURL(id))
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.campaigns.messages.deleteCampaignSuccess'));
                this.setState({ isFetching: false }, () => this.getCampaigns());
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.campaigns.messages.deleteCampaignError'));
                this.setState({ isFetching: false });
            });
    };

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    saveCampaign = () => {
        const { campaign, isEditingCampaign } = this.state;

        if (this.validateFields()) {
            const formData = new FormData();

            const fields = {
                anchorRight: campaign.anchorRight,
                bannerUrl: (campaign.bannerUrl && campaign.bannerUrl !== '') ? String(campaign.bannerUrl).trim() : null,
                button: campaign.button || false,
                buttonText: campaign.button ? String(campaign.buttonText).trim() : null,
                buttonUrl: campaign.button ? String(campaign.buttonUrl).trim() : null,
                start: moment(campaign.start).unix(),
                finish: moment(campaign.finish).set({
                    hour: 23, minute: 59, second: 59, millisecond: 999,
                }).unix(),
                inHomepage: campaign.inHomepage || false,
                name: String(campaign.name).trim(),
                subtitle: (campaign.subtitle && campaign.subtitle !== '') ? String(campaign.subtitle).trim() : null,
                subtitleColor: (campaign.subtitleColor && campaign.subtitleColor !== '') ? String(campaign.subtitleColor).trim() : null,
                title: (campaign.title && campaign.title !== '') ? String(campaign.title).trim() : null,
                titleColor: (campaign.titleColor && campaign.titleColor !== '') ? String(campaign.titleColor).trim() : null,
            };

            if (campaign.desktopImage) {
                formData.append('desktopImage', campaign.desktopImage, campaign.desktopImage.name);
            }

            if (campaign.mobileImage) {
                formData.append('mobileImage', campaign.mobileImage, campaign.mobileImage.name);
            }

            if (campaign.headerImage) {
                formData.append('headerImage', campaign.headerImage, campaign.headerImage.name);
            }

            if (campaign.mobileHeaderImage) {
                formData.append('mobileHeaderImage', campaign.mobileHeaderImage, campaign.mobileHeaderImage.name);
            }

            formData.append('banner', new Blob([JSON.stringify(fields)], {
                type: 'application/json',
            }));

            if (isEditingCampaign) this.editCampaignRequest(formData);
            else this.createCampaignRequest(formData);
        }
    };

    editCampaignRequest = (formData: FormData) => {
        const { t } = this.props;
        const { campaign } = this.state;

        this.setState({ isFetching: true });

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        axios.put(singleBannerAdminURL(campaign.id), formData, config)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.campaigns.messages.editCampaignSuccess'));

                this.setState({
                    isFetching: false,
                    showCampaignModal: false,
                    isEditingCampaign: false,
                    campaign: {
                        ...initialState.campaign,
                    },
                }, () => this.getCampaigns());
            })
            .catch(error => {
                if (error.response) {
                    const { status } = error.response;
                    let responseError;
                    if (status === 413) {
                        responseError = { fields: { thumbnail: [{ typeOfViolation: 'FileSize' }] } };
                    }
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.campaigns.messages.editCampaignError'));
                    this.handleResponse(responseError || error.response.data);
                } else {
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.campaigns.messages.editCampaignError'));
                    this.setState({ isFetching: false });
                }
            });
    };

    createCampaignRequest = (formData: FormData) => {
        const { t } = this.props;
        this.setState({ isFetching: true });

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        axios.post(campaignsAdminURL(), formData, config)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.campaigns.messages.createCampaignSuccess'));

                this.setState({
                    isFetching: false,
                    showCampaignModal: false,
                    isEditingCampaign: false,
                }, () => this.getCampaigns());
            })
            .catch(error => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.campaigns.messages.createCampaignError'));

                if (error.response) {
                    this.handleResponse(error.response.data);
                } else {
                    this.setState({ isFetching: false });
                }
            });
    };

    validateFields = () => {
        const { isEditingCampaign, campaign } = this.state;

        let errors: IFormError | null = {};
        
        if (!isEditingCampaign && !campaign.desktopImage) {
            errors.desktopImage = [{ typeOfViolation: 'NotBlank' }];
        }
        if (!isEditingCampaign && !campaign.mobileImage) {
            errors.mobileImage = [{ typeOfViolation: 'NotBlank' }];
        }
        if (!isEditingCampaign && !campaign.headerImage) {
            errors.headerImage = [{ typeOfViolation: 'NotBlank' }];
        }
        if (!isEditingCampaign && !campaign.mobileHeaderImage) {
            errors.mobileHeaderImage = [{ typeOfViolation: 'NotBlank' }];
        }

        if ((campaign.subtitle && campaign.subtitle !== '') && (!campaign.subtitleColor || campaign.subtitleColor === '')) errors.subtitleColor = [{ typeOfViolation: 'NotBlank' }];
        if ((campaign.title && campaign.title !== '') && (!campaign.titleColor || campaign.titleColor === '')) errors.titleColor = [{ typeOfViolation: 'NotBlank' }];

        if (campaign.button) {
            if (!campaign.buttonText || campaign.buttonText === '') errors.buttonText = [{ typeOfViolation: 'NotBlank' }];
            if (!campaign.buttonUrl || campaign.buttonUrl === '') errors.buttonUrl = [{ typeOfViolation: 'NotBlank' }];
        }

        if (!campaign.name || campaign.name === '') errors.name = [{ typeOfViolation: 'NotBlank' }];

        if (!campaign.start || campaign.start === '') errors.start = [{ typeOfViolation: 'NotBlank' }];
        if (!campaign.finish || campaign.finish === '') errors.finish = [{ typeOfViolation: 'NotBlank' }];

        if (campaign.start && campaign.finish) {
            const campaignStart = moment(campaign.start);
            const campaignEnd = moment(campaign.finish);

            if (campaignEnd.isBefore(campaignStart)) {
                errors.finish = [{ typeOfViolation: 'afterStartDate' }];
            }

            if (!isEditingCampaign && campaignStart.diff(moment(), 'days') < 0) {
                errors.start = [{ typeOfViolation: 'todayOrAfter' }];
            }
        }

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    getCampaigns = async () => {
        const { currentPage, onPagingChange } = this.props;
        const { isFetching, _limit } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        try {
            const { data, headers } = await axios.get(campaignsAdminURL({
                pageSize: _limit,
                page: currentPage - 1,
            }));

            const newTotalResults: number = parseInt(headers['x-total-count']);

            if (data && data.length === 0 && currentPage !== 1) {
                this.setState({ isFetching: false }, () => {
                    onPagingChange(currentPage - 1);
                    this.getCampaigns();
                });
            } else {
                const dateFormat = 'YYYY-MM-DD';
                const campaigns = data.map((campaign: Campaign) => {
                    return {
                        ...campaign,
                        start: moment.unix(Number(campaign.start)).format(dateFormat),
                        finish: moment.unix(Number(campaign.finish)).format(dateFormat),
                    };
                });
                
                const campaignsHomePage = campaigns.filter((campaign: Campaign) => {
                    return campaign.inHomepage;
                });
                const campaignsNotHomePage = campaigns.filter((campaign: Campaign) => {
                    return !campaign.inHomepage;
                });

                this.setState({
                    isFetching: false,
                    campaignsHomePage,
                    campaignsNotHomePage,
                    totalResults: newTotalResults,
                });
            }
        } catch (e) {
            this.setState({ isFetching: false });
        }
    }

    moveCampaign = (e: React.MouseEvent, c: Campaign, direction: BannerMoveDirections) => {
        e.stopPropagation();
        const { campaignsHomePage } = this.state;
        const campaignsHomePageAux = [...campaignsHomePage];
        const currentIndex = campaignsHomePageAux.indexOf(c);
        let indexToSwap = -1;
        if (direction === BannerMoveDirections.UP) {
            indexToSwap = currentIndex - 1;
            [campaignsHomePageAux[indexToSwap], campaignsHomePageAux[currentIndex]] = [campaignsHomePageAux[currentIndex], campaignsHomePageAux[indexToSwap]];
        } else if (direction === BannerMoveDirections.DOWN) {
            indexToSwap = currentIndex + 1;
            [campaignsHomePageAux[currentIndex], campaignsHomePageAux[indexToSwap]] = [campaignsHomePageAux[indexToSwap], campaignsHomePageAux[currentIndex]];
        }

        const dataToSend: KeyedObject = {};
        campaignsHomePageAux.forEach((cmp: Campaign, idx:number) => {
            dataToSend[String(cmp.id)] = idx;
        });

        axios.put(campaignBannerOrderURL(), dataToSend)
            .then(this.getCampaigns)
            .catch(() => {
                const { t } = this.props;
                displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.campaigns.messages.campaignOrderError'));
            });
    }

    renderModalColorPickers = (): JSX.Element => {
        const { t } = this.props;

        const {
            campaign,
            formErrors,
        } = this.state;

        return (
            <Row>
                <Col xs={15} sm={12} md={12} xl={6}>
                    <Col>
                        <Row>
                            <FormLabel>{t('admin.campaigns.labels.sloganColor')}</FormLabel>
                        </Row>
                        <Row>
                            <Col className="pl-0">
                                <HexColorPicker className="color-picker-small" color={campaign.titleColor} onChange={(colorHex: string) => { this.onSliderColorChange(colorHex, 'titleColor'); }} />
                            </Col>
                            <Col className="d-flex align-items-end">
                                <FormTextField
                                    label={t('admin.campaigns.labels.hexCode')}
                                    name="titleColor"
                                    value={campaign.titleColor}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.titleColor', null)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Col>
                <Col xs={15} sm={12} md={12} xl={6}>
                    <Col>
                        <Row>
                            <FormLabel>{t('admin.campaigns.labels.subtitleColor')}</FormLabel>
                        </Row>
                        <Row>
                            <Col className="pl-0">
                                <HexColorPicker className="color-picker-small" onChange={(colorHex: string) => { this.onSliderColorChange(colorHex, 'subtitleColor'); }} />
                            </Col>
                            <Col className="d-flex align-items-end">
                                <FormTextField
                                    label={t('admin.campaigns.labels.hexCode')}
                                    name="subtitleColor"
                                    value={campaign.subtitleColor}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.subtitleColor', null)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Row>
        );
    }

    renderModal = (): JSX.Element => {
        const { t } = this.props;

        const {
            isEditingCampaign,
            campaign,
            formErrors,
        } = this.state;

        return (
            <>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {isEditingCampaign ? t('admin.campaigns.editCampaign') : t('admin.campaigns.addCampaign')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container modal-custom store-form">
                        <Row>
                            <Col xs={12}>
                                <p>{t('admin.campaigns.labels.photoDesktop')}</p>
                                <div className="banner-upload">
                                    <ImageUpload
                                        imageUrl={campaign.desktopImageHash}
                                        onSelected={this.onCampaignDesktopImageSelected}
                                        buttonText={t('global.buttons.upload')}
                                        helpText={t('admin.campaigns.labels.photoDesktopHint')}
                                        errors={get(formErrors, 'fields.desktopImage', null)}
                                    />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <p>{t('admin.campaigns.labels.photoMobile')}</p>
                                <ImageUpload
                                    imageUrl={campaign.mobileImageHash}
                                    onSelected={this.onCampaignMobileImageSelected}
                                    buttonText={t('global.buttons.upload')}
                                    helpText={t('admin.campaigns.labels.photoMobileHint')}
                                    errors={get(formErrors, 'fields.mobileImage', null)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <p>{t('admin.campaigns.labels.headerDesktop')}</p>
                                <div className="banner-upload">
                                    <ImageUpload
                                        imageUrl={resourcesURL(campaign.headerImageHash)}
                                        onSelected={this.onCampaignDesktopHeaderImageSelected}
                                        buttonText={t('global.buttons.upload')}
                                        helpText={t('admin.campaigns.labels.headerDesktopHint')}
                                        errors={get(formErrors, 'fields.headerImage', null)}
                                    />
                                </div>
                            </Col>
                            <Col xs={12}>
                                <p>{t('admin.campaigns.labels.headerMobile')}</p>
                                <ImageUpload
                                    imageUrl={resourcesURL(campaign.mobileHeaderImageHash)}
                                    onSelected={this.onCampaignMobileHeaderImageSelected}
                                    buttonText={t('global.buttons.upload')}
                                    helpText={t('admin.campaigns.labels.headerMobileHint')}
                                    errors={get(formErrors, 'fields.mobileHeaderImage', null)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={5} className="d-flex">
                                <FormCheckbox
                                    name="isHomepage"
                                    value={campaign.inHomepage}
                                    label={t('admin.campaigns.labels.isHomepage')}
                                    onChange={this.onInputChange}
                                />
                                <div className="ml-2 mb-2">
                                    <BackofficeTooltip text={t('admin.campaigns.tooltips.homepage')} />
                                </div>
                            </Col>
                            <Col xs={12} md={5} className="d-flex">
                                <FormCheckbox
                                    name="isAnchoredRight"
                                    value={campaign.anchorRight}
                                    label={t('admin.campaigns.labels.isAnchoredRight')}
                                    onChange={this.onInputChange}
                                />
                                <div className="ml-2 mb-2">
                                    <BackofficeTooltip text={t('admin.campaigns.tooltips.isAnchoredRight')} />
                                </div>
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col xs={12} md={12}>
                                <FormTextField
                                    label={t('admin.campaigns.labels.name')}
                                    name="name"
                                    value={campaign.name}
                                    maxLength={60}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.name', null)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6} lg={6} sm={6}>
                                <FormTextField
                                    label={t('admin.campaigns.labels.dateStart')}
                                    name="start"
                                    type="date"
                                    value={campaign.start || ''}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.start', null)}
                                />
                            </Col>
                            <Col xs={12} md={6} lg={6} sm={6}>
                                <FormTextField
                                    label={t('admin.campaigns.labels.dateEnd')}
                                    name="finish"
                                    type="date"
                                    value={campaign.finish || ''}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.finish', null)}
                                />
                            </Col>
                        </Row>
                        <Row className="show-grid">
                            <Col xs={12} md={12}>
                                <div className="d-flex align-content-end">
                                    <p className="mb-2 mr-2">{t('admin.campaigns.labels.slogan')}</p>
                                    <BackofficeTooltip text={t('admin.campaigns.tooltips.slogan')} />
                                </div>
                                <FormTextField
                                    name="title"
                                    value={campaign.title}
                                    onChange={this.onInputChange}
                                    maxLength={32}
                                    errors={get(formErrors, 'fields.title', null)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="d-flex align-content-end">
                                    <p className="mb-2 mr-2">{t('admin.campaigns.labels.subtitle')}</p>
                                    <BackofficeTooltip text={t('admin.campaigns.tooltips.slogan')} />
                                </div>
                                <FormTextField
                                    name="subtitle"
                                    value={campaign.subtitle}
                                    maxLength={64}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.subtitle', null)}
                                />
                            </Col>
                        </Row>
                        {this.renderModalColorPickers()}
                        <br />
                        <Row>
                            <Col xs={12} md={8}>
                                <FormCheckbox
                                    name="hasButton"
                                    value={campaign.button === undefined ? false : campaign.button}
                                    label={t('admin.campaigns.labels.hasButton')}
                                    onChange={this.onInputChange}
                                />
                            </Col>
                        </Row>
                        {campaign.button && (
                            <>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <FormTextField
                                            label={t('admin.campaigns.labels.buttonText')}
                                            name="buttonText"
                                            value={campaign.buttonText}
                                            maxLength={13}
                                            onChange={this.onInputChange}
                                            errors={get(formErrors, 'fields.buttonText', null)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <div className="d-flex align-content-end">
                                            <p className="mb-2 mr-2">{t('admin.campaigns.labels.buttonLink')}</p>
                                            <BackofficeTooltip text={t('admin.campaigns.tooltips.buttonLink')} />
                                        </div>
                                        <FormTextField
                                            name="buttonUrl"
                                            value={campaign.buttonUrl ? campaign.buttonUrl : ''}
                                            onChange={this.onInputChange}
                                            errors={get(formErrors, 'fields.buttonUrl', null)}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="d-flex align-content-end">
                                    <p className="mb-2 mr-2">{t('admin.campaigns.labels.campaignLink')}</p>
                                    <BackofficeTooltip text={t('admin.campaigns.tooltips.campaignLink')} />
                                </div>
                                <FormTextField
                                    name="bannerUrl"
                                    value={campaign.bannerUrl}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.bannerUrl', null)}
                                />
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <ModalFooter>
                    <Button
                        styles="button--blue button--small"
                        text={t('global.buttons.cancel')}
                        callback={this.onCloseCampaignModal}
                    />
                    <Button
                        styles="button--dark-blue button--small"
                        text={t('global.buttons.ok')}
                        callback={this.saveCampaign}
                    />
                </ModalFooter>
            </>
        );
    }

    render() {
        const { t, currentPage, onPagingChange } = this.props;
        const {
            showCampaignModal,
            isFetching,
            campaignsHomePage,
            campaignsNotHomePage,
            _limit,
            totalResults,
        } = this.state;

        const hasData = (campaignsHomePage || campaignsNotHomePage) && (campaignsHomePage.length > 0 || campaignsNotHomePage.length > 0);

        return (
            <div className="app-tabs__tab-content">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="row justify-content-end buttons-container">
                    <div className="button-wrapper">
                        <Button
                            styles="button--dark-blue button--small"
                            text={t('admin.campaigns.addCampaign')}
                            callback={this.onAddCampaignClick}
                        />
                    </div>
                </div>
                <Table responsive="sm">
                    <thead>
                        <tr>
                            <th>{t('admin.campaigns.headers.image')}</th>
                            <th>{t('admin.campaigns.headers.order')}</th>
                            <th>{t('admin.campaigns.headers.name')}</th>
                            <th>{t('admin.campaigns.headers.slogan')}</th>
                            <th>{t('admin.campaigns.headers.subtitle')}</th>
                            <th>{t('admin.campaigns.headers.dateStart')}</th>
                            <th>{t('admin.campaigns.headers.dateEnd')}</th>
                            <th>{t('admin.campaigns.headers.homepage')}</th>
                            <th />
                        </tr>
                    </thead>
                    {hasData ? (
                        <>
                            {campaignsHomePage.length > 0 && (
                            <tbody>
                                {campaignsHomePage.map((c, idx) => {
                                    return (
                                        <tr key={c.id} onClick={() => this.onCampaignClick(c)} className="clickable">
                                            <td>
                                                <img className="banner-img" src={resourcesURL(c.desktopImageHash)} alt="" />
                                            </td>
                                            <td>
                                                {idx > 0 && (<img className="chevron chevron--up" src={chevron} alt="chevron up" onClick={e => this.moveCampaign(e, c, BannerMoveDirections.UP)} />)}
                                                {idx < campaignsHomePage.length - 1 && (<img className="chevron chevron--down" src={chevron} alt="chevron down" onClick={e => this.moveCampaign(e, c, BannerMoveDirections.DOWN)} />)}
                                            </td>
                                            <td>{c.name}</td>
                                            <td>{c.title}</td>
                                            <td>{c.subtitle}</td>
                                            <td>{c.start}</td>
                                            <td>{c.finish}</td>
                                            <td>{c.inHomepage ? 'Sim' : 'Não'}</td>
                                            <td className="d-flex w-100 justify-content-end trash-icon-cell">
                                                <SvgIcon icon={ICON.TRASH} callback={(e: React.MouseEvent) => this.onDeleteCampaign(e, c)} />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                            )}
                            <tr><br /></tr>
                            {campaignsNotHomePage.length > 0 && (
                                <>
                                    <th colSpan={9} className="font-weight-bold text-nowrap mt-20">{t('admin.campaigns.labels.campaignsOutsideHomepage')}</th>
                                    <tbody>
                                        {campaignsNotHomePage.map(c => {
                                            return (
                                                <tr key={c.id} onClick={() => this.onCampaignClick(c)} className="clickable">
                                                    <td>
                                                        <img className="banner-img" src={resourcesURL(c.desktopImageHash)} alt="" />
                                                    </td>
                                                    <td>N.A.</td>
                                                    <td>{c.name}</td>
                                                    <td>{c.title}</td>
                                                    <td>{c.subtitle}</td>
                                                    <td>{c.start}</td>
                                                    <td>{c.finish}</td>
                                                    <td>{c.inHomepage ? 'Sim' : 'Não'}</td>
                                                    <td className="d-flex w-100 justify-content-end trash-icon-cell">
                                                        <SvgIcon icon={ICON.TRASH} callback={(e: React.MouseEvent) => this.onDeleteCampaign(e, c)} />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </>
                            )}
                        </>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan={4}>
                                    {t('global.noData')}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
                {hasData && (
                    <TablePaging
                        currentPage={currentPage}
                        limit={_limit}
                        totalResults={totalResults}
                        onStartChange={onPagingChange}
                    />
                )}
                <Modal show={showCampaignModal} onHide={this.onCloseCampaignModal} centered size="lg">
                    {this.renderModal()}
                </Modal>
            </div>
        );
    }
}

export default withPaging(withTranslationContext(Campaigns));

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import MediaQuery from 'react-responsive';
import { RouteComponentProps } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import ProductsAdmin from './ProductsAdmin';
import EditStore from './EditStore';
import OrdersList from './OrdersList';
import StoreManagerProfile from '../UserProfile';
import { MatchParams, RouteState, SelectOption } from '../../../constants/misc';
import FormSelectField from '../../elements/FormSelectField';
import UserProfile from '../UserProfile';
import ShippingConfig from './ShippingConfig';

interface OwnProps extends TranslationContext, RouteComponentProps<MatchParams, {}, RouteState> {}

interface OwnState {
    activeTabKey: string;
    tabOptions: Array<SelectOption>;
}

const initialState: OwnState = {
    activeTabKey: 'products',
    tabOptions: [],
};

class StoreManagerAdminScreen extends Component <OwnProps, OwnState> {
    state = { ...initialState }

    componentDidMount(): void {
        const { t, match } = this.props;
        const { params } = match;
        const { id } = params;

        const tabOptions: Array<SelectOption> = [];
        tabOptions.push({
            value: 'products',
            label: t('storeAdmin.tabs.products'),
        });
        tabOptions.push({
            value: 'editStore',
            label: t('storeAdmin.tabs.editStore'),
        });
        tabOptions.push({
            value: 'orders',
            label: t('storeAdmin.tabs.orders'),
        });
        tabOptions.push({
            value: 'shipping',
            label: t('storeAdmin.tabs.shipping'),
        });
        tabOptions.push({
            value: 'profile',
            label: t('storeAdmin.tabs.profile'),
        });

        this.setState({
            tabOptions,
        }, () => {
            if (id) {
                this.setState({
                    activeTabKey: 'orders',
                });
            }
        });
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value,
        });
    };

    onTabChange = (eventKey: string) => {
        this.setState({
            activeTabKey: eventKey,
        });
    };

    renderSmallItems = () => {
        const { activeTabKey } = this.state;

        switch (activeTabKey) {
            case 'products':
                return <ProductsAdmin />;
            case 'editStore':
                return <EditStore />;
            case 'orders':
                return <OrdersList />;
            case 'shipping':
                return <ShippingConfig />;
            case 'profile':
                return <UserProfile />;
            default:
                return <React.Fragment />;
        }
    };

    render() {
        const { t } = this.props;
        const { activeTabKey, tabOptions } = this.state;

        return (
            <div className="container-fluid app_screen">
                <div className="app_screen__centered margin-top-bar">
                    <div className="page-title">{t('storeAdmin.title')}</div>
                </div>
                <div className="app-tabs top-margin">
                    <MediaQuery maxWidth={600}>
                        <div className="px-2">
                            <FormSelectField
                                name="activeTabKey"
                                value={activeTabKey}
                                options={tabOptions}
                                onChange={this.onInputChange}
                            />
                        </div>
                        {this.renderSmallItems()}
                    </MediaQuery>
                    <MediaQuery minWidth={601}>
                        <Tabs
                            id="tab"
                            defaultActiveKey="categories"
                            activeKey={activeTabKey}
                            onSelect={this.onTabChange}
                        >
                            <Tab eventKey="products" title={t('storeAdmin.tabs.products')}>
                                {(activeTabKey === 'products') && (
                                    <ProductsAdmin />
                                )}
                            </Tab>
                            <Tab eventKey="editStore" title={t('storeAdmin.tabs.editStore')}>
                                {(activeTabKey === 'editStore') && (
                                    <EditStore />
                                )}
                            </Tab>
                            <Tab eventKey="orders" title={t('storeAdmin.tabs.orders')}>
                                {(activeTabKey === 'orders') && (
                                    <OrdersList />
                                )}
                            </Tab>
                            <Tab eventKey="shipping" title={t('storeAdmin.tabs.shipping')}>
                                {(activeTabKey === 'shipping') && (
                                    <ShippingConfig />
                                )}
                            </Tab>
                            <Tab eventKey="profile" title={t('storeAdmin.tabs.profile')}>
                                {(activeTabKey === 'profile') && (
                                    <StoreManagerProfile />
                                )}
                            </Tab>
                        </Tabs>
                    </MediaQuery>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(StoreManagerAdminScreen);

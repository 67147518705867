import React from 'react';

export const CookiesPolicy = () => {
    return (
        <div className="terms-screen__content">
            <h4>Política de Cookies </h4>
            <hr className="divider my-4" />
            <div className="text-container">
                <p>
                    O Site utiliza Cookies, entendendo-se estes como arquivos de texto que identificam o computador do Utilizador no servidor
                    da <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b>. Deste modo, os Cookies utilizados não identificam individualmente o Utilizador do Site,
                    e, nessa medida, não constituem uma ferramenta de recolha de dados pessoais.
                </p>
            </div>
            <div className="text-container">
                <p>
                    Os cookies utilizados armazenam as preferências dos Utilizadores, proporcionando a estes uma utilização mais rápida e eficiente do Site.
                </p>
            </div>
            <div className="text-container">
                <p>
                    Em concreto, o Site utiliza os seguintes Cookies:
                </p>
                <p>
                    Cookies de sessão: São cookies temporárias que permanecem em arquivo de cookies do navegador até que este abandone a página web, pelo que
                    não fica qualquer registo no disco rígido do Utilizador. A informação recolhida por estes cookies serve para analisar padrões de tráfego do site.
                    Em última análise, isso permite-nos melhorar o conteúdo e melhorar a usabilidade do site.
                </p>
            </div>

            <div className="text-container">
                <p>
                    Cookies de análise: São aqueles que bem tratados por nós ou por terceiros, nos permitem quantificar o número de Utilizadores e realizar a medição
                    e análise estatística de como os Utilizadores usam o serviço oferecido. Examinamos a sua navegação no nosso site, com o objetivo de melhorar o fornecimento de produtos ou serviços que lhe disponibilizamos.
                </p>
            </div>

            <div className="text-container">
                <p>
                    Cookies publicitárias: São aqueles que bem tratadas por nós ou por terceiros, nos permitem gerir de uma forma mais eficaz a oferta de espaços
                    publicitários que existem no site, podendo analisar os seus hábitos de navegação e mostrar-lhe publicidade relacionada com o seu perfil de navegação.
                </p>
            </div>

            <div className="text-container">
                <p>
                    O Utilizador poderá optar pelo não uso e correspondente apagamento dos Cookies acima referidos, mas, nesse caso, poderá suceder que o funcionamento
                    do Site sofra alterações ou limitações no que respeita à sua navegação.
                </p>
            </div>

        </div>
    );
};

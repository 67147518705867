import React, { Component } from 'react';
import axios from 'axios';
import { decode } from 'jsonwebtoken';
import { RouteComponentProps } from 'react-router-dom';
import { get } from 'lodash';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { MatchParams } from '../../constants/misc';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';
import FormTextField from '../elements/FormTextField';
import Button from '../elements/Button';
import { INDEX_ROUTE } from '../../constants/routes';
import Loader from '../elements/Loader';
import { userResetPassword } from '../../services/users';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { CartContext, withCartContext } from '../controllers/cart/CartContext';

interface OwnProps extends AuthenticationContext, RouteComponentProps<MatchParams>, TranslationContext, CartContext {}

interface OwnState {
    password: string;
    passwordConfirmation: string;
    token: string;
    formErrors: any;
    isFetching: boolean;
}

const initialState: OwnState = {
    password: '',
    passwordConfirmation: '',
    token: '',
    formErrors: null,
    isFetching: false,
};

class ResetPasswordScreen extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    componentDidMount(): void {
        const {
            history, location, isAuthenticated, logout,
        } = this.props;

        if (isAuthenticated) {
            logout();
        }

        const { search } = location;
        if (search && search.trim().length > 0 && search.includes('?t=')) {
            const token: string = search.split('=')[1];
            if (token && token.trim().length > 0) {
                this.setState({
                    token,
                });
            }
        } else {
            history.push(INDEX_ROUTE);
        }
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value,
        });
    };

    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const { password, token } = this.state;

        if (this.validateFields()) {
            const fields = {
                password,
                resetPasswordToken: token,
            };

            this.sendResetPasswordRequest(fields);
        }
    }

    sendResetPasswordRequest = (fields: object) => {
        const {
            t, login, history, cartId,
        } = this.props;
        const { password } = this.state;
        this.setState({ isFetching: true });

        axios.post(userResetPassword(), fields)
            .then(response => {
                const { headers } = response;
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('resetPassword.successMessage'));

                const jwtData: any = decode(headers.authorization);
                const { email } = jwtData;

                login({ email, password, cartId }, () => {
                    this.setState({
                        isFetching: false,
                    }, () => {
                        history.push(INDEX_ROUTE);
                    });
                });
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('resetPassword.errorMessage'));
                this.setState({ isFetching: false });
            });
    }

    validateFields = () => {
        let errors: IFormError | null = getFormErrors(this.state, VALIDATIONS.RESET_PASSWORD);

        if (!errors) errors = {};
        const { password, passwordConfirmation } = this.state;
        if (password && passwordConfirmation && password !== passwordConfirmation) {
            errors.passwordConfirmation = [{ typeOfViolation: 'passwordsDontMatch' }];
        }

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    }

    render() {
        const { t } = this.props;
        const {
            password,
            passwordConfirmation,
            formErrors,
            isFetching,
        } = this.state;

        return (
            <div className="container-fluid app_screen">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="app_screen__centered margin-top-bar">
                    <div className="page-title">
                        {t('resetPassword.title')}
                    </div>
                    <form className="login-form-content" onSubmit={this.onSubmit}>
                        <FormTextField
                            name="password"
                            value={password}
                            type="password"
                            label={t('resetPassword.labels.newPassword')}
                            placeholder={t('resetPassword.labels.newPassword')}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.password', null)}
                        />
                        <FormTextField
                            name="passwordConfirmation"
                            value={passwordConfirmation}
                            type="password"
                            label={t('resetPassword.labels.passwordConfirmation')}
                            placeholder={t('resetPassword.labels.passwordConfirmation')}
                            onChange={this.onInputChange}
                            errors={get(formErrors, 'fields.passwordConfirmation', null)}
                        />
                        <Button
                            type="submit"
                            text={t('global.buttons.accept')}
                            styles="button--dark-blue"
                        />
                    </form>
                </div>
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(withCartContext(ResetPasswordScreen)));

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';
import { ReactCookieProps, withCookies } from 'react-cookie';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import TagManager, { DataLayerArgs } from 'react-gtm-module';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import Loader from './Loader';
import {
    CharacteristicType, MatchParams, RouteState, SelectOption,
} from '../../constants/misc';
import Button from './Button';
import {
    ALL_PRODUCTS_VIEW_ROUTE,
    INDEX_ROUTE,
    NOT_FOUND_ROUTE,
    PRODUCT_VIEW_ROUTE,
} from '../../constants/routes';
import { productGroupPublicURL, productURL } from '../../services/products';
import {
    CompoundSellable,
    DeliveryMethods,
    Store,
    VariantItem,
} from '../../constants/types';

import { resourcesURL } from '../../services/resources';
import {
    numberToCurrency, tagsStringBuilder, scrollToTop, checkProductWithinPromotion,
} from '../../utils/misc';
import {
    formatNamesForUrl, getFormattedProductURL, getFormattedStoreURL, getTrueID,
} from '../../utils/urls';
import { ICON, SvgIcon } from './SvgIcon';
import { CartContext, withCartContext } from '../controllers/cart/CartContext';
import { AuthorizationContext, withAuthorizationContext } from '../controllers/authorization/AuthorizationContext';
import { checkPermission } from '../../utils/authorization';
import { PermissionKey } from '../../constants/authorization';
import { AuthenticationContext, withAuthenticationContext } from '../controllers/authentication/AuthenticationContext';
import { GeneralContext, withGeneralContext } from '../controllers/general/GeneralContext';
import ShowHideField from './ShowHideField';
import iconInfo from '../../assets/images/ic-info.svg';
import iconDelivery from '../../assets/images/ic-delivery.svg';
import iconCreditCard from '../../assets/images/ic-credit-card.svg';
import iconCommerce from '../../assets/images/icon-commerce.svg';
import iconShoppingCartSmall from '../../assets/images/ic-shopping-cart-small.svg';
import iconArrow from '../../assets/images/icon-down_arrow_blue.svg';

import FormSelectField from './FormSelectField';
import ProductColorButtons from './ProductColorButtons';
import ButtonImgIcon from './ButtonImgIcon';
import { API_URL } from '../../settings';
import { storesCourierURL } from '../../services/stores';
import { getProductAllowedHighlightURL, setProductHighlightURL } from '../../services/admin';
import { displayNotification, NOTIFICATION_TYPE } from '../../utils/notifs';
import DiscountBox from './DiscountBox';

interface OwnProps extends RouteComponentProps<MatchParams, {}, RouteState>, GeneralContext,
    TranslationContext, CartContext, AuthorizationContext, AuthenticationContext,
    ReactCookieProps {}

interface OwnState {
    isFetching: boolean;
    productId: string;
    product: CompoundSellable;
    store: Store;
    storeId: string;
    tagsStr: string;
    quantity: number;
    productVariants: Array<CompoundSellable>;
    prices: Array<SelectOption>;
    colors: Array<SelectOption>;
    sizes: Array<SelectOption>;
    dimensions: Array<VariantItem>;
    weights: Array<VariantItem>;
    models: Array<VariantItem>;
    colorSelected: {
        label: string,
        value: string,
    };
    sizeSelected: string | null;
    priceSelected: number | null;
    characteristicFirstSelect: CharacteristicType | null;
    locationFrom: string | null;
    showImageModal: boolean;
    currentImage: string;
    deliveryMethods: Array<DeliveryMethods>;
    canHighlight: boolean;
}

const initialState: OwnState = {
    isFetching: false,
    productId: '',
    storeId: '',
    tagsStr: '',
    prices: [],
    colors: [],
    sizes: [],
    dimensions: [],
    weights: [],
    models: [],
    quantity: 1,
    colorSelected: {
        label: '',
        value: '',
    },
    characteristicFirstSelect: null,
    sizeSelected: null,
    priceSelected: null,
    locationFrom: null,
    productVariants: [],
    product: {
        id: -1,
        description: '',
        shortDescription: '',
        category: null,
        inPromotion: false,
        lastPrice: null,
        price: null,
        reference: '',
        tags: [],
        brand: '',
        tax: null,
        thumbnailHash: '',
        photosHash: [],
        packingWeight: null,
        visibleOnlyOnGroup: false,
        groupId: null,
        size: '',
        color: '',
        colorHex: '',
        inStock: false,
        length: null,
        width: null,
        height: null,
        availableDeliveryMethods: [],
        voucher: false,
        email: '',
        voucherBonus: 0,
        storeInfo: {
            id: -1,
            name: '',
            slug: '',
        },
    },
    store: {
        id: -1,
        address: '',
        closeToLaunch: false,
        closeToLaunchOn: '',
        openFromLaunchOn: '',
        contact: '',
        description: '',
        email: '',
        homeDelivery: false,
        latitude: null,
        longitude: null,
        name: '',
        thumbnailHash: '',
        website: '',
        postalCode: '',
        thumbnail: null,
        categoryId: null,
        tags: [],
        weekSchedule: [],
        photosHash: [],
        history: {
            description: '',
            photo1: '',
            photo2: '',
            photo3: '',
            photo4: '',
            title: '',
        },
        bannerHash: '',
    },
    showImageModal: false,
    currentImage: '',
    deliveryMethods: [],
    canHighlight: true,
};

const sliderRef = React.createRef<Slider>();

const productAttributeLabels = {
    sizeLabel: 'productDetails.size',
    colorLabel: 'productDetails.color',
    priceLabel: 'productDetails.price',
    amountLabel: 'productDetails.amount',
};

class ProductDetails extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    componentDidMount(): void {
        const { match, history } = this.props;
        const { params } = match;
        const { productId } = params;

        setTimeout(() => {
            scrollToTop();
        },
        200);

        if (productId) {
            const realId = getTrueID(productId);

            this.setState({
                productId: realId,
            }, () => {
                this.prepare().then(() => this.validateDeliveryMethods());
            });
        } else {
            history.push(NOT_FOUND_ROUTE);
        }
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const { productId: oldId } = prevState;
        const { match } = this.props;
        const { params } = match;
        const { productId } = params;

        const realId = getTrueID(String(productId));

        if (productId && realId !== oldId) {
            this.setState({
                productId: realId,
            }, () => {
                this.prepare().then(() => this.validateDeliveryMethods());
            });
        }
    }

    onBack = () => {
        const { history } = this.props;

        history.push(INDEX_ROUTE);
    };

    onAddQuantity = () => {
        const { quantity, product } = this.state;
        if (!product.inStock) return;

        if (quantity < 99) {
            this.setState({
                quantity: quantity + 1,
            });
        }
    }

    onMinusQuantity = () => {
        const { quantity, product } = this.state;
        if (!product.inStock) return;

        if (quantity > 1) {
            this.setState({
                quantity: quantity - 1,
            });
        }
    }

    onAddToCart = () => {
        const {
            addToCart,
            cartId,
            generateCartId,
        } = this.props;
        const { quantity, product } = this.state;

        const price = checkProductWithinPromotion(product) ? product.discountPrice : product.price;

        if (!cartId) generateCartId();
        addToCart(product.id, quantity);

        const impressionProduct = {
            name: product.shortDescription,
            id: product.id,
            price,
            brand: product.brand,
            category: product.category,
            quantity,
        };

        const gtmECommerceTag: DataLayerArgs = {
            dataLayer: {
                event: 'addToCart',
                ecommerce: {
                    currencyCode: 'EUR',
                    add: {
                        products: [impressionProduct],
                    },
                },
            },
        };

        TagManager.dataLayer(gtmECommerceTag);
    }

    onPushVariant = (variant: CompoundSellable) => {
        const { history } = this.props;
        const { store } = this.state;

        this.setState({
            product: variant,
        });

        history.push(`/${store.slug}${PRODUCT_VIEW_ROUTE}/${formatNamesForUrl(String(variant.category?.description))}/${formatNamesForUrl(variant.shortDescription)}-${variant.id}`);
    }

    onColorSelect = (opt: SelectOption) => {
        const prevState = this.state;
        this.setState({
            ...prevState,
            colorSelected: opt,
        }, this.findVariantProduct);
    }

    onSizeSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            sizeSelected: e.currentTarget.value,
        }, this.findVariantProduct);
    }

    onPriceSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            priceSelected: Number(e.currentTarget.value),
        }, this.findVariantVoucher);
    }

    findVariantVoucher = (): void => {
        const {
            productVariants,
            priceSelected,
        } = this.state;

        const variant = productVariants.find(el => {
            if (priceSelected !== null) {
                return (el.price === priceSelected);
            }
            return false;
        });

        if (variant) {
            this.onPushVariant(variant);
        }
    }

    findVariantProduct = (): void => {
        const {
            productVariants,
            sizeSelected,
            colorSelected,
        } = this.state;

        let variant = productVariants.find(el => {
            if (colorSelected.value !== null && sizeSelected) {
                return (el.colorHex === colorSelected.value && el.size === sizeSelected);
            }
            if (colorSelected.value !== null) {
                return (el.colorHex === colorSelected.value);
            }
            if (sizeSelected) {
                return (el.size === sizeSelected);
            }
            return false;
        });

        if (variant) {
            this.onPushVariant(variant);
        } else {
            variant = productVariants.find(el => {
                return (el.colorHex === colorSelected.value);
            });

            if (variant) {
                this.onPushVariant(variant);
            }
        }
    }

    getProductVariants = () => {
        const { product, colorSelected, priceSelected } = this.state;

        if (!product.groupId) {
            if (!product.voucher) {
                this.setState({
                    isFetching: false,
                    productVariants: [],
                    colors: [{
                        value: product.colorHex,
                        label: product.color,
                    }],
                    sizes: [{
                        value: product.size,
                        label: product.size,
                    }],
                });
            } else {
                this.setState({
                    isFetching: false,
                    productVariants: [],
                    prices: [{
                        value: product.price,
                        label: numberToCurrency(Number(product.price)),
                    }],
                });
            }
        }

        if (product.storeInfo && product.storeInfo.id && String(product.storeInfo.id).trim() !== ''
            && product.groupId && String(product.groupId).trim() !== '') {
            this.setState({ isFetching: true });
            axios.get(productGroupPublicURL(product.storeInfo.id, product.groupId))
                .then(response => {
                    const { data } = response;

                    const filtered: Array<CompoundSellable> = data;
                    let selectColors: Array<SelectOption> = [];
                    let selectSizes: Array<SelectOption> = [];
                    const selectPrices: Array<SelectOption> = [];

                    filtered.forEach((p: CompoundSellable) => {
                        if (!selectColors.find(el => {
                            return (el.value === p.colorHex && el.label === p.color);
                        })) {
                            selectColors.push({
                                label: p.color,
                                value: p.colorHex,
                            });
                        }

                        if (!selectSizes.find(el => {
                            return el.value === p.size;
                        })) {
                            selectSizes.push({
                                label: (p.size && p.size !== '') ? p.size : 'N.A.',
                                value: p.size,
                                disabled: filtered.find(aux => {
                                    if (colorSelected.value !== '') {
                                        return aux.size === p.size && (aux.colorHex === colorSelected.value);
                                    }
                                    return aux.size === p.size && aux.colorHex === '';
                                }) === undefined,
                            });
                        }

                        if (!selectPrices.find(el => {
                            return el.value === p.price;
                        })) {
                            selectPrices.push({
                                label: numberToCurrency(Number(p.price)),
                                value: p.price,
                                disabled: priceSelected === p.price,
                            });
                        }
                    });

                    selectColors = selectColors.filter((opt, idx) => {
                        return selectColors.indexOf(opt) === idx;
                    });

                    selectSizes = selectSizes.filter((opt, idx) => {
                        return selectSizes.indexOf(opt) === idx;
                    }).sort((a, b) => {
                        if (a.disabled && !b.disabled) return 1;
                        if (!a.disabled && b.disabled) return -1;
                        return -1;
                    });

                    this.setState({
                        isFetching: false,
                        productVariants: [...filtered],
                        colors: selectColors,
                        sizes: selectSizes,
                        prices: selectPrices,
                    });
                })
                .catch(() => {
                    this.setState({
                        isFetching: false,
                    });
                });
        }
    }

    sendGoogleProductImpression = () => {
        const { product } = this.state;

        const impressionProduct = {
            name: product.shortDescription,
            id: product.id,
            price: product.price,
            brand: product.brand,
            category: product.category,
        };

        const gtmECommerceTag: DataLayerArgs = {
            dataLayer: {
                ecommerce: {
                    detail: {
                        products: [impressionProduct],
                    },
                },
            },
        };

        TagManager.dataLayer(gtmECommerceTag);
    }

    checkHighlightCap = async () => {
        const { user, permissions } = this.props;

        if (user && checkPermission(permissions, [PermissionKey.ALL])) {
            const { data: canHighlight } = await axios.get(getProductAllowedHighlightURL());

            this.setState({
                canHighlight,
            });
        }
    }

    prepare = async () => {
        const { addToLastSeenProducts } = this.props;
        const { productId, isFetching } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        this.checkHighlightCap();

        await axios.get(productURL(productId))
            .then(productResponse => {
                const { data } = productResponse;

                let tagsStr = '';
                if (data.tags && data.tags.length > 0) {
                    tagsStr = tagsStringBuilder(data.tags);
                }

                this.setState({
                    isFetching: false,
                    tagsStr,
                    store: {
                        ...data.storeInfo,
                    },
                    product: {
                        ...data,
                        thumbnailUrl: resourcesURL(data.thumbnailHash),
                    },
                    colorSelected: {
                        label: data.color,
                        value: data.colorHex,
                    },
                    priceSelected: data.price,
                    sizeSelected: data.size,
                    currentImage: resourcesURL(data.thumbnailHash),
                }, () => {
                    const { product } = this.state;
                    const { history } = this.props;
                    if (history.location.pathname === `${PRODUCT_VIEW_ROUTE}/${product.id}`) {
                        history.push(getFormattedProductURL(product));
                    }
                    this.getProductVariants();
                    this.sendGoogleProductImpression();
                });
                addToLastSeenProducts(Number(productId));
            })
            .catch(() => {
                this.setState({
                    isFetching: false,
                });
            });
    };

    toggleImageModal = () => {
        const { showImageModal } = this.state;
        this.setState({ showImageModal: !showImageModal });
    }

    onClickCarouselImage = (photo: string | undefined) => {
        const prevState = this.state;
        if (photo) {
            this.setState({
                ...prevState,
                currentImage: photo,
            });
        }
    }

    renderCarouselItems(product: CompoundSellable) {
        let photos: Array<string>;
        if (product.thumbnailUrl) {
            photos = Array(product.thumbnailUrl).concat(product.photosHash);
        } else {
            photos = product.photosHash;
        }
        return photos.map(photo => {
            return (
                <div>
                    <img
                        onClick={() => this.onClickCarouselImage(photo)}
                        src={photo}
                        alt=""
                        width="79px"
                    />
                </div>
            );
        });
    }

    validateDeliveryMethods = async () => {
        const { product } = this.state;
        const id = product.storeInfo?.id;

        const arrayAvailableDeliveryMethods: DeliveryMethods[] = product.availableDeliveryMethods as DeliveryMethods[];
        const { data } = await axios.get(storesCourierURL());
        const isCourier = data.find((el: Store) => el.id === id) !== undefined;

        let arrAux: DeliveryMethods[];
        if (isCourier) {
            arrAux = arrayAvailableDeliveryMethods.filter(el => {
                return el !== DeliveryMethods.THIRD_PARTY;
            });
        } else {
            arrAux = arrayAvailableDeliveryMethods.filter(el => {
                return el !== DeliveryMethods.COURIER;
            });
        }

        this.setState({
            deliveryMethods: arrAux,
        });
    }

    onToggleHighlighted = () => {
        const { product } = this.state;

        this.setState({
            isFetching: true,
        });

        axios.put(setProductHighlightURL(product.id, !product.highlight))
            .then()
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, 'Ocorreu um erro a adicionar produto aos destaques');
            })
            .finally(() => {
                this.setState({
                    isFetching: false,
                }, () => {
                    this.prepare();
                    this.checkHighlightCap();
                });
            });
    }

    getPromotionString = (): string => {
        const { t } = this.props;
        const { product } = this.state;
        const { inPromotion, discountStart, discountEnd } = product;

        if (inPromotion && discountStart && discountEnd) {
            const formattedDiscountStart = moment.unix(Number(discountStart)).format('DD-MM-YYYY');
            const formattedDiscountEnd = moment.unix(Number(discountEnd)).format('DD-MM-YYYY');
            return t('productDetails.promotionLabel', { start: formattedDiscountStart, end: formattedDiscountEnd });
        }

        return '';
    }

    renderHighlightButton = (mobile = false) => {
        const { t } = this.props;
        const { canHighlight, product } = this.state;
        const showTooltip = !product.highlight && !canHighlight;
        return (
            <div className="product-details__product-container__information-container__controls-container__controls">
                <Button
                    text={t(product.highlight ? 'productDetails.removeFromHighlights' : 'productDetails.addToHighlights')}
                    styles={product.highlight ? 'button--light-red' : 'button--blue'}
                    callback={() => this.onToggleHighlighted()}
                    disabled={showTooltip}
                />
                {showTooltip && (
                <OverlayTrigger
                    trigger={mobile ? 'click' : 'hover'}
                    placement={mobile ? 'bottom' : 'right'}
                    delay={{ show: 250, hide: 400 }}
                    overlay={props => {
                        return (
                            <Tooltip
                                className="highlight-tooltip"
                                id="highlight-tooltip"
                                {...props}
                            >
                                {t('productDetails.highlightCap')}
                            </Tooltip>
                        );
                    }}
                >
                    <img src={iconInfo} alt="highlight info" />
                </OverlayTrigger>
                )}
            </div>
        );
    }

    render() {
        const { t, permissions, user } = this.props;
        const {
            isFetching,
            product,
            tagsStr,
            quantity,
            productVariants,
            showImageModal,
            store,
            sizeSelected,
            colorSelected,
            sizes,
            colors,
            currentImage,
            deliveryMethods,
            priceSelected,
            prices,
        } = this.state;

        const hasDescription = product.description && String(product.description) !== '';
        const hasTags = tagsStr && tagsStr !== '';
        const hasProductVariants = productVariants.length > 0;

        const allProducts = [...productVariants];
        allProducts.push(product);

        const productSizeFound = allProducts.findIndex(el => {
            return el.size !== '';
        });

        const productColorFound = allProducts.findIndex(el => {
            return el.colorHex !== '';
        });

        const hasProductSize = productSizeFound !== -1;
        const hasProductColor = productColorFound !== -1;

        const hasProductName = product.shortDescription !== undefined && product.shortDescription !== null && String(product.shortDescription) !== '';
        const hasProductCategory = product.category !== undefined && product.category !== null && String(product.category.description) !== '';
        const hasStoreInfo = product.storeInfo && product.storeInfo.id && product.storeInfo.name;

        const imageCount = product.photosHash.length + 1;

        const isProductWithinPromotion = checkProductWithinPromotion(product);

        const sliderSettings = {
            arrows: false,
            autoplay: true,
            vertical: true,
            draggable: true,
            touchMove: true,
            initialSlide: -1,
            focusOnSelect: true,
        };

        const sliderSettingsMobile = {
            arrows: false,
            autoplay: true,
            vertical: false,
            draggable: true,
            touchMove: true,
            initialSlide: -1,
            focusOnSelect: true,
        };

        return (
            <>
                <Helmet>
                    <meta property="og:title" content={product.shortDescription} />
                    <meta property="og:description" content={product.description} />
                    <meta property="og:image:width" content="1150" />
                    <meta property="og:image:height" content="1150" />
                    <meta property="og:image" content={`${API_URL}/resources/view/public/${product.thumbnailHash}`} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://leiriamarket.pt" />
                </Helmet>
                <div className="app_screen__store_screen">
                    <div className="product-details container-fluid mt-3">
                        {isFetching && (
                        <div className="loader-wrapper">
                            <Loader />
                        </div>
                        )}
                        <div className="product-details__top-container">
                            <MediaQuery minWidth={993}>
                                {hasProductCategory && hasProductName && hasStoreInfo && (
                                <div className="product-details__top-container__links">
                                    <p onClick={() => this.onBack()}>{t('productDetails.backLinks.index')}</p>&nbsp;/&nbsp;
                                    <a href={getFormattedStoreURL(store)}>{product.storeInfo?.name}</a>
                                    &nbsp;/&nbsp;
                                    {product.category && (
                                        <>
                                            <a href={`${ALL_PRODUCTS_VIEW_ROUTE}?categoryId=${product.category.id}`}>{product.category.description}</a>
                                            &nbsp;/&nbsp;
                                        </>
                                    )}
                                    <div className="product-details__top-container__links--current">{product.shortDescription}</div>
                                </div>
                                )}
                            </MediaQuery>
                            <MediaQuery maxWidth={992}>
                                {hasProductCategory && hasProductName && hasStoreInfo && (
                                    <div className="product-details__top-container__links--mobile">
                                        <div className="product-details__top-container__links__line1">
                                            <a href={INDEX_ROUTE}>{t('productDetails.backLinks.index')}</a>&nbsp;/&nbsp;
                                            <a href={getFormattedStoreURL(store)}>{product.storeInfo?.name}</a>
                                            &nbsp;/&nbsp;
                                            {product.category && (
                                                <>
                                                    <a href={`${ALL_PRODUCTS_VIEW_ROUTE}?categoryId=${product.category.id}`}>{product.category.description}</a>
                                                    &nbsp;/&nbsp;
                                                </>
                                            )}
                                            <span>{product.shortDescription}</span>
                                        </div>
                                    </div>
                                )}
                            </MediaQuery>
                        </div>
                        {product.id !== -1 && (
                            <>
                                <MediaQuery minWidth={993}>
                                    <div className="product-details__product-container">
                                        <div className="product-details__product-container__header">
                                            <h5>{store.name}</h5>
                                            <h4>{product.shortDescription}</h4>
                                            <div className="d-flex">
                                                <p className="brand">{t('productDetails.brand')}&nbsp;</p>
                                                <p className="brand">{product.brand}</p>
                                            </div>
                                        </div>
                                        <div className="product-details__product-container__information-container">
                                            <div className="product-details__product-container__information-container__carousel-container">
                                                {imageCount > 4 && (
                                                    <img
                                                        className="product-details__product-container__information-container__carousel-container__up-arrow"
                                                        src={iconArrow}
                                                        alt=""
                                                        width={25}
                                                        onClick={() => {
                                                            if (sliderRef.current && sliderRef.current.slickPrev) {
                                                                sliderRef.current.slickPrev();
                                                            }
                                                        }}
                                                    />
                                                )}
                                                <Slider
                                                    ref={sliderRef}
                                                    {...sliderSettings}
                                                    slidesToShow={imageCount > 4 ? 4 : imageCount}
                                                >
                                                    { this.renderCarouselItems(product) }
                                                </Slider>
                                                {imageCount > 4 && (
                                                    <img
                                                        src={iconArrow}
                                                        alt=""
                                                        width={25}
                                                        onClick={() => {
                                                            if (sliderRef.current && sliderRef.current.slickNext) {
                                                                sliderRef.current.slickNext();
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div className="product-details__product-container__information-container__img-container">
                                                {isProductWithinPromotion && <DiscountBox product={product} />}
                                                <img className="product-details__product-container__information-container__img-container__main-img" onClick={this.toggleImageModal} src={currentImage} alt={product.shortDescription} />
                                            </div>
                                            <div className="product-details__product-container__information-container__controls-container">
                                                <div className="product-details__product-container__information-container__controls-container__style-info">
                                                    {product.voucher
                                                        ? (
                                                            <div className="product-details__product-container__information-container__controls-container__style-info__row">
                                                                {hasProductVariants
                                                                && <FormSelectField label={t(productAttributeLabels.priceLabel)} name="sizes" value={priceSelected} options={prices} onChange={this.onPriceSelect} />
                                                                }
                                                                {product.voucherBonus
                                                                && (
                                                                <div className="product-details__product-container__information-container__controls-container__style-info__row__voucher-label">
                                                                    <span className="product-details__product-container__information-container__controls-container__style-info__row__voucher-label__container">
                                                                        <span>{t('productDetails.voucherBonusLabel')}</span>
                                                                        &nbsp;
                                                                        <span className="value">{numberToCurrency(product.voucherBonus)}</span>
                                                                    </span>
                                                                    <div>{t('productDetails.voucherBonusText')}</div>
                                                                </div>
                                                                )}
                                                            </div>
                                                        )
                                                        : (
                                                            <>
                                                                <div className="product-details__product-container__information-container__controls-container__style-info__row">
                                                                    {hasProductSize && hasProductVariants
                                                                && <FormSelectField label={t(productAttributeLabels.sizeLabel)} name="sizes" value={sizeSelected} options={sizes} onChange={this.onSizeSelect} />
                                                                }
                                                                    {hasProductSize && !hasProductVariants
                                                                && <p>{`${t(productAttributeLabels.sizeLabel)}: ${sizeSelected}`}</p>
                                                                }
                                                                </div>
                                                                <div className="product-details__product-container__information-container__controls-container__style-info__row">
                                                                    {hasProductColor && (
                                                                        <>
                                                                            <div className="product-details__product-container__information-container__controls-container__style-info__row__color-label">
                                                                                <p>{`${t(productAttributeLabels.colorLabel)}: `}</p>
                                                                                <div>&nbsp;{(colorSelected.label && colorSelected.label !== '') ? colorSelected.label : 'N.A.'}</div>
                                                                            </div>
                                                                            <ProductColorButtons onChange={opt => { if (colors.length > 1) this.onColorSelect(opt); }} name="colors" initialSelected={colorSelected || { label: '', value: '' }} options={colors} />
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                </div>
                                                <h5>{t(productAttributeLabels.priceLabel)}</h5>
                                                <div className="product-details__product-container__information-container__controls-container__price">
                                                    <div className="d-flex flex-row align-items-end">
                                                        <div className="product-details__product-container__information-container__controls-container__price__values">
                                                            {isProductWithinPromotion
                                                                ? (
                                                                    product.discountPrice && product.price && (
                                                                        <>
                                                                            <p className="product-details__product-container__information-container__controls-container__price__values--old-price">
                                                                                {numberToCurrency(product.price)}
                                                                            </p>
                                                                            <p>{numberToCurrency(product.discountPrice)}</p>
                                                                        </>
                                                                    ))
                                                                : (
                                                                    <p>{numberToCurrency(product.price || 0)}</p>
                                                                )}
                                                        </div>
                                                        <div className="product-details__product-container__information-container__controls-container__price__stock-label">
                                                            {product.inStock
                                                                ? (<p>{t('productDetails.stockLabel.available')}</p>)
                                                                : (<p>{t('productDetails.stockLabel.unavailable')}</p>)
                                                            }
                                                        </div>
                                                    </div>
                                                    {isProductWithinPromotion && (
                                                        <h5>{this.getPromotionString()}</h5>
                                                    )}
                                                </div>
                                                {(!user || checkPermission(permissions, [PermissionKey.CUSTOMER]))
                                                    && (
                                                        <>
                                                            <h5>{t(productAttributeLabels.amountLabel)}</h5>
                                                            <div className="product-details__product-container__information-container__controls-container__controls">
                                                                <div className="quantity-controls">
                                                                    <div className={`controls minus ${product.inStock ? '' : 'disabled'}`} onClick={this.onMinusQuantity}>
                                                                        <SvgIcon icon={ICON.MINUS} />
                                                                    </div>
                                                                    <div className="input-container">
                                                                        <input
                                                                            name="quantity"
                                                                            value={quantity}
                                                                            disabled
                                                                            className={product.inStock ? '' : 'disabled'}
                                                                        />
                                                                    </div>
                                                                    <div className={`controls plus ${product.inStock ? '' : 'disabled'}`} onClick={this.onAddQuantity}>
                                                                        <SvgIcon icon={ICON.PLUS} />
                                                                    </div>
                                                                    <ButtonImgIcon
                                                                        icon={iconShoppingCartSmall}
                                                                        iconPosition="left"
                                                                        text={t('productDetails.addToCart')}
                                                                        disabled={!product.inStock}
                                                                        styles="button--blue"
                                                                        callback={this.onAddToCart}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                {user && checkPermission(permissions, [PermissionKey.ALL]) && this.renderHighlightButton()}
                                            </div>
                                        </div>
                                        <div className="product-details__bottom-container">
                                            <div className="product-details__bottom-container__logistics">
                                                <ShowHideField
                                                    icon={iconInfo}
                                                    title={t('productDetails.infoTitles.details')}
                                                    startsExpanded
                                                >
                                                    {(hasDescription || hasTags) && (
                                                        <>
                                                            {hasDescription && (
                                                            <p>
                                                                {product.description}
                                                            </p>
                                                            )}
                                                            {hasTags && (
                                                            <div className="show-hide-field__content__tags">
                                                                {tagsStr}
                                                            </div>
                                                            )}
                                                        </>
                                                    )}

                                                </ShowHideField>
                                                <ShowHideField
                                                    icon={iconDelivery}
                                                    title={t('productDetails.infoTitles.shipping')}
                                                    startsExpanded
                                                >
                                                    <ul>
                                                        {deliveryMethods.map(itm => {
                                                            return (<li>{t(`enums.deliveryMethods.${itm}`)}</li>);
                                                        })}
                                                    </ul>
                                                </ShowHideField>
                                            </div>
                                            <div className="product-details__bottom-container__taglines">
                                                <div className="product-details__bottom-container__taglines__row">
                                                    <div className="product-details__bottom-container__taglines__row__icon">
                                                        <img alt="" src={iconCommerce} />
                                                    </div>
                                                    <div className="product-details__bottom-container__taglines__row__text">
                                                        <h5>{t('home.benefits.localCommerce')}</h5>
                                                        <p>{t('home.benefits.localCommerceText')}</p>
                                                    </div>
                                                </div>
                                                <div className="product-details__bottom-container__taglines__row">
                                                    <div className="product-details__bottom-container__taglines__row__icon">
                                                        <img alt="" src={iconDelivery} />
                                                    </div>
                                                    <div className="product-details__bottom-container__taglines__row__text">
                                                        <h5>{t('home.benefits.homeDelivery')}</h5>
                                                        <p>{t('home.benefits.homeDeliveryText')}</p>
                                                    </div>
                                                </div>
                                                <div className="product-details__bottom-container__taglines__row">
                                                    <div className="product-details__bottom-container__taglines__row__icon">
                                                        <img alt="" src={iconCreditCard} />
                                                    </div>
                                                    <div className="product-details__bottom-container__taglines__row__text">
                                                        <h5>{t('home.benefits.payOnline')}</h5>
                                                        <p>{t('home.benefits.payOnlineText')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </MediaQuery>

                                <MediaQuery maxWidth={992}>
                                    <div className="product-details__product-container--mobile">
                                        <div className="product-details__product-container__header">
                                            <h5>{store.name}</h5>
                                            <h4>{product.shortDescription}</h4>
                                            <div className="d-flex">
                                                <p className="brand">{t('productDetails.brand')}&nbsp;</p>
                                                <p className="brand">{product.brand}</p>
                                            </div>
                                        </div>
                                        <div className="product-details__product-container__information-container--mobile">
                                            <div className="product-details__product-container__information-container__img-container">
                                                {isProductWithinPromotion && <DiscountBox product={product} />}
                                                <img className="product-details__product-container__information-container__img-container__main-img" src={currentImage} alt={product.shortDescription} />
                                            </div>
                                            <div className="product-details__product-container__information-container__carousel-container">
                                                {imageCount > 4 && (
                                                    <img
                                                        className="product-details__product-container__information-container__carousel-container__up-arrow"
                                                        src={iconArrow}
                                                        alt=""
                                                        width={25}
                                                        onClick={() => {
                                                            if (sliderRef.current && sliderRef.current.slickPrev) {
                                                                sliderRef.current.slickPrev();
                                                            }
                                                        }}
                                                    />
                                                )}
                                                <Slider
                                                    ref={sliderRef}
                                                    {...sliderSettingsMobile}
                                                    slidesToShow={imageCount > 4 ? 4 : imageCount}
                                                >
                                                    { this.renderCarouselItems(product) }
                                                </Slider>
                                                {imageCount > 4 && (
                                                    <img
                                                        className="product-details__product-container__information-container__carousel-container__down-arrow"
                                                        src={iconArrow}
                                                        alt=""
                                                        width={25}
                                                        onClick={() => {
                                                            if (sliderRef.current && sliderRef.current.slickNext) {
                                                                sliderRef.current.slickNext();
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            <div className="product-details__product-container__information-container__controls-container">
                                                <h5>Preço</h5>
                                                <div className="product-details__product-container__information-container__controls-container__price">
                                                    {isProductWithinPromotion
                                                        ? (
                                                            product.discountPrice && product.price && (
                                                                <>
                                                                    <p className="product-details__product-container__information-container__controls-container__price__values--old-price">
                                                                        {numberToCurrency(product.price)}
                                                                    </p>
                                                                    <p>{numberToCurrency(product.discountPrice)}</p>
                                                                </>
                                                            ))
                                                        : (
                                                            <p>{numberToCurrency(product.price || 0)}</p>
                                                        )}
                                                </div>
                                                <div className="product-details__product-container__information-container__controls-container__style-info__row">
                                                    {product.voucher
                                                        ? (
                                                            <div className="product-details__product-container__information-container__controls-container__style-info__row">
                                                                {hasProductVariants
                                                                && <FormSelectField label={t(productAttributeLabels.priceLabel)} name="sizes" value={priceSelected} options={prices} onChange={this.onPriceSelect} />
                                                                }
                                                                {product.voucherBonus
                                                                && (
                                                                <div className="product-details__product-container__information-container__controls-container__style-info__row__color-label">
                                                                    <div>{t('productDetails.voucherBonusLabel', { bonus: numberToCurrency(product.voucherBonus) })}</div>
                                                                </div>
                                                                )}
                                                            </div>
                                                        )
                                                        : (hasProductSize && hasProductVariants
                                                            && <FormSelectField label={t(productAttributeLabels.sizeLabel)} name="sizes" value={sizeSelected} options={sizes} onChange={this.onSizeSelect} />)}
                                                    {hasProductSize && !hasProductVariants
                                                    && <p>{`${t(productAttributeLabels.sizeLabel)}: ${sizeSelected}`}</p>
                                                    }
                                                </div>
                                                <div className="product-details__product-container__information-container__controls-container__style-info__row">
                                                    {hasProductColor && (
                                                        <>
                                                            <div className="product-details__product-container__information-container__controls-container__style-info__row__color-label">
                                                                <p>{`${t(productAttributeLabels.colorLabel)}: `}</p>
                                                                <div>&nbsp;{(colorSelected.label && colorSelected.label !== '') ? colorSelected.label : 'N.A.'}</div>
                                                            </div>
                                                            <ProductColorButtons onChange={opt => { if (colors.length > 1) this.onColorSelect(opt); }} name="colors" initialSelected={colorSelected || { label: '', value: '' }} options={colors} />
                                                        </>
                                                    )}
                                                </div>
                                                {(!user || checkPermission(permissions, [PermissionKey.CUSTOMER])) && (
                                                    <>
                                                        <h5>{t(productAttributeLabels.amountLabel)}</h5>
                                                        <div className="product-details__product-container__information-container__controls-container__controls">
                                                            <div className="d-flex flex-column align-items-left">
                                                                <div className="quantity-controls">
                                                                    <div className={`controls minus ${product.inStock ? '' : 'disabled'}`} onClick={this.onMinusQuantity}>
                                                                        <SvgIcon icon={ICON.MINUS} />
                                                                    </div>
                                                                    <div className="input-container">
                                                                        <input
                                                                            name="quantity"
                                                                            value={quantity}
                                                                            disabled
                                                                            className={product.inStock ? '' : 'disabled'}
                                                                        />
                                                                    </div>
                                                                    <div className={`controls plus ${product.inStock ? '' : 'disabled'}`} onClick={this.onAddQuantity}>
                                                                        <SvgIcon icon={ICON.PLUS} />
                                                                    </div>
                                                                </div>
                                                                <Button
                                                                    text={t('productDetails.addToCart')}
                                                                    styles="button--blue"
                                                                    disabled={!product.inStock}
                                                                    callback={this.onAddToCart}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {user && checkPermission(permissions, [PermissionKey.ALL]) && this.renderHighlightButton(true)}
                                            </div>
                                        </div>
                                        <div className="product-details__bottom-container--mobile">
                                            <div className="product-details__bottom-container__logistics">
                                                <ShowHideField
                                                    icon={iconInfo}
                                                    title={t('productDetails.infoTitles.details')}
                                                    startsExpanded
                                                >
                                                    {(hasDescription || hasTags) && (
                                                        <>
                                                            {hasDescription && (
                                                                <p>
                                                                    {product.description}
                                                                </p>
                                                            )}
                                                            {hasTags && (
                                                                <div className="show-hide-field__content__tags">
                                                                    {tagsStr}
                                                                </div>
                                                            )}
                                                        </>
                                                    )}

                                                </ShowHideField>
                                                <ShowHideField
                                                    icon={iconDelivery}
                                                    title={t('productDetails.infoTitles.shipping')}
                                                    startsExpanded
                                                >
                                                    <ul>
                                                        {deliveryMethods.map(itm => {
                                                            return (<li>{t(`enums.deliveryMethods.${itm}`)}</li>);
                                                        })}
                                                    </ul>
                                                </ShowHideField>
                                            </div>
                                            <div className="product-details__bottom-container__taglines">
                                                <div className="product-details__bottom-container__taglines__row">
                                                    <div className="product-details__bottom-container__taglines__row__icon">
                                                        <img alt="" src={iconCommerce} />
                                                    </div>
                                                    <div className="product-details__bottom-container__taglines__row__text">
                                                        <h5>{t('home.benefits.localCommerce')}</h5>
                                                        <p>{t('home.benefits.localCommerceText')}</p>
                                                    </div>
                                                </div>
                                                <div className="product-details__bottom-container__taglines__row">
                                                    <div className="product-details__bottom-container__taglines__row__icon">
                                                        <img alt="" src={iconDelivery} />
                                                    </div>
                                                    <div className="product-details__bottom-container__taglines__row__text">
                                                        <h5>{t('home.benefits.homeDelivery')}</h5>
                                                        <p>{t('home.benefits.homeDeliveryText')}</p>
                                                    </div>
                                                </div>
                                                <div className="product-details__bottom-container__taglines__row">
                                                    <div className="product-details__bottom-container__taglines__row__icon">
                                                        <img alt="" src={iconCreditCard} />
                                                    </div>
                                                    <div className="product-details__bottom-container__taglines__row__text">
                                                        <h5>{t('home.benefits.payOnline')}</h5>
                                                        <p>{t('home.benefits.payOnlineText')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </MediaQuery>
                            </>
                        )}
                        <Modal className="modal-custom modal-product-image" show={showImageModal} onHide={this.toggleImageModal} centered size="lg">
                            <Modal.Body>
                                <img src={currentImage} alt={product.shortDescription} />
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </>
        );
    }
}

export default withGeneralContext(withAuthorizationContext(withAuthenticationContext(withCartContext(withRouter(withTranslationContext(withCookies(ProductDetails)))))));

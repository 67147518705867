/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { PaymentReferenceInfo } from './types';

/**
 * provides an object that can be accessed by key dynamically
 * @typedef KeyedObject
 * @property {any} [key:string]
 */
export interface KeyedObject {
    [key:string]: any;
}

/**
 * provides an object for select options
 * @typedef SelectOption
 * @property {any} value
 * @property {any} label
 */
export type SelectOption = {
    value: any;
    label: any;
    disabled?: boolean;
}

/**
 * for use in RouteComponentProps<MatchParams>
 * @typedef MatchParams
 * @property {string} id
 * @property {string} name
 */
export interface MatchParams {
    id?: string;
    productId?: string;
    name?: string;
    token?: string;
    t?: string;
}

export interface RouteState {
    search?: string;
    from?: string;
    paymentReferenceInfo?: PaymentReferenceInfo | null,
    mbWayContact?: string,
    activeTabKey?: string,
    orderId?: string,
}

export enum CharacteristicType {
    COLOR = 'COLOR',
    DIMENSION = 'DIMENSION',
    MODEL = 'MODEL',
    SIZE = 'SIZE',
    WEIGHT = 'WEIGHT'
}

export enum PaymentMethods {
    DEBIT_CARD = 'DEBIT_CARD',
    MBWAY = 'MBWAY',
    CREDIT_CARD = 'CREDIT_CARD',
}

export enum StoreScreenTabs {
    PRODUCTS = 'PRODUCTS',
    HISTORY = 'HISTORY',
    CONTACTS = 'CONTACTS',
}

export const VAT = 1.23;

export const ibanCodeLengths: {[key: string]: number} = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20,
};

export const enabledSubcategories = false;

export const enabledStoreAltPhotos = false;

export const globalButtonLabels = {
    ok: 'global.buttons.ok',
    cancel: 'global.buttons.cancel',
    upload: 'global.buttons.upload',
    accept: 'global.buttons.accept',
    add: 'global.buttons.add',
};

export const cssControlClasses = {
    active: '--active',
    inactive: '--inactive',
};

export const charityStoreTextFieldCharLimit = 16383;

export const enabledNormalContactForm = false;

export const enabledProductCampaignFilter = true;

export const SHOW_STORES_SCREEN_FILTERS = false;

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { RouteComponentProps } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import Categories from './Categories';
import Stores from './StoresAdminList';
import { MatchParams, SelectOption } from '../../../constants/misc';
import Users from './StoreManagers';

import FormSelectField from '../../elements/FormSelectField';
import UserProfile from '../UserProfile';
import ShippingPrices from './ShippingPrices';
import Customers from './Customers';
import Sales from './Sales';
import WhatsAppConfig from './WhatsAppConfig';
import Campaigns from './Campaigns';
import ProductsAdmin from '../storeAdmin/ProductsAdmin';
import PaymentFees from './PaymentFees';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext {}

interface OwnState {
    activeTabKey: string;
    tabOptions: Array<SelectOption>;
}

const initialState: OwnState = {
    activeTabKey: 'categories',
    tabOptions: [],
};

class AdminScreen extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    componentDidMount(): void {
        const { t } = this.props;

        const tabOptions: Array<SelectOption> = [];
        tabOptions.push({
            value: 'categories',
            label: t('admin.tabs.categories'),
        });
        tabOptions.push({
            value: 'stores',
            label: t('admin.tabs.stores'),
        });
        tabOptions.push({
            value: 'users',
            label: t('admin.tabs.storeManagers'),
        });
        tabOptions.push({
            value: 'customers',
            label: t('admin.tabs.customers'),
        });
        tabOptions.push({
            value: 'profile',
            label: t('admin.tabs.profile'),
        });
        tabOptions.push({
            value: 'products',
            label: t('admin.tabs.products'),
        });
        tabOptions.push({
            value: 'sales',
            label: t('admin.tabs.sales'),
        });
        tabOptions.push({
            value: 'campaigns',
            label: t('admin.tabs.campaigns'),
        });
        tabOptions.push({
            value: 'paymentFees',
            label: t('admin.tabs.paymentFees'),
        });

        this.setState({
            tabOptions,
        });
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value,
        });
    };

    onTabChange = (eventKey: string) => {
        this.setState({
            activeTabKey: eventKey,
        });
    };

    renderSmallItems = () => {
        const { activeTabKey } = this.state;

        switch (activeTabKey) {
            case 'categories':
                return <Categories />;
            case 'stores':
                return <Stores />;
            case 'users':
                return <Users />;
            case 'profile':
                return <UserProfile />;
            case 'products':
                return <ProductsAdmin />;
            case 'shippingPrices':
                return <ShippingPrices />;
            case 'whatsApp':
                return <WhatsAppConfig />;
            case 'campaigns':
                return <Campaigns />;
            case 'paymentFees':
                return <PaymentFees />;
            default:
        }
        return <React.Fragment />;
    };

    render() {
        const { t } = this.props;
        const { activeTabKey, tabOptions } = this.state;

        return (
            <div className="container-fluid app_screen">
                <div className="app_screen__centered margin-top-bar">
                    <div className="page-title">{t('admin.title')}</div>
                </div>
                <div className="app-tabs top-margin">
                    <MediaQuery maxWidth={600}>
                        <div className="px-2">
                            <FormSelectField
                                name="activeTabKey"
                                value={activeTabKey}
                                options={tabOptions}
                                onChange={this.onInputChange}
                            />
                        </div>
                        {this.renderSmallItems()}
                    </MediaQuery>
                    <MediaQuery minWidth={601}>
                        <Tabs
                            id="tab"
                            defaultActiveKey="categories"
                            activeKey={activeTabKey}
                            onSelect={this.onTabChange}
                        >
                            <Tab eventKey="categories" title={t('admin.tabs.categories')}>
                                {(activeTabKey === 'categories') && (
                                    <Categories />
                                )}
                            </Tab>
                            <Tab eventKey="stores" title={t('admin.tabs.stores')}>
                                {(activeTabKey === 'stores') && (
                                    <Stores />
                                )}
                            </Tab>
                            <Tab eventKey="users" title={t('admin.tabs.storeManagers')}>
                                {(activeTabKey === 'users') && (
                                    <Users />
                                )}
                            </Tab>
                            <Tab eventKey="customers" title={t('admin.tabs.customers')}>
                                {(activeTabKey === 'customers') && (
                                    <Customers />
                                )}
                            </Tab>
                            <Tab eventKey="profile" title={t('admin.tabs.profile')}>
                                {(activeTabKey === 'profile') && (
                                    <UserProfile />
                                )}
                            </Tab>
                            <Tab eventKey="shippingPrices" title={t('admin.tabs.shippingPrices')}>
                                {(activeTabKey === 'shippingPrices') && (
                                    <ShippingPrices />
                                )}
                            </Tab>
                            <Tab eventKey="products" title={t('admin.tabs.products')}>
                                {(activeTabKey === 'products') && (
                                    <ProductsAdmin />
                                )}
                            </Tab>
                            <Tab eventKey="sales" title={t('admin.tabs.sales')}>
                                {(activeTabKey === 'sales') && (
                                    <Sales />
                                )}
                            </Tab>
                            <Tab eventKey="whatsApp" title={t('admin.tabs.whatsApp')}>
                                {(activeTabKey === 'whatsApp') && (
                                    <WhatsAppConfig />
                                )}
                            </Tab>
                            <Tab eventKey="campaigns" title={t('admin.tabs.campaigns')}>
                                {(activeTabKey === 'campaigns') && (
                                    <Campaigns />
                                )}
                            </Tab>
                            <Tab eventKey="paymentFees" title={t('admin.tabs.paymentFees')}>
                                {(activeTabKey === 'paymentFees') && (
                                    <PaymentFees />
                                )}
                            </Tab>
                        </Tabs>
                    </MediaQuery>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(AdminScreen);

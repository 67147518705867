/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    TranslationContext,
    withTranslationContext,
} from '../controllers/translation/TranslationContext';
import { IError } from '../../utils/validation';

export enum ERROR_MESSAGE_TYPE {
    FIELD = 'field',
    LOGIC = 'error',
}

interface OwnProps extends TranslationContext {
    type?: ERROR_MESSAGE_TYPE,
    errors: any,
    field?: string,
    linkTo? : string,
}

class ErrorMessage extends Component<OwnProps> {
    getGeneralError = (error: string) => {
        const { t } = this.props;
        let message = '';

        if (error !== null && error !== undefined) {
            message = t(`errors.${error}`);
        }

        return message;
    };

    getFieldErrorMessage = (error: IError) => {
        const { t } = this.props;
        const {
            min, max, size, typeOfViolation,
        } = error;

        let message = '';

        if (typeOfViolation !== null) {
            switch (typeOfViolation) {
                case 'Email':
                    message = t('errors.Pattern');
                    break;
                case 'Size':
                    if (min && max) {
                        message = t('errors.Size', { min, max });
                    } else if (min) {
                        message = t('errors.SizeMin', { min });
                    } else if (max) {
                        message = t('errors.SizeMax', { max });
                    }
                    break;
                case 'SizeExact':
                    if (size) {
                        message = t('errors.SizeExact', { size });
                    }
                    break;
                case 'Max':
                    if (max) {
                        message = t('errors.Max', { max });
                    }
                    break;
                case 'Min':
                    if (min) {
                        message = t('errors.Min', { min });
                    }
                    break;
                case 'MinLength':
                    if (min) {
                        message = t('errors.MinLength', { min });
                    }
                    break;
                case 'MaxLength':
                    if (max) {
                        message = t('errors.MaxLength', { max });
                    }
                    break;
                default:
                    message = t(`errors.${typeOfViolation}`);
                    break;
            }
        }

        return message;
    };

    render() {
        const {
            field,
            errors,
            type,
            linkTo,
        } = this.props;

        if (!errors) return null;

        if (type === ERROR_MESSAGE_TYPE.LOGIC) {
            if (linkTo) {
                return (
                    <Link to={linkTo}>
                        <p
                            data-field={field}
                            className="field-error general field-error--centered"
                        >
                            {this.getGeneralError(errors)}
                        </p>
                    </Link>
                );
            }
            return (
                <span
                    data-field={field}
                    className="field-error general"
                >
                    {this.getGeneralError(errors)}
                </span>
            );
        }

        const [error] = errors;

        return (
            <span
                data-field={field}
                className="field-error"
            >
                {this.getFieldErrorMessage(error)}
            </span>
        );
    }
}

export default withTranslationContext(ErrorMessage);

import React from 'react';

export const PersonalDataAndPrivacyPolicy = () => {
    return (
        <div className="terms-screen__content">
            <h4>Política de Privacidade e Dados Pessoais</h4>
            <hr className="divider my-4" />
            <div className="text-container">
                <p>
                    A presente Política de Privacidade (“PP”) tem como objetivo proteger a privacidade de todos aqueles que utilizem o
                    Website https://www.leiriamarket.pt, (“Site”), cujo domínio pertence à <b>Startup Leiria Associação para o
                        Empreendedorismo e Inovação
                                                                                           </b>, dando-lhes assim a conhecer o modo como serão recolhidos e tratados os seus dados pessoais.
                </p>
            </div>
            <div className="text-container">
                <p>
                    A <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b>, está obrigada ao cumprimento da legislação aplicável nesta matéria,
                    designadamente das regras constantes no Regulamento Geral de Proteção de Dados (“RGPD”), aprovado pelo Regulamento (UE) 2016/679 do Parlamento Europeu
                    e do Conselho, de 27 de abril de 2016, conjugada com a lei n.º 58/2019 de 8 de agosto, que assegura a execução, na ordem jurídica nacional do
                    Regulamento Europeu.
                </p>
            </div>
            <div className="text-container">
                <p>
                    Assim sendo, solicitamos que leia atentamente esta nossa PP, e,
                    caso surja qualquer questão relacionada com o exercício dos seus direitos nesta matéria, não deixe de entrar em contacto connosco.
                    Poderá fazêlo através do seguinte endereço eletrónico <a href="mailto:apoiocliente@leiriamarket.pt">apoiocliente@leiriamarket.pt</a>
                </p>
            </div>
            <div className="text-container">
                <p>
                    Por fim, note que a disponibilização dos seus dados pessoais no âmbito da utilização do Site implica o conhecimento e a aceitação das
                    condições constantes desta PP, a qual é complementada pelos nossos <b>Termos e Condições de Utilização</b>, e, ainda, pela nossa <b>Política de Cookies</b>.
                </p>
            </div>
            <div className="text-container">
                <p className="bold">
                    1. Dados pessoais
                </p>
                <p>
                    Nos termos do RGPD, “Dado Pessoal” será toda e qualquer “<i>informação relativa a uma pessoa singular identificada ou
                        identificável («titular dos dados»); é considerada identificável uma pessoa singular que possa ser identificada, direta ou
                        indiretamente, em especial por referência a um identificador, como por exemplo um nome, um número de identificação, dados de localização,
                        identificadores por via eletrónica ou a um ou mais elementos específicos da identidade física, fisiológica, genética, mental, económica, cultural
                        ou social dessa pessoa singular;
                                                                             </i>”.
                </p>
            </div>

            <div className="text-container">
                <p className="bold">
                    2. Responsáveis pelo tratamento dos dados pessoais
                </p>
                <p>
                    A responsabilidade pelo tratamento dos dados pessoais que venham a ser transmitidos no âmbito da utilização do Site, independentemente
                    da sua natureza ou titularidade, caberá sempre à <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b>, na pessoa do seu
                    representante, a saber: Francisco Aguiar.
                </p>
                <p>
                    Contudo, no âmbito do tratamento dos dados pessoais dos Compradores – pessoas singulares ou coletivas que utilizem o Site para adquirirem
                    produtos comercializados pelos Vendedores –, a <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b> e os <b>Vendedores</b> atuarão como
                    Responsáveis Conjuntos, cabendo-lhes determinar reciprocamente as finalidades e os meios desse tratamento, bem como delimitar, entre si, e de
                    modo transparente, as respetivas responsabilidades pelo cumprimento da legislação concretamente aplicável, designadamente no que respeita ao
                    exercício dos direitos dos titulares.
                </p>
            </div>
            <div className="text-container">
                <p>
                    Sempre que se justifique, a <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b> poderá recorrer a
                    Subcontratantes, facto para o qual os utilizadores do Site dão, desde já, o seu expresso consentimento.
                </p>
            </div>
            <div className="text-container">
                <p>
                    Em especial, o recurso a Subcontratantes terá lugar para efeitos de: gestão da relação comercial entre Compradores e Vendedores,
                    prestação de serviços de venda e fornecimento, processamento de pagamentos e faturação, garantia de execução de diligências e de
                    operações de backoffice, designadamente ao nível do apoio e assistência técnica e operacional necessária ao funcionamento Site,
                    bem como todas as outras tidas por necessárias e/ou convenientes, a cada momento.
                </p>
            </div>
            <div className="text-container">
                <p>
                    Os Subcontratantes a quem a <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b> recorre, implementarão todas as medidas técnicas
                    e organizativas adequadas à proteção dos dados pessoais que lhes sejam por si transmitidos, de modo a assegurarem a defesa dos direitos e
                    interesses dos respetivos titulares.
                </p>
            </div>
            <div className="text-container">
                <p>
                    Sem prejuízo do explicitado acima, cumpre notar que a política de privacidade dos Vendedores, para cujo Website o Site possa remeter, não é aplicável
                    à <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b>, não sendo esta responsável pelas informações que aí se possam disponibilizar.
                </p>
            </div>
            <div className="text-container">
                <p className="bold">
                    3. Recolha e tratamento de dados pessoais
                </p>
                <p>
                    O acesso e a mera utilização do Site pelos Utilizadores não implica a recolha ou tratamento de qualquer dado pessoal. Todavia,
                    sempre que o Utilizador crie uma conta no Site, serão recolhidos determinados dados, cuja natureza varia consoante a finalidade que lhe
                    esteja associada. As finalidades subjacentes à recolha e tratamento de dados poderão ser consultadas no ponto 7 da presente PP.
                </p>
            </div>
            <div className="text-container">
                <p className="bold">
                    4. Transferências de dados pessoais
                </p>
                <p>
                    Em determinados casos, os dados pessoais que sejam transmitidos pelos Utilizadores do Site poderão ser objeto de transferência para outras
                    entidades que prestem serviço à <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b> que operam foram do espaço da União Europeia.
                </p>
            </div>
            <div className="text-container">
                <p>
                    No caso específico de transferência de dados pessoais para entidades localizadas nos Estados Unidos da América, asseguramos que as mesmas cumprem
                    com os princípios estabelecidos no Privacy Shield (acordo celebrado entre a Comissão Europeia e o U.S. Department of Commerce que impõe às empresas
                    norte-americanas recetoras de dados pessoais recolhidos na União Europeia especiais obrigações de salvaguarda e cautela).
                </p>
            </div>
            <div className="text-container">
                <p className="bold">
                    5. Direitos dos titulares dos dados pessoais
                </p>
                <p>
                    É garantido aos utilizadores do Site, independentemente da qualidade com que atuem, o direito de acesso,
                    retificação, retirada de consentimento, oposição ao tratamento para os fins anteriormente definidos, apagamento,
                    limitação e portabilidade dos dados pessoais que tiverem transmitido.
                </p>
            </div>
            <div className="text-container">
                <p>
                    Os direitos dos utilizadores atrás referidos poderão ser exercidos a todo e qualquer momento, bastando, para esse efeito,
                    que os interessados enviem por escrito tais pedidos para o representante do Responsável pelo Tratamento de Dados Pessoais
                    - <a href="mailto:francisco.aguiar@startupleiria.com">francisco.aguiar@startupleiria.com</a>.
                </p>
            </div>
            <div className="text-container">
                <p>
                    Sem prejuízo do referido nos termos anteriores, os utilizadores do Site que tenham transmitido os seus
                    dados pessoais poderão ainda apresentar, sempre que o considerem pertinente, reclamação junto da Autoridade Nacional de
                    Controlo (Comissão Nacional de Proteção de Dados – CNPD), cujos contactos podem ser consultados em <a href="https://www.cnpd.pt/">https://www.cnpd.pt/</a>.
                </p>
            </div>
            <div className="text-container">
                <p className="bold">
                    6. Período de conservação dos dados pessoais
                </p>
                <p>
                    Os dados pessoais recolhidos e tratados pela <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b> serão conservados durante
                    todo o período de tempo em que se mantiver ativa a inscrição dos utilizadores no Site.
                </p>
            </div>
            <div className="text-container">
                <p>
                    Uma vez extinta a inscrição do utilizador no Site, os dados pessoais que este haja transmitido à <b>Startup Leiria Associação para o
                        Empreendedorismo e Inovação
                                                                                                                     </b> serão conservados até 1 (um) ano a contar daquela data, exceto se outro prazo de conservação decorrer da lei
                    concretamente aplicável.
                </p>
            </div>
            <div className="text-container">
                <p>
                    Contudo, o utilizador do Site pode, a qualquer altura, solicitar à <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b> o apagamento dos
                    dados pessoais que tiver transmitido.
                </p>
            </div>

            <div className="text-container">
                <p className="bold">
                    7. Finalidades associadas à recolha e tratamento dos dados pessoais
                </p>
                <p>
                    Os dados pessoais fornecidos no âmbito da utilização do Site serão tratados e armazenados informaticamente em base de dados especificamente
                    criadas para este efeito e são de fornecimento obrigatório por serem necessários e indispensáveis para o cumprimento das seguintes finalidades:
                    realização da inscrição de Compradores e Vendedores; acesso, utilização e fruição do Site pelos mesmos; execução dos demais fins, incluindo a
                    prestação de serviços a Compradores e Vendedores, a execução das transações comerciais a celebrar entre estes, a gestão comercial dos Compradores e
                    Vendedores, a realização de operações de Marketing e vendas, incluindo o envio de informações sobre novos ou atuais produtos, ofertas ou outras ações
                    promocionais; cumprimento de obrigações legais em vigor (nomeadamente de natureza fiscal, administrativa e judicial).
                </p>
            </div>

            <div className="text-container">
                <p className="bold">
                    8. Medidas de segurança
                </p>
                <p>
                    A <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b> está empenhada na proteção da privacidade dos utilizadores do Site.
                    Assim, recorre a sistemas de segurança altamente inovadores que garantem que todo e qualquer dado pessoal que lhe venha a ser transmitido pelos
                    Utilizadores do Site seja adequadamente armazenado e protegido, prevenindo e impedindo qualquer acesso não autorizado a estes elementos, bem como
                    a sua perda, divulgação, destruição ou uso para além da finalidade que justificou a sua recolha.
                </p>
            </div>
            <div className="text-container">
                <p>
                    Em concreto, a <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b> adota as seguintes medidas de segurança:
                </p>
            </div>
            <div className="text-container">
                <p>
                    - Encriptação de todos os dados pessoais que lhe sejam transmitidos;
                </p>
                <p>
                    - Obrigatoriedade de uso de uma senha pessoal para cada Utilizador;
                </p>
                <p>
                    - Salvaguarda acrescida dos dados relativos aos cartões de débito ou crédito dos Utilizadores. Este tipo de dados não será armazenado,
                    garantindo, assim, que terceiros não possam lhes possam aceder ou utilizar.
                </p>
                <p>
                    - Supervisão constante dos servidores informáticos de modo a reduzir qualquer hipótese de ataque ou situação de vulnerabilidade.
                </p>
            </div>

            <div className="text-container">
                <p className="bold">
                    9. Política de Cookies
                </p>
                <p>
                    O Site utiliza Cookies, entendendo-se estes como arquivos de texto que identificam o computador do Utilizador no servidor
                    da <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b>. Deste modo, os Cookies utilizados não identificam individualmente o
                    Utilizador do Site, e, nessa medida, não constituem uma ferramenta de recolha de dados pessoais. Para mais detalhes consulte a
                    nossa <b>Política de Cookies</b>.
                </p>
            </div>
            <div className="text-container">
                <p className="bold">
                    10. Alterações à Política de Privacidade
                </p>
                <p>
                    A <b>Startup Leiria Associação para o Empreendedorismo e Inovação</b> reserva-se o direito de proceder às alterações que
                    se revelarem necessárias nesta PP. Sempre que tal aconteça, as mesmas serão publicadas no Site.
                </p>
            </div>
        </div>
    );
};

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import { get, orderBy } from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import Modal from 'react-bootstrap/Modal';
import {
    Map, Marker, Popup, TileLayer,
} from 'react-leaflet';
import L, { Icon, LatLng, LeafletMouseEvent } from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import Loader from '../../elements/Loader';
import Button from '../../elements/Button';
import { ContactFeeType, ScheduleItem, Store } from '../../../constants/types';
import ImageUpload from '../../elements/ImageUpload';
import FormTextField from '../../elements/FormTextField';
import { AuthenticationContext, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { storeURL } from '../../../services/stores';
import FormSelectField from '../../elements/FormSelectField';
import StoreSchedule from '../../elements/StoreSchedule';
import { generateId, validateIBAN, validatePtNif } from '../../../utils/misc';
import { categoriesPublicURL } from '../../../services/categories';
import {
    charityStoreTextFieldCharLimit,
    enabledStoreAltPhotos,
    globalButtonLabels,
    SelectOption,
} from '../../../constants/misc';
import { resourcesURL } from '../../../services/resources';
import {
    getFormErrors, IFormError, validateField, VALIDATIONS,
} from '../../../utils/validation';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import FormTextAreaField from '../../elements/FormTextAreaField';
import mapMarker from '../../../assets/images/map_marker.svg';
import { parseTimeToSend, validateHasEmptySchedule, validateHasOverlappingSchedules } from '../../../utils/schedules';

interface OwnProps extends TranslationContext, AuthenticationContext {}

interface OwnState {
    isFetching: boolean;
    formErrors: any;
    showSaveChanges: boolean;
    store: Store;
    categoriesOptions: Array<SelectOption>;
    showErrorEuPagoDownModal: boolean;
    photosToRemove: Array<string>;
    historyImages: {
        historyPhoto1: File | null,
        historyPhoto2: File | null,
        historyPhoto3: File | null,
        historyPhoto4: File | null,
    };
    banner: File | null,
}

const initialState: OwnState = {
    isFetching: false,
    formErrors: null,
    showSaveChanges: false,
    categoriesOptions: [],
    showErrorEuPagoDownModal: false,
    store: {
        id: -1,
        address: '',
        closeToLaunch: false,
        closeToLaunchOn: '',
        openFromLaunchOn: '',
        contact: '',
        description: '',
        email: '',
        homeDelivery: false,
        latitude: null,
        longitude: null,
        name: '',
        thumbnailHash: '',
        website: '',
        postalCode: '',
        thumbnail: null,
        categoryId: null,
        tags: [],
        weekSchedule: [],
        iban: '',
        nif: '',
        certCode: '',
        photosHash: [],
        photos: null,
        bannerHash: '',
        contactFeeType: '',
        history: {
            description: '',
            photo1: '',
            photo2: '',
            photo3: '',
            photo4: '',
            title: '',
        },
    },
    photosToRemove: [],
    historyImages: {
        historyPhoto1: null,
        historyPhoto2: null,
        historyPhoto3: null,
        historyPhoto4: null,
    },
    banner: null,
};

const paymentInfoContainerClass = 'payment-info-container';
const marker: Icon = new L.Icon({
    iconUrl: mapMarker,
    iconSize: [55, 75],
    iconAnchor: [27, 75],
    popupAnchor: [0, -76],
});

class EditStore extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    componentDidMount(): void {
        this.prepare();
    }

    onStoreThumbnailSelected = (file: File) => {
        this.setState({
            ...this.state,
            showSaveChanges: true,
            store: {
                ...this.state.store,
                thumbnail: file,
            },
        });
    };

    onStoreImageSelected = (file: File, idx: number) => {
        const prevState = this.state;
        const { store } = this.state;
        let { photos } = store;

        if (photos) {
            photos[idx] = file;
        } else {
            photos = [file];
        }

        this.setState({
            ...prevState,
            showSaveChanges: true,
            store: {
                ...store,
                photos,
            },
        });
    };

    onStoreHistoryImageSelected = (file: File, index: number) => {
        const { historyImages } = this.state;

        switch (index) {
            case 1:
                historyImages.historyPhoto1 = file;
                break;
            case 2: historyImages.historyPhoto2 = file;
                break;
            case 3: historyImages.historyPhoto3 = file;
                break;
            case 4: historyImages.historyPhoto4 = file;
                break;
            default: break;
        }

        this.setState({
            showSaveChanges: true,
            historyImages,
        });
    };

    onStoreBannerSelected = (file: File) => {
        this.setState({
            showSaveChanges: true,
            banner: file,
        });
    };

    onAddSchedule = () => {
        const { store } = this.state;
        const { weekSchedule } = store;
        const id = generateId();

        const hasEmptySchedule: boolean = validateHasEmptySchedule(weekSchedule);

        if (!hasEmptySchedule && weekSchedule.length < 14) {
            const newSchedule = {
                id,
                initialWeekDay: '',
                endWeekDay: '',
                openOn: '',
                closeOn: '',
                isInterval: false,
            };

            weekSchedule.push(newSchedule);

            this.setState({
                ...this.state,
                store: {
                    ...this.state.store,
                    weekSchedule: [...weekSchedule],
                },
            });
        }
    };

    onScheduleRemove = (id: string) => {
        const { store } = this.state;
        const { weekSchedule } = store;

        const newSchedule: Array<ScheduleItem> = weekSchedule.filter(item => item.id !== id);

        this.setState({
            ...this.state,
            showSaveChanges: true,
            store: {
                ...this.state.store,
                weekSchedule: [...newSchedule],
            },
        });
    };

    onScheduleChange = (schedule: ScheduleItem) => {
        const { store } = this.state;
        const { weekSchedule } = store;

        const index = weekSchedule.findIndex(s => s.id === schedule.id);

        weekSchedule[index] = {
            ...schedule,
            initialWeekDay: Number(schedule.initialWeekDay),
            endWeekDay: Number(schedule.endWeekDay),
        };

        this.setState({
            ...this.state,
            showSaveChanges: true,
            store: {
                ...this.state.store,
                weekSchedule: [...weekSchedule],
            },
        });
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { name } = e.currentTarget;

        if (name === 'closeToLaunch') {
            this.setState({
                ...this.state,
                store: {
                    ...this.state.store,
                    closeToLaunch: !this.state.store.closeToLaunch,
                },
            });

            return;
        }

        this.setState({
            ...this.state,
            showSaveChanges: true,
            store: {
                ...this.state.store,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onHistoryChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { store } = this.state;
        const { history } = store;

        const { name } = e.currentTarget;

        this.setState({
            showSaveChanges: true,
            store: {
                ...store,
                history: {
                    ...history,
                    [name]: e.currentTarget.value,
                },
            },
        });
    }

    onSaveChanges = () => {
        const { t } = this.props;
        const {
            store, photosToRemove, historyImages, banner,
        } = this.state;

        if (this.validateFields()) {
            if (validateHasEmptySchedule(store.weekSchedule)) {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.emptyScheduleError'));
                return;
            }

            if (validateHasOverlappingSchedules(store.weekSchedule)) {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.scheduleOverlapError'));
                return;
            }

            const formData = new FormData();

            const actualTags: Array<string> = [];
            Object.keys(store.tags).forEach(k => {
                const tag: any = store.tags[Number(k)];

                actualTags.push(tag.label);
            });

            const weekSchedule: Array<any> = [];
            Object.keys(store.weekSchedule).forEach(k => {
                const s = store.weekSchedule[Number(k)];
                const initialWeekDay = String(s.initialWeekDay).trim() === '' ? null : Number(s.initialWeekDay) - 1;

                weekSchedule.push({
                    initialWeekDay,
                    endWeekDay: String(s.endWeekDay).trim() === '' ? initialWeekDay : Number(s.endWeekDay) - 1,
                    closeOn: s.closeOn ? parseTimeToSend(s.closeOn) : null,
                    openOn: s.openOn ? parseTimeToSend(s.openOn) : null,
                });
            });

            const fields = {
                name: String(store.name).trim(),
                description: String(store.description).trim(),
                address: String(store.address).trim(),
                email: String(store.email).trim(),
                contact: String(store.contact).trim(),
                website: String(store.website).trim(),
                postalCode: String(store.postalCode).trim(),
                nif: String(store.nif).trim(),
                iban: String(store.iban).trim(),
                certCode: String(store.certCode).trim(),
                bicSwift: String(store.bicSwift).trim(),
                location: String(store.location).trim(),
                country: String(store.country).trim(),
                latitude: String(store.latitude).trim() === '' ? null : store.latitude,
                longitude: String(store.longitude).trim() === '' ? null : store.longitude,
                homeDelivery: store.homeDelivery,
                closeToLaunch: store.closeToLaunch,
                categoryId: store.categoryId,
                thumbnailHash: store.thumbnailHash,
                contactFeeType: store.contactFeeType,
                closeToLaunchOn: store.closeToLaunch && store.closeToLaunchOn ? parseTimeToSend(store.closeToLaunchOn) : null,
                openFromLaunchOn: store.closeToLaunch && store.openFromLaunchOn ? parseTimeToSend(store.openFromLaunchOn) : null,
                tags: [...actualTags],
                weekSchedule: [...weekSchedule],
                history: {
                    description: store.history ? String(store.history.description).trim() : '',
                    title: store.history ? String(store.history.title).trim() : '',
                },
                photosToRemove,
            };

            if (store.thumbnail) {
                formData.append('thumbnail', store.thumbnail, store.thumbnail.name);
            }

            if (store.photos && store.photos.length > 0) {
                store.photos.forEach((el, idx) => {
                    formData.append(`additionalPhotos[${idx}]`, el, el.name);
                });
            }

            if (historyImages) {
                if (historyImages.historyPhoto1) formData.append('historyPhoto1', historyImages.historyPhoto1, historyImages.historyPhoto1.name);
                if (historyImages.historyPhoto2) formData.append('historyPhoto2', historyImages.historyPhoto2, historyImages.historyPhoto2.name);
                if (historyImages.historyPhoto3) formData.append('historyPhoto3', historyImages.historyPhoto3, historyImages.historyPhoto3.name);
                if (historyImages.historyPhoto4) formData.append('historyPhoto4', historyImages.historyPhoto4, historyImages.historyPhoto4.name);
            }

            if (banner) {
                formData.append('banner', banner, banner.name);
            }

            formData.append('store', new Blob([JSON.stringify(fields)], {
                type: 'application/json',
            }));

            this.editStoreRequest(formData);
        }
    }

    onCloseErrorModal = () => {
        this.setState({
            showErrorEuPagoDownModal: false,
            showSaveChanges: false,
        });
    }

    validateFields = () => {
        let errors: IFormError | null = getFormErrors(this.state.store, VALIDATIONS.STORE_FORM);

        if (!errors) errors = {};
        const { store } = this.state;

        if (store.closeToLaunch) {
            if (!store.closeToLaunchOn || String(store.closeToLaunchOn).trim() === '') {
                errors.closeToLaunchOn = [{ typeOfViolation: 'NotBlank' }];
            }

            if (!store.openFromLaunchOn || String(store.openFromLaunchOn).trim() === '') {
                errors.openFromLaunchOn = [{ typeOfViolation: 'NotBlank' }];
            }
        }

        Object.keys(store.weekSchedule).forEach(key => {
            const item = store.weekSchedule[Number(key)];

            const itemErrors: any = {};

            const errorInitialWeekDay = validateField('initialWeekDay', item.initialWeekDay, VALIDATIONS.STORE_FORM);
            if (errorInitialWeekDay) itemErrors.initialWeekDay = errorInitialWeekDay;

            if (!item.id || !errors) return;

            if (Object.keys(itemErrors).length > 0) errors[item.id] = itemErrors;
            else delete errors[item.id];
        });

        if (!store.paymentInfoValid) {
            if (store.nif) {
                if (!validatePtNif(store.nif)) {
                    errors.nif = [{ typeOfViolation: 'ValidNif' }];
                }
            }
            if (store.iban) {
                if (!validateIBAN(store.iban)) {
                    errors.iban = [{ typeOfViolation: 'ValidIBAN' }];
                }
            }
        }

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    }

    editStoreRequest = async (formData: FormData) => {
        const { t } = this.props;
        const { store } = this.state;

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        this.setState({
            isFetching: true,
            showSaveChanges: false,
        });

        await axios.put(storeURL(store.id), formData, config)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.stores.editStoreSuccess'));

                this.setState({
                    isFetching: false,
                    showSaveChanges: false,
                }, () => this.prepare());
            })
            .catch(error => {
                this.setState({
                    isFetching: false,
                });
                if (error.response) {
                    const { status } = error.response;
                    if (status === 412) {
                        this.setState({
                            showErrorEuPagoDownModal: true,
                        });
                    } else {
                        let responseError = { fields: {} };
                        if (status === 413) {
                            responseError = { fields: { ...responseError.fields, thumbnail: [{ typeOfViolation: 'FileSize' }] } };
                        }
                        if (error.response.data.result && error.response.data.result === 'INVALID POSTAL CODE') responseError = { fields: { ...responseError.fields, postalCode: [{ typeOfViolation: 'Pattern' }] } };
                        displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.editStoreError'));
                        this.handleResponse({ ...responseError, ...error.response.data });
                    }
                } else {
                    displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.stores.editStoreError'));
                    this.setState({ isFetching: false });
                }
            });
    };

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    prepare = () => {
        const { user, t } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        if (user) {
            const { store: userStore } = user;

            if (!userStore) return;

            this.setState({ isFetching: true });

            const urls = [storeURL(userStore.id), categoriesPublicURL()];

            axios.all(urls.map(url => axios.get(url)))
                .then(axios.spread((storeResponse, categoriesResponse) => {
                    const { data: store } = storeResponse;

                    const categoriesOptions: Array<SelectOption> = [];
                    if (categoriesResponse && categoriesResponse.data) {
                        categoriesOptions.push({
                            value: '',
                            label: t('admin.stores.labels.selectCategory'),
                        });

                        Object.keys(categoriesResponse.data).forEach(k => {
                            const c = categoriesResponse.data[Number(k)];
                            categoriesOptions.push({
                                value: c.id,
                                label: c.description,
                            });
                        });
                    }

                    const tagsAsSelectOptions: Array<SelectOption> = [];
                    Object.keys(store.tags).forEach(k => {
                        const tag: any = store.tags[Number(k)];
                        tagsAsSelectOptions.push({
                            value: tag.id,
                            label: tag.description,
                        });
                    });

                    const weekSchedule: Array<ScheduleItem> = [];
                    let scheduleOrdered: Array<ScheduleItem> = [];
                    Object.keys(store.weekSchedule).forEach(k => {
                        const item = store.weekSchedule[Number(k)];

                        const isInterval: boolean = (item.endWeekDay !== null && item.endWeekDay !== undefined && Number(item.endWeekDay) !== Number(item.initialWeekDay));

                        weekSchedule.push({
                            id: item.id,
                            openOn: item.openOn ? moment(item.openOn, 'HH:mm:ss').unix() * 1000 : '',
                            closeOn: item.closeOn ? moment(item.closeOn, 'HH:mm:ss').unix() * 1000 : '',
                            initialWeekDay: Number(item.initialWeekDay) + 1,
                            endWeekDay: Number(item.endWeekDay) + 1,
                            isInterval,
                        });
                    });

                    scheduleOrdered = orderBy(weekSchedule, (o: ScheduleItem) => {
                        return o.initialWeekDay;
                    }, ['asc']);

                    this.setState({
                        isFetching: false,
                        categoriesOptions,
                        store: {
                            ...store,
                            contactFeeType: store.contactFeeType || '',
                            tags: tagsAsSelectOptions,
                            closeToLaunchOn: store.closeToLaunchOn ? moment(store.closeToLaunchOn, 'HH:mm:ss').unix() * 1000 : '',
                            openFromLaunchOn: store.openFromLaunchOn ? moment(store.openFromLaunchOn, 'HH:mm:ss').unix() * 1000 : '',
                            thumbnailUrl: resourcesURL(store.thumbnailHash),
                            categoryId: store.category ? store.category.id : store.categoryId,
                            weekSchedule: [...scheduleOrdered],
                        },
                    });
                }))
                .catch(() => {
                    this.setState({
                        isFetching: false,
                    });
                });
        }
    }

    onRemovePhoto = (idx: number) => {
        const prevState = this.state;
        const { store, photosToRemove } = this.state;
        const { photosHash, photos } = store;

        if (photosHash && photosToRemove) {
            const photoHashSplit = photosHash[idx].split('/');
            const photoHashFileName = photoHashSplit[photoHashSplit.length - 1];

            photosToRemove.push(photoHashFileName);
            photosHash.splice(idx, 1);
        }

        if (photos) {
            photos.splice(idx, 1);
        }

        this.setState({
            ...prevState,
            showSaveChanges: true,
            store: {
                ...store,
                photosHash,
                photos,
            },
            photosToRemove,
        });
    }

    onRemoveBannerPhoto = () => {
        const { photosToRemove, store } = this.state;

        photosToRemove.push(store.bannerHash);

        this.setState({
            photosToRemove,
            store: {
                ...store,
                bannerHash: '',
            },
        });
    }

    onRemoveHistoryImage = (index: number) => {
        const { store, photosToRemove, historyImages } = this.state;

        switch (index) {
            case 1:
                if (store.history.photo1) {
                    photosToRemove.push(store.history.photo1);
                }
                this.setState({
                    store: {
                        ...store,
                        history: {
                            ...store.history,
                            photo1: '',
                        },
                    },
                    historyImages: {
                        ...historyImages,
                        historyPhoto1: null,
                    },
                });
                break;
            case 2:
                if (store.history.photo2) {
                    photosToRemove.push(store.history.photo2);
                }
                this.setState({
                    store: {
                        ...store,
                        history: {
                            ...store.history,
                            photo2: '',
                        },
                    },
                    historyImages: {
                        ...historyImages,
                        historyPhoto2: null,
                    },
                });
                break;
            case 3:
                if (store.history.photo3) {
                    photosToRemove.push(store.history.photo3);
                }
                this.setState({
                    store: {
                        ...store,
                        history: {
                            ...store.history,
                            photo3: '',
                        },
                    },
                    historyImages: {
                        ...historyImages,
                        historyPhoto3: null,
                    },
                });
                break;
            case 4:
                if (store.history.photo4) {
                    photosToRemove.push(store.history.photo4);
                }
                this.setState({
                    store: {
                        ...store,
                        history: {
                            ...store.history,
                            photo4: '',
                        },
                    },
                    historyImages: {
                        ...historyImages,
                        historyPhoto4: null,
                    },
                });
                break;
            default: break;
        }

        this.setState({
            showSaveChanges: true,
            photosToRemove,
        });
    };

    onAddPhoto = () => {
        const prevState = this.state;
        const { store } = this.state;
        const { photosHash } = store;

        photosHash.push('');
        this.setState({
            ...prevState,
            store: {
                ...store,
                photosHash,
            },
        });
    }

    onMapClick = (e: LeafletMouseEvent):void => {
        const { store } = this.state;
        const { lat, lng } = e.latlng;

        const latitude = Number(lat.toFixed(5));
        const longitude = Number(lng.toFixed(5));

        this.setState({
            showSaveChanges: true,
            store: {
                ...store,
                latitude,
                longitude,
            },
        });
    }

    renderMapComponent = ():JSX.Element => {
        const { store } = this.state;

        let position: LatLng = new LatLng(39.7541222, -8.8409781);

        if (store.latitude && String(store.latitude).trim() !== '' && store.longitude && String(store.longitude).trim() !== '') {
            position = new LatLng(store.latitude, store.longitude);
        }

        return (
            <>
                <Map center={position} zoom={13} onclick={this.onMapClick}>
                    <TileLayer url="https://c.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Marker position={position} icon={marker}>
                        <Popup>{store.name}</Popup>
                    </Marker>
                </Map>
            </>
        );
    }

    render() {
        const { t } = this.props;
        const {
            isFetching,
            showSaveChanges,
            formErrors,
            store,
            showErrorEuPagoDownModal,
            categoriesOptions,
        } = this.state;

        const contactFeeTypeLabel = t('admin.stores.labels.contactFeeType');

        return (
            <div className="app-tabs__tab-content store-admin">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="form-centered">
                    <p>{t('storeAdmin.editStore.labels.photos.thumbnail')}</p>
                    <Row>
                        <ImageUpload
                            imageUrl={store.thumbnailUrl}
                            onSelected={this.onStoreThumbnailSelected}
                            buttonText={t(globalButtonLabels.upload)}
                            helpText={t('storeAdmin.editStore.labels.photos.thumbnailHelpText')}
                            errors={get(formErrors, 'fields.thumbnail', null)}
                        />
                    </Row>
                    <p>{t('storeAdmin.editStore.labels.photos.banner')}</p>
                    <Row>
                        <div className="banner-upload">
                            <ImageUpload
                                imageUrl={store.bannerHash ? resourcesURL(store.bannerHash) : ''}
                                onSelected={this.onStoreBannerSelected}
                                buttonText={t(globalButtonLabels.upload)}
                                helpText={t('storeAdmin.editStore.labels.photos.bannerHelpText')}
                                errors={get(formErrors, 'fields.thumbnail', null)}
                            />
                            <Button
                                text={t('global.buttons.remove')}
                                styles="button--light-red button--smaller"
                                disabled={store.bannerHash === undefined || store.bannerHash === ''}
                                callback={() => this.onRemoveBannerPhoto()}
                            />
                        </div>
                    </Row>
                    {enabledStoreAltPhotos && (
                        <>
                            <p>{t('storeAdmin.editStore.labels.photos.alts')}</p>
                            <div className="upload-container">
                                {store.photosHash.map((photo, idx) => {
                                    return (
                                        <div className="upload-container__upload-component">
                                            <ImageUpload
                                                imageUrl={(photo && photo !== '') ? `${photo}` : ''}
                                                onSelected={(file: File) => this.onStoreImageSelected(file, idx)}
                                                buttonText={t(globalButtonLabels.upload)}
                                            />
                                            <Button
                                                text={t('global.buttons.remove')}
                                                styles="button--light-red button--smaller"
                                                callback={() => this.onRemovePhoto(idx)}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            {store.photosHash && store.photosHash.length < 4 && (
                                <Row className="show-grid">
                                    <Col xs={12} md={12} className="schedule-add-btn-wrapper">
                                        <Button
                                            text={t('storeAdmin.products.buttons.addPhoto')}
                                            styles="button--blue button--smaller"
                                            callback={this.onAddPhoto}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </>
                    )}
                    <Row className="show-grid">
                        <Col xs={12} md={12}>
                            <FormTextField
                                label={t('storeAdmin.editStore.labels.name')}
                                placeholder={t('storeAdmin.editStore.labels.name')}
                                name="name"
                                value={store.name}
                                maxLength={60}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.name', null)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <FormTextField
                                label={t('storeAdmin.editStore.labels.address')}
                                placeholder={t('storeAdmin.editStore.labels.address')}
                                name="address"
                                value={store.address}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.address', null)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <FormTextField
                                label={t('storeAdmin.editStore.labels.postalCode')}
                                placeholder={t('storeAdmin.editStore.labels.postalCode')}
                                name="postalCode"
                                value={store.postalCode}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.postalCode', null)}
                            />
                            <FormTextField
                                label={t('storeAdmin.editStore.labels.latitude')}
                                placeholder={t('storeAdmin.editStore.labels.latitude')}
                                name="latitude"
                                value={store.latitude}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.latitude', null)}
                            />
                            <FormTextField
                                label={t('storeAdmin.editStore.labels.longitude')}
                                placeholder={t('storeAdmin.editStore.labels.longitude')}
                                name="longitude"
                                value={store.longitude}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.longitude', null)}
                            />
                            <p className="info-bold">{t('storeAdmin.editStore.mapPinDropInfo')}</p>
                        </Col>
                        <Col xs={12} md={8}>
                            {this.renderMapComponent()}
                        </Col>
                    </Row>
                    <hr />
                    <Row className="show-grid">
                        <Col xs={12} md={6}>
                            <FormTextField
                                label={t('storeAdmin.editStore.labels.contact')}
                                placeholder={t('storeAdmin.editStore.labels.contact')}
                                name="contact"
                                value={store.contact}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.contact', null)}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <FormSelectField
                                name="contactFeeType"
                                label={contactFeeTypeLabel}
                                placeholder={contactFeeTypeLabel}
                                value={store.contactFeeType || ''}
                                options={[
                                    { value: '', label: contactFeeTypeLabel },
                                    { value: ContactFeeType.MOBILE, label: t('contactFeeType.MOBILE') },
                                    { value: ContactFeeType.LANDLINE, label: t('contactFeeType.LANDLINE') },
                                ]}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.contactFeeType', null)}
                            />
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col xs={12} md={6}>
                            <FormTextField
                                label={t('storeAdmin.editStore.labels.email')}
                                placeholder={t('storeAdmin.editStore.labels.email')}
                                name="email"
                                value={store.email}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.email', null)}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <FormTextField
                                label={t('storeAdmin.editStore.labels.website')}
                                placeholder={t('storeAdmin.editStore.labels.website')}
                                name="website"
                                value={store.website}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.website', null)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {store.paymentInfoValid && (
                            <Col xs={12} lg={12}>
                                <p className="info-bold">{t('storeAdmin.editStore.paymentInfoValid')}</p>
                            </Col>
                        )}
                    </Row>
                    <Row className={store.paymentInfoValid ? paymentInfoContainerClass : ''}>
                        <Col xs={12} md={8}>
                            <FormTextField
                                label={t('storeAdmin.editStore.labels.iban')}
                                placeholder={t('storeAdmin.editStore.labels.iban')}
                                name="iban"
                                value={store.iban || null}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.iban', null)}
                                disabled={store.paymentInfoValid}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <FormTextField
                                label={t('storeAdmin.editStore.labels.nif')}
                                placeholder={t('storeAdmin.editStore.labels.nif')}
                                name="nif"
                                value={store.nif || null}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.nif', null)}
                                disabled={store.paymentInfoValid}
                            />
                        </Col>
                    </Row>
                    <Row className={store.paymentInfoValid ? paymentInfoContainerClass : ''}>
                        <Col xs={12} md={12}>
                            <FormTextField
                                label={t('storeAdmin.editStore.labels.certCode')}
                                placeholder={t('storeAdmin.editStore.labels.certCode')}
                                name="certCode"
                                value={store.certCode || null}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.certCode', null)}
                                disabled={store.paymentInfoValid}
                            />
                        </Col>
                    </Row>
                    <Row className={store.paymentInfoValid ? paymentInfoContainerClass : ''}>
                        <Col xs={12} md={4}>
                            <FormTextField
                                label={t('admin.stores.labels.bicSwift')}
                                placeholder={t('admin.stores.labels.bicSwift')}
                                name="bicSwift"
                                value={store.bicSwift || null}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.bicSwift', null)}
                                disabled={store.paymentInfoValid}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <FormTextField
                                label={t('admin.stores.labels.location')}
                                placeholder={t('admin.stores.labels.location')}
                                name="location"
                                value={store.location || null}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.location', null)}
                                disabled={store.paymentInfoValid}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <FormTextField
                                label={t('admin.stores.labels.country')}
                                placeholder={t('admin.stores.labels.country')}
                                name="country"
                                value={store.country || null}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.country', null)}
                                disabled={store.paymentInfoValid}
                            />
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col xs={12} md={6}>
                            <FormSelectField
                                name="categoryId"
                                label={t('storeAdmin.editStore.labels.selectCategory')}
                                placeholder={t('storeAdmin.editStore.labels.selectCategory')}
                                value={String(store.categoryId)}
                                options={categoriesOptions}
                                onChange={this.onInputChange}
                                errors={get(formErrors, 'fields.categoryId', null)}
                            />
                        </Col>
                    </Row>
                    <div className="history-form">
                        <h5>{t('storeAdmin.editStore.labels.history')}</h5>
                        <div className="history-form__content">
                            <Col xs={12} md={12}>
                                <FormTextAreaField
                                    label={`Secção "Sobre nós" (max. ${store.courier ? charityStoreTextFieldCharLimit : 2000} caracteres)`}
                                    name="description"
                                    rows={5}
                                    value={store.description ? store.description : ''}
                                    onChange={this.onInputChange}
                                    maxLength={store.courier ? charityStoreTextFieldCharLimit : 2000}
                                />
                                <FormTextField
                                    label="Título Galeria"
                                    name="title"
                                    value={store.history && store.history.title ? store.history.title : ''}
                                    onChange={this.onHistoryChange}
                                />
                                <FormTextAreaField
                                    fieldStyles="fixed-area"
                                    label={`Legenda Galeria (max. ${store.courier ? charityStoreTextFieldCharLimit : 1000} caracteres)"`}
                                    name="description"
                                    rows={5}
                                    value={store.history && store.history.description ? store.history.description : ''}
                                    onChange={this.onHistoryChange}
                                    maxLength={store.courier ? charityStoreTextFieldCharLimit : 1000}
                                />
                            </Col>
                            <Col className="upload-container-grid">
                                <div className="upload-container-grid__upload-component">
                                    <ImageUpload
                                        imageUrl={(store.history && store.history.photo1) ? resourcesURL(store.history.photo1) : ''}
                                        onSelected={(file: File) => this.onStoreHistoryImageSelected(file, 1)}
                                        buttonText={t(globalButtonLabels.upload)}
                                    />
                                    <Button
                                        text="X"
                                        styles="button--light-red"
                                        disabled={store.history === undefined || store.history.photo1 === undefined || store.history.photo1 === ''}
                                        callback={() => this.onRemoveHistoryImage(1)}
                                    />
                                </div>
                                <div className="upload-container-grid__upload-component">
                                    <ImageUpload
                                        imageUrl={(store.history && store.history.photo2) ? resourcesURL(store.history.photo2) : ''}
                                        onSelected={(file: File) => this.onStoreHistoryImageSelected(file, 2)}
                                        buttonText={t(globalButtonLabels.upload)}
                                    />
                                    <Button
                                        text="X"
                                        styles="button--light-red"
                                        disabled={store.history === undefined || store.history.photo2 === undefined || store.history.photo2 === ''}
                                        callback={() => this.onRemoveHistoryImage(2)}
                                    />
                                </div>
                                <div className="upload-container-grid__upload-component">
                                    <ImageUpload
                                        imageUrl={(store.history && store.history.photo3) ? resourcesURL(store.history.photo3) : ''}
                                        onSelected={(file: File) => this.onStoreHistoryImageSelected(file, 3)}
                                        buttonText={t(globalButtonLabels.upload)}
                                    />
                                    <Button
                                        text="X"
                                        styles="button--light-red"
                                        disabled={store.history === undefined || store.history.photo3 === undefined || store.history.photo3 === ''}
                                        callback={() => this.onRemoveHistoryImage(3)}
                                    />
                                </div>
                                <div className="upload-container-grid__upload-component">
                                    <ImageUpload
                                        imageUrl={(store.history && store.history.photo4) ? resourcesURL(store.history.photo4) : ''}
                                        onSelected={(file: File) => this.onStoreHistoryImageSelected(file, 4)}
                                        buttonText={t(globalButtonLabels.upload)}
                                    />
                                    <Button
                                        text="X"
                                        styles="button--light-red"
                                        disabled={store.history === undefined || store.history.photo4 === undefined || store.history.photo4 === ''}
                                        callback={() => this.onRemoveHistoryImage(4)}
                                    />
                                </div>
                            </Col>
                        </div>
                    </div>
                    <Row>
                        <Col sm={12} className="mt-3 mb-1">
                            <h5>{t('storeAdmin.editStore.labels.schedule')}</h5>
                        </Col>
                    </Row>
                    {Object.keys(store.weekSchedule).map(key => {
                        const s = store.weekSchedule[Number(key)];

                        return (
                            <StoreSchedule
                                key={s.id}
                                schedule={s}
                                onScheduleChange={this.onScheduleChange}
                                errors={get(formErrors, `fields.${s.id}`, null)}
                                onRemove={this.onScheduleRemove}
                            />
                        );
                    })}
                    <Row className="show-grid">
                        {store.weekSchedule.length < 14 && (
                            <Col xs={12} md={12} className="schedule-add-btn-wrapper">
                                <Button
                                    text={t('storeAdmin.editStore.labels.addSchedule')}
                                    styles="button--blue button--smaller"
                                    callback={this.onAddSchedule}
                                />
                            </Col>
                        )}
                    </Row>
                    <hr className="divider" />
                    <div className="row justify-content-end buttons-container">
                        <div className="button-wrapper">
                            <Button
                                text={t('storeAdmin.editStore.saveChanges')}
                                callback={this.onSaveChanges}
                                styles="button--dark-blue"
                                disabled={!showSaveChanges}
                            />
                        </div>
                    </div>
                </div>

                <Modal className="modal-custom" show={showErrorEuPagoDownModal} onHide={this.onCloseErrorModal} centered>
                    <ModalHeader className="success-modal-header" closeButton>
                        <ModalTitle>
                            <div className="success-modal">
                                <h2 className="my-2 red-text">{t('home.contact.error')}</h2>
                                <h5>{t('admin.stores.errorEuPagoDown')}</h5>
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                </Modal>
            </div>
        );
    }
}

export default withAuthenticationContext(withTranslationContext(EditStore));

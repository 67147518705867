/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import {
    GeneralAction,
} from './general_types';

export const setLastSeenProducts = (lastSeenProducts: Array<number>) => {
    return {
        type: GeneralAction.SET_LAST_SEEN_PRODUCTS,
        payload: lastSeenProducts,
    };
};

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import { get, throttle } from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import Loader from '../../elements/Loader';
import Button from '../../elements/Button';
import { Store, StoreManager } from '../../../constants/types';
import {
    storeManagerUserURL, userDeleteURL, usersURL,
} from '../../../services/users';
import { storesURL, storeXLSURL } from '../../../services/stores';
import { globalButtonLabels, SelectOption } from '../../../constants/misc';
import FormTextField from '../../elements/FormTextField';
import { getFormErrors, IFormError, VALIDATIONS } from '../../../utils/validation';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import FormSelectField from '../../elements/FormSelectField';
import { ICON, SvgIcon } from '../../elements/SvgIcon';
import displayConfirm from '../../elements/displayConfirm';
import { downloadXLS } from '../../../utils/misc';
import iconInfo from '../../../assets/images/ic-info.svg';
import { UserRoles } from '../../../constants/authorization';
import withPaging, { WithPagingProps } from '../../hocs/withPaging';
import { AuthenticationContext, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import TablePaging from '../../elements/TablePaging';
import SortArrow from '../../elements/SortArrow';

interface OwnProps extends TranslationContext, AuthenticationContext, WithPagingProps {}

interface OwnState {
    isFetching: boolean;
    showUserModal: boolean;
    isEditingUser: boolean;
    users: Array<StoreManager>;
    storesOptions: Array<SelectOption>;
    user: StoreManager;
    formErrors: any;
    totalResults: number;
    searchString: string;
    sortOrder: string;
    sortField: string;
    _limit: number;
}

const initialState: OwnState = {
    isFetching: false,
    showUserModal: false,
    isEditingUser: false,
    users: [],
    storesOptions: [],
    formErrors: null,
    user: {
        id: -1,
        name: '',
        email: '',
        storeId: null,
    },
    totalResults: 0,
    searchString: '',
    sortField: 'NAME',
    sortOrder: 'ASC',
    _limit: 25,
};

const errorLabels = {
    createUserError: 'admin.users.createUserError',
};

class StoreManagers extends Component<OwnProps> {
    state = { ...initialState };

    private readonly throttledGetManagers = () => { };

    constructor(props: OwnProps) {
        super(props);
        this.throttledGetManagers = throttle(this.getStoreManagers, 900);
    }

    componentDidMount(): void {
        this.prepare();
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const { currentPage } = this.props;
        const { currentPage: oldCurrentPage } = prevProps;

        const hasPagingChanged: boolean = currentPage !== oldCurrentPage;

        if (hasPagingChanged) {
            this.getStoreManagers();
        }
    }

    onAddUser = () => {
        this.setState({
            showUserModal: true,
        });
    };

    onCloseUserModal = () => {
        this.setState({
            showUserModal: false,
            isEditingUser: false,
            formErrors: null,
            user: {
                ...initialState.user,
            },
        });
    };

    onDeleteUser = (e: React.MouseEvent, user: StoreManager) => {
        e.stopPropagation();

        const { t } = this.props;

        displayConfirm({
            acceptButtonText: t(globalButtonLabels.ok),
            onAccept: () => this.deleteUser(user.id),
            onReject: () => {},
            rejectButtonText: t(globalButtonLabels.cancel),
            message: t('admin.users.removeUserConfirmation', { name: user.name }),
        });
    };

    onUserClick = (user: StoreManager) => {
        const { myStores } = user;
        let storeId: number | null = null;
        if (myStores && myStores.length > 0) {
            const store: Store = myStores[0];
            storeId = store.id;
        }

        this.setState({
            isEditingUser: true,
            showUserModal: true,
            user: {
                id: user.id,
                name: user.name,
                email: user.email,
                storeId,
            },
        });
    };

    onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    };

    onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { onPagingChange } = this.props;
        const { value } = e.currentTarget;

        onPagingChange(1);
        this.setState({
            searchString: value,
        }, () => {
            this.throttledGetManagers();
        });
    }

    deleteUser = (id: number) => {
        const { t } = this.props;
        this.setState({ isFetching: true });

        axios.delete(userDeleteURL(id))
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.users.removeUserSuccess'));

                this.setState({ isFetching: false }, () => this.prepare());
            })
            .catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.users.removeUserError'));

                this.setState({ isFetching: false });
            });
    };

    getStoreManagers = async () => {
        const { currentPage, onPagingChange, user } = this.props;
        const {
            isFetching, _limit, searchString, sortOrder, sortField,
        } = this.state;

        if (isFetching && !user) return;

        this.setState({ isFetching: true });
        const url = usersURL({
            pageSize: _limit,
            page: currentPage - 1,
            q: searchString === '' ? null : searchString,
            sort: sortField,
            order: sortOrder,
            role: UserRoles.STORE_MANAGER,
        });

        try {
            const { data, headers } = await axios.get(url);

            const newTotalResults: number = parseInt(headers['x-total-count']);

            if (data && data.length === 0 && currentPage !== 1) {
                this.setState({ isFetching: false }, () => {
                    onPagingChange(currentPage - 1);
                    this.getStoreManagers();
                });
            } else {
                this.setState({
                    isFetching: false,
                    users: data,
                    totalResults: newTotalResults,
                });
            }
        } catch (e) {
            this.setState({ isFetching: false });
        }
    }

    onSortChange = (sortField: string, sortOrder : string) => {
        this.setState({
            sortField,
            sortOrder,
        }, this.prepare);
    }

    validateFields = () => {
        const { isEditingUser } = this.state;

        let errors: IFormError | null;
        if (isEditingUser) errors = getFormErrors(this.state.user, VALIDATIONS.STORE_MANAGER_EDIT_FORM);
        else errors = getFormErrors(this.state.user, VALIDATIONS.STORE_MANAGER_CREATE_FORM);

        if (!errors) errors = {};
        const { password, passwordConfirmation } = this.state.user;
        if (password && passwordConfirmation && password !== passwordConfirmation) {
            errors.passwordConfirmation = [{ typeOfViolation: 'passwordsDontMatch' }];
        }

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    };

    saveUser = () => {
        const { user, isEditingUser, storesOptions } = this.state;
        const { t } = this.props;

        if (this.validateFields()) {
            const fields = {
                name: String(user.name).trim(),
                email: String(user.email).trim(),
                password: isEditingUser ? undefined : String(user.password).trim(),
                storeId: user.storeId,
            };

            const store = storesOptions.find(el => {
                return Number(el.value) === Number(user.storeId);
            });

            if (isEditingUser) {
                displayConfirm({
                    acceptButtonText: t(globalButtonLabels.ok),
                    onAccept: () => this.editUserRequest(fields),
                    onReject: () => {},
                    rejectButtonText: t(globalButtonLabels.cancel),
                    message: t('admin.users.editUserConfirmation', { name: user.name }),
                });
            } else {
                displayConfirm({
                    acceptButtonText: t(globalButtonLabels.ok),
                    onAccept: () => this.createUserRequest(fields),
                    onReject: () => {
                    },
                    rejectButtonText: t(globalButtonLabels.cancel),
                    message: t('admin.users.addStoreManagerConfirmation', { store: store?.label || '', email: user.email }),
                });
            }
        }
    };

    editUserRequest = (fields: object) => {
        const { t } = this.props;
        const { user } = this.state;

        this.setState({ isFetching: true });

        axios.put(storeManagerUserURL(user.id), fields)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.users.editUserSuccess'));

                this.setState({
                    isFetching: false,
                    showUserModal: false,
                    isEditingUser: false,
                    formErrors: null,
                    user: {
                        ...initialState.user,
                    },
                }, () => this.prepare());
            })
            .catch(error => {
                if (error.response) {
                    const { data } = error.response;

                    if (data && data.result) {
                        if (data.result === 'PLEASE COMPLETE THE MISSING INFO ON STORE NIF, IBAN AND CERT CODE') {
                            displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.users.completeStoreMissingInfo'));
                            this.setState({
                                isFetching: false,
                            });
                        } else if (data.result === 'USER ALREADY EXISTS') {
                            displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.users.managerAlreadyExistsError'));
                            this.setState({
                                isFetching: false,
                            });
                        }
                    } else {
                        displayNotification(NOTIFICATION_TYPE.ERROR, t(errorLabels.createUserError));
                        this.handleResponse(error.response.data);
                    }
                } else {
                    displayNotification(NOTIFICATION_TYPE.ERROR, t(errorLabels.createUserError));
                    this.setState({
                        isFetching: false,
                    });
                }
            });
    };

    createUserRequest = (fields: object) => {
        const { t } = this.props;

        this.setState({ isFetching: true });

        axios.post(storeManagerUserURL(), fields)
            .then(() => {
                displayNotification(NOTIFICATION_TYPE.SUCCESS, t('admin.users.createUserSuccess'));

                this.setState({
                    isFetching: false,
                    showUserModal: false,
                    formErrors: null,
                    user: {
                        ...initialState.user,
                    },
                }, () => this.prepare());
            })
            .catch(error => {
                if (error.response) {
                    const { data } = error.response;

                    if (data && data.result) {
                        if (data.result === 'PLEASE COMPLETE THE MISSING INFO ON STORE NIF, IBAN AND CERT CODE') {
                            displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.users.managerStoreError'));
                            this.setState({
                                isFetching: false,
                            });
                        } else if (data.result === 'USER ALREADY EXISTS') {
                            displayNotification(NOTIFICATION_TYPE.ERROR, t('admin.users.managerAlreadyExistsError'));
                            this.setState({
                                isFetching: false,
                            });
                        }
                    } else {
                        displayNotification(NOTIFICATION_TYPE.ERROR, t(errorLabels.createUserError));
                        this.handleResponse(error.response.data);
                    }
                } else {
                    displayNotification(NOTIFICATION_TYPE.ERROR, t(errorLabels.createUserError));
                    this.setState({
                        isFetching: false,
                    });
                }
            });
    };

    handleResponse = (formErrors: any = null) => {
        if (formErrors && Object.keys(formErrors).length > 0) {
            this.setState({
                formErrors,
                isFetching: false,
            });
        } else {
            this.setState({ isFetching: false });
        }
    };

    prepare = () => {
        const { t } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        this.getStoreManagers();

        axios.get(storesURL({
            pageSize: 999,
        }))
            .then(storesResponse => {
                const storesOptions: Array<SelectOption> = [];
                if (storesResponse && storesResponse.data) {
                    storesOptions.push({
                        value: '',
                        label: t('global.select'),
                    });

                    Object.keys(storesResponse.data).forEach(k => {
                        const store = storesResponse.data[Number(k)];
                        storesOptions.push({
                            value: store.id,
                            label: store.name,
                        });
                    });
                }

                this.setState({
                    isFetching: false,
                    storesOptions,
                });
            })
            .catch(() => {
                this.setState({ isFetching: false });
            });
    };

    renderTooltip = (text: string) => {
        return (
            <OverlayTrigger
                trigger="hover"
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={props => {
                    return (
                        <Tooltip
                            className="highlight-tooltip"
                            id="highlight-tooltip"
                            {...props}
                        >
                            {text}
                        </Tooltip>
                    );
                }}
            >
                <img className="mb-1" src={iconInfo} alt="highlight info" />
            </OverlayTrigger>
        );
    }

    render() {
        const { t, currentPage, onPagingChange } = this.props;
        const {
            isFetching,
            showUserModal,
            isEditingUser,
            storesOptions,
            searchString,
            formErrors,
            users,
            user,
            _limit,
            totalResults,
            sortField,
            sortOrder,
        } = this.state;

        const hasData = users && users.length > 0;

        return (
            <div className="app-tabs__tab-content">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                
                <div className="d-flex flex-row justify-content-between">
                    <FormTextField
                        placeholder="Pesquisar Gerentes"
                        name="query"
                        value={searchString}
                        label="Pesquisar"
                        onChange={this.onFilterChange}
                    />
                    <div className="buttons-container__buttons">
                        <div className="button-wrapper">
                            <Button
                                styles="button--dark-blue button--small"
                                text={t('admin.users.addUser')}
                                callback={this.onAddUser}
                            />
                        </div>
                        <div className="button-wrapper">
                            <Button
                                styles="button--dark-blue button--small"
                                text={t('admin.users.export')}
                                callback={() => downloadXLS(storeXLSURL())}
                            />
                        </div>
                    </div>
                </div>
                <Table responsive="sm">
                    <thead>
                        <tr>
                            <th>
                                {t('admin.users.headers.name')}
                                <SortArrow
                                    active={sortField === 'NAME'}
                                    ascending={sortOrder === 'ASC'}
                                    callback={() => { this.onSortChange('NAME', sortField === 'NAME' && sortOrder === 'ASC' ? 'DESC' : 'ASC'); }}
                                />
                            </th>

                            <th>{t('admin.users.headers.email')}</th>
                            <th>
                                {t('admin.users.headers.store')}
                                <SortArrow
                                    active={sortField === 'STORE'}
                                    ascending={sortOrder === 'ASC'}
                                    callback={() => { this.onSortChange('STORE', sortField === 'STORE' && sortOrder === 'ASC' ? 'DESC' : 'ASC'); }}
                                />
                            </th>

                            <th />
                        </tr>
                    </thead>
                    {hasData ? (
                        <tbody>
                            {Object.keys(users).map(k => {
                                const userAux = users[Number(k)];
                                const { myStores } = userAux;

                                let storeName: string | null = null;
                                if (myStores && myStores.length > 0) {
                                    const store: Store = myStores[0];
                                    storeName = store.name;
                                }

                                return (
                                    <tr key={userAux.id} onClick={() => this.onUserClick(userAux)} className="clickable">
                                        <td>{userAux.name}</td>
                                        <td>{userAux.email}</td>
                                        <td>{storeName || '-'}</td>
                                        <td className="d-flex w-100 justify-content-end trash-icon-cell">
                                            <SvgIcon icon={ICON.TRASH} callback={(e: React.MouseEvent) => this.onDeleteUser(e, userAux)} />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan={4}>
                                    {t('global.noData')}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
                {hasData && (
                    <TablePaging
                        currentPage={currentPage}
                        limit={_limit}
                        totalResults={totalResults}
                        onStartChange={onPagingChange}
                    />
                )}
                <Modal show={showUserModal} onHide={this.onCloseUserModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{isEditingUser ? t('admin.users.editUser') : t('admin.users.addUser')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-body modal-custom">
                            <Row className="show-grid">
                                <Col xs={12} md={12}>
                                    <FormTextField
                                        label={t('admin.users.labels.name')}
                                        placeholder={t('admin.users.labels.name')}
                                        name="name"
                                        value={user.name}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.name', null)}
                                    />
                                </Col>
                            </Row>
                            <Row className="show-grid">
                                <Col xs={12} md={12}>
                                    <div className="d-flex align-content-end">
                                        <p className="mb-2 mr-2">{t('admin.users.labels.email')}</p>
                                        {this.renderTooltip(t('admin.users.emailTooltip'))}
                                    </div>
                                    <FormTextField
                                        placeholder={t('admin.users.labels.email')}
                                        name="email"
                                        value={user.email}
                                        disabled={isEditingUser}
                                        maxLength={55}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.email', null)}
                                    />
                                </Col>
                            </Row>
                            <Row className="show-grid">
                                <Col xs={12} md={12}>
                                    <FormSelectField
                                        name="storeId"
                                        label={t('admin.users.labels.selectStore')}
                                        placeholder={t('admin.users.labels.selectStore')}
                                        value={String(user.storeId)}
                                        options={storesOptions}
                                        onChange={this.onInputChange}
                                        errors={get(formErrors, 'fields.storeId', null)}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            styles="button--blue button--small"
                            text={t(globalButtonLabels.cancel)}
                            callback={this.onCloseUserModal}
                        />
                        <Button
                            styles="button--dark-blue button--small"
                            text={isEditingUser ? t('admin.users.save') : t('admin.users.sendEmail')}
                            callback={this.saveUser}
                        />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default withPaging(withAuthenticationContext(withTranslationContext(StoreManagers)));

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component, RefObject } from 'react';
import axios from 'axios';
import {
    clamp,
    forEach,
    get,
    last,
    throttle,
} from 'lodash';
import L, {
    Icon,
    LatLng,
    LeafletMouseEvent,
} from 'leaflet';
import {
    Map,
    Marker,
    TileLayer,
    Tooltip,
} from 'react-leaflet';
import Col from 'react-bootstrap/Col';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import { ReactSVG } from 'react-svg';
import { ReactCookieProps, withCookies } from 'react-cookie';
import TagManager, { DataLayerArgs } from 'react-gtm-module';
import MediaQuery from 'react-responsive';
import { ModalBody, ModalFooter } from 'react-bootstrap';

import { CartContext, withCartContext } from '../../controllers/cart/CartContext';
import {
    AuthenticationContext,
    withAuthenticationContext,
} from '../../controllers/authentication/AuthenticationContext';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import Loader from '../../elements/Loader';
import {
    MatchParams,
    PaymentMethods,
    SelectOption,
    VAT,
} from '../../../constants/misc';
import {
    CART_ROUTE,
    CHECKOUT_SUCCESS_ROUTE,
    INDEX_ROUTE,
    PRIVACY_POLICY_ROUTE,
    PRODUCT_VIEW_ROUTE,
    STORE_VIEW_ROUTE,
    TERMS_AND_CONDITIONS_OF_USE_ROUTE,
} from '../../../constants/routes';
import {
    asyncForEach,
    checkProductWithinPromotion,
    constructVoucherBonusString,
    countVouchers,
    decimalAdjustRound,
    numberToCurrency,
    phoneNumberWithMask,
    scrollToTop,
    validatePtNif,
} from '../../../utils/misc';
import Button from '../../elements/Button';
import { IFormError, validateField, VALIDATIONS } from '../../../utils/validation';
import { paymentFees, shoppingURL } from '../../../services/shopping';
import {
    DeliveryMethods,
    PaymentFee,
    ShoppingCart,
    ShoppingLine,
    Store,
    StoreCart,
    UserAddress,
} from '../../../constants/types';
import { productURL } from '../../../services/products';
import FormTextField from '../../elements/FormTextField';

import mbLogo from '../../../assets/images/multibanco_logo.svg';
import mbWayLogo from '../../../assets/images/mbway_logo.png';
import delivery from '../../../assets/images/checkout-delivery.svg';
import payment from '../../../assets/images/checkout-payment.svg';
import check from '../../../assets/images/green_tick.svg';
import courierCosts from '../../../assets/images/taxas_envio.jpg';
import { shippingPriceCalcultateURL } from '../../../services/shippingPrices';
import FormRadioButtons from '../../elements/FormRadioButtons';
import FormCheckbox from '../../elements/FormCheckbox';
import { userAddressListURL } from '../../../services/users';
import { UserRoles } from '../../../constants/authorization';
import FormSelectField from '../../elements/FormSelectField';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { homeShippingValidateURL, storePublicURL, storesCourierURL } from '../../../services/stores';
import { hasVoucherURL } from '../../../services/vouchers';
import mapMarker from '../../../assets/images/map_marker.svg';
import storeMarker from '../../../assets/images/store_marker.svg';

interface OwnProps extends RouteComponentProps<MatchParams>, CartContext, AuthenticationContext, TranslationContext, ReactCookieProps { }

interface OwnState {
    currentStep: number,
    isFetching: boolean;
    formErrors: any;
    showErrorModal: boolean;
    showStockErrorModal: boolean;
    address: string;
    contact: string;
    postalCode: string;
    hasDeliveryHome: boolean;
    hasStoreOwnedDelivery: boolean;
    showRevisionModal: boolean;
    paymentMethod: PaymentMethods | null;
    mbWayContact: string;
    cartTotal: number;
    taxValue: number | null;
    nif: string;
    name: string;
    surname: string;
    hasDeliveryTaxes: boolean;
    city: string;
    fees: Array<PaymentFee>;
    agreedToTerms: boolean;
    billingAddress: UserAddress;
    selectedShippingAddress: SelectOption;
    selectedBillingAddress: SelectOption;
    saveBillingAddress: boolean;
    showSaveBillingAddressCheckbox: boolean;
    saveShippingAddress: boolean;
    showSaveShippingAddressCheckbox: boolean;
    showCourierInfoModal: boolean;
    userAddresses: Array<UserAddress>;
    canHaveBonus: boolean;
    storesInCart: Array<Store>;
    deliveryCoordinates: {
        latitude: string | null;
        longitude: string | null;
    };
    hasValidCoordinates: boolean;
}

const initialState: OwnState = {
    currentStep: 0,
    isFetching: false,
    formErrors: null,
    showErrorModal: false,
    showStockErrorModal: false,
    hasDeliveryHome: false,
    hasStoreOwnedDelivery: false,
    showRevisionModal: false,
    paymentMethod: PaymentMethods.DEBIT_CARD,
    nif: '',
    name: '',
    surname: '',
    address: '',
    contact: '',
    city: '',
    postalCode: '',
    mbWayContact: '',
    taxValue: null,
    cartTotal: 0,
    hasDeliveryTaxes: false,
    fees: [],
    agreedToTerms: false,
    billingAddress: {
        id: -1,
        postalCode: '',
        phone: '',
        name: '',
        city: '',
        address: '',
    },
    selectedShippingAddress: {
        value: -1,
        label: 'Endereço do perfil',
    },
    selectedBillingAddress: {
        value: -1,
        label: 'Endereço do perfil',
    },
    saveBillingAddress: false,
    showCourierInfoModal: false,
    showSaveBillingAddressCheckbox: false,
    saveShippingAddress: false,
    showSaveShippingAddressCheckbox: false,
    userAddresses: [],
    canHaveBonus: false,
    storesInCart: [],
    deliveryCoordinates: {
        latitude: null,
        longitude: null,
    },
    hasValidCoordinates: false,
};

const checkoutLabels = {
    contact: 'checkout.contact',
};

const marker: Icon = new L.Icon({
    iconUrl: mapMarker,
    iconSize: [55, 75],
    iconAnchor: [27, 75],
    popupAnchor: [0, -76],
});

class CheckoutScreen extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    private readonly defaultSelectedShippingAddress = {
        value: -1,
        label: 'Endereço do perfil',
    }

    private radioRefs: { [key: string]: RefObject<FormRadioButtons> } = {}

    componentDidMount(): void {
        const {
            shoppingCart, history, user, isAuthenticated,
        } = this.props;

        setTimeout(() => {
            scrollToTop();
        },
        400);

        if (!shoppingCart || shoppingCart.productsCounter === 0) {
            history.push(INDEX_ROUTE);
        } else {
            let nameAux = '';
            let surnameAux = '';

            if (isAuthenticated && user && user.name && user.role !== UserRoles.GUEST) {
                const nameSubStr = user.name.split(' ');
                [nameAux] = nameSubStr;
                surnameAux = nameSubStr[nameSubStr.length - 1];

                axios.get(userAddressListURL()).then(res => {
                    let lastAddress = {
                        postalCode: user.postalCode || '',
                        phone: user.contact || '',
                        name: user.name || '',
                        city: user.city || '',
                        address: user.address || '',
                    };

                    if (res.data && res.data.length > 0) {
                        lastAddress = res.data[res.data.length - 1].address;
                    }

                    const userAddresses = res.data.map((el: { id: number, address: UserAddress }) => {
                        return {
                            ...el.address,
                            id: el.id,
                        };
                    });

                    this.setState({
                        billingAddress: {
                            id: -1,
                            ...lastAddress,
                        },
                        userAddresses,
                    });
                }).catch(() => {
                    this.setState({
                        billingAddress: {
                            id: -1,
                            postalCode: user.postalCode || '',
                            phone: user.contact || '',
                            name: user.name || '',
                            city: user.city || '',
                            address: user.address || '',
                        },
                        userAddresses: [{
                            id: -1,
                            postalCode: user.postalCode || '',
                            phone: user.contact || '',
                            name: user.name || '',
                            city: user.city || '',
                            address: user.address || '',
                        }],
                    });
                });
            }

            this.setState({
                name: nameAux,
                surname: surnameAux,
                address: user && user.address ? user.address : '',
                postalCode: user && user.postalCode ? user.postalCode : '',
                contact: user && user.contact ? user.contact : '',
                city: user && user.city ? user.city : '',
                cartTotal: shoppingCart.total,
            }, async () => {
                await this.getStoreHomeShippingInfo();
                await this.prepare();
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<{}>, snapshot?: any) {
        const { shoppingCart: oldCart } = prevProps;
        const { shoppingCart } = this.props;
        const { paymentMethod } = this.state;

        if (oldCart && shoppingCart && oldCart.total !== shoppingCart.total) {
            if (!paymentMethod) {
                this.setState({
                    cartTotal: shoppingCart.total,
                });
            } else {
                if (paymentMethod === PaymentMethods.DEBIT_CARD) {
                    this.setState({
                        cartTotal: shoppingCart.total + 0.65,
                    });
                }
            }
        }
    }

    onStoreClick = (storeSlug: string) => {
        const { history } = this.props;

        history.push(`${STORE_VIEW_ROUTE}/${storeSlug}`);
    }

    onProductClick = (productId: number) => {
        const { history } = this.props;

        history.push(`${PRODUCT_VIEW_ROUTE}/${productId}`);
    }

    onInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
        if (e.currentTarget.name === 'latitude' || e.currentTarget.name === 'longitude') {
            const { deliveryCoordinates } = this.state;
            this.setState({
                deliveryCoordinates: {
                    ...deliveryCoordinates,
                    [e.currentTarget.name]: e.currentTarget.value,
                },
            }, () => {
                const { deliveryCoordinates: { latitude, longitude } } = this.state;
                if (latitude && longitude && this.validateFields()) {
                    this.validateCoordinates();
                }
            });
        } else {
            this.setState({
                ...this.state,
                [e.currentTarget.name]: e.currentTarget.value,
            });
        }
    };

    setDeliveryMethodOnVouchers = () => {
        const { shoppingCart, updateCart } = this.props;

        if (shoppingCart) {
            const shoppingCartAux: ShoppingCart = shoppingCart;

            shoppingCartAux.storeCarts.forEach((storeCart, cartIdx) => {
                storeCart.shoppingLines.forEach((shoppingLine, lineIdx) => {
                    if (shoppingLine.isVoucher) {
                        shoppingCartAux.storeCarts[cartIdx].shoppingLines[lineIdx].deliveryMethod = DeliveryMethods.EMAIL;
                    }
                });
            });
            updateCart(shoppingCartAux);
        }
    }

    onNifChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            nif: e.currentTarget.value,
        }, throttle(async () => {
            const { shoppingCart } = this.props;
            const { nif } = this.state;
            const voucherQuantity = countVouchers(shoppingCart);

            if (nif.length === 9 && voucherQuantity > 0) {
                const hasBoughtVoucher = await this.checkNIFVoucherBonus(nif);
                this.setState({
                    canHaveBonus: !hasBoughtVoucher,
                });
            }
        }, 500));
    };

    checkNIFVoucherBonus = async (nif: string) => {
        const { user } = this.props;
        const email = user?.email || '';

        this.setState({ isFetching: true });
        const { status, data } = await axios.get(hasVoucherURL(email, nif));
        this.setState({ isFetching: false });

        if (status === 200) {
            if (!data) {
                this.setDeliveryMethodOnVouchers();
            }
            return data as boolean;
        }

        return false;
    }

    onShippingAddressInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value,
        }, () => {
            this.onSetSaveShippingAddress(true);
        });
    }

    onBillingAddressInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
        this.setState({
            ...this.state,
            billingAddress: {
                ...this.state.billingAddress,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        }, () => {
            this.onSetSaveBillingAddress(true);
        });
    }

    onPaymentMethodChange = (e: React.FormEvent<HTMLInputElement>): void => {
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value,
        }, () => this.updateTaxValue());
    };

    onPaymentMethodClick = (paymentMethod: PaymentMethods): void => {
        this.setState({
            ...this.state,
            paymentMethod,
        }, () => this.updateTaxValue());
    };

    onDeliveryMethodChange = (value: string, cartId: string, lineId: string): void => {
        const { shoppingCart, updateCart } = this.props;
        if (shoppingCart) {
            const cartIndex = shoppingCart.storeCarts.findIndex(c => c.id === cartId);
            const lineIndex = shoppingCart.storeCarts[cartIndex].shoppingLines.findIndex(l => l.id === lineId);
            shoppingCart.storeCarts[cartIndex].shoppingLines[lineIndex].deliveryMethod = value;
            this.updateDeliveryTaxes(cartIndex, shoppingCart);
            updateCart(shoppingCart);
            this.validateHasDeliveryPrice(shoppingCart);

            const hasHomeShipping = !!shoppingCart.storeCarts[cartIndex].shoppingLines.find(l => l.deliveryMethod === DeliveryMethods.HOME);
            if (hasHomeShipping) {
                this.setState({
                    hasValidCoordinates: false,
                });
            } else {
                this.setState({
                    hasValidCoordinates: false,
                    hasDeliveryHome: false,
                    deliveryCoordinates: {
                        latitude: null,
                        longitude: null,
                    },
                });
            }
        }
        if (value === DeliveryMethods.COURIER) {
            this.setState({
                showCourierInfoModal: true,
            });
        }
    }

    onCloseErrorModal = (): void => {
        this.setState({
            showErrorModal: false,
        });
    }

    onCloseCourierInfoModal = (): void => {
        this.setState({
            showCourierInfoModal: false,
        });
    }

    onCloseStockErrorModal = (): void => {
        const { history } = this.props;

        this.setState({
            showErrorModal: false,
        });

        history.push(CART_ROUTE);
    }

    onFinishRevision = (): void => {
        this.saveOrder();
    }

    getStoreHomeShippingInfo = async (): Promise<void> => {
        const { shoppingCart } = this.props;

        this.setState({
            isFetching: true,
        });

        if (shoppingCart) {
            const uniqueStoreIds = [...Array.from(new Set(shoppingCart.storeCarts.map(el => el.storeId)))];
            const storesInCart: Array<Store> = [];

            await axios.all(uniqueStoreIds.map(id => axios.get(storePublicURL(id))))
                .then(axios.spread((...res) => {
                    res.forEach(el => {
                        storesInCart.push(el.data);
                    });
                }))
                .catch(() => {
                    displayNotification(NOTIFICATION_TYPE.ERROR, 'Erro a obter informações de lojas');
                })
                .finally(() => {
                    this.setState({
                        isFetching: false,
                    });
                });

            this.setState({
                storesInCart,
            });
        }
    }

    prepare = async (): Promise<void> => {
        const { shoppingCart, updateCart, t } = this.props;
        const { isFetching, storesInCart } = this.state;

        if (isFetching || !shoppingCart) return;

        this.setState({
            isFetching: true,
        });

        let cartTotal = 0;
        await asyncForEach(shoppingCart.storeCarts, async (cart: StoreCart) => {
            const { data: courierData } = await axios.get(storesCourierURL());
            const isCourier = courierData.find((el: Store) => cart.storeId === el.id) !== undefined;
            const store = storesInCart.find(el => { return el.id === cart.storeId; });

            await asyncForEach(cart.shoppingLines, async (line: ShoppingLine) => {
                this.radioRefs[line.id] = React.createRef<FormRadioButtons>();

                try {
                    const { data } = await axios.get(productURL(line.productId));
                    if (data) {
                        const price = checkProductWithinPromotion(data) ? data.discountPrice : data.price;

                        line.productPrice = price;
                        line.subtotal = line.quantity * price;
                        line.availableDeliveryMethods = [{ label: t('enums.deliveryMethods.DEFAULT'), value: '' }];

                        forEach(data.availableDeliveryMethods, (e: keyof typeof DeliveryMethods) => {
                            Object.keys(DeliveryMethods).forEach(d => {
                                if (d === e) {
                                    if (isCourier && d === DeliveryMethods.THIRD_PARTY) return null;
                                    if (!isCourier && d === DeliveryMethods.COURIER) return null;
                                    if (store && store.deliveryData && d === DeliveryMethods.HOME) {
                                        const label = t(`enums.deliveryMethods.${DeliveryMethods[d]}`);
                                        return line.availableDeliveryMethods.push({
                                            label: `${label} \n (Distância máxima: ${store.deliveryData.distanceMax} Km)`,
                                            value: d,
                                        });
                                    }
                                    return line.availableDeliveryMethods.push({
                                        label: t(`enums.deliveryMethods.${DeliveryMethods[d]}`),
                                        value: d,
                                    });
                                }
                                return null;
                            });
                        });

                        if (data.availableDeliveryMethods.length < 2) line.deliveryMethod = last(data.availableDeliveryMethods) || '';

                        if (line.deliveryMethod === DeliveryMethods.THIRD_PARTY) {
                            this.setState({ hasDeliveryTaxes: true, hasDeliveryHome: true }, () => {
                                const cartIndex = shoppingCart.storeCarts.findIndex(c => c.id === cart.id);
                                this.updateDeliveryTaxes(Number(cartIndex), shoppingCart);
                            });
                        }

                        if (line.deliveryMethod === DeliveryMethods.HOME) {
                            this.setState({ hasDeliveryTaxes: true, hasDeliveryHome: true, hasStoreOwnedDelivery: true }, () => {
                                const cartIndex = shoppingCart.storeCarts.findIndex(c => c.id === cart.id);
                                this.updateDeliveryTaxes(Number(cartIndex), shoppingCart);
                            });
                        }

                        const lineIndex = cart.shoppingLines.findIndex(l => l.id === line.id);
                        cart.shoppingLines[lineIndex] = { ...line };
                        cartTotal += line.subtotal;
                    }
                } catch (e) {

                }
            });
        });

        axios.get(paymentFees())
            .then(response => {
                updateCart({
                    storeCarts: [...shoppingCart.storeCarts],
                    productsCounter: shoppingCart.productsCounter,
                    total: cartTotal,
                });
                this.setState({
                    isFetching: false,
                    fees: response.data,
                }, () => this.updateTaxValue());
            })
            .catch(() => {
                this.setState({
                    isFetching: false,
                });
            });
    }

    validateFields = (): boolean => {
        let errors: IFormError | null = {};
        const { shoppingCart } = this.props;
        const {
            paymentMethod,
            nif,
            address,
            contact,
            postalCode,
            mbWayContact,
            city,
            name,
            surname,
            hasDeliveryHome,
            currentStep,
            deliveryCoordinates,
        } = this.state;

        if (shoppingCart) {
            if (hasDeliveryHome) {
                const errorName = validateField('name', name, VALIDATIONS.CHECKOUT);
                if (errorName) errors.name = errorName;

                const errorSurname = validateField('surname', surname, VALIDATIONS.CHECKOUT);
                if (errorSurname) errors.surname = errorSurname;

                const errorAddress = validateField('address', address, VALIDATIONS.CHECKOUT);
                if (errorAddress) errors.address = errorAddress;

                const errorContact = validateField('contact', contact, VALIDATIONS.CHECKOUT);
                if (errorContact) errors.contact = errorContact;

                const errorPostalCode = validateField('postalCode', postalCode, VALIDATIONS.CHECKOUT);
                if (errorPostalCode) errors.postalCode = errorPostalCode;

                const errorCity = validateField('city', city, VALIDATIONS.CHECKOUT);
                if (errorCity) errors.city = errorCity;
            }

            Object.keys(shoppingCart.storeCarts).forEach(k => {
                Object.keys(shoppingCart.storeCarts[Number(k)].shoppingLines).forEach(l => {
                    if (!errors) return;
                    const line: ShoppingLine = shoppingCart.storeCarts[Number(k)].shoppingLines[Number(l)];
                    const errorDeliveryMethod = validateField('deliveryMethod', line.deliveryMethod, VALIDATIONS.CHECKOUT);
                    if (errorDeliveryMethod && !line.isVoucher) errors[`${line.productId}_deliveryMethod`] = errorDeliveryMethod;
                    if (line.deliveryMethod === DeliveryMethods.HOME) {
                        const { latitude, longitude } = deliveryCoordinates;
                        let errorLatitude;
                        let errorLongitude;
                        const isLatitudeNaN = isNaN(Number(latitude));
                        const isLongitudeNaN = isNaN(Number(longitude));

                        if (!latitude || !longitude) {
                            if (!latitude) errorLatitude = [{ typeOfViolation: 'NotBlank' }];
                            if (!longitude) errorLongitude = [{ typeOfViolation: 'NotBlank' }];
                        } else if (isLatitudeNaN || isLongitudeNaN) {
                            if (isLatitudeNaN) errorLatitude = [{ typeOfViolation: 'NotBlank' }];
                            if (isLongitudeNaN) errorLongitude = [{ typeOfViolation: 'NotBlank' }];
                        } else {
                            errorLatitude = validateField('latitude', Number(latitude), VALIDATIONS.CHECKOUT);
                            errorLongitude = validateField('longitude', Number(longitude), VALIDATIONS.CHECKOUT);
                        }

                        if (errorLatitude) errors.latitude = errorLatitude;
                        if (errorLongitude) errors.longitude = errorLongitude;
                    }
                });
            });

            if (paymentMethod === PaymentMethods.MBWAY) {
                const errorMBWayContact = validateField('mbWayContact', mbWayContact, VALIDATIONS.CHECKOUT);
                if (errorMBWayContact) errors.mbWayContact = errorMBWayContact;
            }

            if (nif && nif.trim() !== '' && !validatePtNif(nif)) {
                errors.nif = [{ typeOfViolation: 'ValidNif' }];
            }

            if ((!nif || (nif && nif.trim() === '')) && currentStep === 1) {
                errors.nif = [{ typeOfViolation: 'NotBlank' }];
            }

            if (errors && Object.keys(errors).length === 0) errors = null;

            this.setState({ formErrors: errors ? { fields: errors } : errors });
            return errors === null;
        }
        return false;
    }

    saveShippingAddressRequest = () => {
        const { saveShippingAddress } = this.state;

        if (saveShippingAddress) {
            const {
                name,
                surname,
                address,
                postalCode,
                city,
                contact,
            } = this.state;

            const { t } = this.props;

            const addressToSave = {
                phone: contact,
                address,
                city,
                name: `${name} ${surname}`,
                postalCode,
            };

            axios.post(userAddressListURL(), addressToSave).catch(() => {
                displayNotification(NOTIFICATION_TYPE.ERROR, t('errors.cannotSaveShippingAddress'));
            });
        }
    }

    sendCreateCartRequest = (fields: object): void => {
        const { history, fetchCart } = this.props;
        const { mbWayContact, paymentMethod } = this.state;
        this.setState({ isFetching: true });

        axios.post(shoppingURL(), fields)
            .then(response => {
                const { data } = response;

                this.sendGooglePurchaseTag(data.id);

                this.saveShippingAddressRequest();

                this.setState({
                    isFetching: false,
                }, () => {
                    fetchCart();
                    history.push({
                        pathname: CHECKOUT_SUCCESS_ROUTE,
                        state: {
                            mbWayContact,
                            paymentReferenceInfo: paymentMethod === PaymentMethods.DEBIT_CARD ? data.paymentReferenceInfo : null,
                            orderId: data.id,
                        },
                    });
                });
            })
            .catch(error => {
                if (error.response.data.result === 'PRODUCT OUT OF STOCK') {
                    const { shoppingCart, removeProduct } = this.props;
                    if (shoppingCart) {
                        shoppingCart.storeCarts.forEach(cart => {
                            cart.shoppingLines.forEach(line => {
                                if (!line.inStock) {
                                    removeProduct(line.id);
                                }
                            });
                        });
                        this.setState({
                            isFetching: false,
                            showStockErrorModal: true,
                        });
                    }
                } else {
                    this.setState({
                        isFetching: false,
                        showErrorModal: true,
                    });
                }
            });
    }

    saveOrder = (): void => {
        const { shoppingCart, user } = this.props;
        const {
            address,
            contact,
            postalCode,
            paymentMethod,
            mbWayContact,
            name,
            surname,
            nif,
            city,
            billingAddress,
            saveBillingAddress,
            deliveryCoordinates,
            hasDeliveryHome,
        } = this.state;

        if (this.validateFields() && shoppingCart) {
            const storeCartSend: Array<any> = [];

            Object.keys(shoppingCart.storeCarts).forEach(k => {
                const cart = shoppingCart.storeCarts[Number(k)];
                const lines: Array<any> = [];

                Object.keys(cart.shoppingLines).forEach(key => {
                    const line = cart.shoppingLines[Number(key)];

                    lines.push({
                        deliveryMethod: line.deliveryMethod,
                        productId: line.productId,
                        quantity: line.quantity,
                    });
                });

                storeCartSend.push({
                    storeId: cart.storeId,
                    shoppingLines: [...lines],
                });
            });

            let userContact = String(contact).trim();
            if (userContact === '' && user && user.contact) {
                userContact = user.contact;
            }

            const nameAssembled = (String(name).trim() !== '' && String(surname).trim() !== '') ? `${String(name).trim()} ${String(surname).trim()}` : null;

            const fields = {
                storeOrders: [...storeCartSend],
                address: String(address).trim(),
                contact: userContact,
                postalCode: String(postalCode).trim(),
                city: String(city).trim(),
                paymentMethod,
                invoiceName: nameAssembled,
                invoiceNif: String(nif).trim() !== '' ? String(nif).trim() : null,
                alias: paymentMethod === PaymentMethods.MBWAY ? mbWayContact : null,
                billingAddress,
                saveBillingAddress,
                latitude: hasDeliveryHome ? deliveryCoordinates.latitude : null,
                longitude: hasDeliveryHome ? deliveryCoordinates.longitude : null,
            };

            this.sendCreateCartRequest(fields);
        }
    }

    validateHasDeliveryPrice = (shoppingCart: ShoppingCart): void => {
        const { deliveryCoordinates, hasValidCoordinates } = this.state;
        let hasDeliveryHome = false;
        let hasStoreOwnedDelivery = false;
        let hasDeliveryTaxes = false;

        Object.keys(shoppingCart.storeCarts).forEach(k => {
            Object.keys(shoppingCart.storeCarts[Number(k)].shoppingLines).forEach(l => {
                const line: ShoppingLine = shoppingCart.storeCarts[Number(k)].shoppingLines[Number(l)];

                if (line.deliveryMethod === DeliveryMethods.THIRD_PARTY) {
                    hasDeliveryHome = true;
                    hasDeliveryTaxes = true;
                }
                if (line.deliveryMethod === DeliveryMethods.HOME) {
                    hasDeliveryHome = true;
                    hasStoreOwnedDelivery = true;
                    hasDeliveryTaxes = true;
                }
            });
        });

        this.setState({
            hasDeliveryHome,
            hasStoreOwnedDelivery,
            hasDeliveryTaxes,
            hasValidCoordinates: hasStoreOwnedDelivery ? hasValidCoordinates : false,
            deliveryCoordinates: hasDeliveryHome ? deliveryCoordinates : { latitude: null, longitude: null },
        });
    }

    updateTaxValue = (): void => {
        const { shoppingCart } = this.props;
        const { paymentMethod, fees } = this.state;

        const newFee: PaymentFee | undefined = fees.find((f: PaymentFee) => f.paymentMethod === paymentMethod);

        if (newFee && shoppingCart) {
            let taxValue = 0;
            let feeCalc: number | null = null;
            if (newFee.feePercentage !== 0) {
                feeCalc = decimalAdjustRound((newFee.feePercentage / 100) * (shoppingCart.total + this.totalDeliveryPrices()) + newFee.fee);
            }
            const fee = feeCalc === null ? newFee.fee : feeCalc;
            const taxAmount = newFee.paymentMethod === PaymentMethods.MBWAY ? clamp(fee, newFee.minFee, newFee.maxFee) : fee;
            taxValue = decimalAdjustRound(taxAmount * VAT);

            this.setState({
                ...this.state,
                taxValue,
                cartTotal: decimalAdjustRound((shoppingCart.total) + taxValue),
            });
        }
    }

    updateDeliveryTaxes = (cartIndex: number, shoppingCart: ShoppingCart): void => {
        const { updateCart } = this.props;
        const newShoppingCart = shoppingCart;
        let storeCartHasHomeDelivery = false;

        if (shoppingCart) {
            if (!shoppingCart.storeCarts[cartIndex]) {
                return;
            }
            const cart = newShoppingCart.storeCarts[cartIndex];
            const ids: Array<number> = [];
            Object.keys(cart.shoppingLines).forEach(e => {
                const shoppingLine = cart.shoppingLines[Number(e)];
                if (shoppingLine.deliveryMethod === DeliveryMethods.THIRD_PARTY) {
                    for (let index = 0; index < shoppingLine.quantity; index++) {
                        ids.push(shoppingLine.productId);
                    }
                }
            });

            if (ids.length > 0) {
                let totalPrice = 0;
                Object.keys(cart.shoppingLines).forEach(e => {
                    const shoppingLine = cart.shoppingLines[Number(e)];
                    if (shoppingLine.deliveryMethod === DeliveryMethods.HOME) {
                        storeCartHasHomeDelivery = true;
                    }
                });

                axios.get(shippingPriceCalcultateURL(`id=${ids.join('&id=')}`)).then(
                    response => {
                        const { storesInCart } = this.state;
                        const { data } = response;
                        forEach(data, r => {
                            totalPrice += r.price;
                        });
                        if (storeCartHasHomeDelivery) {
                            const store = storesInCart.find(str => {
                                return str.id === cart.storeId;
                            });

                            if (store && store.deliveryData) {
                                totalPrice += store.deliveryData.shippingPriceMin;
                            }
                        }
                        cart.deliveryPrice = totalPrice;
                        updateCart(newShoppingCart);
                        this.validateHasDeliveryPrice(shoppingCart);
                        this.updateTaxValue();
                        this.setState({ hasDeliveryTaxes: true });
                    },
                );
            } else {
                let hasDeliveryTaxes = false;
                let deliveryPrice = 0;
                Object.keys(cart.shoppingLines).forEach(l => {
                    const line: ShoppingLine = cart.shoppingLines[Number(l)];
                    if (line.deliveryMethod === DeliveryMethods.HOME) hasDeliveryTaxes = true;
                });
                if (hasDeliveryTaxes) {
                    const { storesInCart } = this.state;
                    const store = storesInCart.find(aux => {
                        return aux.id === cart.storeId;
                    });

                    if (store) deliveryPrice = store.deliveryData ? store.deliveryData.shippingPriceMin : 0;
                }
                newShoppingCart.storeCarts[cartIndex].deliveryPrice = deliveryPrice;
                updateCart(newShoppingCart);
                this.updateTaxValue();
                this.setState({ hasDeliveryTaxes }, () => this.validateCoordinates());
            }
        }
    }

    deselectHomeDeliveries = (storeId: number) => {
        const { shoppingCart, updateCart } = this.props;
        const shoppingCartAux = shoppingCart;

        if (shoppingCartAux) {
            shoppingCartAux.storeCarts.forEach(storeCart => {
                if (storeCart.storeId === storeId) {
                    storeCart.shoppingLines.forEach((line, idx) => {
                        const lineAux = storeCart.shoppingLines[idx];
                        if (lineAux.deliveryMethod === DeliveryMethods.HOME) {
                            lineAux.deliveryMethod = '';
                            const ref = this.radioRefs[lineAux.id].current;
                            if (ref) ref.reset();
                        }
                    });
                }
            });

            updateCart(shoppingCartAux);

            setTimeout(() => {
                scrollToTop();
            },
            100);
        }
    }

    validateCoordinates = (): void => {
        const { deliveryCoordinates: { latitude, longitude }, storesInCart, hasValidCoordinates } = this.state;
        const { shoppingCart, updateCart } = this.props;
        const shoppingCartAux = shoppingCart;

        let isValid = false;
        const isLatitudeNaN = latitude === null ? true : isNaN(Number(latitude));
        const isLongitudeNaN = longitude === null ? true : isNaN(Number(longitude));

        if (shoppingCartAux && !isLongitudeNaN && !isLatitudeNaN) {
            shoppingCartAux.storeCarts.forEach((storeCart, idx) => {
                const lineWithHomeShipping = storeCart.shoppingLines.find(el => {
                    return el.deliveryMethod === DeliveryMethods.HOME;
                });
                const { storeId } = storeCart;

                if (lineWithHomeShipping) {
                    axios.get(homeShippingValidateURL(storeId, Number(latitude), Number(longitude)))
                        .then(res => {
                            const { data } = res;
                            isValid = data.allowDelivery;
                            if (!data.allowDelivery) {
                                throw new Error();
                            } else {
                                if (!hasValidCoordinates) {
                                    updateCart(shoppingCartAux);
                                    this.setState({ hasDeliveryTaxes: true });
                                }
                            }
                        })
                        .catch(() => {
                            isValid = false;
                            const store = storesInCart.find(el => {
                                return el.id === storeId;
                            });

                            displayNotification(NOTIFICATION_TYPE.ERROR,
                                `A loja ${store?.name} não realiza entregas ao Domicílio para as coordenadas selecionadas. Por favor selecione outro método de entrega.`);
                            this.deselectHomeDeliveries(storeId);

                            setTimeout(() => {
                                scrollToTop();
                            },
                            100);
                        })
                        .finally(() => {
                            this.setState({
                                isFetching: false,
                                hasValidCoordinates: isValid,
                            });
                        });
                }
            });
        }
    }

    totalDeliveryPrices = (): number => {
        const { shoppingCart } = this.props;

        let totalPrice = 0;
        if (shoppingCart) {
            forEach(shoppingCart.storeCarts, e => {
                totalPrice += e.deliveryPrice || 0;
            });
        }
        return totalPrice;
    }

    onNextStage = async (): Promise<any> => {
        const {
            currentStep, paymentMethod, hasStoreOwnedDelivery, hasValidCoordinates,
        } = this.state;

        switch (currentStep) {
            case 0:
                this.sendGoogleCheckoutTag(1);
                break;
            case 1:
                if (paymentMethod) {
                    this.sendGoogleCheckoutTag(2, paymentMethod.toString());
                }
                break;
            case 2:
                this.sendGoogleCheckoutTag(3);
                break;
            default:
        }

        if (this.validateFields() && (hasStoreOwnedDelivery ? (hasStoreOwnedDelivery && hasValidCoordinates) : true)) {
            this.setState({
                currentStep: currentStep + 1,
            });
            setTimeout(() => {
                scrollToTop();
            },
            100);
        }
    }

    sendGoogleCheckoutTag = (step: number, option?: string) => {
        const { shoppingCart } = this.props;

        const impressionProducts = shoppingCart?.storeCarts.map(cart => {
            return cart.shoppingLines.map((line, idx) => {
                return ({
                    name: line.productName,
                    id: line.productId,
                    price: line.productPrice,
                    list: 'Checkout',
                    position: idx,
                });
            });
        });

        const gtmECommerceTag: DataLayerArgs = {
            dataLayer: {
                event: 'checkout',
                ecommerce: {
                    checkout: {
                        actionField: {
                            step,
                            option,
                        },
                        products: [impressionProducts],
                    },
                },
            },
        };

        TagManager.dataLayer(gtmECommerceTag);
    }

    sendGooglePurchaseTag = (orderId: number) => {
        const { shoppingCart } = this.props;

        const impressionProducts = shoppingCart?.storeCarts.map(cart => {
            return cart.shoppingLines.map((line, idx) => {
                return ({
                    name: line.productName,
                    id: line.productId,
                    price: line.productPrice,
                    list: 'Checkout',
                    position: idx,
                });
            });
        });

        const gtmECommerceTag: DataLayerArgs = {
            dataLayer: {
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: orderId,
                            revenue: shoppingCart?.total,
                        },
                        products: impressionProducts,
                    },
                },
            },
        };

        TagManager.dataLayer(gtmECommerceTag);
    }

    moveToStep = (step: number) => {
        this.setState({
            currentStep: step,
        });
    }

    renderHeader = () => {
        const { t } = this.props;
        const { currentStep } = this.state;

        return (
            <div className="app_screen__checkout__step-header">
                <div
                    className={`app_screen__checkout__step-header__icon--${currentStep >= 0 ? 'active' : 'inactive'}`}
                    onClick={() => { if (currentStep > 0) this.moveToStep(0); }}
                >
                    <ReactSVG src={delivery} />
                    <h2>{t('checkout.steps.delivery')}</h2>
                </div>
                <span className="app-screen__checkout__step-header__separator" />
                <div
                    className={`app_screen__checkout__step-header__icon--${currentStep >= 1 ? 'active' : 'inactive'}`}
                    onClick={() => { if (currentStep > 1) this.moveToStep(1); }}
                >
                    <ReactSVG src={payment} />
                    <h2>{t('checkout.steps.payment')}</h2>
                </div>
                <span className="app-screen__checkout__step-header__separator" />
                <div
                    className={`app_screen__checkout__step-header__icon--${currentStep === 2 ? 'active' : 'inactive'}`}
                    onClick={() => { if (currentStep > 2) this.moveToStep(2); }}
                >
                    <ReactSVG src={check} />
                    <h2>{t('checkout.steps.review')}</h2>
                </div>
            </div>
        );
    }

    autofillShippingAddress = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { userAddresses } = this.state;
        const { user } = this.props;
        const selectedId = e.currentTarget.value;

        if (selectedId === '-1' && user) {
            const nameSubStr = user.name.split(' ');
            const nameAux = nameSubStr[0];
            const surnameAux = nameSubStr[nameSubStr.length - 1];

            this.setState({
                selectedShippingAddress: this.defaultSelectedShippingAddress,
                address: user.address || '',
                name: nameAux || '',
                surname: surnameAux || '',
                city: user.city || '',
                postalCode: user.postalCode || '',
                contact: user.contact || '',
            });
        } else {
            const foundAddress = userAddresses.find(el => {
                return el.id === parseInt(selectedId);
            });

            if (foundAddress) {
                const splitName = foundAddress.name.split(' ');
                const firstName = splitName[0];
                const surname = splitName[splitName.length - 1];

                this.setState({
                    selectedShippingAddress: {
                        label: `${foundAddress.name}, ${foundAddress.address}, ${foundAddress.postalCode} ${foundAddress.city}`,
                        value: foundAddress.id,
                    },
                    address: foundAddress.address,
                    name: firstName,
                    surname,
                    city: foundAddress.city,
                    postalCode: foundAddress.postalCode,
                    contact: foundAddress.phone,
                });
            }
        }

        this.onSetSaveShippingAddress(false);
    }

    autofillBillingAddress = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { userAddresses } = this.state;
        const selectedId = e.currentTarget.value;

        const foundAddress = userAddresses.find(el => {
            return el.id === parseInt(selectedId);
        });

        if (foundAddress) {
            this.setState({
                selectedBillingAddress: {
                    label: `${foundAddress.name}, ${foundAddress.address}, ${foundAddress.postalCode} ${foundAddress.city}`,
                    value: foundAddress.id,
                },
                billingAddress: foundAddress,
            });
        }

        this.onSetSaveBillingAddress(false);
    }

    onMapClick = (e: LeafletMouseEvent): void => {
        const { lat, lng } = e.latlng;

        const latitude = lat.toFixed(5);
        const longitude = lng.toFixed(5);

        this.setState({
            deliveryCoordinates: {
                latitude,
                longitude,
            },
        }, () => {
            this.validateCoordinates();
            this.validateFields();
        });
    }

    renderStoreMarkers = () => {
        const { storesInCart } = this.state;
        const { shoppingCart } = this.props;

        if (shoppingCart) {
            const storePin: Icon = new L.Icon({
                iconUrl: storeMarker,
                iconSize: [55, 75],
                iconAnchor: [27, 75],
                popupAnchor: [0, -76],
            });

            const storesToPin: Array<Store> = [];

            shoppingCart.storeCarts.forEach(storeCart => {
                const lineWithHomeShipping = storeCart.shoppingLines.find(el => {
                    return el.deliveryMethod === DeliveryMethods.HOME;
                });

                if (lineWithHomeShipping) {
                    const storeToPin = storesInCart.find(el => {
                        return el.id === storeCart.storeId;
                    });

                    if (storeToPin) storesToPin.push(storeToPin);
                }
            });

            return storesToPin.map(store => {
                const { deliveryData } = store;

                if (deliveryData) {
                    const position: LatLng = new LatLng(deliveryData.latitude, deliveryData.longitude);

                    return (
                        <Marker position={position} icon={storePin}>
                            <Tooltip permanent direction="center" opacity={0.8}>{store.name}</Tooltip>
                        </Marker>
                    );
                }
                return null;
            });
        }
        return null;
    }

    renderHomeShippingMap = () => {
        const { t } = this.props;
        const { deliveryCoordinates: { latitude, longitude }, formErrors } = this.state;

        const position: LatLng = new LatLng(Number(latitude) || 39.7541222, Number(longitude) || -8.8409781);

        return (
            <>
                <div className="app_screen__checkout__address-container">
                    <h4>Selecione as coordenadas de entrega no Mapa*</h4>
                    <Map center={position} zoom={13} onclick={this.onMapClick}>
                        <TileLayer url="https://c.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Marker position={position} icon={marker} />
                        {this.renderStoreMarkers()}
                    </Map>
                    <hr />
                    <div className="app_screen__checkout__address-container__form-single-field">
                        <FormTextField
                            placeholder={`${t('storeAdmin.shipping.labels.latitude')}*`}
                            name="latitude"
                            value={latitude}
                            errors={get(formErrors, 'fields.latitude', null)}
                            onChange={this.onInputChange}
                        />
                    </div>
                    <div className="app_screen__checkout__address-container__form-single-field">
                        <FormTextField
                            placeholder={`${t('storeAdmin.shipping.labels.longitude')}*`}
                            name="longitude"
                            value={longitude}
                            errors={get(formErrors, 'fields.longitude', null)}
                            onChange={this.onInputChange}
                        />
                    </div>
                </div>
            </>
        );
    }

    renderShippingScreen = () => {
        const {
            t,
            shoppingCart,
            user,
        } = this.props;

        const {
            formErrors,
            name,
            surname,
            address,
            contact,
            postalCode,
            hasDeliveryHome,
            hasDeliveryTaxes,
            city,
            userAddresses,
            selectedShippingAddress,
            saveShippingAddress,
            showSaveShippingAddressCheckbox,
            hasStoreOwnedDelivery,
        } = this.state;

        const shippingAddressesSelectOptions = [this.defaultSelectedShippingAddress, ...this.addressListToSelectOption(userAddresses)];

        const isGuest = user?.groups[0] === UserRoles.GUEST;

        return (
            <>
                <Col lg={12}>
                    <h5 className="page-title--shipping">{t('checkout.shippingTitle')}</h5>
                </Col>
                <Col lg={12} className="cart-container">
                    {shoppingCart ? (
                        Object.keys(shoppingCart.storeCarts).map(k => {
                            const storeCart = shoppingCart.storeCarts[Number(k)];
                            // The store slug needs to be gotten from the product because the api doesn't send it in the store object, only on the products
                            const storeSlug = storeCart.shoppingLines[0].storeInfo.slug;
                            return (
                                <div key={storeCart.id} className="store-cart-container">
                                    <h5 className="store-name" onClick={(): void => this.onStoreClick(storeSlug)}>{storeCart.storeName}</h5>
                                    {Object.keys(storeCart.shoppingLines).map((key, idx) => {
                                        const line = storeCart.shoppingLines[Number(key)];
                                        const isLastElement = (idx === Object.keys(storeCart.shoppingLines).length - 1);

                                        return (
                                            <React.Fragment key={line.id}>
                                                <div key={line.id} className="line-container">
                                                    <img
                                                        src={line.productThumbnailUrl}
                                                        alt={line.productName}
                                                        onClick={(): void => this.onProductClick(line.productId)}
                                                    />
                                                    <div className="line-info">
                                                        <span
                                                            className="product-title"
                                                            onClick={(): void => this.onProductClick(line.productId)}
                                                        >
                                                            {line.productName}
                                                            <p className="product-title__quantity">{t('checkout.information.quantity')}&nbsp;{line.quantity}</p>
                                                        </span>
                                                        <div className="attributes">
                                                            <div className="grey">
                                                                {line.productSize && line.productSize !== ''
                                                                    && <p className="line">{t('checkout.size')}&nbsp;{line.productSize}</p>
                                                                }
                                                                {line.productColor && line.productColor !== ''
                                                                    && (
                                                                        <p className="line">{t('checkout.color')}&nbsp;
                                                                            <div style={{ background: line.productColor }} />
                                                                        </p>
                                                                    )}
                                                                {line.isVoucher
                                                                    && (
                                                                        <p className="line">{constructVoucherBonusString(line, 'Bónus: ')}</p>
                                                                    )}
                                                            </div>
                                                            <p className="bold-blue">{numberToCurrency(line.productPrice)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="radio-container">
                                                        <FormRadioButtons
                                                            ref={this.radioRefs[line.id]}
                                                            name={`${line.productName}_delivery-method`}
                                                            options={line.availableDeliveryMethods.filter(e => e.value !== '')}
                                                            onChange={(value: string) => this.onDeliveryMethodChange(value, storeCart.id, line.id)}
                                                            initialSelected={line.deliveryMethod}
                                                            errors={get(formErrors, `fields.${line.productId}_deliveryMethod`, null)}
                                                        />
                                                    </div>
                                                </div>
                                                {!isLastElement && <hr className="divider my-3" />}
                                            </React.Fragment>
                                        );
                                    })}
                                    {hasDeliveryTaxes && (
                                        <div className="store-cart-container__delivery-price" style={{ width: '100%' }}>
                                            <span>{t('checkout.deliveryPrice')}&nbsp;</span>
                                            <span>{numberToCurrency(storeCart.deliveryPrice || 0)}</span>
                                        </div>
                                    )}
                                    <hr className="store-divider" />
                                </div>
                            );
                        })
                    ) : (
                        <div className="no-data">
                            {t('checkout.noShoppingCart')}
                        </div>
                    )}
                </Col>
                {hasDeliveryHome && (
                    <>
                        <div className="app_screen__checkout__address-container">
                            <h4>{t('checkout.dataTitle')}</h4>
                            <div className="app_screen__checkout__address-container__form-single-field">
                                <FormTextField
                                    name="name"
                                    value={name}
                                    placeholder={`${t('checkout.name')}*`}
                                    onChange={this.onShippingAddressInputChange}
                                    errors={get(formErrors, 'fields.name', null)}
                                />
                            </div>
                            <div className="app_screen__checkout__address-container__form-single-field">
                                <FormTextField
                                    name="surname"
                                    value={surname}
                                    placeholder={`${t('checkout.surname')}*`}
                                    onChange={this.onShippingAddressInputChange}
                                    errors={get(formErrors, 'fields.surname', null)}
                                />
                            </div>
                            <div className="app_screen__checkout__address-container__form-single-field">
                                <FormTextField
                                    name="contact"
                                    value={contact}
                                    placeholder={`${t(checkoutLabels.contact)}*`}
                                    onChange={this.onShippingAddressInputChange}
                                    errors={get(formErrors, 'fields.contact', null)}
                                />
                            </div>
                        </div>
                        <div className="app_screen__checkout__address-container">
                            <h4>{t('checkout.addressTitle')}</h4>
                            {userAddresses.length > 0 && !isGuest && (
                                <>
                                    <div className="app_screen__checkout__address-container__form-single-field">
                                        <FormSelectField
                                            label={t('checkout.addressSelectLabel')}
                                            name="shippingAddress"
                                            value={selectedShippingAddress.value}
                                            options={shippingAddressesSelectOptions}
                                            onChange={this.autofillShippingAddress}
                                        />
                                    </div>
                                    <hr />
                                </>
                            )}
                            <div className="app_screen__checkout__address-container__form-single-field">
                                <FormTextField
                                    name="address"
                                    value={address}
                                    placeholder={`${t('checkout.address')}*`}
                                    maxLength={180}
                                    onChange={this.onShippingAddressInputChange}
                                    errors={get(formErrors, 'fields.address', null)}
                                />
                            </div>

                            <div className="app_screen__checkout__address-container__form-single-field">
                                <FormTextField
                                    name="postalCode"
                                    value={postalCode}
                                    placeholder={`${t('checkout.postalCode')}*`}
                                    maxLength={20}
                                    onChange={this.onShippingAddressInputChange}
                                    errors={get(formErrors, 'fields.postalCode', null)}
                                />
                            </div>
                            <div className="app_screen__checkout__address-container__form-single-field">
                                <FormTextField
                                    name="city"
                                    value={city}
                                    placeholder={`${t('checkout.city')}*`}
                                    onChange={this.onShippingAddressInputChange}
                                    errors={get(formErrors, 'fields.city', null)}
                                />
                            </div>
                            {showSaveShippingAddressCheckbox && !isGuest && (
                                <div className="app_screen__checkout__address-container__checkbox-container">
                                    <FormCheckbox
                                        label={t('checkout.saveShippingAddress')}
                                        name="saveShippingAddrCheckbox"
                                        value={saveShippingAddress}
                                        onChange={this.onToggleSaveShippingAddress}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
                {hasStoreOwnedDelivery && this.renderHomeShippingMap()}
            </>
        );
    }

    onToggleSaveBillingAddress = () => {
        const { saveBillingAddress } = this.state;
        this.setState({
            saveBillingAddress: !saveBillingAddress,
        });
    }

    onSetSaveBillingAddress = (value: boolean) => {
        this.setState({
            showSaveBillingAddressCheckbox: value,
            saveBillingAddress: false,
        });
    }

    onToggleSaveShippingAddress = () => {
        const { saveShippingAddress } = this.state;
        this.setState({
            saveShippingAddress: !saveShippingAddress,
        });
    }

    onSetSaveShippingAddress = (value: boolean) => {
        this.setState({
            showSaveShippingAddressCheckbox: value,
            saveShippingAddress: false,
        });
    }

    addressListToSelectOption = (addrList: UserAddress[]): Array<SelectOption> => {
        return addrList.map(addr => {
            const label = `${addr.name}, ${addr.address}, ${addr.postalCode} ${addr.city}`;
            return ({
                value: addr.id,
                label,
            });
        });
    }

    renderPaymentScreen = () => {
        const { t, user } = this.props;
        const {
            paymentMethod,
            mbWayContact,
            nif,
            billingAddress,
            saveBillingAddress,
            showSaveBillingAddressCheckbox,
            selectedBillingAddress,
            userAddresses,
            formErrors,
        } = this.state;

        const billingAddressesSelectOptions: Array<SelectOption> = this.addressListToSelectOption(userAddresses);

        const isGuest = user?.groups[0] === UserRoles.GUEST;

        return (
            <>
                <div className="cart-container">
                    <Col lg={12} className="payment-options">
                        <p className="title">{t('checkout.paymentMethod')}</p>
                        <div className="payment-options__options-container">
                            <div
                                className="form-check"
                                onClick={() => this.onPaymentMethodClick(PaymentMethods.DEBIT_CARD)}
                            >
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="paymentMethod"
                                    value={PaymentMethods.DEBIT_CARD}
                                    onChange={this.onPaymentMethodChange}
                                    checked={paymentMethod === PaymentMethods.DEBIT_CARD}
                                />
                                <label className="form-check-label" htmlFor="exampleRadios1">
                                    <img src={mbLogo} alt="MB" />
                                    <p>{t('checkout.information.DEBIT_CARD')}</p>
                                </label>
                            </div>
                            <div
                                className="form-check"
                                onClick={() => this.onPaymentMethodClick(PaymentMethods.MBWAY)}
                            >
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="paymentMethod"
                                    value={PaymentMethods.MBWAY}
                                    onChange={this.onPaymentMethodChange}
                                    checked={paymentMethod === PaymentMethods.MBWAY}
                                />
                                <label className="form-check-label" htmlFor="exampleRadios1">
                                    <img src={mbWayLogo} alt="MBWay" />

                                    <p>{t('checkout.information.MBWAY')}</p>
                                </label>
                            </div>
                        </div>
                    </Col>
                    <div className="payment-options__fields-container">
                        <p className="title mt-2">{t('checkout.billingInformation')}</p>
                        <div className="payment-options__fields-container__form-single-field">
                            <FormTextField
                                name="nif"
                                value={nif}
                                placeholder={`${t('checkout.nif')}*`}
                                maxLength={9}
                                onChange={this.onNifChange}
                                errors={get(formErrors, 'fields.nif', null)}
                            />
                        </div>

                        {paymentMethod === PaymentMethods.MBWAY && (
                            <div className="payment-options__fields-container__form-single-field">
                                <FormTextField
                                    placeholder={`${t(checkoutLabels.contact)}*`}
                                    name="mbWayContact"
                                    value={mbWayContact}
                                    maxLength={15}
                                    onChange={this.onInputChange}
                                    errors={get(formErrors, 'fields.mbWayContact', null)}
                                />
                            </div>
                        )}

                        <h6>{t('checkout.reviewBillingAddress')}</h6>
                        {userAddresses.length > 0 && !isGuest && (
                            <>
                                <div className="payment-options__fields-container__form-single-field">
                                    <FormSelectField
                                        label={t('checkout.addressSelectLabel')}
                                        name="shippingAddress"
                                        value={selectedBillingAddress.value}
                                        options={billingAddressesSelectOptions}
                                        onChange={this.autofillBillingAddress}
                                    />
                                </div>
                                <hr />
                            </>
                        )}
                        <div className="payment-options__fields-container__form-single-field">
                            <FormTextField
                                name="name"
                                value={billingAddress.name}
                                placeholder={t('checkout.name')}
                                maxLength={180}
                                onChange={this.onBillingAddressInputChange}
                            />
                        </div>
                        <div className="payment-options__fields-container__form-single-field">
                            <FormTextField
                                name="phone"
                                value={billingAddress.phone}
                                placeholder={t(checkoutLabels.contact)}
                                maxLength={180}
                                onChange={this.onBillingAddressInputChange}
                            />
                        </div>
                        <div className="payment-options__fields-container__form-single-field">
                            <FormTextField
                                name="address"
                                value={billingAddress.address}
                                placeholder={t('checkout.information.billingAddress')}
                                maxLength={180}
                                onChange={this.onBillingAddressInputChange}
                            />
                        </div>
                        <div className="payment-options__fields-container__form-single-field">
                            <FormTextField
                                name="postalCode"
                                value={billingAddress.postalCode}
                                placeholder={t('checkout.postalCode')}
                                maxLength={180}
                                onChange={this.onBillingAddressInputChange}
                            />
                        </div>
                        <div className="payment-options__fields-container__form-single-field">
                            <FormTextField
                                name="city"
                                value={billingAddress.city}
                                placeholder={t('checkout.city')}
                                maxLength={180}
                                onChange={this.onBillingAddressInputChange}
                            />
                        </div>
                        {showSaveBillingAddressCheckbox && !isGuest && (
                            <div className="payment-options__fields-container__checkbox-container">
                                <FormCheckbox
                                    label={t('checkout.saveBillingAddress')}
                                    name="saveBillingAddrCheckbox"
                                    value={saveBillingAddress}
                                    onChange={this.onToggleSaveBillingAddress}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }

    onAgreementChange = (): void => {
        const { agreedToTerms } = this.state;

        this.setState({
            agreedToTerms: !agreedToTerms,
        });
    }

    renderConfirmationScreen = () => {
        const {
            t,
            shoppingCart,
        } = this.props;
        const {
            paymentMethod,
            mbWayContact,
            cartTotal,
            taxValue,
            hasDeliveryTaxes,
            hasDeliveryHome,
            address,
            postalCode,
            contact,
            city,
            agreedToTerms,
            billingAddress,
            canHaveBonus,
        } = this.state;

        let storeCount = 0;

        let taxLabel: string = t('checkout.debitCardTax');
        if (paymentMethod === PaymentMethods.MBWAY) {
            taxLabel = t('checkout.mbwayTax');
        }
        const hasBillingAddr = billingAddress
            && billingAddress.name !== ''
            && billingAddress.city !== ''
            && billingAddress.phone !== ''
            && billingAddress.address !== ''
            && billingAddress.postalCode !== '';

        return (
            <div className="app_screen__cart">
                <MediaQuery maxWidth={600}>
                    <p className="page-title">Confirme o seu pedido.</p>
                </MediaQuery>
                <div className="cart-container">
                    <Col lg={12} className="cart-container">
                        {shoppingCart ? (
                            Object.keys(shoppingCart.storeCarts).map(k => {
                                const cart = shoppingCart.storeCarts[Number(k)];
                                storeCount++;
                                return (
                                    <div key={cart.id} className="store-cart-container">
                                        {Object.keys(cart.shoppingLines).map((key, idx) => {
                                            const line = cart.shoppingLines[Number(key)];
                                            const isLastElement = (idx === Object.keys(cart.shoppingLines).length - 1);

                                            return (
                                                <React.Fragment key={line.id}>
                                                    <div key={line.id} className="line-container">
                                                        <img
                                                            src={line.productThumbnailUrl}
                                                            alt={line.productName}
                                                            onClick={(): void => this.onProductClick(line.productId)}
                                                        />
                                                        <div className="line-info">
                                                            <h5 className="store-name">{cart.storeName}</h5>
                                                            <span
                                                                className="product-title"
                                                                onClick={(): void => this.onProductClick(line.productId)}
                                                            >
                                                                {line.productName}
                                                                <p className="product-title__quantity">{t('checkout.information.quantity')}&nbsp;{line.quantity}</p>
                                                            </span>
                                                            {line.isVoucher && canHaveBonus
                                                                && (
                                                                    <span className="bonus">{constructVoucherBonusString(line)}</span>
                                                                )
                                                            }
                                                            <div className="attributes">
                                                                <div className="grey">
                                                                    {line.productSize && line.productSize !== ''
                                                                        && <p className="line">{t('checkout.size')}&nbsp;{line.productSize}</p>
                                                                    }
                                                                    {line.productColor && line.productColor !== ''
                                                                        && (
                                                                            <p className="line">{t('checkout.color')}&nbsp;
                                                                                <div style={{ background: line.productColor }} />
                                                                            </p>
                                                                        )}
                                                                    {line.isVoucher
                                                                        && (
                                                                            <p className="line">{t('checkout.value')}&nbsp;{numberToCurrency(line.productPrice)}</p>
                                                                        )
                                                                    }
                                                                </div>
                                                                <p className="bold-blue">{numberToCurrency(line.productPrice)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {!isLastElement && <hr className="divider my-3" />}
                                                </React.Fragment>
                                            );
                                        })}
                                        {storeCount < shoppingCart.storeCarts.length && (
                                            <hr className="divider store-divider" />
                                        )}
                                    </div>
                                );
                            })
                        ) : (
                            <div className="no-data">
                                {t('checkout.noShoppingCart')}
                            </div>
                        )}
                    </Col>
                </div>
                <div className="fullwidth-container">
                    <Col lg={12} className="info-container">
                        <div className="content">
                            {shoppingCart && (
                                <div className="price-container small">
                                    <span>{t('checkout.subtotal')}&nbsp;</span>
                                    <span className="bold">{numberToCurrency(shoppingCart.total)}</span>
                                </div>
                            )}
                            {(taxValue || taxValue === 0) && (
                                <div className="price-container small">
                                    <span>{taxLabel}&nbsp;</span>
                                    <span className="bold">{numberToCurrency(taxValue)}</span>
                                </div>
                            )}
                            {hasDeliveryTaxes && (
                                <div className="price-container small">
                                    <span>{t('checkout.deliveryPrices')}&nbsp;</span>
                                    <span className="bold">{numberToCurrency(this.totalDeliveryPrices())}</span>
                                </div>
                            )}
                            <hr className="divider store-divider" />
                            <div className="price-container__total">
                                <span className="price-container__total__label">{t('checkout.total')}&nbsp;</span>
                                <div className="price-container__total__price">
                                    <span className="price-container__total__price__number">{numberToCurrency(cartTotal + this.totalDeliveryPrices())}</span>
                                    <span className="price-container__total__price__tax-label">{t('checkout.iva')}</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </div>
                <div className="info-container--address-container">
                    <div>
                        {hasDeliveryHome && (
                            <div className="info-container--address-container__address">
                                <h2 className="bold">{t('checkout.information.address')}</h2>
                                <span className="bold">{address}</span>
                                <span className="bold">{postalCode}</span>
                                <span className="bold">{city}</span>
                                <span className="bold">{contact}</span>
                            </div>
                        )}
                        {hasBillingAddr && (
                            <div className="info-container--address-container__address">
                                <h2 className="bold">{t('checkout.information.billingAddress')}</h2>
                                <span className="bold">{billingAddress.address}</span>
                                <span className="bold">{billingAddress.postalCode}</span>
                                <span className="bold">{billingAddress.city}</span>
                                <span className="bold">{billingAddress.phone}</span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="info-container--payment-method-container">
                    <div>
                        <h2>{t('checkout.information.paymentMethod')}</h2>
                        {paymentMethod === PaymentMethods.DEBIT_CARD && (
                            <label className="form-check-label">
                                <img src={mbLogo} alt="MB" />
                                <p>{t('checkout.information.DEBIT_CARD')}</p>
                            </label>
                        )}
                        {paymentMethod === PaymentMethods.MBWAY && mbWayContact && (
                            <>
                                <div className="form-check-label">
                                    <img src={mbWayLogo} alt="MB" />
                                    <p>{t('checkout.information.MBWAY')}</p>
                                </div>
                                <div className="mt-4">
                                    <span>{`${t('checkout.information.mbwayContact')} ${phoneNumberWithMask(mbWayContact)}`}</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="app_screen__checkout__controls">
                    <MediaQuery minWidth={601}>
                        <div className="app_screen__checkout__controls__checkbox">
                            <FormCheckbox name="agreedToTerms" value={agreedToTerms} label={t('checkout.agreement.line1')} onChange={this.onAgreementChange} />
                            <span>
                                {t('checkout.agreement.line2.part1')}
                                &nbsp;
                                <NavLink to={TERMS_AND_CONDITIONS_OF_USE_ROUTE} target="_blank">{t('checkout.agreement.line2.termsAndConditions')}</NavLink>
                                &nbsp;
                                {t('checkout.agreement.line2.part2')}
                                &nbsp;
                                <NavLink to={PRIVACY_POLICY_ROUTE} target="_blank">{t('checkout.agreement.line2.privacyPolicy')}</NavLink>
                                &nbsp;
                                {t('checkout.agreement.line2.part3')}
                            </span>
                        </div>
                    </MediaQuery>
                    <MediaQuery maxWidth={600}>
                        <div className="app_screen__checkout__controls__checkbox app_screen__checkout__controls__checkbox--mobile">
                            <FormCheckbox name="agreedToTerms" value={agreedToTerms} onChange={this.onAgreementChange} />
                            <span>{t('checkout.agreement.line1')}</span>
                            <br />
                            <br />
                            <span>
                                {t('checkout.agreement.line2.part1')}
                                &nbsp;
                                <NavLink to={TERMS_AND_CONDITIONS_OF_USE_ROUTE} target="_blank">{t('checkout.agreement.line2.termsAndConditions')}</NavLink>
                                &nbsp;
                                {t('checkout.agreement.line2.part2')}
                                &nbsp;
                                <NavLink to={PRIVACY_POLICY_ROUTE} target="_blank">{t('checkout.agreement.line2.privacyPolicy')}</NavLink>
                                &nbsp;
                                {t('checkout.agreement.line2.part3')}
                            </span>
                        </div>
                    </MediaQuery>
                    <div className="app_screen__checkout__controls__button">
                        <Button
                            text={t('checkout.finishBtn')}
                            styles="button--dark-blue button--bigger"
                            disabled={!agreedToTerms}
                            callback={this.onFinishRevision}
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {
            t,
        } = this.props;
        const {
            isFetching,
            showErrorModal,
            showStockErrorModal,
            showCourierInfoModal,
            currentStep,
        } = this.state;

        return (
            <div className="app_screen">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="app_screen__checkout">
                    <div className="page-title--step-header">
                        {this.renderHeader()}
                    </div>
                    {currentStep === 0 && (this.renderShippingScreen())}
                    {currentStep === 1 && (this.renderPaymentScreen())}
                    {currentStep === 2 && this.renderConfirmationScreen()}
                    {currentStep < 2 && (
                        <Button
                            styles="button--dark-blue"
                            text="Continuar"
                            callback={this.onNextStage}
                        />
                    )}
                </div>

                <Modal className="modal-custom" show={showErrorModal} onHide={this.onCloseErrorModal} centered>
                    <ModalHeader className="success-modal-header" closeButton>
                        <ModalTitle>
                            <div className="success-modal">
                                <h2 className="my-2 red-text">{t('checkout.error')}</h2>
                                <h5>{t('checkout.requestErrorMessage')}</h5>
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                </Modal>
                <Modal className="modal-custom" show={showStockErrorModal} onHide={this.onCloseStockErrorModal} centered>
                    <ModalHeader className="success-modal-header" closeButton>
                        <ModalTitle>
                            <div className="success-modal">
                                <h2 className="my-2 red-text">{t('checkout.error')}</h2>
                                <h5>Um ou mais produtos do seu carrinho encontram-se sem stock.</h5>
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                </Modal>
                <Modal className="modal-custom" show={showCourierInfoModal} onHide={() => { }} centered size="lg">
                    <ModalHeader>
                        <ModalTitle>
                            <div className="success-modal">
                                <h2 className="my-2">Entrega por Estafeta</h2>
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody className="courier-modal-body">
                        <h5>Atenção: os custos deste método de envio devem ser pagos no ato da entrega do(s) produto(s) e não serão apresentados nesta janela de checkout.</h5>
                        <h5>Consulte a imagem que se segue para informação relativa aos valores: </h5>
                        <img className="courier-modal-body__image" src={courierCosts} alt="courier costs" />
                    </ModalBody>
                    <ModalFooter>
                        <Button styles="button--bigger button--blue" callback={this.onCloseCourierInfoModal} text="Tomei Conhecimento" />
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default withTranslationContext(withAuthenticationContext(withCartContext(withCookies(CheckoutScreen))));

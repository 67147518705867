import React, { Component } from 'react';
import axios from 'axios';
import { get } from 'lodash';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import Modal from 'react-bootstrap/Modal';
import { RouteComponentProps } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { getFormErrors, IFormError, VALIDATIONS } from '../../utils/validation';
import FormTextField from '../elements/FormTextField';
import Button from '../elements/Button';
import { userResetToken } from '../../services/users';
import greenTickIcon from '../../assets/images/green_tick.svg';
import { MatchParams, RouteState } from '../../constants/misc';

interface OwnProps extends RouteComponentProps<MatchParams, {}, RouteState>, TranslationContext {}

interface OwnState {
    email: string;
    formErrors: any;
    isFetching: boolean;
    isRedirect: boolean;
    showErrorModal: boolean;
    showSuccessModal: boolean;
    errorLabel: string;
}

const initialState: OwnState = {
    email: '',
    formErrors: null,
    isFetching: false,
    isRedirect: false,
    showErrorModal: false,
    showSuccessModal: false,
    errorLabel: '',
};

class ResetActivationTokenScreen extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    componentDidMount() {
        const { location } = this.props;
        if (location && location.state) {
            const locationState = { ...location.state as { isRedirect: boolean } };
            this.setState({
                ...locationState,
            });
        }
    }

    onEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
        const { currentTarget } = e;
        const { value } = currentTarget;
        this.setState({
            email: value,
        });
    };
 
    onCloseErrorModal = () => {
        this.setState({
            showErrorModal: false,
        });
    };
 
    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { email } = this.state;

        if (this.validateFields()) {
            const fields = {
                email: String(email).trim(),
            };

            this.sendRecoverRequest(fields);
        }
    }

    onCloseSuccessModal = () => {
        this.setState({
            showSuccessModal: false,
        });
    };
    
    sendRecoverRequest = (fields: object) => {
        this.setState({ isFetching: true });
        const { t } = this.props;

        axios.get(userResetToken(fields))
            .then(() => {
                this.setState({
                    isFetching: false,
                    showSuccessModal: true,
                    showErrorModal: false,
                });
            })
            .catch(error => {
                this.setState({
                    errorLabel: t('home.contact.error'),
                    isFetching: false,
                    showErrorModal: true,
                    showSuccessModal: false,
                });
            });
    }

    validateFields = () => {
        let errors: IFormError | null = getFormErrors(this.state, VALIDATIONS.RECOVER_PASSWORD);

        if (errors && Object.keys(errors).length === 0) errors = null;

        this.setState({ formErrors: errors ? { fields: errors } : errors });
        return errors === null;
    }

    renderSuccessModal() {
        const { email } = this.state;
        const { t } = this.props;
        return (
            <Modal className="modal-custom" show onHide={this.onCloseSuccessModal} centered>
                <ModalHeader className="success-modal-header" closeButton>
                    <ModalTitle>
                        <div className="success-modal">
                            <img className="green-tick" src={greenTickIcon} alt="" />
                            <h5 className="mt-2">{t('resetToken.successMessage', { email })}</h5>
                        </div>
                    </ModalTitle>
                </ModalHeader>
            </Modal>
        );
    }

    renderFailureModal() {
        const { errorLabel } = this.state;
        const { t } = this.props;
        return (
            <Modal className="modal-custom" show onHide={this.onCloseErrorModal} centered>
                <ModalHeader className="success-modal-header" closeButton>
                    <ModalTitle>
                        <div className="success-modal">
                            <h2 className="my-2 red-text">{errorLabel}</h2>
                            <h5>{t('resetToken.errorMessage')}</h5>
                        </div>
                    </ModalTitle>
                </ModalHeader>
            </Modal>
        );
    }

    render() {
        const { t } = this.props;
        const {
            email,
            formErrors,
            showErrorModal,
            showSuccessModal,
            isRedirect,
        } = this.state;

        return (
            <div className="container-fluid app_screen">
                {showSuccessModal && this.renderSuccessModal()}
                {showErrorModal && this.renderFailureModal()}
                <div className="app_screen__centered margin-top-bar">
                    <div className="page-title with-border">
                        {t('resetToken.title')}
                    </div>
                    <div className="information-text">
                        {t(`${isRedirect ? 'resetToken.redirectText' : 'resetToken.informationText'}`)}
                    </div>
                    <form className="login-form-content recover-password" onSubmit={this.onSubmit}>
                        <FormTextField
                            name="email"
                            value={email}
                            label={t('resetToken.labels.email')}
                            placeholder={t('resetToken.labels.email')}
                            onChange={this.onEmailChange}
                            errors={get(formErrors, 'fields.email', null)}
                        />
                        <Button
                            type="submit"
                            text={t('resetToken.sendBtn')}
                            styles="button--dark-blue"
                        />
                    </form>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(ResetActivationTokenScreen);

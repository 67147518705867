/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';

import iconOpen from '../../assets/images/ic-down-small.svg';

interface OwnProps {
    title?: string;
    startsOpen?: boolean;
}

interface OwnState {
    isExpanded: boolean;
}

const initialState = {
    isExpanded: false,
};

class FilterField extends React.Component<OwnProps, OwnState> {
    state = initialState;

    componentDidMount() {
        const { startsOpen } = this.props;

        if (startsOpen) {
            this.setState({
                isExpanded: true,
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const { startsOpen } = this.props;
        if (prevProps.startsOpen !== startsOpen && startsOpen) {
            this.setState({
                isExpanded: true,
            });
        }
    }

    toggleShowHide = () => {
        const { isExpanded } = this.state;
        this.setState({
            isExpanded: !isExpanded,
        });
    }

    forceClose = () => {
        this.setState({
            isExpanded: false,
        });
    }

    render() {
        const {
            title,
            children,
        } = this.props;

        const { isExpanded } = this.state;

        const buttonClass = isExpanded ? 'filter-field__header__button--expanded' : 'filter-field__header__button';
        const titleClass = isExpanded ? 'filter-field__header__title--expanded' : 'filter-field__header__title';
        const contentClass = isExpanded ? 'filter-field__content--expanded' : 'filter-field__content';

        return (
            <div className="filter-field">
                <div data-testid="filter-field-header" className="filter-field__header" onClick={() => { this.toggleShowHide(); }}>
                    {title && (<p className={titleClass}>{title}</p>)}
                    <div className={buttonClass}>
                        <img src={iconOpen} alt="" />
                    </div>
                </div>
                <div data-testid="filter-field-content" className={contentClass}>
                    {children}
                </div>
            </div>
        );
    }
}

export default FilterField;

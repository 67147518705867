/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const categoriesAdminURL = (): string => {
    return `${API_URL}/categories`;
};

export const categoryAdminURL = (id: number | string): string => {
    return `${API_URL}/categories/${id}`;
};

export const categoryRandomURL = (): string => {
    return `${API_URL}/categories/public/highlight`;
};

export const subcategoriesAdminURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/categories/subcategories${queryString}`;
};

export const subcategoryAdminURL = (id?: number | string): string => {
    if (id) {
        return `${API_URL}/categories/subcategory/${id}`;
    }

    return `${API_URL}/categories/subcategory`;
};

export const categoriesPublicURL = (): string => {
    return `${API_URL}/categories/public`;
};

export const subcategoriesPublicURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/categories/subcategories/public${queryString}`;
};

export const customCategoriesURL = (id : number | string): string => {
    return `${API_URL}/storecategories/${id}`;
};

import React, { Component } from 'react';
import ErrorMessage from './ErrorMessage';
import { SelectOption } from '../../constants/misc';

interface OwnProps {
    name: string;
    value: string | number | null;
    onChange?: Function;
    label?: string;
    containerStyles?: string;
    placeholder?: string;
    customClass?: string;
    errors?: any;
    disabled?: boolean;
    required?: boolean;
    options: Array<SelectOption>;
}

class FormSelectField extends Component<OwnProps> {
    renderErrors = () => {
        const { name, errors } = this.props;
        if (errors) {
            return (
                <ErrorMessage errors={errors} field={name} />
            );
        }
        return <React.Fragment />;
    };

    render() {
        const {
            name,
            value,
            onChange,
            label,
            disabled,
            options,
            placeholder,
            errors,
            required,
            customClass,
        } = this.props;

        const className = errors ? 'is-invalid' : `${customClass}`;
        
        return (
            <div className="form-select-field">
                {label && (
                    <label htmlFor={name}>{label}</label>
                )}
                <select
                    name={name}
                    value={value || ''}
                    placeholder={placeholder}
                    className={`form-control ${className}`}
                    onChange={onChange ? e => onChange(e) : () => {}}
                    disabled={disabled || false}
                    required={required || false}
                >
                    {Object.keys(options).map(o => {
                        const opt = options[Number(o)];

                        return (
                            <option key={opt.value} value={opt.value} disabled={opt.disabled}> {opt.label} </option>
                        );
                    })}
                </select>
                <p className="error-field">{this.renderErrors()}</p>
            </div>
        );
    }
}

export default FormSelectField;

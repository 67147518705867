/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export const VERSION = process.env.REACT_APP_VERSION;
export const API_URL = process.env.REACT_APP_API_URL;
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
export const MSG_EMAIL = 'site@leiriamarket.pt';
export const GTM_KEY = process.env.REACT_APP_GTM_KEY;

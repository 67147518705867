/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import axios, { AxiosResponse } from 'axios';
import { Modal, Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import { get } from 'lodash';

import withPaging, { WithPagingProps } from '../hocs/withPaging';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { GeneralContext, withGeneralContext } from '../controllers/general/GeneralContext';
import { UserAddress } from '../../constants/types';
import Loader from '../elements/Loader';
import { ICON, SvgIcon } from '../elements/SvgIcon';
import { userAddressListURL, userAddressURL } from '../../services/users';
import TablePaging from '../elements/TablePaging';
import FormTextField from '../elements/FormTextField';
import displayConfirm from '../elements/displayConfirm';
import Button from '../elements/Button';
import { globalButtonLabels } from '../../constants/misc';
import {
    getFormErrors, IFormError, VALIDATIONS,
} from '../../utils/validation';

export interface OwnProps extends GeneralContext, TranslationContext, WithPagingProps {}

export interface OwnState {
    isFetching: boolean;
    addressList: Array<UserAddress>;
    _limit: number;
    totalResults: number;
    showingAddressDetails: boolean;
    selectedAddress: UserAddress;
    formErrors: any;
}

const initialState = {
    isFetching: false,
    addressList: [],
    _limit: 15,
    totalResults: 0,
    showingAddressDetails: false,
    selectedAddress: {
        id: -1,
        address: '',
        city: '',
        name: '',
        phone: '',
        postalCode: '',
    },
    formErrors: null,
};

class AddressManagement extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    componentDidMount() {
        this.prepare();
    }

    onAddressClick = (addr: UserAddress): void => {
        this.setState({
            selectedAddress: addr,
            showingAddressDetails: true,
        });
    }

    onHideAddressModal = ():void => {
        this.setState({
            selectedAddress: initialState.selectedAddress,
            showingAddressDetails: false,
        });
    }

    onAddressCreate = (): void => {
        this.setState({
            showingAddressDetails: true,
        });
    }

    onAddressDelete = (addr: UserAddress) => {
        const { t } = this.props;

        displayConfirm({
            acceptButtonText: t('global.buttons.ok'),
            onAccept: () => this.removeAddress(addr),
            onReject: () => {},
            rejectButtonText: t('global.buttons.cancel'),
            message: t('admin.users.removeAddressConfirmation'),
        });
    }

    onAddressSave = (): void => {
        const { selectedAddress } = this.state;
        const {
            address,
            city,
            name,
            phone,
            postalCode,
        } = selectedAddress;

        const addressData = {
            address,
            city,
            name,
            phone,
            postalCode,
        };

        let request: Promise<AxiosResponse>;

        if (this.validateFields()) {
            const closeAndPrepare = () => {
                this.onHideAddressModal();
                this.prepare();
            };

            if (selectedAddress.id === -1) {
                request = axios.post(userAddressListURL(), addressData);
            } else {
                request = axios.put(userAddressURL(selectedAddress.id), addressData);
            }

            request.then(closeAndPrepare);
        }
    }

    prepare = async () => {
        const { currentPage, onPagingChange } = this.props;
        const { isFetching, _limit } = this.state;

        if (isFetching) return;

        this.setState({
            isFetching: true,
        });

        try {
            const { data, headers } = await axios.get(userAddressListURL({
                pageSize: _limit,
                page: currentPage - 1,
            }));

            const newTotalResults: number = parseInt(headers['x-total-count']);

            if (data && data.length === 0 && currentPage !== 1) {
                this.setState({ isFetching: false }, () => {
                    onPagingChange(currentPage - 1);
                    this.prepare();
                });
            } else {
                const addrList: Array<UserAddress> = data.map((addr: {id: number, address: UserAddress}) => {
                    return {
                        id: addr.id,
                        address: addr.address.address,
                        city: addr.address.city,
                        name: addr.address.name,
                        phone: addr.address.phone,
                        postalCode: addr.address.postalCode,
                    };
                });
                this.setState({
                    isFetching: false,
                    addressList: [...addrList],
                    totalResults: newTotalResults,
                });
            }
        } catch (e) {
            this.setState({
                isFetching: false,
            });
        }
    }

    addressToString = (addr: UserAddress): string => {
        const {
            name,
            address,
            city,
            postalCode,
        } = addr;

        return `${name}, ${address}, ${postalCode} ${city}`;
    }

    onAddressInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
        const { selectedAddress } = this.state;
        this.setState({
            selectedAddress: {
                ...selectedAddress,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    }

    validateFields = (): boolean => {
        const { selectedAddress } = this.state;
        const errors: IFormError | null = getFormErrors(selectedAddress, VALIDATIONS.USER_ADDRESS);

        this.setState({
            formErrors: errors ? { fields: errors } : errors,
        });

        return errors === null;
    }

    removeAddress = (addr: UserAddress):void => {
        this.setState({
            isFetching: true,
        });

        axios.delete(userAddressURL(addr.id)).then(() => {
            this.setState({
                isFetching: false,
            });
            this.prepare();
        });
    }

    render() {
        const {
            t,
            onPagingChange,
            currentPage,
        } = this.props;

        const {
            isFetching,
            addressList,
            _limit,
            totalResults,
            showingAddressDetails,
            selectedAddress,
            formErrors,
        } = this.state;

        const hasData = addressList && addressList.length > 0;

        return (
            <div className="app-tabs__tab-content container customer-admin">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="row justify-content-end buttons-container">
                    <div className="button-wrapper">
                        <Button
                            styles="button--dark-blue button--small"
                            text={t(globalButtonLabels.add)}
                            callback={this.onAddressCreate}
                        />
                    </div>
                </div>
                <Table responsive="sm">
                    <thead>
                        <tr>
                            <th>{t('customerAdmin.tabs.addresses')}</th>
                            <th />
                        </tr>
                    </thead>

                    {hasData ? (
                        <tbody>
                            {Object.keys(addressList).map(k => {
                                const addr: UserAddress = addressList[Number(k)];

                                return (
                                    <tr key={addr.id} className="clickable">
                                        <td onClick={() => this.onAddressClick(addr)}>{this.addressToString(addr)}</td>
                                        <td className="d-flex w-100 justify-content-end trash-icon-cell">
                                            <SvgIcon icon={ICON.TRASH} callback={() => this.onAddressDelete(addr)} />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan={4}>
                                    {t('global.noData')}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
                {hasData && (
                    <TablePaging
                        currentPage={currentPage}
                        limit={_limit}
                        totalResults={totalResults}
                        onStartChange={onPagingChange}
                    />
                )}
                <Modal show={showingAddressDetails} onHide={this.onHideAddressModal} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{t('customerAdmin.tabs.addresses')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormTextField
                                    label={t('storeAdmin.profile.labels.name')}
                                    name="name"
                                    value={selectedAddress.name}
                                    placeholder={t('storeAdmin.profile.labels.name')}
                                    maxLength={180}
                                    onChange={this.onAddressInputChange}
                                    errors={get(formErrors, 'fields.name', null)}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <FormTextField
                                    label={t('storeAdmin.profile.labels.contact')}
                                    name="phone"
                                    value={selectedAddress.phone}
                                    placeholder={t('storeAdmin.profile.labels.contact')}
                                    maxLength={180}
                                    onChange={this.onAddressInputChange}
                                    errors={get(formErrors, 'fields.phone', null)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                                <FormTextField
                                    name="address"
                                    label={t('storeAdmin.profile.labels.address')}
                                    value={selectedAddress.address}
                                    placeholder={t('storeAdmin.profile.labels.address')}
                                    maxLength={180}
                                    onChange={this.onAddressInputChange}
                                    errors={get(formErrors, 'fields.address', null)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormTextField
                                    name="postalCode"
                                    value={selectedAddress.postalCode}
                                    label={t('storeAdmin.profile.labels.postalCode')}
                                    placeholder={t('storeAdmin.profile.labels.postalCode')}
                                    maxLength={180}
                                    onChange={this.onAddressInputChange}
                                    errors={get(formErrors, 'fields.postalCode', null)}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <FormTextField
                                    name="city"
                                    value={selectedAddress.city}
                                    label={t('storeAdmin.profile.labels.city')}
                                    placeholder={t('storeAdmin.profile.labels.city')}
                                    maxLength={180}
                                    onChange={this.onAddressInputChange}
                                    errors={get(formErrors, 'fields.city', null)}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            styles="button--blue button--small"
                            text={t(globalButtonLabels.accept)}
                            callback={this.onAddressSave}
                        />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default withPaging(withTranslationContext(withGeneralContext(AddressManagement)));

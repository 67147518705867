/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import Select from 'react-select';
import ErrorMessage from './ErrorMessage';
import { SelectOption } from '../../constants/misc';

interface OwnProps {
    name: string;
    value: any;
    onChange?: Function;
    label?: string;
    containerStyles?: string;
    placeholder?: string;
    fieldStyles?: string;
    errors?: any;
    disabled?: boolean;
    options: Array<SelectOption>;
}

class FormMultipleSelectField extends Component<OwnProps> {
    renderErrors = () => {
        const { name, errors } = this.props;
        if (errors) {
            return (
                <ErrorMessage errors={errors} field={name} />
            );
        }
        return <React.Fragment />;
    };

    render() {
        const {
            name,
            value,
            onChange,
            label,
            disabled,
            options,
            placeholder,
        } = this.props;

        return (
            <div className="form-group">
                {label && (
                    <label htmlFor={name}>{label}</label>
                )}
                <Select
                    isMulti
                    isDisabled={disabled}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange ? e => onChange(name, e) : () => {}}
                    options={options}
                />
                <p className="error-field">{this.renderErrors()}</p>
            </div>
        );
    }
}

export default FormMultipleSelectField;

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

export enum GeneralAction {
    SET_LAST_SEEN_PRODUCTS = 'SET_LAST_SEEN_PRODUCTS',
}

export interface SetLastSeenProductsAction {
    type: typeof GeneralAction.SET_LAST_SEEN_PRODUCTS,
    payload: Array<number>,
}

export type GeneralActionTypes = SetLastSeenProductsAction;

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import ErrorMessage from './ErrorMessage';
import { FormTextFieldTypes } from '../../constants/types';

interface OwnProps {
    name: string;
    value: string | number | null;
    onChange?: Function;
    label?: string;
    containerStyles?: string;
    placeholder?: string;
    fieldStyles?: string;
    errors?: any;
    disabled?: boolean;
    maxLength?: number;
    type?: FormTextFieldTypes;
    required?: boolean;
    autoComplete?: string;
    tooltip? : React.ReactNode;
}

class FormTextField extends Component<OwnProps> {
    renderErrors = () => {
        const { name, errors } = this.props;
        if (errors) {
            return (
                <ErrorMessage errors={errors} field={name} />
            );
        }
        return <React.Fragment />;
    };

    render() {
        const {
            name,
            value,
            onChange,
            label,
            disabled,
            type,
            placeholder,
            errors,
            maxLength,
            required,
            autoComplete,
            tooltip,
            fieldStyles,
            containerStyles,
        } = this.props;

        const className = errors ? 'is-invalid' : '';

        return (
            <div className={`form-group ${containerStyles}`}>
                {label && (
                    <label htmlFor={name}>{label}{tooltip}</label>
                )}
                <input
                    name={name}
                    type={type || 'text'}
                    value={value || ''}
                    placeholder={placeholder}
                    className={`form-control ${fieldStyles} ${className}`}
                    onChange={onChange ? e => onChange(e) : () => {}}
                    maxLength={maxLength || 80}
                    disabled={disabled || false}
                    required={required || false}
                    autoComplete={autoComplete || ''}
                />
                <p className="error-field">{this.renderErrors()}</p>
            </div>
        );
    }
}

export default FormTextField;

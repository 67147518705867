import React, { Component } from 'react';
import ErrorMessage from './ErrorMessage';

interface OwnProps {
    name: string;
    value: boolean;
    onChange?: Function;
    label?: string;
    containerStyles?: string;
    placeholder?: string;
    fieldStyles?: string;
    errors?: any;
    disabled?: boolean;
}

class FormCheckbox extends Component<OwnProps> {
    renderErrors = () => {
        const { name, errors } = this.props;
        if (errors) {
            return (
                <ErrorMessage errors={errors} field={name} />
            );
        }
        return <React.Fragment />;
    };

    render() {
        const {
            name,
            value,
            onChange,
            label,
            disabled,
            placeholder,
            errors,
        } = this.props;

        return (
            <div className="form-check">
                <input
                    name={name}
                    checked={value}
                    placeholder={placeholder}
                    type="checkbox"
                    className={errors ? 'form-check-input is-invalid' : 'form-check-input'}
                    onChange={onChange ? e => onChange(e) : () => {}}
                    disabled={disabled || false}
                    data-testid={`checkbox-${name}`}
                />
                {label && (
                    <label className="form-check-label" htmlFor={name}>{label}</label>
                )}
                <p className="error-field">{this.renderErrors()}</p>
            </div>
        );
    }
}

export default FormCheckbox;

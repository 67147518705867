/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, {
    createContext, ComponentType, FC,
} from 'react';
import { ShoppingCart } from '../../../constants/types';
import { ConnectedCartController } from './CartController';

export interface CartContext {
    cartId: string | null;
    shoppingCart: ShoppingCart | null;
    generateCartId(): Promise<string>;
    fetchCart(callback?: () => void): void;
    updateCart(shoppingCart: ShoppingCart): void;
    updateProduct(lineId: string, product: number, quantity: number): void;
    addToCart(product: number, quantity: number): void;
    removeProduct(lineId: string): void;
    resetCart(): void;
}

export const cartContextDefaultValue = {
    cartId: null,
    shoppingCart: null,
    generateCartId: async () : Promise<string> => '',
    fetchCart: () => {},
    updateCart: () => {},
    updateProduct: () => {},
    removeProduct: () => {},
    addToCart: () => {},
    resetCart: () => {},
};

const CartContext = createContext<CartContext | null>(cartContextDefaultValue);

export const CartContextProvider = CartContext.Provider;
export const CartContextConsumer = CartContext.Consumer;

export const withCartContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof CartContext>> => props => (
    <ConnectedCartController>
        <CartContextConsumer>
            {ctx => <Component {...props as P} {...ctx} />}
        </CartContextConsumer>
    </ConnectedCartController>
    );

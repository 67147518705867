/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */
import { API_URL } from '../settings';
import { objectToParams } from '../utils/misc';

export const shoppingCartURL = (params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/shoppingcart${queryString}`;
};

export const updateCartProductURL = (id: string, params: object = {}): string => {
    const queryString = objectToParams(params);
    return `${API_URL}/shoppingcart/${id}${queryString}`;
};

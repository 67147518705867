/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */
import axios from 'axios';
import moment from 'moment';
import { ibanCodeLengths } from '../constants/misc';
import {
    Product, ShoppingCart, ShoppingLine, StoreCart, StoreTag,
} from '../constants/types';

export const objectToParams = (obj: any, useQ = true) => {
    let params = '';

    if (obj !== undefined && obj !== null) {
        Object.keys(obj).forEach(key => {
            if (obj[key] !== null) {
                if (typeof obj[key] === 'string' && obj[key].length < 1) return;
                if (params.length > 0) {
                    params = `${params}&${key}=${encodeURIComponent(obj[key])}`;
                } else {
                    params = `${key}=${encodeURIComponent(obj[key])}`;
                }
            }
        });
    }

    if (useQ) {
        if (params && params.length > 0) {
            params = `?${params}`;
        }
    }

    return params;
};

export const generateId = () => {
    return Math.random().toString(36).slice(2);
};

export const generateInt = (min: number, max: number): number => {
    const minVal = Math.ceil(min);
    const maxVal = Math.floor(max);
    return Math.floor(Math.random() * (maxVal - minVal + 1)) + minVal;
};

export const numberToCurrency = (number: number | string) => {
    try {
        const formatter = new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
        });
        return formatter.format(Number(number));
    } catch (e) {
        return number;
    }
};

export const asyncForEach = async (array: Array<any>, callback: Function) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
};

export const mod97 = (string: string) => {
    let checksum = string.slice(0, 2);
    for (let offset = 2; offset < string.length; offset += 7) {
        const fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = (parseInt(fragment) % 97).toString();
    }
    return checksum;
};

export const validateIBAN = (input: string) => {
    const iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, '');
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

    if (!code || iban.length !== ibanCodeLengths[code[1]]) {
        return false;
    }

    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, letter => {
        return (letter.charCodeAt(0) - 55).toString();
    });

    return mod97(digits) === '1';
};

export const validatePtNif = (nif: string) => {
    let checkDigit: number;
    const characterReg = '[0-9]{9}';
    if (nif.match(characterReg)) {
        checkDigit = Number(nif.charAt(0)) * 9;
        for (let i = 2; i <= 8; i++) {
            checkDigit += Number(nif.charAt(i - 1)) * (10 - i);
        }
        checkDigit = 11 - (checkDigit % 11);
        if (checkDigit >= 10) {
            checkDigit = 0;
        }

        return String(checkDigit) === String(nif.charAt(8));
    }

    return false;
};

export const phoneNumberWithMask = (phone: string): string => {
    if (phone.length !== 9) return phone;

    const firstPart = phone.substr(0, 3);
    const middlePart = phone.substr(3, 3);
    const lastPart = phone.substr(6, 3);
    return `${firstPart} ${middlePart} ${lastPart}`;
};

export const decimalAdjustRound = (value: number, exp = -2) => {
    value = +value;
    exp = +exp;

    if (isNaN(value) || (exp % 1 !== 0)) {
        return NaN;
    }

    const valueArray: string[] = value.toString().split('e');
    value = Math.round(+(`${valueArray[0]}e${valueArray[1] ? (+valueArray[1] - exp) : -exp}`));

    const newValueArray: string[] = value.toString().split('e');
    return +(`${newValueArray[0]}e${newValueArray[1] ? (+newValueArray[1] + exp) : exp}`);
};

const emulateScrollToElement = (elementOffset: number, duration: number) => { // suport for IE and Edge
    const element = document.scrollingElement || document.documentElement;
    const start = element.scrollTop;
    const change = elementOffset || 0 - start;
    const startDate = +new Date();

    const easeInOutQuad = (t:number, b:number, c:number, d:number) => {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        element.scrollTop = easeInOutQuad(currentTime, start, change, duration);
        if (currentTime < duration) {
            requestAnimationFrame(animateScroll);
        } else {
            element.scrollTop = elementOffset || 0;
        }
    };
    animateScroll();
};

export const scrollToTop = (): void => {
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style; // suport for IE and Edge

    supportsNativeSmoothScroll ? window.scrollTo({
        behavior: 'smooth',
        top: 0,
    }) : emulateScrollToElement(0, 400);
};

export const scrollToElement = (hash:string):void => {
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style; // suport for IE and Edge

    if (hash && hash !== '#home_screen') {
        const element = document.getElementById(hash.substr(1));
        const topBarElement = document.getElementById('top_bar');

        if (element && topBarElement) {
            let yOffset = -Number(topBarElement.offsetHeight);

            if (hash === '#categories' || hash === '#stores') {
                yOffset = -Number(topBarElement.offsetHeight) - 20;
            }

            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            supportsNativeSmoothScroll ? window.scrollTo({
                top: y,
                behavior: 'smooth',
            }) : emulateScrollToElement(y, 400);
        } else if (element) {
            supportsNativeSmoothScroll ? element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            }) : emulateScrollToElement(element.offsetTop, 400);
        }
    } else if (hash && (hash === '#home_screen')) {
        supportsNativeSmoothScroll ? window.scrollTo({
            behavior: 'smooth',
            top: 0,
        }) : emulateScrollToElement(0, 400);
    }
};

export const truncateString = (fullString: string, length: number): string => {
    if (!fullString || fullString.trim() === '') return '';

    if (fullString.length < length) return fullString;

    const truncatedString = String(fullString).substring(0, length);
    return `${truncatedString}...`;
};

export const tagsStringBuilder = (tags: Array<StoreTag>): string => {
    let tagsStr = '';
    let count = 0;
    Object.keys(tags).forEach(k => {
        const tag: StoreTag = tags[Number(k)];

        if (count < tags.length - 1) {
            tagsStr += `${tag.description}, `;
        } else {
            tagsStr += `${tag.description}`;
        }

        count++;
    });

    return tagsStr;
};

export const downloadXLS = (url: string): void => {
    axios({
        url,
        method: 'GET',
        responseType: 'blob',
        headers: {
            accept: 'application/octet-stream',
        },
    }).then(response => {
        if (response.headers['content-disposition']) {
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1].replaceAll('"', ''));
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }).catch(() => {});
};

export const countVouchers = (shoppingCart: ShoppingCart | null): number => {
    let lineCount = 0;
    if (shoppingCart?.storeCarts) {
        shoppingCart.storeCarts.forEach((cart: StoreCart) => {
            cart.shoppingLines.forEach(line => {
                if (line.isVoucher) lineCount++;
            });
        });
    }
    return lineCount;
};

export const constructVoucherBonusString = (line: ShoppingLine, customString?: string) => {
    const { voucherBonus, quantity, bonusPerVoucher } = line;
    const string = customString || 'Bónus oferta Viva Leiria (primeira compra):';

    if (voucherBonus && voucherBonus !== 0 && bonusPerVoucher) {
        const vouchersWithBonus = voucherBonus / bonusPerVoucher;
        return `${string} ${numberToCurrency(bonusPerVoucher)} x ${vouchersWithBonus > quantity ? quantity : vouchersWithBonus}`;
    }

    return '';
};

export const calculateDiscountPercentage = (product: Product) => {
    const { price, discountPrice } = product;
    
    if (discountPrice && price) {
        if (+price > +discountPrice) return Math.round((+price - +discountPrice) * 100 / +price);
    }
    return 0;
};

export const checkProductWithinPromotion = (product: Product): boolean => {
    if (product.inPromotion) {
        const { discountStart, discountEnd } = product;
        const startFormatted = moment.unix(Number(discountStart));
        const endFormatted = moment.unix(Number(discountEnd));

        return moment().isBetween(startFormatted, endFormatted, 'day', '[]');
    }
    return false;
};

export const formatToken = (token : string) : string => (token.charAt(token.length - 1) === '.' ? token.slice(0, -1) : token);

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import axios, { AxiosResponse } from 'axios';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { first } from 'lodash';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { shoppingOrdersDetailsURL, shoppingOrdersURL, singleShoppingOrderURL } from '../../../services/shopping';
import {
    DeliveryMethods, DeliveryStoreOrder, Order, ShoppingOrderLine, ShoppingOrderState,
} from '../../../constants/types';
import withPaging, { WithPagingProps } from '../../hocs/withPaging';
import TablePaging from '../../elements/TablePaging';
import Loader from '../../elements/Loader';
import { numberToCurrency } from '../../../utils/misc';
import { STORE_VIEW_ROUTE } from '../../../constants/routes';
import { MatchParams, PaymentMethods, RouteState } from '../../../constants/misc';
import { GeneralContext, withGeneralContext } from '../../controllers/general/GeneralContext';

interface OwnProps extends RouteComponentProps<MatchParams, Record<string, unknown>, RouteState>, TranslationContext, GeneralContext, WithPagingProps {}

interface OwnState {
    isFetching: boolean;
    orders: Array<Order>;
    _limit: number;
    totalResults: number;
    orderDetails: Array<DeliveryStoreOrder>;
    showingOrderDetails: boolean;
    selectedOrder: Order | null;
    orderDeliveryTaxes: number;
}

const initialState: OwnState = {
    isFetching: false,
    orders: [],
    _limit: 15,
    totalResults: 0,
    orderDetails: [],
    showingOrderDetails: false,
    selectedOrder: null,
    orderDeliveryTaxes: 0,
};

class CustomerOrdersList extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    componentDidMount(): void {
        this.prepare().then(() => {
            const { match } = this.props;
            const { params } = match;
            const { id } = params;

            if (id) {
                this.loadOrderModalFromURL(id);
            }
        });
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const { currentPage } = this.props;
        const { currentPage: oldCurrentPage } = prevProps;

        const hasPagingChanged: boolean = currentPage !== oldCurrentPage;

        if (hasPagingChanged) {
            this.prepare();
        }
    }

    onCloseOrderModal = () => {
        this.setState({
            orderDetails: [],
            showingOrderDetails: false,
        });
    }

    onOrderClick = (order: Order) => {
        axios.get(shoppingOrdersDetailsURL(order.id, {})).then(response => {
            const { data } = response as AxiosResponse<Array<DeliveryStoreOrder>>;
            const orderDeliveryTaxes = data.reduce((total, deliveryStoreOrder) => total + deliveryStoreOrder.orderLines.reduce((subTotal, o) => subTotal + o.subTotal, 0), 0);

            this.setState({
                selectedOrder: { ...order },
                showingOrderDetails: true,
                orderDetails: [...data],
                orderDeliveryTaxes: order.paymentReferenceInfo.amount - orderDeliveryTaxes - order.paymentReferenceInfo.fee,
            });
        });
    }

    onProductClick = (storeId: any, productId: any) => {
        const { history } = this.props;
        history.push(`${STORE_VIEW_ROUTE}/${storeId}/product/${productId}`);
    }

    loadOrderModalFromURL = async (orderId: string) => {
        const { data } = await axios.get(singleShoppingOrderURL(orderId));
        if (data) {
            this.onOrderClick(data);
        }
    }

    stateLabel(orderState: ShoppingOrderState): string {
        const { t } = this.props;
        const { WAITING_PAYMENT, CANCELED_NO_PAYMENT } = ShoppingOrderState;
        switch (orderState) {
            case WAITING_PAYMENT: return t('customerAdmin.ordersList.orderState.NOT_PAID');
            case CANCELED_NO_PAYMENT: return t('customerAdmin.ordersList.orderState.CANCELED_NO_PAYMENT');
            default: return t('customerAdmin.ordersList.orderState.PAID');
        }
    }

    prepare = async () => {
        const { currentPage, onPagingChange } = this.props;
        const { isFetching, _limit } = this.state;

        if (isFetching) return;

        this.setState({
            isFetching: true,
        });

        try {
            const { data, headers } = await axios.get(shoppingOrdersURL({
                pageSize: _limit,
                page: currentPage - 1,
            }));

            const newTotalResults: number = parseInt(headers['x-total-count']);

            if (data && data.length === 0 && currentPage !== 1) {
                this.setState({ isFetching: false }, () => {
                    onPagingChange(currentPage - 1);
                    this.prepare();
                });
            } else {
                this.setState({
                    isFetching: false,
                    orders: [...data],
                    totalResults: newTotalResults,
                });
            }
        } catch (e) {
            this.setState({
                isFetching: false,
            });
        }
    }

    generateOrderStateString = (order: DeliveryStoreOrder): string => {
        const { t } = this.props;

        if (order.deliveryMethod === DeliveryMethods.THIRD_PARTY && order.orderState === ShoppingOrderState.READY_TO_PICKUP) {
            return t('customerAdmin.ordersList.details.orderState.READY_TO_PICKUP_CTT');
        }
        return t(`customerAdmin.ordersList.details.orderState.${order?.orderState}`);
    }

    renderLinePrice = (line: ShoppingOrderLine): JSX.Element => {
        const { t } = this.props;
        const { previousPrice, unitPrice } = line;

        if (previousPrice) {
            const discount = Math.round((previousPrice - unitPrice) * 100 / previousPrice);
            return (
                <>
                    <div><del>{numberToCurrency(previousPrice)}</del> &nbsp;{numberToCurrency(unitPrice)}</div>
                    <p>{t('customerAdmin.ordersList.details.discountString', { discount })}</p>
                </>
            );
        }
        return (
            <p>{numberToCurrency(unitPrice)}</p>
        );
    }

    render() {
        const { t, currentPage, onPagingChange } = this.props;
        const {
            orders,
            isFetching,
            _limit,
            totalResults,
            orderDetails,
            showingOrderDetails,
            selectedOrder,
            orderDeliveryTaxes,
        } = this.state;

        const hasData = orders && orders.length > 0;
        return (
            <div className="app-tabs__tab-content container customer-admin">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <Table responsive="sm">
                    <thead>
                        <tr>
                            <th>{t('customerAdmin.ordersList.headers.id')}</th>
                            <th>{t('customerAdmin.ordersList.headers.date')}</th>
                            <th>{t('customerAdmin.ordersList.headers.total')}</th>
                            <th>{t('customerAdmin.ordersList.headers.state')}</th>
                        </tr>
                    </thead>
                    {hasData ? (
                        <tbody>
                            {Object.keys(orders).map(k => {
                                const order = orders[Number(k)];

                                return (
                                    <tr key={order.id} onClick={() => this.onOrderClick(order)} className="clickable">
                                        <td>{order.id}</td>
                                        <td className="date-cell">{moment.unix(Number(order.createdDate)).local().format('DD/MM/YYYY - HH:mm:ss')}</td>
                                        <td className="total-cell">{order.paymentReferenceInfo ? numberToCurrency(order.paymentReferenceInfo.amount) : ' - ' }</td>
                                        <td>{ this.stateLabel(order.orderState) }</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan={4}>
                                    {t('global.noData')}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
                {hasData && (
                    <TablePaging
                        currentPage={currentPage}
                        limit={_limit}
                        totalResults={totalResults}
                        onStartChange={onPagingChange}
                    />
                )}
                <Modal show={showingOrderDetails} onHide={this.onCloseOrderModal} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{t('customerAdmin.ordersList.detailsTitle')}&nbsp;{selectedOrder && selectedOrder.id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {orderDetails && (
                            <div className="modal-body modal-custom order-modal">
                                <Row>
                                    <Col xs={12} lg={12} className="mb-2">
                                        <span>{t('customerAdmin.ordersList.labels.date')}</span>
                                        <span className="bold">{moment.unix(Number(first(orderDetails)?.createdDate)).local().format('DD/MM/YYYY - HH:mm:ss')}</span>
                                    </Col>
                                    {first(orderDetails)?.contact && first(orderDetails)?.contact.trim() !== '' && (
                                        <Col xs={12} lg={12} className="mb-2">
                                            <span>{t('customerAdmin.ordersList.labels.contact')}</span>
                                            <span className="bold">{first(orderDetails)?.contact}</span>
                                        </Col>
                                    )}
                                    {
                                        first(orderDetails)?.orderState === ShoppingOrderState.WAITING_PAYMENT && PaymentMethods.MBWAY !== selectedOrder?.paymentReferenceInfo.paymentMethod && (
                                            <Col xs={12} lg={12} className="mb-2">
                                                <span>{t('customerAdmin.ordersList.labels.entity')}</span>
                                                <span className="bold">{selectedOrder && selectedOrder.paymentReferenceInfo ? selectedOrder.paymentReferenceInfo.entity : ' - '}</span>
                                            </Col>

                                        )
                                    }
                                    {
                                        selectedOrder?.orderState === ShoppingOrderState.WAITING_PAYMENT && PaymentMethods.MBWAY !== selectedOrder?.paymentReferenceInfo.paymentMethod && (
                                            <Col xs={12} lg={12} className="mb-2">
                                                <span>{t('customerAdmin.ordersList.labels.reference')}</span>
                                                <span className="bold">{selectedOrder && selectedOrder.paymentReferenceInfo ? selectedOrder.paymentReferenceInfo.reference : ' - '}</span>
                                            </Col>
                                        )
                                    }
                                    <Col xs={12} lg={12} className="mb-2">
                                        <span>{t('customerAdmin.ordersList.labels.total')}</span>
                                        <span className="bold">{ selectedOrder && selectedOrder.paymentReferenceInfo ? numberToCurrency(selectedOrder.paymentReferenceInfo.amount || 0) : ' - '}</span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} lg={12} className="mt-4">
                                        {orderDetails.map((order, idx) => {
                                            const hasNext = order && orderDetails[idx + 1];
                                            return (
                                                <React.Fragment>
                                                    <Table responsive="sm" className="store-order-table">
                                                        <thead>
                                                            <tr>
                                                                <td className="order-number store-name" colSpan={1}>
                                                                    <span>{t('customerAdmin.ordersList.labels.orderNumber') + order.id}</span>
                                                                </td>
                                                                <td className="store-name" colSpan={2}>
                                                                    <span>{order.storeName}</span>
                                                                </td>
                                                                <td className="store-name" colSpan={1}>
                                                                    {this.generateOrderStateString(order)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>{t('customerAdmin.ordersList.labels.product')}</th>
                                                                <th>{t('customerAdmin.ordersList.labels.quantity')}</th>
                                                                <th>{t('customerAdmin.ordersList.labels.unitPrice')}</th>
                                                                <th>{t('customerAdmin.ordersList.labels.subtotal')}</th>
                                                            </tr>
                                                        </thead>
                                                        {order.orderLines && order.orderLines.length > 0 ? (
                                                            <tbody>
                                                                {Object.keys(order.orderLines).map(k => {
                                                                    const line = order.orderLines[Number(k)];

                                                                    return (
                                                                        <tr key={line.productId}>
                                                                            <td>{line.description}</td>
                                                                            <td>{line.quantity}</td>
                                                                            <td className="total-cell">{this.renderLinePrice(line)}</td>
                                                                            <td className="total-cell">{numberToCurrency(line.subTotal)}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                                <tr>
                                                                    <td>Total da Loja</td>
                                                                    <td />
                                                                    <td />
                                                                    <td className="total-cell">{numberToCurrency(order.orderLines.reduce((total, o) => total + o.subTotal, 0))}</td>
                                                                </tr>
                                                            </tbody>
                                                        ) : (
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan={4}>
                                                                        {t('global.noData')}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )}
                                                    </Table>
                                                    {hasNext && <hr />}
                                                </React.Fragment>
                                            );
                                        })
                                        }
                                        <Table responsive="sm" className="store-order-table">
                                            <thead>
                                                <tr>
                                                    <td className="store-name" colSpan={6}>
                                                        <span>{t('customerAdmin.ordersList.details.labels.delivery')}</span>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Math.abs(orderDeliveryTaxes).toFixed(2) !== '0.00' && (
                                                    <tr>
                                                        <td>{t('customerAdmin.ordersList.details.labels.deliveryTaxes')}</td>
                                                        <td className="tax-price">{numberToCurrency(orderDeliveryTaxes)}</td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td>{selectedOrder?.paymentReferenceInfo.paymentMethod === PaymentMethods.MBWAY ? t('customerAdmin.ordersList.details.labels.mbway') : t('customerAdmin.ordersList.details.labels.creditCard')}</td>
                                                    <td className="tax-price">{numberToCurrency(selectedOrder?.paymentReferenceInfo.fee || 0) }</td>
                                                </tr>
                                            </tbody>
                                        </Table>

                                    </Col>
                                </Row>
                            </div>
                        ) }
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default withRouter(withPaging(withTranslationContext(withGeneralContext(CustomerOrdersList))));

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { ComponentType, createContext, FC } from 'react';

import { ScrollMemory } from '../../../constants/scroll';
import { ConnectedScrollMemoryController } from './ScrollMemoryController';

/**
 * @typedef {Object} ScrollMemoryContext
 * @property {Object} scrollmemory
 * @property {Function} setScrollMemory
 * @property {Function} clearScrollMemory
 */
export interface ScrollMemoryContext {
    storesScrollMemory: ScrollMemory | null;
    productsScrollMemory: ScrollMemory | null;
    setScrollMemoryProducts(coordinate: number, page: number, location: string): void;
    clearScrollMemoryProducts(): void;
    setScrollMemoryStores(coordinate: number, page: number, location: string): void;
    clearScrollMemoryStores(): void;
}

export const scrollMemoryContextDefaultValue: ScrollMemoryContext = {
    storesScrollMemory: null,
    productsScrollMemory: null,
    setScrollMemoryProducts: () => {},
    clearScrollMemoryProducts: () => {},
    setScrollMemoryStores: () => {},
    clearScrollMemoryStores: () => {},
};

const ScrollMemoryContext = createContext<ScrollMemoryContext | null>(scrollMemoryContextDefaultValue);

export const ScrollMemoryContextProvider = ScrollMemoryContext.Provider;
export const ScrollMemoryContextConsumer = ScrollMemoryContext.Consumer;

/**
 * provides a context HOC that components can use to subscribe as consumers
 * @param Component
 * @returns {React.FC}
 */
export const withScrollMemoryContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof ScrollMemoryContext>> => props => (
    <ConnectedScrollMemoryController>
        <ScrollMemoryContextConsumer>
            {ctx => <Component {...props as P} {...ctx} />}
        </ScrollMemoryContextConsumer>
    </ConnectedScrollMemoryController>
    );

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, {
    createContext, ComponentType, FC,
} from 'react';
import { ConnectedGeneralController } from './GeneralController';

export interface GeneralContext {
    lastSeenProducts: Array<number>;
    addToLastSeenProducts(productId: number): void;
}

export const generalContextDefaultValue = {
    lastSeenProducts: [],
    addToLastSeenProducts: () => {},
};

const GeneralContext = createContext<GeneralContext | null>(generalContextDefaultValue);

export const GeneralContextProvider = GeneralContext.Provider;
export const GeneralContextConsumer = GeneralContext.Consumer;

export const withGeneralContext = <P extends object>(
    Component: ComponentType<P>,
): FC<Omit<P, keyof GeneralContext>> => props => (
    <ConnectedGeneralController>
        <GeneralContextConsumer>
            {ctx => <Component {...props as P} {...ctx} />}
        </GeneralContextConsumer>
    </ConnectedGeneralController>
    );

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import axios from 'axios';
import FilterResults from 'react-filter-search';
import moment from 'moment';
import { toast } from 'react-toastify';

import TagManager, { DataLayerArgs } from 'react-gtm-module';
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import MediaQuery from 'react-responsive';
import {
    MatchParams, RouteState, SelectOption, SHOW_STORES_SCREEN_FILTERS,
} from '../../../constants/misc';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import {
    Category,
    Store,
    StoreFiltersList,
    StoreFiltersSearch,
    StoreFiltersSelection,
} from '../../../constants/types';
import {
    ALL_STORES_ROUTE, INDEX_ROUTE, STORE_HISTORY_ROUTE, STORE_PRODUCTS_ROUTE,
} from '../../../constants/routes';
import Loader from '../../elements/Loader';
import { storesCategoriesURL, storesLocationsURL, storesPublicURL } from '../../../services/stores';
import { categoriesPublicURL } from '../../../services/categories';
import { resourcesURL } from '../../../services/resources';
import FilterField from '../../elements/FilterField';
import iconSearch from '../../../assets/images/icon-search.svg';
import { objectToParams, scrollToTop } from '../../../utils/misc';
import { getFormattedStoreURL } from '../../../utils/urls';

import clock from '../../../assets/images/ic-relogio.svg';
import pin from '../../../assets/images/ic-localizacao.svg';
import phone from '../../../assets/images/icon-phone.svg';
import email from '../../../assets/images/icon-email.svg';
import arrow from '../../../assets/images/icon-right-arrow_long.svg';

import Button from '../../elements/Button';
import ButtonImgIcon from '../../elements/ButtonImgIcon';
import SortFilterButton from '../../elements/SortFilterButton';
import iconClose from '../../../assets/images/icon-cross_circle.svg';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { ScrollMemoryContext, withScrollMemoryContext } from '../../controllers/scroll/ScrollMemoryContext';

interface OwnProps extends RouteComponentProps<MatchParams, {}, RouteState>, TranslationContext, ScrollMemoryContext {}

interface OwnState {
    stores: Array<Store>;
    categoryId: string | null;
    isFetching: boolean;
    categoriesOptions: Array<SelectOption>;
    _limit: number;
    totalResults: number;
    searchValue: string;
    page: number;
    availableFilters: StoreFiltersList;
    selectedFilters: StoreFiltersSelection;
    searchFilters: StoreFiltersSearch;
    showFilters: boolean;
}

const initialState: OwnState = {
    stores: [],
    categoryId: null,
    isFetching: false,
    categoriesOptions: [],
    _limit: 24,
    totalResults: 0,
    searchValue: '',
    page: 0,
    availableFilters: {
        category: [],
        location: [],
    },
    selectedFilters: {
        categoryId: null,
        location: '',
        online: '',
        time: null,
        q: null,
    },
    searchFilters: {
        category: '',
        location: '',
    },
    showFilters: false,
};

const baseFilterClass = 'stores-list-screen__filters-container__filter';

class StoresListScreen extends Component<OwnProps> {
    state = { ...initialState };

    private readonly categoryFilterFieldRef = React.createRef<FilterField>();

    private readonly locationFilterFieldRef = React.createRef<FilterField>();

    componentDidMount(): void {
        document.addEventListener('scroll', this.onScroll);
        this.parseFilters(() => {
            this.getStores();
            this.loadFilters();
            this.checkForScrollHistory();
        });
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) {
        const { location } = this.props;
        const prevSearch = prevProps.location.search;
        const currentSearch = location.search;

        if (prevSearch !== currentSearch) {
            this.setState({
                stores: [],
                page: 0,
            }, () => {
                this.parseFilters(() => {
                    this.getStores();
                    this.loadFilters();
                });
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll);
    }

    onStoreClick = (store: Store, sendToHistory = false) => {
        const { history, setScrollMemoryStores } = this.props;

        const gtmECommerceTag: DataLayerArgs = {
            dataLayer: {
                event: 'store_click',
                ecommerce: {
                    items: [{
                        item_name: store.name,
                        item_id: store.id,
                        item_category: store.category?.description,
                    }],
                },
            },
        };

        TagManager.dataLayer(gtmECommerceTag);

        const { page } = this.state;
        const { location } = history;
        const coordinate = window.scrollY;

        setScrollMemoryStores(coordinate, page, location.pathname);

        const url = getFormattedStoreURL(store);

        if (sendToHistory) {
            history.push(`${url}${STORE_HISTORY_ROUTE}`);
        } else {
            history.push(`${url}${STORE_PRODUCTS_ROUTE}`);
        }
    };

    onScroll = () => {
        const bottom = document.body.scrollHeight - window.pageYOffset - window.innerHeight <= 300;
        const {
            page,
            isFetching,
        } = this.state;
        if (bottom) {
            if (!isFetching) {
                this.setState({ page: page + 1 }, () => this.getStores());
            }
        }
    }

    parseFilters = (callback: () => void) => {
        const prevState = this.state;

        const { location } = this.props;
        const { search } = location;

        const categoryRef = this.categoryFilterFieldRef.current;
        const locationRef = this.locationFilterFieldRef.current;

        if (search === '' && categoryRef && locationRef) {
            this.clearFilters();
            categoryRef.forceClose();
            locationRef.forceClose();
            this.checkForScrollHistory();
        } else if (search) {
            const filters: StoreFiltersSelection = this.storeFiltersToObject(search);

            this.setState({
                ...prevState,
                selectedFilters: filters,
            }, () => callback());
        } else {
            callback();
        }
    }

    storeFiltersToObject = (params: string): StoreFiltersSelection => {
        const hasQ = params.charAt(0) === '?';
        const paramList = params.substr(hasQ ? 1 : 0).split('&');
        let object: StoreFiltersSelection = {
            categoryId: null,
            location: '',
            online: '',
            time: null,
            q: null,
        };

        paramList.forEach(filter => {
            const paramSplit = filter.split('=');
            object = {
                ...object,
                [decodeURI(paramSplit[0])]: decodeURI(paramSplit[1]),
            };
        });

        return object;
    };

    checkForFilters = ():boolean => {
        const { selectedFilters } = this.state;
        return (selectedFilters.categoryId !== -1
            || selectedFilters.location !== ''
            || selectedFilters.q !== null);
    }

    loadFilters = async () => {
        const { t } = this.props;
        const { selectedFilters: { categoryId, location } } = this.state;

        const urls = [storesLocationsURL({ categoryId }), storesCategoriesURL({ location })];

        axios.all(urls.map(url => axios.get(url)))
            .then(axios.spread((locationsResponse, categoriesResponse) => {
                this.setState({
                    availableFilters: {
                        location: locationsResponse.data,
                        category: categoriesResponse.data.map((el: Array<string>) => {
                            return {
                                headerUrl: '',
                                longDescription: '',
                                thumbnailUrl: '',
                                id: Number(el[0]),
                                description: el[1],
                            };
                        }),
                    },
                });
            }))
            .catch(() => displayNotification(NOTIFICATION_TYPE.ERROR, t('errors.couldNotFetchFilters')));
    }

    getStores = async (customLimit?: number) => {
        const {
            _limit,
            isFetching,
            page,
            stores,
            selectedFilters,
        } = this.state;

        if (isFetching) return;

        const hasFilters = this.checkForFilters();

        this.setState({
            isFetching: true,
        });

        let url = storesPublicURL({
            pageSize: customLimit || _limit,
            page: customLimit ? 0 : page,
        });

        if (hasFilters) {
            url = storesPublicURL({
                q: selectedFilters.q,
                pageSize: customLimit || _limit,
                page: customLimit ? 0 : page,
                categoryId: selectedFilters.categoryId,
                location: selectedFilters.location,
            });
        }

        try {
            const { data, headers } = await axios.get(url);

            const newTotalResults: number = parseInt(headers['x-total-count']);

            if (newTotalResults === 0 && page === 0) {
                this.setState({
                    isFetching: false,
                    totalResults: newTotalResults,
                });
            }
            if (stores.length < newTotalResults) {
                if (data && data.length === 0) {
                    this.setState({ isFetching: false }, () => {
                        this.getStores();
                    });
                } else {
                    this.setState({
                        isFetching: false,
                        stores: [...stores, ...data],
                        totalResults: newTotalResults,
                    }, () => {
                        const impressionStores = data.map((el: Store) => {
                            return ({
                                item_id: el.id,
                                item_name: el.name,
                                item_category: el.category?.description,
                                list: 'AllStoresList',
                            });
                        });

                        const gtmECommerceStores: DataLayerArgs = {
                            dataLayer: {
                                event: 'stores_list',
                                ecommerce: {
                                    items: impressionStores,
                                },
                            },
                        };

                        TagManager.dataLayer(gtmECommerceStores);
                    });
                }
            } else {
                this.setState({
                    isFetching: false,
                });
            }
        } catch (e) {
            this.setState({
                isFetching: false,
            });
        }
    }

    prepare = () => {
        const { t } = this.props;
        const { isFetching } = this.state;

        if (isFetching) return;

        this.setState({ isFetching: true });

        axios.get(categoriesPublicURL())
            .then(response => {
                const categoriesOptions: Array<SelectOption> = [];
                categoriesOptions.push({
                    value: '',
                    label: t('stores.selectAll'),
                });

                if (response && response.data) {
                    Object.keys(response.data).forEach(k => {
                        const c = response.data[Number(k)];
                        categoriesOptions.push({
                            value: c.id,
                            label: c.description,
                        });
                    });
                }

                this.setState({
                    isFetching: false,
                    categoriesOptions,
                }, () => this.getStores());
            })
            .catch(() => {
                this.setState({
                    isFetching: false,
                });
            });
    };

    setSearchFields = (e: React.ChangeEvent<HTMLInputElement>) => {
        const prevState = this.state;
        const { searchFilters } = this.state;

        this.setState({
            ...prevState,
            searchFilters: {
                ...searchFilters,
                [e.currentTarget.name]: e.currentTarget.value,
            },
        });
    }

    onFilterChange = (name: keyof StoreFiltersSelection, value: string | number | boolean) => {
        const { history } = this.props;
        const prevState = this.state;
        const { selectedFilters } = this.state;

        let valueToChange = String(value) === decodeURIComponent(String(selectedFilters[name])) ? initialState.selectedFilters[name] : value;

        if (name === 'time') {
            valueToChange = selectedFilters[name] ? null : value;
        }

        if (value !== -1) {
            this.setState({
                ...prevState,
                page: 0,
                stores: [],
                selectedFilters: {
                    ...selectedFilters,
                    [name]: valueToChange,
                },
            }, () => {
                const { selectedFilters: newFilters } = this.state;

                const queryString = objectToParams(newFilters);

                if (queryString === '') {
                    history.push(ALL_STORES_ROUTE);
                } else {
                    history.push(queryString);
                }
                this.getStores();
                this.loadFilters();
            });
        }
    }

    getCategoryNameFromID = (id: number | null) => {
        const { availableFilters: { category } } = this.state;
        if (id) {
            const cat = category.find(el => {
                return (Number(el.id) === Number(id));
            });
            if (cat) {
                return cat.description;
            }
        }
        return '';
    }

    clearFilters = () => {
        const { history } = this.props;

        this.setState({
            page: 0,
            stores: [],
            selectedFilters: {
                categoryId: null,
                location: '',
                q: null,
            },
        }, () => {
            history.push(ALL_STORES_ROUTE);
            this.getStores();
            this.loadFilters();
        });
    }

    renderStoreStatus = (store: Store): JSX.Element => {
        const dayOfWeek = moment().day();
        const currentHour = moment().format('HH:mm:ss');
        const { weekSchedule } = store;

        if (weekSchedule.length === 0) {
            return <p className="closed">HORÁRIO INDISPONÍVEL</p>;
        }

        const scheduleForToday = weekSchedule.find(sch => {
            if (sch) {
                if (sch.initialWeekDay && sch.endWeekDay && sch.openOn && sch.closeOn) {
                    const currentHourFormatted = moment(currentHour, 'HH:mm:ss');
                    const openHourFormatted = moment(sch.openOn, 'HH:mm:ss');
                    const closeHourFormatted = moment(sch.closeOn, 'HH:mm:ss');
                    return (sch.initialWeekDay <= dayOfWeek
                        && sch.endWeekDay >= dayOfWeek
                        && currentHourFormatted.isBetween(openHourFormatted, closeHourFormatted));
                }
            }
            return false;
        });

        if (scheduleForToday) {
            const formattedOpenOn = String(scheduleForToday.openOn).substr(0, String(scheduleForToday.openOn).length - 3);
            const formattedCloseOn = String(scheduleForToday.closeOn).substr(0, String(scheduleForToday.closeOn).length - 3);

            const openOnString = scheduleForToday.openOn ? ` | ${formattedOpenOn}h` : '';
            const closeOnString = scheduleForToday.closeOn ? `${formattedCloseOn}h` : '';

            return (
                <>
                    <p className="open">ABERTO</p>
                    <p>{`${openOnString} - ${closeOnString}`}</p>
                </>
            );
        }
        return <p className="closed">FECHADO</p>;
    }

    onPhoneNumberClick = (phoneNr: string) => {
        const { t } = this.props;
        navigator.clipboard.writeText(phoneNr);
        toast.info(t('footer.copyMessage'), {
            position: 'bottom-right',
        });
    }

    onEmailClick = (addr: string) => {
        window.location.href = `mailto:${addr}`;
    }

    toggleMobileFilters = ():void => {
        const { showFilters } = this.state;
        this.setState({
            showFilters: !showFilters,
        });
    }

    renderStoreAddress = (store: Store): JSX.Element => {
        const addressString = store.address || '';
        const locationString = store.location ? `, ${store.location}` : '';

        return <p>{`${addressString}${locationString}`}</p>;
    }

    checkForScrollHistory = async () => {
        const { storesScrollMemory: scrollMemory, history, clearScrollMemoryStores: clearScrollMemory } = this.props;
        const { _limit } = this.state;

        if (scrollMemory && history.location.pathname === scrollMemory.location) {
            const { coordinate, page } = scrollMemory;
            const numberOfElements = (page + 1) * _limit;
            await this.getStores(numberOfElements);
            this.setState({
                page,
            });
            window.scroll({
                top: coordinate,
                left: 0,
                behavior: 'smooth',
            });
        } else {
            setTimeout(() => {
                scrollToTop();
            },
            200);
        }
        clearScrollMemory();
    }

    renderStoreContainer = (store: Store):JSX.Element => {
        return (
            <div
                className="stores-list-screen__stores-results-container__store-container"
            >
                <img
                    src={resourcesURL(store.thumbnailHash)}
                    alt={store.name}
                />
                <div className="stores-list-screen__stores-results-container__store-container__content">
                    <div className="stores-list-screen__stores-results-container__store-container__content__titles">
                        {store.category && <h6>{store.category.description}</h6>}
                        <h5>{store.name}</h5>
                    </div>
                    <div className="stores-list-screen__stores-results-container__store-container__content__info-container">
                        <div className="stores-list-screen__stores-results-container__store-container__content__info-container__row">
                            <img src={clock} alt="" />
                            {this.renderStoreStatus(store)}
                        </div>
                        <div className="stores-list-screen__stores-results-container__store-container__content__info-container__row">
                            <img src={pin} alt="" />
                            {this.renderStoreAddress(store)}
                        </div>
                        <div className="stores-list-screen__stores-results-container__store-container__content__info-container__row">
                            <img src={phone} alt="" />
                            <p className="link" onClick={() => this.onPhoneNumberClick(store.contact)}>{store.contact}</p>
                        </div>
                        <div className="stores-list-screen__stores-results-container__store-container__content__info-container__row">
                            <img src={email} alt="" />
                            <p className="link" onClick={() => this.onEmailClick(store.email)}>{store.email}</p>
                        </div>
                    </div>
                    <div className="stores-list-screen__stores-results-container__store-container__content__bottom-buttons">
                        <Button
                            text="Mais info"
                            styles="button--blue button--small"
                            callback={() => { this.onStoreClick(store, true); }}
                        />
                        <ButtonImgIcon
                            icon={arrow}
                            iconPosition="right"
                            text="Comprar online"
                            styles="button--blue button--small"
                            callback={() => { this.onStoreClick(store); }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderFilterFields = (): JSX.Element => {
        const { t } = this.props;

        const {
            selectedFilters,
            searchFilters,
            availableFilters,
        } = this.state;

        return (
            <div className="stores-list-screen__filters-container">
                <FilterField
                    title="Categoria"
                    startsOpen={selectedFilters.categoryId !== null && selectedFilters.categoryId > -1}
                    ref={this.categoryFilterFieldRef}
                >
                    <div className="stores-list-screen__filters-container__search-field">
                        <input
                            value={searchFilters.category}
                            type="text"
                            placeholder="Pesquise por categoria"
                            name="category"
                            onChange={this.setSearchFields}
                        />
                        <img src={iconSearch} alt="" />
                    </div>
                    <FilterResults
                        value={searchFilters.category}
                        data={availableFilters.category}
                        renderResults={(results: Array<Category>) => {
                            return results.map((cat: Category) => {
                                return (
                                    <div
                                        className={`${baseFilterClass}${Number(cat.id) === Number(selectedFilters.categoryId) ? '--active' : '--inactive'}`}
                                        onClick={() => this.onFilterChange('categoryId', cat.id)}
                                    >
                                        {cat.description}
                                    </div>
                                );
                            });
                        }}
                    />
                </FilterField>
                <FilterField
                    title="Localidade"
                    startsOpen={selectedFilters.location !== ''}
                    ref={this.locationFilterFieldRef}
                >
                    <div className="products-list-screen__filters-container__search-field">
                        <input
                            value={searchFilters.location}
                            type="text"
                            placeholder="Pesquise por localidade"
                            name="location"
                            onChange={this.setSearchFields}
                        />
                        <img src={iconSearch} alt="" />
                    </div>
                    <FilterResults
                        value={searchFilters.location}
                        data={availableFilters.location}
                        renderResults={(results: Array<string>) => {
                            if (results.length > 0) {
                                return results.map(location => {
                                    return (
                                        <div
                                            className={`products-list-screen__filters-container__filter${location === selectedFilters.location ? '--active' : '--inactive'}`}
                                            onClick={() => this.onFilterChange('location', location)}
                                        >
                                            {location}
                                        </div>
                                    );
                                });
                            }
                            return <p>{t('errors.noFiltersFound')}</p>;
                        }}
                    />
                </FilterField>
            </div>
        );
    }

    render() {
        const { t } = this.props;

        const {
            isFetching,
            stores,
            selectedFilters,
            totalResults,
            showFilters,
        } = this.state;

        const catString = this.getCategoryNameFromID(selectedFilters.categoryId);

        return (
            <>
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <div className="stores-list-header">

                    <div className={`stores-list-header__content--${selectedFilters.categoryId}`}>
                        {selectedFilters.categoryId
                            && selectedFilters.categoryId !== -1
                            && (
                            <div className="stores-list-header__content__links">
                                <div>
                                    <a href={INDEX_ROUTE}>{t('productDetails.backLinks.index')}</a>
                                    &nbsp;/&nbsp;
                                    <div>Lojas</div>
                                    &nbsp;/&nbsp;
                                    <div className="stores-list-header__content__links--current">{catString}</div>
                                </div>
                            </div>
                            )}
                        <div className="stores-list-header__content__text">
                            <h3>Descubra o comércio local de Leiria!</h3>
                            <div>Encontre aqui todas as lojas da região.</div>
                        </div>
                    </div>
                </div>
                <div className="stores-list-screen">
                    <div className="stores-list-screen__order-by">
                        <div className="stores-list-screen__order-by__results">
                            <div>Encontrámos</div>
                            <div className="blue">&nbsp;{totalResults}&nbsp;</div>
                            <div>resultados para a sua pesquisa</div>
                        </div>
                        <div className="stores-list-screen__order-by__controls">
                            {SHOW_STORES_SCREEN_FILTERS && (
                                <>
                                    <SortFilterButton
                                        label="Aberto agora"
                                        callback={() => { this.onFilterChange('time', moment().unix()); }}
                                        isActive={selectedFilters.time !== null}
                                    />
                                    <SortFilterButton
                                        label="Comprar online"
                                        callback={() => { this.onFilterChange('online', 'true'); }}
                                        isActive={selectedFilters.online === 'true'}
                                    />
                                </>
                            )}
                            <MediaQuery maxWidth={1023}>
                                <SortFilterButton
                                    label="Filtrar"
                                    callback={() => { this.toggleMobileFilters(); }}
                                    isActive={showFilters}
                                />
                            </MediaQuery>
                        </div>
                    </div>
                    <MediaQuery maxWidth={1023}>
                        <Modal show={showFilters} onHide={() => { this.toggleMobileFilters(); }}>
                            <Modal.Header className="stores-list-screen__filters-container__modal-header">
                                <h6 className={`${baseFilterClass}-title`}>Filtrar por</h6>
                                <img src={iconClose} alt="close modal" onClick={() => { this.toggleMobileFilters(); }} />
                            </Modal.Header>
                            <ModalBody>
                                {this.renderFilterFields()}
                            </ModalBody>
                            <ModalFooter>
                                <div className="products-list-screen__filters-container__modal-buttons">
                                    <Button
                                        disabled={!this.checkForFilters}
                                        callback={() => this.clearFilters()}
                                        text="Limpar"
                                    />
                                    <Button
                                        callback={() => { this.toggleMobileFilters(); }}
                                        text="Filtrar"
                                    />
                                </div>
                            </ModalFooter>
                        </Modal>
                    </MediaQuery>
                    <MediaQuery minWidth={1024}>
                        <div className="stores-list-screen__filters-container">
                            <h6 className={`${baseFilterClass}-title`}>Filtrar por</h6>
                            {this.renderFilterFields()}
                            <div className="stores-list-screen__filters-container__clear-button">
                                <Button
                                    disabled={!this.checkForFilters}
                                    callback={() => this.clearFilters()}
                                    text="Limpar filtros"
                                />
                            </div>
                        </div>
                    </MediaQuery>
                    <div className="stores-list-screen__stores-results-container">
                        {stores.map(store => { return this.renderStoreContainer(store); })}
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslationContext(withScrollMemoryContext(StoresListScreen));

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { ReactCookieProps, withCookies } from 'react-cookie';

import okIcon from '../../../assets/images/icon-ok-circle.svg';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import { MatchParams, RouteState } from '../../../constants/misc';
import Button from '../../elements/Button';
import { ALL_PRODUCTS_VIEW_ROUTE } from '../../../constants/routes';
import { numberToCurrency, phoneNumberWithMask, scrollToTop } from '../../../utils/misc';
import { PaymentReferenceInfo } from '../../../constants/types';
import { CartContext, withCartContext } from '../../controllers/cart/CartContext';
import { AuthenticationContext, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import { UserRoles } from '../../../constants/authorization';

interface OwnProps extends RouteComponentProps<MatchParams, {}, RouteState>, TranslationContext, ReactCookieProps, CartContext, AuthenticationContext { }

interface OwnState {
    mbWayContact: string | null;
    paymentReferenceInfo: PaymentReferenceInfo | null;
    orderId: string;
}

const initialState: OwnState = {
    mbWayContact: null,
    paymentReferenceInfo: null,
    orderId: '',
};

class CheckoutSuccessScreen extends Component<OwnProps, OwnState> {
    state = { ...initialState };

    private historyUnlistener: Function = () => { };

    componentDidMount() {
        const {
            location,
            fetchCart,
            user,
        } = this.props;
        const { state } = location;

        setTimeout(() => {
            scrollToTop();
        },
            100);

        if (state) {
            const { mbWayContact, paymentReferenceInfo, orderId } = state;

            if (mbWayContact) {
                this.setState({
                    mbWayContact,
                });
            } else if (paymentReferenceInfo) {
                this.setState({
                    paymentReferenceInfo,
                });
            }
            if (orderId) {
                this.setState({ orderId });
            }

            fetchCart();
        }

        if (user?.groups[0] === UserRoles.GUEST) {
            const { history, logout } = this.props;
            this.historyUnlistener = history.listen(() => logout());
        }
    }

    componentWillUnmount() {
        this.historyUnlistener();
    }

    onContinueClick = () => {
        const { history } = this.props;
        history.push(ALL_PRODUCTS_VIEW_ROUTE);
    }

    renderPaymentInfo = () => {
        const { t } = this.props;
        const {
            mbWayContact,
            paymentReferenceInfo,
        } = this.state;

        if (mbWayContact) {
            return (
                <p className="small mt-4">{t('checkout.mbWayInfo', { number: phoneNumberWithMask(mbWayContact) })}</p>
            );
        }
        if (paymentReferenceInfo) {
            return (
                <React.Fragment>
                    <div className="payment-info">
                        <Col lg={12} className="information-container">
                            <span>{t('checkout.paymentInfo.entity')}&nbsp;</span>
                            <span className="bold">{paymentReferenceInfo.entity}</span>
                        </Col>
                        <Col lg={12} className="information-container">
                            <span>{t('checkout.paymentInfo.reference')}&nbsp;</span>
                            <span className="bold">{paymentReferenceInfo.reference}</span>
                        </Col>
                        <Col lg={12} className="information-container">
                            <span>{t('checkout.paymentInfo.value')}&nbsp;</span>
                            <span className="bold">{numberToCurrency(paymentReferenceInfo.amount)}</span>
                        </Col>
                    </div>
                    <p className="small mt-2">{t('checkout.mbPaymentInfo')}</p>
                    <p className="small">{t('checkout.timeToPay')}</p>
                </React.Fragment>
            );
        }

        return (
            <div>
                {t('checkout.orderNum')}
            </div>
        );
    }

    renderOrderId = () => {
        const { t } = this.props;
        const { orderId } = this.state;

        if (orderId) {
            return (
                <div className="message__order-id">
                    <p>{t('checkout.orderNum')}&nbsp;</p>
                    <b>{orderId}</b>
                </div>
            );
        }
        return <React.Fragment />;
    }

    render() {
        const { t } = this.props;

        return (
            <div className="app_screen">
                <div className="app_screen__checkout container">
                    <div className="success-screen">
                        <div className="success-screen__success-icon">
                            <img src={okIcon} alt="" />
                        </div>
                        <p>{t('checkout.successMessage')}</p>
                        <div className="message">
                            {this.renderOrderId()}
                            {this.renderPaymentInfo()}
                            <hr />
                            <div className="message__processing">{t('checkout.processing')}</div>
                        </div>
                        <div className="success-screen__button">
                            <Button
                                text={t('checkout.continueBtn')}
                                styles="button--dark-blue"
                                callback={this.onContinueClick}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslationContext(withCookies(withAuthenticationContext(withCartContext(CheckoutSuccessScreen))));

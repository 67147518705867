import React, { Component } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../../controllers/translation/TranslationContext';
import {
    DeliveryMethods, ShoppingOrder, ShoppingOrderLine, ShoppingOrderState,
} from '../../../constants/types';
import {
    shoppingCartsURL,
    shoppingOrdersStatusUpdateConfirmURL,
    shoppingOrdersStatusUpdateURL,
    singleShoppingCartURL,
} from '../../../services/shopping';
import withPaging, { WithPagingProps } from '../../hocs/withPaging';
import TablePaging from '../../elements/TablePaging';
import { AuthenticationContext, withAuthenticationContext } from '../../controllers/authentication/AuthenticationContext';
import Loader from '../../elements/Loader';
import { numberToCurrency } from '../../../utils/misc';
import Button from '../../elements/Button';
import { displayNotification, NOTIFICATION_TYPE } from '../../../utils/notifs';
import { MatchParams, RouteState } from '../../../constants/misc';

interface OwnProps extends TranslationContext, WithPagingProps, AuthenticationContext, RouteComponentProps<MatchParams, {}, RouteState> {}

interface OwnState {
    isFetching: boolean;
    orders: Array<ShoppingOrder>;
    _limit: number;
    totalResults: number;
    showingOrderDetails: boolean;
    order: ShoppingOrder | null;
    storeId: number;
}

const initialState: OwnState = {
    isFetching: false,
    orders: [],
    _limit: 15,
    totalResults: 0,
    showingOrderDetails: false,
    order: null,
    storeId: -1,
};

class OrdersList extends Component<OwnProps> {
    state = { ...initialState };

    componentDidMount(): void {
        this.prepare().then(() => {
            const { match } = this.props;
            const { params } = match;
            const { id } = params;

            if (id) {
                const { storeId } = this.state;
                this.loadOrderModalFromURL(storeId, id);
            }
        });
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any): void {
        const { currentPage } = this.props;
        const { currentPage: oldCurrentPage } = prevProps;

        const hasPagingChanged: boolean = currentPage !== oldCurrentPage;

        if (hasPagingChanged) {
            this.prepare();
        }
    }

    loadOrderModalFromURL = async (storeId: number, orderId: string) => {
        const { data } = await axios.get(singleShoppingCartURL(storeId, orderId));
        if (data) {
            this.onOrderClick(data);
        }
    }

    onCloseOrderModal = (): void => {
        this.setState({
            order: null,
            showingOrderDetails: false,
        });
    }

    onOrderClick = (order: ShoppingOrder): void => {
        this.setState({
            showingOrderDetails: true,
            order: {
                ...order,
            },
        });
    }

    onOrderUpdateStatus = (orderId: number, storeId: number, newState: ShoppingOrderState): void => {
        const { t } = this.props;

        this.setState({ isFetching: true });
        switch (newState) {
            case ShoppingOrderState.DELIVERED:
                axios.put(shoppingOrdersStatusUpdateURL(orderId, storeId, newState)).then(() => {
                    this.setState({ isFetching: false }, () => this.prepare());
                })
                    .catch(() => {
                        displayNotification(NOTIFICATION_TYPE.ERROR, t('storeAdmin.errorDeliver'));
                        this.setState({ isFetching: false });
                    });
                break;
            case ShoppingOrderState.CANCELED:
                axios.put(shoppingOrdersStatusUpdateURL(orderId, storeId, newState)).then(() => {
                    this.setState({ isFetching: false }, () => this.prepare());
                })
                    .catch(() => {
                        displayNotification(NOTIFICATION_TYPE.ERROR, t('storeAdmin.errorCancel'));
                        this.setState({ isFetching: false });
                    });
                break;
            case ShoppingOrderState.READY_TO_PICKUP:
                axios.put(shoppingOrdersStatusUpdateConfirmURL(orderId, storeId)).then(() => {
                    this.setState({ isFetching: false }, () => this.prepare());
                })
                    .catch(() => {
                        displayNotification(NOTIFICATION_TYPE.ERROR, t('storeAdmin.errorConfirm'));
                        this.setState({ isFetching: false });
                    });
                break;
            default:
        }
    }

    prepare = async (): Promise<void> => {
        const { currentPage, onPagingChange, user } = this.props;
        const { isFetching, _limit } = this.state;

        if (isFetching || !user || (user && !user.store)) return;

        this.setState({
            isFetching: true,
        });

        try {
            const { data, headers } = await axios.get(shoppingCartsURL(user.store ? user.store.id : -1, {
                pageSize: _limit,
                page: currentPage - 1,
            }));
            const newTotalResults: number = parseInt(headers['x-total-count']);

            if (user && user.store) {
                const { store } = user;
                this.setState({
                    storeId: store.id,
                });
            }

            if (data && data.length === 0 && currentPage !== 1) {
                this.setState({ isFetching: false }, () => {
                    onPagingChange(currentPage - 1);
                    this.prepare();
                });
            } else {
                this.setState({
                    isFetching: false,
                    orders: [...data],
                    totalResults: newTotalResults,
                });
            }
        } catch (e) {
            this.setState({
                isFetching: false,
            });
        }
    }

    getOrderStateString = (order: ShoppingOrder): string => {
        const { t } = this.props;
        const { orderState, deliveryMethod } = order;

        if (deliveryMethod === DeliveryMethods.THIRD_PARTY && orderState === ShoppingOrderState.READY_TO_PICKUP) {
            return t('storeAdmin.ordersList.orderState.READY_TO_PICKUP_CTT');
        }
        if (deliveryMethod === DeliveryMethods.EMAIL && orderState === ShoppingOrderState.DELIVERED) {
            return t('storeAdmin.ordersList.orderState.EMAIL_SENT');
        }
        return (order.orderState ? t(`storeAdmin.ordersList.orderState.${order.orderState}`) : ' - ');
    }

    getOrderTotal = (order: ShoppingOrder): string | number => {
        const normalTotal = order.orderLines.reduce((total, o) => total + o.subTotal, 0);
        if (order.deliveryMethod === DeliveryMethods.HOME) {
            return numberToCurrency(normalTotal + (order.homeDeliveryPrice || 0));
        }
        return numberToCurrency(normalTotal);
    }

    renderTableHead = () => {
        const { t } = this.props;

        return (
            <thead>
                <tr>
                    <th>{t('storeAdmin.ordersList.headers.id')}</th>
                    <th>{t('storeAdmin.ordersList.headers.customer')}</th>
                    <th>{t('storeAdmin.ordersList.headers.contact')}</th>
                    <th>{t('storeAdmin.ordersList.headers.customerEmail')}</th>
                    <th>{t('storeAdmin.ordersList.headers.deliveryMethod')}</th>
                    <th>{t('storeAdmin.ordersList.headers.total')}</th>
                    <th>{t('storeAdmin.ordersList.headers.date')}</th>
                    <th>{t('storeAdmin.ordersList.headers.state')}</th>
                    <th />
                </tr>
            </thead>
        );
    }

    renderBillingInfo = () => {
        const { order } = this.state;
        const { t } = this.props;

        if (order) {
            return (
                <React.Fragment>
                    <Col xs={12} lg={12} className="mb-2 mt-2">
                        <span className="bold">{t('storeAdmin.ordersList.labels.billingInfo')}</span>
                    </Col>
                    {order.billingAddress && order.billingAddress.name && order.billingAddress.name.trim() !== '' && (
                    <Col xs={12} lg={12} className="mb-2">
                        <span>{t('storeAdmin.ordersList.labels.name')}</span>
                        <span className="bold">{order.billingAddress.name}</span>
                    </Col>
                    )}
                    <Col xs={12} lg={12} className="mb-2">
                        <span>{t('storeAdmin.ordersList.labels.nif')}</span>
                        <span className="bold">{order.invoiceNif}</span>
                    </Col>
                    {order.billingAddress && order.billingAddress.address && order.billingAddress.address.trim() !== '' && (
                    <Col xs={12} lg={12} className="mb-2">
                        <span>{t('storeAdmin.ordersList.labels.address')}</span>
                        <span className="bold">{order.billingAddress.address}</span>
                    </Col>
                    )}
                    {order.billingAddress && order.billingAddress.postalCode && order.billingAddress.postalCode.trim() !== '' && (
                    <Col xs={12} lg={12} className="mb-2">
                        <span>{t('storeAdmin.ordersList.labels.postalCode')}</span>
                        <span className="bold">{order.billingAddress.postalCode}</span>
                    </Col>
                    )}
                    {order.billingAddress && order.billingAddress.city && order.billingAddress.city.trim() !== '' && (
                    <Col xs={12} lg={12} className="mb-2">
                        <span>{t('storeAdmin.ordersList.labels.city')}</span>
                        <span className="bold">{order.billingAddress.city}</span>
                    </Col>
                    )}
                </React.Fragment>
            );
        }
        return <React.Fragment />;
    }

    renderShippingInfo = () => {
        const { t } = this.props;
        const { order } = this.state;

        if (order) {
            return (
                <React.Fragment>
                    <Col xs={12} lg={12} className="mb-2 mt-2">
                        <span className="bold">{t('storeAdmin.ordersList.labels.deliveryInfo')}</span>
                    </Col>
                    {order.address && order.address.trim() !== '' && (
                    <Col xs={12} lg={12} className="mb-2">
                        <span>{t('storeAdmin.ordersList.labels.address')}</span>
                        <span className="bold">{order.address}</span>
                    </Col>
                    )}
                    {order.postalCode && order.postalCode.trim() !== '' && (
                    <Col xs={12} lg={12} className="mb-2">
                        <span>{t('storeAdmin.ordersList.labels.postalCode')}</span>
                        <span className="bold">{order.postalCode}</span>
                    </Col>
                    )}
                    {order.city && order.city.trim() !== '' && (
                    <Col xs={12} lg={12} className="mb-2">
                        <span>{t('storeAdmin.ordersList.labels.city')}</span>
                        <span className="bold">{order.city}</span>
                    </Col>
                    )}
                    {order.deliveryMethod === DeliveryMethods.HOME && (
                        <>
                            <Col xs={12} lg={12} className="mb-2 mt-2">
                                <span className="bold">{t('storeAdmin.ordersList.labels.coordinates')}</span>
                            </Col>
                            <Col xs={12} lg={12} className="mb-2">
                                <span>{t('storeAdmin.ordersList.labels.latitude')}</span>
                                <span className="bold">{order.latitude || ''}</span>
                            </Col>
                            <Col xs={12} lg={12} className="mb-2">
                                <span>{t('storeAdmin.ordersList.labels.longitude')}</span>
                                <span className="bold">{order.longitude || ''}</span>
                            </Col>
                        </>
                    )}
                </React.Fragment>
            );
        }
        return <React.Fragment />;
    }

    renderLinePrice = (line: ShoppingOrderLine): JSX.Element => {
        const { t } = this.props;
        const { previousPrice, unitPrice } = line;

        if (previousPrice) {
            const discount = Math.round((previousPrice - unitPrice) * 100 / previousPrice);
            return (
                <>
                    <div><del>{numberToCurrency(previousPrice)}</del> &nbsp;{numberToCurrency(unitPrice)}</div>
                    <p>{t('customerAdmin.ordersList.details.discountString', { discount })}</p>
                </>
            );
        }
        return (
            <p>{numberToCurrency(unitPrice)}</p>
        );
    }

    render() {
        const {
            t,
            currentPage,
            onPagingChange,
        } = this.props;
        const {
            isFetching,
            orders,
            _limit,
            totalResults,
            showingOrderDetails,
            order,
            storeId,
        } = this.state;

        const hasData = orders && orders.length > 0;
        let hasAddressData = false;
        if (order) {
            const hasAddress = !!(order.address && order.address.trim() !== '');
            const hasPostalCode = !!(order.address && order.address.trim() !== '');
            hasAddressData = hasAddress || hasPostalCode;
        }

        return (
            <div className="app-tabs__tab-content container customer-admin store-manager">
                {isFetching && (
                    <div className="loader-wrapper">
                        <Loader />
                    </div>
                )}
                <Table responsive="sm">
                    {this.renderTableHead()}
                    {hasData ? (
                        <tbody>
                            {Object.keys(orders).map(k => {
                                const orderAux = orders[Number(k)];
                                const deliveryDateString = orderAux.deliveryDate ? `(${moment.unix(Number(orderAux.deliveryDate)).local().format('DD/MM/YYYY - HH:mm:ss')})` : '';
                                const hasDeliveredButton = (orderAux.orderState === ShoppingOrderState.READY_TO_PICKUP && orderAux.deliveryMethod === DeliveryMethods.PRIVATE)
                                    || (orderAux.orderState === ShoppingOrderState.IN_TRANSIT
                                        && (orderAux.deliveryMethod === DeliveryMethods.COURIER || orderAux.deliveryMethod === DeliveryMethods.HOME));

                                return (
                                    <tr key={orderAux.id} onClick={(): void => this.onOrderClick(orderAux)} className="clickable">
                                        <td>{orderAux.id}</td>
                                        <td>{orderAux.customer.name}</td>
                                        <td>{orderAux.contact || ' - '}</td>
                                        <td>{orderAux.customer.email}</td>
                                        <td>{t(`enums.deliveryMethods.${orderAux.deliveryMethod}`)}</td>
                                        <td className="total-cell">{this.getOrderTotal(orderAux)}</td>
                                        <td className="date-cell">{moment.unix(Number(orderAux.createdDate)).local().format('DD/MM/YYYY HH:mm')}</td>
                                        <td>{`${this.getOrderStateString(orderAux)} ${deliveryDateString}`}</td>
                                        <td>
                                            { hasDeliveredButton && (
                                                <div className="orders-buttons">
                                                    <Button
                                                        styles="button--small button--blue"
                                                        callback={(e): void => {
                                                            e.stopPropagation();
                                                            this.onOrderUpdateStatus(orderAux.id, storeId, ShoppingOrderState.DELIVERED);
                                                        }}
                                                        text={t('storeAdmin.ordersList.button.delivered')}
                                                    />
                                                </div>
                                            )}
                                            {(orderAux.orderState === ShoppingOrderState.PROCESSING) && (
                                                <div className="orders-buttons">
                                                    <Button
                                                        styles="button--small button--blue"
                                                        callback={(e): void => {
                                                            e.stopPropagation();
                                                            this.onOrderUpdateStatus(orderAux.id, storeId, ShoppingOrderState.READY_TO_PICKUP);
                                                        }}
                                                        text={t('storeAdmin.ordersList.button.ready')}
                                                    />
                                                    <Button
                                                        styles="button--small button--light-red"
                                                        callback={(e): void => {
                                                            e.stopPropagation();
                                                            this.onOrderUpdateStatus(orderAux.id, storeId, ShoppingOrderState.CANCELED);
                                                        }}
                                                        text={t('storeAdmin.ordersList.button.cancel')}
                                                    />
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    ) : (
                        <tbody>
                            <tr>
                                <td colSpan={4}>
                                    {t('global.noData')}
                                </td>
                            </tr>
                        </tbody>
                    )}
                </Table>
                {hasData && (
                    <TablePaging
                        currentPage={currentPage}
                        limit={_limit}
                        totalResults={totalResults}
                        onStartChange={onPagingChange}
                    />
                )}
                <Modal show={showingOrderDetails} onHide={this.onCloseOrderModal} centered size="xl">
                    <Modal.Header closeButton>
                        {order && (
                            <Modal.Title>{t('storeAdmin.ordersList.detailsTitle')} {order.id}</Modal.Title>
                        )}
                    </Modal.Header>
                    <Modal.Body>
                        {order && (
                            <div className="modal-body modal-custom order-modal">
                                <Row>
                                    <Col xs={12} lg={12} className="mb-2">
                                        <span>{t('storeAdmin.ordersList.labels.total')}</span>
                                        <span className="bold">{this.getOrderTotal(order)}</span>
                                    </Col>
                                    <Col xs={12} lg={12} className="mb-2">
                                        <span>{t('storeAdmin.ordersList.labels.deliveryMethod')}</span>
                                        <span className="bold">{t(`enums.deliveryMethods.${order.deliveryMethod}`)}</span>
                                    </Col>
                                    <Col xs={12} lg={12} className="mb-2">
                                        <span>{t('storeAdmin.ordersList.labels.date')}</span>
                                        <span className="bold">{moment.unix(Number(order.createdDate)).local().format('DD/MM/YYYY - HH:mm:ss')}</span>
                                    </Col>
                                    <Col xs={12} lg={12} className="mb-2">
                                        <span>{t('storeAdmin.ordersList.labels.customer')}</span>
                                        <span className="bold">{order.customer.name}</span>
                                    </Col>
                                    <Col xs={12} lg={12} className="mb-2">
                                        <span>{t('storeAdmin.ordersList.labels.customerEmail')}</span>
                                        <span className="bold">{order.customer.email}</span>
                                    </Col>
                                    {order.contact && order.contact.trim() !== '' && (
                                        <Col xs={12} lg={12} className="mb-2">
                                            <span>{t('storeAdmin.ordersList.labels.contact')}</span>
                                            <span className="bold">{order.contact}</span>
                                        </Col>
                                    )}
                                    {this.renderBillingInfo()}
                                    {hasAddressData && (order.deliveryMethod !== DeliveryMethods.PRIVATE) && this.renderShippingInfo()}
                                    <Col xs={12} lg={12} className="mt-4">
                                        <Table responsive="sm">
                                            <thead>
                                                <tr>
                                                    <th>{t('storeAdmin.ordersList.labels.product')}</th>
                                                    <th>{t('storeAdmin.ordersList.labels.brand')}</th>
                                                    <th>{t('storeAdmin.ordersList.labels.color')}</th>
                                                    <th>{t('storeAdmin.ordersList.labels.size')}</th>
                                                    <th>{t('storeAdmin.ordersList.labels.reference')}</th>
                                                    <th>{t('storeAdmin.ordersList.labels.quantity')}</th>
                                                    <th>{t('storeAdmin.ordersList.labels.unitPrice')}</th>
                                                    {order.orderLines
                                                    && ((order.orderLines.length > 1 || order.deliveryMethod === DeliveryMethods.HOME)
                                                        ? <th>{t('storeAdmin.ordersList.labels.subtotal')}</th>
                                                        : <th>{t('storeAdmin.ordersList.labels.totalHeader')}</th>
                                                    )}
                                                </tr>
                                            </thead>
                                            {order.orderLines && order.orderLines.length > 0 ? (
                                                <>
                                                    <tbody>
                                                        {Object.keys(order.orderLines).map(k => {
                                                            const line = order.orderLines[Number(k)];

                                                            return (
                                                                <tr key={line.productId}>
                                                                    <td>{line.description}</td>
                                                                    <td>{line.brand || '---'}</td>
                                                                    <td>{line.color || '---'}</td>
                                                                    <td>{line.size || '---'}</td>
                                                                    <td>{line.productReference}</td>
                                                                    <td>{line.quantity}</td>
                                                                    <td className="total-cell">{this.renderLinePrice(line)}</td>
                                                                    <td className="total-cell">{numberToCurrency(line.subTotal)}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>

                                                    {order.deliveryMethod === DeliveryMethods.HOME && (
                                                        <>
                                                            <br />
                                                            <tbody>
                                                                <tr>
                                                                    <td><span className="bold">Portes: </span> Entrega ao Domicílio</td>
                                                                    <td />
                                                                    <td />
                                                                    <td />
                                                                    <td />
                                                                    <td />
                                                                    <td />
                                                                    <td>{numberToCurrency(order.homeDeliveryPrice || 0)}</td>
                                                                </tr>

                                                            </tbody>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={4}>
                                                            {t('global.noData')}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </Table>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default withRouter(withAuthenticationContext(withPaging(withTranslationContext(OrdersList))));

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import { MatchParams } from '../../constants/misc';
import { TranslationContext, withTranslationContext } from '../controllers/translation/TranslationContext';
import { CookiesPolicy } from '../elements/terms/CookiesPolicy';
import { TermsAndConditionsOfUse } from '../elements/terms/TermsAndConditionsOfUse';
import { PersonalDataAndPrivacyPolicy } from '../elements/terms/PersonalDataAndPrivacyPolicy';
import FrequentlyAskedQuestions from '../elements/terms/FrequentlyAskedQuestions';
import {
    COOKIES_POLICY_ROUTE, PRIVACY_POLICY_ROUTE, FAQS_ROUTE, TERMS_AND_CONDITIONS_OF_USE_ROUTE, SALE_CONDITIONS_ROUTE,
} from '../../constants/routes';
import SaleConditions from '../elements/terms/SaleConditions';

interface OwnProps extends RouteComponentProps<MatchParams>, TranslationContext {}

interface OwnState {
    activeKey: string;
}

const initialState: OwnState = {
    activeKey: 'termsAndConditionsOfUse',
};

class TermsAndConditionsScreen extends Component<OwnProps> {
    state = { ...initialState };

    componentDidMount(): void {
        this.selectTabAccordingToPathname();

        window.scrollTo({
            top: 0,
            behavior: 'auto',
        });
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<{}>, snapshot?: any): void {
        const { location: oldLocation } = prevProps;
        const { location } = this.props;
        const { pathname: oldPathname } = oldLocation;
        const { pathname } = location;

        if (pathname !== oldPathname) {
            this.selectTabAccordingToPathname();

            window.scrollTo({
                top: 0,
                behavior: 'auto',
            });
        }
    }

    onNavChange = (eventKey: string) => {
        const { history } = this.props;
        let path = '';
        switch (eventKey) {
            case 'personalDataAndPrivacyPolicy':
                path = PRIVACY_POLICY_ROUTE;
                break;
            case 'cookiesPolicy':
                path = COOKIES_POLICY_ROUTE;
                break;
            case 'faqs':
                path = FAQS_ROUTE;
                break;
            case 'saleConditions':
                path = SALE_CONDITIONS_ROUTE;
                break;
            case 'termsAndConditionsOfUse':
            default:
                path = TERMS_AND_CONDITIONS_OF_USE_ROUTE;
                break;
        }

        history.push(path);

        window.scrollTo({
            top: 0,
            behavior: 'auto',
        });
    }

    selectTabAccordingToPathname = () => {
        const { location } = this.props;
        const { pathname } = location;
        let activeKey = '';

        switch (pathname) {
            case COOKIES_POLICY_ROUTE:
                activeKey = 'cookiesPolicy';
                break;
            case PRIVACY_POLICY_ROUTE:
                activeKey = 'personalDataAndPrivacyPolicy';
                break;
            case FAQS_ROUTE:
                activeKey = 'faqs';
                break;
            case SALE_CONDITIONS_ROUTE:
                activeKey = 'saleConditions';
                break;
            default:
                activeKey = 'termsAndConditionsOfUse';
                break;
        }

        this.setState({
            activeKey,
        });
    }

    render() {
        const { t } = this.props;
        const { activeKey } = this.state;

        return (
            <div className="terms-screen container">
                <Tab.Container activeKey={activeKey} onSelect={this.onNavChange}>
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column sticky-top">
                                <Nav.Item>
                                    <Nav.Link eventKey="termsAndConditionsOfUse">{t('terms.termsAndConditionsOfUse')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="personalDataAndPrivacyPolicy">{t('terms.personalDataAndPrivacyPolicy')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="cookiesPolicy">{t('terms.cookiesPolicy')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="saleConditions">{t('terms.saleConditions')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="faqs">{t('terms.faqs')}</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="termsAndConditionsOfUse">
                                    <TermsAndConditionsOfUse />
                                </Tab.Pane>
                                <Tab.Pane eventKey="personalDataAndPrivacyPolicy">
                                    <PersonalDataAndPrivacyPolicy />
                                </Tab.Pane>
                                <Tab.Pane eventKey="cookiesPolicy">
                                    <CookiesPolicy />
                                </Tab.Pane>
                                <Tab.Pane eventKey="faqs">
                                    <FrequentlyAskedQuestions />
                                </Tab.Pane>
                                <Tab.Pane eventKey="saleConditions">
                                    <SaleConditions />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        );
    }
}

export default withTranslationContext(TermsAndConditionsScreen);

/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

import React from 'react';

import arrow from '../../assets/images/arrow-long-up.svg';

interface OwnProps {
    ascending: boolean;
    active: boolean;
    callback: (() => void);
}
 
const SortArrow : React.FunctionComponent<OwnProps> = ({ active, ascending, callback }) => (
    <img
        className={`app-tabs__arrow${!ascending && active ? '--descending' : ''} app-tabs__arrow${active ? '--active' : '--inactive'}`}
        src={arrow}
        alt=""
        onClick={callback}
    />
);
 
export default SortArrow;

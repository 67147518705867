/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

/**
 * AuthenticationAction
 * @enum {string}
 */
export enum AuthenticationAction {
    LOGIN_REQUEST = 'LOGIN_REQUEST',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',
    LOGOUT_REQUEST = 'LOGOUT_REQUEST',
    SET_AUTHENTICATION = 'SET_AUTHENTICATION',
    RESET_AUTHENTICATION = 'RESET_AUTHENTICATION',
}

/**
 * @typedef {Object} LoginRequestAction
 * @property {string} type
 */
export interface LoginRequestAction {
    type: typeof AuthenticationAction.LOGIN_REQUEST;
}

/**
 * @typedef {Object} LoginSuccessAction
 * @property {string} type
 * @property {object} payload
 */
export interface LoginSuccessAction {
    type: typeof AuthenticationAction.LOGIN_SUCCESS;
    payload: object;
}

/**
 * @typedef {Object} LoginFailureAction
 * @property {string} type
 * @property {object} payload
 */
export interface LoginFailureAction {
    type: typeof AuthenticationAction.LOGIN_FAILURE;
    payload: object;
}

/**
 * @typedef {Object} LogoutFailureAction
 * @property {string} type
 */
export interface LogoutFailureAction {
    type: typeof AuthenticationAction.LOGOUT_REQUEST;
}

/**
 * @typedef {Object} SetAuthenticationAction
 * @property {string} type
 * @property {object} payload
 */
export interface SetAuthenticationAction {
    type: typeof AuthenticationAction.SET_AUTHENTICATION;
    payload: object;
}

/**
 * @typedef {Object} ResetAuthenticationAction
 * @property {string} type
 */
export interface ResetAuthenticationAction {
    type: typeof AuthenticationAction.RESET_AUTHENTICATION;
}

export type AuthenticationActionTypes = LoginRequestAction | LoginSuccessAction | LoginFailureAction | LogoutFailureAction | SetAuthenticationAction | ResetAuthenticationAction;
